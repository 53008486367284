<template>
  <!-- .wrapper  -->
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-edit-home"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <!-- .header -->
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <!-- ./header -->
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper mr-0 bg-white">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="bg-white">
                      <div style="text-align: center; margin-top: 10px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                              src="/assets/images/smart/vector-houses.svg"
                              class="avatar-smart mt-50"
                          />
                          <h5 class="font-weight-bold">
                            {{ $t('message.setting.edit_home') }}
                          </h5>
                        </div>
                      </div>
                      <br/>
                      <form class="px-20">
                        <div class="form-group">
                          <h6>{{ $t('message.ModalAddHome.name') }} <span class="text-danger">*</span></h6>
                          <input
                              type="text"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              maxlength="32"
                              autocomplete="off"
                              id="homename"
                              :placeholder="$t('message.ModalAddHome.hintName')"
                          />
                        </div>

                        <div class="row">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="editHomeName()"
                            >
                              <span class="text-white"> {{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                          <!-- /.col -->
                        </div>
                      </form>
                      <div class="text-center" style="height: 50px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
        <!-- /. Main content -->

        <div
            class="modal center-modal fade"
            id="modal-center"
            tabindex="-1"
            data-keyboard="false"
            data-backdrop="static"
        >
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
                </div>
                <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.Content Wrapper. Contains page content -->
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </div>
  <!-- ./wrapper -->
</template>

<script>
//  import ModalCenter from './ModalCenter';
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameHomeValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    AppBar,
  },
  data: function () {
    return {
      title: '',
      loading: false,
      isModalVisible: false
    };
  },
  props: {
    homeids: {type: Array},
  },
  created() {
    this.title = this.$t('message.setting.edit')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$store.dispatch("infoHome");
    document.getElementById("homename").value = this.$store.getters.getNameHome;
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        $("#modal-edit-home").modal("hide");
      }
    },
    editHomeName() {
      let self = this;
      this.loading = true;
      var name = document.getElementById("homename").value;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.homeids[0];
      if (!nameHomeValidationInput(name)) return;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        home_id: home_id,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.homeEdit;
      const p = postAjaxCall(URL, data, "");
      // this.openModalLoading();

      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              // self.exitModalLoading();
              self.loading = false
              self.$router.push({name: "mainpage"});
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    },
  },
};
</script>


<style scoped>
</style>
