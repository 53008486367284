<template>
  <v-select
      v-model="$i18n.locale"
      :items="langs"
      @change="langChanged($i18n.locale)"
      item-text="show"
      item-value="value"
      menu-props="auto"
      label="Select"
      style="width: 120px"
      hide-details
      prepend-icon="mdi-web"
      single-line
  ></v-select>
</template>

<script>
export default {
  name: 'SwitchLocale',
  data() {
    return {
      langs: [
        {
          value: "en",
          show: "English"
        },
        {
          value: "fa",
          show: 'فارسی'
        },
        {
          value: "ar",
          show: 'العربیة'
        },
        {
          value: "tur",
          show: 'Turkish'
        }
      ]
    }
  },
  methods: {
    langChanged(lang) {
      localStorage.Lang = lang;
      location.reload();
      this.$router.push({
        name: "home",
      }).then();
      //this is OK
    },
  }
}
</script>

<style scoped>
select {
  width: 150px;
  line-height: 49px;
  text-align: center;
  height: 38px;
  outline: 0;
  color: #000;
  border-radius: 20px;
  background-color: #eeeeee;
  margin-bottom: 15px;
  font-size: 16px;
}
option{
  text-align: center;
  width:100px;
}
</style>