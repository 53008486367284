<template>
  <v-app class="bg">
    <div class="mt-3">
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickEditAlarm()"
          >
            <i class="mdi mdi-border-color mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.setting.edit') }}</label>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickPinCode()"
          >
            <i class="mdi mdi-border-color mdi-24px"></i>
            <div class="media-body">
              <!-- <label>{{ $t('message.setting.edit') }}</label> -->
              پین کد 
              <label v-if="data.setPassword" style="color: green;">فعال</label>
              <label v-else style="color: red;">غیر فعال</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickChangePass()"
          >
            <i class="mdi mdi-lock-outline mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.setting.changePIN') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickRelay4()"
          >
            <i class="mdi mdi-lightbulb-outline mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.relay.relay') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickAnt()"
          >
            <i class="mdi mdi-access-point-network mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.antenaa') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickWireless()"
          >
            <i class="mdi mdi-lock-pattern mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.ws_sensor') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickWireless24()"
          >
            <i class="mdi mdi-white-balance-iridescent mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.ws_sensor_24h') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickRemote()"
          >
            <i class="mdi mdi-remote mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.public.remote') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickZoneSms()"
          >
            <i class="mdi mdi-email-alert mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.alert_sms') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickZone()"
          >
            <i class="mdi mdi-checkbox-blank-outline mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.zone') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickSirenTime()"
          >
            <i class="mdi mdi-alarm-multiple mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.alert_time') }}</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickOutputSound()"
          >
            <i class="mdi mdi-volume-high mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.zone_melodi') }}</label>
            </div>
          </div>
          <div
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickPrivacyReport()">
            <i class="mdi mdi-security-network mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.alarm.privacy_report') }}</label>
            </div>
          </div>
          <div
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="ClickDelAlarm()"
          >
          <i class="mdi mdi-delete-forever mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.subDevice.delete') }}</label>
            </div>
          </div>
          <div
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="ClickResetFactory()"
          >
          <i class="mdi mdi-format-rotate-90 mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.noneHome.reset') }}</label>
            </div>
          </div>
          <div  
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickAbout()"
          >
            <i class="mdi mdi-information-outline mdi-24px"></i>
            <div class="media-body">
              <label>{{ $t('message.AppBar.about_device') }}</label>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <SettingModalResetFactory v-bind:serialnumbers="serialnumber"></SettingModalResetFactory>
    <SubModalDel v-bind:data_info_sub="data_info_alarm"></SubModalDel>
    <SettingModalInitPass
        v-show="isShow"
        v-bind:homeids="homeid"
        @passInit="passInit"
    ></SettingModalInitPass>
    <SettingModalChangePass
        v-show="isShowChange"
        v-bind:homeids="homeid"
    ></SettingModalChangePass>
    <EditName v-bind:data_name="title" @clicked="getNameCmpCallback"></EditName>
    <ModalResetFactoryAlarm
          :infoItem="dataReset"></ModalResetFactoryAlarm>
    <SubModalSetPass v-bind:data_info_sub="data_info_alarm"></SubModalSetPass>
  </v-app>
</template>
<script>

import SubModalDel from "../../subdevice/SubModalDel";
import SettingModalResetFactory from "../../setting/SettingModalResetFactory.vue";
import SettingModalInitPass from "../../setting/SettingModalInitPass.vue";
import SettingModalChangePass from "../../setting/SettingModalChangePass.vue";
import LayoutDefault from "../../../layouts/LayoutDefault";
import EditName from "@/components/Cmp/EditName.vue";
import ModalResetFactoryAlarm from "@/components/subdevice/alarmFara/settings/ModalResetFactoryAlarm";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  components: {
    SubModalDel,
    SettingModalResetFactory,
    SettingModalInitPass,
    SettingModalChangePass,

    EditName,
    ModalResetFactoryAlarm,
    SubModalSetPass,

  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      homeid: [this.home_id],
      serialnumber: this.serial_number,
      title: '',
      data_info_alarm: [this.home_id, this.serial_number],
      CmpResetShow:false,
      dataReset:[],
      data:[],
    };
  },
  mounted() {
    this.title = this.$t('message.public.settings')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsAlarm');
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
    this.$store.dispatch("InfoSubDevice");
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    console.log('data',this.data)
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {

      if (value == 'back') {
        this.$router.push({name: "SubAlarmFaraInfo", params: {home_id: this.home_id, serial_number: this.serial_number},});

      }
    },
    xxx() {
      localStorage.setItem('selection', this.type + "m")
      this.$router.push({
        name: "selection_drag",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type_code: this.type,
        }
      },)
    },
    ClickEditAlarm(){
        $("#modal-edit-name").modal("show");
    },
    ClickPinCode(){
      $("#modal-set-pass-sub").modal("show");  
      },
    clickPrivacyReport() {
      this.$router.push({
        name: "PrivacyReportInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickOutputSound() {
      this.$router.push({
        name: "OutputSoundInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickZoneSms() {
      this.$router.push({
        name: "ZoneSmsInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickAnt() {
      this.$router.push({
        name: "AntInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickSirenTime() {
      this.$router.push({
        name: "SirenTimeInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    clickAbout() {
      this.$router.push({
        name: "AboutAlarmInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    clickRelay4() {
      this.$router.push({
        name: "Relay4Setting", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    clickWireless() {
      this.$router.push({
        name: "WirelessInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickWireless24() {
      this.$router.push({
        name: "Wireless24Info", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickRemote() {
      this.$router.push({
        name: "RemoteAlarmInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    clickZone() {
      this.$router.push({
        name: "ZoneInfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickEditHome() {
      $("#modal-edit-home").modal('show');
    },
    ClickManage() {
      this.$router.push({
        name: "managementclientinfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickResetFactory() {
      $("#modal-reset-alarm").modal('show');
    },
    passInit() {
      this.$router.push({name: "mainpage"})
    },
    ClickCLock() {
      this.$router.push({
        name: "setClock", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    ClickDelAlarm() {
      $("#modal-del-sub").modal("show");
    },
    clickInitPass() {
      $("#modal-init-pass").modal("show");
    },
    clickChangePass() {
      this.isShowChange = true;
      $("#modal-change-pass0").modal("show");
    },
    goBackSetting() {
      this.$router.push({name: "homeDevices", params: {home_id: this.home_id, serial_number: this.serial_number},});
    },
    getNameCmpCallback(name_value) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        name: name_value,
        serial_number: this.subdevice_serial,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.$router.push({
                name: "homeDevices",
                params: {
                  serial_number: self.serial_number,
                  home_id: self.home_id,
                },
              });
              self.lastStatus(user_id, token);
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
