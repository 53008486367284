<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "CoolerFaraTimer",
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {}
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.public.off'),
        status: true
      },
        {
          name: this.$t('message.subDevice.cooler_pomp'),
          status: false
        },
        {
          name: this.$t('message.subDevice.cooler_low'),
          status: false
        },
        {
          name: this.$t('message.subDevice.cooler_fast'),
          status: false
        },
      ],
      type: "coolerFara"
    }
  },
  mounted() {
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
    this.$store.dispatch("InfoSubDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(val, status);
      switch (val) {
        case 0:
          this.nextAll();
          break;
        case 1:
          this.nextL1(status);
          break;
        case 2:
          this.nextL2(status);
          break;
        case 3:
          this.nextL3(status);
          break;
      }
    },
    nextAll() {
      let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox = "K5F";
      const saction = "PS" + subdevice_serial + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CoolerStep2TimerInfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL1(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "K1T";
      } else if (!status) {
        status_checkbox = "K1F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CoolerStep2TimerInfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL2(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "K2T";
      } else if (!status) {
        status_checkbox = "K2F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CoolerStep2TimerInfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL3(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "K3T";
      } else if (!status) {
        status_checkbox = "K3F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CoolerStep2TimerInfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    goBackTimer() {
      this.$router.push({
        name: "CoolerFaraTimerShow",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
        },
      });
    },
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "CoolerFaraTimerShow",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    },
  },
}
</script>

<style scoped>

</style>