<template>
  <v-app class="bg">
    <v-card round  class="mt-5 mx-4">
      <v-container>
      <v-row>
        <v-col>
                  <div style="text-align: center;">
                    <div class="fontawesome-icon-list m-0">
                      <img
                        src="/assets/images/smart/profile.svg"
                        style="width: 200px; height: 200px"
                      />
                      <h5 class="font-weight-bold">
                          {{ $t ('message.public.input_info', {name: $t('message.profile.change_password')}) }}
                      </h5>
                    </div>
                  </div>
                  <br />
                  <form novalidate class="px-20">
                    <div class="form-group">
                      <h6>
                        {{$t('message.public.password')}} 
                        <span class="text-danger">*</span></h6>
                      <input
                        type="text"
                        class="form-control"
                        required=""
                        aria-invalid="false"
                        maxlength="32"
                        autocomplete="off"
                        v-model="password"
                        :placeholder="$t('message.loginEmail.inputPassword')"
                      />
                    </div>
                    <div class="form-group">
                      <h6>
                        {{ $t('message.public.new_password') }}  
                        <span class="text-danger">*</span></h6>
                      <input
                        type="text"
                        class="form-control"
                        required
                        aria-invalid="false"
                        maxlength="16"
                        autocomplete="off"
                        v-model="new_password"
                        :placeholder="$t('message.loginEmail.inputNewPass')"
                      />
                    </div>
                    <div class="form-group">
                      <h6>
                        {{ $t('message.public.confirm_new_password') }}  
                        <span class="text-danger">*</span></h6>
                      <input
                        type="text"
                        class="form-control"
                        required
                        aria-invalid="false"
                        maxlength="16"
                        autocomplete="off"
                        v-model="new_password_confirm"
                        :placeholder="$t('message.loginEmail.inputConfirmNewPass')"
                      />
                    </div>
                    <div class="row fixed-bottom mb-5 px-4">
                    <div class="row">
                      <div class="col-12 text-center">
                         <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="myFunction()"
                            >
                            <span class="text-white"> {{$t('message.public.submit')}} </span>
                            </v-btn>
                      </div>
                      <!-- /.col -->
                    </div>
                    </div>
                  </form>          
        </v-col>
      </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

 <script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {
  passEmailValidationInput,
  passCompareValidationInput
} from "@/../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {},
  data() {
    return {
      title: '',
      loading: false,
      password:'',
      new_password:'',
      new_password_confirm:'',
    };
  },
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.title= this.$t('message.profile.change_password');
        this.$store.dispatch("infoUser");
  },
  created() {
    let data = {
      title: this.$t('message.profile.change_password'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ProfileChangePassword');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    myFunction() {
      let self = this;
       
      //--------------------------------------  variable --------------------------------------//

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      //     //--------------------------------------  validation --------------------------------------//
      if (
        
          !passEmailValidationInput(this.password) ||
          !passEmailValidationInput(this.new_password) ||
          !passEmailValidationInput(this.new_password_confirm) ||
          !passCompareValidationInput(this.new_password,this.new_password_confirm)
       
      )
        return;
      this.loading=true;
      //     //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        password:this.password,
        new_password:this.new_password,
        new_password_confirm:this.new_password_confirm
      };
      //     //--------------------------------------Request --------------------------------------//
      let URL = getUrl.profilePasswordChange;
      //  $("#modal-center").modal('show');
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
                 self.loading=false
            self.$router.push({ name: "profile" });
          } else {
                 self.loading=false
            //error
          }
        },
        //         //--------------------------------------Err --------------------------------------//
        function (errr) {
         
        }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({ name: "profile" });
      }
    },
  },
};
</script>



<style scoped>
</style>
