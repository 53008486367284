<template>
  <v-app class="bg">
    <div class="mr-0">
      <!-- Main content -->
      <div class="box-body chart-responsive">
        <div style="height: 812px">
          <section class="content">
            <!-- row -->
            <div class="media-list media-list-divided media-list-hover">
              <div
                  class="media align-items-center setting"
                  v-on:click.prevent="ClickEditName()"
              >
                <i class="mdi mdi-border-color fa-2x"></i>
                <div class="media-body">
                  <p>{{ $t('message.CmpEditName.edit_name') }}</p>
                </div>
              </div>
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickCLock()"
              >
                <i class="mdi mdi-clock fa-2x"></i>
                <div class="media-body">
                  <p>{{ $t('message.noneHome.clock') }}</p>
                </div>
              </div>
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickManage"
              >
                <i class="mdi mdi-account-settings mdi-24px"></i>
                <div class="media-body">
                  <p>{{ $t('message.client.management') }}</p>
                </div>
              </div>
              <div
                  class="media align-items-center"
                  v-on:click.prevent="clickReport()"
              >
                <i class="mdi mdi-chart-arc fa-2x fa-2x"></i>
                <div class="media-body">
                  <p> {{ $t('message.setting.report') }} </p>
                </div>
              </div>
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickAbout()"
              >
                <i class="mdi mdi-information-outline fa-2x"></i>
                <div class="media-body">
                  <p> {{ $t('message.AppBar.about_device') }} </p>
                </div>
              </div>
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickResetFactory()"
              >
                <i class="mdi mdi-format-rotate-90 fa-2x"></i>
                <div class="media-body">
                  <p>{{ $t('message.noneHome.reset') }}</p>
                </div>
              </div>
            </div>

            <!-- /.row -->
          </section>
          <!-- /. Main content -->
        </div>
      </div>
    </div>
    <ModalNoneResetFactoryIr v-bind:serialnumbers="serialnumber"></ModalNoneResetFactoryIr>

    <EditName v-bind:data_name="name" @clicked="getNameCmpCallback"></EditName>
  </v-app>
</template>

<script>
import ModalNoneResetFactoryIr from "./ModalNoneResetFactoryIr";
import EditName from "@/components/Cmp/EditName.vue";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {nameHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    // ModalEditNameNoneIr,
    ModalNoneResetFactoryIr,
    EditName,
  },
  props: {
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      homeid: this.home_id,
      serialnumber: [this.serial_number],
      title: '',
      name: ''
    };
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    this.name = this.$store.getters.getNameNoneHome;
  },
  mounted() {
    this.title = this.$t('message.public.settings');
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
    let details = {
      title: this.title,
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneIr');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    ClickManage() {
      localStorage.setItem("type", "8")
      this.$router.push({
        name: "noneHomeClientInfo",
        params: {serial_number: this.serial_number, type: "8"},
      });
    },
    ClickEditName() {
      $("#modal-edit-name").modal('show');
    },

    ClickCLock() {
      this.$router.push({
        name: "SettingNoneClockIr", params: {serial_number: this.serial_number},
      });
    },
    ClickAbout() {
      this.$router.replace({name: "AboutNoneIr", params: {serial_number: this.serial_number},});

    },
    clickReport() {
      this.$router.push({
        name: "reportirnone",
        params: {serial_number: this.serial_number},
      });
    },

    ClickResetFactory() {
      $("#modal-reset-factory-none-ir").modal('show');
    },
    getNameCmpCallback(msg) {
      console.log("name msg", msg)
      let self = this;

      var name = msg;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;


      if (!nameHomeValidationInput(name)) return;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        serial_number: serial_number

      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.noneDeviceEdit;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();

      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.exitModalLoading();
              self.$router.push({name: "mainpage"});
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );

    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({name: "irGatewaynone", params: {serial_number: this.serial_number},});
      }
    },
  },
};
</script>


<style scoped>
</style>
