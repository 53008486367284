<template>
  <v-app class="bg">
    <div class="mt-4 px-2">
      <section class="content">
        <div :key="index" v-for="(item, index) in List">
          <CmpRowList :infoItem="item" :subTitleShow="false" :icon="true" :ripple="false" @clicked="clicked">
          </CmpRowList>
        </div>
      </section>
    </div>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import CmpRowCard from "@/components/Cmp/CmpRowCard.vue";
import { getTopics } from "@/../public/assets/js/utils/topics.js";
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import { getMessageSub } from "@/../public/assets/js/utils/helper_sub_message.js";


var showToast;
var connection = false;
var timeout;

export default {
  components: {
    CmpRowCard,
    CmpRowList
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      subdevice_serial: "",
      title: "",
      deviceID: '',
      status_wifi: "",
      status_switch: false,
      wifi_setting: 1,
      info: [],
      List: [],
    };
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        // this.showStatus(String(data))
      }

      SucessToastMsg.Msg();
      //  }
    },
    "+/+/receive/+/seting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        // this.showStatus(String(data))
      }

      SucessToastMsg.Msg();
      //  }
    },
  },
  created() {
    this.List.push({ index: 1, title: this.$t("message.public.wifi_setting_status"), actions: 'switch', switch: false, icon: "mdi mdi-toggle-switch-off" })
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("infoSubDevice"));

    this.subdevice_serial = this.info.serial_number

    this.List[0].switch = this.info.wifi_setting ? true : false;
    this.wifi_setting = this.info.wifi_setting ? true : false;

    this.title = this.$t("message.public.wifi_setting_status")
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'TherostaticProStatusWifi');
    this.onSubscribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubThermostaticProInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicSettingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSetingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
    },
    clicked() {

      var status = this.List[0].switch ? status = "ON" : status = "OFF"
      var msg = getMessageSub.wifiSettingStatus(status)
      this.sendMessage(msg)
    },
    switchclick(status_switch, index) {


    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {
      this.status_wifi = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.status_wifi, '');
      }
    },
    sendReq(password) {
      this.sendRequest(this.status_wifi, password);
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let topic_final = getTopics.getTopicBroadcastSend(this.serial_number, subdevice_serial);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                // self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                // self.lastStatus();
              }
            }
            else {
              self.closeModalLoading();
              // self.$router.push({
              //   name: "homeDevices",
              //   params: {home_id: self.home_id, serial_number: self.serial_number},
              //     });
              self.$router.push({
                name: "SubThermostaticProInfo",
                params: {
                  serial_number: this.serial_number,
                  home_id: this.home_id,
                  count: this.count,
                },
              });
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped></style>
