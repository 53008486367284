<template>
    <section class="content">
    <Step2Timer :end="false" :daysData="daysdata" @myFunction="MyFunction"/>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    </section>
</template>

<script>
import {ErrorToastMsg, BetweenStr} from "../../../../../public/assets/js/utils/util.js";
import {Tools} from "../../../../../public/assets/js/utils/util.js";
import Step2Timer from "../../../Cmp/timer/create/Step2Timer";
import {listOfDays} from "../../../../../public/assets/js/utils/timer_parser";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {DayValidationInput} from "../../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var showToast;
var connection = false;
export default {
  name: "keyTouchTimerStep2Timer",
  components: {
    Step2Timer,
    CmpPasscode
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.daysdata = listOfDays();
  },
  data() {
    return {
      lang: '',
      daysdata: [],
      temparray: [],
      selectedday: "",
      days: [],
      timer_count: "",
      time1: '',
      isModalVisible: false
    };
  },
  props: {
    serial_number: {},
    click: {}
  },
  async mounted() {
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer3');
    await this.$store.dispatch("statusTimer");
    await this.getCount();
    console.log("timer_count", this.timer_count);
    //------subscribe
    const topic1 = getTopics.getTopicTimerReceive(this.serial_number, '256');
    this.$mqtt.subscribe(topic1);
  },
  mqtt: {
    "rnd/+/r/+/timer"(data, topic) {

      var serail = BetweenStr.getBetweenStr(topic, "rnd/", "/r");
      var count = BetweenStr.getBetweenStr(topic, "r/", "/timer");
      if (
          serail[1] == this.serial_number &&
          count[1] == "256"
      ) {
        if (topic.split("/")[4] === "timer") {

          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            Tools.sweetAlert(this.$t('message.timer.timer'), this.$t('message.timer.added'), 'success', 3000)//TODO added in ar
          }
        }
      }
    },
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] == this.serial_number) {
        if (topic.split("/")[4] === "timer") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            Tools.sweetAlert(this.$t('message.timer.timer'), this.$t('message.timer.added'), 'success', 3000)
          }
        }
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendReq(password) {
      this.sendRequest(password);
    },
    MyFunction(days, time1) {
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var timer_status = this.$store.getters.getTimerStatus;
      console.log('timer',timer_status)
      if (
          !DayValidationInput(days.length)
      )
        return;
      console.log(this.timer_count)
      var msgday = this.getDaySelected(days);
      message =
          "TIMER" +
          this.timer_count +
          "-" +
          timer_status +
          "-" +
          msgday +
          time1 +
          "#";

      var topic_final = getTopics.getTopicTimerSend(
          this.serial_number,
          256);
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.timer_count = jsonArray.content.timer_count;
              setTimeout(function () {
                if (connection) {
                  self.exitModalLoading();
                  self.$router.push({
                    name: "keyTouchTimerShow",
                    params: {serial_number: self.serial_number},
                  });
                }
                if (!connection) {
                  self.exitModalLoading();
                  console.log("connection", connection)
                  if (showToast == 1) {
                    console.log("connection", connection)
                    ErrorToastMsg.Msg();
                    self.$router.push({
                      name: "keyTouchTimerShow",
                      params: {serial_number: self.serial_number},
                    });
                  }
                }
                showToast = 0;
                connection = false;
              }, 5000);
            } else {
              //error

              self.exitModalLoading();
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getCount() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var tapp = localStorage.getItem("t_app");

      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneTimerCount;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.timer_count = jsonArray.content.timer_count;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getDaySelected(days) {
      this.selectedday = "";
      var check = false;
      for (var i of this.daysdata) {
        this.temparray.push(i);
      }
      this.temparray.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);
      this.temparray.forEach((element) => {
        days.forEach((values) => {
          if (values.value == element.value) check = true;
        });
        if (check) this.selectedday += element.value + "1" + "-";
        else this.selectedday += element.value + "0" + "-";
        check = false;
      });
      return this.selectedday;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "keyTouchTimer", params: {serial_number: this.serial_number},});
      }
    },
  }
}
</script>

<style scoped>

</style>