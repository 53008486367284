import { render, staticRenderFns } from "./Ant.vue?vue&type=template&id=2ac86779&scoped=true"
import script from "./Ant.vue?vue&type=script&lang=js"
export * from "./Ant.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ac86779",
  null
  
)

export default component.exports