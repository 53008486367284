import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import Vuetify from 'vuetify'
// import VueMqtt from "vue-mqtt"
import 'vuetify/dist/vuetify.min.css'
import VueMqtt from "vue-mqtt"
// import '@mdi/font/css/materialdesignicons.css'
import VueSweetAlert from 'vue-sweetalert'
import i18n from './i18n'
import Modal from "./components/Modal/Modal.vue";

// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
// import { createVuetify } from 'vuetify'

// export default createVuetify({
//   icons: {
//     defaultSet: 'mdi', // This is already the default value - only for display purposes
//   },
// })

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}; 

const url=process.env.VUE_APP_MQTT_URL
const user=process.env.VUE_APP_MQTT_USER
const pass=process.env.VUE_APP_MQTT_PASS

Vue.use(VueI18n);
Vue.use(Vuetify);
Vue.use(VueSweetAlert,options);
// Vue.use(VueMqtt, url,
//   {
//       clientId: 'Fara' + parseInt(Math.random() * 100000),
//       username: user,
//       password: pass,
//       reconnectPeriod: 1000,
//       clean: true,

//   });

Vue.component('my-modal', Modal)
Vue.config.productionTip = false


new Vue({
  data() {
    return {
      url_mqtt:process.env.VUE_APP_MQTT_URL,
    };
  },
  mounted() {
    // console.log("dsddddddddddd",this.url_mqtt)
  },
  router,
  store,
  i18n,
  vuetify: new Vuetify({
    // icons: {
    //   iconfont: 'mdi', // default - only for display purposes
    // },
    icons: {
          iconfont: 'mdi' // This is already the default value - only for display purposes
        },
  }),
  render: h => h(App)
}).$mount('#app')
