<template>
  <div
      class="modal modal-fill fade"
      tabindex="-1"
      role="dialog"
      id="modal-set-pass-sub"
      ref="vuemodal"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <div class="content-wrapper mr-0 bg-white">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <section class="content">
              <div class="row">
                <div class="col-12 col-lg-12 p-0">
                  <div class="box">
                    <div class="box-body p-0">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div style="text-align: center; margin-top: 10px">
                          <div class="fontawesome-icon-list m-0">
                            <img
                                src="/assets/images/smart/setpassword.svg"
                                style="width: 300px; height: 300px"
                            />
                            <h5 class="font-weight-bold">
                              {{ $t('message.ModalAddHome.password_hint') }}
                            </h5>
                          </div>
                        </div>
                        <br/>
                        <form class="px-20">
                          <div class="form-group">
                            <h6>{{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
                            <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="4"
                                pattern="[0-9]"
                                id="change_pass_status-sub"
                                autocomplete="off"
                                style="
                                -webkit-text-security: disc;
                                margin-top: 8px;
                              "
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder=" $t('message.ModalAddHome.password_hint') "
                                v-model="setpassvalue"
                            />
                          </div>
                          <div class="row">
                            <div class="col-12 text-center">
                              <v-btn
                                  class="btn btn-info btn-block"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="FunctionPassStatus()"
                              >
                                <span class="text-white"> {{ $t('message.public.submit') }} </span>
                              </v-btn>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  components: {
    AppBar,
  },
  data: function () {
    return {
      setpassvalue: "",
      title: "",
      loading: false,
    };
  },
  props: {
    data_info_sub: {type: Array},
    device: {},
  },
  created() {
    this.title = this.$t('message.ModalAddHome.pin_code')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$store.dispatch("infoHome");
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.setpassvalue = "";
        $("#modal-set-pass-sub").modal("hide");
      }
    },
    FunctionPassStatus() {
      let self = this;
      var info_sub = this.data_info_sub;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = info_sub[0];
      let device_serial = info_sub[1];
      let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (this.device == 'relay') {
        subdevice_serial = info_sub[2];
      }
      var password = this.setpassvalue;
      this.loading = true;

      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: subdevice_serial,
        password: password,
      };
      //--------------------------------------Request --------------------------------------//  
      let URL = getUrl.passChange;
      const p = postAjaxCall(URL, data);

      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              self.$router.push({
                name: "homeDevices",
                params: {home_id: home_id, serial_number: device_serial},
              });
              self.setpassvalue = "";
              $("#modal-set-pass-sub").modal("hide");
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
