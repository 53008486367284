import { render, staticRenderFns } from "./NCooler.vue?vue&type=template&id=bc32fa1c&scoped=true"
import script from "./NCooler.vue?vue&type=script&lang=js"
export * from "./NCooler.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc32fa1c",
  null
  
)

export default component.exports