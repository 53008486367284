<template>
  <div id="app">
    <component :is="layout" :update="update" @onClickChild="onClickChild">
      <router-view :layout.sync="layout" :update.sync="update" :click="click"></router-view>
    </component>
  </div>
</template>
<script>
import {postAjaxCall} from "../public/assets/js/postAjax";
import Swal from "sweetalert2";
import LayoutDefault from "./layouts/LayoutDefault";

export default {
  name: "App",
  data() {
    return {
      isModalVisible: false,
      isUpdateVisible: false,
      lang: false,
      subtitle: "",
      version: "",
      layout: "div",
      click: "",
      update: "",
    };
  },
  mqtt: {
    /** 'VueMqtt/publish1' or '+/publish1' */
    "v/p"(data) {
      //   this.buff = this.buff + data + '<br>'
    },
  },
  watch: {
    isRtl() {
      this.isRtl = localStorage.getItem('Lang') === 'en';
    },
    update() {

    }
  },
  created() {
    if (localStorage.getItem('Lang') != null)
      this.$i18n.locale = localStorage.getItem('Lang');
    console.log("language", localStorage.getItem('Lang'))
    if (this.$i18n.locale === 'en' || this.$i18n.locale === 'tur') {
      document.querySelector('body').classList.add('ltr');
      this.$vuetify.ltr = true;
      if (document.querySelector('body').classList.contains('rtl'))
        document.querySelector('body').classList.remove('rtl');
      this.$vuetify.rtl = false;
    }
    if (this.$i18n.locale === 'fa' || this.$i18n.locale === 'ar') {
      document.querySelector('body').classList.add('rtl');
      this.$vuetify.rtl = true;
      if (document.querySelector('body').classList.contains('ltr'))
        document.querySelector('body').classList.remove('ltr');
      this.$vuetify.ltr = false;

    }
    let select = localStorage.getItem("startOfWeek")
    if (!select) {
      localStorage.setItem("startOfWeek", 0);
    }
    this.checkUpdate();
  },
  mounted() {
    this.$mqtt.subscribe("VueMqtt/#");
    // this.checkUpdate();
  },

  methods: {
    checkUpdate() {
      let self = this;
      let URL = "https://server.pfas.ir/api/app/update/check";
      const p = postAjaxCall(URL);
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        let version = jsonArray.content.version.version_number;
        if (localStorage.getItem('Version') != null) {
          if (localStorage.getItem('Version') !== version) {
            if (localStorage.getItem('Version') < version) {
              // self.isUpdateVisible = true;
              Swal.fire({
                title: self.$t('message.public.update'),
                icon: 'success',
                html: jsonArray.content.version.detail,
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: self.$t('message.public.update'),
                cancelButtonText: self.$t('message.public.cancel'),
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  self.updated();
                }
              })
              self.version = version;
              self.subtitle = jsonArray.content.version.detail;
            } else if (localStorage.getItem('Version') > version)
              localStorage.setItem('Version', version);
          }
        } else {
          localStorage.setItem('Version', version);
        }
      }).catch(function (error) {
      });
    },
    updated() {
      localStorage.setItem('Version', this.version);
      caches.keys().then(function (names) {
        for (let name of names)
          caches.delete(name);
      });
      this.isUpdateVisible = false;
      location.reload(true);
    },
    onClickChild(event) {
      this.click = event;
      setTimeout(function () {
        this.click = "";
      }.bind(this), 2000);
    },
  },
};
</script>
<style>
@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url(fonts/webfonts/eot/IRANSansWeb.eot);
  src: url(fonts/webfonts/eot/IRANSansWeb.eot?#iefix) format("embedded-opentype"),
    /* IE6-8 */ url(fonts/webfonts/woff2/IRANSansWeb.woff2) format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url(fonts/webfonts/woff/IRANSansWeb.woff) format("woff"),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url(fonts/webfonts/ttf/IRANSansWeb.ttf) format("truetype");
}

.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;;
}

.blue {
  color: #0fc491;
}

.swal2-html-container {
  font-family: 'IRANSans';
}

.v-btn--fab.v-size--default {
  width: 36px;
  height: 36px;
}
</style>