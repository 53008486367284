<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="box">
              <div class="row no-gutters justify-content-md-center">
                <div class="col-lg-3 col-md-5 col-12">
                  <div style="text-align: center" class="mt-3">
                    <img :src="'/assets/images/smart/anim-door.jpg'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mx-2 mb-1">
          <div class="col-12">
            <div class="container box">
              <v-row d-flex>
                <v-col cols="12  d-flex justify-content-center"><label class="text-center"> {{ $t('message.subDevice.doorshutter.status_doorshutter') }} :
                  {{ txtStatus }}</label></v-col>
              </v-row>
              <div class="row d-flex">
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="OpenFunction()"
                    >
                      <i
                        class="mdi mdi-garage-open mdi-36px align-self-center"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="StopFunction()"
                    >
                      <i class="mdi mdi-stop mdi-36px align-self-center"></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="CloseFunction()"
                    >
                      <i class="mdi mdi-garage mdi-36px align-self-center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
    </div>
    <ModalOutletMomentray
      v-bind:serials="serial_number"
      :v-bind:subdevice_serial="subdevice_serial"
      v-bind:opentime="opentime" 
      v-bind:closetime="closetime" 
      type="doorShutter"
      @set_time_shutter="setTimeShutter"
    ></ModalOutletMomentray>
    <SubModalDel v-bind:data_info_sub="data_info_door"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_door"></SubModalEdit>
    <EditName v-bind:data_name="title" @clicked="getNameCmpCallback"></EditName>
    <SubModalSetPass v-bind:data_info_sub="data_info_door"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import EditName from "@/components/Cmp/EditName.vue";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import TimerInput from "../../Cmp/TimerInput.vue";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import { passHomeValidationInput } from "../../../../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    ModalOutletMomentray,
    TimerInput,
    EditName,

  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {},
    // subdevice_serial:{},
  },
  data() {
    return {
      pass: true,
      set_password: "",
      door_status: "",
      txtStatus: '',
      data_info_door: [this.home_id, this.serial_number],
      main_admin: "",
      title: "",
      isModalVisible: false,
      long_time: "",
      subdevice_serial: "",
      // data for  instant setting
      delayOpen:"",
      keyOpen:"",
      delayClose:"",
      keyClose:"",
      // type Request 0  for action and 1  for instant Setting
      typeRequest:0,
      opentime :30,
      closetime : 30
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] ==this.subdevice_serial) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          console.log('String(data)',String(data));
          this.showGetMsg(String(data));
          this.exitModal();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      var TimeRes = BetweenStr.getBetweenStr(String(data), "K1:", "S")[1];
      this.showGetMsgBroadCast(String(data));
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3]== this.subdevice_serial
      ) {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          localStorage.setItem("shutterLong" + this.subdevice_serial, TimeRes);
          clearTimeout(timeout);
          $("#modal-outlet-momentray").modal("hide");
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },

  mounted() {
    let self=this;
    try {
      this.subdevice_serial = JSON.parse(
        localStorage.getItem("infoSubDevice")
      ).serial_number;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"));
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      this.onSubscribe();
      this.onSubscribeSetting();
      setTimeout(function () {
        self.lastStausShutter();
      }, 100);

    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: self.$t("message.public.operation_failure"),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: self.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({
              name: "homeDevices",
              params: {
                home_id: self.home_id,
                serial_number: self.serial_number,
              },
            });
            swal.close();
          }
        }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true,
      shuttersetting: true,
      about: true,
    };
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit("update:layout", LayoutDefault);
    this.$emit("update:update", "SubCooler");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    lastStausShutter() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModal();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModal();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              console.log('message',message)
              self.parseStatusShutter(message);
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    parseStatusShutter(msg) {
      console.log('parseStatusShutter',msg)
      if (msg == '1000') {
        this.txtStatus = this.$t('message.public.open')
      }
      if (msg == '0000') {
        this.txtStatus = this.$t('message.public.stop')
      }
      if (msg == '0010') {
        this.txtStatus = this.$t('message.public.close')
      }
    },
    setTimeShutter(delayOpen, keyOpen, delayClose, keyClose) {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
        this.delayOpen=delayOpen;
        this.keyOpen=keyOpen;
        this.delayClose=delayClose;
        this.keyClose=keyClose;
        this.typeRequest=1;
       
      } else {
        this.sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, "");
      }
    },
    sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var sendDelayTime =
        "K" +
        keyOpen +
        ":" +
        delayOpen +
        "S" +
        "~" +
        "K" +
        keyClose +
        ":" +
        delayClose +
        "S#";
      var topic_final = getTopics.getTopicElevatorSend(
        this.serial_number,
        this.subdevice_serial
      );

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: sendDelayTime,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            connection = false;
            console.log('jsonArray',jsonArray);
            $("#modal-pass-code").modal("hide");
            $("#modal-outlet-momentray").modal("hide");
            timeout = setTimeout(function () {
              self.exitModal();
              // showToast = 0;
              // connection = false;
              if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }}
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.exitModal();
            $("#modal-outlet-momentray").modal("hide");
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if (value === "about") {
        this.$router.push({
          name: "AboutShutter",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-name").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value === "shuttersetting") {
        $("#modal-outlet-momentray").modal("show");
      }
    },
    OpenFunction() {
      this.sendMessage("K1T#");
      this.typeRequest=0;
    },
    StopFunction() {
      this.sendMessage("K2T#");
      this.typeRequest=0;
    },
    CloseFunction() {
      this.sendMessage("K3T#");
      this.typeRequest=0;
    },
    sendMessage(status) {
      if (this.set_password == 1) {
        this.door_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(status, "");
      }
    },
    sendReq(password) {
      if(this.typeRequest==0){
         this.sendRequest(this.door_status, password);
        }
      else if(this.typeRequest==1){
        this.sendRequestLong(this.delayOpen, this.keyOpen, this.delayClose, this.keyClose,password);
      }
    },
    sendRequest(status, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      var topic_final = getTopics.getTopicActionSend(
        this.serial_number,
        this.subdevice_serial
      );

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: status,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModal();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.openModal();
            connection = false;
            $("#modal-pass-code").modal("hide");
            timeout = setTimeout(function () {
              self.exitModal();
              // showToast = 1;
              // connection = false;
              if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }}
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.exitModal();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
    showGetMsgBroadCast(msg) {
     
      this.opentime = msg.split("K1:").pop().split("S")[0];
      this.closetime = msg.split("K3:").pop().split("S")[0];
      localStorage.setItem("openTimeShutter", this.opentime);
      localStorage.setItem("closeTimeShutter", this.closetime);
      console.log('opentime',this.opentime)
      console.log('closetime',this.closetime)
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    onSubscribeSetting() {
      var topic1 = getTopics.getTopicElevatorReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    showGetMsg(msg) {
      this.parseStatusShutter(msg)
    },
    getNameCmpCallback(name_value) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        name: name_value,
        serial_number: this.subdevice_serial,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.$router.push({
                name: "homeDevices",
                params: {
                  serial_number: self.serial_number,
                  home_id: self.home_id,
                },
              });
              self.lastStatus(user_id, token);
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
