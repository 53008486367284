<template>
  <v-app class="bg">
    <v-container>
      <v-col cols="12" md="5" class="py-0 d-flex" style="margin:auto;">
        <img style="display: block; margin:auto; height:100%; width: 100%"
             :src="'/assets/images/smart/elevator_door.jpg'">
      </v-col>
      <v-card>
        <v-row class="rounded-16 mt-5">
          <v-col class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 px-5 rounded">
              <img
                  src="/assets/images/smart/sub_elavator.svg"
                  alt="smart"
                  width="32px"
                  height="32px"
              />
              <div class="media-body">
                <h6>{{ $t('message.relay.type.elevator') }}</h6>
              </div>
              <v-btn   
                  :loading="loadingInstant"
                  :disabled="loadingInstant"
                  color="bg-primary"
                  fab
                  @click="clickElvator()"
              >
                <v-icon class="text-white">
                  mdi mdi-swap-vertical
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <ModalOutletMomentray
        v-bind:serials="serial_number" :v-bind:subdevice_serial="subdevice_serial" 
        v-bind:delay_elvator="delay_elvator" 
        type="elevator"
        @set_delayEl="SetDelayEl"
    ></ModalOutletMomentray>
    <SubModalDel v-bind:data_info_sub="data_info_elevator"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_elevator"></SubModalEdit>
    <EditName v-bind:data_info_sub="data_info_elevator"></EditName>
    <SubModalSetPass v-bind:data_info_sub="data_info_elevator"></SubModalSetPass>
  </v-app>
</template>

<script>
import CmpPasscode from '../../../components/Cmp/general/CmpPasscode.vue'
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import EditName from '../../../components/Cmp/EditName.vue'
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { getMessageElavator } from "../../../../public/assets/js/utils/elvatorParser.js";
import {BetweenStr, SucessToast, SucessToastMsg ,ErrorToastMsg} from "../../../../public/assets/js/utils/util";
import {getTopics} from "../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import LayoutDefault from "../../../layouts/LayoutDefault";
var connection = false;
var showToast;
var timeout;

export default {
  name: "subElevator",
  components: {
    ModalOutletMomentray,
    CmpPasscode,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      title: this.$t('message.relay.type.elevator'),
      pass: false,
      set_password: false,
      data_info_elevator: [this.home_id, this.serial_number, this.count],
      loadingInstant: false,
      subdevice_serial: '',
      isModalVisible: false,
      main_admin:'',
      delay_elvator:'',
    }
  },
  mounted: function mounted() {
    // init run
    try {
    
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      console.log('setting elavtor',this.$store.getters.getInfoSub.elevator_setting)
      if(this.$store.getters.getInfoSub.elevator_setting==null){
        this.delay_elvator='01'
      }else{
        this.delay_elvator=getMessageElavator.getMessageElvatorInstant(this.$store.getters.getInfoSub.elevator_setting);
      }
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.onSubscribe();
      this.onSubscribeSetting();
      setTimeout(function () {
        self.lastStausElavator();
      }, 100);
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: this.$t("message.ListDevice.err_process"),
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t("message.public.OK"),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      edit: true,
      del: true,
      momentary: true,
      timer:true,
      about:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubService');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
          if (topic.split("/")[4] === "action") {
        connection = true;
        clearTimeout(timeout);
        if (showToast == 1) {
          this.loadingInstant = false;
          SucessToastMsg.Msg();
            }
        }
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      var TimeRes = BetweenStr.getBetweenStr(String(data), "K1:", "S")[1];
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        
        if (topic.split("/")[4] === "setting") {
           connection = true;
           localStorage.setItem("elevator_delay" + this.subdevice_serial,TimeRes);
          clearTimeout(timeout);
            $("#modal-outlet-momentray").modal("hide");
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  methods: {
    sendReq(password) {
      this.MyFunctionPower(password);
    },
    clickElvator() {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.MyFunctionPower('')
      }
    },
    MyFunctionPower(password) {  
      this.loadingInstant = true;
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let saction = "K1T#";
      var topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);        
        timeout = setTimeout(function () {
            self.loadingInstant = false;
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);}
         else {
          self.loadingInstant = false;
        }
      });
    },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "homeDevices",
        });
      }
      if (value === "momentary") {
        $("#modal-outlet-momentray").modal("show");
      }
      if (value === "timer") {
        this.$router.push({
          name: "ElevatorTimerShow",
        })
      }
      if (value === "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value === "edit") {
        $("#modal-edit-sub").modal("show");      
      }
      if (value === "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
           if (value === "about") {
        this.$router.push({
          name: "aboutelevator",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    FunctionBtnOff() {
      this.$emit('send', this.data_info_elevator)
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    onSubscribeSetting() {
      var topic1 = getTopics.getTopicElevatorReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    SetDelayEl(value) {
      if (this.set_password == 1) {
      } else {
        this.sendSettingDelay(value, "");
      }
    },
    sendSettingDelay(delay,password){
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      if (delay == null || delay === "") {
        delay = "01";
      }
      let saction = "K1:" + delay + "S#";
      var topic_final = getTopics.getTopicElevatorSend(
        this.serial_number,
        this.subdevice_serial
      ); 
      showToast = 1;
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);
          timeout = setTimeout(function () {
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            $("#modal-outlet-momentray").modal("hide");
            showToast = 0;
            connection = false;
          }, 3000);}
      });
    },
    lastStausElavator() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModalLoading();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
  }
}
</script>

<style scoped>

</style>