<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "../../../Cmp/timer/create/timer/Timer";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "ServiceTimer",
  components: {Timer},
  data: () => ({
    title: "",
    data: {},
    status_checkbox: '',
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.touch_key.key'),
        status: false
      },
        {
          name: this.$t('message.touch_key.music'),
          status: false
        },
        {
          name: this.$t('message.touch_key.fan'),
          status: false
        },
        {
          name: this.$t('message.touch_key.spray'),
          status: false
        },
      ],
      type: "service"
    }
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
    this.$store.dispatch("InfoSubDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      switch (val) {
        case 0:
          this.nextL1(status);
          break;
        case 1:
          this.nextL2(status);
          break;
        case 2:
          this.nextL3(status);
          break;
        case 3:
          this.nextL4(status);
          break;
      }
    },
    nextL1(status) {
      if (status) {
        this.status_checkbox = "K1T";
      } else if (!status) {
        this.status_checkbox = "K1F";
      }
      var saction = this.status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ServiceStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL2(status) {
      if (status) {
        this.status_checkbox = "K2T";
      } else if (!status) {
        this.status_checkbox = "K2F";
      }
      var saction = this.status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ServiceStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL3(status) {
      if (status) {
        this.status_checkbox = "K3T";
      } else if (!status) {
        this.status_checkbox = "K3F";
      }
      var saction = this.status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ServiceStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL4(status) {
      if (status) {
        this.status_checkbox = "K4T";
      } else if (!status) {
        this.status_checkbox = "K4F";
      }
      var saction = this.status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ServiceStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "ServiceTimerShow",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
  }
}
</script>

<style scoped>

</style>