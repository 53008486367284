<template>
  <!-- .wrapper  -->
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-del-home"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <!-- .header -->
      <section class="error-page bg-img mt-50 rtl">
        <div class="container  px-40">
          <div
              class="row  align-items-center justify-content-center text-center"
          >
            <div class="col-lg-7 col-md-10 col-12">
              <h1
                  class="text-warning font-size-180 font-weight-bold error-page-title"
              ></h1>
              <img
                  src="/assets/images/smart/vector-houses.svg"
                  class="avatar-smart"
              />
              <br/>
              <h4 class="text-danger mt-10"><strong>{{ $t('message.public.warning') }}</strong></h4>
              <br/>
              <h6 class="mt-10" style="line-height: 25px !important">
                <strong
                >{{ $t('message.client.del_home') }}
                </strong
                >
              </h6>
              <h6>{{ $t('message.setting.if_del_home') }}</h6>
              <v-btn
                  class="btn mt-5"
                  :loading="loading"
                  :disabled="loading"
                  color="bg-danger"
                  @click="DelFunction()"
              >
                <span class="text-white">  {{ $t('message.public.delete') }} </span>
              </v-btn>
              <button
                  type="button"
                  class="btn btn-dark mb-5 mt-10 m-5"
                  v-on:click.prevent="CancelFunction()"
              >
                {{ $t('message.public.cancel') }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
      <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
            </div>
            <div class="modal-body">
              <div class="spinner-border textprimary" role="status">
                <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
              </div>
              <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal Add Home-->

  </div>
  <!-- ./wrapper -->
</template>

<script>

//--------------------------------------  variable --------------------------------------//
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  data: function () {
    return {
      title: '',
      loading: false,
    };
  },
  props: {
    homeids: {type: Array},
  },
  components: {
    AppBar,
  },
  mounted() {
    this.title= this.$t('message.client.delete_home')
  },
  methods: {
    onClickChild(value) {

      if (value == 'back') {
        $("#modal-del-home").modal("hide");
      }
    },
    CancelFunction() {
      $("#modal-del-home").modal("hide");
    },
    DelFunction() {
      //--------------------------------------  variable --------------------------------------//
      let self = this;
      this.loading = true;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.homeids[0];
      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.homeDel;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              // self.exitModalLoading();
              self.$router.push({name: "mainpage"});
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },

  },
};
</script>


<style scoped>
</style>
