<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-edit-sub"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <section class="content">
        <div class="row">
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-edit-name.svg"
                            class="avatar-smart"
                        />
                        <h5 class="font-weight-bold">
                          {{ $t('message.profile.edit') }}
                        </h5>
                      </div>
                    </div>
                    <br/>
                    <form novalidate class="px-20">
                      <div class="form-group">
                        <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
                        <input
                            type="text"
                            class="form-control"
                            required=""
                            aria-invalid="false"
                            maxlength="32"
                            autocomplete="off"
                            id="inputeditnamesub"
                            :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                        />
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <v-btn
                              class="btn btn-info btn-block mt-10"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="myFunction()"
                          >
                            <span class="text-white"> {{ $t('message.public.submit') }} </span>
                          </v-btn>
                        </div>
                      </div>
                    </form>
                    <div class="text-center" style="height: 50px">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-12"></div>
      </section>
    </div>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
</template>
<script>
import AppBar from "../AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  components: {
    AppBar,
  },
  props: {
    data_info_sub: {type: Array},
  },
  data() {
    return {
      title: '',
      loading: false,
    };
  },

  created() {
    this.title = this.$t('message.subDevice.edit')
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$store.dispatch("InfoSubDevice");
    document.getElementById("inputeditnamesub").value = this.$store.getters.getNameSub
  },
  methods: {
    onClickChild(value) {
      if (value == 'back') {
        $("#modal-edit-sub").modal("hide");
      }

    },
    CancelFunction() {
      $("#modal-edit-sub").modal("hide");
    },
    myFunction() {
      var name = document.getElementById("inputeditnamesub").value
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      var home_id = this.data_info_sub[0];
      var serial_number = this.data_info_sub[1];
      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        serial_number: subdevice_serial,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              self.$router.push({name: "homeDevices", params: {home_id: home_id, serial_number: serial_number}});
            } else {
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
