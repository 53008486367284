<template>
  <v-app class="bg">
    <div class="mt-5">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickEdit()"
          >
            <i class="mdi mdi-border-color fa-2x"></i>
            <div class="media-body" v-if="relay8type.type === 16">
              <p>{{ $t('message.relay.edit.edit') }}</p>
            </div>
            <div class="media-body" v-else>
              <p>{{ $t('message.CmpEditName.edit_name') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickEditRGB"
              v-if="relay8type.type !== 16"
          >
            <i class="mdi mdi-border-color fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.edit') + ' ' + 'RGB' }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="TimerNone()"
          >
            <i class="mdi mdi-clock fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.AppBar.scheduler') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ToggleRelay8()"
          >
            <i class="mdi mdi-clock fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.relay.toggle.toggle_setting') }}({{this.toggleStatus}})</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickManage()"
          >
            <i class="mdi mdi-account-settings mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.client.management') }}</p>
            </div>
          </div>
          <div
              v-if="relay8type.type === 16"
              class="media align-items-center setting"
              v-on:click.prevent="ClickDelete()"
          >
            <i class="mdi mdi-delete fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.delete') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickResetFactory()"
          >
            <i class="mdi mdi-format-rotate-90 fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.reset') }}</p>
            </div>
          </div>
        </div>

        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <Modal8RelayResetFactory :infoItem="dataReset" v-if="CmpResetShow"></Modal8RelayResetFactory>
  </v-app>
</template>

<script>
import {getTopics} from "../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import Modal8RelayResetFactory from "./8Relay/noneModal8RelayResetFactory.vue";
import {getMessageSub} from "../../../public/assets/js/utils/helper_sub_message.js";
import LayoutDefault from "../../layouts/LayoutDefault";
import {SucessToastMsg,ErrorToastMsg,BetweenStr,} from "../../../public/assets/js/utils/util.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  components: {
    Modal8RelayResetFactory,
  },
  props: {
    serial_number: {},
    click: {}
  },
  mqtt: {

    "+/+/receive/256/setting"(data, topic) {
      this.connection = true;
      // let str = String(data);
      if (topic.split("/")[1] === this.serial_number) {
       
        this.connection = true;
      }
     
    },
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      info: '',
      serialnumber: this.serial_number,
      title: '',
      name: '',
      isModalVisible: false,
      dataReset: '',
      relay8type: '',
      CmpResetShow: false,
      type:'',
      toggleStatus:'',
      toggleMode: "",
      toggleBtnDialogOK: "",
      connection:false,
    };
  },
  created() {
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    this.name = this.$store.getters.getNameNoneHome;
  
   
    let data = {
      title: this.$t('message.public.settings'),
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneHomeSettingRelay');
  },
  mounted() {
    let self=this;
    this.dataReset = this.serial_number;
    this.relay8type = JSON.parse(localStorage.getItem("data_edit"));
    this.CmpResetShow = true;
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
    setTimeout(function () {
        self.getRelaysItem();
      }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    TimerNone() {
      if (this.relay8type.type === 16) {
        this.$router.push({
          name: "nRelayTimerShow",
          params: {serial_number: this.serial_number},
        });
      } else if (this.type === 19) {
        this.$router.push({
          name: "keyTouchTimerShow",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    ClickEdit() {
      if (this.relay8type.type == 16) {
        this.$router.push({
          name: "noneHomeRelayEdit",
          params: {serial_number: this.serial_number},
        });
      } else if (this.type == 19) {
        this.$router.push({
          name: "KeyTouchEdit",
          params: {serial_number: this.serial_number},
        });
      }
    },
    ClickEditRGB() {
      this.$router.push({
        name: "EditRGB",
        params: {serial_number: this.serial_number},
      });
    },
    ClickCLock() {
      this.$router.push({
        name: "noneSetClock", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickResetFactory() {
      this.CmpResetShow = true;
      $("#modal-reset").modal('show');
    },
    goBackSetting() {
      this.$router.push({name: "GatewayNoneInfo", params: {serial_number: this.serial_number},});
    },
    ClickManage() {
      if (this.relay8type.type=== 16) {
        localStorage.setItem("type", 16)
        this.$router.push({
          name: "noneHomeClientInfo",
          params: {serial_number: this.serial_number, type: 16},
        });
      } else if (this.type === 19) {
        localStorage.setItem("type", 19)
        this.$router.push({
          name: "noneHomeClientInfo",
          params: {serial_number: this.serial_number, type: 19},
        });
      }
    },
    ClickDelete() {
      this.showDelDialog(this.serial_number);
    },
    showDelDialog(sn) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
              this.resetRelay(sn);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicToggleReceive(
          this.serial_number,
          256
      );
      this.$mqtt.subscribe(topic1);
    },
    resetRelay(sn) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.loading = true;
      let saction = "RESET#";
      var topic_final = getTopics.getTopicResetSend(
          this.serial_number,
          256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            self.delRelay(sn)
          },
          function (errr) {
            //errr
          }
      );
    },
    delRelay(serial_number) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      this.openModalLoading()
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneDeviceDel;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.closeModalLoading();
                self.$router.push({
                  name: "mainpage"
                })
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == 'back') {
        if (this.relay8type.type == 16)
          this.$router.push({name: "noneSubRelayItem", params: {serial_number: this.serial_number},});
        else if (this.type == 19) {
          this.$router.push({
            name: "keyTouchNoneInfo",
            params: {serial_number: this.serial_number},
          });
        }
      }
    },
    ToggleRelay8(){
      this.showToggleDialog();
    },
    showToggleDialog() {
      let self=this;
      this.$swal({
        // title: this.$t("message.public.delete"),
        text: this.$t("message.relay.toggle.toggle_dialog_txt_descript"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.toggleBtnDialogOK,
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              // this.resetRelay(sn);
              // this.onSubscribe(self.serialnumber);
              self.sendToggle();
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );

    },
    sendToggle() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // this.loading = true;
      let saction = getMessageSub.getMessageToggleRelay(this.toggleMode)
      var topic_final = getTopics.getTopicToggleSend(
          this.serial_number,
          256
      );
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            self.getRelaysItem();
            setTimeout(function () {
              self.closeModalLoading();
              if (!self.connection) {
                // ErrorToastMsg.Msg();
              }
            }, 4000);
            self.connection = false;
          },
          function (errr) {
            //errr
            self.closeModalLoading();
          }
      );
    },
    toggleShowStatus() {
      if (this.toggleMode === 1) {
        this.toggleBtnDialogOK = this.$t(
            "message.relay.toggle.toggle_status_chassis"
        );
      } else if (this.toggleMode === 0) {
        this.toggleBtnDialogOK = this.$t(
            "message.relay.toggle.toggle_status_always"
        );
      }
      if (this.toggleMode === 1) {
        this.toggleStatus = this.$t(
            "message.relay.toggle.toggle_status_always"
        );
      } else if (this.toggleMode === 0) {
        this.toggleStatus = this.$t(
            "message.relay.toggle.toggle_status_chassis"
        );
      }
    },
    getRelaysItem() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      let URL = getUrl.noneRelayItems;
      const p = postAjaxCall(URL, data, "");
      // this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.toggleMode=jsonArray.content.toggle_mode
          self.toggleShowStatus();
          self.closeModalLoading();
          // self.toggleShowStatus();
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
  },
};
</script>


<style scoped>
</style>
