<template>
<v-app class="bg">
  <CmpUpdate v-if="finish" :data_txt="time[0] +' '+ time[1]" :data_type="'default'" class="mt-3 align-self-center"
               @refresh="getTime"></CmpUpdate>
  <section class="px-1" >
  <TimerList :isShow="isShow" :length="length" :infos="info" @addClock="addClock()" @addTimer="addTimer()"
             @deleteTimer="deleteTimer"/>
  <my-modal v-show="isModalVisible"></my-modal>
 
  </section>
</v-app>
</template>
<script>
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import TimerList from "../../../Cmp/timer/show/TimerList";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {SetClock, Tools} from "../../../../../public/assets/js/utils/util";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpUpdate from "../../../Cmp/public/CmpUpdate.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";



var connection = false;
export default {
  name: "NThermostatTimerShow",
  components: {
    TimerList,
    CmpUpdate
  },
  props: {
    serial_number: {},
    click: {}
  },
  data: () => ({
    info: "",
    length: "",
    lang: '',
    count_timer: 0,
    time: '',
    finish: false,
    isShow: false,
    title: '',
    isModalVisible: false,
  }),
  created() {
   
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mqtt: {
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.setTime((String(data).split("-")))
        connection = true;
      }
    },
  },
  mounted() {
    let self = this;
    this.lang = localStorage.getItem("Lang");
    let details = {
      title: this.$t('message.timer.scheduler_show'),
      menu:true,
      menuAdd:true,
      refresh: true,
      delAll:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'NThermostatTimerShow');
    setTimeout(function () {
      self.lastStatus();
      self.getTime();
    }, 100);
  },
  methods: {
    lastStatus() {
      let self = this;
      this.info = '';

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;

      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.noneTimer;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              self.length = content.length;
              var len = content.length;
              if (len == 0) {
                self.isShow = true;
              }
              content.forEach(function (item) {
                const data = getTimerData(item.message);

                finaldata.push(data);
                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    setTime(time) {
      time[1] = time[1].substring(0, 1);
      time[0] = time[0].substring(1,);
      this.time = time;
      switch (time[1]) {
        case "1":
          this.time[1] = this.$t("message.timer.monday");
          break;
        case "2":
          this.time[1] = this.$t("message.timer.tuesday");
          break;
        case "3":
          this.time[1] = this.$t("message.timer.wednesday");
          break;
        case "4":
          this.time[1] = this.$t("message.timer.thursday");
          break;
        case "5":
          this.time[1] = this.$t("message.timer.friday");
          break;
        case "6":
          this.time[1] = this.$t("message.timer.saturday");
          break;
        case "7":
          this.time[1] = this.$t("message.timer.sunday");
          break;
      }
      this.finish = true;
    },
    getTime() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      var topic_final = getTopics.getTopicToggleSend(this.serial_number, "256");
      let message = "DCTN?"
      var data = {
        user_id: user_id,
        token: token,
        message: message,
        topic: topic_final,
        serial_number: serial_number,
        count: 256,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              self.subcribeTime();
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: this.$t('message.timer.timer'),
            text: this.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: this.$t('message.public.delete'),
            cancelButtonText: this.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {
              let message = "TIMER" + counttimer + "-OFF#";
              self.sendDelete(message,'item');
            }
          }
      );
    },
    sendDelete(message,type) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");  

      var topic_final="";

      type=="item"? topic_final = getTopics.getTopicTimerSend(this.serial_number, '256'):
                    topic_final = getTopics.getTopicTimerSend(this.serial_number, '256');

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                  self.exitModalLoading();
                }
              }, 10000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic);  
    },
    subcribeTime(){
      var topic1 = getTopics.getTopicToggleReceive(this.serial_number, 256);
      this.$mqtt.subscribe(topic1);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();

      let device_serial = this.serial_number;
      swal({
        title: this.$t('message.timer.timer'),
        text: this.$t('message.timer.timer_delete'),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      self.lastStatus();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");

      var topic_final = getTopics.getTopicClockSend(this.serial_number, '256');

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000)
            } else {
              //errork
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingNoneThermostat",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "refresh") {
        this.lastStatus();
      }
      if (value == "add") {
        this.$router.push({
          name: "NThermostatTimer",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "delAll") {
        let self = this;
    swal(
      {
      title: this.$t('message.timer.timer'),
      text: this.$t('message.timer.timer_isDeleteAll'),
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: this.$t('message.public.delete'),
      cancelButtonText: this.$t('message.public.cancel'),
      closeOnConfirm: true,
      closeOnCancel: true,
    },
    function (isConfirm) {
      if (isConfirm) {
        let message = "TIMERALL-OFF#";
        self.sendDelete(message);
      }
    }
);
        
      }
    },
    addTimer() {
      this.$router.push({
        name: "NThermostatTimer",
        params: {serial_number: this.serial_number},
      });
    }
  }
}
</script>

<style scoped>

</style>