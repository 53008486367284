<template>
  <v-card class="mt-2" @click="selectRow(infoItem.index)" :ripple="ripple">
    <v-list subheader two-line :class="bgList">
      <v-list-item>
        <v-list-item-avatar rounded>
         <div>
          <v-img v-if="img"
              lazy-src="image"
              :src="infoItem.img"
              contain
              aspect-ratio="1"
              max-height="40"
          ></v-img>
          <v-icon v-if="icon">{{ infoItem.icon}}</v-icon>
         </div>
        </v-list-item-avatar>
        <v-list-item-content class="mt-2">
          <v-list-item-title>
            <h6 class="text-bold">{{ infoItem.title }}</h6>
          </v-list-item-title>
        
          <v-list-item-subtitle v-if="subTitleShow">
            <label class="text-regular text-primary" style="font-size: 12px">
              {{ infoItem.subTitle }}
              </label
            >
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="captionShow">
            <label class="text-regular text-grey" style="font-size: 11px">
              {{ infoItem.caption }}
              </label
            >
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-spacer></v-spacer>
        <slot></slot>
        <v-list-item-action v-if="infoItem.actions == 'icon'">
          <v-btn icon @click="iconClick(index)">
            <v-icon class="text-danger mr-2">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
        <v-menu bottom left v-if="infoItem.actions == 'menu'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, i) in items" :key="i">
              <v-list-item-title @click="selectSection(i, infoItem.index)">
                <v-icon class="px-2">{{ item.icon }}</v-icon>

                <label class="px-2"> {{ item.title }}</label>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-if="infoItem.actions == 'switch'">
          <v-switch
              v-model="infoItem.switch"
              inset
              @click="switchclick(infoItem.switch, infoItem.index)"
          ></v-switch>
        </div>
        <div v-if="actions === 'checkBox'">
          <v-checkbox
              v-model="infoItem.active"
              class="mt-6"
          ></v-checkBox>
        </div>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import ActionSwitch from "./ActionSwitch";

export default {
  props: {
    index: "",
    infoItem: [],
    items: [],
    type: {},
    ripple: {
      default: true,
    },
    icon:{
      default: false,
    },
    img:{
      default: true,
    },
    menuShow: {
      default: true,
    },
    subTitleShow: {
      default: false,
    },
    captionShow: {
      default: false,
    },
    bgList: {
      default: "bg-white",
    },
    actions: "",
  },
  components: {
    ActionSwitch,
  },
  data() {
    return {
      switch1: false,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    selectSection(i, index) {
      switch (i) {
        case i:
          this.$emit("clicked", {
            itemId: i,
            index: index,
          });
          break;
      }
    },
    selectRow(index) {
      this.$emit("clickedRow", index);
    },
    switchclick(status, index) {
      this.$emit("clicked", {
        status: status,
        index: index,
      });
    },
    iconClick(index) {
      this.$emit("clicked", index);
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}

.v-list-item__content {
  overflow: visible;
}
</style>
