<template>
  <v-app class="bg">
    <div class="mt-5">
      <section class="content">
        <div class="media-list media-list-divided media-list-hover">
          <div class="media align-items-center setting">
            <i class="mdi mdi-barcode mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</p>
           
                <h2 class="mt-2 text-primary">
                {{serial_number}}

                </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    serial_number: {},
    click: {}
  },
  data() {
    return {
      title: "",
    };
  },
  mounted() {
    this.title = this.$t('message.AppBar.about_device');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'NThermostatic');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingNoneThermostatic",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
