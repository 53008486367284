<template>
  <v-app class="bg">
    <v-container class="mt-2">
      <div v-if="emptyShow" class="mt-50">
        <CmpEmptyData
            :btnShow="true"
            :txtBtn="$t('message.relay.add.add_relay')"
            @clicked="clickAddRelay"
        ></CmpEmptyData>
      </div>
      <v-row>
        <v-col
            lg="6"
            class="py-0"
            :key="index" v-for="(subBoxRelay, index) in subBoxRelays">
          <CmpRowList
              :infoItem="subBoxRelay"
              :items="itemsMenu"
              :captionShow="true"
              :subTitleShow="true"
              @clickedRow="getIndexRowSub"
              @clicked="clickItemMenu"
          ></CmpRowList>
        </v-col>
      </v-row>
      <my-modal v-show="isModalVisible"></my-modal>
      <Modal8RelayResetFactory
          :infoItem="dataReset"
          v-if="CmpResetShow"
      ></Modal8RelayResetFactory>
    </v-container>
  </v-app>
</template>

<script>
import CmpEmptyData from "../../Cmp/public/CmpEmptyData.vue";
import ActionSwitch from "@/components/Cmp/ActionSwitch.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import Modal8RelayResetFactory from "./Modal8RelayResetFactory.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {getMessageSub} from "../../../../public/assets/js/utils/helper_sub_message.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    ActionSwitch,
    CmpEmptyData,
    CmpRowList,
    Modal8RelayResetFactory,
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      ServerInfoSubBox: [],
      itemsMenu: [],
      isModalVisible: false,
      isModalVisibleRest: false,
      emptyShow: false,
      subBoxRelays: [],
      localRelaydata: [],
      sub_serial: "",
      dataReset: [],
      CmpResetShow: false,
      toggleBtnDialogOK: "",
      toggleStatus: "",
      toggleMode: "",
      connection: false,
      main_admin: '',
      connection:false,
    };
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.connection = true;
        this.showGetMsg(String(data));
      }
    },
  },
  created() {
    this.$store.dispatch("infoHome");
    let data = {
      title: this.$t('message.relay.relay_list')
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'BoxRelay');
    this.main_admin = this.$store.getters.getadmin;
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted: function mounted() {
    try {
      let self = this;
      setTimeout(function () {
        self.getHomeRelays();

      }, 50);
    } catch (err) {
    }
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    toggleShowStatus() {
      if (this.toggleMode === 1) {
        this.toggleBtnDialogOK = this.$t(
            "message.relay.toggle.toggle_status_chassis"
        );
      } else if (this.toggleMode === 0) {
        this.toggleBtnDialogOK = this.$t(
            "message.relay.toggle.toggle_status_always"
        );
      }
      if (this.toggleMode === 1) {
        this.toggleStatus = this.$t(
            "message.relay.toggle.toggle_status_always"
        );
      } else if (this.toggleMode === 0) {
        this.toggleStatus = this.$t(
            "message.relay.toggle.toggle_status_chassis"
        );
      }
    },
    getHomeRelays() {
      this.subBoxRelays = [];
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      let URL = getUrl.relays;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);

        if (responseAjax(response)) {
          self.ServerInfoSubBox = jsonArray.content.relays;
          if (self.ServerInfoSubBox.length == 0) {
            self.emptyShow = true;
          }
          self.ServerInfoSubBox.forEach(function (item, i) {
            self.toggleMode = item.toggle_mode;


            self.CmpResetShow = true;
            var actions;
            if (self.main_admin == 0) {
              actions = ''
            }
            if (self.main_admin == 1) {
              actions = 'menu'
            }
            self.subBoxRelays.push({
              index: i,
              title: item.name,
              img: item.type.logo_path,
              subTitle:
                  self.$t("message.relay.active_relay_count") +
                  "-" +
                  Array(1).fill("\xa0").join("") +
                  item.relays.length,
              caption: item.serial_number,
              icon: true,
              actions: actions,
            });
          });
          self.toggleShowStatus();
          self.setItemMenuValue();

          self.closeModalLoading();
        } else {
          self.closeModalLoading();
          self.$router.push({
            name: "homeDevices",
            params: {
              home_id: self.home_id,
              serial_number: self.serial_number,
            },
          });
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
    clickItemMenu(value) {
      var sub_serial = this.ServerInfoSubBox[value.index].serial_number;
      localStorage.setItem("infoSubDevice", this.ServerInfoSubBox[value.index].set_password);
      let self = this;
      switch (value.itemId) {
        case 0:
          this.$router.push({
            name: "boxrelayedit",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              sub_serial: sub_serial,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "boxRelayTimerShow",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              sub_serial: sub_serial,
            },
          });
          break;
        case 2:
          this.showToggleDialog(
              self.ServerInfoSubBox[value.index].serial_number
          );
          break;
        case 3:
          this.showDelDialog(self.ServerInfoSubBox[value.index].serial_number);
          break;
        case 4:
          this.dataReset = [];
          this.dataReset.push(
              {serial: self.serial_number},
              {sub_serial: sub_serial}
          );
          $("#modal-reset").modal("show");
          break;
      }
    },
    getIndexRowSub(val) {
      this.sub_serial = this.ServerInfoSubBox[val].serial_number;
      localStorage.setItem("infoSubDevice", this.ServerInfoSubBox[val].set_password);
      this.$router.push({
        name: "subrelayitem",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          sub_serial: this.sub_serial,
        },
      });
    },
    showDelDialog(sn) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.resetRelay(sn);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    showToggleDialog(sub_sn) {
      this.$swal({
        // title: this.$t("message.public.delete"),
        text: this.$t("message.relay.toggle.toggle_dialog_txt_descript"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.toggleBtnDialogOK,
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              // this.resetRelay(sn);
              this.onSubscribe(sub_sn);
              this.sendToggle(sub_sn);

            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );

    },
    delRelay(serial_number) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      this.openModalLoading();
      //-----------------------Request ------------------------------//
      let URL = getUrl.subDelete;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            self.closeModalLoading();
            if (responseAjax(response)) {
              setTimeout(function () {
                self.subBoxRelays = [];
                self.closeModalLoading();
                self.getHomeRelays();
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
            self.closeModalLoading();
          }
      );
    },
    clickAddRelay() {
      localStorage.setItem(
          "infoRelay8oLocal",
          JSON.stringify(this.localRelaydata)
      );
      this.$router.push({
        name: "boxrelayadd",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type_code: 15,
        },
      });
    },
    resetRelay(sub_serial) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // this.loading = true;
      let saction = "RESET#";
      var topic_final = getTopics.getTopicResetSend(
          this.serial_number,
          sub_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            self.delRelay(sub_serial);
          },
          function (errr) {
            self.closeModalLoading();
            //errr
          }
      );
    },
    sendToggle(sub_serial) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let saction = getMessageSub.getMessageToggleRelay(this.toggleMode)
      var topic_final = getTopics.getTopicToggleSend(
          this.serial_number,
          sub_serial
      );
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            setTimeout(function () {
              self.closeModalLoading();
              if (!self.connection) {
                ErrorToastMsg.Msg();
              }
            }, 3000);
            self.connection = false;
          },
          function (errr) {
            //errr
            self.closeModalLoading();
          }
      );
    },
    setItemMenuValue(sub_relay) {
      this.itemsMenu = [];
      var item1 = this.$t("message.public.edit");
      var item2 = this.$t("message.AppBar.scheduler");
      var item3 = this.$t("message.relay.toggle.toggle_setting") + "(" + this.toggleStatus + ")";
      var item4 = this.$t("message.public.delete");
      var item5 = this.$t("message.relay.reset");

      this.itemsMenu.push(
          {title: item1, icon: "mdi-border-color"},
          {title: item2, icon: "mdi-clock"},
          {title: item3, icon: "mdi mdi-settings"},
          {title: item4, icon: "mdi-delete"},
          {title: item5, icon: "mdi mdi-format-rotate-90"},
      );
    },
    showGetMsg(msg) {
      if (msg.startsWith('TOGGLEMODE')) {
        this.getHomeRelays();
        this.closeModalLoading();
      }
    },
    onSubscribe(sub_sn) {
      var topic1 = getTopics.getTopicToggleReceive(
          this.serial_number,
          sub_sn
      );
      this.$mqtt.subscribe(topic1);
    },
  },
};
</script>
<style>
.swal2-modal .swal2-content {
  font-family: "IRANSans";
}

.swal2-modal .swal2-styled {
  font-family: "IRANSans";
}
</style>
