<template>
  <v-app class="bg">
    <div class="px-3 mt-2">
      <v-btn class="btn btn-block" color="bg-blue-light" elevation="1" @click="AddScenarioKeyAction()">
        <v-icon class="text-dark mdi-24px" left> mdi-plus</v-icon>
        <label class="text-dark text-bold">
          {{ $t("message.subDevice.scenarioKey.scenarioKey_add") }}
        </label>
      </v-btn>
      <v-row class="mb-50 mt-1">
        <v-col cols="12" class="py-0" :key="index" v-for="(listItem, index) in localScenarioKeydata">
          <CmpRowList :infoItem="listItem" :subTitleShow="true" :index="index" :ripple="false" @clicked="CmpCallDel">
          </CmpRowList>
        </v-col>
      </v-row>
      <div class="fixed-bottom mb-1 px-4">
        <cmpbutton class="mb-2" :txtBtn="$t('message.public.submit')" :loading="loading" @clickedbtn="getCallBackBtn">
        </cmpbutton>
      </div>
    </div>
    <div>
      <DialogInput v-model="showDialogInput77" :data="scenarios" :dialogtxt="dialogScenarioKeyTxt"
        @clicked="CmpCallScenarioKey" />
    </div>
    <!-- ./Modal Pass-->
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import DialogInput from "@/components/Cmp/DialogInput.vue";
import CmpEmptyData from "../../Cmp/public/CmpEmptyData.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import { BetweenStr } from "../../../../public/assets/js/utils/util.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRow2RF from "@/components/Cmp/CmpRow2RF.vue";
import { getUrl } from "../../../../public/assets/js/utils/helper_url.js";

var connection = false;
var showToast;
var timeout;

export default {
  // name: "Test",
  components: {
    cmpbutton,
    DialogInput,
    CmpRowList,
    CmpEmptyData,
    CmpRow2RF,
    DialogInput
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
    // type_code: {},
  },
  data() {
    return {
      loading: false,
      isModalVisible: false,
      showDialogInput77: false,
      localScenarioKeydata: [],
      key_info: [],
      listnum: [],
      scenarios: [],
      dialogScenarioKeyTxt: [],
      data: [],
      scenario_key: [],
      // sub_sn is subdevice serial number
      sub_sn: ''
    };
  },
  async created() {
    let self = this;
    this.data = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    for (let i = 1; i < 100; i++) {
      this.listnum.push(i)
    }
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);

          this.closeModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    // // init run
    // // try {
    let self = this;
    // this.$store.dispatch("infoScenarioKeyLocal");
    this.$store.dispatch("InfoSubDevice");
    let details = {
      title: this.$t('message.public.configuration')
    }
    localStorage.setItem("AppBar", JSON.stringify(details));

    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Test');
    this.sub_sn = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;

    setTimeout(function () {
      self.getScenaroList();
    }, 100);
    this.initDataServer();
    this.initDilaog();
    // } 
    // catch (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: "در انجام فرایند خطایی رخ داده است ",
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: "تایید",
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }

  },
  watch: {
    click(val) {
      console.log('watch', val)
      this.onClickChild(val);
    }
  },
  methods: {
    SaveRelay() {
      this.key_info = [];
      let self = this;
      // if (
      //   !actionScenarioKeydata(this.localScenarioKeydata)
      // )
        // return;

      this.localScenarioKeydata.forEach(function (item, i) {
        self.key_info.push({
          // name: item.title,
          scenario_msg: item.msg,
          key_count: item.numbers,
          scenario_uid: item.uid,
        });
      });
        this.sendRequest();
    },
    sendRequest() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var home_id = this.home_id;
      var serial_number = this.sub_sn;

      var object = {
        key_info: this.key_info,
      };
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
        scenario_data: JSON.stringify(object),
      };
      //-----------------------Request ------------------------------//
      // let URLss = "https://server.pfas.ir/api/user/home/scenario/key/edit";
      let URL = getUrl.SCENARIOKEYADD;
      const p = postAjaxCall(URL, data);
      this.loading = true;
      // -----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            self.loading = false;
            self.$router.push({
              name: "homeDevices",
              params: {
                home_id: self.home_id,
                serial_number: self.serial_number,

              },
            });
          } else {
            //error
            self.loading = false;
            // self.exitModalLoading();
          }
        },
        //----------------------Err ---------------------//
        function (errr) {
        }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubScenarioKeyInfo",
          params: { home_id: this.home_id, serial_number: this.serial_number, count: this.count },
        });
      }
    },
    getCallBackBtn(msg) {
      this.SaveRelay();
    },
    AddScenarioKeyAction() {
      // this.showDialogInput = true;
      this.showDialogInput77 = true;
    },
    CmpCallScenarioKey(msg) {
      let self = this;

      var text = "";
      var txtScenario = "";


      text += this.$t("message.subDevice.scenarioKey.scenarioKey") + msg.numbers;
      txtScenario += this.$t("message.subDevice.scenarioKey.selected_scenarioKey") + ':' + msg.secnario.name;
      //  this.localScenarioKeydata=[];
      if (this.localScenarioKeydata == null || this.localScenarioKeydata == "") {
        this.localScenarioKeydata = []
      }
      this.localScenarioKeydata.push({
        title: text,
        img: msg.logo_path,
        subTitle: txtScenario,
        icon: true,
        actions: "icon",
        numbers: msg.numbers,
        uid: msg.secnario.uid,
        msg: msg.secnario.msg
        // type_code: msg.type,
      });
      localStorage.setItem("infoScenarioKeyLocal", JSON.stringify(this.localScenarioKeydata));
    },
    CmpCallDel(index) {
      this.showDelDialog(index);
    },
    showErrEqaulDialog(uid) {
      this.$swal({
        title: this.$t("message.public.error"),
        text: this.$t("message.relay.add.err_add_equal"),
        type: "error",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.OK"),
        // cancelButtonText: this.$t("message.public.cancel"),
      }).then(
        (result) => {
          if (result) {
            // this.delScenario(uid);
          }
        }
        // (dismiss) => {
        //   if (dismiss === "cancel") {
        //   } else {
        //     throw dismiss;
        //   }
        // }
      );
    },
    showDelDialog(index) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.subDevice.scenarioKey.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
        (result) => {
          if (result) {
            this.localScenarioKeydata.splice(index, 1);
            localStorage.setItem(
              "infoScenarioKeyLocal",
              JSON.stringify(this.localScenarioKeydata)
            );
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          } else {
            throw dismiss;
          }
        }
      );
    },
    getScenaroList() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      self.scenarios = [];
      self.senarioServerInfo = [];
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      this.openModalLoading();
      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarios;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            let item;
            for (item in jsonArray.content.scenarios) {
              self.senarioServerInfo.push(jsonArray.content.scenarios[item].scenario);
            }
            self.closeModalLoading();
            if (self.senarioServerInfo.length == 0) {
              self.emptyShow = true;
            }
            self.senarioServerInfo.forEach(function (item, i) {
              var nextTodoId = -1;
              self.scenarios.push({
                index: i + nextTodoId + 1,
                name: item.name,
                uid: item.uid,
                msg: item.msg
              });
            });
          } else {
            //error
            self.closeModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //----------------------Err ---------------------//
        function (errr) {
        }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    initDilaog(){
      this.dialogScenarioKeyTxt.push({
      header: this.$t('message.subDevice.scenarioKey.dialog.header'),
      caption: this.$t('message.subDevice.scenarioKey.dialog.caption'),
      input1_title: this.$t('message.subDevice.scenarioKey.dialog.input1_title'),
      input1_hint: this.$t('message.subDevice.scenarioKey.dialog.input1_hint'),
      input2_title: this.$t('message.subDevice.scenarioKey.dialog.input2_title'),
      input2_hint: this.$t('message.subDevice.scenarioKey.dialog.input2_hint'),
    })
    },
    initDataServer(){
      var jsondataKey = JSON.parse(this.data.scenario_keys).key_info
      let self=this;
      jsondataKey.forEach(function (item, i) {
        self.localScenarioKeydata.push({
          title: self.$t("message.subDevice.scenarioKey.scenarioKey") + item.key_count,
          img: item.scenario_icon,
          subTitle: item.scenario_name,
          icon: true,
          actions: "icon",
          numbers: item.key_count,
          uid: item.scenario_uid,
          msg: item.scenario_msg
          // type_code: msg.type,
        });
      });
    }
  },
};
</script>

<style scoped>

</style>