<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick" @back="goBackTimer" :isFinished="isFinished"/>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var count;

export default {
  name: "IRTimer",
  components: {
    Timer
  },
  data: () => ({
    data: {},
    isFinished: false,
    isModalVisible: false
  }),
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  created() {
    this.data = {
      items: [],
      type: "IR"
    }
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, 1000);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    getData() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.serial_number;
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      let URL = getUrl.controlsInfo;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.closeModalLoading();
          // console.log(jsonArray);
          let selfData = jsonArray.content.control;
          selfData.forEach(function (item, i) {
            if (item.learned == 0)
              self.data.items.push({
                index: i,
                title: item.name,
                img: item.logo_path,
                subTitle: item.type,
                icon: true,
                actions: "",
                switch: false,
                numbers: 0,
                keys: item.key_info,
                uid: item.uid,
                count: item.count,
                learned: item.learned,
                type: item.type_code,
              });
          })
          // self.data.items = jsonArray.content.control;
          self.cmpShow = true;
          console.log(self.data);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
        self.isFinished = true;
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClick(index, item) {
      console.log(index, item);
      const dataircontrols = {
        name: item.name,
        uid: item.uid,
        type_code: item.type,
      };
      localStorage.setItem("infoIrControls", JSON.stringify(dataircontrols));
      if (item.keys.length > 0)
        switch (item.type) {
          case 1001:
            this.$router.push({
              name: "IrTvTimer",
              params: {serial_number: this.serial_number, home_id: this.home_id, count: item.count}
            });
            break;
          case 1002:
            this.$router.push({
              name: "IrAmplifierTimer",
              params: {serial_number: this.serial_number, home_id: this.home_id, count: item.count}
            });
            break;
          case 1003:
            this.$router.push({
              name: "IrAirTimer",
              params: {serial_number: this.serial_number, home_id: this.home_id, count: item.count}
            });
            break;
          default:
            break;
        }
      else
        swal({
          title: this.$t('message.timer.no_key'),
          text: this.$t('message.timer.no_key_text'),
          icon: "warning",
          dangerMode: true,
        });
      // this.next1(status);
    },
    goBackTimer() {
      this.$router.push({
        name: "IRTimerShow",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    }
    ,
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({
          name: "IRTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },

}
</script>

<style scoped>

</style>