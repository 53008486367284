<template>
  <v-app>
    <v-stepper v-model="e1" editable >
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
        color="bg-primary"
        @click="test()"
      >
        step 0
      </v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step step="2"
     >
        step 1
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          class="mb-12" height="450px">
        <section class="content">
          <v-row>
            <v-col cols="12">
                          <h6>
                            {{ $t('message.public.email') }}
                            <span class="text-danger">*</span>
                          </h6>
                          <input
                              id="inputsubfarahoushpol"
                              type="text"
                              class="form-control"
                              aria-invalid="false"
                              autocomplete="off"
                              maxlength="32"
                              :placeholder="$t('message.loginEmail.inputEmail')"
                              v-model="new_email"
                          />
                        <div class="row fixed-bottom mb-5 px-4">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="changeEmailFunction()"
                            >
                              <span class="text-white"> {{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                          </div>
            </v-col>
          </v-row>
        </section>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          class="mb-12" height="450px">
        <section class="content">
          <v-row>
            <v-col cols="12">
                          <h6>
                            {{ $t('message.client.OK') }}
                            <span class="text-danger">*</span>
                          </h6>
                          <input
                              id="inputsubfarahoushpol"
                              type="text"
                              class="form-control"
                              aria-invalid="false"
                              autocomplete="off"
                              maxlength="32"
                              :placeholder="$t('message.loginVerify.inputCode')"
                              v-model="verify_code"
                          />
                        
                        <div class="row fixed-bottom mb-5 px-4">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="changeEmailVerifyFunction()"
                            >
                              <span class="text-white"> {{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                          </div>
            </v-col>
          </v-row>
        </section>
        </v-card>

      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>

  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {
  EmailValidationInput ,
  smsCodeValidationInput,
} from "@/../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";


export default {
  // components:{
  //   ModalCenter,

  // },
  props: {
    id: {},
    new_user: {},
    click: {},
  },
  data: () => ({
        user_id:"",
        token:"",
        isModalVisible:false,
        new_email:'',
        verify_id:'',
        e1: 1,
        loading:false,
        verify_code:''
    // UserID: this.$route.params.userID,
  }),
  mounted() {
    //----------------- Language
    let details = {
      title: this.$t('message.profile.change_email'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ProfileChangeEmail');

    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");


    let self = this;

    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
  },
  watch:{
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({ name: "profile" });
      }
    },
    test(){
     this.e1=1
    },
    step1(){
     
      this.e1=2
    },
    changeEmailVerifyFunction() {
      let self = this;
      if (!smsCodeValidationInput(this.verify_code))
        return;

      var data = {
        user_id:this.user_id,
        token:this.token,
        verify_id: this.verify_id,
        verify_code: this.verify_code,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.profileEmailChangeVerify;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModal();

              let jsonArray = JSON.parse(response.responseText);
             
              console.log("jsonArray",jsonArray)
              self.$router.push({
               name: "profile",
                });
            } else {
              self.closeModal();
            }
          },
          //--------------------------------------Err --------------------------------------//

          function (errr) {

            setTimeout(function () {
              self.closeModal();
            }, 1000);
          }
      );
    },
    changeEmailFunction() {
      let self = this;
      if (!EmailValidationInput(this.new_email))
        return;

      var data = {
        user_id:this.user_id,
        token:this.token,
        new_email:this.new_email

      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.profileEmailChange;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModal();
              let jsonArray = JSON.parse(response.responseText);
              self.verify_id=jsonArray.content.verify_id
              self.e1=2;
            } else {
              self.closeModal();
            }
          },
          //--------------------------------------Err --------------------------------------//

          function (errr) {

            setTimeout(function () {
              self.closeModal();
            }, 1000);
          }
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
     this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
</style>
