import i18n from "../../../../src/i18n";
export function getControlamplifierStatus(message,version) {
    var  type='';
    if(version=='V1'){
        switch(message) {
            case 0:
                type='mdi mdi-power';
              break;
            case 1:
                type='mdi mdi-volume-off';
              break;
            case 2:
                type='mdi mdi-menu';
              break;
            case 3:
                type='mdi mdi-plus';
              break;
            case 4:
                type='mdi mdi-minus';
              break;
            case 5:
                type='mdi-checkbox-marked-circle';
              break;
            case 6:
                type='ti-angle-up';
              break;
            case 7:
                type='ti-angle-down';
              break;
            case 8:
                type='ti-angle-left';
              break;
            case 9:
                type='ti-angle-right';
              break;
            case 10:
                type='mdi mdi-keyboard-return';
              break;
            case 11:
                type='mdi mdi-play';
              break;
            case 12:
                type='mdi mdi-pause';
              break;
            case 13:
                type='mdi mdi-step-backward';
              break;
            case 14:
                type='mdi mdi-stop';
              break;
            case 15:
                type='mdi mdi-step-forward';
              break;

            default:
              // code block
          }
      return type;   
    } 
}