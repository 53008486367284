<template>
  <!-- .wrapper  -->
  <v-app class="bg mr-0">
    <div class="mr-0">
      <!-- Main content -->
      <section class="content">
        <div class="row mt-20 px-20" id="app">
          <div class="col-12">
            <!-- .row device-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 py-0">
                <div
                    class="media align-items-center bg-white rounded"
                >
                  <img
                      class="avatar text-primary"
                      src="/assets/images/smart/device.svg"
                      width="24px"
                      height="24px"
                      alt="..."
                  />
                  <div class="media-body">
                    <p><strong>{{ this.$store.getters.getNameNoneHome }}</strong></p>
                    <br><span>{{ serial }}</span>
                  </div>
                
                  <label class="switch">
                    <input
                        name="checkoutlet"
                        id="checkoutlet"
                        type="checkbox"
                    />
                    <span class="switch-indicator"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- .row lahzeie-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 ">
                <div
                    class="media align-items-center bg-white mb-5 py-20 rounded"
                >
                  <i class="mdi mdi-timer-sand mdi-24px text-primary"></i>
                  <div class="media-body">
                    <p><strong>{{ $t('message.AppBar.instantSettings') }}</strong></p>
                    <p></p>
                  </div>
                  <VueLoadingButton
                      @click.native="MyFunctionPower()"
                      class="btn btn-circle btn-primary btn-sm  mr-15 "
                      :loading="isLoadRefresh"
                      :style="isStyled"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </VueLoadingButton>
                </div>
              </div>
            </div>
            <!-- .row report-->
            <div class="row mt-0">
              <div class="col-lg-12 col-12 py-0">
                <!-- Default box -->
                <div class="box">
                  <div class="box-header with-border">
                    <h4 class="box-title">{{ $t('message.RelayKey.reports') }}</h4>
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-12">
                        <div class="text-center py-10 bb-1">
                          <h4>{{ $t('message.RelayKey.last_update') }}</h4>
                          <label id="clock" style="font-size: 16px"
                          >00.00.00</label
                          >
                          <ul class="flexbox flex-justified text-center my-20">
                            <li class="px-10">
                              <h6 class="mb-0 text-bold">{{ $t('message.RelayKey.I') }}</h6>
                              <label
                                  id="amp"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>

                            <li class="br-1 bl-1 px-10">
                              <h6 class="mb-0 text-bold">{{ $t('message.RelayKey.T') }}</h6>
                              <label
                                  id="temp"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>

                            <li class="px-10">
                              <h6 class="mb-0 text-bold">{{ $t('message.RelayKey.V') }}</h6>
                              <label
                                  id="volt"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <br/>
                    </div>
                    <div class="d-flex justify-content-center mt-20">
                      <VueLoadingButton
                          @click.native="ATVC()"
                          class="btn btn-primary mb-5"
                          :loading="isLoading"
                          :style="isStyled"
                      >
                        {{ $t('message.public.update') }}
                      </VueLoadingButton>
                    </div>
                  </div>
                  <!-- /.box-body -->
                </div>
                <!-- /.box -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>
    <div class="modal center-modal fade" id="modal-pass-outlet" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('message.public.security') }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>{{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
              <input
                  type="text"
                  class="form-control"
                  required=""
                  aria-invalid="false"
                  maxlength="4"
                  autocomplete="off"
                  id="idpassoutlet"
                  :placeholder="$t('message.ModalAddHome.password_hint')"
                  v-model="passoutlet"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassCode()"
                >
                  {{ $t('message.public.send') }}
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>
<script>
import {BetweenStr} from "../../../public/assets/js/utils/util.js";
import {SucessToastMsg, ErrorToastMsg, SucessToast, Internets} from "../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import VueLoadingButton from "vue-loading-button";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {msgOutlet} from "../../../public/assets/js/utils/msg_handler_outlet";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

var topic1;
var topic2 = "ok";
var tapp = localStorage.getItem("t_app");
var switch_status = "P1OFF#";
var showToast;
var connection = false;


export default {
  components: {
    VueLoadingButton
  },
  props: {
    serial_number: {},
    click: {},
  },
  data() {
    return {
      isModalVisiblePass: false,
      isShowOutlet: false,
      isModalVisible: false,
      serial: this.serial_number,
      passoutlet: "",
      isLoading: false,
      isStyled: true,
      isLoadRefresh: false,
    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsg(data + "");
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
    "+/+/receive/256/atvc"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsgATVC(data + "");
      }
    },
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    let data = {
      title: this.$store.getters.getNameNoneHome,
      noneHome: true,
      noneHome_setting: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneHomeRelyKey');
  },
  mounted() {
    let self = this;

    setTimeout(function () {

      self.lastStatusMqtt();
      self.ATVC();
    }, 300);

    var checkbox = document.getElementById("checkoutlet");
    var setting_relay = document.getElementById("btn_setting");
    var connection = false;
    var msg_relay;
    //----------------------------------- Storage-----------------------------------
    var status_admin = localStorage.getItem("status_admin");
    var set_password = localStorage.getItem("set_password");
    var tapp = localStorage.getItem("t_app");
    localStorage.setItem("count", "256");

    if (status_admin == 0) {
      // timer_relay.style.visibility = "hidden";
      setting_relay.style.visibility = "hidden";
    }


    var topic1 = getTopics.getTopicActionReceive(this.serial_number, '256');
    var topic2 = getTopics.getTopicAtvcReceive(this.serial_number, '256');

    this.$mqtt.subscribe(topic1);
    this.$mqtt.subscribe(topic2);


    checkbox.addEventListener("change", function () {

      if (checkbox.checked) {
        // switch_status = true;

        switch_status = "P1ON#";
        if (set_password == 1) {

          $("#modal-pass-outlet").modal("show");
        } else {
          self.switchAction("P1ON#", "");
        }
      } else {
        switch_status = "P1OFF#";

        if (set_password == 1) {

          $("#modal-pass-outlet").modal("show");

        } else {
          self.switchAction("P1OFF#", "");
        }
      }
    });
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    switchAction(saction, password) {
      let self = this;
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      // err.innerHTML = "";
      var topic_final = getTopics.getTopicActionSend(this.serial_number, '256');

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      showToast = 1;
      self.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {

          var jsonArray = JSON.parse(response.responseText);


          $("#modal-pass-outlet").modal("hide");

          setTimeout(function () {
            self.exitModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
              setTimeout(function () {
                // location.reload();
                var checkbox = document.getElementById("checkoutlet");
                checkbox.checked = !checkbox.checked;
              }, 3000);
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {

          if (response.status == 401) self.$router.push({name: "home"});
          //error
        }
      });
    },
    MyFunctionPower() {
      this.isLoadRefresh = true;
      let self = this;
      setTimeout(function () {
        self.isLoadRefresh = false;
      }, 3000);
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      // img_delay.src="public/src/image/power_delay_on.png";
      var topic_final;
      let relay_delay = localStorage.getItem("relay_delay" + this.serial_number);

      if (relay_delay == null || relay_delay == "") {
        relay_delay = "01";
      }
      let ssaction = "P1:" + relay_delay + "S#";
      var topic_final = getTopics.getTopicDelaySend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: ssaction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);
          SucessToast.delay();
          //    setTimeout(function () {
          //   swal("", "پیام با موفقیت ارسال شد", "success");
          // }, 500);
        } else {
          //error
        }
      });
    },
    lastStatusMqtt() {
      let self = this;
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data, "");
      self.openModalLoading();
      p.then(function (response) {
        if (responseAjax(response)) {
          self.exitModalLoading();
          var checkbox = document.getElementById("checkoutlet");
          var jsonArray = JSON.parse(response.responseText);
          if (jsonArray.content.message == "NOTSET") {
            checkbox.checked = true;
          }
          var msg_relay = jsonArray.content.message;
          msgOutlet(msg_relay, checkbox);

        } else {
        }
      });
    },
    showGetMsg(msg) {
      var checkbox = document.getElementById("checkoutlet");
      msgOutlet(msg, checkbox);
    },
    ATVC() {
      this.isLoading = true;
      let self = this;
      setTimeout(function () {
        self.isLoading = false;
      }, 3000);
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicAtvcSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: "ATVC?",
        client_id: "atv" + Date.now(),
      };
      // --------------------Requsest----------------------//
      
      let URL = getUrl.nonemqttRequest;

      const p = postAjaxCall(URL, data, "");
      // loading_atvc.style.display = "block";
      // loading.style.display = "hidden";
      // main_report.style.visibility = "hidden";
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.message;

            } else {
              //error
            }
          },
          function (errr) {
            // loading.style.visibility = "hidden";
            let jsonArray = JSON.parse(errr);
            // err.innerHTML = jsonArray.message;
            // EnableItem();
          }
      );
    },
    showGetMsgATVC(msg) {

      var amp = document.getElementById("amp");
      var temp = document.getElementById("temp");
      var volt = document.getElementById("volt");
      var clock = document.getElementById("clock");

      var A = BetweenStr.getBetweenStr(msg, "A=", "~T");
      var T = BetweenStr.getBetweenStr(msg, "~T=", "~V");
      var V = BetweenStr.getBetweenStr(msg, "~V=", "~C");
      var C = BetweenStr.getBetweenStr(msg, "~C", "#");

      amp.textContent = A[1];
      temp.textContent = T[1];
      volt.textContent = V[1];
      clock.textContent = C[1];
    },
    editNameOutlet() {
      $("#modal-edit-outlet").modal("show");
    },
    outletPassShow() {
      this.isShowOutlet = true;
    },
    openModalPass() {
      this.isModalVisiblePass = true;

    },
    closeModalPass() {
      this.isModalVisiblePass = false;

    },
    sendPassCode() {
      var msg;


      $("#modal-pass-outlet").modal("hide");
      this.switchAction(switch_status, this.passoutlet);
      this.passoutlet = "";
    },
    statusPass() {
      $("#modal-pass-status-outlet").modal("show");
    },
    goBackRelay() {
      this.$router.push({name: "mainpage"});
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "mainpage"});
      }
      if (value == 'del') {
        $("#modal-del-sub").modal("show");
      }
      if (value === 'noneHomeSetting') {
        this.$router.push({
          name: "SettingsNone",
          params: {
            serial_number: this.serial_number,
          },
        });
      }

    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },

  },
};
</script>


<style scoped>
</style>
