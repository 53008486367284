<template>
  <v-app>
    <v-row style="width: 100%; place-content: center" class="d-flex align-self-center">
      <v-col cols="6" class="d-flex flex-column" style="place-items: center">
        <img
            src="/assets/images/smart/vector-edit-name.svg"
            class="avatar-smart"
        />
        <h5 class="font-weight-bold">
          {{ $t('message.profile.edit') }}
        </h5>
      </v-col>
      <br/>
      <v-col cols="12">
        <form novalidate>
          <div class="form-group">
            <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
            <input
                type="text"
                class="form-control"
                required=""
                aria-invalid="false"
                maxlength="32"
                autocomplete="off"
                id="inputeditnamesub"
                :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
            />
          </div>
          <h6>{{ $t('message.ModalAddSubDevicePol.pols') }}<span class="text-danger">*</span></h6>
          <div class="form-group" v-for="(name,index) in data.labels" :key="index">
            <input
                v-if="index < data.pol"
                type="text"
                class="form-control"
                required=""
                aria-invalid="false"
                v-model="data.labels[index].label"
                maxlength="32"
                autocomplete="off"
            />
          </div>
          <!-- /.col -->
          <div class="col-12 text-center">
            <v-btn
                class="btn btn-info btn-block"
                :loading="loading"
                :disabled="loading"
                color="bg-primary"
                @click="myFunction()"
            >
              <span class="text-white"> {{ $t('message.public.submit') }} </span>
            </v-btn>
          </div>
          <!-- /.col -->
        </form>
      </v-col>
      <!-- /.col -->
    </v-row>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-app>  <!-- ./wrapper -->
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      title: '',
      loading: false,
      data: {
        labels: [],
      },
    };
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    this.title = this.$t('message.subDevice.edit')
    this.$store.dispatch("InfoSubDevice");
    document.getElementById("inputeditnamesub").value = this.$store.getters.getNameSub
    this.data = JSON.parse(localStorage.getItem("data_edit_sub"))
    console.log(this.data);
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'CmpEdit');
  },
  methods: {
    onClickChild(value) {
      console.log("value", value)
      if (value == "back") {
        if (this.data.type === "2RF")
         {
          this.$router.push({
            name: "SubKeyTouchFaraInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          })
         }
        else if (this.data.type === "RF")
         {
          this.$router.push({
            name: "SubKeyTouchInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          })
         }
         else if (this.data.type === "PRO")
         {
            if(this.data.type_code=="30"){
              this.$router.push({
            name: "TouchProSetting",
            params: {
              serial_number: this.serial_number,
              count: this.count,
            },
          })
            }
            if(this.data.type_code=="32"){
              this.$router.push({
            name: "SubCurtainProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          })
            }
         }
      }
    },
    CancelFunction() {
    },
    myFunction() {
      var name = document.getElementById("inputeditnamesub").value
      let self = this;
      console.log(name)
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      var home_id = this.home_id;
      var serial_number = this.serial_number;
      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;
      let labels = JSON.stringify(this.data.labels)
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        labels: labels,
        serial_number: subdevice_serial,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data, "");
      // loading.style.visibility = "visible";
      //--------------------------------------Response --------------------------------------//

      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              if (self.data.type === "PRO"){
                if(self.data.type_code=="30"){
                  self.$router.push({
            name: "SubKeyTouchProInfo",
            params: {
              home_id: self.home_id,
              serial_number: self.serial_number,
              count: self.count,
            },
          })
            }
              }
              else{
                self.$router.push({name: "homeDevices", params: {home_id: home_id, serial_number: serial_number}});

              }
              //  location.reload();
            } else {
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>


<style scoped>
</style>
