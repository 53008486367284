<template>
  <v-app class="bg">
    <div>
      <div class="row px-20 mt-20">
        <div class="col-lg-12 col-12 py-0">
          <div class="box rounded-16">
            <div class="box-body pb-0">
              <div class="row">
                <div class="col-xl-12 col-md-12 col-12 py-0">
                  <div
                    class="box-footer with-border p-0"
                    v-bind:key="index"
                    v-for="(info, index) in infos"
                  >
                    <CmpRow2RF :info="info">
                      <template v-slot:select>
                         <v-checkbox v-model="info.active" class="mt-0" @click="chkClick(info.id)" ></v-checkbox>
                      </template>
                      <img :src="info.imgStatus" width="40px" height="40px" aspect-ratio="1"  :style="[info.active ? opacityHigh : opacityLow]"/>
                    </CmpRow2RF>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
  </template>
  <script>
  import CmpRow2RF from "@/components/Cmp/CmpRow2RF.vue";
  export default {
    components: {
      CmpRow2RF,
    },
    props: ["infos"],
    
    data() {
      return {
        cmpShow: false,
         opacityLow: {
          opacity:0.2
        },
        opacityHigh: {
         opacity:1
        },
      };
    },
    created() {},
    mounted() {},
    methods: {
      chkClick(id){
           this.infos.forEach(function (item, i) {
             if(item.id!=id){
               item.active=false;
             }
      });
          
      }
    },
  };
  </script>
  <style scoped>
  
  </style>
  