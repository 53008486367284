<template>
  <v-app class="bg">
    <div class="mr-0" >
      <section class="content" v-if="showUI">
        <div class="row mt-30 px-20" id="trall" v-if="polall">
          <div class="col-12 py-0">
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 py-0">
                <div
                    class="media align-items-center bg-white mb-20 py-20 rounded">
                  <img
                      src="/assets/images/smart/switch-off.svg"
                      id="imgall"
                      alt="smart"
                      width="32px"
                      height="32px"
                      :style="resultAll.check ? resultAll.onFilter : resultAll.offFilter"
                  />
                  <div class="media-body">
                    <h6>{{ $t('message.public.all') }}</h6>
                  </div>                
                  <v-switch
                      color="primary"
                      inset
                      v-model="resultAll.check"
                      @click="MyFunctionPower(0, resultAll.check)"
                      id="checkall"
                  ></v-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-20">
          <div class="col-lg-12 col-12 py-0">
            <div class="box rounded-50">
              <div class="box-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-12">
                   
                    <div id="trl1"  v-if="pol1" >
                      <div class="media media-single px-0 py-20">
                         <img
                            src="/assets/images/smart/switch-off.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                            :style="result1.check ? result1.onFilter : result1.offFilter"
                            alt="Show"/>

                       <div class="media-body">  
                          <h6>{{ labels[0].label }}</h6>
                        </div>
                        <v-switch
                            :color="data.onColors[0]"
                            v-model="result1.check"
                            @click="MyFunctionPower(1, result1.check)"
                            inset
                            id="checkL1"
                        ></v-switch>
                      </div>
                    </div>
                    <div id="trl2"  v-if="pol2" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <v-img
                              src="/assets/images/smart/switch-off.svg"
                              max-width="32px"
                              max-height="32px"
                              :style="result2.check ? result2.onFilter : result2.offFilter"
                              id="imgl2"
                          >
                          </v-img>
                          <div class="media-body">
                            <h6>{{ labels[1].label }}</h6>
                          </div>
                          <v-switch
                              :color="data.onColors[1]"
                              v-model="result2.check"
                              @click="MyFunctionPower(2, result2.check)"
                              inset
                              id="checkL2"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl3" v-if="pol3" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl3"
                              width="32px"
                              :style="result3.check ? result3.onFilter : result3.offFilter"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[2].label }}</h6>
                          </div>
                          <v-switch
                              :color="data.onColors[2]"
                              v-model="result3.check"
                              @click="MyFunctionPower(3, result3.check)"
                              inset
                              id="checkL3"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl4" v-if="pol4" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl4"
                              :style="result4.check ? result4.onFilter : result4.offFilter"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[3].label }}</h6>
                          </div>
                          <v-switch
                              :color="data.onColors[3]"
                              v-model="result4.check"
                              @click="MyFunctionPower(4, result4.check)"
                              inset
                              id="checkL4"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ./Modal Pass-->
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {ErrorToastMsg, SucessToastMsg} from "../../../../public/assets/js/utils/util";
import {getTopics} from "../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {Color, hexToRgb} from "../../../../public/assets/js/utils/colorRGB";
import {Solver} from "../../../../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import { getMtt } from "@/../public/assets/js/utils/helper_mtt.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var showToast;
var connection = false;
var timeout;
var base="farahoush";
export default {
  name: "KeyTouch",
  components: {
    CmpPasscode
  },
  props: {
    serial_number: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/+/action" (data, topic) {
      console.log("topic",topic)
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showStatus(String(data))
      }
      SucessToastMsg.Msg();
      //  }
    },
    "+/+/receive/+/setting"(data, topic) {
      // let str = String(data);
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
      }
      SucessToastMsg.Msg();
      //  }
    },
  },
  data() {
    return {
      passkeytouchfara: "",
      data_info_keytouch: [this.serial_number, this.count],
      // main_admin: "",
      title: "",
      isModalVisible: false,
      keytouch_status: "",
      pass: 0,
      pol: 1,
      data: [],
      labels: [],
      info: [],
      rgbInfo:[],
      showUI:false,
      polall:false,
      pol1:false,
      pol2:false,
      pol3:false,
      pol4:false,
      resultAll: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result1: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result2: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result3: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result4: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      set_password: 0,
    };
  },
  created() {
    let self=this;
    // this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    // this.parserRgbSetting();
 
   
    //    this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
    //   onColors: [self.rgbInfo.color[1], self.rgbInfo.color[3], self.rgbInfo.color[5], self.rgbInfo.color[7]],
    //   offColors: ['#a9a9a9', '#a9a9a9', '#a9a9a9', '#a9a9a9'],
    // };
    // this.setColors();
  },
  mounted: function mounted() {
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    // init run
    // try {
      let details = {
        //  title: this.title,
         title:this.info.name,
        // refresh: true,
        noneHome_setting: true,
        noneHome: true
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'noneHomeKeyTouch');
      this.lastStatus();
      this.title=this.info.name
      // this.parserRgbSetting();
      // subscribe
      let topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          256
      );

      this.$mqtt.subscribe(topic1);
      // console.log("this.info ",this.info )
     
    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    mqttAction(){
      console.log('mqttAction',getMtt.action)
     return getMtt.action;
    },
    MyFunctionPower(index, value) {
      // console.log(index, value);
      if (this.pol === 1) {
        if (index === 1)
          if (value)
            this.keytouch_status = "K1T#";
          else
            this.keytouch_status = "K1F#";
      } else {
        switch (index) {
          case 0:
            if (value)
              this.keytouch_status = "K5T#";
            else
              this.keytouch_status = "K5F#";
            break;
          case 1:
            if (value)
              this.keytouch_status = "K1T#";
            else
              this.keytouch_status = "K1F#";
            break;
          case 2:
            if (value)
              this.keytouch_status = "K2T#";
            else
              this.keytouch_status = "K2F#";
            break;
          case 3:
            if (value)
              this.keytouch_status = "K3T#";
            else
              this.keytouch_status = "K3F#";
            break;
          case 4:
            if (value)
              this.keytouch_status = "K4T#";
            else
              this.keytouch_status = "K4F#";
            break;
        }
      }
      // console.log("this.keytouch_status",this.keytouch_status)
      this.sendMessage(this.keytouch_status);
    },
    setColors() {
      let onColors = this.data.onColors.map(color => {
        return hexToRgb(color);
      });
      let offColors = this.data.offColors.map(color => {
        return hexToRgb(color);
      });
      let colorAll = new Color(0, 0, 255);
      let color1 = new Color(onColors[0][0], onColors[0][1], onColors[0][2]);
      let color1_2 = new Color(offColors[0][0], offColors[0][1], offColors[0][2]);
      let color2 = new Color(onColors[1][0], onColors[1][1], onColors[1][2]);
      let color2_2 = new Color(offColors[1][0], offColors[1][1], offColors[1][2]);
      let color3 = new Color(onColors[2][0], onColors[2][1], onColors[2][2]);
      let color3_2 = new Color(offColors[2][0], offColors[2][1], offColors[2][2]);
      let color4 = new Color(onColors[3][0], onColors[3][1], onColors[3][2]);
      let color4_2 = new Color(offColors[3][0], offColors[3][1], offColors[3][2]);
      this.resultAll.onFilter = new Solver(colorAll).solve().filter;
      this.resultAll.offFilter = '';
      this.result1.onFilter = new Solver(color1).solve().filter;
      this.result1.offFilter = new Solver(color1_2).solve().filter;
      this.result2.onFilter = new Solver(color2).solve().filter;
      this.result2.offFilter = new Solver(color2_2).solve().filter;
      this.result3.onFilter = new Solver(color3).solve().filter;
      this.result3.offFilter = new Solver(color3_2).solve().filter;
      this.result4.onFilter = new Solver(color4).solve().filter;
      this.result4.offFilter = new Solver(color4_2).solve().filter;
      // console.log(this.result1);
    },
    lastStatus() {
      // console.log("lastStatus")
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      // ------------------Request------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
          function (response) {
            self.closeModalLoading();
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.info=jsonArray.content.info
              // console.log("last status",jsonArray.content.info)
            
              self.showStatus(message);
              self.pol = self.info.pol;
              self.set_password= self.info.set_password;
              //Show and Hide pol in views
              self.parserRgbSetting();
              self.showHidePol();
              self.showUI=true;
             
              
            } else {
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },
    showHidePol(){
      // let trall = document.getElementById("trall");
      // let trl1 = document.getElementById("trl1");
      // let trl2 = document.getElementById("trl2");
      // let trl3 = document.getElementById("trl3");
      // let trl4 = document.getElementById("trl4");
      // console.log("showHidePol",this.pol)
      if (this.pol === 1) {
        // trl1.style.display = "block";
        this.pol1=true
      }
      else if (this.pol === 2) {
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trall.style.display = "block";
        this.pol1=true
        this.pol2=true
        this.polall=true
      }
     else if (this.pol === 3) {
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trl3.style.display = "block";
        // trall.style.display = "block";
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.polall=true
      }
      else if (this.pol === 4) {
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trl3.style.display = "block";
        // trl4.style.display = "block";
        // trall.style.display = "block";
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.pol4=true
        this.polall=true
      }
      if (!this.info.label) {
      this.labels = [{pol: "1", label: "L1"}, {pol: "2", label: "L2"}, {pol: "3", label: "L3"}, {
        pol: "4",
        label: "L4"
      }];
    } else {
      this.labels = JSON.parse(this.info.label);
      // console.log("labels",this.labels)
    }
   
    },
    showStatus(message) {
      if (message === "NOTSET") {
        this.result1.check = false;
        this.result2.check = false;
        this.result3.check = false;
        this.result4.check = false;
        this.resultAll.check = false;
        return;
      }

      let L1_r = message.charAt(0);
      let L2_r = message.charAt(1);
      let L3_r = message.charAt(2);
      let L4_r = message.charAt(3);

      this.result1.check = L1_r === "1";
      this.result2.check = L2_r === "1";
      this.result3.check = L3_r === "1";
      this.result4.check = L4_r === "1";
      this.resultAll.check = message === "1111" || this.pol === 3 && message === "1110" || this.pol === 2 && message === "1100";
    },
    parserRgbSetting(){
      let self=this;
      // console.log('info rgb',this.info.rgb_setting)
      if(this.info.rgb_setting==null){
        // console.log('nullllllllll')
        this.info.rgb_setting='L1ON00ff00L1OFFff0000L2ON00ff00L2OFFff0000L3ON00ff00L3OFFff0000L4ON00ff00L4OFFff0000'
      }
      var arrRgb =this.info.rgb_setting.split('L');
      arrRgb.forEach((item, index) => { 
              let str="";
              if(item.match("ON")){
                str = item.substr(0 + 3, 6);
              }
              if(item.match("OFF")){
                str = item.substr(0 + 4, 6);
              }
              self.rgbInfo.push({"id":index,"color":'#'+str})
              // console.log("self.rgbInfo",self.rgbInfo)
       })
       this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
       onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color,self.rgbInfo[5].color,self.rgbInfo[7].color],
       offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
    };
      // console.log("this.data",this.data)
     
      },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
      if (value === "refresh") {
        this.lastStatus();
        // location.reload();
      }
      if (value === "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value === 'noneHomeSetting') {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: 19,
        };
        localStorage.setItem("data_edit", JSON.stringify(data));
        this.$router.push({
          name: "SettingNoneTouchKey",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {
      // console.log('sendMessage',this.set_password)
      this.keytouch_status=status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.keytouch_status, '');
      }
    },
    sendReq(password) {
      this.sendRequest(this.keytouch_status, password);
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // console.log(message, message);
      let topic_final = getTopics.getTopicActionSend(
          this.serial_number, 256);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          // self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
  },
}
</script>

<style scoped>

</style>