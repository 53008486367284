<template>
  <v-app class="bg">
    <CmpThermostatTemp v-if="cmpShow" @callThermostatTempRefresh="ThermostatTempRefresh" :serial_number="serial_number"
       :temp_val="temp_val" :temp_home="temp_home" :subdevice_serial="serial_number">
    </CmpThermostatTemp>
    <CmpThermostat :status="statusThermostat" @clicked="getThermostatCmpCallback" :temp_home="temp_home">
    </CmpThermostat>
    <div class="row rounded-16 px-15 fixed-bottom">
      <div class="col-12 col-lg-12 px-15 py-20">
        <v-btn class="btn btn-info btn-block" color="bg-primary" :loading="loading" :disabled="loading"
          @click="sendDataThermostat()">
          <span class="text-white"> {{ $t('message.public.send') }}</span>
        </v-btn>
      </div>
    </div>
    <CmpPasscode @sendPassCode="sendReq" @cancel="closePass"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import { passHomeValidationInput } from "../../../../public/assets/js/utils/error_handler";
import CmpThermostat from "@/components/Cmp/sub/CmpThermostat";
import CmpThermostatTemp from "@/components/Cmp/sub/CmpThermostatTemp";

import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var timeout;

export default {
  name: "NThermostat",
  components: {
    CmpThermostat,
    CmpThermostatTemp,
    CmpPasscode
  },
  props: {
    serial_number: {},
    click: {},
  },
  data() {
    return {
      user_id: "",
      token: "",

      statusThermostat: {},
      passthermostat: "",
      // data_info_thermostat: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      temp_val: 25,
      loading: false,
      cmpShow: false,
      msgFinalSend: '',
      // data: {},
      temp_home: 16,
      main_admin: "",
      title: "",
      pass: true,
      set_password: false,
      pol: 1,
      loadingInstant: false,
      isModalVisible: false,
      isModalVisibles: false,
      info: [],
      data: [],
    };
  },
  async created() {
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    this.pass = this.info.password === 1;
    this.set_password = this.info.set_password;
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number && topic.split("/")[3] == '256') {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.exitModalLoading();

          SucessToastMsg.Msg();

        }
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number && topic.split("/")[3] == '256') {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          clearTimeout(timeout);
          this.exitModalLoading();
          SucessToastMsg.Msg();
           this.showGetMsgBroadCast(String(data));
        }
      }
    },
    "+/+/receive/+/temp"(data, topic) {
      if (topic.split("/")[1] === this.serial_number && topic.split("/")[3] == '256') {
        if (topic.split("/")[4] === "temp") {
          connection = true;
          clearTimeout(timeout);
          this.exitModalLoading();
          // SucessToastMsg.Msg();
           this.showGetMsgBroadCast(String(data));
        }
      }
    },
  },
  mounted: function mounted() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    // init run
    try {
      let self = this;
      Internets.Internet;
      setTimeout(function () {
        self.lastStausThermostat();
      }, 100);
      this.$store.dispatch("infoHome");

      // this.main_admin = this.$store.getters.getadmin;

      // subscribe
      
      this.ThermostatTempRefresh()
      this.onSubscribe();
    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: this.$t("message.ListDevice.err_process"),
          type: "error",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({ name: "mainpage" });
            swal.close();
          }
        }
      );
    }

    let details = {
      title: this.info.name,
      noneHome_setting: true,
      noneHome: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'NThermostat');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onSubscribe(){
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        256
      );
      var topic2 = getTopics.getTopicTempReceive(
        this.serial_number,
        256
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
    },
    getThermostatCmpCallback(msg) {
      this.msgFinalSend = msg;
    },
    
    sendReq(password) {
        this.sendMessage(this.msgFinalSend, password);
    },
    
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
      if (value === 'noneHomeSetting') {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: 19,
        };
        localStorage.setItem("data_edit", JSON.stringify(data));
        this.$router.push({
          name: "SettingNoneThermostat",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    sendMessage(saction, password) {

      let self = this;

      var topic_final = getTopics.getTopicActionSend(
        this.serial_number,
        256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();

      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            //  connection=false;
            self.openModalLoading();
            $("#modal-pass-code").modal("hide");
            timeout = setTimeout(function () {
              self.exitModalLoading();

              if (!connection) {
                setTimeout(function () {
                  self.lastStausThermostat();
                }, 1000);

                ErrorToastMsg.Msg();


              }

              connection = false;
            }, 3000);
          } else {
            self.exitModalLoading();
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    },
    ThermostatTempRefresh() {
        this.sendMessageTemp()
    },
    sendMessageTemp() {
      let self = this;

      var topic_finalTemp = getTopics.getTopicTempSend(
        this.serial_number,
        256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_finalTemp,
        message: "TEMP?",
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();

      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            self.openModalLoading();
          } else {
            self.exitModalLoading();
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );

    },
    showGetMsgBroadCast(msg) {
      this.temp_val = msg.slice(5, 7);
      localStorage.setItem("primeryTemp", this.temp_val);

    },
    showGetMsgBroadAction(msg) {
      this.temp_home = BetweenStr.getBetweenStr(String(msg), "TP", "-")[1];
      if (this.temp_home >= 16) {
        localStorage.setItem("tempHome", this.temp_home)
      } else {
        localStorage.setItem("tempHome", 329)
      }

    },
    parseMsgTON(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    parseMsgTOFF(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    sendDataThermostat() {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.msgFinalSend, '')
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassKeyTouchFara() {
      if (!passHomeValidationInput(this.passthermostat)) return;
      this.sendMessage(this.keytouch_status, this.passthermostat);
      this.passthermostat = "";
    },
    lastStausThermostat() {
      let self = this;
      // ------------------Data--------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
        function (response) {
          self.exitModalLoading();
          self.cmpShow = true;
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.temp_home = BetweenStr.getBetweenStr(String(jsonArray.content.message), "TP", "-")[1];

            let message = jsonArray.content.message;

            self.statusThermostat = message
            self.msgFinalSend = message;

            if (message == "NOTSET") {
              self.msgFinalSend = message;
            }
            if (message.startsWith("TOFF")) {
              self.parseMsgTOFF(message);
            }
            if (message.startsWith("TON")) {
              self.parseMsgTON(message);
            }
          } else {
            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    }
    ,
    closePass() {
      // location.reload();
      this.lastStausThermostat();
    }
    ,
  },
};
</script>

<style scoped></style>
