<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i
              class="ti-angle-right"
              style="font-size: 24px"
              @click="goBackTimerShow()"
            ></i>
            <div class="media-body p-0">
              <strong>{{ $t('message.timer.scheduler_show') }}</strong>
            </div>
          </div>
        </div>
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav" v-if="this.$store.getters.getadmin == 1">
            <!-- Notifications -->
            <li class="dropdown" v-on:click.prevent="goRefresh()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="mdi mdi-refresh mdi-24px"></i>
              </a>
            </li>
            <li class="dropdown" v-on:click.prevent="AddTimer()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="mdi mdi-plus mdi-36px"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <vue-pull-refresh :on-refresh="onRefresh"></vue-pull-refresh>
      <!-- Main content -->
      <section class="error-page rtl" v-show="isShow" v-if="length == 0">
        <div class="container">
          <div
            class="row align-items-center justify-content-center text-center"
          >
            <div class="col-lg-7 col-md-10 col-12">
              <h1
                class="
                  text-warning
                  font-size-180 font-weight-bold
                  error-page-title
                "
              ></h1>
              <img
                src="/assets/images/smart/timenone.svg"
                style="width: 300px; height: 300px"
              />
              <br />
              <h4 class="mt-10"><strong>{{ $t('message.timer.scheduler_not_add') }}</strong></h4>
              <h6>{{ $t('message.timer.txt_3') }}</h6>
              <button
                type="button"
                class="btn btn-success mb-5 mt-10"
                v-on:click.prevent="addClock()"
              >
                {{ $t('message.timer.setTime_device') }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <section class="content">
        <!-- row -->
        <div class="row" v-bind:key="index" v-for="(infos, index) in info">
          <div class="col-12 col-lg-12">
            <div class="box">
              <div class="box-body">
                <div class="table-responsive">
                  <table class="table product-overview mb-0">
                    <tbody>
                      <tr>
                        <td class="border-0">
                          <!-- <img src="../../images/product/product-1.png" alt="" width="80"> -->
                          <i
                            class="mdi mdi-timer mdi-24px"
                            style="color: #007bff"
                          ></i>
                        </td>
                        <td class="border-0">
                          <h6></h6>
                          <p>{{ $t('message.timer.timer') }} {{ infos.count }}</p>
                          <img
                            v-if="infos.day6 == '0'"
                            src="/assets/images/smart/icon_day1off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day6 == '1'"
                            src="/assets/images/smart/icon_day1on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day7 == '0'"
                            src="/assets/images/smart/icon_day2off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day7 == '1'"
                            src="/assets/images/smart/icon_day2on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day1 == '0'"
                            src="/assets/images/smart/icon_day3off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day1 == '1'"
                            src="/assets/images/smart/icon_day3on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day2 == '0'"
                            src="/assets/images/smart/icon_day4off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day2 == '1'"
                            src="/assets/images/smart/icon_day4on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day3 == '0'"
                            src="/assets/images/smart/icon_day5off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day3 == '1'"
                            src="/assets/images/smart/icon_day5on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day4 == '0'"
                            src="/assets/images/smart/icon_day6off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day4 == '1'"
                            src="/assets/images/smart/icon_day6on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day5 == '0'"
                            src="/assets/images/smart/icon_day7off.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                          <img
                            v-if="infos.day5 == '1'"
                            src="/assets/images/smart/icon_day7on.svg"
                            width="12px"
                            height="12px"
                            alt="..."
                          />
                        </td>
                        <td class="border-0">
                          <p class="font-size-12 mt-4">
                            {{ $t('message.timer.start') }}  : {{ infos.time1 }}
                          </p>
                          <p class="font-size-12">{{ $t('message.timer.end') }} : {{ infos.time2 }}</p>
                        </td>
                        <td class="border-0" width="50" align="center">
                          {{ infos.status }}
                        </td>
                        <td
                          class="border-0"
                          align="center"
                          @click="deleteTimer(infos.count)"
                        >
                          <i
                            class="ti-trash text-danger"
                            style="font-size: 18px"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
  </div>
  <!-- /. Wrapper -->
</template>

 <script>
import { SetClock } from "../../../../../public/assets/js/utils/util.js";
import VuePullRefresh from "vue-pull-refresh";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import {getTopics} from "@/../public/assets/js/utils/topics.js";

var count_timer;
var connection = false;

export default {
  components: {
    "vue-pull-refresh": VuePullRefresh,
  },
  data: () => ({
    info: "",
    length: "",
    isShow: false,
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
  },
  mqtt: {
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
  },
  mounted() {
    try {
      let self = this;
      this.$store.dispatch("infoHome");
      this.$store.dispatch("InfoSubDevice");
      setTimeout(function () {
        self.lastStatus();
      }, 100);
    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: self.$t('message.public.operation_failure'),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: self.$t('message.public.OK'),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({ name: "mainpage" });
            swal.close();
          }
        }
      );
    }
  },

  methods: {
    lastStatus() {
      let self = this;

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let sucdevice_sn = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: sucdevice_sn,
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/device/timers";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.exitModalLoading();
            let content = jsonArray.content.timers;

            var finaldata = [];
            self.length = content.length;
            var len = content.length;
            if (len == 0) {
              self.isShow = true;
            }
            content.forEach(function (item) {
              const data = getTimerData(item.message);

              finaldata.push(data);
              self.info = finaldata;
            });
          } else {
            //error

            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //----------------------Err ---------------------//
        function (errr) {}
      );
    },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: self.$t('message.timer.timer'),
            text: self.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#007bff",
            confirmButtonText: self.$t('message.public.delete'),
            cancelButtonText: self.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true
          },
        function (isConfirm) {
          if (isConfirm) {
            // swal("Deleted!", "Your imaginary file has been deleted.", "success");
            self.sendDelete(counttimer);
          }
        }
      );
    },
    sendDelete(counttimer) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      let count = this.count;
      var topic_final;
      count_timer = counttimer;
      let message = "TIMER" + count_timer + "-OFF#";

    
      var topic_final = getTopics.getTopicTimerSend(device_serial, "256");

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);

            self.subcribe();
            setTimeout(function () {
              if (!connection) {
                swal("", self.$t('message.public.operation_failure'), "error");
              }
            }, 10000);
          } else {
            //error
          }
        },
        //----------------------Err ---------------------//
        function (errr) {}
      );
    },
    subcribe() {
      var device_serial = this.serial_number;

      var topic_final = getTopics.getTopicTimerReceive(device_serial, "256");
      this.$mqtt.subscribe(topic_final);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();
      let device_serial = this.serial_number;
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      });

      location.reload();
    },
    openModalLoading() {
      this.$parent.showModal();
    },
    exitModalLoading() {
      this.$parent.closeModal();
    },
    goBackTimerShow() {
      // this in mistake
      this.$router.push({
        name: "SublampInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          count: this.count,
        },
      });
    },
    AddTimer() {
      this.$router.push({
        name: "Lamptimer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      var topic_final = getTopics.getTopicClockSend(serial_number, "256");

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.exitModalLoading();
          } else {
            //error

            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //----------------------Err ---------------------//
        function (errr) {}
      );
    },
    goRefresh() {
      location.reload();
    },
    onRefresh: function () {
      return new Promise(function (resolve, reject) {
        setTimeout(function () {
          resolve();
        }, 1000);
      });
    },
  },
};
</script>



<style scoped>
</style>
