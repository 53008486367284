<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!-- Content Wrapper. Contains page content -->
      <section class="content">
        <div class="col-12" >
          <div style="text-align: center; margin-top: 20px">
            <img
                src="/assets/images/smart/vector-verify.svg"
                style="width: 200px; height: 200px"
                alt="Verify"
            />
            <h6 class="p-10">
              {{ $t('message.loginEmail.verifyEmail') }}
            </h6>
          </div>
          <br/>
        </div>
        <!-- col -->
        <div class="col-12">
          <div class="box">
            <div class="box-body p-30">
              <form novalidate>
                <h6 class="mb-20 font-weight-bold" id="txtloginverify">
                  {{ $t('message.public.user_info') }}
                </h6>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-email-outline"></i
                          ></span>
                    </div>
                    <input
                        id="verify_code"
                        type="number"
                        class="form-control pl-15"
                        required
                        :placeholder="$t('message.loginVerify.inputCode')"
                        autocomplete="off"
                        v-model="verify_code"
                    />
                  </div>
                </div>
                <div class="form-group" id="form-fname" >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-account-outline"></i
                            ></span>
                    </div>
                    <input
                        id="fname"
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginVerify.inputFname')"
                        autocomplete="off"
                        v-model="fname"
                    />
                  </div>
                </div>
                <div class="form-group" id="form-lname" >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-account-multiple"></i
                            ></span>
                    </div>
                    <input
                        id="lname"
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginVerify.inputLname')"
                        autocomplete="off"
                        v-model="lname"
                    />
                  </div>
                </div>
                <div class="form-group" id="form-mobile" >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-cellphone"></i
                            ></span>
                    </div>
                    <input
                        type="number"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.login.txt1')"
                        autocomplete="off"
                        v-model="mobile"
                    />
                  </div>
                </div>
                <div class="form-group" id="form-pass" >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-lock-outline"></i
                            ></span>
                    </div>
                    <input
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginEmail.inputPassword')"
                        autocomplete="off"
                        v-model="password"
                        
                    />
                  </div>
                </div>
                <div class="form-group" id="form-pass-confirm" >
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-lock-outline"></i
                            ></span>
                    </div>
                    <input
                        id="passconfrim"
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginEmail.inputConfirmPassword')"
                        autocomplete="off"
                        v-model="password_confirm"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <button
                        type="button"
                        id="submitButton"
                        class="btn btn-info btn-block"
                        @click="RegisterEmailVerify"
                    >
                      {{ $t('message.public.submit') }}
                    </button>

                    <!-- <h6 v-if="countshow===true" class="mt-20"> {{ $t('message.loginVerify.again_txt1') }} {{ countDown }} {{ $t('message.loginVerify.again_txt2') }}</h6>
                    <div class="text-center mt-10" v-if="btnagain===true">
                      <button
                          type="button"
                          id="againButton"
                          class="btn btn btn-outline btn-primary"
                          @click="sendDataAgain()"
                      >
                         {{ $t('message.loginVerify.send_again') }}
                      </button>
                    </div> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> 
      </section>
     <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import {
  smsCodeValidationInput,
  nameValidationInput,
  lnameUserValidationInput,
  MobileValidationInput,
  passEmailValidationInput,
  passCompareValidationInput
} from "../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";


export default {
  // components:{
  //   ModalCenter,

  // },
  props: {
    id: {},
    new_user: {},
    click: {},
  },
  data: () => ({
    isModalVisible:false,
        verify_code: "",
        mobile:"",
        fname: "",
        lname: "",
        password:"",
        password_confirm:""
    // UserID: this.$route.params.userID,
  }),
  mounted() {
    //----------------- Language
    let details = {
      title: this.$t('message.public.user_info'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'RegisterEmailVerify');
    this.countDownTimer()
    let self = this;

    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
  },
  watch:{
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({name: "registerEmail"})
      }
    },
    RegisterEmailVerify() {
      // this.$router.push({
      //   name: "loginEmail",
      // });
      let self = this;
      if (
          !smsCodeValidationInput(this.verify_code) ||
          !nameValidationInput(this.fname) ||
          !lnameUserValidationInput(this.lname) ||
          !MobileValidationInput(this.mobile)||
          !passEmailValidationInput(this.password)||
          !passEmailValidationInput(this.password_confirm) ||
          !passCompareValidationInput(this.password,this.password_confirm)
      )
        return;

      var data = {
        verify_id: this.id,
        verify_code: this.verify_code,
        fname: this.fname,
        lname: this.lname,
        mobile:this.mobile,
        password:this.password,
        password_confirm:this.password_confirm

      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.registerEmailVerify;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModal();

              let jsonArray = JSON.parse(response.responseText);
              console.log("jsonArray",jsonArray)
              self.$router.push({
               name: "loginEmail",
                });
              let dataparse = jsonArray.content.user;
              dataparse.forEach(function (item) {
                localStorage.setItem("user_id", item.user_id);
                localStorage.setItem("token", item.token);
              });
              localStorage.setItem("login_id", 1);
              // self.$router.replace({name: "mainpage"});
            } else {
              self.closeModal();
            }
          },
          //--------------------------------------Err --------------------------------------//

          function (errr) {

            setTimeout(function () {
              self.closeModal();
            }, 1000);
          }
      );
    },
    // sendDataAgain() {
    //   let self = this
    //   let mobile = localStorage.getItem("mobile_number");
    //   var data = {
    //     mobile: mobile,
    //   };
    //   //--------------------------------------Request --------------------------------------//
    //   let URL = getUrl.login;
    //   const p = postAjaxCall(URL, data);
    //   this.openModal();
    //   //--------------------------------------Response --------------------------------------//
    //   p.then(
    //       function (response) {
    //         self.closeModal();
    //         if (responseAjax(response)) {
    //           let jsonArray = JSON.parse(response.responseText);
    //           localStorage.setItem("verifyid", jsonArray.content.verify_id);
    //         } else {
    //         }
    //       },
    //       //--------------------------------------Err --------------------------------------//
    //       function (errr) {
    //       }
    //   );
    // },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
     this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
</style>
