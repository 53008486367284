<template>
  <v-app class="bg">
    <div class="mt-3">
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickEditAlarm()"
          >
            <i class="mdi mdi-border-color mdi-24px"></i>
            <div class="media-body">
              <!-- <label>{{ $t('message.setting.edit') }}</label> -->
              ویرایش رله 
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickChangePass()"
          >
          <i class="mdi mdi-clock mdi-24px"></i>
            <div class="media-body">
              <label>زمانبندی</label>
            </div>
          </div>
          <div
              
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickRelay4Instant()"
          >
          <i class="mdi mdi-timer-sand mdi-24px"></i>
            <div class="media-body">
              <label>تنظیمات لحظه ای </label>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content --> 
  </v-app>
</template>
<script>

import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  components: {

  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      title: '',
    };
  },
  mounted() {
    this.title = this.$t('message.public.settings')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Relay4Setting');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "SettingAlarmInfo", params: {home_id: this.home_id, serial_number: this.serial_number},});
      }
    },
    clickPrivacyReport() {
      this.$router.push({
        name: "PrivacyReportInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickRelay4Instant() {
      this.$router.push({
        name: "Relay4Instant", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
  },
};
</script>


<style scoped>
</style>
