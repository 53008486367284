<template>
  <div class="col-12">
    <div :class="[isAlert ? alertClass : '', alertColor]" class="p-0 m-0">
      <div class="media">
        <div v-if="data_type == 'default'">
          <i class="mdi mdi-alert-circle-outline mdi-18px"></i>
        </div>
        <div class="media-body">
          <label id="lblaccountlogin">
            {{ data_txt }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data_txt: String,
    data_type: String,
  },
  data() {
    return {
      isAlert: true,
      alertClass: 'alert',
      alertColor:''
    };
  },
  created(){
     if(this.data_type=='default'){this.alertColor="alert-secondary"}
     if(this.data_type=='danger'){this.alertColor="alert-danger"}
     if(this.data_type=='success'){this.alertColor="alert-success"}
     if(this.data_type=='info'){this.alertColor="alert-info"}
     if(this.data_type=='warning '){this.alertColor="alert-warning"}
  },
 
};

</script>
<style  scoped>
</style>
