
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMqtt from "vue-mqtt"
import Home from "../components/Home.vue";
import Welcome from "../components/Welcome.vue";
import Login from "../components/Login.vue";
import LoginEmail from "../components/LoginEmail.vue";
import LoginVerify from "../components/LoginVerify.vue";
import RegisterEmail from "../components/RegisterEmail.vue";
import RegisterEmailVerify from "../components/RegisterEmailVerify.vue";
import ForgotPasswordEmail from "../components/ForgotPasswordEmail.vue";


import MainPage from "../components/MainPage.vue";
import AboutUs from "../components/AboutUs.vue";
import ModalConfig from "../components/Modal/ModalConfig.vue";
import mainSettings from "../components/Settings.vue";

//profile
import Profile from "../components/profile/Profile.vue";
import ProfileEdit from "../components/profile/ProfileEdit.vue";
import ProfileChangeEmail from "../components/profile/ProfileChangeEmail.vue";
import ProfileChangePassword from "../components/profile/ProfileChangePassword.vue";

import ListDevice from "../components/ListDevice.vue";
import AddDevice from "../components/AddDevice.vue";

//SubDevice
import SubCurtain from "../components/subdevice/curtain/SubCurtain.vue";
import SubCurtainFara from "../components/subdevice/curtainFara/SubCurtainFara.vue";
import AboutCurtain from "../components/subdevice/curtainFara/AboutCurtain.vue";
import SubAlarmFara from "../components/subdevice/alarmFara/SubAlarmFara.vue";
import SubRemote from "../components/subdevice/remote/SubRemote.vue";
import SubLock from "../components/subdevice/lock/SubLock.vue";
import SubLamp from "../components/subdevice/lamp/SubLamp.vue";
import SubDimmer from "../components/subdevice/dimmer/SubDimmer.vue";
import SubCooler from "../components/subdevice/cooler/SubCooler.vue";
import subElevator from "../components/subdevice/elevator/subElevator";
import AboutElevator from "../components/subdevice/elevator/AboutElevator.vue";
import subDoor from "../components/subdevice/door/SubDoor.vue"
import SubDoorShutter from "../components/subdevice/doorShutter/SubDoorShutter.vue";
import AboutShutter from "../components/subdevice/doorShutter/AboutShutter.vue";
import SubService from "../components/subdevice/service/SubService";
import AboutSubService from "../components/subdevice/service/AboutSubService";
import EditDevice from "../components/subdevice/service/EditDevice";
import SubCoolerFara from "../components/subdevice/coolerFara/SubCoolerFara.vue";
import Thermostat from "../components/subdevice/thermostat/Thermostat.vue";
import SubKeyTouch from "../components/subdevice/keytouch/SubKeyTouch.vue";
import SubRelay from "../components/subdevice/subrelay/SubRelay.vue";
import SubKeyTouchFara from "../components/subdevice/keytouchfara/SubKeyTouchFara.vue";
import SubScenarioKey from "../components/subdevice/scenariokey/SubScenarioKey.vue";
import SubScenarioKeyConfig from "../components/subdevice/scenariokey/SubScenarioKeyConfig.vue";
import RelayKey from "../components/devicewifi/RelayKey.vue";

import SubKeyTouchPro from "../components/subdevice/keytouchpro/SubKeyTouchPro.vue";
import EditRGBPro from "../components/subdevice/keytouchpro/EditRGBPro.vue"
import TouchProStatusWifi from "../components/subdevice/keytouchpro/TouchProStatusWifi.vue"
import TouchProSetting from "../components/subdevice/keytouchpro/TouchProSetting.vue"

import SubCurtainPro from "../components/subdevice/curtainpro/SubCurtainPro.vue";
import CurtainEditRGBPro from "../components/subdevice/curtainpro/CurtainEditRGBPro.vue"
import CurtainProStatusWifi from "../components/subdevice/curtainpro/CurtainProStatusWifi.vue"
import AboutCurtainPro from "../components/subdevice/curtainpro/AboutCurtainPro.vue";
import CurtainProSetting from "../components/subdevice/curtainpro/CurtainProSetting.vue";

import SubDoorShutterPro from "../components/subdevice/doorshutterpro/SubDoorShutterPro.vue";
import DoorSutterEditRGBPro from "../components/subdevice/doorshutterpro/DoorSutterEditRGBPro.vue"
import DoorShutterProStatusWifi from "../components/subdevice/doorshutterpro/DoorShutterProStatusWifi.vue"
import AboutDoorShutterPro from "../components/subdevice/doorshutterpro/AboutDoorShutterPro.vue";
import DoorProSetting from "../components/subdevice/doorshutterpro/DoorProSetting.vue";


import SubCoolerPro from "../components/subdevice/coolerpro/SubCoolerPro.vue";
import CoolerEditRGBPro from "../components/subdevice/coolerpro/CoolerEditRGBPro.vue"
import CoolerProStatusWifi from "../components/subdevice/coolerpro/CoolerProStatusWifi.vue"
import AboutCoolerPro from "../components/subdevice/coolerpro/AboutCoolerPro";
import CoolerProSetting from "../components/subdevice/coolerpro/CoolerProSetting";



import SubElevatorPro from "../components/subdevice/elevatorpro/SubElevatorPro.vue";
import ElevatorEditRGBPro from "../components/subdevice/elevatorpro/ElevatorEditRGBPro.vue"
import ElevatorProStatusWifi from "../components/subdevice/elevatorpro/ElevatorProStatusWifi.vue"
import AboutElevatorPro from "../components/subdevice/elevatorpro/AboutElevatorPro";
import ElevatorProSetting from "../components/subdevice/elevatorpro/ElevatorProSetting.vue";


import SubServicePro from "../components/subdevice/servicepro/SubServicePro.vue";
import ServiceEditRGBPro from "../components/subdevice/servicepro/ServiceEditRGBPro.vue"
import ServiceProStatusWifi from "../components/subdevice/servicepro/ServiceProStatusWifi.vue"
import AboutServicePro from "../components/subdevice/servicepro/AboutServicePro";
import ServiceProSetting from "../components/subdevice/servicepro/ServiceProSetting";



import SubThermostatPro from "../components/subdevice/thermostatpro/SubThermostatPro.vue";
import ThermostatEditRGBPro from "../components/subdevice/thermostatpro/ThermostatEditRGBPro.vue"
import ThermostatProStatusWifi from "../components/subdevice/thermostatpro/ThermostatProStatusWifi.vue"
import AboutThermostatPro from "../components/subdevice/thermostatpro/AboutThermostatPro";
import ThermostatProSetting from "../components/subdevice/thermostatpro/ThermostatProSetting";


import SubThermostaticPro from "../components/subdevice/thermostaticpro/SubThermostaticPro.vue";
import ThermostaticProStatusWifi from "../components/subdevice/thermostaticpro/ThermostaticProStatusWifi.vue"
import AboutThermostaticPro from "../components/subdevice/thermostaticpro/AboutThermostaticPro";

//Cmp
import CmpEdit from "../components/Cmp/general/CmpEdit";
import Test from "../components/subdevice/scenariokey/Test.vue";
// About Subdevice
import AboutSubCoolerFara from "../components/subdevice/coolerFara/AboutCoolerFara.vue";
import AboutThermostat from "../components/subdevice/thermostat/AboutThermostat.vue";
import AboutKeyFara from "../components/subdevice/keytouchfara/AboutKeyFara.vue";
import AboutKeyPro from "../components/subdevice/keytouchpro/AboutKeyPro.vue";
import AboutRelay from "../components/devicewifi/AboutRelay.vue";
// Setting Outlet
import Settings from "../components/setting/Settings.vue";
import SettingsIR from "../components/setting/SettingsIR.vue";
import SettingClientIR from "../components/setting/SettingClientIR.vue";
import SettingClient from "../components/setting/SettingClient.vue";
import SettingTemp from "../components/setting/SettingTemp.vue";
import SettingCurrent from "../components/setting/SettingCurrent.vue";
import SettingVolt from "../components/setting/SettingVolt.vue";
import SettingClock from "../components/setting/SettingClock.vue";
import Selection_Drag from "../components/setting/SelectionDrag";
//-- Alarm
import SettingAlarm from "../components/setting/alarm/SettingAlarm.vue";
import Ant from "../components/subdevice/alarmFara/settings/Ant.vue";
import ZoneSms from "../components/subdevice/alarmFara/settings/ZoneSms.vue";
import OutputSound from "../components/subdevice/alarmFara/settings/OutputSound.vue";
import SirenTime from "../components/subdevice/alarmFara/settings/SirenTime.vue";
import PrivacyReport from "../components/subdevice/alarmFara/settings/PrivacyReport.vue";
import AboutAlarm from "../components/subdevice/alarmFara/settings/AboutAlarm.vue";
import WirelessList from "../components/subdevice/alarmFara/settings/WirelessList.vue";
import WirelessAdd from "../components/subdevice/alarmFara/settings/WirelessAdd.vue";
import Wireless24List from "../components/subdevice/alarmFara/settings/Wireless24List.vue";
import Wireless24Add from "../components/subdevice/alarmFara/settings/Wireless24Add.vue";
import RemoteList from "../components/subdevice/alarmFara/settings/RemoteList.vue";
import RemoteAdd from "../components/subdevice/alarmFara/settings/RemoteAdd.vue";
import ZoneList from "../components/subdevice/alarmFara/settings/ZoneList.vue";
import ZoneAdd from "../components/subdevice/alarmFara/settings/ZoneAdd.vue";
import Relay4Setting from "../components/subdevice/alarmFara/settings/Relay4Setting.vue";
// -- - - - - - Alarm Relay instant
import Relay4Instant from "../components/subdevice/alarmFara/settings/relay4/instant/Relay4Instant";
// -- - - - - - Alarm Relay timer
import Relay4TimerShow from "../components/subdevice/alarmFara/settings/relay4/timer/Relay4TimerShow";
import Relay4Timer from "../components/subdevice/alarmFara/settings/relay4/timer/Relay4Timer";
import Relay4Step2Timer from "../components/subdevice/alarmFara/settings/relay4/timer/Relay4Step2Timer";
//-- Alarm
// IR 
import IrTv from "../components/ir/irtv/IrTv.vue";
import IrTvLearn from "../components/ir/irtv/IrTvLearn.vue";
import AddBandTv from "../components/ir/irtv/AddBandTv.vue";
import IrAir from "../components/ir/irair/IrAir.vue";
import IrAirSaveList from "../components/ir/irair/IrAirSaveList.vue";

import IrAirLearn from "../components/ir/irair/IrAirLearn.vue";
import IrAirLearnEdit from "../components/ir/irair/IrAirLearnEdit.vue";
import IrAirList from "../components/ir/irair/IrAirList.vue";
import IrAmplifier from "../components/ir/iramplifier/IrAmplifier.vue";
import IrAmplifierLearn from "../components/ir/iramplifier/IrAmplifierLearn.vue";
import IrGateway from "../components/ir/IrGateway.vue";
import IrType from "../components/ir/IrType.vue";
import IrBrand from "../components/ir/IrBrand.vue";
import AboutIr from "../components/ir/AboutIr.vue";
import IrReport from "../components/ir/settingIr/IrReport.vue";
//None home IR
import IrTvNone from "../components/nonehomeir/irnone/irtvnone/IrTvNone.vue";
import IrTvLearnNone from "../components/nonehomeir/irnone/irtvnone/IrTvLearnNone.vue";
import AddBandNoneTv from "../components/nonehomeir/irnone/irtvnone/AddBandNoneTv.vue";
import IrAirNone from "../components/nonehomeir/irnone/irairnone/IrAirNone.vue";
import IrAirLearnNone from "../components/nonehomeir/irnone/irairnone/IrAirLearnNone.vue";
import IrAirListNone from "../components/nonehomeir/irnone/irairnone/IrAirListNone.vue";
import IrAirLearnEditNone from "../components/nonehomeir/irnone/irairnone/IrAirLearnEditNone.vue";
import IrAmplifierNone from "../components/nonehomeir/irnone/iramplifiernone/IrAmplifierNone.vue";
import IrAmplifierLearnNone from "../components/nonehomeir/irnone/iramplifiernone//IrAmplifierLearnNone.vue";
import IrGatewayNone from "../components/nonehomeir/irnone/IrGatewayNone.vue";
import IrTypeNone from "../components/nonehomeir/irnone/IrTypeNone.vue";
import IrBrandNone from "../components/nonehomeir/irnone/IrBrandNone.vue";
import SettingsNoneIr from "../components/nonehomeir/irnone/SettingsNoneIr.vue";
import AboutNoneIr from "../components/nonehomeir/irnone/AboutNoneIr.vue";
import SettingNoneClockIr from "../components/nonehomeir/irnone/SettingNoneClockIr.vue";
import IrReportNone from "../components/nonehomeir/irnone/settingIrNone/IrReportNone.vue";


import ManagementClient from "../components/setting/client/ManagementClient.vue";
import ManagementClientClient from "../components/setting/client/ManagementClientClient.vue";
import AddClient from "../components/setting/client/AddClient.vue";
import AddClientEmail from "../components/setting/client/AddClientEmail.vue";
import AddClientVerify from "../components/setting/client/AddClientVerify.vue";
import DelClient from "../components/setting/client/DelClient.vue";
import DelClientVerify from "../components/setting/client/DelClientVerify.vue";
import SetAdmin from "../components/setting/client/SetAdmin.vue";
import SetAdminEmail from "../components/setting/client/SetAdminEmail.vue";
import SetAdminVerify from "../components/setting/client/SetAdminVerify.vue";
import SetClient from "../components/setting/client/SetClient.vue";
import SetClientEmail from "../components/setting/client/SetClientEmail.vue";
import SetClientVerify from "../components/setting/client/SetClientVerify.vue";
import SettingClientDelHome from "../components/setting/client/SettingClientDelHome.vue";
import SettingDelHomeClientVerify from "../components/setting/client/SettingDelHomeClientVerify.vue";
import noneHomeClient from "../components/setting/client/noneHomeClient.vue";
import noneDelClient from "../components/setting/client/noneDelClient.vue";
import noneDelClientVerify from "../components/setting/client/noneDelClientVerify.vue";
import noneAddClient from "../components/setting/client/noneAddClient.vue";
import noneAddClientVerify from "../components/setting/client/noneAddClientVerify.vue";
//None Home
import NonehomeRelyKey from "../components/nonehome/NonehomeRelyKey.vue";
import SettingsNone from "../components/nonehome/SettingsNone.vue";
import SettingNoneTemp from "../components/nonehome/SettingNoneTemp.vue";
import SettingNoneVolt from "../components/nonehome/SettingNoneVolt.vue";
import SettingNoneCurrent from "../components/nonehome/SettingNoneCurrent.vue";

import AboutNoneRelay from "../components/nonehome/AboutNoneRelay.vue";
import SettingNoneClock from "../components/nonehome/SettingNoneClock.vue";
import NOutletTimer from "../components/nonehome/nonetimer/NOutletTimer.vue";
import NOutletStep2Timer from "../components/nonehome/nonetimer/NOutletStep2Timer.vue";
import NOutletTimerShow from "../components/nonehome/nonetimer/NOutletTimerShow.vue";
import keytouch from "../components/nonehome/KeyTouch/KeyTouch.vue";
import KeyTouchEdit from "../components/nonehome/KeyTouch/KeyTouchEdit.vue"
import keyTouchConvert from "../components/nonehome/KeyTouch/keyTouchConvert.vue"
import EditRGB from "../components/nonehome/KeyTouch/EditRGB.vue"
import keyTouchAbout from "../components/nonehome/KeyTouch/keyTouchAbout.vue";
import N2Relay from "../components/nonehome/2Relay/N2Relay.vue";
import N2RelayEdit from "../components/nonehome/2Relay/N2RelayEdit.vue"
import N2RelayAbout from "../components/nonehome/2Relay/N2RelayAbout.vue";
import NCurtain from "../components/nonehome/curtain/NCurtain.vue";
import NCurtainEdit from "../components/nonehome/curtain/NCurtainEdit.vue"
import NCurtainAbout from "../components/nonehome/curtain/NCurtainAbout.vue";

import NCooler from "../components/nonehome/cooler/NCooler.vue";
import NCoolerEdit from "../components/nonehome/cooler/NCoolerEdit.vue"
import NCoolerAbout from "../components/nonehome/cooler/NCoolerAbout.vue";

import NService from "../components/nonehome/service/NService.vue";
import NServiceEdit from "../components/nonehome/service/NServiceEdit.vue"
import NServiceAbout from "../components/nonehome/service/NServiceAbout.vue";
import NServiceRgb from "../components/nonehome/service/NServiceRgb.vue";

import NThermostat from "../components/nonehome/thermostat/NThermostat.vue";
import NThermostatEdit from "../components/nonehome/thermostat/NThermostatEdit.vue"
import NThermostatAbout from "../components/nonehome/thermostat/NThermostatAbout.vue";

import NThermostatic from "../components/nonehome/thermostatic/NThermostatic.vue";
import NThermostaticEdit from "../components/nonehome/thermostatic/NThermostaticEdit.vue"
import NThermostaticAbout from "../components/nonehome/thermostatic/NThermostaticAbout.vue";

//import Timer
import OutletTimer from "../components/devicewifi/timer/OutletTimer.vue";
import OutletStep2Timer from "../components/devicewifi/timer/OutletStep2Timer.vue";
import OutletTimerShow from "../components/devicewifi/timer/OutletTimerShow.vue";

import IRTimerList from "../components/ir/timer/IRTimerList";
import IRTimerShow from "../components/ir/timer/IRTimerShow";
import IRStep2Timer from "../components/ir/timer/IRStep2Timer";
import IrTvTimer from "../components/ir/timer/devices/IrTvTimer.vue";
import IrAirTimer from "../components/ir/timer/devices/IrAirTimer.vue";
import IrAmplifierTimer from "../components/ir/timer/devices/IrAmplifierTimer.vue";

// import Subdevice Timer
import TouchTimer from "../components/subdevice/keytouch/timer/TouchTimer.vue";
import TouchStep2Timer from "../components/subdevice/keytouch/timer/TouchStep2Timer.vue";
import TouchTimerShow from "../components/subdevice/keytouch/timer/TouchTimerShow.vue";

import CoolerFaraTimer from "../components/subdevice/coolerFara/timer/CoolerFaraTimer";
import CoolerFaraStep2Timer from "../components/subdevice/coolerFara/timer/CoolerFaraStep2Timer";
import CoolerFaraTimerShow from "../components/subdevice/coolerFara/timer/CoolerFaraTimerShow";

import LampTimer from "../components/subdevice/lamp/timer/LampTimer.vue";
import LampStep2Timer from "../components/subdevice/lamp/timer/LampStep2Timer.vue";
import LampTimerShow from "../components/subdevice/lamp/timer/LampTimerShow.vue";

import RemoteTimer from "../components/subdevice/remote/timer/RemoteTimer.vue";
import RemoteStep2Timer from "../components/subdevice/remote/timer/RemoteStep2Timer.vue";
import RemoteTimerShow from "../components/subdevice/remote/timer/RemoteTimerShow.vue";

import TouchFaraTimer from "../components/subdevice/keytouchfara/timer/TouchFaraTimer.vue";
import TouchFaraStep2Timer from "../components/subdevice/keytouchfara/timer/TouchFaraStep2Timer.vue";
import TouchFaraTimerShow from "../components/subdevice/keytouchfara/timer/TouchFaraTimerShow.vue";

import SubRelayTimer from "../components/subdevice/subrelay/timer/SubRelayTimer.vue";
import SubRelayStep2Timer from "../components/subdevice/subrelay/timer/SubRelayStep2Timer.vue";
import SubRelayTimerShow from "../components/subdevice/subrelay/timer/SubRelayTimerShow.vue";

import ThermostatTimer from "../components/subdevice/thermostat/timer/ThermostatTimer.vue";
import ThermostatStep2Timer from "../components/subdevice/thermostat/timer/ThermostatStep2Timer.vue";
import ThermostatTimerShow from "../components/subdevice/thermostat/timer/ThermostatTimerShow.vue";

import BoxRelayTimer from "../components/subdevice/boxrelay/timer/BoxRelayTimer";
import BoxRelayTimerShow from "../components/subdevice/boxrelay/timer/BoxRelayTimerShow";
import BoxRelayStep2Timer from "../components/subdevice/boxrelay/timer/BoxRelayStep2Timer";

import CurtainTimerShow from "../components/subdevice/curtainFara/timer/CurtainTimerShow";
import CurtainTimer from "../components/subdevice/curtainFara/timer/CurtainTimer";
import CurtainStep2Timer from "../components/subdevice/curtainFara/timer/CurtainStep2Timer";

import CurtainRFTimerShow from "../components/subdevice/curtain/timer/CurtainRFTimerShow";
import CurtainRFTimer from "../components/subdevice/curtain/timer/CurtainRFTimer";
import CurtainRFStep2Timer from "../components/subdevice/curtain/timer/CurtainRFStep2Timer";

import ServiceTimerShow from "../components/subdevice/service/timer/ServiceTimerShow";
import ServiceTimer from "../components/subdevice/service/timer/ServiceTimer";
import ServiceStep2Timer from "../components/subdevice/service/timer/ServiceStep2Timer";

import ElevatorTimerShow from "../components/subdevice/elevator/timer/ElevatorTimerShow";
import ElevatorStep2Timer from "../components/subdevice/elevator/timer/ElevatorStep2Timer";

import TouchProTimer from "../components/subdevice/keytouchpro/timer/TouchProTimer.vue";
import TouchProStep2Timer from "../components/subdevice/keytouchpro/timer/TouchProStep2Timer.vue";
import TouchProTimerShow from "../components/subdevice/keytouchpro/timer/TouchProTimerShow.vue"; 

import CurtainProTimer from "../components/subdevice/curtainpro/timer/CurtainProTimer.vue";
import CurtainProStep2Timer from "../components/subdevice/curtainpro/timer/CurtainProStep2Timer.vue";
import CurtainProTimerShow from "../components/subdevice/curtainpro/timer/CurtainProTimerShow.vue"; 

import CoolerProTimer from "../components/subdevice/coolerpro/timer/CoolerProTimer.vue";
import CoolerProStep2Timer from "../components/subdevice/coolerpro/timer/CoolerProStep2Timer.vue";
import CoolerProTimerShow from "../components/subdevice/coolerpro/timer/CoolerProTimerShow.vue"; 

import ElevatorProTimer from "../components/subdevice/elevatorpro/timer/ElevatorProTimer.vue";
import ElevatorProStep2Timer from "../components/subdevice/elevatorpro/timer/ElevatorProStep2Timer.vue";
import ElevatorProTimerShow from "../components/subdevice/elevatorpro/timer/ElevatorProTimerShow.vue";

import ThermostatProTimer from "../components/subdevice/thermostatpro/timer/ThermostatProTimer.vue";
import ThermostatProStep2Timer from "../components/subdevice/thermostatpro/timer/ThermostatProStep2Timer.vue";
import ThermostatProTimerShow from "../components/subdevice/thermostatpro/timer/ThermostatProTimerShow.vue";

import ThermostaticProTimer from "../components/subdevice/thermostaticpro/timer/ThermostaticProTimer.vue";
import ThermostaticProStep2Timer from "../components/subdevice/thermostaticpro/timer/ThermostaticProStep2Timer.vue";
import ThermostaticProTimerShow from "../components/subdevice/thermostaticpro/timer/ThermostaticProTimerShow.vue";
//Timer none Home

import nBoxTimer from "../components/nonehome/8Relay/timer/NBoxRelayTimer"
import nBoxTimerShow from "../components/nonehome/8Relay/timer/NBoxRelayTimerShow"
import nBoxStep2Timer from "../components/nonehome/8Relay/timer/NBoxRelayStep2Timer"

import keyTouchTimerShow from "../components/nonehome/KeyTouch/timer/keyTouchTimerShow";
import keyTouchTimer from "../components/nonehome/KeyTouch/timer/KeyTouchTimer";
import keyTouchStep2Timer from "../components/nonehome/KeyTouch/timer/keyTouchStep2Timer";

import NCurtainTimerShow from "../components/nonehome/curtain/timer/NCurtainTimerShow";
import NCurtainTimer from "../components/nonehome/curtain/timer/NCurtainTimer";
import NCurtainStep2Timer from "../components/nonehome/curtain/timer/NCurtainStep2Timer";

import NCoolerTimerShow from "../components/nonehome/cooler/timer/NCoolerTimerShow";
import NCoolerTimer from "../components/nonehome/cooler/timer/NCoolerTimer";
import NCoolerStep2Timer from "../components/nonehome/cooler/timer/NCoolerStep2Timer";

import NThermostatTimerShow from "../components/nonehome/thermostat/timer/NThermostatTimerShow";
import NThermostatTimer from "../components/nonehome/thermostat/timer/NThermostatTimer";
import NThermostatStep2Timer from "../components/nonehome/thermostat/timer/NThermostatStep2Timer";

import SettingReport from "../components/setting/SettingReport.vue";
import SettingNoneDevice from "../components/nonehome/SettingNoneDevice";
import SettingNoneTouchKey from "../components/nonehome/SettingNoneTouchKey";
import SettingNoneN2Relay from "../components/nonehome/SettingNoneN2Relay";
import SettingNoneCurtain from "../components/nonehome/SettingNoneCurtain";
import SettingNoneCooler from "../components/nonehome/SettingNoneCooler";
import SettingNoneService from "../components/nonehome/SettingNoneService";
import SettingNoneThermostat from "../components/nonehome/SettingNoneThermostat";
import SettingNoneThermostatic from "../components/nonehome/SettingNoneThermostatic";


//Senario
import ScenarioAddList from "../components/senario/ScenarioAddList.vue";
import SenarioAbout from "../components/senario/SenarioAbout.vue";
import ScenarioEditList from "../components/senario/ScenarioEditList.vue";
import SenarioDevices from "../components/senario/SenarioDevices.vue";
import ScenarioList from "../components/senario/ScenarioList.vue";
import GetSubDeviceStr from "../components/senario/scenariosub/GetSubDeviceStr.vue";
import GetScenarioControlStr from "../components/senario/scenariocontrol/GetScenarioControlStr.vue";
// import GetDeviceTouchStr  from "../components/senario/GetDeviceTouchStr.vue";

//Relay 
import BoxRelayAdd from "../components/subdevice/boxrelay/BoxRelayAdd.vue";
import BoxRelayEdit from "../components/subdevice/boxrelay/BoxRelayEdit.vue";
import SubBoxRelay from "../components/subdevice/boxrelay/SubBoxRelay.vue";
import SubRelayItem from "../components/subdevice/boxrelay/SubRelayItem.vue";
import noneHomeRelayAdd from "../components/nonehome/8Relay/noneBoxRelayAdd.vue";
import noneHomeRelayItem from "@/components/nonehome/8Relay/noneSubRelayItem";
import noneHomeRelayEdit from "../components/nonehome/8Relay/noneBoxRelayEdit.vue";

//Modal
import ModalAddDevice from "../components/Modal/ModalAddDevice";
import ModalAddSubdeviceFara from "../components/Modal/ModalAddSubdeviceFara";
import ModalAddSubdeviceFarapol from "../components/Modal/ModalAddSubdeviceFarapol";
import ModalAddHome from "../components/Modal/ModalAddHome";
import ModalAddDeviceFarapol from "../components/Modal/ModalAddDeviceFarapol";

import SettingRoom from "../components/rooms/SettingRoom";
import RoomAdd from "../components/rooms/RoomAdd";
import RoomDevices from "../components/rooms/RoomDevices";
import showRoom from "../components/rooms/showRoom";




Vue.use(VueRouter);

const url=process.env.VUE_APP_MQTT_URL
const user=process.env.VUE_APP_MQTT_USER
const pass=process.env.VUE_APP_MQTT_PASS



Vue.use(VueMqtt, url,
  {
      clientId: 'Fara' + parseInt(Math.random() * 100000),
      username: user,
      password: pass,
      reconnectPeriod: 1000,
      clean: true,

  });
//wss://pfas.ir:8884
// Vue.use(VueMqtt, 'wss://pfas.ir:8884',
//     {
//         clientId: 'Fara' + parseInt(Math.random() * 100000),
//         username: "user1",
//         password: "f@R@housh3.14#",
//         reconnectPeriod: 1000,
//         clean: true,
 
//     });

const routes = [
    {path: '/', component: Home, name: 'home'},
    {path: '/welcome', component: Welcome, name: 'welcome', meta: "Authentication"},
    {path: '/login', component: Login, name: 'login', meta: "Authentication"},


    
    {path: '/loginEmail', component: LoginEmail, name: 'loginEmail', meta: "Authentication"},
    {path: '/registerEmail', component: RegisterEmail, name: 'registerEmail', meta: "Authentication"},
    {path: '/registerEmailVerify/:id/:new_user', component: RegisterEmailVerify, props: true, name: 'registerEmailVerify', meta: "Authentication"},
    {path: '/forgotPassEmail', component: ForgotPasswordEmail, props: true, name: 'forgotPasswordEmail', meta: "Authentication"},

    

    {path: '/settings', component: mainSettings, name: 'mainSettings', meta: "user"},
    {
        path: '/loginverify/:id/:new_user',
        component: LoginVerify,
        props: true,
        name: 'loginverify',
        meta: "Authentication"
    },
    {path: '/mainpage', component: MainPage, props: true, name: 'mainpage', meta: "user"},
    {path: '/aboutus', component: AboutUs, props: true, name: 'aboutus', meta: "user"},
    {path: '/ModalConfig', component: ModalConfig, props: true, name: 'ModalConfig'},
    {path: '/ModalAddDevice', component: ModalAddDevice, props: true, name: 'ModalAddDevice'},
    {path: '/ModalAddHome', component: ModalAddHome, props: true, name: 'ModalAddHome'},
    {path: '/ModalAddDeviceFarapol', component: ModalAddDeviceFarapol, props: true, name: 'ModalAddDeviceFarapol'},
    {
        path: '/home/:home_id/devices/:serial_number/ModalAddSubdeviceFara',
        component: ModalAddSubdeviceFara,
        props: true,
        name: 'ModalAddSubdeviceFara'
    },
    {
        path: '/home/:home_id/devices/:serial_number/ModalAddSubdeviceFarapol',
        component: ModalAddSubdeviceFarapol,
        props: true,
        name: 'ModalAddSubdeviceFarapol'
    },

    //-------------Profile
    {path: '/user/profile', component: Profile, props: true, name: 'profile', meta: "user"},
    {path: '/user/editprofile', component: ProfileEdit, props: true, name: 'profiledit', meta: "user"},
    {path: '/user/profileemail', component: ProfileChangeEmail, props: true, name: 'profilchangeemail', meta: "user"},
    {path: '/user/profilepassword', component: ProfileChangePassword, props: true, name: 'profilchangepassword', meta: "user"},

    


    {
        path: '/home/:home_id/devices/:serial_number/homeDevices',
        component: ListDevice,
        props: true,
        name: 'homeDevices',
        meta: "user"
    },
    // {
    //     path: '/home/:home_id/devices/:serial_number/editName',
    //     component: EditName,
    //     props: true,
    //     name: 'editName',
    //     meta: "user"
    // },
    {
        path: '/home/:home_id/settings/gateway/:serial_number',
        component: Settings,
        props: true,
        name: 'settings',
        meta: "user"
    },
    {
        path: '/home/:home_id/settings/ir/:serial_number',
        component: SettingsIR,
        props: true,
        name: 'settingsir',
        meta: "user"
    },
    {
        path: '/home/:home_id/settings/client/:serial_number',
        component: SettingClient,
        props: true,
        name: 'settingclient',
        meta: "user"
    },
    {
        path: '/home/:home_id/settings/clientir/:serial_number',
        component: SettingClientIR,
        props: true,
        name: 'SettingClientIR',
        meta: "user"
    },
    {
        path: '/home/:home_id/settings/gateway/:serial_number/selection',
        component: Selection_Drag,
        props: true,
        name: 'selection_drag',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/info',
        component: RelayKey,
        props: true,
        name: 'outletGatewayInfo',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/info',
        component: AboutRelay,
        props: true,
        name: 'aboutGatewayInfo',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/temp',
        component: SettingTemp,
        props: true,
        name: 'outletGatewayTemp',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/volt',
        component: SettingVolt,
        props: true,
        name: 'outletGatewayVolt',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/current',
        component: SettingCurrent,
        props: true,
        name: 'outletGatewayCurrent',
        meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/clock',
        component: SettingClock,
        props: true,
        name: 'setClock',
        meta: "user"
    },

    {
        path: '/home/:home_id/device/:serial_number/settingRoom',
        component: SettingRoom,
        props: true,
        name: "settingRoom",
        meta: "user"
    },
    {
        path: '/home/:home_id/device/:serial_number/AddRoom',
        component: RoomAdd,
        props: true,
        name: "AddRoom",
        meta: "user"
    },
    {
        path: '/home/:home_id/device/:serial_number/RoomDevices',
        component: RoomDevices,
        props: true,
        name: "RoomDevices",
        meta: "user"
    },
    {
        path: '/home/:home_id/device/:serial_number/showRoom/:uid',
        component: showRoom,
        props: true,
        name: "RoomInfo",
        meta: "user"
    },
    //---------- IR 
    {
        path: '/home/:home_id/ir/:serial_number/gateway/info',
        component: IrGateway,
        props: true,
        name: 'irGatewayInfo',
        meta: "user"
    },
    {
        path: '/home/:home_id/ircontrols/:serial_number/add',
        component: IrType,
        props: true,
        name: 'irtypeInfo',
        meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/ircontrols/:type_code/brand',
        component: IrBrand,
        props: true,
        name: 'irbrandInfo', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:type_code/:uid/irtvbrand',
        component: AddBandTv,
        props: true,
        name: 'irtvbrand', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/irtv',
        component: IrTv,
        props: true,
        name: 'irtvinfo',
        meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/irtvlearn',
        component: IrTvLearn,
        props: true,
        name: 'irtvlearninfo', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/iramplifier',
        component: IrAmplifier,
        props: true,
        name: 'iramplifierinfo', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/iramplifierlearninfo',
        component: IrAmplifierLearn,
        props: true,
        name: 'iramplifierlearninfo', meta: "user"
    },
    {path: '/home/:home_id/ir/:serial_number/control/:count/irair', component: IrAir, props: true, name: 'irairinfo'},
    {path: '/home/:home_id/ir/:serial_number/control/:count/airList', component: IrAirSaveList, props: true, name: 'irairsavelist'},
    
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/irairlearn/:keyitem',
        component: IrAirLearn,
        props: true,
        name: 'irairlearninfo', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/irairedit/:keyitem',
        component: IrAirLearnEdit,
        props: true,
        name: 'irairedit', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/irairlist',
        component: IrAirList,
        props: true,
        name: 'irairlistinfo', meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/info',
        component: AboutIr,
        props: true,
        name: 'aboutGatewayIrInfo',
        meta: "user"
    },
    {path: '/home/:home_id/ir/:serial_number/report', component: IrReport, props: true, name: 'reportir', meta: "user"},
    {
        path: '/home/:home_id/ir/:serial_number/timer',
        component: IRTimerShow,
        props: true,
        name: 'IRTimerShow',
        meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/timerList',
        component: IRTimerList,
        props: true,
        name: 'IRTimerList',
        meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/timer/control/:type_code/:count',
        component: IRStep2Timer,
        props: true,
        name: 'IRTimerStep2',
        meta: "user"
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/IrAirTimer',
        component: IrAirTimer,
        props: true,
        name: 'IrAirTimer'
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/IrAmplifierTimer',
        component: IrAmplifierTimer,
        props: true,
        name: 'IrAmplifierTimer'
    },
    {
        path: '/home/:home_id/ir/:serial_number/control/:count/IrTvTimer',
        component: IrTvTimer,
        props: true,
        name: 'IrTvTimer'
    },
    //---------- IR Path NoneHome
    {
        path: '/nonehome/ir/:serial_number/gateway-n/info',
        component: IrGatewayNone,
        props: true,
        name: 'irGatewaynone',
        meta: "user"
    },
    {
        path: '/nonehome/ircontrols/:serial_number/add',
        component: IrTypeNone,
        props: true,
        name: 'irtypenone',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/ircontrols/:type_code/brand-n',
        component: IrBrandNone,
        props: true,
        name: 'irbrandnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:type_code/:uid/irtvbrand-n',
        component: AddBandNoneTv,
        props: true,
        name: 'irtvbrandnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irtv',
        component: IrTvNone,
        props: true,
        name: 'irtvnone',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irtvlearn',
        component: IrTvLearnNone,
        props: true,
        name: 'irtvlearnnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/iramplifier',
        component: IrAmplifierNone,
        props: true,
        name: 'iramplifiernone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/iramplifierlearninfo',
        component: IrAmplifierLearnNone,
        props: true,
        name: 'iramplifierlearnnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irair-n',
        component: IrAirNone,
        props: true,
        name: 'irairnone',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irairlearn/:keyitem',
        component: IrAirLearnNone,
        props: true,
        name: 'irairlearnnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irairedit-n/:keyitem',
        component: IrAirLearnEditNone,
        props: true,
        name: 'iraireditnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/control/:count/irairlist',
        component: IrAirListNone,
        props: true,
        name: 'irairlistnone', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/about/info',
        component: AboutNoneRelay,
        props: true,
        name: 'aboutnoneGatewayInfo', meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/setting',
        component: SettingsNoneIr,
        props: true,
        name: 'SettingsNoneIr',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/setting',
        component: AboutNoneIr,
        props: true,
        name: 'AboutNoneIr',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/clock',
        component: SettingNoneClockIr,
        props: true,
        name: 'SettingNoneClockIr',
        meta: "user"
    },
    {
        path: '/nonehome/ir/:serial_number/report-n',
        component: IrReportNone,
        props: true,
        name: 'reportirnone',
        meta: "user"
    },

    // client

    {
        path: '/home/:home_id/client/:serial_number/managementclient',
        component: ManagementClient,
        props: true,
        name: 'managementclientinfo', meta: "user"
    },
    {
        path: '/nonehome/client/:serial_number/managementclient',
        component: noneHomeClient,
        props: true,
        name: 'noneHomeClientInfo', meta: "user"
    },
    {
        path: '/nonehome/client/:serial_number/delManagementclient',
        component: noneDelClient,
        props: true,
        name: 'noneDelClient', meta: "user"
    },
    {
        path: '/nonehome/client/:serial_number/noneAddClient',
        component: noneAddClient,
        props: true,
        name: 'noneAddClient', meta: "user"
    },
    {
        path: '/nonehome/client/:serial_number/noneAddClientVerify',
        component: noneAddClientVerify,
        props: true,
        name: 'noneAddClientVerify', meta: "user"
    },
    {
        path: '/nonehome/client/:serial_number/delManagementClientVerify',
        component: noneDelClientVerify,
        props: true,
        name: 'noneDelClientVerify', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/managementclientclient',
        component: ManagementClientClient,
        props: true,
        name: 'managementclientclientinfo', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/addclient/:type',
        component: AddClient,
        props: true,
        name: 'AddClientinfo', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/addclient/email',
        component: AddClientEmail,
        props: true,
        name: 'AddClientEmailInfo', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/:verify/clientverify/:type',
        component: AddClientVerify,
        props: true,
        name: 'AddClientVerifyinfo', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/delclient/:type',
        component: DelClient,
        props: true,
        name: 'DelClientinfo', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/:verify/delclientverify/:type',
        component: DelClientVerify,
        props: true,
        name: 'DelClientVerifyinfo', meta: "user"
    },
    {path: '/home/:home_id/client/:serial_number/admin/:type', component: SetAdmin, props: true, name: 'SetAdmin'},
    {path: '/home/:home_id/client/:serial_number/admin/:type', component: SetAdminEmail, props: true, name: 'SetAdminEmail'},
    
    {
        path: '/home/:home_id/client/:serial_number/setclient/:type',
        component: SetClient,
        props: true,
        name: 'SetClient', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/SetClientEmail/:type',
        component: SetClientEmail,
        props: true,
        name: 'SetClientEmail', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/:verify/adminverify/:type',
        component: SetAdminVerify,
        props: true,
        name: 'SetAdminVerify', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/:verify/clientverify:type',
        component: SetClientVerify,
        props: true,
        name: 'SetClientVerify', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/delhome',
        component: SettingClientDelHome,
        props: true,
        name: 'clientdelhome', meta: "user"
    },
    {
        path: '/home/:home_id/client/:serial_number/:verify/verify',
        component: SettingDelHomeClientVerify,
        props: true,
        name: 'clientdelhomeverify', meta: "user"
    },
    {
        path: '/home/:home_id/device/:serial_number/add',
        component: AddDevice,
        props: true,
        name: 'homeAddDevice',
        meta: "user"
    },

    //---------- SubDevice
    {
        path: '/home/:home_id/curtain/:serial_number/subdevice/:count/curtaininfo',
        component: SubCurtain,
        props: true,
        name: 'curtainInfo', meta: "user"
    },
    {
        path: '/home/:home_id/remote/:serial_number/subdevice/:count/remoteinfo',
        component: SubRemote,
        props: true,
        name: 'remoteInfo', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/lampinfo',
        component: SubLamp,
        props: true,
        name: 'SublampInfo', meta: "user"
    },
    {
        path: '/home/:home_id/cooler/:serial_number/subdevice/:count/coolerinfo',
        component: SubCooler,
        props: true,
        name: 'SubcoolerInfo', meta: "user"
    },
    {
        path: '/home/:home_id/cooler/:serial_number/subdevice/:count/coolerFaraInfo',
        component: SubCoolerFara,
        props: true,
        name: 'SubCoolerFaraInfo', meta: "user"
    },
    {
        path: '/home/:home_id/elevator/:serial_number/subdevice/:count/subElevatorInfo',
        component: subElevator,
        props: true,
        name: 'subElevatorInfo', meta: "user"
    },
    {
        path: '/home/:home_id/elevatorPro/:serial_number/subdevice/:count/setting',
        component: ElevatorProSetting,
        props: true,
        name: 'ElevatorProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/elevator/:serial_number/subdevice/:count/about',
        component: AboutElevator,
        props: true,
        name: 'aboutelevator', meta: "user"
    },
    {
        path: '/home/:home_id/elevator/:serial_number/subdevice/:count/subDoorInfo',
        component: subDoor,
        props: true,
        name: 'SubdoorInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdoor/:serial_number/subdevice/:count/SubDoorShutter',
        component: SubDoorShutter,
        props: true,
        name: 'SubDoorShutter', meta: "user"
    },
    {
        path: '/home/:home_id/subdoor/:serial_number/subdevice/:count/about',
        component: AboutShutter,
        props: true,
        name: 'AboutShutter', meta: "user"
    },
    {
        path: '/home/:home_id/subservice/:serial_number/subdevice/:count/subService',
        component: SubService,
        props: true,
        name: 'SubServiceInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subservice/:serial_number/subdevice/:count/about',
        component: AboutSubService,
        props: true,
        name: 'aboutservice', meta: "user"
    },
    {
        path: '/home/:home_id/elevator/:serial_number/subdevice/:count/subService/edit',
        component: EditDevice,
        props: true,
        name: 'EditDevice', meta: "user"
    },
    {
        path: '/home/:home_id/cooler/:serial_number/subdevice/:count/about',
        component: AboutSubCoolerFara,
        props: true,
        name: 'SubCoolerFaraAbout', meta: "user"
    },
    {
        path: '/home/:home_id/touch/:serial_number/subdevice/:count/KeyTouchinfo',
        component: SubKeyTouch,
        props: true,
        name: 'SubKeyTouchInfo', meta: "user"
    },
    {
        path: '/home/:home_id/touchFara/:serial_number/subdevice/:count/KeyTouchFaraInfo',
        component: SubKeyTouchFara,
        props: true,
        name: 'SubKeyTouchFaraInfo', meta: "user"
    },
    {
        path: '/home/:home_id/tochPro/:serial_number/subdevice/:count/KeyTouchProInfo',
        component: SubKeyTouchPro,
        props: true,
        name: 'SubKeyTouchProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/touchPro/:serial_number/subdevice/:count/editRGB',
        component: EditRGBPro,
        props: true,
        name: 'EditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/touchPro/:serial_number/subdevice/:count/statusWifi',
        component: TouchProStatusWifi,
        props: true,
        name: 'TouchProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/touchPro/:serial_number/subdevice/:count/setting',
        component: TouchProSetting,
        props: true,
        name: 'TouchProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtainPro/:count/curtainProInfo',
        component: SubCurtainPro,
        props: true,
        name: 'SubCurtainProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtainPro/:count/editRGB',
        component: CurtainEditRGBPro,
        props: true,
        name: 'CurtainEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtainPro/:count/statusWifi',
        component: CurtainProStatusWifi,
        props: true,
        name: 'CurtainProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtain/:count/setting',
        component: CurtainProSetting,
        props: true,
        name: 'CurtainProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtain/:count/about',
        component: AboutCurtainPro,
        props: true,
        name: 'AboutCurtainPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/shutterPro/:count/shutterProInfo',
        component: SubDoorShutterPro,
        props: true,
        name: 'SubDoorShutterProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/shutterPro/:count/editRGB',
        component: DoorSutterEditRGBPro,
        props: true,
        name: 'DoorShutterEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/shutterPro/:count/statusWifi',
        component: DoorShutterProStatusWifi,
        props: true,
        name: 'DoorShutterProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/shutterPro/:count/setting',
        component: DoorProSetting,
        props: true,
        name: 'DoorProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/shutterPro/:count/about',
        component: AboutDoorShutterPro,
        props: true,
        name: 'AboutDoorShutterPro', meta: "user"
    },
    
    {
        path: '/home/:home_id/subdevice/:serial_number/coolerPro/:count/Info',
        component: SubCoolerPro,
        props: true,
        name: 'SubCoolerProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/coolerPro/:count/editRGB',
        component: CoolerEditRGBPro,
        props: true,
        name: 'CoolerEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/coolerPro/:count/statusWifi',
        component: CoolerProStatusWifi,
        props: true,
        name: 'CoolerProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/coolerPro/:count/setting',
        component: CoolerProSetting,
        props: true,
        name: 'CoolerProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/coolerPro/:count/about',
        component: AboutCoolerPro,
        props: true,
        name: 'AboutCoolerPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevatorPro/:count/Info',
        component: SubElevatorPro,
        props: true,
        name: 'SubElevatorProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevatorPro/:count/editRGB',
        component: ElevatorEditRGBPro,
        props: true,
        name: 'ElevatorEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevatorPro/:count/statusWifi',
        component: ElevatorProStatusWifi,
        props: true,
        name: 'ElevatorProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevatorPro/:count/about',
        component: AboutElevatorPro,
        props: true,
        name: 'AboutElevatorPro', meta: "user"
    },



    {
        path: '/home/:home_id/subdevice/:serial_number/servicePro/:count/Info',
        component: SubServicePro,
        props: true,
        name: 'SubServiceProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/servicePro/:count/editRGB',
        component: ServiceEditRGBPro,
        props: true,
        name: 'ServiceEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/servicePro/:count/statusWifi',
        component: ServiceProStatusWifi,
        props: true,
        name: 'ServiceProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/servicePro/:count/setting',
        component: ServiceProSetting,
        props: true,
        name: 'ServiceProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/servicePro/:count/about',
        component: AboutServicePro,
        props: true,
        name: 'AboutServicePro', meta: "user"
    },

    
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostatPro/:count/Info',
        component: SubThermostatPro,
        props: true,
        name: 'SubThermostatProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostatPro/:count/editRGB',
        component: ThermostatEditRGBPro,
        props: true,
        name: 'ThermostatEditRGBPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostatPro/:count/statusWifi',
        component:ThermostatProStatusWifi,
        props: true,
        name: 'ThermostatProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostatPro/:count/setting',
        component:ThermostatProSetting,
        props: true,
        name: 'ThermostatProSetting', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostatPro/:count/about',
        component: AboutThermostatPro,
        props: true,
        name: 'AboutThermostatPro', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostaticPro/:count/Info',
        component: SubThermostaticPro,
        props: true,
        name: 'SubThermostaticProInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostaticPro/:count/statusWifi',
        component:ThermostaticProStatusWifi,
        props: true,
        name: 'ThermostaticProStatusWifi', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/thermostaticPro/:count/about',
        component: AboutThermostaticPro,
        props: true,
        name: 'AboutThermostaticPro', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/SubScenarioKey',
        component: SubScenarioKey,
        props: true,
        name: 'SubScenarioKeyInfo', meta: "user"
    },
    {
        path: '/home/:home_id/test/:serial_number/subdevice/:count/test',
        component: Test,
        props: true,
        name: 'Testinfo', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/SubScenarioKeyConfig',
        component: SubScenarioKeyConfig,
        props: true,
        name: 'SubScenarioKeyConfig', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/KeyTouch/edit',
        component: CmpEdit,
        props: true,
        name: 'SubKeyTouchEdit', meta: "user"
    },
        // -- SubDevice About
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/about',
        component: AboutKeyFara,
        props: true,
        name: 'SubAboutKeyFara', meta: "user"
    },
    {
        path: '/home/:home_id/touchpro/:serial_number/subdevice/:count/about',
        component: AboutKeyPro,
        props: true,
        name: 'SubAboutKeyPro', meta: "user"
    },
    {
        path: '/home/:home_id/lock/:serial_number/subdevice/:count/lockinfo',
        component: SubLock,
        props: true,
        name: 'SublockInfo', meta: "user"
    },
    {
        path: '/home/:home_id/relay/:serial_number/subdevice/:count/Subrelayinfo',
        component: SubRelay,
        props: true,
        name: 'SubrelayInfo', meta: "user"
    },
    {
        path: '/home/:home_id/thermostat/:serial_number/subdevice/:count/thermostat',
        component: Thermostat,
        props: true,
        name: 'thermostatInfo', meta: "user"
    },
    {
        path: '/home/:home_id/thermostat/:serial_number/subdevice/:count/about',
        component: AboutThermostat,
        props: true,
        name: 'aboutthermostat', meta: "user"
    },
    {path: '/home/dimmer/', component: SubDimmer, props: true, name: 'dimmerinfo', meta: "user"},

    //---------- SubDevice  Farahoush
    {
        path: '/home/:home_id/curtainfara/:serial_number/subdevice/:count/curtainfarainfo',
        component: SubCurtainFara,
        props: true,
        name: 'curtainFaraInfo', meta: "user"
    },
     {
        path: '/home/:home_id/curtainfara/:serial_number/subdevice/:count/about',
        component: AboutCurtain,
        props: true,
        name: 'aboutcurtain', meta: "user"
    },
    // ------------  ******  Alarm
    {
        path: '/home/:home_id/alarmfara/:serial_number/subdevice/:count/alarmfarainfo',
        component: SubAlarmFara,
        props: true,
        name: 'SubAlarmFaraInfo', meta: "user"
    },
    // ---- Alarm Setting
    {
        path: '/home/:home_id/alarmfara/:serial_number/settingalarm',
        component: SettingAlarm,
        props: true,
        name: 'SettingAlarmInfo', meta: "user"
    },
     // ---- Alarm Ant
     {
        path: '/home/:home_id/alarmfara/:serial_number/ant',
        component: Ant,
        props: true,
        name: 'AntInfo', meta: "user"
    },
     // ---- Alarm ZoneSms
    {
        path: '/home/:home_id/alarmfara/:serial_number/zonesms',
        component: ZoneSms,
        props: true,
        name: 'ZoneSmsInfo', meta: "user"
    },
     // ---- Alarm ZoneSms
     {
        path: '/home/:home_id/alarmfara/:serial_number/sound',
        component: OutputSound,
        props: true,
        name: 'OutputSoundInfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/time',
        component: SirenTime,
        props: true,
        name: 'SirenTimeInfo', meta: "user"
    },
      // ---- Alarm Privacy
    {
        path: '/home/:home_id/alarmfara/:serial_number/privacy',
        component: PrivacyReport,
        props: true,
        name: 'PrivacyReportInfo', meta: "user"
    },
    // ---- Alarm Relay Timer
    {
        path: '/home/:home_id/alarmfara/:serial_number/relay4/timer',
        component: Relay4Timer,
        props: true,
        name: 'relay4timer', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/relay4/infotimer',
        component: Relay4Step2Timer,
        props: true,
        name: 'relay4Step2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/relay4/infotimershow',
        component: Relay4TimerShow,
        props: true,
        name: 'relay4TimerShow', meta: "user"
    },
     // ---- Alarm Alarm
    {
        path: '/home/:home_id/alarmfara/:serial_number/about',
        component: AboutAlarm,
        props: true,
        name: 'AboutAlarmInfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/wireless',
        component: WirelessList,
        props: true,
        name: 'WirelessInfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/wireless24',
        component: Wireless24List,
        props: true,
        name: 'Wireless24Info', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/add',
        component: WirelessAdd,
        props: true,
        name: 'Wirelessadd', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/add24',
        component: Wireless24Add,
        props: true,
        name: 'Wireless24add', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/remote',
        component: RemoteList,
        props: true,
        name: 'RemoteAlarmInfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/remoteAdd',
        component: RemoteAdd,
        props: true,
        name: 'RemoteAdd', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/zone',
        component: ZoneList,
        props: true,
        name: 'ZoneInfo', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/ZoneAdd',
        component: ZoneAdd,
        props: true,
        name: 'ZoneAdd', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/relay4',
        component: Relay4Setting,
        props: true,
        name: 'Relay4Setting', meta: "user"
    },
    {
        path: '/home/:home_id/alarmfara/:serial_number/relay4/Instant',
        component: Relay4Instant,
        props: true,
        name: 'Relay4Instant', meta: "user"
    },
    // ---- End Alarm Alarm

    // Start Subdevice Timer & Fara & Pro

    {
        path: '/home/:home_id/subdevice/:serial_number/curtainfara/:count/CurtainTimer',
        component: CurtainTimer,
        props: true,
        name: 'CurtainTimer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtainfara/:count/CurtainStep2Timer',
        component: CurtainStep2Timer,
        props: true,
        name: 'CurtainStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtainfara/:count/CurtainTimerShow',
        component: CurtainTimerShow,
        props: true,
        name: 'CurtainTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtain/:count/CurtainRFTimer',
        component: CurtainRFTimer,
        props: true,
        name: 'CurtainRFTimer', meta: "user"
    },
      {
        path: '/home/:home_id/subdevice/:serial_number/curtain/:count/CurtainRFStep2Timer',
        component: CurtainRFStep2Timer,
        props: true,
        name: 'CurtainRFStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/curtain/:count/CurtainRFTimerShow',
        component: CurtainRFTimerShow,
        props: true,
        name: 'CurtainRFTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/WC/:count/ServiceTimerShow',
        component: ServiceTimerShow,
        props: true,
        name: 'ServiceTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/WC/:count/ServiceTimer',
        component: ServiceTimer,
        props: true,
        name: 'ServiceTimer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/WC/:count/ServiceStep2Timer',
        component: ServiceStep2Timer,
        props: true,
        name: 'ServiceStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/Cooler/:count/timerCooler',
        component: CoolerFaraTimer,
        props: true,
        name: 'CoolerFaraTimer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/Cooler/:count/infoCooler',
        component: CoolerFaraStep2Timer,
        props: true,
        name: 'CoolerStep2TimerInfo', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/Cooler/:count/infoCoolerShow',
        component: CoolerFaraTimerShow,
        props: true,
        name: 'CoolerFaraTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevator/:count/ElevatorStep2Timer',
        component: ElevatorStep2Timer,
        props: true,
        name: 'ElevatorStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/subdevice/:serial_number/elevator/:count/ElevatorTimerShow',
        component: ElevatorTimerShow,
        props: true,
        name: 'ElevatorTimerShow', meta: "user"
    },
    
     // keytouch
     {
        path: '/home/:home_id/keytouch/:serial_number/subdevice/:count/timerkeytouch',
        component: TouchTimer,
        props: true,
        name: 'Touchtimer', meta: "user"
    },
    {
        path: '/home/:home_id/keytouch/:serial_number/subdevice/:count/infotimerkeytouch',
        component: TouchStep2Timer,
        props: true,
        name: 'TouchStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/keytouch/:serial_number/subdevice/:count/infotimershowkeytouch',
        component: TouchTimerShow,
        props: true,
        name: 'TouchTimerShow', meta: "user"
    },
    // SubRelay
    {
        path: '/home/:home_id/subrelay/:serial_number/subdevice/:count/timersubrelay',
        component: SubRelayTimer,
        props: true,
        name: 'SubRelaytimer', meta: "user"
    },
    {
        path: '/home/:home_id/subrelay/:serial_number/subdevice/:count/infotimersubrelay',
        component: SubRelayStep2Timer,
        props: true,
        name: 'SubRelayStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/subrelay/:serial_number/subdevice/:count/infotimershowsubrelay',
        component: SubRelayTimerShow,
        props: true,
        name: 'SubRelayTimerShow', meta: "user"
    },

    // lamp
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/timerlamp',
        component: LampTimer,
        props: true,
        name: 'Lamptimer', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/infotimerlamp',
        component: LampStep2Timer,
        props: true,
        name: 'LampStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/infotimershowlamp',
        component: LampTimerShow,
        props: true,
        name: 'LampTimerShow', meta: "user"
    },

    // remote
    {
        path: '/home/:home_id/remote/:serial_number/subdevice/:count/timerremote',
        component: RemoteTimer,
        props: true,
        name: 'Remotetimer', meta: "user"
    },
    {
        path: '/home/:home_id/remote/:serial_number/subdevice/:count/infotimerremote',
        component: RemoteStep2Timer,
        props: true,
        name: 'RemoteStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/remote/:serial_number/subdevice/:count/infotimershowremote',
        component: RemoteTimerShow,
        props: true,
        name: 'RemoteTimerShow', meta: "user"
    },
    // keytouch Fara
    {
        path: '/home/:home_id/keytouchfara/:serial_number/subdevice/:count/timertouchFara',
        component: TouchFaraTimer,
        props: true,
        name: 'TouchFaratimer', meta: "user"
    },
    {
        path: '/home/:home_id/keytouchfara/:serial_number/subdevice/:count/infotimertouchFara',
        component: TouchFaraStep2Timer,
        props: true,
        name: 'TouchFaraStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/keytouchfara/:serial_number/subdevice/:count/infotimershowtouchFara',
        component: TouchFaraTimerShow,
        props: true,
        name: 'TouchFaraTimerShow', meta: "user"
    },
    // keytouch Pro Timer
    {
        path: '/home/:home_id/keytouchpro/:serial_number/subdevice/:count/timertouchPro',
        component: TouchProTimer,
        props: true,
        name: 'TouchProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/keytouchpro/:serial_number/subdevice/:count/infotimertouchPro',
        component: TouchProStep2Timer,
        props: true,
        name: 'TouchProStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/keytouchpro/:serial_number/subdevice/:count/infotimershowtouchPro',
        component: TouchProTimerShow,
        props: true,
        name: 'TouchProTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/curtainpro/:serial_number/subdevice/:count/timercurtainPro',
        component: CurtainProTimer,
        props: true,
        name: 'CurtainProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/curtainpro/:serial_number/subdevice/:count/infotimercurtainPro',
        component: CurtainProStep2Timer,
        props: true,
        name: 'CurtainProStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/curtainpro/:serial_number/subdevice/:count/infotimershowcurtainPro',
        component: CurtainProTimerShow,
        props: true,
        name: 'CurtainProTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/timercoolerPro',
        component: CoolerProTimer,
        props: true,
        name: 'CoolerProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/infotimercoolerPro',
        component: CoolerProStep2Timer,
        props: true,
        name: 'CoolerProStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/infotimershowcoolerPro',
        component: CoolerProTimerShow,
        props: true,
        name: 'CoolerProTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/timerelevatorPro',
        component: ElevatorProTimer,
        props: true,
        name: 'ElevatorProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/infotimerelevatorPro',
        component: ElevatorProStep2Timer,
        props: true,
        name: 'ElevatorProStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/elevatorpro/:serial_number/subdevice/:count/infotimershowelevatorPro',
        component: ElevatorProTimerShow,
        props: true,
        name: 'ElevatorProTimerShow', meta: "user"
    },

    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/timerthermostatPro',
        component: ThermostatProTimer,
        props: true,
        name: 'ThermostatProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/infotimerthermostatPro',
        component: ThermostatProStep2Timer,
        props: true,
        name: 'ThermostatProStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/coolerpro/:serial_number/subdevice/:count/infotimershowelthermostatPro',
        component: ThermostatProTimerShow,
        props: true,
        name: 'ThermostatProTimerShow', meta: "user"
    },

    {
        path: '/home/:home_id/thermostatic/:serial_number/subdevice/:count/timerthermostaticPro',
        component: ThermostaticProTimer,
        props: true,
        name: 'ThermostaticProtimer', meta: "user"
    },
    {
        path: '/home/:home_id/thermostatic/:serial_number/subdevice/:count/infotimerthermostaticPro',
        component: ThermostaticProStep2Timer,
        props: true,
        name: 'ThermostaticProStep2Timer', meta: "user"
    },
    {
        path: '/home/:home_id/thermostatic/:serial_number/subdevice/:count/infotimershowelthermostaticPro',
        component: ThermostaticProTimerShow,
        props: true,
        name: 'ThermostaticProTimerShow', meta: "user"
    },
    // End Subdevice Timer
    // Start  Gadget Timer
    {
        path: '/Gadget/keyTouch/:serial_number/keyTouchTimerShow',
        component: keyTouchTimerShow,
        props: true,
        name: 'keyTouchTimerShow', meta: "user"
    },
    {
        path: '/Gadget/keyTouch/:serial_number/keyTouchTimer',
        component: keyTouchTimer,
        props: true,
        name: 'keyTouchTimer', meta: "user"
    },
    {
        path: '/Gadget/keyTouch/:serial_number/keyTouchStep2Timer',
        component: keyTouchStep2Timer,
        props: true,
        name: 'keyTouchStep2Timer', meta: "user"
    },
    {
        path: '/Gadget/curtain/:serial_number/NCurtainTimerShow',
        component: NCurtainTimerShow,
        props: true,
        name: 'NCurtainTimerShow', meta: "user"
    },
    {
        path: '/Gadget/curtain/:serial_number/NCurtainTimer',
        component: NCurtainTimer,
        props: true,
        name: 'NCurtainTimer', meta: "user"
    },
    {
        path: '/Gadget/curtain/:serial_number/NCurtainStep2Timer',
        component: NCurtainStep2Timer,
        props: true,
        name: 'NCurtainStep2Timer', meta: "user"
    },

    {
        path: '/Gadget/cooler/:serial_number/NCoolerTimerShow',
        component: NCoolerTimerShow,
        props: true,
        name: 'NCoolerTimerShow', meta: "user"
    },
    {
        path: '/Gadget/cooler/:serial_number/NCoolerTimer',
        component: NCoolerTimer,
        props: true,
        name: 'NCoolerTimer', meta: "user"
    },
    {
        path: '/Gadget/cooler/:serial_number/NCoolerStep2Timer',
        component: NCoolerStep2Timer,
        props: true,
        name: 'NCoolerStep2Timer', meta: "user"
    },
    {
        path: '/Gadget/thermostat/:serial_number/NThermostatTimerShow',
        component: NThermostatTimerShow,
        props: true,
        name: 'NThermostatTimerShow', meta: "user"
    },
    {
        path: '/Gadget/thermostat/:serial_number/NThermostatTimer',
        component: NThermostatTimer,
        props: true,
        name: 'NThermostatTimer', meta: "user"
    },
    {
        path: '/Gadget/thermostat/:serial_number/NThermostatStep2Timer',
        component: NThermostatStep2Timer,
        props: true,
        name: 'NThermostatStep2Timer', meta: "user"
    },
    // End  Gadget Timer 
    //----------None Home
    {
        path: '/nonehome/outlet/:serial_number/gateway/info',
        component: NonehomeRelyKey,
        props: true,
        name: 'GatewayNoneInfo', meta: "user"
    },
    {
        path: '/nonehome/keytouch/:serial_number/info',
        component: keytouch,
        props: true,
        name: 'keyTouchNoneInfo', meta: "user"
    },
    {
        path: '/nonehome/keytouch/:serial_number/about',
        component: keyTouchAbout,
        props: true,
        name: 'keytouchaboutnone', meta: "user"
    },
    {
        path: '/nonehome/keytouch/:serial_number/edit',
        component: KeyTouchEdit,
        props: true,
        name: 'KeyTouchEdit', meta: "user"
    },
    {
        path: '/nonehome/keytouch/:serial_number/convert',
        component: keyTouchConvert,
        props: true,
        name: 'keyTouchConvert', meta: "user"
    },
    {
        path: '/nonehome/keytouch/:serial_number/editRGB',
        component: EditRGB,
        props: true,
        name: 'EditRGB', meta: "user"
    },
    {
        path: '/nonehome/SettingsNone/gateway/:serial_number',
        component: SettingsNone,
        props: true,
        name: 'SettingsNone',
        meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/temp',
        component: SettingNoneTemp,
        props: true,
        name: 'noneGatewayTemp', meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/volt',
        component: SettingNoneVolt,
        props: true,
        name: 'noneGatewayVolt', meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/current',
        component: SettingNoneCurrent,
        props: true,
        name: 'noneGatewayCurrent', meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/:type/clock',
        component: SettingNoneClock,
        props: true,
        name: 'noneSetClock', meta: "user"
    },
    {
        path: '/nonehome/SettingsNone/gadget/:serial_number',
        component: SettingNoneDevice,
        props: true,
        name: 'SettingNone',
        meta: "user"
    },
    {
        path: '/nonehome/SettingsNoneToch/gadget/:serial_number',
        component: SettingNoneTouchKey,
        props: true,
        name: 'SettingNoneTouchKey',
        meta: "user"
    },
    {
        path: '/nonehome/SettingsNone2Relay/gadget/:serial_number',
        component: SettingNoneN2Relay,
        props: true,
        name: 'SettingNoneN2Relay',
        meta: "user"
    },
    {
        path: '/nonehome/SettingNoneCurtain/gadget/:serial_number',
        component: SettingNoneCurtain,
        props: true,
        name: 'SettingNoneCurtain',
        meta: "user"
    },
    {
        path: '/nonehome/SettingNoneCooler/gadget/:serial_number',
        component: SettingNoneCooler,
        props: true,
        name: 'SettingNoneCooler',
        meta: "user"
    },
    {
        path: '/nonehome/SettingNoneService/gadget/:serial_number',
        component: SettingNoneService,
        props: true,
        name: 'SettingNoneService',
        meta: "user"
    },
    {
        path: '/nonehome/SettingNoneThermostat/gadget/:serial_number',
        component: SettingNoneThermostat,
        props: true,
        name: 'SettingNoneThermostat',
        meta: "user"
    },
    {
        path: '/nonehome/SettingNoneThermostatic/gadget/:serial_number',
        component: SettingNoneThermostatic,
        props: true,
        name: 'SettingNoneThermostatic',
        meta: "user"
    },
    {
        path: '/nonehome/2Relay/:serial_number/edit',
        component: N2RelayEdit,
        props: true,
        name: 'N2RelayEdit', meta: "user"
    },
    {
        path: '/nonehome/gadget/:serial_number/2relay',
        component: N2Relay,
        props: true,
        name: 'N2RelayInfo', meta: "user"
    },
    {
        path: '/nonehome/gadget/:serial_number/about',
        component: N2RelayAbout,
        props: true,
        name: 'n2relayabout', meta: "user"
    },
    {
        path: '/nonehome/curtain/:serial_number/edit',
        component: NCurtainEdit,
        props: true,
        name: 'NCurtainEdit', meta: "user"
    },
    {
        path: '/nonehome/curtain/:serial_number',
        component: NCurtain,
        props: true,
        name: 'NCurtainInfo', meta: "user"
    },
    {
        path: '/nonehome/curtain/:serial_number/about',
        component: NCurtainAbout,
        props: true,
        name: 'ncurtainabout', meta: "user"
    },
    {
        path: '/nonehome/cooler/:serial_number/edit',
        component: NCoolerEdit,
        props: true,
        name: 'NCoolerEdit', meta: "user"
    },
    {
        path: '/nonehome/cooler/:serial_number',
        component: NCooler,
        props: true,
        name: 'NCoolerInfo', meta: "user"
    },
    {
        path: '/nonehome/cooler/:serial_number/about',
        component: NCoolerAbout,
        props: true,
        name: 'NCoolerAbout', meta: "user"
    },
    {
        path: '/nonehome/service/:serial_number/edit',
        component: NServiceEdit,
        props: true,
        name: 'NServiceEdit', meta: "user"
    },
    {
        path: '/nonehome/service/:serial_number',
        component: NService,
        props: true,
        name: 'NServiceInfo', meta: "user"
    },
    {
        path: '/nonehome/service/:serial_number/about',
        component: NServiceAbout,
        props: true,
        name: 'NServiceAbout', meta: "user"
    },
    {
        path: '/nonehome/service/:serial_number/rgb',
        component: NServiceRgb,
        props: true,
        name: 'NServiceRgb', meta: "user"
    },
    {
        path: '/nonehome/thermostat/:serial_number/edit',
        component: NThermostatEdit,
        props: true,
        name: 'NThermostatEdit', meta: "user"
    },
    {
        path: '/nonehome/thermostat/:serial_number',
        component: NThermostat,
        props: true,
        name: 'NThermostatInfo', meta: "user"
    },
    {
        path: '/nonehome/thermostat/:serial_number/about',
        component: NThermostatAbout,
        props: true,
        name: 'NThermostatAbout', meta: "user"
    },
    {
        path: '/nonehome/thermostatic/:serial_number/edit',
        component: NThermostaticEdit,
        props: true,
        name: 'NThermostaticEdit', meta: "user"
    },
    {
        path: '/nonehome/thermostatic/:serial_number',
        component: NThermostatic,
        props: true,
        name: 'NThermostaticInfo', meta: "user"
    },
    {
        path: '/nonehome/thermostatic/:serial_number/about',
        component: NThermostaticAbout,
        props: true,
        name: 'NThermostaticAbout', meta: "user"
    },
    //---------Timer
    //  outlet NoneHome

    {
        path: '/nonehome/outlet/:serial_number/gateway/timeroutlet',
        component: NOutletTimer,
        props: true,
        name: 'noutlettimer', meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/infotimer',
        component: NOutletStep2Timer,
        props: true,
        name: 'nOutletStep2Timerinfo', meta: "user"
    },
    {
        path: '/nonehome/outlet/:serial_number/gateway/infotimershow',
        component: NOutletTimerShow,
        props: true,
        name: 'nOutletTimerShow', meta: "user"
    },
     //  Relay NoneHome
     {
        path: '/noneHome/Relay/:serial_number/WIFI/Timer',
        component: nBoxTimer,
        props: true,
        name: 'nRelayTimer', meta: "user"
    },
    {
        path: '/noneHome/Relay/:serial_number/WIFI/infoTimer',
        component: nBoxStep2Timer,
        props: true,
        name: 'nRelayStep2Timer', meta: "user"
    },
    {
        path: '/noneHome/Relay/:serial_number/WIFI/infoTimerShow',
        component: nBoxTimerShow,
        props: true,
        name: 'nRelayTimerShow', meta: "user"
    },
    //---------Timer
    //  outlet
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/timeroutlet',
        component: OutletTimer,
        props: true,
        name: 'outlettimer', meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/infotimer',
        component: OutletStep2Timer,
        props: true,
        name: 'OutletStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/outlet/:serial_number/gateway/infotimershow',
        component: OutletTimerShow,
        props: true,
        name: 'OutletTimerShow', meta: "user"
    },

   
    //relay
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/:sub_serial/timerBoxRelay',
        component: BoxRelayTimer,
        props: true,
        name: 'boxRelayTimer', meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/:sub_serial/infoTimerBoxRelay',
        component: BoxRelayStep2Timer,
        props: true,
        name: 'boxRelayTimerInfo', meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/:sub_serial/infoTimerShowBoxRelay',
        component: BoxRelayTimerShow,
        props: true,
        name: 'boxRelayTimerShow', meta: "user"
    },


    // Thermostat
    {
        path: '/home/:home_id/Thermostat/:serial_number/subdevice/:count/timerth',
        component: ThermostatTimer,
        props: true,
        name: 'Thermostattimer', meta: "user"
    },
    {
        path: '/home/:home_id/Thermostat/:serial_number/subdevice/:count/infotimerth',
        component: ThermostatStep2Timer,
        props: true,
        name: 'ThermostatStep2Timerinfo', meta: "user"
    },
    {
        path: '/home/:home_id/Thermostat/:serial_number/subdevice/:count/infotimershowth',
        component: ThermostatTimerShow,
        props: true,
        name: 'ThermostatTimerShow', meta: "user"
    },
    {
        path: '/home/:home_id/device/:serial_number/SettingReport',
        component: SettingReport,
        props: true,
        name: 'SettingReport', meta: "user"
    },
    //Scenario
    {
        path: '/home/:home_id/Gatwaye/ir/:serial_number/Scenarios/Add',
        component: ScenarioAddList,
        props: true,
        name: 'ScenarioAddList',
        meta: "user"
    },
    {
        path: '/home/:home_id/lamp/ir/:serial_number/Scenarios/Uid/Edit',
        component: ScenarioEditList,
        props: true,
        name: 'ScenarioEditList',
        meta: "user"
    },
    {
        path: '/home/:home_id/lamp/:serial_number/subdevice/:count/about',
        component: SenarioAbout,
        props: true,
        name: 'SenarioAbout', meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/ir/:serial_number/devices',
        component: SenarioDevices,
        props: true,
        name: 'SenarioDevices',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/ir/:serial_number/Scenarios',
        component: ScenarioList,
        props: true,
        name: 'ScenarioList',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/ir/:serial_number/Scenarios/Add/sub/:subdevice_serial/devicesStr',
        component: GetSubDeviceStr,
        props: true,
        name: 'GetSubDeviceStr',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/ir/:serial_number/Scenarios/Add/sub/:typecode/:count/ControlStr',
        component: GetScenarioControlStr,
        props: true,
        name: 'GetControlStr',
        meta: "user"
    },
    // {path:'/home/:home_id/Gatwaye/ir/:serial_number/sub/:subdevice_serial/:pol/devicesStr' ,component:GetDeviceTouchStr, props: true,name:'GetDeviceTouchStr'},
    // {path:'/Senario/:userId' ,component:GetDeviceStr,  props: true, name:'GetDeviceStr'},
    // {path:'/home/Senario' ,component:GetDeviceStr,  props: (route) => {
    //   console.log("entered route");
    //   console.log(route);
    //   return { ...route.params };
    // },name:'GetDeviceStr'},

    //Relay
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/:type_code/BoxRelayAdd',
        component: BoxRelayAdd,
        props: true,
        name: 'boxrelayadd',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/:sub_serial/BoxRelayEdit',
        component: BoxRelayEdit,
        props: true,
        name: 'boxrelayedit',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/:serial_number/Sub/subboxrelay',
        component: SubBoxRelay,
        props: true,
        name: 'subboxrelay',
        meta: "user"
    },
    {
        path: '/home/:home_id/Gatwaye/:serial_number/SubRelay/:sub_serial/subrelayitem',
        component: SubRelayItem,
        props: true,
        name: 'subrelayitem',
        meta: "user"
    },
    {
        path: '/nonehome/boxRelay/gadget/add',
        component: noneHomeRelayAdd,
        props: false,
        name: 'noneHomeRelayAdd',
        meta: "user"
    },
    {
        path: '/nonehome/boxRelay/:serial_number/gadget',
        component: noneHomeRelayItem,
        props: true,
        name: 'noneSubRelayItem',
        meta: "user"
    },
    {
        path: '/nonehome/boxRelay/:serial_number/gadget/edit',
        component: noneHomeRelayEdit,
        props: true,
        name: 'noneHomeRelayEdit',
        meta: "user"
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})
router.beforeEach((to, from, next) => {
    if (to.meta === 'user' && !(parseInt(localStorage.getItem('login_id')) === 1))
        next({name: 'welcome'});
    if (to.meta === 'Authentication' && (parseInt(localStorage.getItem('login_id')) === 1)) {
        next({name: 'mainpage'})
    } else next();
})

export default router
