<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick" @back="goBackTimer" :isFinished="isFinished"/>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue"
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "BoxRelayStep2Timer",
  components: {
    Timer
  },
  data() {
    return {
      isModalVisible: false,
      serverInfoRelayItem: [],
      data: {
        items: [],
        type: "8Relay"
      },
      isFinished: false,
    }
  },
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.$store.dispatch("InfoSubDevice");
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  mounted() {
    try {
      let self = this;
      setTimeout(function () {
        self.getRelaysItem();
      }, 50);
    } catch (err) {
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(val, status) {
      console.log(val, status, this.data.items);
      let status_checkbox = "K" + this.data.items[val].numbers[0];
      if (status)
        status_checkbox += "T"
      else status_checkbox += "F";
      var saction = status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "nRelayStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    getRelaysItem() {
      this.subRelaysItems = []
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      let URL = getUrl.noneRelayItems;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.serverInfoRelayItem = jsonArray.content.relays;
          self.serverInfoRelayItem.forEach(function (item, i) {
            if (item.numbers.length < 2 && item.delay === 0) {
              console.log("Home relays item type", item.type);
              var text = "";
              for (let i = 0; i < item.numbers.length; i++) {
                text += self.$t('message.relay.relay') + item.numbers[i];
              }
              self.data.items.push({
                index: i,
                title: item.name,
                img: item.type.logo_path,
                // subTitle: getTypeRelay8(item.type.type_code),
                subTitle: text,
                icon: true,
                actions: "switch",
                switch: false,
                numbers: item.numbers
              });
              console.log("Home relays item type", self.data);
              self.closeModalLoading();
            } else {
              self.closeModalLoading();
            }
            self.isFinished = true;
          });
        } else {
          self.closeModalLoading();
          if (response.status == 401
          ) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    }
    ,
    closeModalLoading() {
      this.isModalVisible = false;
    }
    ,
    goBackTimer() {
      this.$router.push({
        name: "nRelayTimerShow",
        params: {
          serial_number: this.serial_number,
        },
      });
    }
    ,
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "nRelayTimerShow",
          params: {serial_number: this.serial_number},
        });
      }
    }
    ,
  },
}
;
</script>

<style scoped>

</style>