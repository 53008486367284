<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!-- Content Wrapper. Contains page content -->
      <section class="content">
        <div class="col-12" >
          <div style="text-align: center; margin-top: 50px">
            <img
                src="/assets/images/smart/vector-verify.svg"
                style="width: 200px; height: 200px"
                alt="Verify"
            />
            <h6 class="p-30" id="txtmobileloginverify">
              {{ $t('message.loginVerify.txt1') }}<span id="mobile_number" style="color:#007bff!important"> </span
            >
              {{ $t('message.loginVerify.txt2') }}
            </h6>
          </div>
          <br/>
        </div>
        <!-- col -->
        <div class="col-12">
          <div class="box">
            <div class="box-body">
              <form novalidate>
                <h6 class="mb-20 font-weight-bold" id="txtloginverify">
                  {{ $t('message.loginVerify.title') }}
                </h6>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-email-outline"></i
                          ></span>
                    </div>
                    <input
                        id="verify_code"
                        v-model="verify_code"
                        type="number"
                        class="form-control pl-15"
                        required
                        :placeholder="$t('message.loginVerify.inputCode')"
                        autocomplete="off"
                    />
                  </div>
                </div>
               <div v-if="show_info">
                <div class="form-group" id="form-fname" v-if="user_info">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-account-outline"></i
                            ></span>
                    </div>
                    <input
                        id="fname"
                        v-model="fname"
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginVerify.inputFname')"
                        autocomplete="off"
                    />
                  </div>
                </div>
                <div class="form-group" id="form-lname" v-if="user_info">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info">
                            <i class="mdi mdi-account-multiple"></i
                            ></span>
                    </div>
                    <input
                        id="lname"
                        v-model="lname"
                        type="text"
                        class="form-control pl-15"
                        required
                        maxlength="32"
                        :placeholder="$t('message.loginVerify.inputLname')"
                        autocomplete="off"
                    />
                  </div>
                </div>
               </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <button
                        type="button"
                        id="submitButton"
                        class="btn btn-info btn-block"
                        @click="loginVerify"
                    >
                      {{ $t('message.public.submit') }}
                    </button>

                    <h6 v-if="countshow===true" class="mt-20"> {{ $t('message.loginVerify.again_txt1') }} {{ countDown }} {{ $t('message.loginVerify.again_txt2') }}</h6>
                    <div class="text-center mt-10" v-if="btnagain===true">
                      <button
                          type="button"
                          id="againButton"
                          class="btn btn btn-outline btn-primary"
                          @click="sendDataAgain()"
                      >
                         {{ $t('message.loginVerify.send_again') }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div style="text-align: center; margin-top: 20px">
            <h6 class="mt-5 p-10" id="loginverifyaccounttips">
               {{ $t('message.loginVerify.wrongNumber') }}
              <a href="/login"
              ><u class="text-primary" id="loginverifycreataccount">
                 {{ $t('message.loginVerify.edit_number') }}
              </u></a
              >
            </h6>
          </div>
        </div>
      </section>
     <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import {
  lnameUserValidationInput,
  nameValidationInput,
  smsCodeValidationInput
} from "../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";

var mobile_number = localStorage.getItem("mobile_number");

export default {
  // components:{
  //   ModalCenter,

  // },
  props: {
    id: {},
    new_user: {},
    click: {},
  },
  data: () => ({
    countDown: 180,
    countshow: true,
    btnagain: false,
    isModalVisible:false,
    user_info:true,
    show_info:false,
    fname:'',
    lname:'',
    verify_code:''
    // UserID: this.$route.params.userID,
  }),
  created() {
    let data = {
      title: this.$t('message.loginVerify.title'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'LoginVerify');
    // console.log("this.new_user",this.new_user)
    if (this.new_user === false) {
      this.user_info=false
    }
  },
  mounted() {
    //----------------- Language
    this.countDownTimer()
    let self = this;
    //--------------------------------------  storage --------------------------------------//
    document.getElementById("mobile_number").innerHTML = localStorage.getItem("mobile_number");

    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
    //  console.log("this.new_user",this.new_user)
    if (this.new_user === false) {
      this.user_info=false

    }
    if (this.new_user === true) {
      this.show_info=true;

    }
   
    setTimeout(function () {
      self.btnagain = true;
      self.countshow = false;
    }, 180000);
  },
  watch:{
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({name: "login"})
      }
    },
    loginVerify() {
      let self = this;
      // var fname = document.getElementById("fname").value;
      // var lname = document.getElementById("lname").value;
      // var verify_code = document.getElementById("verify_code").value;

      if (
          !smsCodeValidationInput(this.verify_code) &&
          !nameValidationInput(this.fname) &&
          !lnameUserValidationInput(this.lname)
      )
        return;

      var data = {
        verify_id: this.id,
        verify_code: this.verify_code,
        fname: this.fname,
        lname: this.lname,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.loginVerify;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModal();

              let jsonArray = JSON.parse(response.responseText);
              let dataparse = jsonArray.content.user;
              dataparse.forEach(function (item) {
                localStorage.setItem("user_id", item.user_id);
                localStorage.setItem("token", item.token);
              });
              localStorage.setItem("login_id", 1);
              self.$router.replace({name: "mainpage"});
            } else {
              self.closeModal();
            }
          },
          //--------------------------------------Err --------------------------------------//

          function (errr) {

            setTimeout(function () {
              self.closeModal();
            }, 1000);
          }
      );
    },
    sendDataAgain() {
      let self = this
      let mobile = localStorage.getItem("mobile_number");
      var data = {
        mobile: mobile,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.login;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            self.closeModal();
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              localStorage.setItem("verifyid", jsonArray.content.verify_id);
            } else {
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
     this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
</style>
