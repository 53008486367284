<template>
  <v-app class="bg">
    <v-row style="width: 100%; place-content: center" class="d-flex align-self-center">
      <!-- col -->
      <v-col cols="6" class="d-flex flex-column" style="place-items: center">
        <img src="/assets/images/smart/vector-edit-name.svg" class="avatar-smart" />
        <h5 class="font-weight-bold">
          {{ $t('message.profile.edit') }}
        </h5>
      </v-col>
      <br />
      <v-col cols="12">
        <form novalidate style="background-color: white; padding:10px; border-radius: 10px">
          <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
          <div class="form-group p-1">
            <input type="text" class="form-control" required="" aria-invalid="false" maxlength="32" autocomplete="off"
              id="inputeditnamesub" :placeholder="$t('message.ModalAddSubDevicePol.hintName')" />
          </div>
        </form>
      </v-col>
      <v-col cols="12" class="fixed-bottom d-flex flex-column justify-content-center align-center">
        <div class="col-11 text-center ">
          <v-btn class="btn btn-info btn-block" :loading="loading" :disabled="loading" color="bg-primary"
            @click="myFunction()">
            <span class="text-white"> {{ $t('message.public.submit') }} </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { nameDeviceValidationInput } from "@/../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";


export default {
  name: "N2RelayEdit",
  components: {},
  props: {
    serial_number: {},
    click: {}
  },
  data() {
    return {
      loading: false,
      color: '',
      data: {
        labels: [],
        onColors: [],
        offColors: [],
      },
      dialog: false,
      target: [],
    };
  },
  mounted() {
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    document.getElementById("inputeditnamesub").value = this.info.name;
    let data = {
      title: this.$t('message.subDevice.edit'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneHomeNCurtainEdit');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingNoneService",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    CancelFunction() {
    },
    myFunction() {
      var name = document.getElementById("inputeditnamesub").value
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.serial_number;
      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;
      let labels = JSON.stringify(this.data.labels)
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        labels: labels,
        serial_number: serial_number,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.noneDeviceEdit;
      const p = postAjaxCall(URL, data, "");
      // loading.style.visibility = "visible";
      //--------------------------------------Response --------------------------------------//

      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            self.loading = false
            // self.$router.push({
            //   name: "SettingNoneService",
            //   params: {
            //     serial_number: this.serial_number,
            //   },
            // });
            self.$router.push({ name: "mainpage", });
            //  location.reload();
          } else {
            self.loading = false
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    },
  }
}
</script>

<style scoped></style>