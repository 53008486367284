<template>
  <v-app class="bg">
    <TimerList :isShow="isShow" :length="length" :infos="info" @addClock="addClock()" @addTimer="addTimer()"
               @deleteTimer="deleteTimer"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import TimerList from "../../../Cmp/timer/show/TimerList.vue";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {SetClock, Tools} from "../../../../../public/assets/js/utils/util";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";


export default {
  name: "ServiceTimerShow",
  components: {
    CmpPasscode,
    TimerList
  },
  data: () => ({
    info: "",
    lang: '',
    length: "",
    isShow: false,
    title: '',
    sub_serial_number: '',
    connection: false,
    set_password: '',
    counter: '',
    isModalVisible: false
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.sub_serial_number = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
  },
  mounted() {
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
    let self = this;
    this.title = this.$t('message.timer.scheduler_show')
    this.$store.dispatch("infoHome");
    this.$store.dispatch("InfoSubDevice");
    this.set_password = JSON.parse(localStorage.getItem("infoSubDevice")).set_password;
    setTimeout(function () {
      self.lastStatus();
    }, 100);
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        this.connection = true;
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let sucdevice_sn = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      this.info = '';

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: sucdevice_sn,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.timers;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              var len = content.length;
              self.length = content.length;
              if (len === 0) {
                self.isShow = true;
              }
              let items = [{
                name: self.$t('message.touch_key.key'),
                status: false
              }, {
                name: self.$t('message.touch_key.music'),
                status: false
              }, {
                name: self.$t('message.touch_key.fan'),
                status: false
              }, {
                name: self.$t('message.touch_key.spray'),
                status: false
              },]
              content.forEach(function (item) {
                let msg = item.message.substr(item.message.indexOf(item.message.match("K")) + 1, 1);
                const data = getTimerData(item.message);
                switch (msg) {
                  case "1":
                    data.nameStr = items[0].name;
                    break
                  case "2":
                    data.nameStr = items[1].name;
                    break
                  case "3":
                    data.nameStr = items[2].name;
                    break
                  case "4":
                    data.nameStr = items[3].name;
                    break
                }
                finaldata.push(data);
                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    sendReq(pass) {
      this.sendDelete(this.counter, pass);
    },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: this.$t('message.timer.timer'),
            text: this.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: this.$t('message.public.delete'),
            cancelButtonText: this.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {
              if (self.set_password == 1) {
                self.counter = counttimer;
                $("#modal-pass-code").modal("show");
              } else
                self.sendDelete(counttimer, '');
            }
          }
      );
    },
    sendDelete(countTimer, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let message = "TIMER" + countTimer + "-OFF#";

      var topic_final = getTopics.getTopicTimerSend(this.serial_number, this.sub_serial_number);

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        password: password,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.subcribe();
              setTimeout(function () {
                if (!this.connection) {
                  self.exitModalLoading();
                  swal("", self.$t('message.public.operation_failure'), "error");
                } else {
                  self.exitModalLoading();
                  self.lastStatus()
                }
              }, 5000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, 256);
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();
      let device_serial = this.serial_number;
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      // location.reload();
      self.lastStatus();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicClockSend(this.serial_number, this.sub_serial_number);

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000);
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubServiceInfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "refresh") {
        this.lastStatus();
      }
      if (value == "add") {
        this.$router.push({
          name: "ServiceTimer",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    addTimer() {
      this.$router.push({
        name: "ServiceTimer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
  },
}
</script>

<style scoped>

</style>