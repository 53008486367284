<template>
  <v-app class="bg">
    <div class="row rounded-16 px-5">
      <div class="col-12 col-lg-12">
        <div class="box mt-5 px-5">
          <div class="box px-5">
            <!-- Main content -->
            <section class="content">
              <!-- row -->
              <div class="row" id="all-page" ref="allpage">
                <!-- col -->
                <div class="col-12 col-lg-12">
                  <div class="box">
                    <div class="box-body ">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div style="text-align: center; margin-top: 0px">
                          <div class="fontawesome-icon-list m-0">
                            <img
                                src="/assets/images/smart/vector-amp.svg"
                                class="avatar-smart mt-10"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="form-check px-40 mt-20">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            value="checkbox1"
                            v-model="checked"
                            id="checkbox1"
                        />
                        <label class="form-check-label" for="checkbox1">
                          {{ $t('message.noneHome.active') + " " + $t('message.RelayKey.I') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>

              <!-- /.row -->
            </section>
            <!-- /. Main content -->
          </div>
        </div>
      </div>
    </div>
    <div class="row rounded-16 px-5 mt-0">
      <div class="col-12 col-lg-12">
        <div class="box">
          <div class="box-body px-5">
            <div class="text-center py-5">
              <h6 style="line-height: 25px !important">
                {{ $t('message.noneHome.I_txt') }}
              </h6>
              <ul class="flexbox flex-justified text-center my-10 mt-30">
                <li class="px-2">
                  <h6 class="mb-0 text-bold">{{ $t('message.public.from') }}</h6>
                  <div class="form-group mt-10">
                    <input
                        id="input1"
                        type="number"
                        value="00"
                        min="00"
                        max="19"
                        v-model="amp1"
                        style="direction: ltr"
                        class="form-control text-center"
                        required=""
                        :disabled="!checked"
                        aria-invalid="false"
                        autocomplete="off"
                        onKeyPress="if(this.value.length==2) return false;"
                    />
                  </div>
                </li>
                <li class="br-1 px-2">
                  <h6 class="mb-0 text-bold">{{ $t('message.public.to') }}</h6>
                  <div class="form-group mt-10">
                    <input
                        id="input2"
                        type="number"
                        class="form-control text-center"
                        required=""
                        v-model="amp2"
                        aria-invalid="false"
                        value="20"
                        :disabled="!checked"
                        min="00"
                        style="direction: ltr"
                        max="20"
                        autocomplete="off"
                        onKeyPress="if(this.value.length==2) return false;"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <br/><br/>
          </div>
        </div>
      </div>
    </div>
    <div class="row rounded-16 px-5 fixed-bottom">
      <div class="col-12 col-lg-12 px-15">
        <button
            type="button"
            class="btn btn-primary btn-lg btn-block mb-10"
            v-on:click.prevent="sendMessage()"
        >
          {{ $t('message.public.submit') }}
        </button>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {SucessToastMsg, ErrorToastMsg, Internets, BetweenStr} from "../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {ampCompareValidationInput, ampValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

var connection = false;
var showToast;

export default {
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      checked: false,
      amp1: "00",
      amp2: "20",
      title: '',
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/amp"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "amp") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted: function () {
    this.title = this.$t('message.noneHome.setI');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingCurrent');
    var self = this;
    this.openModalLoading();
    var topic = getTopics.getTopicAmpReceive(this.serial_number, '256');
    this.$mqtt.subscribe(topic);
    let allpage = this.$refs.allpage;
    allpage.style.display = "none";
    setTimeout(function () {
      self.getInfoLastStatus();
    }, 300);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendMessage() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      // var tapp = localStorage.getItem("t_app");
      let self = this;

      //--------------------------------------Message-------------------------------------//
      var status_checkbox;
      var checkbox = document.getElementById("checkbox1");
      if (this.checked == true) {
        let input1 = this.amp1;
        let input2 = this.amp2;

        var formattedNumber1 = ("0" + input1).slice(-2);
        var formattedNumber2 = ("0" + input2).slice(-2);

        //--------------------------------------Validation-------------------------------------//
        if (
            !ampValidationInput(input1) ||
            !ampValidationInput(input2) ||
            !ampCompareValidationInput(formattedNumber1, formattedNumber2)
        )
          return;
        status_checkbox =
            "AMP" + formattedNumber1 + "-" + formattedNumber2 + "#";
      } else if (this.checked == false) {
        status_checkbox = "DEACTIVEA#";
      }
      //--------------------------------------function for Post Method --------------------------------------//
      var topic_final = getTopics.getTopicAmpSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: status_checkbox,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModalLoading();
              setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
            }
          },
          function (errr) {
            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({name: "home"});
              }
            }
          }
      );
    },
    getInfoLastStatus() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var tapp = localStorage.getItem("t_app");
      var self = this;

      var allpage = this.$refs.allpage;
      let device_serial = this.serial_number;

      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.amp;
      const p = postAjaxCall(URL, data, "");
      this.checked = false;

      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.exitModalLoading();
              allpage.style.display = "block";
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.amp;
              var checkbox = document.getElementById("checkbox1");
              if (message == "DEACTIVEAMP") {
                self.checked = false;
              } else {
                self.checked = true;
                var value1 = message.substr(message.indexOf("AMP") + 3, 2);
                var value2 = message.substr(message.indexOf("AMP") + 6, 2);
                document.getElementById("input1").value = value1;
                document.getElementById("input2").value = value2;
              }
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "settings",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },
};
</script>


<style scoped>
</style>
