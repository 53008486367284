import i18n from "../../../../src/i18n";
export const SetClock = {
    getSetClock: function () {
        var year = parseInt(new Date().getFullYear().toString().substr(2, 2));
        var month = parseInt(new Date().getMonth() + 1);
        var date = parseInt(new Date().getDate());
        var d = parseInt(new Date().getDay());
        var hours = parseInt(new Date().getHours());
        var minute = parseInt(new Date().getMinutes());
        var second = parseInt(new Date().getSeconds());

        var formattedmonth = ("0" + month).slice(-2);
        var formatteddate = ("0" + date).slice(-2);
        var formattedhours = ("0" + hours).slice(-2);
        var formattedminute = ("0" + minute).slice(-2);
        var formattedsecond = ("0" + second).slice(-2);

        if (d == 0) {
            d = d + 7;
        }
        let datefinal = year + '/' + formattedmonth + '/' + formatteddate + '/' + d;
        let timefinal = formattedhours + ':' + formattedminute + ':' + formattedsecond;

        let saction = "CLOCK" + datefinal + "," + timefinal + "#";
        return saction;
    }
};
export const BetweenStr = {
    getBetweenStr: function (str, first, second) {
        return str.match(new RegExp(first + "(.*)" + second));
    }
};
export const Internets = {
    Internet: function () {
        window.addEventListener("offline", (event) => {
            
            $.toast({
                text: i18n.t('message.public.internet'),
                // It can be plain, fade or slide
                bgColor: '#f62d51 ',              // Background color for toast
                textColor: '#eee',            // text color
                allowToastClose: true,       // Show the close button or not
                hideAfter: 5000,              // `false` to make it sticky or time in miliseconds to hide after
                stack: 5,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
                textAlign: 'left',            // Alignment of text i.e. left, right, center
                position: 'bottom-left',      // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
                // icon: 'warning'
            })
        });
        window.addEventListener("online", (event) => {
            // hideModal(modal)
            location.reload();
        });
    }
};
export const SucessToast = {
    delay: function () {

        $.toast({
            text: i18n.t('message.public.msg'),
            // It can be plain, fade or slide
            bgColor: '#28a745 ',              // Background color for toast
            textColor: '#eee',            // text color
            allowToastClose: true,       // Show the close button or not
            hideAfter: 3000,              // `false` to make it sticky or time in miliseconds to hide after
            stack: 5,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
            textAlign: 'left',            // Alignment of text i.e. left, right, center
            position: 'bottom-left',      // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
            // icon: 'success'
        })
    }
};
export const SucessToastMsg = {
    Msg: function () {

        $.toast({
            text: i18n.t('message.public.msg'),
            // It can be plain, fade or slide
            bgColor: '#28a745 ',              // Background color for toast
            textColor: '#eee',            // text color
            allowToastClose: true,       // Show the close button or not
            hideAfter: 3000,              // `false` to make it sticky or time in miliseconds to hide after
            stack: 5,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
            textAlign: 'left',            // Alignment of text i.e. left, right, center
            position: 'bottom-left',      // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
            // icon: 'success'
        })
    }
};
export const SucessToastAdd = {
    MsgAdd: function (txt) {

        $.toast({
            text: txt,
            // It can be plain, fade or slide
            bgColor: '#28a745',              // Background color for toast
            textColor: '#eee',            // text color
            allowToastClose: true,       // Show the close button or not
            hideAfter: 3000,              // `false` to make it sticky or time in miliseconds to hide after
            stack: 5,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
            textAlign: 'left',            // Alignment of text i.e. left, right, center
            position: 'bottom-left',

            // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
            // icon: 'success'
        })
    }
};
export const ToastCustom = {
    custom: function (txt, background, color) {
        if (typeof (color) == "undefined") {
            color = '#000'
        }

        $.toast({
            text: txt,
            // It can be plain, fade or slide
            bgColor: background,              // Background color for toast
            textColor: color,            // text color
            allowToastClose: true,       // Show the close button or not
            hideAfter: 3000,              // `false` to make it sticky or time in miliseconds to hide after
            stack: 1,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
            textAlign: 'left',            // Alignment of text i.e. left, right, center
            position: 'bottom-left',      // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
            // icon: 'success'
        })
    }
};
export const ErrorToastMsg = {
    Msg: function () {
        $.toast({
            text: i18n.t('message.public.operation_failure'),
            // It can be plain, fade or slide
            bgColor: '#dc3545 ',              // Background color for toast
            textColor: '#eee',            // text color
            allowToastClose: true,       // Show the close button or not
            hideAfter: 2000,              // `false` to make it sticky or time in miliseconds to hide after
            stack: 5,                     // `fakse` to show one stack at a time count showing the number of toasts that can be shown at once
            textAlign: 'left',            // Alignment of text i.e. left, right, center
            position: 'bottom-left',      // bottom-left or bottom-right or bottom-center or top-left or top-right or top-center or mid-center or an object representing the left, right, top, bottom values to position the toast on page
            // icon: 'error'
        })
    }
};
export const SuccessAlert = {
    Alert: function () {
        swal(i18n.t('message.public.configuration'), i18n.t('message.public.order'), "success");
    }
};
export const ErrorAlert = {
    Alert: function () {
        swal(i18n.t('message.public.configuration'), i18n.t('message.public.operation_failure'), "error");
    }
};
export const modalCenterShows = {
    modalCenterShow: function () {
        $("#modal-center").modal('show');
    }
};

class ROZ {
    testCall() {
        return "roz"
    }
}

export {ROZ};

export const Tools = {
    getTopicActionSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~action"
    },
    getTopicActionReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/action"
    },
    getNow() {
        const today = new Date();
        var h = today.getHours()
        var m = today.getMinutes()
        h = checkTime(h);
        m = checkTime(m);
        const time = h + ":" + m;
        return String(time);
    },
    sweetAlert(title, text, type, timer) {
        swal({
            title: title,
            text: text,
            type: type,
            timer: timer,
            showConfirmButton: false,
        });
    }
};

function getStrTopic(gatwaysn, sn, send) {
    var tapp = localStorage.getItem("t_app");
    var topic;
    if (send) {
        if (tapp == 1) {
            topic = "rnd/" + gatwaysn + "/s/" + sn;
        } else {
            topic = "farahoush/" + gatwaysn + "/send/" + sn;
        }
    } else {
        if (tapp == 1) {
            topic = "rnd/" + gatwaysn + "/r/" + sn;
        } else {
            topic = "farahoush/" + gatwaysn + "/receive/" + sn;

        }
    }

    return topic

}

// add a zero in front of numbers<10
function checkTime(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}

