<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i
                class="ti-angle-right"
                style="font-size: 24px"
                @click="goBackTimerS2()"
            ></i>
            <div class="media-body p-0">
              <strong>{{ $t('message.timer.scheduler') }}</strong>
            </div>
          </div>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box" style="height: 812px">
              <div class="box-body p-0">
                <div class="text-center py-10 bb-1 bb-dashed mt-40">
                  <h4>{{ $t('message.timer.select_time') }}</h4>
                  <ul class="flexbox flex-justified text-center my-20">
                    <li class="px-10">
                      <small>{{ $t('message.timer.start') }}</small>
                      <!-- <timeselector v-model="time1"></timeselector> -->
                      <input type="time" id="myTime1" value="16:30"/>
                    </li>
                    <li class="br-1 bl-1 px-10">
                      <small>{{ $t('message.timer.end') }}</small>
                      <!-- <timeselector v-model="time2"></timeselector> -->
                      <input type="time" id="myTime2" value="16:30"/>
                    </li>
                  </ul>
                </div>
                <div class="text-center py-10 bb-1 bb-dashed">
                  <h4> {{ $t('message.timer.select_day') }}</h4>
                  <div :class="{
                     'text-left py-5':lang === 'en',
                     'text-right py-5':lang !== 'en'}">
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk1"
                      />
                      <label class="form-check-label" for="chk1"> {{ $t('message.timer.saturday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk2"
                      />
                      <label class="form-check-label" for="chk2">
                        {{ $t('message.timer.sunday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk3"
                      />
                      <label class="form-check-label" for="chk3">
                        {{ $t('message.timer.monday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk4"
                      />
                      <label class="form-check-label" for="chk4">
                        {{ $t('message.timer.tuesday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk5"
                      />
                      <label class="form-check-label" for="chk5">
                        {{ $t('message.timer.wednesday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk6"
                      />
                      <label class="form-check-label" for="chk6">
                        {{ $t('message.timer.thursday') }}
                      </label>
                    </div>
                    <div class="form-check px-40 py-5">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="chk7"
                      />
                      <label class="form-check-label" for="chk7"> {{ $t('message.timer.friday') }} </label>
                    </div>
                  </div>
                </div>
                <div class="text-center py-10 px-40">
                  <button
                      type="button"
                      class="btn btn-primary btn-lg btn-block mb-10"
                      v-on:click.prevent="MyFunction()"
                  >
                    {{ $t('message.public.submit') }}

                  </button>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- Modal -->
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal -->
  </div>
  <!-- /. Wrapper -->
</template>

 <script>
import { SucessToastMsg } from "../../../../../public/assets/js/utils/util.js";
import { ErrorToastMsg } from "../../../../../public/assets/js/utils/util.js";
import { BetweenStr } from "../../../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
var showToast;
var data0 = "";
let chek1_status = "D60";
let chek2_status = "D70";
let chek3_status = "D10";
let chek4_status = "D20";
let chek5_status = "D30";
let chek6_status = "D40";
let chek7_status = "D50";
let timer_count;
var connection = false;

export default {
  components: {},
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  data() {
    return {
      lang: '',
    };
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
  },
  mounted() {
    let self = this;
    this.$store.dispatch("statusTimer");
    this.$store.dispatch("InfoSubDevice");

    setTimeout(function () {
      self.getCount();
    }, 50);

    //------subscribe
    var tapp = localStorage.getItem("t_app");
    let device_serial = this.serial_number;
    let subdevice_serial = this.$store.getters.getSerialSub;
    var topic1;
    if (tapp == 1) {
      topic1 = "rnd/" + subdevice_serial + "/r/" + "256" + "/timer";
    } else {
      topic1 =
          "farahoush/" + subdevice_serial + "/receive/" + "256" + "/timer";
    }
    this.$mqtt.subscribe(topic1);
    // find checkbox
    let Chek1 = document.getElementById("chk1");
    let Chek2 = document.getElementById("chk2");
    let Chek3 = document.getElementById("chk3");
    let Chek4 = document.getElementById("chk4");
    let Chek5 = document.getElementById("chk5");
    let Chek6 = document.getElementById("chk6");
    let Chek7 = document.getElementById("chk7");

    Chek1.addEventListener("change", function () {
      if (Chek1.checked) {
        chek1_status = "D61";
      } else {
        chek1_status = "D60";
      }
    });
    Chek2.addEventListener("change", function () {
      if (Chek2.checked) {
        chek2_status = "D71";
      } else {
        chek2_status = "D70";
      }
    });
    Chek3.addEventListener("change", function () {
      if (Chek3.checked) {
        chek3_status = "D11";
      } else {
        chek3_status = "D10";
      }
    });
    Chek4.addEventListener("change", function () {
      if (Chek4.checked) {
        chek4_status = "D21";
      } else {
        chek4_status = "D20";
      }
    });
    Chek5.addEventListener("change", function () {
      if (Chek5.checked) {
        chek5_status = "D31";
      } else {
        chek5_status = "D30";
      }
    });
    Chek6.addEventListener("change", function () {
      if (Chek6.checked) {
        chek6_status = "D41";
      } else {
        chek6_status = "D40";
      }
    });
    Chek7.addEventListener("change", function () {
      if (Chek7.checked) {
    
        chek7_status = "D51";
      } else {
        chek7_status = "D50";
      }
    });
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] == this.$store.getters.getSerialSub && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "timer") {
          console.log("data", String(data));
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  methods: {
    MyFunction() {
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      let subdevice_serial = this.$store.getters.getSerialSub;
      let count = this.count;
      var tapp = localStorage.getItem("t_app");
      var time1 = document.getElementById("myTime1").value;
      var time2 = document.getElementById("myTime2").value;
      var topic_final;
      // var time1=this.time1;
      // var time2=this.time2;
      var timer_status = this.$store.getters.getTimerStatus;
      
      message =
          "TIMER" +
          timer_count +
          "-" +
          timer_status +
          "-" +
          chek3_status +
          "-" +
          chek4_status +
          "-" +
          chek5_status +
          "-" +
          chek6_status +
          "-" +
          chek7_status +
          "-" +
          chek1_status +
          "-" +
          chek2_status +
          "-" +
          time1 +
          "-" +
          time2 +
          "#";

      if (tapp == 1) {
        topic_final = "rnd/" + subdevice_serial + "/s/" + "256" + "/~timer";
      } else {
        topic_final =
            "farahoush/" + subdevice_serial + "/send/" + "256" + "/~timer";

      }
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              timer_count = jsonArray.content.timer_count;

              setTimeout(function () {
                if (connection) {
                  self.$router.push({
                    name: "SubRelayTimerShow",
                    params: {
                      serial_number: self.serial_number,
                      home_id: self.home_id,
                      count: self.count,
                    },
                  });
                }
                if (!connection) {
                  self.exitModalLoading();
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }

                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              //error
              self.exitModalLoading();
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getCount() {
      console.log(" getCount");
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var tapp = localStorage.getItem("t_app");
      let subdevice_serial = this.$store.getters.getSerialSub;

      var data = {
        user_id: user_id,
        token: token,
        serial_number: subdevice_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.timerCount;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              console.log("timer_count", jsonArray.content.timer_count);
              timer_count = jsonArray.content.timer_count;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.$parent.showModal();
    },
    exitModalLoading() {
      this.$parent.closeModal();
    },
    goBackTimerS2() {
      this.$router.push({
        name: "SubRelaytimer",
        params: {serial_number: this.serial_number, home_id: this.home_id},
      });
    },
  },
};
</script>


<style scoped>
</style>
