<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!--/.section empty home and device -->
    <!-- Left side column. contains the logo and sidebar -->   
    <div
        :class="{
        modal: true,
        'modal-left': lang === 'en',
        'modal-right': lang !== 'en'
      }"
        id="modal-right"
        tabindex="-1"
        data-backdrop="false"
        style=" 
        background-color: transparent !important"
        aria-hidden="true"
        v-if="showslide"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <img v-if="appMode=='farahoush'"
                src="/assets/images/smart/logo3.png"
                alt=""
                width="30px"
                height="30px"
            />
            <img v-else
                src="/assets/images/smart/logo_bmsx_3.png"
                alt=""
                width="30px"
                height="30px"
            />
            <h5 class="modal-title" v-if="appMode=='farahoush'">
              {{ $t("message.mainPage.farahoush_smart_home") }}
            </h5>
            <h5 class="modal-title" v-else>
              {{ $t("message.mainPage.bmsx_smart_home") }}
            </h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" style="font-size: 30px">×</span>
            </button>
          </div>
          <div
              class="modal-body m-0 p-0 d-flex flex-column justify-space-between"
          >
            <section class="sidebar">
              <ul class="sidebar-menu" data-widget="tree">
                <li class="user-profile treeview" @click="rowProfile()">
                  <a class="bb-1" style="padding: 10px !important">
                    <i class="mdi mdi-account-circle mdi-36px"></i>
                    <p class="px-4 mb-0">
                      <strong class="d-block font-weight-600 font-size-15 text-primary">
                        {{ profile.name }}
                      </strong>
                      <strong class="email-id font-size-13 text-gray">{{ profile.mobile }}</strong>
                    </p>
                    <span class="pull-right-container">
                    </span>
                  </a>
                </li>
                <li class="treeview" v-on:click.prevent="rowAddHome()">
                  <a href="#">
                    <i class="mdi mdi-home-outline"></i>
                    {{ $t("message.mainPage.submit_home") }}
                  </a>
                </li>
                <li class="treeview" v-on:click.prevent="rowAddDevice()">
                  <a href="#">
                    <i class="mdi mdi-router-wireless"></i>
                    {{ $t("message.mainPage.submit_device") }}
                  </a>
                </li>
                <li class="treeview" v-on:click.prevent="configDevice()">
                  <a href="#">
                    <i class="mdi mdi-tune-vertical"></i>
                    {{ $t("message.mainPage.configuration") }}
                  </a>
                </li>
                <li class="treeview" v-on:click.prevent="settings()">
                  <a href="#">
                    <i class="mdi mdi-settings"></i>
                    {{ $t("message.public.settings") }}
                  </a>
                </li>
                <li class="treeview" @click="aboutUs()">
                  <a href="#">
                    <i class="mdi mdi-information-outline"></i>
                    {{ $t("message.public.about_us") }}
                  </a>
                </li>

                <li class="treeview" v-on:click.prevent="rowExitAccount()">
                  <a href="#">
                    <i class="mdi mdi-exit-to-app"></i>
                    {{ $t("message.mainPage.exit_account") }}
                  </a>
                </li>
                <li class="treeview"></li>
              </ul>
            </section>
            <div class="d-flex flex-column px-5 mb-5">
              <SwitchLanguage/>
            </div>
          </div>
          <div class="modal-footer modal-footer-uniform">
            <div style="text-align: center">
              <small class="text-gray">Version 1.4.0</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.Left side column. contains the logo and sidebar -->
    <v-row justify="center" v-if="dialog">
      <v-dialog
          class="bg"
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card class="bg">
          <v-card-title>
            {{ $t("message.mainPage.devices") }}
          </v-card-title>
          <v-card-text class="pt-3">
            <div class="row pt-2">
              <div
                  class="col-4 col-lg-4 px-1 py-2"
                  v-bind:key="index"
                  v-for="(infos, index) in select"
              >
                <v-sheet
                    color="white"
                    rounded
                    class="flexbox flex-justified text-center b-1 box rounded-24 py-20 m-0"
                    v-on:click="
                  showAddDevice(infos.type_code, infos.label)"
                >
                  <img
                      style="
                    max-height: 50px !important;
                    min-height: 50px !important;
                  "
                      :src="infos.logo_path"
                      alt=""
                  />
                  <p class="mb-0 font-weight-300 mt-10">{{ infos.label }}</p>
                </v-sheet>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <!-- <v-btn color="primary" text @click="dialog = false">
              <span class="text-primary">{{ $t("message.public.close") }}</span>
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div v-else>
    </div>


    
    <!-- row Empty Home -->
   <div >
    <section class="error-page" v-if="show_empty_home">
      <div class="container">
        <div
            class="row align-items-center justify-content-center text-center"
        >
          <div class="col-lg-7 col-md-10 col-12" style="margin-top: 100px">
            <h1
                class="
                  text-warning
                  font-size-180 font-weight-bold
                  error-page-title
                "
            ></h1>
            <img
                src="../../public/assets/images/smart/vector-add-home.svg"
                style="width: 300px; height: 300px"
            />
            <br/>
            <h4 class="mt-10">
              <strong> {{ $t("message.mainPage.nothing") }} </strong>
            </h4>
            <h6>
              {{ $t("message.mainPage.noneHome_txt") }}
            </h6>

            <button
                type="button"
                class="btn btn-success mb-5 mt-10 mx-1"
                v-on:click.prevent="addHomeEmpty()"
            >
              <label> {{ $t("message.mainPage.add_home") }}</label>
            </button>

            <button
                type="button"
                class="btn btn-dark mb-5 mt-10 mx-1"
                v-on:click.prevent="addDeviceEmpty()"
            >
              <label>{{ $t("message.mainPage.add_device") }}</label>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="justify-content-center m-10" id="app">
      <!-- row Home -->
      <div class="row m-1" id="home" v-if="show_home_div">
        <div class="col-12 col-lg-12">
          <div class="row">
            <div
                class="col-6 p-1"
                v-bind:key="index"
                v-for="(home, index) in homes"
            >
              <div
                  class="box mb-0"
                  v-on:click="
                    sendId(
                      home.home_name,
                      home.device[0].serial,
                      home.home_id,
                      home.device[0].type_code,
                      home.admin,
                      home.device[0].password,
                      home.device[0].set_password,
                    )
                  "
              >
                <div class="box-body">
                  <span class="pull-right" v-if="home.admin === 0">
                      <span class="badge badge-secondary p-2">
                        {{ $t("message.public.guest") }}
                      </span>
                    </span>
                  <span class="pull-right" v-else
                  ><span class="badge badge-success p-2">
                        {{ $t("message.public.manager") }}
                      </span></span
                  >
                  <div class="box rounded align-items-center">
                    <img
                        src="/assets/images/smart/house.svg"
                        width="50px"
                        height="50px"
                    />
                    <div class="text-overflow-handle">
                      <h5 class="mb-0 font-weight-300 p-2 text-center">
                        <strong>{{ home.home_name }}</strong>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row Home -->
      <!-- row device none home-->
      <div class="row mt-0"  v-if="show_none_home">
        <div class="col-lg-12 col-12">
          <!-- Default box -->
          <div class="box">
            <div class="box-header with-border">
              <h4 class="box-title">
                {{ $t("message.mainPage.devices") }}
              </h4>
            </div>
            <div class="box-body">
              <div class="row">
                <div
                    class="col-xl-12 col-md-12 col-12 py-0"
                    v-bind:key="index"
                    v-for="(nonehome, index) in nonehomes"
                >
                  <!-- none home outlet -->
                  <v-container
                      v-if="nonehome.type_code === 11"
                      class="media align-items-center bg-white py-2 mt-4 border"
                      @click="
                        noneHome(
                          nonehome.serial_number,
                          nonehome.name,
                          nonehome.password,
                          nonehome.set_password
                        )
                      "
                  >
                    <v-col cols="12" lg="2" class="py-0 ">
                      <v-row>
                        <v-col cols="2" class="py-0 px-0" align-self="center" justify-center>
                          <v-img
                              src="/assets/images/smart/vector-outlet-blue.svg"
                              alt="Gadget Outlet"
                              style="width:60px"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-row class="mt-5">
                            <v-col cols="12" class="py-0">
                              <label class="text-bold">{{ nonehome.name }}</label>
                            </v-col>
                            <v-col cols="12" class="py-0 mb-4">
                              <label class="text-regular text-gray" style="font-size: 11px">{{
                                  nonehome.serial_number
                                }}</label>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                  <!-- none home IR -->
                  <v-container
                      v-else-if="nonehome.type_code === 12"
                      class="media align-items-center bg-white py-2 mt-4 border"
                      @click="
                        noneHomeIR(
                          nonehome.serial_number,
                          nonehome.name,
                          nonehome.password,
                          nonehome.set_password
                        )
                      "
                  >
                    <v-col cols="12" lg="2" class="py-0 ">
                      <v-row>
                        <v-col cols="2" class="py-0 px-0" align-self="center" justify-center>
                          <v-img
                              src="/assets/images/smart/icon-ir-remote.svg"
                              alt="Gadget IR"
                              style="width:60px"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-row class="mt-5">
                            <v-col cols="12" class="py-0">
                              <label class="text-bold">{{ nonehome.name }}</label>
                            </v-col>
                            <v-col cols="12" class="py-0 mb-4">
                              <label class="text-regular text-gray" style="font-size: 11px">{{
                                  nonehome.serial_number
                                }}</label>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                  <!-- none home Relay8 -->
                  <v-container
                      v-else-if="nonehome.type_code === 16"
                      class="media align-items-center bg-white py-2 mt-4 border"
                      @click="
                        getIndexRowSub(index, nonehome.type_code)
                      "
                  >
                    <v-col cols="12" lg="2" class="py-0 ">
                      <v-row>
                        <v-col cols="2" class="py-0 px-0" align-self="center" justify-center>
                          <v-img
                              src="/assets/images/smart/icon-8relay-none.svg"
                              alt="Gadget IR"
                              style="width:60px"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-row class="mt-5">
                            <v-col cols="12" class="py-0">
                              <label class="text-bold">{{ nonehome.name }}</label>
                            </v-col>
                            <v-col cols="12" class="py-0 mb-4">
                              <label class="text-regular text-gray" style="font-size: 11px">{{
                                  nonehome.serial_number
                                }}</label>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                  <v-container
                      v-else
                      class="media align-items-center bg-white py-2 mt-4 border"
                      @click="
                        getIndexRowSub(index, nonehome.type_code)
                      ">
                    <v-col cols="12" lg="2" class="py-0 ">
                      <v-row>
                        <v-col cols="2" class="py-0 px-0" align-self="center" justify-center>
                          <v-img
                              :src="nonehome.type_logo"
                              alt="Gadget IR"
                              style="width:60px"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="10">
                          <v-row class="mt-5">
                            <v-col cols="12" class="py-0">
                              <label class="text-bold">{{ nonehome.name }}</label>
                            </v-col>
                            <v-col cols="12" class="py-0 mb-4">
                              <label class="text-regular text-gray" style="font-size: 11px">{{
                                  nonehome.serial_number
                                }}</label>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-container>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
          <!-- /.box -->
        </div>
      </div>
    </section>
   </div>
    <ModalExitAccount></ModalExitAccount>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import addToHome from "./setting/addToHome";
import ModalExitAccount from "./Modal/ModalExitAccount";
import ModalAddHome from "./Modal/ModalAddHome";
import ModalAddDevice from "./Modal/ModalAddDevice";
import ModalConfig from "./Modal/ModalConfig";
import SwitchLanguage from "./setting/SwitchLanguage";
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";
import ModalAddDeviceFarapol from "./Modal/ModalAddDeviceFarapol";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    ModalAddDeviceFarapol,
    addToHome,
    ModalExitAccount,
    ModalAddHome,
    ModalAddDevice,
    ModalConfig,
    SwitchLanguage,
  },
  props: {
    click: {},
  },
  data() {
    return {
      homes: null,
      nonehomes: null,
      deferredPrompt: null,
      // isExpanded: false,
      expandedGroup: [],
      label: "",
      select: [],
      ind: 0,
      lang: "",
      isModalVisible: false,
      dialog: false,
      localRelaydata: [],
      itemsMenu: [],
      relayData: [],
      subBoxRelays: [],
      profile: {},
      appMode:'farahoush',
      show_none_home:false,
      show_empty_home:false,
      show_home_div:true,
      // show_page:false,
      showslide:true,

    };
  },
  watch: {
      // group () {
      //   this.drawer = false
      // },
    },
  mounted() {
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    // var tapp = localStorage.getItem("t_app");
    this.appMode=process.env.VUE_APP_MODE


    let self = this;
    //  this.captureEvent();
    this.$store.dispatch("infoUser");

    setTimeout(function () {
      self.lastStatus(user_id, token);
    }, 300);
  },

  created() {
    let data = {
      mainPage: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    for (const key in data) {
    }
    if (localStorage.getItem("Lang") != null)
      this.$i18n.locale = localStorage.getItem("Lang");
    if (this.$i18n.locale === "en" || this.$i18n.locale === 'tur') {
      document.querySelector("body").classList.add("ltr");
      if (document.querySelector("body").classList.contains("rtl"))
        document.querySelector("body").classList.remove("rtl");
    }
    if (this.$i18n.locale === "fa" || this.$i18n.locale === "ar") {
      document.querySelector("body").classList.add("rtl");
      if (document.querySelector("body").classList.contains("ltr"))
        document.querySelector("body").classList.remove("ltr");
    }
    this.lang = localStorage.getItem("Lang");
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'mainpage');
  },

  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },

  methods: {
    onClickChild(val) {
      if (val === 'refresh') {
        this.goRefresh()
      }
      if (val === "menu") {
        this.openMenu()
        this.showslide=true
      }
    },
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    clickCallback() {
      // Show the prompt

      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }
        this.deferredPrompt = null;
      });
    },
    sendId(name, serial, homeId, type_code, admin, password, set_password) {
      //----------------Data Home for Vuex
      const homeinfo = {
        name: name,
        serial: serial,
        homeId: homeId,
        type_code: type_code,
        admin: admin,
        password: password,
        setPassword: set_password
      };
      //----------------Data  Vuex
      localStorage.setItem("infoHome", JSON.stringify(homeinfo));
      this.$router.replace({
        name: "homeDevices",
        params: {home_id: homeId, serial_number: serial},
      });
    },
    rowExitAccount() {
      $("#modal-exit-account").modal("show");
      $("#modal-right").modal("hide");
    },
    rowAddHome() {
      $("#modal-right").modal("hide");
      this.$router.push({name: "ModalAddHome"})
    },
    rowAddDevice() {
      this.getNoneDevices();
      this.dialog = true;
      $("#modal-right").modal("hide");
    },
    rowProfile() {
      this.$router.replace({
        name: "profile",
      });
      $("#modal-right").modal("hide");
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 4900)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    pub() {
      this.$mqtt.publish("v/pu", "Hi roz");
    },
    addHomeEmpty() {
      // console.log("add home")
      // $("#modal-add-home").modal("show");
      this.$router.push({name: "ModalAddHome"})
    },
    addDeviceEmpty() {
      $("#modal-add-device").modal("show");
    },
    noneHome(serial_number, name, password, set_password) {
      const noneHomeinfo = {
        name: name,
        password: password,
        set_password: set_password,
      };

      localStorage.setItem("infoNoneHome", JSON.stringify(noneHomeinfo));
      this.$router.replace({
        name: "GatewayNoneInfo",
        params: {
          serial_number: serial_number,
        },
      });
    },
    noneHomeIR(serial_number, name, password, set_password) {
      const noneHomeinfo = {
        name: name,
        password: password,
        set_password: set_password,
      };
      localStorage.setItem("infoNoneHome", JSON.stringify(noneHomeinfo));
      this.$router.push({
        name: "irGatewaynone",
        params: {
          serial_number: serial_number,
        },
      });
    },
    lastStatus(user_id, token) {
      let self = this;
      // var tapp = localStorage.getItem("t_app");
      // var none_home_div = document.getElementById("none-home");
      // var home_div = document.getElementById("home");
      // var empty_home = document.getElementById("empty-home");

      var data = {
        user_id: user_id,
        token: token,
      };
      let URL = getUrl.info;
      const p = postAjaxCall(URL, data, "mainPage");
      this.openModalLoading();
      p.then(function (response) {
        self.exitModalLoading();
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.exitModalLoading();
          self.homes = jsonArray.content.homes;
          self.nonehomes = jsonArray.content.none_home;
          // console.log("self.jsonArray",jsonArray)
          // console.log("self.nonehomes",self.nonehomes)
          self.profile.name = jsonArray.content.user[0].fname + " " + jsonArray.content.user[0].lname;
          self.profile.mobile = jsonArray.content.user[0].mobile;
          let homes = jsonArray.content.homes;
          let users = jsonArray.content.user;
          let nonehomes = jsonArray.content.none_home;
          users.forEach(function (item) {
            //----------------Data for Vuex
            const userinfo = {
              mobile: item.mobile,
              lname: item.lname,
              fname: item.fname,
              address: item.address,
            };
            //----------------Vuex
            localStorage.setItem("infoUser", JSON.stringify(userinfo));
          });

          if (homes.length == 0) {
            if (nonehomes.length == 0) {
              // empty_home.style.display = "block";
              self.show_empty_home=true
            }
          }
          if (homes.length == 0) {
            // home_div.style.display = "none";
            self.show_home_div=false
          }
          if (nonehomes.length !== 0) {
            // none_home_div.style.display = "block";
            self.show_none_home=true
          }
          // self.show_page=true;
          // console.log("show_page",self.show_page)
        } else {
          self.exitModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.replace({name: "home"});
            }
          }
        }
      })
    },
    getNoneDevices() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      let data = {
        user_id: user_id,
        token: token,
      };
      let URL = getUrl.noneTypes;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.exitModalLoading();
          self.select = jsonArray.content.types;
        } else {
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.replace({name: "home"});
            }
          }
        }
      });
    },
    showAddDevice(select_type_code, label) {
      this.dialog = false;
      localStorage.setItem(
          "infoRelay8oLocal",
          JSON.stringify(this.localRelaydata)
      );
      switch (select_type_code) {
        case 11:
        case 12:
          this.$router.push({
            name: "ModalAddDevice",
          });
          break;
        case 16:
          this.$router.push({
            name: "noneHomeRelayAdd",
          });
          break;
        case 19:
          this.label = label
          this.$router.push({
            name: "ModalAddDeviceFarapol",
            params: {
              label: label,
            },
          });
          break;
          case 25:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 26:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 27:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 28:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          case 29:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 31:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 32:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
          break;
          case 37:
          this.$router.push({
            name: "ModalAddDevice",
            params: {
              label: label,
            },
          });
      }
    },
    getIndexRowSub(val, type) {
      this.serial_number = this.nonehomes[val].serial_number;

      switch (type) {
        case 16:
        this.$router.push({
          name: "noneSubRelayItem",
          params: {serial_number: this.serial_number},
        });
        break;
        case 19:
        localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "keyTouchNoneInfo",
          params: {serial_number: this.serial_number},
        });
        break;
        case 25:
        localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "N2RelayInfo",
          params: {serial_number: this.serial_number},
        });
        break;
        case 26:
           localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "NCurtainInfo",
          params: {serial_number: this.serial_number},
        });
           break;
           case 27:
           localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "NCoolerInfo",
          params: {serial_number: this.serial_number},
        });
            break;
            case 28:
            // console.log("this.nonehomes[val])",this.nonehomes[val]);
            
           localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "NServiceInfo",
          params: {serial_number: this.serial_number},
        });
            break;
            case 29:
           localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "NThermostatInfo",
          params: {serial_number: this.serial_number},
        });
            break;
            case 37:
           localStorage.setItem("infoNoneHome", JSON.stringify(this.nonehomes[val]));
        this.$router.push({
          name: "NThermostaticInfo",
          params: {serial_number: this.serial_number},
        });
            break;
    }
    },
    showDelDialog(sn) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.delRelay(sn);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    delRelay(serial_number) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      this.openModalLoading();
      //-----------------------Request ------------------------------//
      let URL = getUrl.subDelete;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.subBoxRelays = [];
                self.closeModalLoading();
                self.getHomeRelays();
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    configDevice() {
      $("#modal-right").modal("hide");
      this.$router.replace({name: "ModalConfig"});
    },
    settings() {
      $("#modal-right").modal("hide");
      this.$router.replace({name: "mainSettings"});
    },
    openMenu() {
      // eslint-disable-next-line no-undef
      this.showslide=true
      // console.log("openMenu")
      $("#modal-right").modal({
        backdrop: false,
      });
      // eslint-disable-next-line no-undef
      $("#modal-right").modal("show");
    },
    aboutUs() {
      $("#modal-right").modal("hide");
      this.$router.replace({
        name: "aboutus",
      });
    },
    // dim(){
    //    this.$router.push({ name: "dimmerinfo" });
    // },
    goRefresh() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      this.lastStatus(user_id, token);
    },
  },
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
}

.text-overflow-handle {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

</style>
