<template>
  <div class="modal center-modal fade" id="modal-pass-code" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t('message.public.security') }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" @click="cancelFunction">
            <span aria-hidden="true" style="font-size: 28px">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <h6>
              {{ $t('message.ModalAddHome.pin_code') }} <span
                class="text-danger"
            >*</span>
            </h6>
            <input
                type="number"
                class="form-control"
                required=""
                aria-invalid="false"
                maxlength="4"
                pattern="[0-9]"
                autocomplete="off"
                id="idpasscurtain"
                style="-webkit-text-security: disc; margin-top: 8px"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                :placeholder="$t('message.ModalAddHome.password_hint')"
                v-model="passcode"
            />
          </div>
          <div class="row">
            <!-- /.col -->
            <div class="col-12 text-center">
              <button
                  type="button"
                  class="btn btn-info btn-block margin-top-10"
                  @click="sendPassCode()"
              >
                  <span class="text-white"
                  > {{ $t('message.public.send') }} </span
                  >
              </button>
            </div>
            <!-- /.col -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CmpPasscode",
  data() {
    return {
      passcode: ""
    };
  },
  methods: {
    sendPassCode() {
      this.$emit("sendPassCode", this.passcode);
      $("#modal-pass-code").modal('hide');
      this.passcode = ""
    },
    cancelFunction() {
      this.passcode = ""
      this.$emit("cancel");
      $("#modal-pass-code").modal('hide');
    },
  },
}
</script>

<style scoped>

</style>