<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "CoolerFaraTimer",
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {}
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  created() {
    this.data = {
      items: [
        {
          name: this.$t('message.relay.type.curtain')+" "+this.$t('message.public.open'),
          status: true
        },
        {
          name: this.$t('message.relay.type.curtain')+" "+this.$t('message.public.close'),
          status: true
        }
      ],
      type: "curtainFara"
    }
  },
  mounted() {

    let self = this;
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
    this.$store.dispatch("InfoSubDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(val, status);
      switch (val) {
        case 0:
          this.nextL1();
          break;
        case 1:
          this.nextL2();
          break;
      }
    },
    nextL1() {
      console.log(this.count , 'get count')
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let status_checkbox = "K1T";
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CurtainRFStep2Timer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL2() {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let status_checkbox = "K1F";
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "CurtainRFStep2Timer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    goBackTimer() {
      this.$router.push({
        name: "CurtainRFTimerShow",
        params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "CurtainRFTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
        });
      }
    },
  },
}
</script>

<style scoped>

</style>