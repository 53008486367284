import i18n from "../../../../src/i18n";
export function getControlAirStatus(message,version) {
    var temp='';
    var fan='';
    var mode='';
    var swimV='';
    var swimH='';
    if(version=='V1'){
         
      if(message.power=="OFF"){
        return i18n.t('message.public.off');
    }
       if(message.power=="ON"){
             temp=message.temp
            if (message.mode=="COOL")  mode=i18n.t('message.subDevice.thermostat.cool')
            if (message.mode=="HEAT") mode=i18n.t('message.subDevice.thermostat.heat')
            if (message.mode=="DRY") mode=i18n.t('message.irAir.dry')
            if (message.mode=="AUTO") fan=i18n.t('message.irAir.auto')
            if (message.fan=="LOW") fan=i18n.t('message.subDevice.thermostat.fan_low')
            if (message.fan=="HIGH")fan=i18n.t('message.subDevice.thermostat.fan_high')
            if (message.fan=="MED") fan=i18n.t('message.subDevice.thermostat.fan_auto')
            if (message.swimH=="ON") swimH=i18n.t('message.irAir.swim_h')+' : '+i18n.t('message.public.on')
            if (message.swimV=="ON") swimV=i18n.t('message.irAir.swim_v')+' : '+i18n.t('message.public.on')
            if (message.swimH=="OFF") swimH=i18n.t('message.irAir.swim_h')+' : '+i18n.t('message.public.off')
            if (message.swimV=="OFF") swimV=i18n.t('message.irAir.swim_v')+' : '+i18n.t('message.public.off')
        return temp+'°'+Array(2).fill('\xa0').join('')+mode +Array(2).fill('\xa0').join('')+ fan+Array(2).fill('\xa0').join('')+ swimH+Array(2).fill('\xa0').join('')+ swimV ;   
    }
 
    } 
}