<template>
  <v-app class="bg">
    <div>
      <div class="row px-20 mt-20">
        <div class="col-lg-12 col-12 py-0">
          <div class="box rounded-16">
            <div class="box-body pb-0">
              <div class="row">
                <div class="col-xl-12 col-md-12 col-12 py-0">
                  <div
                      class="box-footer with-border p-0"
                      v-bind:key="index"
                      v-for="(info, index) in infos"
                  >
                    <CmpRow2RF :info="info">
                      <label class="switch" v-if="info.id!=5">
                        <input
                            name="checkL4"
                            type="checkbox"
                            v-model="info.value"
                            v-bind="$attrs"
                            :disabled="!info.active || index === 3"
                        />
                        <span class="switch-indicator"></span>
                      </label>
                      <template v-slot:select>
                        <div class="d-flex">
                          <v-checkbox v-model="info.active" class="mt-0" @click="chkClick(info.id)"></v-checkbox>
                        </div>
                      </template>
                    </CmpRow2RF>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CmpRow2RF from "../CmpRow2RF.vue";

export default {
  name: "CmpCoolerSenario",
  components: {
    CmpRow2RF
  },
  props: ["infos"],
  data() {
    return {
      cmpShow: false,
      opacityLow: {
        opacity: 0.2
      },
      opacityHigh: {
        opacity: 1
      },
    };
  },
  methods: {
    chkClick(id) {
      this.infos.forEach(function (item, i) {
        if (item.id != id) {
          item.active = false;
        }
      });

    }
  },
}
</script>

<style scoped>

</style>