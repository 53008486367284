<template>
  <!-- .wrapper  -->
  <div
    class="modal modal-fill fade"
    data-backdrop="false"
    id="modal-edit-outlet"
    tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar @clicked="onClickChild"></AppBar>
      <section class="content">
        <div class="row">
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                          src="/assets/images/smart/vector-edit.svg"
                          class="avatar-smart"
                        />
                        <h5 class="font-weight-bold">
                          {{$t('message.CmpEditName.edit')}}
                        </h5>
                      </div>
                    </div>
                    <br />
                    <form novalidate class="px-20">
                      <div class="form-group">
                        <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
                        <input
                          type="text"
                          class="form-control"
                          required=""
                          aria-invalid="false"
                          maxlength="32"
                          autocomplete="off"
                          id="editnameoutlet"
                          :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                          v-model="editnameoutlet"
                        />
                      </div>
                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">
                          <v-btn
                            class="btn btn-info btn-block"
                            :loading="loading"
                            :disabled="loading"
                            color="bg-primary"
                            @click="EditName()"
                          >
                            <span class="text-white"> {{ $t('message.public.submit') }} </span>
                          </v-btn>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="modal center-modal fade"
      id="modal-center"
      tabindex="-1"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{$t('message.ModalCenter.loading')}}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{$t('message.ModalCenter.loading')}}</span>
            </div>
            <span class="pl-20" id="txtwait">{{$t('message.ModalCenter.wait')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
</template>

 <script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import AppBar from "../AppBar";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {AppBar},
  props: {
    data_infos: { type: Array },
  },
  data() {
    return {
      editnameoutlet: "",
      title: '',
      loading: false,
    };
  },
  created() {
    let data = {
      title: this.$t('message.CmpEditName.edit_name'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
  },
  mounted() {
    let self = this;
    this.title= this.$t('message.CmpEditName.edit_name')
    setTimeout(function () {
      self.editnameoutlet = self.data_infos[2];
    }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        $("#modal-edit-outlet").modal("hide");
      }
    },
    EditName() {
      let self = this;

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.data_infos[0];
      let serial_number = this.data_infos[1];

      if (!nameDeviceValidationInput(this.editnameoutlet)) return;
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: this.editnameoutlet,
        serial_number: serial_number,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.deviceEdit;
      const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            self.loading = false;
            $("#modal-edit-outlet").modal("hide");

            self.$router.push({
              name: "homeDevices",
              params: {
                serial_number: serial_number,
                home_id: home_id,
              },
            });
          } else {
            //error
            self.loading = false;
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          console.log("err", errr);
        }
      );
    },
  },
};
</script>
<style scoped>
</style>
