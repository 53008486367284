<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <CmpControlTv
            :data_list="listactive"
            @clicked="getTvControlCmpCallback"
            :mode="'Done'"
        ></CmpControlTv>
      </section>
    </div>
    <EditName v-bind:data_name="title" @clicked="getNameCmpCallback"></EditName>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>
<script>
import {BetweenStr} from "../../../../../public/assets/js/utils/util.js";
import {SucessToastMsg} from "../../../../../public/assets/js/utils/util.js";
import {ErrorToastMsg} from "../../../../../public/assets/js/utils/util.js";
import {ToastCustom} from "../../../../../public/assets/js/utils/util.js";
import EditName from "@/components/Cmp/EditName.vue";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command.js";
import CmpControlTv from "../../../ir/CmpControlTv.vue";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;

var timer;

export default {
  components: {EditName, CmpControlTv},
  props: {
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      isActive: true,
      data_info: [this.serial_number, this.count],
      title: "",
      learned: '',
      listactive: [
        {
          id: 0,
          active: false,
          code: ''
        },
        {
          id: 1,
          active: false,
          code: ''
        },
        {
          id: 2,
          active: false,
          code: ''
        },
        {
          id: 3,
          active: false,
          code: ''
        },
        {
          id: 4,
          active: false,
          code: ''
        },
        {
          id: 5,
          active: false,
          code: ''
        },
        {
          id: 6,
          active: false,
          code: ''
        },
        {
          id: 7,
          active: false,
          code: ''
        },
        {
          id: 8,
          active: false,
          code: ''
        },
        {
          id: 9,
          active: false,
          code: ''
        },
        {
          id: 10,
          active: false,
          code: ''
        },
        {
          id: 11,
          active: false,
          code: ''
        },
        {
          id: 12,
          active: false,
          code: ''
        },
        {
          id: 13,
          active: false,
          code: ''
        },
        {
          id: 14,
          active: false,
          code: ''
        },
        {
          id: 15,
          active: false,
          code: ''
        },
      ],
      user_id: '',
      token: '',
      isModalVisible: false,
    };
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mqtt: {
    "rnd/+/r/+/action"(data, topic) {
      var serail = BetweenStr.getBetweenStr(topic, "rnd/", "/r");
      var count = BetweenStr.getBetweenStr(topic, "r/", "/action");
      if (serail[1] == this.serial_number && count[1] == "256") {
        if (topic.split("/")[4] === "action") {
          clearTimeout(timer);
          connection = true;
          this.exitModalLoading();
          this.showGetMsg(String(data));
        }
      }
    },
    "+/+/receive/256/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          clearTimeout(timer);
          connection = true;
          this.exitModalLoading();
          console.log("data after", String(data))
          this.showGetMsg(String(data));
        }
      }
    },
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
  },
  async mounted() {
    let self = this;
    await this.$store.dispatch("infoIrControls");
    await this.$store.dispatch("infoNoneHome");
    this.onSubscribe();
    const promise = await self.lastStatus();
    if (promise) {
      let details = {
        title: this.title,
        menu: true,
        config: true,
        pin: false,
        editName: true,
        del: true,
        noneHome: true,
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'irtvnone');
    }
  },
  methods: {
    golearnIr() {
      // the root is problem
      this.$router.push({
        name: "irtvlearnnone",
        params: {
          serial_number: this.serial_number,
          count: this.count,
        },
      });
    },
    delTvControl() {
      $("#modal-del-control-none").modal("show");
    },
    Done(key, code) {
      let self = this;
      // this.data_info.push(value);

      var message = getCommand.getCommandDone(this.count, key);
      var topic_final = getTopics.getTopicActionSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
   
      let URL = getUrl.nonemqttRequest;

      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();

      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {

          connection = false;
          var jsonArray = JSON.parse(response.responseText);
          //  setTimeout(function(){ self.$emit("close"); }, 30000);
          timer = setTimeout(function () {
            self.exitModalLoading();
            if (!connection) {
              ErrorToastMsg.Msg();
            }
            connection = false;
          }, 5000);

        } else {
          //error
        }
      });
    },
    unActive() {
      ToastCustom.custom(this.$t('message.public.operation_failure'), "#fd7e14")
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      console.log("msg mqtt", msg)
      var done = msg.includes("DONE");
      var del = msg.includes("DELETEOK");
      var msg1 = BetweenStr.getBetweenStr(msg, "CONTROL", "-KEY");
      if (done) {
        if (msg1[1] == this.count) {
          SucessToastMsg.Msg();
        }
      }
      if (del) {
        ToastCustom.custom(this.$t('message.irTv.delete'), '#28a745', '#fff')
        this.$router.replace({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
    },
    async lastStatus() {
      let self = this;
      let uid = this.$store.getters.getUIDIrControl;
      // ------------------Data--------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        control_uid: uid,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.controlInfo;

      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      // ------------------Rseponse----------------//
      return p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              var control = jsonArray.content.control[0]
              self.title = control.name
              let key_info = control.key_info;
              self.learned = control.learned;
              self.exitModalLoading();
              key_info.forEach(function (item) {
                var key = item.key_count;
                for (let i = 0; i <= 15; i++) {
                  if (i == key) {
                    self.listactive[i].active = true;

                    if (item.key_count !== null) {
                      self.listactive[i].code = item.key_count;
                    }
                  }
                }
              });
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
              return new Promise((resolve, reject) => {
                resolve(false);
              });
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    getTvControlCmpCallback(msg) {
      if (msg == '' && msg != '0') {
        this.unActive();
      } else {
        this.Done(msg);
      }
    },
    getNameCmpCallback(msg) {
      let self = this
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        name: msg,
        control_uid: uid,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.controlEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.lastStatus();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic1);
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "config") {
        this.$router.push({
          name: "irtvlearnnone",
          params: {
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "del") {
        this.$swal({
          title: this.$t('message.ir.delete'),
          text: this.$t('message.ir.txt_c'),
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#0F6BB9",
          confirmButtonText: this.$t('message.public.delete'),
          cancelButtonText: this.$t('message.public.cancel'),
          allowOutsideClick: false,
        }).then(
            (result) => {
              if (result) {
                this.deleteControl();
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
                console.log("dissmis", dismiss);
              } else {
                throw dismiss;
              }
            }
        );
      }
      if (value == "edit") {
        $("#modal-edit-name").modal("show");
      }
    },
    deleteControl() {
      let self = this;
      let saction = getCommand.getCommandDeleteControl(this.count)
      var topic_final = getTopics.getTopicActionSend(this.serial_number, '256');
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            setTimeout(function () {
              if (!connection) {
                swal("", self.$t('message.public.operation_failure'), "error");
                self.loading = false
                self.exitModalLoading()
              }
            }, 5000);
          },
          function (errr) {
            self.exitModalLoading()
          }
      );


    },
  },
};

</script>

<style scoped>
</style>
