<template>
  <v-app class="bg">
    <div class="mr-0" >
      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="box">
              <div class="row no-gutters justify-content-md-center">
                <div class="col-lg-3 col-md-5 col-12">
                  <div style="text-align: center" class="mt-3">
                    <img :src="'/assets/images/smart/anim-door.jpg'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mx-2 mb-1">
          <div class="col-12">
            <div class="container box">
              <v-row d-flex>
                <v-col cols="12  d-flex justify-content-center"><label class="text-center"> {{ $t('message.subDevice.doorshutter.status_doorshutter') }} :
                  {{ txtStatus }}</label></v-col>
              </v-row>
              <div class="row d-flex">
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="openFunction()"
                    >
                      <i
                        class="mdi mdi-garage-open mdi-36px align-self-center"
                      ></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="stopFunction()"
                    >
                      <i class="mdi mdi-stop mdi-36px align-self-center"></i>
                    </a>
                  </div>
                </div>
                <div class="col-md-4 d-flex justify-content-center">
                  <div
                    class="
                      col-3
                      d-flex
                      align-items-start
                      justify-content-center
                      align-self-center
                    "
                  >
                    <a
                      class="
                        btn btn-app btn-outline-secondray
                        d-flex
                        justify-content-center
                      "
                      v-on:click.prevent="closeFunction()"
                    >
                      <i class="mdi mdi-garage mdi-36px align-self-center"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <ModalOutletMomentray
      v-bind:serials="serial_number"
      :v-bind:subdevice_serial="subdevice_serial"
      v-bind:opentime="opentime" 
      v-bind:closetime="closetime" 
      type="doorShutter"
      @set_time_shutter="setTimeShutter"
    ></ModalOutletMomentray>
    <ModalResetFactoryDoorShutter :infoItem="dataReset"></ModalResetFactoryDoorShutter>
    <SubModalDel v-bind:data_info_sub="data_info_curtain"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_curtain"></SubModalEdit>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_curtain"
    ></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {ErrorToastMsg, SucessToastMsg} from "@/../public/assets/js/utils/util";
import {getTopics} from "@/../public/assets/js/utils/topics";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {Color, hexToRgb} from "@/../public/assets/js/utils/colorRGB";
import {Solver} from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryDoorShutter from "../../subdevice/doorshutterpro/ModalResetFactoryDoorShutter";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import {getMessageSub} from "@/../public/assets/js/utils/helper_sub_message.js";
import {passHomeValidationInput} from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    ModalResetFactoryDoorShutter,
    ModalOutletMomentray,

  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      type_Selected_item:"action",
      // passkeytouchfara: "",
      // data_info_keytouch_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      // pass: true,
      // pol: 1,
      // labels: [],
      isModalVisible: false,
      subdevice_serial:"",
      curtain_status: "",
      pass: 0,
      data: [],
      info: [],
      // rgbInfo:[],
      dataReset:[],
      txtStatus: '',
      data_info_curtain: [this.home_id, this.serial_number, this.count],
      delayOpen:"",
      keyOpen:"",
      delayClose:"",
      keyClose:"",
      typeRequest:0,
      opentime :30,
      closetime : 30,
      // polall:false,
      // pol1:false,
      // pol2:false,
      // pol3:false,
      // pol4:false,
      // result1: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result2: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result3: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result4: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      set_password: 0,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
 
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data))
        this.showGetMsgBroadCast(String(data));
      }
    },
  },
  created() {
    let self=this;
    let data = localStorage.getItem("infoSubDevice");
    data = JSON.parse(data);
    // if (!JSON.parse(data.labels))
    //   this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
    //     pol: "3",
    //     label: "L4"
    //   }];
    // else
    //   this.labels = JSON.parse(data.labels);
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
  },
  mounted: function mounted() {
   
    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      // this.pol = this.$store.getters.getPolSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;

    // this.showHidePol();
    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    console.log("info",this.info.instant_setting)
    this.subdevice_serial = this.info.serial_number;
    this.dataReset.push({serial_number:this.serial_number,subdevice_serial:this.subdevice_serial})
    // init run
    // try {
     
      this.lastStatus();
      // subscribe
     this.onSubscribe();
   
     
    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      sub_setting:true,
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'SubKeyTouchPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {

    onSubscribe(){
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSettingReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
    },
    setTimeShutter(delayOpen, keyOpen, delayClose, keyClose) {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
        this.delayOpen=delayOpen;
        this.keyOpen=keyOpen;
        this.delayClose=delayClose;
        this.keyClose=keyClose;
        this.typeRequest=1;
       
      } else {
        this.sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, "");
      }
    },
    sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var sendDelayTime = getMessageSub.settingDelayTime(keyOpen,delayOpen,keyClose,delayClose)
      var topic_final = getTopics.getTopicElevatorSend(
        this.serial_number,
        this.subdevice_serial
      );

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: sendDelayTime,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            connection = false;
            console.log('jsonArray',jsonArray);
            $("#modal-pass-code").modal("hide");
            $("#modal-outlet-momentray").modal("hide");
            timeout = setTimeout(function () {
              self.closeModalLoading();
              // showToast = 0;
              // connection = false;
              if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }}
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.closeModalLoading();
            $("#modal-outlet-momentray").modal("hide");
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    openFunction() {
      this.sendMessage("K1T#");
      this.typeRequest=0;
    },
    stopFunction() {
      this.sendMessage("K2T#");
      this.typeRequest=0;
    },
    closeFunction() {
      this.sendMessage("K3T#");
      this.typeRequest=0;
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
          function (response) {
            self.closeModalLoading();
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              // ****** code save data in subdevice
              self.sub_device_info.push({
                  subDevices: jsonArray.content.device_info
              })
              localStorage.setItem("infoSubDevice",JSON.stringify(self.sub_device_info[0].subDevices))
               // ****** code save data in subdevice
             self.parseStatusCurtain(message);
             self.parseInstantSetting(self.info.instant_setting)
            } else {
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },

    // parserRgbSetting(){
    //   let self=this;
    //   if(this.info.rgb_setting==null){
    //     this.info.rgb_setting='L1ON00ff00L1OFFff0000L2ON00ff00L2OFFff0000L3ON00ff00L3OFFff0000L4ON00ff00L4OFFff0000'
    //   }
    //   var arrRgb =this.info.rgb_setting.split('L');
    //   arrRgb.forEach((item, index) => { 
    //           let str="";
    //           if(item.match("ON")){
    //             str = item.substr(0 + 3, 6);
    //           }
    //           if(item.match("OFF")){
    //             str = item.substr(0 + 4, 6);
    //           }
    //           self.rgbInfo.push({"id":index,"color":'#'+str})
    //    })
    //    this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
    //    onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color,self.rgbInfo[5].color,self.rgbInfo[7].color],
    //    offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
    // };
     
    //   },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if(value== "sub_setting"){
        this.$router.push({
          name: "DoorProSetting",
          params: { home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value === "momentary") {
        $("#modal-outlet-momentray").modal("show");
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "AboutDoorShutterPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "wifi_sub_onoff") {
        this.$router.push({
          name: "DoorShutterProStatusWifi",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "subReset") {
        $("#modal-reset-doorshutterPro").modal('show');
      }
      if (value == "update") {
        this.showUpdateDialog();
      }
      if (value == "editRgb") {
        this.$router.push({
          name: "DoorShutterEditRGBPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStatus();
        // location.reload();
      }
      // if (value == "timer") {
      //   let data = {
      //     // labels: this.labels,
      //     // pol: this.pol,
      //     type: "PRO",
      //     type_code:this.info.type.type_code,
      //   }
      //   localStorage.setItem("data_edit_sub", JSON.stringify(data));
      //   this.$router.push({
      //     name: "DoorShutterProTimerShow",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {
     
      this.curtain_status=status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.curtain_status, '');
      }
    },
    sendReq(password) {
    
      if(this.typeRequest==0){
        this.sendRequest(this.curtain_status, password);
        }
      else if(this.typeRequest==1){
        this.sendRequestLong(this.delayOpen, this.keyOpen, this.delayClose, this.keyClose,password);
      }
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final="";

    
      this.type_Selected_item=='update'?
      topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial):
      topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      console.log("topic_final",topic_final)
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      console.log("data",data)
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
             if (this.set_password == 1) {
               $("#modal-pass-code").modal("show");
             } else {
              this.type_Selected_item="update"
              this.sendRequest('UPDATE#','');
            }  
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    showGetMsgBroadCast(msg) {
     
     this.opentime = msg.split("K1:").pop().split("S")[0];
     this.closetime = msg.split("K3:").pop().split("S")[0];
     localStorage.setItem("openTimeShutter", this.opentime);
     localStorage.setItem("closeTimeShutter", this.closetime);
   },
    parseInstantSetting(msg){
      this.opentime = msg.split("K1:").pop().split("S")[0];
      this.closetime = msg.split("K3:").pop().split("S")[0];
    },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      this.parseStatusCurtain(msg)
      if(msg=="UPDATEOK"){
        swal({
        // title: self.$t('message.timer.timer'),
        title:this.$t("message.public.refresh"),
        text: this.$t("message.public.update_done"),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      }
    },
    parseStatusCurtain(msg) {
      if (msg == '1000') {
        this.txtStatus = this.$t('message.public.open')
      }
      if (msg == '0000') {
        this.txtStatus = this.$t('message.public.stop')
      }
      if (msg == '0010') {
        this.txtStatus = this.$t('message.public.close')
      }
    },
  },
}
</script>

<style scoped>

</style>