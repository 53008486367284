<template>
  <!-- .wrapper  -->
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-edit-home-none"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <!-- .header -->
      <AppBar v-bind:title="title" v-bind:nonehome="true" @clicked="onClickChild"></AppBar>
      <!-- ./header -->
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper mr-0 bg-white">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="bg-white">
                      <div style="text-align: center; margin-top: 10px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                              src="/assets/images/smart/vector-houses.svg"
                              class="avatar-smart"
                          />
                          <h5 class="font-weight-bold">
                            {{ $t('message.noneHome.edit_device') }}
                          </h5>
                        </div>
                      </div>
                      <br/>
                      <form class="px-20">
                        <div class="form-group">
                          <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
                          <input
                              type="text"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              maxlength="32"
                              autocomplete="off"
                              id="editnameoutlet"
                              :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                              v-model="editnameoutlet"
                          />
                        </div>
                        <div class="row">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                          
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="editHomeName()"
                            >
                              <span class="text-white">{{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                          <!-- /.col -->
                        </div>
                      </form>
                      <div class="text-center" style="height: 50px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
        <!-- /. Main content -->
        <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false"
             data-backdrop="static">
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
                </div>
                <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.Content Wrapper. Contains page content -->

    </div>
    <!-- /.modal Add Home-->
    <my-modal v-show="isModalVisible"></my-modal>

  </div>
  <!-- ./wrapper -->
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameHomeValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
// TODO "edit_device","edit" not found in ar
export default {
  components: {
    AppBar,

  },
  data: function () {
    return {
      namenone: '',
      title: '',
      editnameoutlet: '',
      loading: false,
      isModalVisible: false
    };
  },
  props: {
    serialnumbers: {type: String},
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    this.editnameoutlet = this.$store.getters.getNameNoneHome;
  },
  mounted() {
    this.title = this.$t('message.noneHome.edit');
    // this.btnLoading();
    this.$store.dispatch("infoNoneHome");
    this.namenone = this.$store.getters.getNameNoneHome;

  },
  methods: {
    onClickChild(value) {

      if (value == 'back') {
        $("#modal-edit-home-none").modal("hide");
      }

    },

    editHomeName() {
      let self = this;
      this.loading = true;
      var name = this.editnameoutlet;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.homeids;
      let serial_number = this.serialnumbers;
      if (!nameHomeValidationInput(name)) return;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        serial_number: serial_number

      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.noneDeviceEdit;
      const p = postAjaxCall(URL, data);
      // this.openModalLoading();

      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              // self.exitModalLoading();
              self.loading = false
              self.$router.push({name: "mainpage"});
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {

          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    }, 
  },
};
</script>


<style scoped>
</style>
