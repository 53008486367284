<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-pass-status-outlet"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar @clicked="onClickChild"></AppBar>
      <div class="content-wrapper mr-0 bg-white">
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="bg-white">
                      <div style="text-align: center; margin-top: 10px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                              src="/assets/images/smart/setpassword.svg"
                              class="avatar-smart"
                          />
                          <h6 class="font-weight-bold">
                            {{ $t('message.ModalAddHome.password_hint') }}
                          </h6>
                        </div>
                      </div>
                      <br/>
                      <form class="px-40">
                        <div class="form-group">
                          <input
                              type="number"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              maxlength="4"
                              pattern="[0-9]"
                              id="change_pass_status"
                              autocomplete="off"
                              style="-webkit-text-security: disc;margin-top:8px"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :placeholder="$t('message.ModalAddHome.password_hint')"
                              v-model="pass"
                          />
                        </div>

                        <div class="row">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="FunPassStatus()"
                            >
                              <span class="text-white"> {{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                          <!-- /.col -->
                        </div>
                      </form>
                      <div class="text-center" style="height: 50px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
            </div>
            <div class="modal-body">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import AppBar from "../AppBar";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";


export default {
  name: "Modal",
  data: function () {
    return {
      pass: '',
      loading: false,
    }
  },
  components: {
    AppBar
  },
  props: {
    data_infos: {type: Array}
  },
  created() {
    let data = {
      title: this.$t('message.ModalAddHome.pin_code'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
  },
  methods: {
    onClickChild(value) {
      console.log("value", value)
      if (value == 'back') {
        this.pass = "";
        $('#modal-pass-status-outlet').modal('hide');
      }
    },
    FunPassStatus() {

      var password = this.pass;
      let self = this;
      if (!passHomeValidationInput(password)) return
      console.log("password", password)
      this.loading = true;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.data_infos[0];
      let serial_number = this.data_infos[1];

      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        password: password
      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.passStatus;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {

              self.loading = false
              self.$router.push({
                name: "homeDevices",
                params: {home_id: home_id, serial_number: serial_number},
              });
              $('#modal-pass-status-outlet').modal('hide');
              self.pass = "";
              //  location.reload();
            } else {
              //error
              self.loading = false
              if (response.status == 401) {
                if (jsonArray.code == '4001') {
                  self.$router.push({name: "home"});
                }
              }

            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {

          });

    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}