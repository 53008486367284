<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <CmpControlAmplifier
            :data_list="listactive"
            @clicked="getAmpliControlCmpCallback"
            :mode="'Learn'"
        ></CmpControlAmplifier>
        <CmpTips :data_txt="tips_txt" :data_type="'default'" class="mt-5"></CmpTips>
      </section>
    </div>
    <ModalIrNone
        @clicked="getCancelModalIrCallback"
        v-show="isModalVisibleIrVol"
    ></ModalIrNone>
  </v-app>
</template>

<script>
import ModalIrNone from "../ModalIrNone";
import {BetweenStr} from "../../../../../public/assets/js/utils/util.js";
import {SuccessAlert} from "../../../../../public/assets/js/utils/util.js";
import {ErrorAlert} from "../../../../../public/assets/js/utils/util.js";
import CmpControlAmplifier from "../../../ir/CmpControlAmplifier.vue";
import CmpTips from "../../../Cmp/public/CmpTips.vue";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command.js";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;
var timer;

export default {
  components: {
    ModalIrNone,
    CmpTips,
    CmpControlAmplifier,
  },
  props: {
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      isModalVisibleIrVol: false,
      data_info: [this.serial_number, this.count],
      title: "",
      listactive: [
        {
          id: 0,
          active: false,
          code: 0,
        },
        {
          id: 1,
          active: false,
          code: 1,
        },
        {
          id: 2,
          active: false,
          code: 2,
        },
        {
          id: 3,
          active: false,
          code: 3,
        },
        {
          id: 4,
          active: false,
          code: 4,
        },
        {
          id: 5,
          active: false,
          code: 5,
        },
        {
          id: 6,
          active: false,
          code: 6,
        },
        {
          id: 7,
          active: false,
          code: 7,
        },
        {
          id: 8,
          active: false,
          code: 8,
        },
        {
          id: 9,
          active: false,
          code: 9,
        },
        {
          id: 10,
          active: false,
          code: 10,
        },
        {
          id: 11,
          active: false,
          code: 11,
        },
        {
          id: 12,
          active: false,
          code: 12,
        },
        {
          id: 13,
          active: false,
          code: 13,
        },
        {
          id: 14,
          active: false,
          code: 14,
        },
        {
          id: 15,
          active: false,
          code: 15,
        },
      ],
      key: '',
      cancelModal: false,
      tips_txt:
          this.$t('message.irAmplifier.tip')
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          connection = true;
          this.closeModalIr();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  mounted() {
    this.onSubscribe();
    this.$store.dispatch("infoNoneHome");
    this.$store.dispatch("infoIrControls");
    this.title =
        this.$t('message.public.configuration') +
        Array(2).fill("\xa0").join("") +
        this.$store.getters.getNameIrControl;
    let details = {
      title: this.title,
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IRLearnAmp');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    Learn(key) {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      this.key = key;
      var message = getCommand.getCommandLearn(this.count, key);
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);

      self.showModalIr();

      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          timer = setTimeout(function () {
            if (!connection && !self.cancelModal) {
              self.closeModalIr();
              ErrorAlert.Alert();
            }

            connection = false;
          }, 30000);
        } else {
          //error
          self.closeModalIr();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showModalIr() {
      this.isModalVisibleIrVol = true;
    },
    closeModalIr() {
      this.isModalVisibleIrVol = false;
    },
    showGetMsg(msg) {
      var self = this;
      var sucess = msg.includes("LEARNOK");
      var err = msg.includes("LEARNERROR");
      var cancel = msg.includes("CANECLERROR");
      if (sucess) {
        self.closeModalIr();
        clearTimeout(timer);
        SuccessAlert.Alert();
        connection = false;
      }
      if (err) {
        self.closeModalIr();
        ErrorAlert.Alert();
        connection = false;
      }
      if (cancel) {
        self.closeModalIr();
        clearTimeout(timer);
        connection = false;
      }
    },
    goBackIrAmplifierLearn() {
      this.$router.replace({
        name: "iramplifiernone",
        params: {serial_number: this.serial_number, count: this.count},
      });
    },
    getAmpliControlCmpCallback(msg) {
      this.Learn(msg);
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    getCancelModalIrCallback(msg) {
      this.cancelModal = true;
      if (msg == "cancel") {
        let self = this;
        var user_id = localStorage.getItem("user_id");
        var token = localStorage.getItem("token");
        var message = getCommand.getCommandCancelLearn(this.count, this.key);
        var topic_final = getTopics.getTopicActionSend(
            this.serial_number,
            "256"
        );
        // ----------------------Data------------------//
        var data = {
          user_id: user_id,
          token: token,
          topic: topic_final,
          message: message,
          // password: password,
          client_id: "fara" + Date.now(),
        };
        // --------------------Requsest----------------------//
        let URL = getUrl.nonemqttRequest;
        const p = postAjaxCall(URL, data);
        // ---------------------Rseponse--------------------//
        p.then(function (response) {
          if (responseAjax(response)) {
            var jsonArray = JSON.parse(response.responseText);
            self.closeModalIr();
          } else {
            //error
          }
        });
      }
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "iramplifiernone",
          params: {
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
