<template>
  <div class="wrapper m-0">
    <!-- .header -->
    <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white" style="height: 812px">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                          src="/assets/images/smart/vector-houses.svg"
                          class="avatar-smart"
                        />
                      </div>
                    </div>
                    <br />
                    <form class="px-40">
                      <div class="form-group">
                        <h6>{{ $t('message.client.OK') }}<span class="text-danger">*</span></h6>
                        <input
                          id="inputdelusercode"
                          type="number"
                          class="form-control"
                          aria-invalid="false"
                          autocomplete="off"
                          maxlength="6"
                          :placeholder="$t('message.loginVerify.inputCode')"
                          v-model="smscodedel"
                        />
                      </div>

                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn btn-info btn-block margin-top-10"
                            @click="confirmCode()"
                          >
                            {{ $t('message.public.submit') }}
                          </button>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>
                    <div class="text-center" style="height: 50px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
  </div>
  <!-- /.modal Add Home-->
</template>

 <script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    AppBar,
  },
  props: {
    data_infos: { type: Array },
    home_id: {},
    serial_number: {},
    verify: {},
  },
  data() {
    return {
      info: null,
      smscodedel: "",
      title: '',
    };
  },

  mounted() {
    this.title= this.$t('message.public.submit');
  },
  methods: {
    confirmCode() {
      //--------------------------------------  variable --------------------------------------//
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let verify_code = this.smscodedel;
      let admin_verify_id = this.verify;

      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        admin_verify_id: admin_verify_id,
        verify_code: verify_code,
      };
      //--------------------------------------Request --------------------------------------//
        let URL = getUrl.clientDelVerify;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            self.$router.push({ name: "mainpage" });
          } else {
            //error
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          console.log("err", errr);
        }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "clientdelhome",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
