<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-edit-name"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <section class="content bg-white">
        <div class="row">
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-edit.svg"
                            class="avatar-smart"
                        />
                        <h5 class="font-weight-bold">
                          {{ $t("message.CmpEditName.edit") }}
                        </h5>
                      </div>
                    </div>
                    <br/>
                    <form novalidate class="px-20">
                      <div class="form-group">
                        <h6>
                          {{
                            $t("message.public.name")
                          }}<span class="text-danger">*</span>
                        </h6>
                        <input
                            type="text"
                            class="form-control"
                            required=""
                            aria-invalid="false"
                            maxlength="32"
                            autocomplete="off"
                            :placeholder="$t('message.loginVerify.inputFname')"
                            v-model="data_name"
                            ref="myTestField"
                        />
                      </div>
                      <div class="row">
                        <div class="col-12 text-center">
                          <v-btn
                              class="btn btn-info btn-block mt-10"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="EditName()"
                          >
                            <span class="text-white">
                              {{ $t("message.public.submit") }}</span
                            >
                          </v-btn>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.modal Add Home-->
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">
              {{ $t("message.ModalCenter.loading") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{
                  $t("message.ModalCenter.loading")
                }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{
                $t("message.ModalCenter.wait")
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
</template>
<script>
// import AppBar from "@/components/AppBar.vue";
//--------------------------------------  variable --------------------------------------//
import LayoutDefault from "../../layouts/LayoutDefault";
import AppBar from "../AppBar";

export default {
  components: {AppBar},
  props: {
    data_name: "",
    click: {}
  },
  watch: {
    data_name(newVal) {
      this.name = newVal;
    },
    click(val) {
      this.onClickChild(val);
    }
  },
  data() {
    return {
      title: "",
      loading: false,
      name: '',
    };
  },

  created() {
    let data = {
      title: this.$t("message.public.edit"),
      // noneHome: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Editname');
  },
  mounted() {
    // this.name = this.data_name;
  },

  methods: {
    EditName() {
      let self = this;
      this.loading = true;
      //  this.data_name=this.$refs.myTestField.value
      this.$emit("clicked", this.name);
      setTimeout(function () {
        self.loading = false;
      }, 3000);
    },
    onClickChild(value) {
      if (value === "back") {
        $("#modal-edit-name").modal("hide");
      }
    },
  },
};
</script>
<style scoped>
</style>
