<template>
    <v-app  class="bg">
     <div class="px-3 mt-8">
        <v-card max-width="1750" class="mx-auto">
            <v-row class="justify-center px-3 py-0">
            <v-col cols="1" class="align-self-center">
              <v-icon color="primary">mdi-format-color-text</v-icon>
            </v-col
            >
            <v-col cols="4" class="align-self-center">
              <label class="mt-1">
                <!-- {{ $t("message.relay.relay_name") }} -->
             انتخاب زون
              </label>
            </v-col>
            <v-col cols="7" class="align-self-center">
                <v-select :items="listZone" 
                             v-model="selectZone"
                             label="انتخاب کنید"
                              solo
                              flat
                              background-color="#f5f5f5"
                              hide-details="true"
                              class="text-md-center">
                    </v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center px-3 py-0">
            <v-col cols="1" class="align-self-center">
              <v-icon color="primary">mdi-format-color-text</v-icon>
            </v-col
            >
            <v-col cols="4" class="align-self-center">
              <label class="mt-1">
                <!-- {{ $t("message.relay.relay_name") }} -->
             انتخاب صدا
              </label>
            </v-col>
            <v-col cols="7" class="align-self-center">
                <v-select :items="listSound" 
                             v-model="selectSound"
                             label="انتخاب کنید"
                              solo
                              flat
                              background-color="#f5f5f5"
                              hide-details="true"
                              class="text-md-center">
                    </v-select>
            </v-col>
          </v-row>
         
        </v-card>
      </div>
      <div class="fixed-bottom mb-1 px-4">
     <cmpbutton
           class="mb-1"
           :txtBtn="'ارسال دستور' "
           :loading="loading"
           @clickedbtn="CallBackBtn">
    </cmpbutton>
     </div>
     <my-modal v-show="isModalVisible"></my-modal>
    </v-app>
</template>
<script>
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

import CmpRowCard from "@/components/Cmp/CmpRowCard.vue";
import CmpPasscode from "@/components/Cmp/general/CmpPasscode";
import {CommandAlarm} from "@/../public/assets/js/utils/get_alarm_command.js"
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";

var connection = false;
var showToast;
var timeout;
export default {
  props: {
    home_id:{},
    serial_number: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        connection = true;
        this.loading=false;
        clearTimeout(timeout);
        this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  data() {
    return {
      loading: false,
      isModalVisible: false,
      listSound: [],
      listZone: [],
      zoneSms:'',
      selectSound:'',
      selectZone:'',
      // volumn: 1,
     
    };
  },
  components: {
    cmpbutton,
    CmpPasscode,
    CmpRowCard,
  },
  mounted() {
    let data = {
      title: "پیامک زون",
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ZoneSmsInfo');

    let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.onSubscribe();
  },
  created() {
    for (let i = 1; i <= 9; i++) {
     if(i==9){
        this.listSound.push('MELODIAll')
     }else{
        this.listSound.push('MELODI'+i)
     }
    }
    for (let i = 1; i <= 9; i++) {
     if(i==9){
        this.listZone.push('ZoneAll')
     }else{
        this.listZone.push(i)
     }
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {

    sendReq(password) {
      this.sendRequest(curtain_status, password);
    },
    sendRequest(saction, password) {
      console.log("saction",saction)
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModalLoading();
              // $("#modal-pass-curtain").modal("hide");
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                self.loading=false
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicSettingReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingAlarmInfo",
          params: {home_id:this.home_id,serial_number: this.serial_number},
        });
      }
    },
    CallBackBtn(){
      this.loading=true;
        var MelodiCount=BetweenStr.getBetweenStr(this.selectSound,'MELODI','')[1];
        this.sendRequest(CommandAlarm.getCommandSound(this.selectZone,MelodiCount), '')
    },
    showGetMsg(msg) {
      if(msg!=null){
        this.parseStatus(msg);
      }
    },
    parseStatus(msg) {
     this.selectZone=parseInt(BetweenStr.getBetweenStr(msg,'SZ','-')[1]);
     this.selectSound='MELODI'+parseInt(BetweenStr.getBetweenStr(msg,'-','')[1]);
     
    },
  },
};
</script>
<style scoped>
</style>