<template>
  <v-app class="bg-warning" style="margin-top:-64px !important">
    <v-container class="welcome-bg d-grid p-0 ">
      <div class="d-grid h-full">
        <div class="d-flex justify-content-center weight">
          <img v-if="appMode=='farahoush'"
              src="/assets/images/smart/logo_w.png"
              alt="Farahoush"
              class="align-self-center"
              width="320px"
              style="height: 320px"
          />
          <img v-else
              src="/assets/images/smart/logo_bmsx_w.png"
              alt="Farahoush"
              class="align-self-center"
              width="320px"
              style="height: 320px"
          />
        </div>
        <v-card class="st-col weight2">
          <v-row class="d-flex flex-column">
            <v-col>
              <h3 class="text-bold text-center px-3 mt-3" v-if="appMode=='farahoush'">{{ $t('message.welcome.welcome') }}</h3>
              <h3 class="text-bold text-center px-3 mt-3" v-else>{{ $t('message.welcome.welcome_bmsx') }}</h3>
            </v-col>
          </v-row >
         <div v-if="appMode=='farahoush'">
          <v-row class="d-flex flex-column">
            <v-col class="d-flex flex-column align-items-center p-0">
              <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 50%; outline: none;"
                  v-on:click.prevent="functionWelcome()">
                <span class="text-white">{{ $t('message.public.title') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col class="d-flex flex-column align-items-center p-0">
              <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 50%; outline: none;"
                  v-on:click.prevent="functionLoginEmail()">
                <span class="text-white">{{ $t('message.loginEmail.login_with_email') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4"  v-else>
          <v-row class="d-flex flex-column">
            <v-col class="d-flex flex-column align-items-center p-0">
              <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 50%; outline: none;"
                  v-on:click.prevent="functionLoginEmail()">
                <span class="text-white">{{ $t('message.loginEmail.login_with_email') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
          <!-- <v-row class="d-flex flex-column">
            <v-col class="d-flex flex-column align-items-center p-0">
              <div v-if="appMode=='farahoush'">
              <v-row>
                <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 100%; outline: none;"
                  v-on:click.prevent="functionWelcome()">
                <span class="text-white">{{ $t('message.public.title') }}</span>
              </v-btn>
              </v-row>
              <v-row>
                <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 100%; outline: none;"
                  v-on:click.prevent="functionLoginEmail()">
                <span class="text-white">ورود/ ثبت نام  با ایمیل</span>
              </v-btn>
              </v-row>
            </div>
              <div  v-else>
                <v-btn 
                  color="primary"
                  class="mb-2"
                  style="width: 100%; outline: none;"
                  v-on:click.prevent="functionLoginEmail()">
                <span class="text-white">ورود/ ثبت نام  با ایمیل</span>
              </v-btn>
              </div>
             
            </v-col>
          </v-row> -->
          <v-row class="d-flex flex-column">
          <v-col class="d-flex flex-column align-items-center p-0">
            <v-btn
                  class="mt-5"
                  color="primary"
                  style="width: 50%; outline: none;"
                  outlined
                  v-on:click.prevent="functionConfig()">
                <span class="text-primary">{{ $t('message.public.configuration') }}</span>
              </v-btn>
          </v-col>
          </v-row >
          <v-row class="d-flex flex-column">
            <v-col class="d-flex flex-column pt-3 px-10 align-center bg-white">
              <SwitchLanguage/>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import SwitchLanguage from "./setting/SwitchLanguage.vue"
import LayoutNoAppBar from "../layouts/LayoutNoAppBar";
export default {
  components: {
    SwitchLanguage
  },
  data() {
    return {
      appMode:'farahoush'
    };
  },
  created() {
    this.$emit('update:layout', LayoutNoAppBar)
  },
  mounted() {
    this.exitModalLoading();
    this.appMode=process.env.VUE_APP_MODE
  },
  methods: {
    functionWelcome() {
      this.$router.push({
        name: "login",
      });
    },
    functionLoginEmail() {
      this.$router.push({
        name: "loginEmail",
      });
    },
    functionConfig() {
      this.$router.push({ name: "ModalConfig" });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
}

.weight {
  height: 65% !important;
}

.weight2 {
  margin-top: 12px !important;
  height: 35% !important;
}

.col {
  flex-basis: auto !important;
}

.st-col {
  background-color: white !important;
  border-radius: 20px 20px 0 0 !important;
}
</style>
