<template>
  <v-app class="bg">
    <v-container>
      <v-row>
        <v-col sm="12"
               cols="12"
               :md="size"
               :lg="size"
               :xl="size"
               class="py-0 px-1"
        >
          <v-sheet
              width="100%"
              elevation="0"
              style="border-radius: 10px"
              class="pt-4 px-1 my-2"
          >
            <v-dialog
                ref="menu"
                v-model="menu2"
                :return-value.sync="time1"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row class="mt-3 d-flex align-center justify-space-between" style="width: 100%">
                  <v-col cols="9" class="py-0 d-flex">
                    <v-icon color="#0F6BB9" class="px-1 mb-6">mdi-clock</v-icon>
                    <div class="media-body">
                      <div class="px-2 d-flex" size="12px"><h6 class="text-bold mb-1">
                        {{ $t('message.error_handler.period') }}</h6> <h6 class="px-1 mb-1 text-bold">
                        {{ $t('message.timer.start') }}</h6></div>
                      <p class="px-2">{{ $t('message.timer.default_time') }}</p>
                    </div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field
                        class="text-right"
                        v-model="time1"
                        readonly
                        solo-inverted
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <v-time-picker
                  v-if="menu2"
                  v-model="time1"
                  format="24hr"
                  full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="menu2 = false"
                    style="outline: none"
                >
                  <h6 style="color: #0F6BB9; margin-top: 10px">{{ $t('message.public.cancel') }}</h6>
                </v-btn>
                <v-btn
                    style="background-color: #0F6BB9; outline: none"
                    @click="$refs.menu.save(time1)"
                >
                       <span class="text-white">
                         {{ $t('message.public.OK') }}
                      </span>
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-sheet>
        </v-col>
        <v-col sm="12"
               cols="12"
               md="6"
               lg="6"
               xl="6"
               v-if="end"
               class="py-0 px-1"
        >
          <v-sheet
              width="100%"
              elevation="0"
              style="border-radius: 10px"
              class="pt-4 px-1 my-2"
          >
            <v-dialog
                ref="menu1"
                v-model="menu3"
                :return-value.sync="time2"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row class="mt-3 d-flex align-center justify-space-between" style="width: 100%">
                  <v-col cols="9" class="py-0 d-flex">
                    <v-icon color="#0F6BB9" class="px-1 mb-6">mdi-clock</v-icon>
                    <div class="media-body">
                      <div class="px-2 d-flex" size="12px"><h6 class="text-bold mb-1">
                        {{ $t('message.error_handler.period') }}</h6> <h6 class="px-1 mb-1 text-bold">
                        {{ $t('message.timer.end') }}</h6></div>
                      <p class="px-2">{{ $t('message.timer.end_time') }}</p>
                    </div>
                  </v-col>
                  <v-col cols="3" class="py-0">
                    <v-text-field
                        v-model="time2"
                        class="text-right"
                        readonly
                        solo-inverted
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <v-time-picker
                  v-if="menu3"
                  v-model="time2"
                  full-width
                  format="24hr"
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="menu3 = false"
                    color="primary"
                    style="outline: none"
                >
                  <h6 style="color: #0F6BB9; margin-top: 10px">{{ $t('message.public.cancel') }}</h6>
                </v-btn>
                <v-btn
                    style="background-color: #0F6BB9; outline: none"
                    @click="$refs.menu1.save(time2)"
                >
                       <span class="text-white">
                      {{ $t('message.public.OK') }}
                      </span>
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-sheet>
        </v-col>
        <v-col
            cols="12"
            style="height: 300px;"
            class="py-0 px-1 my-2"
            justify="space-around"
        >
          <v-sheet
              width="100%"
              elevation="0"
              style="border-radius: 10px"
              class="py-4 px-1"
          >
            <v-row>
              <v-col
                  sm="12"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="d-flex pb-0 pt-0"
              >
                <v-icon class="px-1" color="#0F6BB9">mdi-calendar</v-icon>
                <div class="media-body">
                  <h6 class="px-2 mt-4 text-bold"> {{ $t('message.timer.select_day') }} </h6>
                  <p class="px-2 mt-1">
                    {{ $t('message.timer.default_day') }}</p>
                </div>
              </v-col>
              <v-col
                  sm="12"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="d-flex flex-column align-items-center mt-2 pt-1"
              >
                <v-chip-group
                    multiple
                    color="#0F6BB9"
                    active-class="primary"
                    class="d-flex"
                    v-model="days"
                >
                  <v-chip
                      style="width:35px"
                      class="text-center"
                      v-for="day in daysData"
                      :key="day.id"
                      color="#aaaaaa"
                  >
                    <span class="text-white">{{ day.name }}</span>
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col
            cols="12"
            style="height: 300px;"
            class="py-0 px-1 my-2"
            justify="space-around"
        >

        </v-col>
        <v-col class="text-center fixed-bottom" style="z-index: 0" cols="12">
          <v-btn
              color="primary"
              class="btn btn-primary btn-lg text-white btn-block mb-1"
              v-on:click.prevent="myFunction"
          >
            <span class="text-white">{{ $t('message.public.submit') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {Tools} from "../../../../../public/assets/js/utils/util";

export default {
  name: "Step2Timer",
  props: {
    daysData: {},
    end: {},
  },
  data() {
    return {
      lang: '',
      days: [],
      menu2: false,
      menu3: false,
      time1: Tools.getNow(),
      time2: Tools.getNow(),
    }
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  mounted() {
    console.log(this.daysData);
  },
  computed: {
    size() {
      if (this.end)
        return 6;
      return 12;
    }
  },
  methods: {
    myFunction() {
      let result = [];
      for (let day in this.days) {
        result.push(this.daysData[this.days[day]]);
      }
      console.log(result, this.time1, this.time2)
      this.$emit('myFunction', result, this.time1, this.time2)
    }
  }
}
</script>

<style scoped>
</style>