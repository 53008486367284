<template>
  <v-app class="bg">
    <div class="mr-0" >
      <section class="content" v-if="cmpShow">
        <div class="row mt-30 px-20" id="trall" v-if="polall">
          <div class="col-12 py-0">
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 py-0">
                <div
                    class="media align-items-center bg-white mb-20 py-20 rounded">
                  <img
                      src="/assets/images/smart/switch-off.svg"
                      id="imgall"
                      alt="smart"
                      width="32px"
                      height="32px"
                      :style="resultAll.check ? resultAll.onFilter : resultAll.offFilter"
                  />
                  <div class="media-body">
                    <h6>{{ $t('message.public.all') }}</h6>
                  </div>                
                  <v-switch
                      color="primary"
                      inset
                      v-model="resultAll.check"
                      @click="MyFunctionPower(0, resultAll.check)"
                      id="checkall"
                  ></v-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-20">
          <div class="col-lg-12 col-12 py-0">
            <div class="box rounded-50">
              <div class="box-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-12">
                    <div id="trl1"  v-if=pol1 >
                      <div class="media media-single px-0 py-20">
                         <img
                            src="/assets/images/smart/switch-off.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                            :style="result1.check ? result1.onFilter : result1.offFilter"
                            alt="Show"/>

                       <div class="media-body">  
                          <h6>{{ labels[0].label }}</h6>
                        </div>
                        <v-switch
                            :color="dataColor.onColors[0]"
                            v-model="result1.check"
                            @click="MyFunctionPower(1, result1.check)"
                            inset
                            id="checkL1"
                        ></v-switch>
                      </div>
                    </div>
                    <div id="trl2"  v-if="pol2" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <v-img
                              src="/assets/images/smart/switch-off.svg"
                              max-width="32px"
                              max-height="32px"
                              :style="result2.check ? result2.onFilter : result2.offFilter"
                              id="imgl2"
                          >
                          </v-img>
                          <div class="media-body">
                            <h6>{{ labels[1].label }}</h6>
                          </div>
                          <v-switch
                              :color="dataColor.onColors[1]"
                              v-model="result2.check"
                              @click="MyFunctionPower(2, result2.check)"
                              inset
                              id="checkL2"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl3" v-if="pol3" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl3"
                              width="32px"
                              :style="result3.check ? result3.onFilter : result3.offFilter"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[2].label }}</h6>
                          </div>
                          <v-switch
                              :color="dataColor.onColors[2]"
                              v-model="result3.check"
                              @click="MyFunctionPower(3, result3.check)"
                              inset
                              id="checkL3"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl4" v-if="pol4" >
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl4"
                              :style="result4.check ? result4.onFilter : result4.offFilter"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[3].label }}</h6>
                          </div>
                          <v-switch
                              :color="dataColor.onColors[3]"
                              v-model="result4.check"
                              @click="MyFunctionPower(4, result4.check)"
                              inset
                              id="checkL4"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <!-- <ModalResetFactoryKey :infoItem="dataReset"></ModalResetFactoryKey> -->
    <!-- <SubModalDel v-bind:data_info_sub="data_info_keytouch_pro"></SubModalDel> -->
    <!-- <SubModalEdit v-bind:data_info_sub="data_info_keytouch_pro"></SubModalEdit> -->
    <!-- <SubModalSetPass
        v-bind:data_info_sub="data_info_keytouch_pro"
    ></SubModalSetPass> -->
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {ErrorToastMsg, SucessToastMsg} from "@/../public/assets/js/utils/util";
import {getTopics} from "@/../public/assets/js/utils/topics";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {Color, hexToRgb} from "@/../public/assets/js/utils/colorRGB";
import {Solver} from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
// import SubModalDel from "../../subdevice/SubModalDel";
// import SubModalEdit from "../../subdevice/SubModalEdit";
// import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryKey from "../../subdevice/keytouchpro/ModalResetFactoryKey";
import {passHomeValidationInput} from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    // SubModalDel,
    // SubModalEdit,
    // SubModalSetPass,
    CmpPasscode,
    // ModalResetFactoryKey
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      cmpShow:false,
      sub_device_info:[],
      type_Selected_item:"action",
      passkeytouchfara: "",
      data_info_keytouch_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      // pass: true,
      pol: 1,
      labels: [],
      isModalVisible: false,
      subdevice_serial:"",
      keytouch_status: "",
      pass: true,
      data: [],
      dataColor:[],
      infoHome:[],
      infoSubDevice:[],
      info: [],
      rgbInfo:[],
      dataReset:[],
      polall:false,
      pol1:false,
      pol2:false,
      pol3:false,
      pol4:false,
      resultAll: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result1: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result2: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result3: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result4: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      set_password: "",
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showStatus(String(data))
      }
    SucessToastMsg.Msg();
      //  }
    },
    // "+/+/receive/+/setting"(data, topic) {
    //   if (topic.split("/")[1] === this.serial_number) {
    //     connection = true;
    //     clearTimeout(timeout);
    //     this.closeModalLoading();
    //     this.showGetMsg(String(data))
    //   }
    // },
    // "+/+/receive/+/seting"(data, topic) {
    //   if (topic.split("/")[1] === this.serial_number) {
    //     connection = true;
    //     clearTimeout(timeout);
    //     this.closeModalLoading();
    //     this.showGetMsg(String(data))
    //   }
    // },
  },
  created() {
    let self=this;
    this.infoSubDevice =  JSON.parse(localStorage.getItem("infoSubDevice"));
    this.subdevice_serial = this.infoSubDevice.serial_number;
    this.infoHome = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.infoHome.password === 1;
    this.set_password = this.infoSubDevice.set_password;

    },
  mounted: function mounted() {
   
    Internets.Internet;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.pol = this.$store.getters.getPolSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;

    this.showHidePol();
    this.dataReset.push({serial_number:this.serial_number,subdevice_serial:this.subdevice_serial})
    // init run
    // try {
     
      this.lastStatus();
      // this.parserRgbSetting()
      // subscribe
     this.onSubscribe();
   
     
    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      // pin: this.pass,
      // passcode: this.set_password,
      // menu: false,
      sub_setting:true,
      // edit: true,
      // del: true,
      // refresh: true,
      // timer: true,
      // about: true,
      // update: true,
      // editRgb:true,
      // wifi_sub_onoff:true,
      // subReset:true,
     
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'SubKeyTouchPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onSubscribe(){
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );
      // var topic2 = getTopics.getTopicSetingReceive(
      //     this.serial_number,
      //     this.subdevice_serial
      // );
      // var topic3 = getTopics.getTopicSettingReceive(
      //     this.serial_number,
      //     this.subdevice_serial
      // );
      this.$mqtt.subscribe(topic1);
      // this.$mqtt.subscribe(topic2);
      // this.$mqtt.subscribe(topic3);
    },
    MyFunctionPower(index, value) {
      if (this.pol === 1) {
        if (index === 1)
          if (value)
            this.keytouch_status = "K1T#";
          else
            this.keytouch_status = "K1F#";
      } else {
        switch (index) {
          case 0:
            if (value)
              this.keytouch_status = "K5T#";
            else
              this.keytouch_status = "K5F#";
            break;
          case 1:
            if (value)
              this.keytouch_status = "K1T#";
            else
              this.keytouch_status = "K1F#";
            break;
          case 2:
            if (value)
              this.keytouch_status = "K2T#";
            else
              this.keytouch_status = "K2F#";
            break;
          case 3:
            if (value)
              this.keytouch_status = "K3T#";
            else
              this.keytouch_status = "K3F#";
            break;
          case 4:
            if (value)
              this.keytouch_status = "K4T#";
            else
              this.keytouch_status = "K4F#";
            break;
        }
      }
      this.sendMessage(this.keytouch_status);
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      this.openModalLoading();
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.infoSubDevice.serial_number,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      // ------------------Response----------------//
      p.then(
          function (response) {
          
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              // ****** code save data in subdevice
              self.sub_device_info.push({
                  subDevices: jsonArray.content.device_info
              })
              localStorage.setItem("infoSubDevice",JSON.stringify(self.sub_device_info[0].subDevices))
              self.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];

               // ****** code save data in subdevice
              self.showLabel(self.info);
              self.showStatus(message);
              self.parserRgbSetting(); 
              self.cmpShow=true
              self.closeModalLoading();
            } else {
              self.cmpShow=true
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },
    showHidePol(){
     if (this.pol === 1) {
        this.pol1=true
      }
      else if (this.pol === 2) {
        this.pol1=true
        this.pol2=true
        this.polall=true
      }
     else if (this.pol === 3) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.polall=true
      }
      else if (this.pol === 4) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.pol4=true
        this.polall=true
      }
    },
    showLabel(infoSubDevice){
      if (!JSON.parse(infoSubDevice.labels)){
       this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
        pol: "3",
        label: "L4"
      }];}
    else{
      this.labels = JSON.parse(infoSubDevice.labels);
    }
    },
    showStatus(message) {
      if (message === "NOTSET") {
        this.result1.check = false;
        this.result2.check = false;
        this.result3.check = false;
        this.result4.check = false;
        this.resultAll.check = false;
        return;
      }

      let L1_r = message.charAt(0);
      let L2_r = message.charAt(1);
      let L3_r = message.charAt(2);
      let L4_r = message.charAt(3);

      this.result1.check = L1_r === "1";
      this.result2.check = L2_r === "1";
      this.result3.check = L3_r === "1";
      this.result4.check = L4_r === "1";
      this.resultAll.check = message === "1111" || this.pol === 3 && message === "1110" || this.pol === 2 && message === "1100";
    },
    parserRgbSetting(){
      let self=this;
      if(this.info.rgb_setting==null){
        this.info.rgb_setting='L1ON00ff00L1OFFff0000L2ON00ff00L2OFFff0000L3ON00ff00L3OFFff0000L4ON00ff00L4OFFff0000'
      }
      var arrRgb =this.info.rgb_setting.split('L');
      arrRgb.forEach((item, index) => { 
              let str="";
              if(item.match("ON")){
                str = item.substr(0 + 3, 6);
              }
              if(item.match("OFF")){
                str = item.substr(0 + 4, 6);
              }
              self.rgbInfo.push({"id":index,"color":'#'+str})
       })
       this.dataColor = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
       onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color,self.rgbInfo[5].color,self.rgbInfo[7].color],
       offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
    };
     
      },
    onClickChild(value) {
      if(value== "sub_setting"){
        this.$router.push({
          name: "TouchProSetting",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      // if (value == "del") {
      //   $("#modal-del-sub").modal("show");
      // }
      // if (value == "about") {
      //   this.$router.push({
      //     name: "SubAboutKeyPro",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "wifi_sub_onoff") {
      //   this.$router.push({
      //     name: "TouchProStatusWifi",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "subReset") {
      //   // this.keytouch_status="RESET#"
      //   $("#modal-reset-touchPro").modal('show');
      // }
      // if (value == "update") {
      //   this.showUpdateDialog();
      // }
      // if (value == "editRgb") {
      //   this.$router.push({
      //     name: "EditRGBPro",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "edit") {
      //   let data = {
      //     labels: this.labels,
      //     pol: this.pol,
      //     type: "PRO",
      //     type_code:this.info.type.type_code,
      //   }
      //   localStorage.setItem("data_edit_sub", JSON.stringify(data));
      //   this.$router.push({
      //     name: "SubKeyTouchEdit",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "pass") {
      //   $("#modal-set-pass-sub").modal("show");
      // }
      // if (value == "refresh") {
      //   this.lastStatus();
      //   // location.reload();
      // }
      // if (value == "timer") {
      //   let data = {
      //     labels: this.labels,
      //     pol: this.pol,
      //     type: "PRO",
      //     type_code:this.info.type.type_code,
      //   }
      //   localStorage.setItem("data_edit_sub", JSON.stringify(data));
      //   this.$router.push({
      //     name: "TouchProTimerShow",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {
     
      this.keytouch_status=status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.keytouch_status, '');
      }
    },
    sendReq(password) {
      console.log("this.keytouch_status",this.keytouch_status)
      // if(this.keytouch_status=="RESET#"){
      // this.sendRequest(this.keytouch_status, password);
      // }
      this.sendRequest(this.keytouch_status, password);
    },
    sendRequest(message, password) {

      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final="";

    
      this.type_Selected_item=='update'?
      topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial):
      topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
             if (this.set_password == 1) {
              this.keytouch_status="UPDATE#"
               $("#modal-pass-code").modal("show");
             } else {
              this.type_Selected_item="update"
              this.sendRequest('UPDATE#','');
            }  
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      if(msg=="UPDATEOK"){
        swal({
        // title: self.$t('message.timer.timer'),
        title:this.$t("message.public.refresh"),
        text: this.$t("message.public.update_done"),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      }
    },
  },
}
</script>

<style scoped>

</style>