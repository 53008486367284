<template>
  <v-app class="bg">
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="mt-6 mr-0">
      <!-- Main content -->
      <section class="content bg-white">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-addsub.svg"
                            class="avatar-smart"
                        />
                        <h5 class="font-weight-bold">
                          {{ $t('message.ModalAddSubDevicePol.title') }}
                        </h5>
                      </div>
                    </div>
                    <br/>
                    <form class="px-40">
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddSubDevicePol.name') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            id="inputsubfarahoush"
                            type="text"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            maxlength="32"
                            :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                            v-model="namesubdevice"
                        />
                      </div>
                      <div class="form-group" v-if="farahoush">
                        <h6>
                          {{ $t('message.ModalAddSubDeviceFaraPol.serial') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            id="inputserial"
                            type="number"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            :placeholder="$t('message.ModalAddSubDeviceFaraPol.hintSerial')"
                            v-model="serailsubfara"
                        />
                      </div>
                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">
                          <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="add()"
                          >
                            <span class="text-white"> {{ $t('message.public.submit') }} </span>
                          </v-btn>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->

      <div
          class="modal center-modal fade"
          id="modal-center"
          tabindex="-1"
          data-keyboard="false"
          data-backdrop="static"
      >
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
            </div>
            <div class="modal-body">
              <div class="spinner-border textprimary" role="status">
                <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
              </div>
              <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- /.modal Add Home-->
  </v-app>
</template>

<script>
// import ModalCenter from "./ModalCenter";
// import { Internets } from "../../public/assets/js/utils/util.js";
import {SucessToastAdd} from "../../../public/assets/js/utils/util.js";
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput, serialDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    // ModalCenter,
  },
  props: {
    home_id: {},
    serial_number: {},
    farahoush: {},
    type_code: {},
    click: {},
    nameDevice: {}
  },
  data() {
    return {
      // info: null,
      namesubdevice: "",
      serailsubfara: "",
      loading: false,
    };
  },

  created() {
    let data = {
      title: this.nameDevice
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ModalAddSubDeviceFara')
  },
  mounted() {
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "homeAddDevice",
          params: {home_id: this.home_id},
        });
      }
    },
    add() {
      if (this.farahoush)
        this.addSubdeviceFarahoush();
      else
        this.addSubdevice();
    },
    addSubdeviceFarahoush() {
      let self = this;
      let name = this.namesubdevice;
      let serial = this.serailsubfara;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let tapp = localStorage.getItem("t_app");

      if (
          !nameDeviceValidationInput(name) ||
          !serialDeviceValidationInput(serial)
      )
        return;
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        home_id: this.home_id,
        type_code: this.type_code,
        serial_number: serial,
      };
      //-----------------------------Request ---------------------------//
      let URL = getUrl.subAdd;
      const p = postAjaxCall(URL, data, "");
      // $("#modal-center").modal("show");
      //------------------------------Response ---------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              SucessToastAdd.MsgAdd(self.$t('message.ModalAddSubDevice.register'));
              setTimeout(function () {
                self.$router.push({
                  name: "homeDevices",
                  params: {
                    home_id: self.home_id,
                    serial_number: self.serial_number
                  }
                });
              }, 50);
            } else {
              //error
              self.loading = false
            }
          },
          //------------------------------Err ---------------------------//
          function (errr) {
          }
      );
    },
    addSubdevice() {
      let self = this;
      var name = this.namesubdevice;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var tapp = localStorage.getItem("t_app");
      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        home_id: this.home_id,
        type_code: this.type_code,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.subAdd;
      const p = postAjaxCall(URL, data);
      // setTimeout(function(){   $("#modal-center").modal('show');  }, 50);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              self.namesubdevice = "";
              SucessToastAdd.MsgAdd(self.$t('message.ModalAddSubDevice.register'));
              setTimeout(function () {
                self.$router.push({
                  name: "homeDevices",
                  params: {
                    home_id: self.home_id,
                    serial_number: self.serial_number
                  }
                });
              }, 50);
            } else {
              //error
              self.loading = false
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
  },
};
</script>


<style scoped>
</style>
