<template>
  <v-app class="bg">
    <div class="px-4 mb-10">
      <label class="mt-3" v-if="controls.length !== 0">{{ $t('message.Rooms.controls') }}</label>
      <div :key="index + 'A'" v-for="(control, index) in controls">
        <CmpRowList
            :infoItem="control"
            :menuShow="false"
            :subTitleShow="true"
            actions="checkBox"
        ></CmpRowList>
      </div>
      <label class="mt-3" v-if="subDevices.length !== 0">{{ $t('message.Rooms.sub_devices') }}</label>
      <div :key="index" v-for="(subDevice, index) in subDevices">
        <CmpRowList
            :infoItem="subDevice"
            :menuShow="false"
            :subTitleShow="true"
            actions="checkBox"
        ></CmpRowList>
      </div>
      <label class="mt-3" v-if="relays.length !== 0">{{ $t('message.relay.relays') }}</label>
      <div :key="'A' + index" v-for="(relay, index) in relays" class="mb-3">
        <CmpRowList
            :infoItem="relay"
            :menuShow="false"
            :subTitleShow="true"
            actions="checkBox"
        ></CmpRowList>
      </div>
    </div>
    <div class="row rounded-16 px-4 mb-1 fixed-bottom">
      <div class="col-12 col-lg-12 px-2">
        <v-btn
            class="btn btn-info btn-block"
            color="bg-primary"
            :loading="loading"
            :disabled="loading"
            @click="submit"
        >
          <span class="text-white">{{ $t("message.Rooms.save_device") }}</span>
        </v-btn>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import LayoutDefault from "../../layouts/LayoutDefault";
import CmpRowList from "../Cmp/CmpRowList";
import {getTypeRelay8} from "../../../public/assets/js/utils/relay8_type_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  name: "RoomDevices",
  components: {CmpRowList},
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      ServerInfoSub: [],
      ServerInfoControls: [],
      subdevice_serial: "",
      isModalVisible: false,
      subDevices: [],
      controls: [],
      relays: [],
      devices: {},
      subtitleControl: "",
      subtitleSub: "",
      loading: false,
      lang: '',
    };
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.lastStatus()
    let details = {
      title: this.$t('message.ListDevice.list_device'),
      refresh:true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'roomDevices');
  },
  watch: {
    click(val) {
      if (val === 'back')
        this.back()
    }
  },
  methods: {
    lastStatus() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.roomsDevices;

      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.closeModalLoading();
              self.ServerInfoSub = jsonArray.content.subDevices;
              self.ServerInfoControls = jsonArray.content.controls;
              let list_relays = jsonArray.content.relays;
              // console.log("relays",list_relays)
              if (list_relays.length > 0)
                // relays = relays[0]
                for (let i = 0; i < list_relays.length; i++) {
                  for (let j = 0; j < list_relays[i].length; j++) {
                     self.relays.push({
                      uid:list_relays[i][j].id_number,
                      title: list_relays[i][j].name,
                      img: list_relays[i][j].type.logo_path,
                      subTitle: getTypeRelay8(list_relays[i][j].type.name),
                      icon: false,
                      active: false,
                     })
              }
                }  
              self.ServerInfoControls.forEach(function (item) {
                if (item.new_control.category.type_code == "1001")
                  self.subtitleControl = self.$t("message.irTv.tv_control");
                if (item.new_control.category.type_code == "1002")
                  self.subtitleControl = self.$t("message.irAmplifier.amplifier_control");
                if (item.new_control.category.type_code == "1003")
                  self.subtitleControl = self.$t("message.irAir.air_control");
                self.controls.push({
                  uid: item.uid,
                  title: item.name,
                  img: item.new_control.category.logo_path,
                  subTitle: self.subtitleControl,
                  icon: false,
                  active: false,
                });
              });

              self.ServerInfoSub.forEach(function (item) {
                self.subDevices.push({
                  sn: item.serial_number,
                  title: item.name,
                  img: item.type.logo_path,
                  subTitle: self.lang === 'en' || self.lang === 'ar' ? item.type.label.en :
                      item.type.label.fa,
                  icon: false,
                  active: false,
                });
              });
              self.checkActive()
            } else {

              //error
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    checkActive() {
      this.devices = localStorage.getItem('roomDevices') ? JSON.parse(localStorage.getItem('roomDevices')) : {
        controls: [],
        subDevices: [],
        relays: []
      }
      this.controls = this.controls.filter((selected) => {
        let check = true
        this.devices.controls.forEach((item) => {
          if (item.uid === selected.uid) {
            check = false
          }
        })
        return check
      })
      this.subDevices = this.subDevices.filter((selected) => {
        let check = true
        this.devices.subDevices.forEach((item) => {
          if (item.sn === selected.sn) {
            check = false
          }
        })
        return check
      })
      this.relays = this.relays.filter((selected) => {
        let check = true
        this.devices.relays.forEach((item) => {
          if (item.uid === selected.uid) {
            check = false
          }
        })
        return check
      })
    },
    submit() {
      let controls = this.controls.filter((item) => item.active)
      let subDevices = this.subDevices.filter((item) => item.active)
      let relays = this.relays.filter((item) => item.active)
      console.log(controls.length, subDevices.length, relays.length)
      if (relays.length > 0)
        this.devices.relays = this.devices.relays.concat(relays)
      if (subDevices.length > 0)
        this.devices.subDevices = this.devices.subDevices.concat(subDevices)
      if (controls.length > 0)
        this.devices.controls = this.devices.controls.concat(controls)
      localStorage.setItem("roomDevices", JSON.stringify(this.devices))
      this.back()
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 12000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    back() {
      this.$router.push({
        name: "AddRoom",
        params: {
          home_id: this.home_id, serial_number: this.serial_number
        }
      })
    }
  }
}
</script>

<style scoped>

</style>