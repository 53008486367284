<template>
  <div
    class="modal modal-fill fade bg"
    data-backdrop="false"
    id="modal-outlet-momentray"
    tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar :title="title" @clicked="onClickChild"></AppBar>
      <div class="content-wrapper mr-0 bg-white">
        <div class="box-body chart-responsive">
          <div style="height: 812px">
            <section class="content">
              <div class="row">
                <div class="col-12 col-lg-12 p-0">
                  <div class="box">
                    <div class="box-body p-0">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="bg-white">
                          <div style="text-align: center; margin-top: 10px">
                            <div class="fontawesome-icon-list m-0">
                              <img
                                src="/assets/images/smart/time-delay.svg"
                                class="avatar-smart"
                              />
                              <h6 class="font-weight-bold">
                                {{ $t("message.ModalOutletMomentray.range") }}
                              </h6>
                            </div>
                          </div>
                          <br />
                          <form v-if="type === 'WC'">
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                  <label for="start">
                                    {{ $t("message.touch_key.fan_moment") }}
                                  </label>
                                  <input
                                    id="start"
                                    type="number"
                                    class="form-control"
                                    v-model="delay"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                  <label for="end">
                                    {{ $t("message.touch_key.spray_moment") }}
                                  </label>
                                  <input
                                    id="end"
                                    type="number"
                                    class="form-control"
                                    v-model="delay2"
                                  />
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-12">
                                <div class="media media-single p-0 mb-5">
                                  <div class="media-body d-flex flex-column">
                                    <h6>{{ $t("message.irAir.auto") }}</h6>
                                  </div>
                                  <label class="switch">
                                    <input
                                      name="checkL"
                                      id="checkL"
                                      type="checkbox"
                                    />
                                    <span class="switch-indicator"></span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <v-btn
                              class="btn btn-info btn-block mt-10"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="SetDelays()"
                            >
                              <span class="text-white">
                                {{ $t("message.public.submit") }}
                              </span>
                            </v-btn>
                          </form>
                          <form class="px-40" v-else-if="type === 'elevator'">
                            <div class="form-group">
                              <!-- <h6>نام دستگاه <span class="text-danger">*</span></h6> -->
                              <input
                                type="number"
                                class="form-control text-center"
                                required=""
                                aria-invalid="false"
                                maxlength="32"
                                autocomplete="off"
                                v-model="delay_elvator"
                                id="outletdelay"
                                :placeholder="
                                  $t('message.ModalOutletMomentray.value')
                                "
                                value="01"
                              />
                            </div>

                            <div class="row">
                              <div class="col-12 text-center">
                                <v-btn
                                  class="btn btn-info btn-block mt-10"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="SetDelayEl()"
                                >
                                  <span class="text-white">
                                    {{ $t("message.public.submit") }}
                                  </span>
                                </v-btn>
                              </div>
                            </div>
                          </form>
                          <form
                            class="px-40"
                            v-else-if="type === 'doorShutter'"
                          >
                            <div class="form-group">
                              <label for="longopen" class="text-gray">
                               {{ $t("message.ModalOutletMomentray.open") }}
                              </label>
                              <div class="input-group input-shutter">
                                <div
                                  class="input-group-prepend"
                                  style="height: 40px !important"> 
                                </div>
                                <input
                                  style="height: 40px !important"
                                  type="number"
                                  aria-invalid="false"
                                  maxlength="32"
                                  autocomplete="off"
                                  v-model="opentime"
                                  id="longopen"
                                  :placeholder="
                                    $t('message.ModalOutletMomentray.value')"
                                  value="1"
                                  ref="groupId"
                                  class="form-control"/>
                                <div class="input-group-prepend">
                                  <span class="input-group-text"> {{ $t("message.ModalOutletMomentray.time") }}</span>
                                </div>
                              </div>
                            </div>
                           <div class="form-group">
                              <label for="longclose" class="text-gray">
                                {{ $t("message.ModalOutletMomentray.close") }}
                              </label>
                              <div class="input-group input-shutter">
                                <div
                                  class="input-group-prepend"
                                  style="height: 40px !important">
                                </div>
                                <input
                                  style="height: 40px !important"
                                  type="number"
                                  aria-invalid="false"
                                  maxlength="32"
                                  autocomplete="off"
                                  v-model="closetime"
                                  id="longclose"
                                  :placeholder="
                                    $t('message.ModalOutletMomentray.value')
                                  "
                                  value="1"
                                  ref="groupId"
                                  class="form-control"
                                />
                                <div class="input-group-prepend">
                                  <span class="input-group-text"> {{ $t("message.ModalOutletMomentray.time") }}</span>
                                </div>
                              </div>
                            </div>
                            <v-btn
                                  class="btn btn-info btn-block mt-10"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="setLongOpen()"
                                >
                                  <span class="text-white">
                                    {{ $t("message.public.submit") }}
                                  </span>
                                </v-btn>

                          </form>

                          <form class="px-40" v-else>
                            <div class="form-group">
                              <input
                                type="number"
                                class="form-control text-center"
                                required=""
                                aria-invalid="false"
                                maxlength="32"
                                autocomplete="off"
                                v-model="delay"
                                id="outletdelay"
                                :placeholder="
                                  $t('message.ModalOutletMomentray.value')
                                "
                                value="01"
                              />
                            </div>

                            <div class="row">
                              <div class="col-12 text-center">
                                <v-btn
                                  class="btn btn-info btn-block mt-10"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="SetDelay()"
                                >
                                  <span class="text-white">
                                    {{ $t("message.public.submit") }}
                                  </span>
                                </v-btn>
                              </div>
                            </div>
                          </form>
                          <div class="text-center" style="height: 50px"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div
          class="modal center-modal fade"
          id="modal-center"
          tabindex="-1"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">
                  {{ $t("message.ModalCenter.loading") }}
                </h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{
                    $t("message.ModalCenter.loading")
                  }}</span>
                </div>
                <span class="pl-20" id="txtwait">{{
                  $t("message.ModalCenter.wait")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { errOutletDelay } from "../../../public/assets/js/utils/error_handler";
import {
  BetweenStr,
  SucessToast,
  SucessToastMsg,
} from "../../../public/assets/js/utils/util";
import { getTopics } from "../../../public/assets/js/utils/topics";
import { postAjaxCall } from "../../../public/assets/js/postAjax";
import { responseAjax } from "../../../public/assets/js/utils/response_ajax_handler";
import AppBar from "../AppBar";

export default {
  components: {
    AppBar,
  },
  props: {
    serials: { type: String },
    type: String,
    serial_number: {},
    subdevice_serial: {},
    delay_elvator: {
      type: String,
      default: "01",
    },
    opentime: {
      type: String,
      default: "20",
    },
    closetime: {
      type: String,
      default: "20",
    },
    stateShutterkey:{}
  },
  data() {
    return {
      delay: "01",
      delay2: "01",
      auto: "",
      title: "",
      loading: false,
      // opentime:'',
      closetime:''
    };
  },
  mqtt: {

  },
  beforeCreate() {
    let data = {
      title:  this.$t("message.public.setting") + ' '.repeat(10) + this.$t("message.AppBar.instantSettings"),
    };
    localStorage.setItem("AppBar", JSON.stringify(data));
  },
  created() {
    if (this.type === "WC") {
      let delay = localStorage.getItem("delay")
        ? JSON.parse(localStorage.getItem("delay"))
        : {};
      this.delay = delay.fan;
      this.delay2 = delay.spray;
      this.auto = delay.auto;
    } else if (this.type === "elevator") {
    }
  },
  mounted() {
    this.subdevice_serial = JSON.parse(
      localStorage.getItem("infoSubDevice")
    ).serial_number;
    this.title = this.$t("message.AppBar.instantSettings");
    if (this.type === "outlet") {
      this.delay = localStorage.getItem("relay_delay" + this.serials)
        ? localStorage.getItem("relay_delay" + this.serials)
        : "01";
    } else if (this.type === "elevator") {

    } else if (this.type === "WC") {
      this.setStatus();
    }else if (this.type === "doorShutter") {
    
    }

// this.opentime =  localStorage.getItem("openTimeShutter");
// this.closetime =  localStorage.getItem("closeTimeShutter");


// if(this.opentime === null){
//   this.opentime = this.opentime
// }
  },
  methods: {
    setStatus() {
      let checkbox1 = document.getElementById("checkL");
      checkbox1.checked = this.auto === 1;
    },

    SetDelayEl() {
      this.loading = true;
      let self = this;
      setTimeout(function () {
        self.loading = false;
      }, 2000);
      this.$emit("set_delayEl", this.delay_elvator);
    },
    setLongOpen() {
      this.loading = true;
      let self = this;
      setTimeout(function () {
        self.loading = false;
      }, 2000);
       if (!errOutletDelay(this.opentime) || !errOutletDelay(this.closetime)) return;
      this.$emit("set_time_shutter", this.opentime ,1,this.closetime ,3 );
    },
  
    SetDelays() {
      let checkbox1 = document.getElementById("checkL");
      if (!errOutletDelay(this.delay) || !errOutletDelay(this.delay2)) return;
      this.$emit("set_delay", {
        fan: this.delay,
        spray: this.delay2,
        auto: checkbox1.checked ? 1 : 0,
      });
      $("#modal-outlet-momentray").modal("hide");
    },
    onClickChild(value) {
      if (value == "back") {
        $("#modal-outlet-momentray").modal("hide");
      }
    },
    
    showGetMsgElevatorSetting(msg) {
      var TimeRes = BetweenStr.getBetweenStr(msg, "K1:", "s#");
      this.delay = TimeRes[1];
    },
  
  },
};
</script>


<style scoped>
.input-shutter {
  /* margin-top: 50px; */
  margin-bottom: 50px;
}
</style>
