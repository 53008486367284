<template>
      <div>
    <v-container class="py-4">
      <v-row class="px-0 py-0">
        <v-col cols="2"  align-self="center">
              <slot name="image"></slot>
           </v-col>
        <v-col cols="7" align-self="center" class="px-0">
          <slot name="label"></slot>
        </v-col>
        <v-col cols="3" class="text-left" align-self="center">
        <slot name="btn"></slot>
        </v-col>
      </v-row>
         </v-container>
      </div>
</template>
<script>
export default {
  data() {
    return {
      switch1: true,
      nameKey: "L1",
      status: {
        id: "",
        check: "",
      },
      finalval: false,
      todos:[],
    };
  },

  props: ["info"],
  
  created() {
  
    if(this.info.active==true){
    this.testval=true
    }else{
    this.testval=false
    }
  },
  methods: {
    switchClick(value) {
      this.finalval = value;
      this.status.id = this.id;
      this.status.check = value;
      
    },
    check: function(e) {
    if(this.value==false) this.value==0;
    if(this.value==true) this.value==1;


		if(this.checked==true){
         this.$emit("clicked",'OK'+this.value);
      
    }
		if(this.checked==false){
         this.$emit("clicked",'DEL'+this.value);
      
    }
    
    }
  },
};
</script>
<style scoped>
.galimage[src='null'] {
  display:none;
}
</style>
