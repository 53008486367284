<template>
  <!-- .wrapper  -->
  <div
    class="modal modal-fill fade"
    data-backdrop="false"
    id="modal-reset-factory"
    tabindex="-1"
  >
    <div class="wrapper m-0">
      <section class="error-page bg-img mt-50 rtl">
        <div class="container px-40">
          <div
            class="row align-items-center justify-content-center text-center"
          >
            <div class="col-lg-7 col-md-10 col-12">
              <h1
                class="
                  text-warning
                  font-size-180 font-weight-bold
                  error-page-title
                "
              ></h1>
              <img
                src="/assets/images/smart/vector-reset-factory.svg"
                class="avatar-smart"
              />
              <br />
              <h4 class="text-danger mt-10"><strong>{{$t('message.public.warning')}}</strong></h4>
              <br />
              <h6 style="line-height: 25px !important">
                {{$t('message.noneHome.reset_txt')}}
              </h6>
              <br />
              <v-btn
                class="btn mt-5"
                :loading="loading"
                :disabled="loading"
                color="bg-danger"
                @click="DelFunction()"
              >
                <span class="text-white"> {{$t('message.public.submit')}} </span>
              </v-btn>
              <button
                type="button"
                class="btn btn-dark mb-5 mt-10 m-5"
                v-on:click.prevent="CancelFunction()"
              >
                {{$t('message.public.cancel')}}
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
      <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="txtloading">{{$t('message.ModalCenter.loading')}}</h5>
            </div>
            <div class="modal-body">
              <div class="spinner-border textprimary" role="status">
                <span class="sr-only">{{$t('message.ModalCenter.loading')}}</span>
              </div>
              <span class="pl-20" id="txtwait">{{$t('message.ModalCenter.wait')}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- /.Content Wrapper. Contains page content -->
    </div>
    <!-- /.modal Add Home-->
  </div>
  <!-- ./wrapper -->
</template>

 <script>
//  import ModalCenter from './ModalCenter';
import AppBar from "@/components/AppBar.vue";
import { getTopics } from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
var connection = false;
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    AppBar,
  },
  data: function () {
    return {
      title: "",
      loading: false,
    };
  },
  props: {
    serialnumbers: { type: String },
  },
  mqtt: {
    "+/+/receive/256/reset"(data, topic) {
      if (topic.split("/")[1] === this.serialnumbers) {
        this.checkMqtt(String(data));
        connection = true;
      }
    },
  },

  mounted() {
    this.title= this.$t('message.noneHome.reset')
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        $("#modal-reset-factory").modal("hide");
      }
    },
    CancelFunction() {
      $("#modal-reset-factory").modal("hide");
    },
    DelFunction() {
      let self = this;
      this.loading = true;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let saction = "RESET#";
      var topic_final = getTopics.getTopicResetSend(this.serialnumbers, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          self.MqttSend();
          setTimeout(function () {
            if (!connection) {
              swal("", self.$t('message.public.operation_failure'), "error");
              self.loading = false;

            }
          }, 10000);
        },
        function (errr) {
          loading.style.visibility = "hidden";
          err.innerHTML = JSON.parse(errr).message;
        }
      );
    },
    MqttSend() {
      var topic = getTopics.getTopicResetReceive(this.serialnumbers, "256");
      this.$mqtt.subscribe(topic);
    },
    checkMqtt(msg) {
      let self = this;

      let device_serial = this.serialnumbers;
      if (msg == "RESET") {
        localStorage.setItem("relay_delay" + device_serial, "");
        swal({
          title: this.$t('message.irAir.settings'),
          text: this.$t('message.noneHome.r_txt'),
          type: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(function () {
          self.loading = false;
          self.$router.push({ name: "mainpage" });
        }, 2005);
      }
    },
  },
};
</script>



<style scoped>
</style>
