<template>
  <v-app class="bg">
    <div>
      <v-container>
        <div v-if="emptyShow" class="mt-50">
          <CmpEmptyData
              :txtCaption="txtCaptionSenarioEmpty"
              :btnShow="true"
              :txtBtn="$t('message.scenario.add.add_scenario')"
              @clicked="clickAddScenario"
          ></CmpEmptyData>
        </div>
        <div :key="index" v-for="(senario, index) in scenarios">
          <v-row class="d-flex align-items-center px-2">
            <v-col cols="10" class="align-items-center p-0">
              <CmpRowList
                  :infoItem="senario"
                  :subTitleShow="true"
                  :items="itemsMenu"
                  @clicked="clickItemMenu"
                  :ripple="false"
              ></CmpRowList>
            </v-col>
            <v-col
                cols="2"
                class="mt-2 px-2 "
                @click="runScenario(senario.index)">
              <v-card height="85px" class="d-flex justify-center">
                <v-icon large color="#0F6BB9" v-if="lang === 'fa' || lang === 'ar'">mdi-play mdi-flip-h</v-icon>
                <v-icon large color="#0F6BB9" v-if="lang ==='en' || lang ==='tur'">mdi-play</v-icon>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <my-modal v-show="isModalVisible"></my-modal>
        <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
      </v-container>
    </div>
  </v-app>
</template>
<script>
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import DialogImg from "@/components/Cmp/DialogImg.vue";
import CmpEmptyData from "../Cmp/public/CmpEmptyData.vue";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {ToastCustom} from "../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax.js";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler.js";
import {SucessToastMsg, ErrorToastMsg, BetweenStr} from "../../../public/assets/js/utils/util.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../Cmp/general/CmpPasscode"
import LayoutDefault from "../../layouts/LayoutDefault";

var showToast;
var connection;

export default {
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  components: {
    CmpRowList,
    CmpEmptyData,
    DialogImg,
    CmpPasscode,
  },
  mqtt: {
    "+/+/receive/+/scenario"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsg(String(data));
      }
    },
  },
  data() {
    return {
      scenarios: [],
      senarioServerInfo: [],
      isModalVisible: false,
      txtCaptionSenarioEmpty: "",
      message: '',
      topic: '',
      txtCaptionSenario: '',
      itemsMenu: [],
      emptyShow: false,
      lang: '',
      localScenariodata: {
        name: "",
        logo: "",
        actions: [],
      },
    };
  },
  created() {
    let self = this;
    this.txtCaptionSenarioEmpty = this.$t(
        "message.scenario.empty_caption_scenario"
    );
    this.txtCaptionSenario = this.$t(
        "message.scenario.added_status"
    );
    var item1 = this.$t("message.public.edit");
    var item2 = this.$t("message.public.delete");
    this.itemsMenu.push({title: item1, icon: 'mdi-border-color'}, {title: item2, icon: 'mdi-delete'});
    this.lang = localStorage.getItem("Lang");

    setTimeout(function () {
      self.getScenaroList();
    }, 100);
  },
  mounted() {
    let details = {
      title: this.$t('message.scenario.scenarios'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'scenarioList');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    getScenaroList() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      self.scenarios = [];
      self.senarioServerInfo = [];
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      this.openModalLoading();
      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarios;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              let item;
              for (item in jsonArray.content.scenarios) {
                self.senarioServerInfo.push(jsonArray.content.scenarios[item].scenario);
                self.senarioServerInfo[item].set_password = jsonArray.content.scenarios[item].set_passcode;
              }
              self.closeModalLoading();
              if (self.senarioServerInfo.length == 0) {
                self.emptyShow = true;
              }
              self.senarioServerInfo.forEach(function (item, i) {
                var nextTodoId = -1;
                self.scenarios.push({
                  index: i + nextTodoId + 1,
                  title: item.name,
                  img: item.logo_path,
                  subTitle: JSON.parse(item.actions).length + Array(1).fill('\xa0').join('') + self.txtCaptionSenario,
                  icon: true,
                  set_password: item.set_password,
                  actions: 'menu'
                });
              });
            } else {
              //error
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "add") {
        localStorage.setItem("flagEditScenario", "Add");
        localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));
        this.$router.replace({
          name: "ScenarioAddList",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
      if (value == "refresh") {
        this.getScenaroList();
      }
    },
    clickItemMenu(value) {
      switch (value.itemId) {
        case 0:
          localStorage.setItem("flagEditScenario", "Edit");
          this.editScenario(value.index);
          break;
        case 1:
          let self = this;
          this.showDelDialog(self.senarioServerInfo[value.index].uid);
          break;
      }
    },
    sendReq(password) {
      this.sendMessage(this.message, this.topic, password);
    },
    delScenario(uid) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        scenario_uid: uid,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarioDel;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.scenarios = [];
                self.getScenaroList();
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    editScenario(index) {
      localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));
      this.localScenariodata.actions = JSON.parse(this.senarioServerInfo[index].actions);
      this.localScenariodata.actions.forEach(function (item, i) {     
              });

      localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));
      localStorage.setItem("infoIrScenario", JSON.stringify(this.senarioServerInfo[index])
      );
      this.$router.push({
        name: "ScenarioEditList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickAddScenario() {
      localStorage.setItem("flagEditScenario", "Add");
      localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));
      this.$router.push({
        name: "ScenarioAddList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    runScenario(index) {
      this.topic = getTopics.getTopicScenarioSend(
          this.serial_number,
          "256"
      );
      var count = this.senarioServerInfo[index].count;
      var msg = this.senarioServerInfo[index].msg;
      this.message = "SCENARIO" + count + msg + "#";
      if (this.senarioServerInfo[index].set_password == "1") {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.message, this.topic, '');
      }
    },
    sendMessage(message, topic, password) {
      let self = this;
      this.onSubScribe();
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      showToast = 1;
      self.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          var jsonArray = JSON.parse(response.responseText);

          setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
                self.closeModalLoading();
              }
              setTimeout(function () {
              }, 3000);
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          self.lastStatusMqtt(token, user_id);
          if (response.status == 401) self.$router.push({name: "home"});
          //error
        }
      });
    },
    showGetMsg(msg) {
      this.closeModalLoading();
      if (showToast == 1) {
        var running = msg.includes("RUNNING");
        if (running) {
          ToastCustom.custom(this.$t("message.scenario.toast_success_scenario"), "#28a745", "#fff");
          // connection = false;
        }
      }
    },
    onSubScribe() {
      var topic1 = getTopics.getTopicScenarioReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    showDelDialog(uid) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.scenario.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.delScenario(uid);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
  },
};
</script>

