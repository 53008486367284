<template>
  <v-row justify="center" class="px-10">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <DialogGeneral>
          <template v-slot:title>
            <h5 class="text-bold">
              {{dialogtxt[0].header}}
          </h5>
           </template>
           <template v-slot:caption>
            <label class="mt-1 justify-center" style="font-size: 14px">
              {{dialogtxt[0].caption}} 
              </label>
           </template>
           <template v-slot:body>
            <v-row>
              <v-col cols="12 py-1" >
                <label class="text-primary">
                  {{dialogtxt[0].input1_title}}
                </label>
                <v-text-field
                  type="number"
                  background-color="#f5f5f5"
                  :placeholder=dialogtxt[0].input1_hint
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="serial_number_guest"
                  maxlength="20"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 py-0">  
            <label class="text-primary"> {{dialogtxt[0].input2_title}} </label>
            <v-select
                  v-model="selectd_host_pol"
                  :items="listpolhost"
                  :label=dialogtxt[0].input2_hint
                  background-color="#f5f5f5"
                  required
                  solo
                  hide-details="true"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 py-0">  
            <label class="text-primary"> {{dialogtxt[0].input3_title}} </label>
            <v-select
                  v-model="selectd_guest_pol"
                  :items="listpolguest"
                  :label=dialogtxt[0].input3_hint
                  background-color="#f5f5f5"
                  required
                  solo
                  hide-details="true"
                ></v-select>
              </v-col>
            </v-row>
           </template>
           <template v-slot:alert>
             <v-alert color="red" type="error" class="mt-2 mx-auto" dense outlined v-if="showError">
                <span class="text-danger">{{ textError }}</span>
              </v-alert>
           </template>
           <template v-slot:btn>
            <v-btn color="text-secondray" text @click="show = false">
             <label class="text-blue-gray"> {{ $t("message.public.cancel") }}</label>
          </v-btn>
          <v-btn color="bg-primary" @click="Save()">
            <label class="text-white mt-1">
              {{ $t("message.public.submit") }}</label
            >
          </v-btn>
           </template>
         </DialogGeneral> 
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import CmpTips from "../Cmp/public/CmpTips.vue";
import DialogGeneral from "@/components/Cmp/DialogGeneral.vue";

export default {
  data() {
    return {
      items: [],
      selectd_guest_pol:'',
      selectd_host_pol:'',
      serial_number_guest:'',
      listpolhost: ["1", "2", "3", "4" ,"5"],
      listpolguest: ["1", "2", "3", "4","5"],
      showError: false,
      textError: "",
      infoDialog:[],
      errName: false,
    };
  },
  components: {
    CmpTips,
    DialogGeneral
  },
  props: {
    value: Boolean,
    data: {},
    dialogtxt: {},
  },
  watch: {
    show(val) {
      if (val) {
        this.emptyData();
        this.errName=false
      }
    },
    items(newItem, oldName) {
      if(newItem.length!=0){
        this.showError=false;
      }
     
    },
    serial_number_guest: function(val) {
            var self = this;
            self.showError = false;
            
        }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.errName = false;
  },
  methods: {
    Save() {
      this.validSelect();
    },

    validSelect() {
        if (this.serial_number_guest==0) {
          // this.textError = this.$t("message.subDevice.scenarioKey.dialog.message_err");
          this.textError = "شماره سریال خالی می باشد";
          this.showError = true;
         } else if(this.selectd_guest_pol==="" || this.selectd_host_pol===""){
          this.showError = true;
          this.textError = this.$t("message.subDevice.scenarioKey.dialog.message_err");
        } else {
          this.showError = false;
          this.sendData();
        }
    },
    sendData() {
      this.infoDialog.push({
        serial_number_guest:this.serial_number_guest,
        guest_pol: this.selectd_guest_pol,
        host_pol: this.selectd_host_pol,
      });
      
      this.$emit("clicked", this.infoDialog);
      this.show = false;
    },
    emptyData() {
      this.showError = false;
      // this.selectedMode = this.$t("message.relay.relay_on_off");
      // this.items = [];
    },
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
.v-text-field input {
  font-family: "IRANSans";
  font-size: 16px !important;
}
</style>
