<template>
 <v-app class="bg">
    <v-container class="mt-2">
      
      <div v-if="listConverts.length==0" class="mt-50">
        <CmpEmptyData
            :btnShow="true"
            :txtBtn="$t('message.public.add')"
            @clicked="clickAddConvert"
        ></CmpEmptyData>
      </div>
     
      <v-row>
        <v-col
            lg="6"
            class="py-0"
            :key="index" v-for="(itemConvert, index) in listConverts">
          <CmpRowList
              :infoItem="itemConvert"
              :items="itemsMenu"
              :captionShow="true"
              :subTitleShow="true"
              @clickedRow="getIndexRowSub"
              @clicked="clickItemMenu"
          ></CmpRowList>
        </v-col>
      </v-row>
      <!-- <my-modal v-show="isModalVisible"></my-modal> -->
      <DialogInputConvert v-model="showDialog" :dialogtxt="dialogConvertTxt"  @clicked="CmpCallConvertKey"/>
    </v-container>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpEmptyData from "../../Cmp/public/CmpEmptyData.vue";
import DialogInputConvert from "@/components/Cmp/DialogInputConvert.vue";
import DialogGeneral from "@/components/Cmp/DialogGeneral.vue";
import {getTopics} from "../../../../public/assets/js/utils/topics";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {ErrorToastMsg, SucessToastMsg} from "../../../../public/assets/js/utils/util";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getMessageGadget} from "@/../public/assets/js/utils/helper_gadget_message.js";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";


var showToast;
var timeout;
var connection = false;
export default {
  components: {
    CmpEmptyData,
    DialogInputConvert,
    DialogGeneral,
    CmpRowList
  },
  props: {
    serial_number: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/+/convert" (data, topic) {
      console.log("data",String(data))
      if (
        topic.split("/")[1]== this.serial_number &&
        topic.split("/")[3] == 256
      ) {
        if (topic.split("/")[4] === "convert") {
          connection = true;
          // clearTimeout(timeout);
          this.closeModalLoading();
          SucessToastMsg.Msg();
          // if (showToast == 1) {
            // SucessToastMsg.Msg();
          // }
        }
      }
    },
  },
  data() {
    return {
      title: "",
      listConverts: [],
      showDialog: false,
      dialogConvertTxt: [],
      msg_convert:"",
      info:[]
    };
  },
  mounted() {
    this.title = this.$t('message.AppBar.Conversion_capability');
    let details = {
      title: this.title,
      add: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutKeyFara');
    this.initDilaog();
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    console.log("convert",this.info.converts)
    this.onSubscribe()
    this.lastStatus()
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingNoneTouchKey",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
      if (value === 'add') {
      // Open Dialog
      this.showDialog = true;
    //  this.$swal({
    //       title: this.$t('message.ir.delete'),
    //       text: this.$t('message.ir.delete_key'),
    //       html: `<label class="text-primary">${this.name}</label>` +
    //             `<input id="swal-input1" class="swal2-input" placeholder=${this.name1}>` +
    //             `<label class="text-primary">${this.name}</label>`+
    //             `<select id="swal-input2" class="swal2-input"> <option value="fi_FI">${this.name1}</option> <option value="sv_SE">sv_SE</option> </select>`+ 
    //             '<select id="swal-input3" class="swal2-input"> <option value="fi_FI">fi_FI</option> <option value="sv_SE">sv_SE</option> </select>',
    //       showCancelButton: true,
    //       confirmButtonColor: "#d33",
    //       cancelButtonColor: "#0F6BB9",
    //       confirmButtonText: this.$t('message.public.delete'),
    //       cancelButtonText: this.$t('message.public.cancel'),
    //       allowOutsideClick: false,
    //     }).then(
    //         (result) => {
    //           if (result) {
    //             // this.deleteControl();
    //             console.log("customer",document.getElementById('swal-input1').value)
    //             console.log("customer 2",document.getElementById('swal-input2').value)
    //             console.log("customer 3",document.getElementById('swal-input3').value)
    //           }
    //         },
    //         (dismiss) => {
    //           if (dismiss === "cancel") {
    //             console.log("customer",document.getElementById('swal-input1').value)

    //           } else {
    //             throw dismiss;
    //           }
    //         }
    //     );
      }
    },
    clickAddConvert() {
      this.showDialog = true;
      // localStorage.setItem(
      //     "infoRelay8oLocal",
      //     JSON.stringify(this.localRelaydata)
      // );
      // this.$router.push({
      //   name: "boxrelayadd",
      //   params: {
      //     home_id: this.home_id,
      //     serial_number: this.serial_number,
      //     type_code: 15,
      //   },
      // });
    },
    clickItemMenu(value) {
      var sub_serial = this.ServerInfoSubBox[value.index].serial_number;
      localStorage.setItem("infoSubDevice", this.ServerInfoSubBox[value.index].set_password);
      let self = this;
      switch (value.itemId) {
        case 0:
          this.$router.push({
            name: "boxrelayedit",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              sub_serial: sub_serial,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "boxRelayTimerShow",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              sub_serial: sub_serial,
            },
          });
          break;
        case 2:
          this.showToggleDialog(
              self.ServerInfoSubBox[value.index].serial_number
          );
          break;
        case 3:
          this.showDelDialog(self.ServerInfoSubBox[value.index].serial_number);
          break;
        case 4:
          this.dataReset = [];
          this.dataReset.push(
              {serial: self.serial_number},
              {sub_serial: sub_serial}
          );
          $("#modal-reset").modal("show");
          break;
      }
    },
    initDilaog(){
      this.dialogConvertTxt.push({
      header: this.$t('message.dialog.dialog_convert.header'),
      caption: this.$t('message.dialog.dialog_convert.caption'),
      input1_title: this.$t('message.dialog.dialog_convert.input1_title'),
      input1_hint: this.$t('message.dialog.dialog_convert.input1_hint'),

      input2_title: this.$t('message.dialog.dialog_convert.input2_title'),
      input2_hint: this.$t('message.dialog.dialog_convert.input2_hint'),

      input3_title: this.$t('message.dialog.dialog_convert.input3_title'),
      input3_hint: this.$t('message.dialog.dialog_convert.input3_hint'),
    })
    },
    showDelDialog(sn) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.resetRelay(sn);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    CmpCallConvertKey(msg){
      this.msg_convert=getMessageGadget.getMessageConvert(
        msg[0].serial_number_guest,
        msg[0].guest_pol,
        msg[0].host_pol
      );
      this.sendMessage( this.msg_convert)
    },
    showToggleDialog(sub_sn) {
      this.$swal({
        // title: this.$t("message.public.delete"),
        text: this.$t("message.relay.toggle.toggle_dialog_txt_descript"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.toggleBtnDialogOK,
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              // this.resetRelay(sn);
              this.onSubscribe(sub_sn);
              this.sendToggle(sub_sn);

            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );

    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(message) {
      // this.keytouch_status=status;
      // if (this.set_password == 1) {
      //   $("#modal-pass-code").modal("show");
      // } else {
        this.sendRequest(message, '');
      // }
    },
    sendReq(password) {
      this.sendRequest(this.keytouch_status, password);
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // console.log(message, message);
      let topic_final = getTopics.getTopicConvertSend(
          this.serial_number, 256);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          // self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            console.log("connection 01",connection)

            if (!connection) {
                  if (showToast === 1)
                   self.closeModalLoading ();
                    ErrorToastMsg.Msg();
                } else{
                  self.closeModalLoading ();
                  // self.$router.push({
                  //   name: "mainpage",
                  // });
                  self.$router.push({
                  name: "keyTouchNoneInfo",
                  params: {serial_number: self.serial_number},
                  });
                }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    lastStatus() {
      // console.log("lastStatus")
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      // ------------------Request------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
          function (response) {
            self.closeModalLoading();
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.info=jsonArray.content.info
            
              // self.showUI=true;
             
              
            } else {
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },
    onSubscribe(){
      let topic2 = getTopics.getTopicConvertReceive(
        this.serial_number,
        256
    )
    this.$mqtt.subscribe(topic2);
    }
  },
};
</script>
<style scoped>
</style>
