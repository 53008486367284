import i18n from "../../../../src/i18n";

export function MobileValidationInput(mobile) {
    // console.log("in err fun", mobile.length)
    if (emptyValid(mobile)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.profile.tel')}), "error");
        return false
    }
    if (lengthMinValid(mobile, 11) || lengthMaxValid(mobile, 11)) {
        swal("", i18n.t('message.error_handler.11number'), "error");
        return false
    }
    return true;
}
export function EmailValidationInput(email) {
    // console.log("in err fun", mobile.length)
    if (emptyValid(email)) {
        swal("", i18n.t('message.error_handler.empty', {name: "ایمیل"}), "error");
        return false
    }
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    //     swal("", "ایمیل نادرست است", "error");
    //     return false
    // }
    return true;
}
export function nameValidationInput(name) {
    // console.log("in err fun", name.length)
    if (emptyValid(name)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.public.name')}), "error");
        return false
    }
    if (lengthMaxValid(name, 32) || lengthMaxValid(name, 32)) {
        swal("", i18n.t('message.error_handler.max', {count: 32}), "error");
        return false
    }
    return true;
}

export function nameHomeValidationInput(name) {
    // console.log("in err fun", name.length)
    if (emptyValid(name)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.ModalAddHome.name')}), "error");
        return false
    }
    if (lengthMaxValid(name, 32)) {
        swal("", i18n.t('message.error_handler.name_max', {count: 32}), "error");
        return false
    }
    return true;
}

export function lnameUserValidationInput(name) {
    if (emptyValid(name)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.profile.last_name')}), "error");
        return false
    }
    if (lengthMaxValid(name, 32) || lengthMaxValid(name, 32)) {
        swal("", i18n.t('message.error_handler.max', {count: 32}), "error");
        return false
    }
    return true;
}

export function addressUserValidationInput(address) {
    if (emptyValid(address)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.profile.address')}), "error");
        return false
    }
    if (lengthMaxValid(address, 200)) {
        swal("", i18n.t('message.error_handler.max', {count: 200}), "error");
        return false
    }
    return true;
}

export function nameDeviceValidationInput(name) {
    // console.log("in err fun", name.length)

    if (emptyValid(name)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.ModalAddSubDevicePol.name')}), "error");
        return false
    }
    if (lengthMaxValid(name, 32)) {
        swal("", i18n.t('message.error_handler.max', {count: 32}), "error");
        return false
    }
    return true;
}

export function serialDeviceValidationInput(serial) {
    // console.log("in err fun serial", serial.length)

    if (emptyValid(serial)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.ModalAddSubDeviceFaraPol.serial')}), "error");
        return false
    }
    if (lengthMaxValid(serial, 12)) {
        swal("", i18n.t('message.error_handler.max', {count: 12}), "error");
        return false
    }
    return true;
}

export function passEmailValidationInput(pass) {
    // console.log("in err fun", pass.length)

    if (emptyValid(pass)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.public.password')}), "error");
        return false
    }
    if (lengthMaxValid(pass, 8)) {
        swal("", i18n.t('message.error_handler.max', {count: 8}), "error");
        return false
    }
    return true;
}
export function passCompareValidationInput(oldPass, newPass) {
    // console.log('oldPass',oldPass)
    // console.log('newPass',newPass)
    // console.log('equalValid(oldPass, newPass)',equalValid(oldPass, newPass))

   if (!equalValid(oldPass, newPass)) {
        //   console.log('equalValid')
        swal("", i18n.t('message.error_handler.equal_pass', {name: ' کلمه عبور'}), "error");
        return false
    }
    // console.log('equalValid else')
    return true;
}

export function passHomeValidationInput(pass) {
    // console.log("in err fun", pass.length)

    if (emptyValid(pass)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.ModalAddHome.pin_code')}), "error");
        return false
    }
    if (lengthMaxValid(pass, 4)) {
        swal("", i18n.t('message.error_handler.max', {count: 4}), "error");
        return false
    }
    return true;
}

export function passOldHomeValidationInput(pass) {
    // console.log("in err fun", pass.length)

    if (emptyValid(pass)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.public.oldPIN')}), "error");
        return false
    }
    if (lengthMaxValid(pass, 4)) {
        swal("", i18n.t('message.error_handler.max', {count: 4}), "error");
        return false
    }
    return true;
}

export function passRepeatHomeValidationInput(pass) {
    // console.log("in err fun", pass.length)

    if (emptyValid(pass)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.ModalAddHome.password_repeat')}), "error");
        return false
    }
    if (lengthMaxValid(pass, 4)) {
        swal("", i18n.t('message.error_handler.max', {count: 4}), "error");
        return false
    }
    return true;
}

export function smsCodeValidationInput(smscode) {
    // console.log("in err fun", smscode.length)

    if (emptyValid(smscode)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.client.OK')}), "error");
        return false
    }
    return true;
}

export function tempValidationInput(temp) {
    // console.log("in err fun", temp.length)

    if (emptyValid(temp)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.RelayKey.T')}), "error");
        return false
    }
    if (MaxValid(temp, 60) || MinValid(temp, 0)) {
        swal("", i18n.t('message.error_handler.between', {from: 0, to: 60}), "error");
        return false
    }
    return true;
}

export function tempCompareValidationInput(temp1, temp2) {
    // console.log("in err fun", temp1.length)
    // console.log("in err fun", temp2.length)

    if (compareValid(temp1, temp2)) {
        swal("", i18n.t('message.error_handler.compare', {name: i18n.t('message.RelayKey.T')}), "error");
        return false
    } else if (equalValid(temp1, temp2)) {
        swal("", i18n.t('message.error_handler.equal', {name: i18n.t('message.RelayKey.T')}), "error");
        return false
    }
    return true;
}

export function ampValidationInput(amp) {
    // console.log("in err fun", amp.length)

    if (emptyValid(amp)) {
        swal("", i18n.t('message.error_handler.empty', {name: i18n.t('message.RelayKey.I')}), "error");
        return false
    }
    if (MaxValid(amp, 20) || MinValid(amp, 0)) {
        swal("", i18n.t('message.error_handler.between', {from: 0, to: 20}), "error");
        return false
    }
    return true;
}

export function ampCompareValidationInput(amp1, amp2) {
    // console.log("in err amp1", amp1)
    // console.log("in err amp2", amp2)

    if (compareValid(amp1, amp2)) {
        swal("", i18n.t('message.error_handler.compare', {name: i18n.t('message.RelayKey.I')}), "error");
        return false
    } else if (equalValid(amp1, amp2)) {
        swal("", i18n.t('message.error_handler.equal', {name: i18n.t('message.RelayKey.I')}), "error");
        return false
    }
    return true;
}

export function voltValidationInput(temp, volt2, volt3) {

    if (emptyValid(temp)) {
        swal("", i18n.t('message.error_handler.vacant', {name: i18n.t('message.error_handler.delay')}), "error");
        return false
    }
    if (emptyValid(volt2)) {
        swal("", i18n.t('message.error_handler.vacant', {name: i18n.t('message.error_handler.SV')}), "error");
        return false
    }
    if (emptyValid(volt3)) {
        swal("", i18n.t('message.error_handler.vacant', {name: i18n.t('message.error_handler.FV')}), "error");
        return false
    }
    if (MaxValid(temp, 800) || MinValid(temp, 1)) {
        swal("", i18n.t('message.error_handler.among', {
            name: i18n.t('message.error_handler.delay'),
            from: 1,
            to: 800
        }), "error");
        return false
    }
    if (MaxValid(volt2, 250) || MinValid(volt2, 160)) {
        swal("", i18n.t('message.error_handler.among', {
            name: i18n.t('message.error_handler.SV'),
            from: 160,
            to: 250
        }), "error");
        return false
    }
    if (MaxValid(volt3, 250) || MinValid(volt3, 160)) {
        swal("", i18n.t('message.error_handler.among', {
            name: i18n.t('message.error_handler.FV'),
            from: 160,
            to: 250
        }), "error");
        return false
    }
    // if (compareValid(volt2,volt3)) {
    //         swal("", "ولتاژ آغازین نمی تواند از ولتاژ پایانی بزرگتر باشد", "error");
    //         return false
    // }if (equalValid(volt2,volt3)) {
    //         swal("", "ولتاژ آغازین و ولتاژ پایانی نمی تواند یکسان باشد", "error");
    //         return false
    // }
    return true;
}

export function volCompareValidationInput(volt2, volt3) {
    // console.log("in err volt2", volt2)
    // console.log("in err volt3", volt3)

    if (compareValid(volt2, volt3)) {
        swal("", i18n.t('message.error_handler.compare', {name: i18n.t('message.RelayKey.V')}), "error");
        return false
    } else if (equalValid(volt2, volt3)) {
        swal("", i18n.t('message.error_handler.equal', {name: i18n.t('message.RelayKey.V')}), "error");
        return false
    }
    return true;
}

export function emptyValid(input) {
    if (input === "" || input.length == 0) {
        return true
    } else {
        return false
    }
}

export function lengthMinValid(input, min) {
    if (input.length < min) {
        return true
    } else {
        return false
    }
}

export function lengthMaxValid(input, max) {
    console.log('input',input)
    console.log('input',input.length)
    if (input.length > max) {
        return true
    } else {
        return false
    }
}

export function MaxValid(input, max) {
    if (input > max) {
        return true
    } else {
        return false
    }
}

export function MinValid(input, min) {
    if (input < min) {
        return true
    } else {
        return false
    }
}

export function errOutletDelay(time) {
    // console.log('errOutletDelay',time);
    if (time > 99 || time === "" || time == null || time < 0 || time == 0) {
        swal("", i18n.t('message.error_handler.time'), "error");
        return false
    }
    return true

}

export function compareValid(value1, value2) {
    if (value1 > value2) {
        return true
    } else {
        return false
    }

}

export function equalValid(value1, value2) {
    if (value1 === value2) {
        console.log('true equl')
        return true
    } else {
        console.log('false equl')
        return false
    }

}

export function TimerValidationInput(value1, value2) {
    // console.log("time1",value1)
    // console.log("time2",value2)
    if (value1 > value2) {
        swal("", i18n.t('message.error_handler.compare', {name: i18n.t('message.error_handler.period')}), "error");
        return false
    } else if (value1 == value2) {
        swal("", i18n.t('message.error_handler.equal', {name: i18n.t('message.error_handler.period')}), "error");
        return false
    } else {
        return true
    }

}

export function DayValidationInput(value1) {
    if (value1 == 0) {
        swal("", i18n.t('message.error_handler.date'), "error");
        return false
    } else {
        return true
    }

}

export function getTimerTime(message) {
    return message.substr(message.indexOf("D") + 2, 1);
}  
export function actionsScenariodata(message) {
    // console.log("mssge actions",message)
            if(message.length==0){
                    swal("", i18n.t('message.error_handler.add_scenario_status_err'), "error");
                    return false
            } else{
                    return true
            }
}
export function actionRelaydata(message) {
            if(message.length==0){
                    swal("", i18n.t('message.error_handler.add_relay_status_err'), "error");
                    return false
            } else{
                    return true
            }
}
export function actionScenarioKeydata(message) {
    if(message.length==0){
            swal("", i18n.t('message.error_handler.add_relay_status_err'), "error");
            return false
    } else{
            return true
    }
}