<template>
  <!-- <v-app class="bg"> -->
    <section class="content">
    <Step2Timer :end="false" :daysData="daysdata" @myFunction="MyFunction"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </section>

  <!-- </v-app> -->
</template>
<script>
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../../public/assets/js/utils/util.js";
import {Tools} from "../../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {DayValidationInput, TimerValidationInput} from "../../../../../public/assets/js/utils/error_handler";
import Step2Timer from "@/components/Cmp/timer/create/Step2Timer.vue";
import {listOfDays} from "../../../../../public/assets/js/utils/timer_parser";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var showToast;
var connection = false;

export default {
  components: {
    Step2Timer,
    CmpPasscode,
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.daysdata = listOfDays();
    this.getCount();
  },
  data() {
    return {
      lang: '',
      title: "",
      time1: Tools.getNow(),
      // time2: Tools.getNow(),
      daysdata: [],
      set_password: '',
      timer_count: "",
      days: [],
      temparray: [],
      selectedday: "",
      isModalVisible: false
    };
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  mounted() {
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer3');
    let self = this;
    this.title = this.$t('message.timer.scheduler')
    this.$store.dispatch("statusTimer");
    this.$store.dispatch("InfoSubDevice");
    this.set_password = JSON.parse(localStorage.getItem("infoSubDevice")).set_password;
    setTimeout(function () {
      self.getCount();
    }, 50);
    //------subscribe
    var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
    var topic1 = getTopics.getTopicTimerReceive(
        this.serial_number,
        subdevice_serial
    );
    this.$mqtt.subscribe(topic1);
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "timer") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            Tools.sweetAlert(this.$t('message.timer.timer'), this.$t('message.timer.added'), 'success', 3000)
          }
        }
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    MyFunction(days, time1) {
      this.days = days;
      this.time1 = time1;
      // this.time2 = time2;
      if (
          !TimerValidationInput(time1) ||
          !DayValidationInput(days.length)
      )
        return;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.days, this.time1, '');
      }
    },
    sendReq(password) {
      this.sendMessage(this.days, this.time1, password);
    },
    sendMessage(days, time1, password) {
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let time = time1.split(":");
      let h, m;
      if (parseInt(time[1]) === 59) {
        if (parseInt(time[0]) === 23) {
          time[0] = 0;
          time[1] = 0;
        } else {
          time[1] = parseInt(time[1]) + 1;
          time[0] = 0;
        }
      } else {
        h = parseInt(time[0]);
        m = parseInt(time[1]) + 1;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      let time2 = h + ":" + m;
      let msgday = this.getDaySelected(days);
      let delay = localStorage.getItem("elevator_delay" + this.serial_number) ? localStorage.getItem("elevator_delay" + this.serial_number) : "01";
      // let timer_status = "K1:" + delay + "S#";
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let timer_status = "PS" + subdevice_serial + "*K1T";
      // timer_status = timer_status.replaceAll('"', '')
      message =
          "TIMER" +
          this.timer_count +
          "-" +
          timer_status +
          "-" +
          msgday +
          time1 +
          "-" +
          '25:00' +
          "#";
      const topic_final = getTopics.getTopicTimerSend(
          this.serial_number,
          subdevice_serial
      );
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      //-----------------------Response ------------------------//

      p.then(function (response) {
            if (responseAjax(response)) {
              self.exitModalLoading();
              let jsonArray = JSON.parse(response.responseText);
          
              setTimeout(function () {
                if (connection){
                self.$router.push({
                  name: "ElevatorProTimerShow",
                  params: {
                    serial_number: self.serial_number,
                    home_id: self.home_id,
                    count: self.count,
                  },
                });
                }
                else if (!connection) {
                  self.exitModalLoading();
                  if (showToast === 1) {
                    ErrorToastMsg.Msg();
                    self.$router.push({
                      name: "ElevatorProTimerShow",
                      params: {
                        serial_number: self.serial_number,
                        home_id: self.home_id,
                        count: self.count,
                      },
                    });
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              //error

              self.exitModalLoading();
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getCount() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;

      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.timerCount;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.timer_count = jsonArray.content.timer_count;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getDaySelected(days) {
      this.selectedday = "";
      var check = false;
      for (var i of this.daysdata) {
        this.temparray.push(i);
      }
      this.temparray.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);
      this.temparray.forEach((element) => {
        days.forEach((values) => {
          if (values.value == element.value) check = true;
        });
        if (check) this.selectedday += element.value + "1" + "-";
        else this.selectedday += element.value + "0" + "-";
        check = false;
      });
      return this.selectedday;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "ElevatorProTimerShow",
          params: {serial_number: this.serial_number, home_id: this.home_id},
        });
      }
    },
  },
};
</script>


<style scoped>
</style>
