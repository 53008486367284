<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-reset-curtainPro"
      tabindex="-1">
    <div class="wrapper m-0">
       <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
       <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
      <CmpResetFactory
       @clicked="CallCmpReset"
       :txtDescript="$t('message.relay.relay_reset_txt')"
       :loading="loading"
       :countshow="countshow"
       ></CmpResetFactory>
       
      <!-- <div class="modal-dialog p-20">
       

      </div> -->
    </div>
  </div>
</template>
<script>
var connection = false;
//--------------------------------------  variable --------------------------------------//
import AppBar from "@/components/AppBar.vue";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import CmpResetFactory from "@/components/Cmp/general/CmpResetFactory.vue";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import CmpPasscode from "@/components/Cmp/general/CmpPasscode";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  components: {
    AppBar,
    CmpResetFactory,
    CmpPasscode,
  },
  data() {
    return {
      title: "",
      dataReset: "",
      serial_number: "",
      subdevice_serial:"",
      loading: false,
      countshow: false,
      set_password: 0,
     
    };
  },
  props: {
    infoItem: {},
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      // if (topic.split("/")[1] === this.serialnumbers[0]) {
      this.checkMqtt(String(data));
      connection = true;
      // }
    },
  },
    created() {
      this.title = this.$t("message.noneHome.reset");
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.set_password= this.info.set_password;
    this.onSubScribe();
  },
  methods: {
    CallCmpReset(value) {
    
      this.dataReset = this.infoItem;
      this.serial_number = this.infoItem[0].serial_number;
      this.subdevice_serial = this.infoItem[0].subdevice_serial;
      this.countshow = true;
      if (value == "cancel"){
      
        this.CancelFunction();
      }
      if (value == "delete") {
        this.loading = true;
        if (this.set_password == 1) {
               $("#modal-reset-curtainPro").modal("hide");
               $("#modal-pass-code").modal("show");
             } else {
              this.ResetFunction('');
            }  
      }
    },
    CancelFunction() {
      $("#modal-reset-curtainPro").modal("hide");
      this.loading = false;
      this.countshow = false
    },
    sendReq(password) {
      this.openModalLoading();
      this.ResetFunction(password);
    },
    ResetFunction(password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.loading = true;
      let saction = "RESET#";
      var topic_final = getTopics.getTopicBroadcastSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password:password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            self.onSubScribe();
            setTimeout(function () {
              if (!connection) {
                self.loading=false
                swal("", self.$t("message.public.operation_failure"), "error");
                $("#modal-reset-curtainPro").modal("hide");
              }
            }, 25000);
          },
          function (errr) {
            //errr
            self.countshow = false
            self.loading=false
          }
      );
    },
    onSubScribe() {
      var topic = getTopics.getTopicSettingReceive(
          this.serial_number,
          this.subdevice_serial);
      this.$mqtt.subscribe(topic);
    },
    checkMqtt(msg) {
      let self = this;

      // let device_serial=this.serialnumbers[0]
      if (msg == "RESETOK") {
        self.loading=false
        self.countshow = false
        swal({
          title: this.$t("message.irAir.settings"),
          text: this.$t("message.noneHome.r_txt"),
          type: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(function () {
          self.$router.push({name: "mainpage"});
        }, 2005);
      }
    },
    onClickChild(value) {
      if (value == "back") {
        $("#modal-reset-curtainPro").modal("hide");
      }
    },
  },
};
</script>


<style scoped>
</style>
