<template>
  <div class="input-group">
    <div class="input-group-prepend" style="height: 40px !important">
      <button class="btn btn-primary btn-sm" type="button" @click="getTime">
        {{ $t('message.subDevice.doorshutter') }}
      </button>
    </div>
    <input
      style="height: 40px !important"
      type="number"
      :value="value"
      ref="groupId"
      class="form-control inputVal fileUpload"
      id="fileUpload"
      placeholder=""
      aria-label=""
      aria-describedby="basic-addon1"
    />
    <div class="input-group-prepend">
      <span class="input-group-text">ثانیه</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
      time: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  methods: {
    getTime: function () {
      this.inputVal();
    },
    inputVal() {
      this.time = this.$refs.groupId.value;
      console.log(this.time);
    },
  },
};
</script>
<style  scoped>
</style>
