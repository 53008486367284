<template>
  <!-- .wrapper  -->
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-sub-momentray"
      tabindex="-1">
    <div class="wrapper m-0">
      <!-- .header -->
      <header class="main-header">
        <!-- Header Navbar -->
        <nav class="navbar navbar-static-top">
          <!-- Sidebar toggle button-->
          <div class="navbar-custom-menu toolbar">
            <div class="media p-0">
              <i :class=" {'ti-angle-left':this.lang === 'en',
                'ti-angle-right':this.lang !== 'en'}" style="font-size:24px" @click="goBack()"></i>
              <div class="media-body p-0">
                <strong>{{ $t('message.AppBar.instantSettings') }}</strong></div>
            </div>
          </div>
        </nav>
      </header>
      <!-- ./header -->
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper mr-0 ">
        <div class="box-body chart-responsive">
          <div style="height: 812px;">
            <!-- Main content -->
            <section class="content">
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-12 col-lg-12 p-0">
                  <div class="box">
                    <div class="box-body p-0">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="bg-white">
                          <div style="text-align: center; margin-top: 10px">
                            <div class="fontawesome-icon-list m-0">
                              <img
                                  src="/assets/images/smart/time-delay.svg"
                                  class="avatar-smart"
                              />
                              <h6 class="font-weight-bold">
                                شما می توانید اعداد 1 تا 90 را در کادر زیر وارد نمایید
                              </h6>
                            </div>
                          </div>
                          <br/>
                          <form class="px-40">
                            <div class="form-group">
                              <!-- <h6>نام دستگاه <span class="text-danger">*</span></h6> -->
                              <input
                                  type="number"
                                  class="form-control text-center"
                                  required=""
                                  aria-invalid="false"
                                  maxlength="32"
                                  autocomplete="off"
                                  v-model="delay"
                                  id="outletdelay"
                                  placeholder="مقدار را وارد نمایید"
                                  value="01"
                              />
                            </div>

                            <div class="row">
                              <!-- /.col -->
                              <div class="col-12 text-center">
                                <button
                                    id="btn-add-delay"
                                    type="button"
                                    class="btn btn-info btn-block margin-top-10"
                                    v-on:click.prevent="SetDelay()"
                                >
                                  ثبت
                                </button>
                              </div>
                              <!-- /.col -->
                            </div>
                          </form>
                          <div class="text-center" style="height: 50px"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->
            </section>
            <!-- /. Main content -->
          </div>
        </div>
        <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false"
             data-backdrop="static">
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">در حال بارگذاری</h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <span class="pl-20" id="txtwait">لطفا شکیبا باشید</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.Content Wrapper. Contains page content -->

    </div>
    <!-- /.modal Add Home-->

  </div>
  <!-- ./wrapper -->
</template>

<script>
//--------------------------------------  variable --------------------------------------//


import {errOutletDelay} from "../../../../public/assets/js/utils/error_handler";

export default {
  components: {},
  data() {
    return {
      delay: "01",
      lang: ""

    }
  },
  props: {
    serials: {type: String}
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");

    this.btnLoading();
    // var input1 = document.getElementById("outletdelay");
    var relay_delay = localStorage.getItem("relay_delay" + JSON.parse(localStorage.getItem("infoSubDevice")).serial_number);


    if (localStorage.getItem("log") == 0) {
      console.log = function () {
      };
    }

    if (relay_delay == null) {

      this.delay = "01";

    } else {
      this.delay = localStorage.getItem("relay_delay" + JSON.parse(localStorage.getItem("infoSubDevice")).serial_number);
    }
  },
  methods: {
    SetDelay() {
      // var input1 = document.getElementById("outletdelay");
      var time = this.delay;
      var formatted_time = ("0" + time).slice(-2);
      if (!errOutletDelay(time))
        return


      localStorage.setItem("relay_delay" + JSON.parse(localStorage.getItem("infoSubDevice")).serial_number, formatted_time);
      //It not Good
      // location.reload();
      setTimeout(function () {

        $("#modal-sub-momentray").modal("hide");
      }, 50);


    },
    goBack() {
      //It not Good
      // location.reload();
      setTimeout(function () {
        //  this.isModalVisible = false;

        $("#modal-sub-momentray").modal("hide");
      }, 50);
    },
    btnLoading() {

      var $btn = $('#btn-add-delay');
      $btn.click(function () {
        var $this = $(this);
        $this.attr('disabled', 'disabled').html("<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span> بارگذاری...");
        setTimeout(function () {
          $this.removeAttr('disabled').html('ثبت');
        }, 2000)
      });
    }
  },
};
</script>


<style scoped>
</style>
