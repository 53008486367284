<template>
  <v-app class="bg">
    <div>
      <div class="row px-20 mt-20">
        <div class="col-lg-12 col-12 py-0">
          <div class="box rounded-16">
            <div class="box-body pb-0">
              <div class="row">
                <div class="col-xl-12 col-md-12 col-12 py-0">
                  <div class="box-footer with-border p-0" v-bind:key="index" v-for="(info, index) in infoScenarioKey">
                    <CmpRowImg :info="info">
                      <template v-slot:image>
                        <v-img :src="info.img" width="30px" height="30px" aspect-ratio="1" class="galimage"
                          align-self="center"></v-img>
                      </template>
                      <template v-slot:label>
                        <label class="px-1" align-self="center">{{ info.name }}</label>
                        <br>
                        <label v-if="info.subtitle" class="px-1" align-self="center" style="color: #0F6BB9;font-size: 11px;">{{ info.subtitle }}</label>
                        <label v-else-if="!info.subtitle" class="px-1" align-self="center" style="color: #BDBDBD;font-size: 11px;">{{$t("message.subDevice.scenarioKey.noselect_scenariokey")}}</label>

                        
                      </template>
                      <template v-slot:btn>
                        <v-btn fab small elevation="2" :disabled='!info.active' color="primary"
                          @click="clickRunScenario(info.scenarioCount, info.value, info.active)">
                          <v-icon>mdi-power</v-icon>
                        </v-btn>
                      </template>
                    </CmpRowImg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./Modal Pass-->
    <CmpPasscode @sendPassCode="sendReq" @cancel="closePass"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
    <SubModalDel v-bind:data_info_sub="data_info_scenariokey"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_scenariokey"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_scenariokey"></SubModalSetPass>
  </v-app>
</template>

<script>

import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { BetweenStr } from "../../../../public/assets/js/utils/util.js";
import { ErrorToastMsg } from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRowImg from "@/components/Cmp/CmpRowImg.vue";
import { getMessageSub } from "../../../../public/assets/js/utils/helper_sub_message.js";
import { ToastCustom } from "../../../../public/assets/js/utils/util.js";
import { getUrl } from "../../../../public/assets/js/utils/helper_url.js";

var connection = false;
var showToast;
var timeout;

export default {
  name: "SubService",
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    CmpRowImg
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      data_info_scenariokey: [this.home_id, this.serial_number, this.count],
      title: "",
      pass: true,
      set_password: false,
      isModalVisible: false,
      subdevice_serial: "",
      info: [],
      infoScenarioKey: [],
      infoSubDeviceServer: [],
      dataSubDevice: [],
      scenarios: ['0', '1'],
      dialogScenarioKeyTxt: [],
      // saction is message created for action
      saction:''
    };
  },
  async created() {
    this.dataSubDevice = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.subdevice_serial = this.dataSubDevice.serial_number
    this.pass = this.dataSubDevice.password === 1;
    this.set_password = this.dataSubDevice.set_password;
  },
  mqtt: {
    "+/+/receive/+/scenario"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number
      ) {
        if (topic.split("/")[4] === "scenario") {
          connection = true;
          clearTimeout(timeout);

          this.closeModalLoading();
          if (showToast == 1) {
            this.showGetMsg(String(data));
          }
        }
      }
    },
  },
  mounted() {
    // init run;
    try {
     
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.datahome = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.datahome.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.title = this.$store.getters.getNameSub;
      // subscribe
      this.onSubscribe()
    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: this.$t("message.ListDevice.err_process"),
          type: "error",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({ name: "mainpage" });
            swal.close();
          }
        }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      edit: true,
      del: true,
      about:true,
      config: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubScenariokey');
     this.scenario_key = this.dataSubDevice.scenario_keys,
     this.initDilaog();
     this.initData();
     this.initDataServer();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if (value === "config") {
        this.$router.push({
          name: "SubScenarioKeyConfig",
          params: { home_id: this.home_id, serial_number: this.serial_number, count: this.count },
        });
      }
      if (value == "del") {
        localStorage.setItem("infoScenarioKeyLocal", []);
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "SenarioAbout",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "edit") {
        // this.$router.push({
        //   name: "EditDevice",
        //   params: {
        //     home_id: this.home_id,
        //     serial_number: this.serial_number,
        //     count: this.count,
        //     type: "WC",
        //     active: false
        //   },
        // });
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    sendMessage(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      var topic_final = getTopics.getTopicScenarioSend(
        this.serial_number,
        256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            //  connection=false;
            self.openModalLoading();
            $("#modal-pass-code").modal("hide");
            timeout = setTimeout(function () {
              self.closeModalLoading();
              if (!connection) {
                setTimeout(function () {
                }, 1000);
                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                }

              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.closeModalLoading();
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    },
    sendReq(password) {
      this.sendMessage(this.saction, password);
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    closePass() {
      // location.reload();
    },
    clickRunScenario(count, value, active) {
      if (active == true) {
        this.saction = getMessageSub.getMessageScenarioKeyAction(count, value)
        if (this.set_password == 1) {
          $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.saction, '');
      }
      }
    },
    showGetMsg(msg) {
      this.closeModalLoading();
      if (showToast == 1) {
        var running = msg.includes("RUNNING");
        if (running) {
          ToastCustom.custom(this.$t("message.scenario.toast_success_scenario"), "#28a745", "#fff");
          // connection = false;
        }
      }
    },
    initDilaog(){
      this.dialogScenarioKeyTxt.push({
        header: this.$t('message.subDevice.scenarioKey.dialog.header'),
        caption: this.$t('message.subDevice.scenarioKey.dialog.caption'),
        input1_title: this.$t('message.subDevice.scenarioKey.dialog.input1_title'),
        input1_hint: this.$t('message.subDevice.scenarioKey.dialog.input1_hint'),
        input2_title: this.$t('message.subDevice.scenarioKey.dialog.input2_title'),
        input2_hint: this.$t('message.subDevice.scenarioKey.dialog.input2_hint'),
      })
    },
    initData(){
      this.scenario_key = this.dataSubDevice.scenario_keys
      this.infoScenarioKey.push(
      {
        id: 1,
        name:  this.$t('message.subDevice.scenarioKey.scenario_key1'),
        subtitle:'',
        imgStatus: 'mdi-water-pump',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 2,
        name: this.$t('message.subDevice.scenarioKey.scenario_key2'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 3,
        name: this.$t('message.subDevice.scenarioKey.scenario_key3'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 4,
        name: this.$t('message.subDevice.scenarioKey.scenario_key4'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 5,
        name: this.$t('message.subDevice.scenarioKey.scenario_key5'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 6,
        name: this.$t('message.subDevice.scenarioKey.scenario_key6'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 7,
        name: this.$t('message.subDevice.scenarioKey.scenario_key7'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 8,
        name:this.$t('message.subDevice.scenarioKey.scenario_key8'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      },
      {
        id: 9,
        name: this.$t('message.subDevice.scenarioKey.scenario_key9'),
        subtitle:'',
        imgStatus: 'mdi-fan',
        active: false,
        value: '',
        img: "/assets/images/smart/scenario_deactive.svg",
        scenarioCount: ''
      }
    );
    },
    initDataServer(){
      if (this.scenario_key){
      var jsondataKey = JSON.parse(this.scenario_key).key_info
      let self=this;
      jsondataKey.forEach(function (item, i) {
      for (var i = 0; i < self.infoScenarioKey.length; i++) {
        if (item.key_count == self.infoScenarioKey[i].id) {
          self.infoScenarioKey[i].active = true
          self.infoScenarioKey[i].value = item.scenario_msg
          self.infoScenarioKey[i].scenarioCount = item.scenario_count
          self.infoScenarioKey[i].img = item.scenario_icon
          self.infoScenarioKey[i].subtitle = item.scenario_name

        }
      }
    });
  }
    },
    onSubscribe(){
      var topic1 = getTopics.getTopicScenarioReceive(
        this.serial_number,
        256
      );
      this.$mqtt.subscribe(topic1);
    }
  },
};
</script>

<style scoped>

</style>