<template>
  <v-app class="bg">
    <v-container class="mt-4">
      <!-- row -->
      <v-card elevation="0" class="my-2">
        <v-row align="center">
          <v-col class="d-flex" cols="8">
            <div class="media align-items-center setting">
              <i class="mdi mdi-calendar-today mdi-24px"></i>
              <h5> {{ $t('message.settings.startOfWeek') }} </h5>
            </div>
          </v-col>
          <v-col cols="4" style=" padding-right: 25px; padding-left: 25px">
            <v-select
                v-model="select"
                :items="items"
                item-text="name"
                item-value="id"
                label="Select"
                persistent-hint
                return-object
                single-line
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import LayoutDefault from "../layouts/LayoutDefault";

export default {
  name: "Settings",
  props: {
    click: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      lang: '',
      select: {},
      items: [
        {
          id: 0,
          name: this.$t('message.timer.saturday'),
          value: "D6",
          sort: 6,
        },
        {
          id: 1,
          name: this.$t('message.timer.sunday'),
          value: "D7",
          sort: 7,
        },
        {
          id: 2,
          name: this.$t('message.timer.monday'),
          value: "D1",
          sort: 1,
        },
      ]
    };
  },
  created() {
    let data = {
      setting: true,
      title: this.$t('message.public.settings'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Settings');
  },
  mounted() {
    let sel = localStorage.getItem('startOfWeek')
    if (sel !== undefined) {
      for (const item in this.items) {
        if (this.items[item].id == sel) {
          this.select = this.items[item]
        }
      }
    } else {
      this.select = this.items[0]
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onClickChild(value) {
      if (value === "back") {
        localStorage.setItem('startOfWeek', this.select.id)
        this.$router.push({
          name: "mainpage",
        });
      }
    },
  }
}
</script>

<style scoped>

</style>