<template>
  <v-app class="bg">
    <CmpControlTv
        :data_list="listactive"
        @clicked="getTvControlCmpCallback"
        :mode="'Done'"
    ></CmpControlTv>
  </v-app>
</template>
<script>
import CmpControlTv from "../../CmpControlTv";
import {BetweenStr, ErrorToastMsg, SucessToastMsg, ToastCustom} from "../../../../../public/assets/js/utils/util";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;
var showToast;
var timer;

export default {
  components: {
    CmpControlTv,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {}
  },
  data() {
    return {
      isActive: true,
      data_info: [this.serial_number, this.count, this.home_id],
      learned: '',
      listactive: [
        {
          id: 0,
          active: false,
          code: ''
        },
        {
          id: 1,
          active: false,
          code: ''
        },
        {
          id: 2,
          active: false,
          code: ''
        },
        {
          id: 3,
          active: false,
          code: ''
        },
        {
          id: 4,
          active: false,
          code: ''
        },
        {
          id: 5,
          active: false,
          code: ''
        },
        {
          id: 6,
          active: false,
          code: ''
        },
        {
          id: 7,
          active: false,
          code: ''
        },
        {
          id: 8,
          active: false,
          code: ''
        },
        {
          id: 9,
          active: false,
          code: ''
        },
        {
          id: 10,
          active: false,
          code: ''
        },
        {
          id: 11,
          active: false,
          code: ''
        },
        {
          id: 12,
          active: false,
          code: ''
        },
        {
          id: 13,
          active: false,
          code: ''
        },
        {
          id: 14,
          active: false,
          code: ''
        },
        {
          id: 15,
          active: false,
          code: ''
        },
      ],
      user_id: '',
      token: ''
    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          clearTimeout(timer);
          connection = true;
          this.exitModalLoading();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2.5');
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoIrControls");
    this.$store.dispatch("infoHome");
    this.onSubscribe();
    setTimeout(function () {
      self.lastStatus();
    }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    Done(key) {
      var message = getCommand.getTimerCommand(this.count, key);
      console.log(message);
      localStorage.setItem("code", message);
      this.$router.push({
        name: 'IRTimerStep2',
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          count: this.count,
          type_code: 1001
        }
      });
      // --------------------Requsest----------------------//
    },
    unActive() {
      ToastCustom.custom(this.$t('message.irAmplifier.config'), "#fd7e14");
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "IRTimerList",
          params: {serial_number: this.serial_number, home_id: this.home_id},
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      var done = msg.includes("DONE");
      var del = msg.includes("DELETEOK");
      var msg1 = BetweenStr.getBetweenStr(msg, "CONTROL", "-KEY");
      if (done) {
        if (msg1[1] == this.count) {
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
      if (del) {
        SucessToastMsg.Msg();
        this.$router.replace({
          name: "irGatewayInfo",
          params: {serial_number: this.serial_number, count: this.count},
        });
      }
    },
    lastStatus() {
      let self = this;
      let uid = this.$store.getters.getUIDIrControl;
      // ------------------Data--------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        control_uid: uid,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.controlInfo;

      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              var control = jsonArray.content.control[0]

              self.title = control.name
              let key_info = control.key_info;
              self.learned = control.learned;
              self.exitModalLoading();

              key_info.forEach(function (item) {

                var key = item.key_count;
                for (let i = 0; i <= 15; i++) {
                  if (i == key) {
                    self.listactive[i].active = true;

                    if (item.key_count !== null) {
                      self.listactive[i].code = item.key_count;
                    }
                  }
                }
              });

            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    getTvControlCmpCallback(msg) {

      if (msg == '' && msg != '0') {
        this.unActive();
      } else {
        this.Done(msg);
      }
    },
    getNameCmpCallback(msg) {
      let self = this
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        name: msg,
        control_uid: uid,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.controlEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.lastStatus();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic1);
    }
  },
};
</script>
<style scoped>

</style>
