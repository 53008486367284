<template>
  <v-app class="bg">
    <div class="mt-5">
      <section class="content">
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickEditName()"
          >
            <i class="mdi mdi-border-color fa-2x"></i>
            <div class="media-body" >
              <p>{{ $t('message.CmpEditName.edit_name') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickTimer()">
            <i class="mdi mdi-clock fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.AppBar.scheduler') }}</p>
            </div>
          </div> 
          <div
              class="media align-items-center setting"
             v-on:click.prevent="ClickUpdate()"
          >
            <i class="mdi mdi-update fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.refresh_device') }}</p>
            </div>
          </div> 
          <!-- <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickAssistant()"
          >
            <i class="mdi mdi-microphone fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.assistant') }} </p>
            </div>
          </div>  -->
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickManage()"
          >
            <i class="mdi mdi-account-settings mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.client.management') }}</p>
            </div>
          </div>
          <div>
            <div v-if="!set_password"
              class="media align-items-center setting"
              v-on:click.prevent="ClickSetPinCode()"
          >
          <i class="mdi mdi-delete-forever fa-2x"></i>
            <div class="media-body">
              <!-- <p>{{ $t('message.public.del_hardware') }}</p> -->
              <p>ست کردن پین کد</p>
            </div>
          </div>
          <div v-else
              class="media align-items-center setting"
              v-on:click.prevent="ClickChangePinCode()"
          >
          <i class="mdi mdi-delete-forever fa-2x"></i>
            <div class="media-body">
              <!-- <p>{{ $t('message.public.del_hardware') }}</p> -->
              <p>تغییر پین کد</p>
            </div>
          </div>
          </div>
          <div 
              class="media align-items-center setting"
              v-on:click.prevent="ClickStatusPinCode()"
          >
          <i class="mdi mdi-delete-forever fa-2x"></i>
            <div class="media-body">
              <!-- <p>{{ $t('message.public.del_hardware') }}</p> -->
              <p>وضعیت پین کد / <b v-if="set_password">فعال</b><b v-if="!set_password">غیر فعال</b></p>
            </div>
          </div>
          <div 
              class="media align-items-center setting"
              v-on:click.prevent="ClickDelete()"
          >
            <i class="mdi mdi-delete fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.delete') }}</p>
            </div>
          </div>
          <div 
              class="media align-items-center setting"
              v-on:click.prevent="ClickResetFactory()"
          >
          <i class="mdi mdi-delete-forever fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.public.del_hardware') }}</p>
            </div>
          </div>
          <div 
              class="media align-items-center setting"
              v-on:click.prevent="ClickAbout()"
          >
          <i class="mdi mdi-information-outline fa-2x"></i>
            <div class="media-body">
              <p>{{ $t("message.AppBar.about_device") }}</p>
            </div>
          </div>
        </div>
      </section>    
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <EditName v-bind:data_name="name" @clicked="getNameCmpCallback"></EditName>
    <!-- <DialogImg
          v-model="showDialogImg"
          :data="logos"
          @clicked="CmpCallImg"
      /> -->
      <DialogPass v-model="showDialogPass" :dialogtxt="dialogPassTxt" @clicked="CallSetPass">
            <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.ModalAddHome.password_hint')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="set_pincode"
                  maxlength="4"
                  type='number'
                >
                </v-text-field>
                <br>
                <v-text-field
                  background-color="#f5f5f5"
                  :placeholder=" $t('message.ModalAddHome.password_repeat_hint')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="set_pincode_repeat"
                  maxlength="4"
                  type='number'
                >
                </v-text-field>
    </DialogPass>
    <DialogPass v-model="showDialogStatusPass" :dialogtxt="dialogPassTxt" @clicked="CallStatusPass">
            <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.ModalAddHome.password_hint')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="pass_status"
                  maxlength="4"
                  type='number'
                >
                </v-text-field>
    </DialogPass>
    <DialogPass v-model="showDialogChangePass" :dialogtxt="dialogPassTxt" @clicked="CallChangePass">
            <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.setting.oldPIN')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="old_pass"
                  maxlength="4"
                  type='number'
                >
                </v-text-field>
                <br>
                <v-text-field
                  background-color="#f5f5f5"
                  :placeholder=" $t('message.setting.new_pincode')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="new_pass"
                  maxlength="4"
                  type='number'
                >
                </v-text-field>
    </DialogPass>
    <ModalResetFactoryNThermostat
          :infoItem="dataReset"></ModalResetFactoryNThermostat>
         
         </v-app>
</template>

<script>

import {getTopics} from "@/../public/assets/js/utils/topics";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {getMessageSub} from "@/../public/assets/js/utils/helper_sub_message.js";
import {passHomeValidationInput, passRepeatHomeValidationInput,passCompareValidationInput} from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../layouts/LayoutDefault";
import {SucessToastMsg,ErrorToastMsg,BetweenStr,} from "@/../public/assets/js/utils/util.js";
import EditName from "@/components/Cmp/EditName.vue";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import ModalResetFactoryNThermostat from "@/components/nonehome/thermostat/ModalResetFactoryNThermostat";
import DialogPass from "@/components/Cmp/DialogPass.vue";
import CmpPasscode from "@/components/Cmp/general/CmpPasscode";
import DialogImg from "@/components/Cmp/DialogImg.vue";

// import ModalNoneMomentray from "@/components/nonehome/ModalNoneMomentray.vue";

var connection = false;
var timeout;

export default {
  components: {
    ModalResetFactoryNThermostat,
    EditName,
    DialogPass,
    // ModalNoneMomentray,
    CmpPasscode,
    DialogImg
  },
  props: {
    serial_number: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/256/update"(data, topic) {
      this.connection = true;
      if (topic.split("/")[1] === this.serial_number) {
        this.connection = true;
        this.showGetMsg(String(data))
      }
     
    },
    "+/+/receive/+/setting"(data, topic) {
      if (
        topic.split("/")[1] === this.serial_number &&
        topic.split("/")[3] === '256'
      ) {
        if (topic.split("/")[4] === "setting") {
          // console.log("data setting",String(data))
          connection = true;
          clearTimeout(timeout);
           this.showGetMsg(String(data))
           this.exitModalLoading();
            SucessToastMsg.Msg();
     
        }
      }
    },
  },
  data() {
    return {
      info: '',
      // serialnumber: this.serial_number,
      title: '',
      name: '',
      user_id:"",
      token:"",
      isModalVisible: false,
      dataReset:[this.serial_number],
      CmpResetShow: false,
      type:'',
      type_code:'',
      connection:false,
      delSoft:false,
      delHard:false,
      loading:false,
      set_password:"",
      showDialogPass:false,
      showDialogStatusPass:false,
      showDialogChangePass:false,
      dialogPassTxt:[],
      old_pass:"",
      new_pass:"",
      set_pincode:"",
      set_pincode_repeat:"",
      pass_status:"",
      //نوع دستور تنظیمات تایمر یا 
      type_item_setting:"null",
      // showDialogImg: false,
      logos: [],
    };
  },
  created() {},
  mounted() {
    let self=this;
    let data = {
      title: this.$t('message.public.settings'),
      noneHome: true,
    }
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'settingnonetouchkey1');
    this.CmpResetShow = true;
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");

   this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
   this.type_code=this.info.type_code;
   this.set_password=this.info.set_password
  //  console.log("info",this.info);
   this.name =  this.info.name;


    this.onSubscribe();
   
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    CmpCallImg(info) {
      // this.type_relay = info;
      this.showDialog = true;
    },
    ClickEditName() {
      // $("#modal-edit-name").modal("show");
      this.$router.push({
        name: "NThermostatEdit",
        params: {serial_number: this.serial_number},
      }); 
    },
    initDilaogSetPass(){
      this.dialogPassTxt.push({
      header: 'ست کردن پین کد',
      caption: "برای امنیت بیشتر می توانید پین کد 4  رقمی وارد نمایید.",
     
    })
    },
    initDilaogStatusPass(){
      this.dialogPassTxt.push({
      header: 'تغییر وضعیت پین کد',
      caption: "برای تغییر وضعیت پین کد 4  رقمی وارد نمایید.",
     
    })
    },
    initDilaogChangePass(){
      this.dialogPassTxt.push({
      header: 'تغییر پین کد',
      caption: "برای تغییر پین کد اطلاعات زیر را وارد نمایید.",
     
    })
    },
    ClickTimer() {
        this.$router.push({
          name: "NThermostatTimerShow",
          params: {serial_number: this.serial_number},
        }); 
    },
    ClickManage() {
      localStorage.setItem("type", this.type_code)
        this.$router.push({
          name: "noneHomeClientInfo",
          params: {serial_number: this.serial_number,type: this.type_code},
        });
    },
    ClickSetPinCode() {
      this.initDilaogSetPass();
      this.showDialogPass=true
    },
    ClickStatusPinCode() {
      this.initDilaogStatusPass();
      this.showDialogStatusPass=true;
    },
    ClickChangePinCode() {
      this.initDilaogChangePass();
      this.showDialogChangePass=true;
    },
    ClickResetFactory() {
      this.CmpResetShow = true;
      $("#modal-reset-n-thermostat").modal('show');
    },
    ClickUpdate(){
      this.showUpdateDialog();
    },
    ClickAbout(){
      this.$router.push({
          name: "NThermostatAbout",
          params: {serial_number: this.serial_number},
        });
    },
    ClickDelete() {
      this.delSoft=true;
      this.showDelDialog();
    },
   
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
             if (this.set_password == 1) {
              // this.type_item_setting="update"
               $("#modal-pass-code").modal("show");
             } else {
              this.sendRequest('UPDATE#','update');
            }
          }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    showDelDialog() {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.dialog.del_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              // this.onSubscribe();
              if (this.set_password == 1) {
               $("#modal-pass-code").modal("show");
             } else {
              this.sendRequest('RESET','reset');
            } 
           }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    CallSetPass(value){
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
        password:this.set_pincode,
       
      };
      if (
        !passHomeValidationInput(this.set_pincode) ||
        !passRepeatHomeValidationInput(this.set_pincode_repeat) || !passCompareValidationInput(this.set_pincode,this.set_pincode_repeat)
      )
      return
      
      this.sendRequestPinCode(getUrl.noneSetPin,data)
    },
    sendReq(password) {
      // console.log("password",password);
      // console.log("this.type_item_setting",this.type_item_setting)
      // if(this.type_item_setting=="instant"){
      //   this.sendSettings(password);
      // }else{
      //   console.log("ELSE")
        this.sendRequest(password,'reset');
      // }
      
    },
    CallStatusPass(value){
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
        password:this.pass_status,
       
      };
      if (!passHomeValidationInput(this.pass_status))
      return
      this.sendRequestPinCode(getUrl.noneStatusPin,data)

    },
    CallChangePass(value){
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
        old_password:this.old_pass,
        new_password:this.new_pass
      };
      if (
        !passHomeValidationInput(this.old_pass) ||
        !passRepeatHomeValidationInput(this.new_pass) || !passCompareValidationInput(this.old_pass,this.new_pass)
      )
      return
      this.sendRequestPinCode(getUrl.noneChangePin,data)

    },
    onSubscribe() {
      var topic1 = getTopics.getTopicResetReceive(
          this.serial_number,
          256
      );
      var topic2 = getTopics.getTopicUpdateReceive(
          this.serial_number,
          256
      );
      var topic3 = getTopics.getTopicAssistantReceive(
          this.serial_number,
          256
      );
      var topic4 = getTopics.getTopicSettingReceive(
          this.serial_number,
          256
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
      this.$mqtt.subscribe(topic3);
      this.$mqtt.subscribe(topic4);

      // console.log("topic2",topic2)
    },
    sendRequestPinCode(url,data) {
      let self = this;
      // --------------------Requsest----------------------//
      let URL = url;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.exitModalLoading ();
                self.$router.push({
                  name: "mainpage"
                })
              }, 500);
            } else {
              self.exitModalLoading ();
              //error
            }
          },
          function (errr) {
            //errr
          }
      );
    },
    sendRequest(saction,type) {
      // console.log('sendRequest',saction)
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
     
      var topic_final ='';
      if(type=='reset'){
        topic_final=getTopics.getTopicResetSend(
          this.serial_number,
          256
      );
      }
      if(type=='update'){
        this.loading = true;
        topic_final=getTopics.getTopicUpdateSend(
          this.serial_number,
          256
      );
      }
      if(type=='assistant'){
        this.openModalLoading();
        topic_final=getTopics.getTopicAssistantSend(
          this.serial_number,
          256
      );
      }
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if(self.delSoft){
              // self.exitModalLoading();
              self.delServiceDevice()
            }else{
              setTimeout(function () {
                if (!self.connection) {
                    ErrorToastMsg.Msg();
                    self.loading=false;
                    self.exitModalLoading ();
                } else {
                  self.exitModalLoading ();
                }
                self.connection = false;
              }, 3000);
            }
          },
          function (errr) {
            //errr
          }
      );
    },
    delServiceDevice() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      this.openModalLoading()
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneDeviceDel;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.exitModalLoading ();
                self.$router.push({
                  name: "mainpage"
                })
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading () {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == 'back') {
          this.$router.push({name: "NThermostatInfo", params: {serial_number: this.serial_number},}); 
      }
    },
    getNameCmpCallback(name_value) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        name: name_value,
        serial_number: this.serial_number,
      };
      // console.log('data',data)
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.deviceEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.$router.push({
                name: "homeDevices",
                params: {
                  serial_number: self.serial_number,
                  home_id: self.home_id,
                },
              }); 
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    showGetMsg(msg) {
      // console.log("msg mqtt",msg.startsWith("AUTO"))
      let self = this;
      this.loading=false
      if(msg=="UPDATEOK"){
        swal({
        // title: self.$t('message.timer.timer'),
        title:this.$t("message.public.refresh"),
        text: this.$t("message.public.update_done"),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      }
      else if(msg=="RESET"){

      }
      // else if(msg.startsWith("AUTO")){
      //   console.log("msg.startsWith ",msg.startsWith("AUTO"))
      //    $("#modal-outlet-momentray-none").modal("hide");
      //     this.$router.push({
      //             name: "mainpage",
      //             });
      // }
//       else if(msg=="ASSISTANTOK"){
//      swal({
//         // title: self.$t('message.timer.timer'),
//         title:this.$t("message.public.assistant"),
//         text: this.$t("message.public.order"),
//         type: "success",
//         timer: 3000,
//         showConfirmButton: false,
//       });
// }
      
    },

  },
};
</script>
<style scoped>
</style>
