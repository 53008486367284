<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content mt-3">
        <div class="row px-5" v-bind:key="index" v-for="(infos, index) in info">
          <div class="col-12 col-lg-12 py-0 mt-3">
            <div class="box">
              <div class="box-body">
                <div class="navbar-custom-menu" id="setting_curtain">

                </div>
                <table class="table borderless" role="grid">
                  <tbody>
                  <tr role="row">
                    <td class="sorting_1">
                      <span class="label label-success">
                        {{ $t('message.public.manager') }}
                      </span>
                      <strong class="px-2"> {{ infos.user.fname }} {{ infos.user.lname }}</strong>
                    </td>

                    <td></td>
                    <td>
                      <div v-if="userphone==infos.mobile">
                      </div>
                      <div v-else>
                        <ul class="box-controls pull-right">
                          <li class="dropdown">
                            <a data-toggle="dropdown" href="#" aria-expanded="false"
                            ><i class="ti-more-alt rotate-90"></i
                            ></a>
                            <div
                                class="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                                style="
                  position: absolute;
                  transform: translate3d(-43px, 21px, 0px);
                  top: 0px;
                  left: 50px;
                  will-change: transform;
                "
                            >
                              <a class="dropdown-item" v-on:click.prevent="delClient(infos.user.mobile)">
                                <i class="mdi mdi-delete"></i>{{ $t('message.public.delete') }}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";
export default {
  name: "noneHomeClient",
  components: {},
  props: {
    serial_number: {},
    type: {},
    click: {}
  },
  data() {
    return {
      info: "",
      userphone: '',
      title: '',
      admin_main: '',
      lang: '',
      isModalVisible: false,
      type_code:'',
    };
  },
  created() {
    this.title = this.$t('message.client.management');
    this.lang = localStorage.getItem("Lang");
    let details = {
      title: this.title,
      add: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneClient');
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoUser");
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    this.type_code=this.type
    // this.admin_main = this.$store.getters.getadmin;
    var data = {
      user_id: user_id,
      token: token,
      serial_number: this.serial_number,
    };
    let URL = getUrl.clients;
    const p = postAjaxCall(URL, data, "");
    this.openModalLoading();
    p.then(function (response) {
      var jsonArray = JSON.parse(response.responseText);

      if (responseAjax(response)) {
        self.exitModalLoading();
        self.info = jsonArray.content.clients;
        // self.userphone = this.$store.getters.getUserPhone;
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({name: "home"});
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    addUser() {
      this.$router.push({
        name: "noneAddClient",
        params: {
          serial_number: this.serial_number,
          type: this.type_code
        },
      });
    },
    delClient(mobile) {
      //  this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "noneDelClient",
        params: {
          serial_number: this.serial_number,
          type: this.type_code
        },
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value === "add") {
        this.$router.push({
          name: "noneAddClient",
          params: {
            serial_number: this.serial_number,
            type: this.type_code
          },
        });
      }
      if (value === "back") {
        if (localStorage.getItem("type"))
          this.type_code = localStorage.getItem("type")
        if (this.type_code == 7) {
          this.$router.push({
            name: "SettingsNone",
            params: {
              serial_number: this.serial_number,
            },
          });
        } else if (this.type_code == 8) {
          this.$router.push({
            name: "SettingsNoneIr",
            params: {
              serial_number: this.serial_number,
            },
          });
        } else if (this.type_code == 16) {
          this.$router.push({
            name: "SettingNone",
            params: {
              serial_number: this.serial_number,
              type_code: 16
            },
          });
        } else if (this.type_code == 19) {
          this.$router.push({
            name: "SettingNoneTouchKey",
            params: {
              serial_number: this.serial_number,
              type_code: 19
            },
          });
        }
        else if (this.type_code == 25) {
          this.$router.push({
            name: "SettingNoneN2Relay",
            params: {
              serial_number: this.serial_number,
              type_code: 25
            },
          });
        }
        else if (this.type_code == 26) {
          this.$router.push({
            name: "SettingNoneCurtain",
            params: {
              serial_number: this.serial_number,
              type_code: 26
            },
          });
        }
        else if (this.type_code == 27) {
          this.$router.push({
            name: "SettingNoneCooler",
            params: {
              serial_number: this.serial_number,
              type_code: 27
            },
          });
        }
        else if (this.type_code == 28) {
          this.$router.push({
            name: "SettingNoneService",
            params: {
              serial_number: this.serial_number,
              type_code: 28
            },
          });
        }
        else if (this.type_code == 29) {
          this.$router.push({
            name: "SettingNoneThermostat",
            params: {
              serial_number: this.serial_number,
              type_code: 28
            },
          });
          
        }
        else if (this.type_code == 37) {
          this.$router.push({
            name: "SettingNoneThermostatic",
            params: {
              serial_number: this.serial_number,
              type_code: 28
            },
          });
          
        }
      }
    },
  },
};
</script>
<style scoped>

</style>