<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-add-ir_control"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <!-- .header -->
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <!-- ./header -->
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper bg-white mr-0">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div style="text-align: center; margin-top: 10px">
                    <div class="fontawesome-icon-list m-0">
                      <img
                          src="/assets/images/smart/vector-addsub.svg"
                          class="avatar-smart"
                      />
                      <h5 class="font-weight-bold">
                        {{ $t('message.public.submit') + " " + $t('message.irAir.control') }}
                      </h5>
                    </div>
                  </div>
                  <br/>
                  <form class="px-40">
                    <div class="form-group">
                      <h6>{{ $t('message.ir.name') }} <span class="text-danger">*</span></h6>
                      <input
                          id="inputcontrolname"
                          type="text"
                          class="form-control"
                          aria-invalid="false"
                          autocomplete="off"
                          maxlength="32"
                          :placeholder="$t('message.ir.hintName')"
                          v-model="nameircontrol"
                      />
                    </div>

                    <div class="row">
                      <!-- /.col -->
                      <div class="col-12 text-center">
                        <v-btn
                            class="btn btn-info btn-block"
                            :loading="loading"
                            :disabled="loading"
                            color="bg-primary"
                            @click="addIrControl()"
                        >
                          <span class="text-white"> {{ $t('message.public.submit') }} </span>
                        </v-btn>
                      </div>
                      <!-- /.col -->
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
        <!-- /. Main content -->

        <div
            class="modal center-modal fade"
            id="modal-center"
            tabindex="-1"
            data-keyboard="false"
            data-backdrop="static"
        >
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
                </div>
                <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.Content Wrapper. Contains page content -->
    </div>
    <!-- /.modal Add Home-->
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    AppBar,
  },
  props: {
    data_infos: {type: Array},
  },
  data() {
    return {
      nameircontrol: "",
      title: '',
      loading: false,

    };
  },

  mounted() {
    this.title=this.$t('message.ir.add');
  },
  methods: {
    addIrControl() {
      let self = this;
      var name = this.nameircontrol;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.data_infos[0];
      var home_id = this.data_infos[1];
      var control_uid = this.data_infos[2];

      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        control_uid: control_uid,
        serial_number: serial_number,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.controlAdd;
      const p = postAjaxCall(URL, data, "");
      // setTimeout(function(){   $("#modal-center").modal('show');  }, 50);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
               $("#modal-add-ir_control").modal("hide");
              self.$router.push({
                name: "irGatewayInfo",
                params: {home_id: home_id, serial_number: serial_number},
              });
              self.nameircontrol = "";
            } else {
              //error
              self.loading = false
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.nameircontrol = "";
        $("#modal-add-ir_control").modal("hide");
      }
    },
  },
};
</script>


<style scoped>
</style>
