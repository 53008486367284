<template>
  <div style="width: 95%;">
    <div :class="[isAlert ? alertClass : '', alertColor]" class="p-2 m-0 pb-0">
      <div class="d-flex p-3">
        <div class="d-flex px-5" style="width: 72%">
          <i class="mdi mdi-clock mdi-18px" style="cursor: pointer;" @click="$emit('refresh')"></i>
          <p class="pt-1 px-1" style="cursor: pointer;" @click="$emit('refresh')">{{$t('message.timer.Time')}}</p>
        </div>
        <div class="d-flex pt-1">
          <label id="lblaccountlogin" class="align-self-center font-size-14">
            {{ data_txt }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data_txt: String,
    data_type: String,
  },
  data() {
    return {
      isAlert: true,
      alertClass: 'alert',
      alertColor:''
    };
  },
  created(){
    if(this.data_type=='default'){this.alertColor="alert-secondary"}
    if(this.data_type=='danger'){this.alertColor="alert-danger"}
    if(this.data_type=='success'){this.alertColor="alert-success"}
    if(this.data_type=='info'){this.alertColor="alert-info"}
    if(this.data_type=='warning '){this.alertColor="alert-warning"}
  },

};

</script>
<style  scoped>
</style>
