<template>
  <v-app class="bg">
    <v-row style="width: 100%; place-content: center" class="d-flex align-self-center">
      <v-col cols="6" class="d-flex flex-column" style="place-items: center">
        <img
            src="/assets/images/smart/vector-edit-name.svg"
            class="avatar-smart"
        />
        <h5 class="font-weight-bold">
          {{ $t('message.profile.edit') }}
        </h5>
        <!--         <h6 class="mt-5 p-10">   </h6> -->
      </v-col>
      <br/>
      <v-col cols="12">
        <form novalidate style="background-color: white; padding:10px; border-radius: 10px">
          <div class="form-group">
            <h6>{{ $t('message.ModalAddSubDevicePol.name') }}<span class="text-danger">*</span></h6>
            <input
                type="text"
                class="form-control"
                v-model="name"
                aria-invalid="false"
                maxlength="32"
                autocomplete="off"
                id="inputeditnamesub"
            />
          </div>
          <div v-if="active">
            <div class="form-group p-1" v-for="(name,index) in labels" :key="index">
              <div class="d-flex">
                <div @click="onColor(index)"
                     :style="{'width': '40px', 'height': '40px','padding': '10px','background-color': data.onColors[index]}">
                </div>
                <label
                    type="text"
                    class="form-control"
                    aria-invalid="false"
                    style="margin-left: 10px; margin-right: 10px"
                >
                  {{ labels[index] }}
                </label>
                <div @click="offColor(index)"
                     :style="{'width': '40px', 'height': '40px','padding': '10px','background-color': data.offColors[index]}">
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
          <!-- /.col -->
        </form>
      </v-col>
      <v-col
          cols="12"
          class="fixed-bottom d-flex flex-column justify-content-center align-center"
      >
        <div class="col-11 text-center ">
          <v-btn
              class="btn btn-info btn-block"
              :loading="loading"
              :disabled="loading"
              color="bg-primary"
              @click="checkPass()"
          >
            <span class="text-white"> {{ $t('message.public.submit') }} </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="dialog">
      <v-dialog
          class="bg"
          v-model="dialog"
          persistent
          width="300px"
          transition="dialog-bottom-transition"
      >
        <v-card class="d-flex flex-column">
          <v-card-title>
            color pallet
          </v-card-title>
          <v-color-picker justify="center" v-model="color"></v-color-picker>
          <v-card-actions>
            <v-btn color="primary" text @click="submitColor">
              <span class="text-primary">{{ $t("message.public.OK") }}</span>
            </v-btn>
            <v-btn color="primary" text @click="dialog = false">
              <span class="text-primary">{{ $t("message.public.close") }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {hexToRgb, rgbToHex} from "../../../../public/assets/js/utils/colorRGB";
import {nameDeviceValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTopics} from "@/../public/assets/js/utils/topics";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import {BetweenStr, ErrorToastMsg} from "../../../../public/assets/js/utils/util";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";


var connection = false;
var showToast;
var timeout;

export default {
  name: "editRGB",
  components: {CmpPasscode},
  props: {
    serial_number: {},
    count: {},
    home_id: {},
    type: {},
    active: {},
    click: {}
  },
  data() {
    return {
      title: this.$t('message.public.edit'),
      loading: false,
      labels: [this.$t('message.touch_key.key'), this.$t('message.touch_key.music'), this.$t('message.touch_key.fan'),
        this.$t('message.touch_key.spray'),],
      data: {
        onColors: [],
        offColors: []
      },
      color: '',
      name: "",
      dialog: false,
      target: [],
      set_password: false,
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          // clearTimeout(timeout);
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  created() {
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'EditDevice');
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.name = this.$store.getters.getNameSub
    let data = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.set_password = data.set_password;
  },
  mounted() {
    let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
    this.data = localStorage.getItem("data_color" + subdevice_serial) ?
        JSON.parse(localStorage.getItem("data_color" + subdevice_serial)) : {
          onColors: ['#00ff00', '#00ff00', '#00ff00', '#00ff00'],
          offColors: ['#ff0000', '#ff0000', '#ff0000', '#ff0000'],
        };
    let topic2 = getTopics.getTopicToggleReceive(
        this.serial_number,
        subdevice_serial
    )
    this.$mqtt.subscribe(topic2);
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubServiceInfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    sendReq(password) {
      this.myFunction(password);
    },
    checkPass() {
      if (this.set_password) {
        $("#modal-pass-code").modal("show");
      } else {
        this.myFunction('');
      }
    },
    async myFunction(password) {
      if (await this.sendName() && this.active) {
        await this.sendColor(password)
      } else {
        await this.$router.push({
          name: "homeDevices",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    },
    async sendColor(password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let self = this;
      let message = "L1ON" + this.data.onColors[0].replace('#', '') +
          "L1OFF" + this.data.offColors[0].replace('#', '') + "L2ON" +
          this.data.onColors[1].replace('#', '') + "L2OFF" +
          this.data.offColors[1].replace('#', '') + "L3ON" +
          this.data.onColors[2].replace('#', '') + "L3OFF" +
          this.data.offColors[2].replace('#', '') + "L4ON" +
          this.data.onColors[3].replace('#', '') + "L4OFF" +
          this.data.offColors[3].replace('#', '');


      var topic_final = getTopics.getTopicToggleSend(
          this.serial_number,
          subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      return p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              //  connection=false;
              self.openModalLoading();
              setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1)
                    ErrorToastMsg.Msg();
                } else
                  self.$router.push({
                    name: "SubServiceInfo",
                    params: {
                      home_id: self.home_id,
                      serial_number: self.serial_number,
                      count: self.count,
                    },
                  });
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    async sendName() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (!nameDeviceValidationInput(this.name)) return;
      this.loading = true;
      let data = {
        user_id: user_id,
        token: token,
        name: this.name,
        serial_number: subdevice_serial,
      };
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data);
      return p.then(
          function (response) {
            if (responseAjax(response)) {
              self.loading = false
              return new Promise(resolve => {
                resolve(true)
              })
              //  location.reload();
            } else {
              return new Promise(resolve => {
                resolve(false)
              })
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            return new Promise(resolve => {
              resolve(false)
            })
          }
      );
    },
    onColor(index) {
      this.color = this.data.onColors[index]
      this.target = [index, "on"]
      this.dialog = true;
      let rgb = hexToRgb(this.color)
      let hex = rgbToHex(rgb[0], rgb[1], rgb[2])
    }
    ,
    offColor(index) {
      this.color = this.data.offColors[index]
      this.target = [index, "off"]
      this.dialog = true;
    }
    ,
    submitColor() {
      this.dialog = false;
      if (this.target[1] === "on") {
        this.data.onColors[this.target[0]] = this.color
      } else {
        this.data.offColors[this.target[0]] = this.color
      }
    }
    ,
  }
}
</script>

<style scoped>

</style>