<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="box-body chart-responsive">
        <div style="height: 812px"></div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

 <script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
   components:{},
  data: function () {
    return {
      title: '',
      isModalVisible: false
    };
  },
 props: {
    serial_number:{},
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    this.name = this.$store.getters.getNameNoneHome;
    let details = {
      title: this.$t('message.noneHome.clock'),
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneIrClock');
  },
  mounted() {
    let self=this;
    this.openModalLoading();
    setTimeout(function(){   self.sendClock(); }, 500); 
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendClock(){
      let self=this;
    var topic_final;
    let user_id = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");
    let device_serial = this.serial_number;


    var tapp = localStorage.getItem("t_app");
    var year = parseInt(new Date().getFullYear().toString().substr(2, 2));
    var month = parseInt(new Date().getMonth() + 1);
    var date = parseInt(new Date().getDate());
    var d = parseInt(new Date().getDay());
    var hours = parseInt(new Date().getHours());
    var minute = parseInt(new Date().getMinutes());
    var second = parseInt(new Date().getSeconds());

    var formattedmonth = ("0" + month).slice(-2);
    var formatteddate = ("0" + date).slice(-2);
    var formattedhours = ("0" + hours).slice(-2);
    var formattedminute = ("0" + minute).slice(-2);
    var formattedsecond = ("0" + second).slice(-2);
    if (d == 0) {
      d = d + 7;
    }

    let datefinal = year + "/" + formattedmonth + "/" + formatteddate + "/" + d;
    let timefinal =
      formattedhours + ":" + formattedminute + ":" + formattedsecond;

    let saction = "CLOCK" + datefinal + "," + timefinal + "#";

      var topic_final = getTopics.getTopicClockSend(device_serial, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
     
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          setTimeout(function () {
            self.exitModalLoading();
           
             self.$router.push({name: "SettingsNoneIr", params: { serial_number: self.serial_number  },
      });
          }, 3000);
        swal({   
            title: self.$t('message.noneHome.clock'),
            text: self.$t('message.noneHome.clock_ok'),
            type: "success",  
            timer: 2000,   
            showConfirmButton: false 
        });
        
        },
        function (errr) {
          
        }
      ); 
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
     onClickChild(value) {
      if (value == "back") {
      this.$router.push({name: "SettingsNoneIr", params: { serial_number: this.serial_number  },});

      }
    },
  },
};
</script>



<style scoped>
</style>
