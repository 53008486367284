<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!-- Content Wrapper. Contains page content -->
    <Step2Timer :end="true" :daysData="daysdata" @myFunction="MyFunction"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import {Tools} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import AppBar from "@/components/AppBar.vue";
import Step2Timer from "@/components/Cmp/timer/create/Step2Timer.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {DayValidationInput, TimerValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {listOfDays} from "../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode"
import LayoutDefault from "../../../layouts/LayoutDefault";

var showToast;
var connection = false;

export default {
  components: {
    Step2Timer,
    CmpPasscode,
  },
  data() {
    return {
      title: '',
      lang: '',
      daysdata: [],
      temparray: [],
      selectedday: "",
      days: [],
      timer_count: "",
      time1: '',
      time2: '',
      isModalVisible: false,
      set_password: '',
    };
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.daysdata = listOfDays();
    this.getCount();
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "timer") {
          connection = true;
          // console.log(" mqtt")
          // console.log("connection mqtt",connection)
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
            Tools.sweetAlert(this.$t('message.timer.timer'), this.$t('message.timer.added'), 'success', 3000)
          }
        }
      }
    },
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler')
    this.$store.dispatch("statusTimer");
    this.set_password = JSON.parse(localStorage.getItem("infoDevice")).set_password;
    setTimeout(function () {
      self.getCount();
    }, 50);
    //.......... Sub
    var topic1 = getTopics.getTopicTimerReceive(this.serial_number, "256");
    // console.log("topic",topic1)
    this.$mqtt.subscribe(topic1);
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer3');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    MyFunction(days, time1, time2) {
      this.days = days;
      this.time1 = time1;
      this.time2 = time2;
      if (
          !TimerValidationInput(time1, time2) ||
          !DayValidationInput(days.length)
      )
        return;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.days, this.time1, this.time2, '');
      }
    },
    sendReq(password) {
      this.sendMessage(this.days, this.time1, this.time2, password);
    },
    sendMessage(days, time1, time2, password) {
      console.log('MyFunction')
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var msgday = this.getDaySelected(days);
      var timer_status = this.$store.getters.getTimerStatus;

      message =
          "TIMER" +
          this.timer_count +
          "-" +
          timer_status +
          "-" +
          msgday +
          time1 +
          "-" +
          time2 +
          "#";

      var topic_final = getTopics.getTopicTimerSend(this.serial_number, "256");

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.timer_count = jsonArray.content.timer_count;
              console.log("connection", connection)
              setTimeout(function () {
                if (connection) {
                  self.$router.push({
                    name: "OutletTimerShow",
                    params: {
                      home_id: self.home_id,
                      serial_number: self.serial_number,
                    },
                  });
                }
                if (!connection) {
                  self.exitModalLoading();

                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                    self.$router.push({
                      name: "OutletTimerShow",
                      params: {
                        home_id: self.home_id,
                        serial_number: self.serial_number,
                      },
                    });
                  }
                }
                showToast = 0;
              }, 3000);
            } else {
              self.exitModalLoading();
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getCount() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var tapp = localStorage.getItem("t_app");

      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.timerCount;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.timer_count = jsonArray.content.timer_count;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getDaySelected(days) {
      // console.log('getDaySelected')
      this.selectedday = "";
      var check = false;
      for (var i of this.daysdata) {
        this.temparray.push(i);
      }
      this.temparray.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);
      this.temparray.forEach((element) => {
        days.forEach((values) => {
          if (values.value === element.value) check = true;
        });
        if (check) this.selectedday += element.value + "1" + "-";
        else this.selectedday += element.value + "0" + "-";
        check = false;
      });
      // console.log("output", this.selectedday);
      return this.selectedday;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "outlettimer",
          params: {serial_number: this.serial_number, home_id: this.home_id},
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
