<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h5 class="text-bold">
            {{dialogtxt[0].header}}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align-self="center">
              <v-col cols="12" class="mb-2" align-self="center">
                <label class="mt-1 justify-center" style="font-size: 14px">
                 {{dialogtxt[0].caption}}
                </label>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <slot></slot>
            <!-- <v-row>
              <v-col cols="12 py-1" >
                <label class="text-primary">
                 {{dialogtxt[0].input1_title}}
                </label>
               
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 py-0">  
            <label class="text-primary"> {{dialogtxt[0].input2_title}} </label>
              
              </v-col>
            </v-row>
            <v-row>
              <br/>
              <v-alert color="red" type="error" class="mt-2" dense outlined v-if="showError">
                <span class="text-danger">{{ textError }}</span>
              </v-alert>
            </v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text-secondray" text @click="show = false">
             <label class="text-blue-gray"> {{ $t("message.public.cancel") }}</label>
          </v-btn>
          <v-btn color="bg-primary" @click="Submit()">
            <label class="text-white mt-1">
              {{ $t("message.public.submit") }}</label
            >
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import CmpTips from "../Cmp/public/CmpTips.vue";

export default {
  data() {
    return {
      items: [],
      showError: false,
      textError: "",
      infoDialog: {
        numbers:'',
        logo_path: "",
        secnario: "",
      },
      errName: false,
    };
  },
  components: {
    // CmpTips,
  },
  props: {
    value: Boolean,
    data: {},
    dialogtxt: [],
  },
  watch: {
    show(val) {
      if (val) {
      //  this.checkSelectedDataNumbers()
        // this.emptyData();
        // this.errName=false
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.errName = false;
  },
  methods: {
    Submit() {
      this.$emit("clicked", 'pass');
      // this.validSelect();
    },
    // validSelect() {
    //   console.log('err0',this.selectedScenario)
    //   console.log('err1',this.number)
    //     if (this.number==0 || this.selectedScenario==="") {
    //       console.log('err')
    //       this.showError = true;
    //       this.textError = this.$t("message.subDevice.scenarioKey.dialog.message_err");
    //     } else {
    //       console.log('ok')
    //       this.showError = false;
    //       this.sendData();
    //     }
    // },
    // sendData() {
    //   this.infoDialog.secnario = this.selectedScenario;
    //   this.infoDialog.numbers = this.number;
      
    //   this.$emit("clicked", this.infoDialog);
    //   this.show = false;
    // },
    // emptyData() {
    //   this.showError = false;
    //   // this.infoDialog.name = "";
    //   // this.name = "";
    //   this.selectedMode = this.$t("message.relay.relay_on_off");
    //   this.items = [];
    // },
 
 
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
.v-text-field input {
  font-family: "IRANSans";
  font-size: 16px !important;
}
</style>
