<template>
  <div>
    {{ value }}
    <label class="switch">
      <input
          type="checkbox"
          v-bind='$attrs'
          :checked='value'
          @change='$emit("input", $event.target.checked)'
      />
      <span class="switch-indicator" :for='id'></span>
    </label>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ['id', 'value'],
  mounted() {
  },
}
</script>
