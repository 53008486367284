<template>
  <v-app>
    <div class="mr-0">
      <div class="box-body chart-responsive">
        <div style="height: 812px"></div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  data: function () {
    return {
      isModalVisible: false
    };
  },
  props: {
    click: {},
    serial_number: {},
    type:{}
  },
  created() {
  },
  mounted() {
    let self = this;
    this.openModalLoading();
    setTimeout(function () {
      self.sendClock();
    }, 500);


  },
  methods: {

    sendClock() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var year = parseInt(new Date().getFullYear().toString().substr(2, 2));
      var month = parseInt(new Date().getMonth() + 1);
      var date = parseInt(new Date().getDate());
      var d = parseInt(new Date().getDay());
      var hours = parseInt(new Date().getHours());
      var minute = parseInt(new Date().getMinutes());
      var second = parseInt(new Date().getSeconds());

      var formattedmonth = ("0" + month).slice(-2);
      var formatteddate = ("0" + date).slice(-2);
      var formattedhours = ("0" + hours).slice(-2);
      var formattedminute = ("0" + minute).slice(-2);
      var formattedsecond = ("0" + second).slice(-2);
      if (d == 0) {
        d = d + 7;
      }

      let datefinal = year + "/" + formattedmonth + "/" + formatteddate + "/" + d;
      let timefinal =
          formattedhours + ":" + formattedminute + ":" + formattedsecond;

      let saction = "CLOCK" + datefinal + "," + timefinal + "#";

      var topic_final = getTopics.getTopicClockSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);

      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            setTimeout(function () {
              self.exitModalLoading();
                console.log("self.type",self.type)
              if (self.type == 19) {
                self.$router.push({
                  name: "SettingNoneTouchKey",
                  params: {serial_number: self.serial_number},
                });
              }else if(self.type == 25){
                self.$router.push({
                name: "N2RelayInfo", params: {serial_number: self.serial_number},
              });
              }
              else if(self.type == 26){
                self.$router.push({
                name: "NCurtainInfo", params: {serial_number: self.serial_number},
              });
              }
              else if(self.type == 27){
                self.$router.push({
                name: "NCoolerInfo", params: {serial_number: self.serial_number},
              });
              }
              else{
                self.$router.push({
                name: "SettingsNone", params: {serial_number: self.serial_number},
              });
              }
              
            }, 3000);
            swal({
              title: self.$t('message.irAir.settings'),
              text: self.$t('message.noneHome.clock_ok'),
              type: "success",
              timer: 2000,
              showConfirmButton: false
            });
          },
          function (errr) {

          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>


<style scoped>
</style>
