<template>
  <v-app class="bg">
    <div>
      <CmpTips :data_txt="tips_txt" :data_type="'default'"></CmpTips>
      <div v-if="type_code == '1001'">
        <CmpControlTv
            @clicked="getTvControlCmpCallback"
            :data_list="listactive"
            :command="'Test'"
        ></CmpControlTv>
      </div>
      <div v-if="type_code == '1002'">
        <CmpControlAmplifier
            @clicked="getAmpliControlCmpCallback"
            :data_list="listactive"
            :command="'Test'"
        ></CmpControlAmplifier>
      </div>
      <div v-if="type_code == '1003'">
        <CmpControlAir2
            :command="1"
            @clicked="getAirControlCmpCallback"
        ></CmpControlAir2>
      </div>

      <Modal
          v-show="isModalVisible"
          @close="closeModal"
          :data_txt="info_modal"
      >
      </Modal>

      <div class="row rounded-16 px-15 fixed-bottom">
        <div class="col-12 col-lg-12 px-15">
          <button
              type="button"
              class="btn btn-primary btn-lg btn-block mb-5 px-40"
              v-on:click.prevent="saveClick()"
          >
            {{ $t('message.public.submit') }}
          </button>
        </div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>
<script>
import CmpControlTv from "../../../ir/CmpControlTv.vue";
import CmpControlAmplifier from "../../../ir/CmpControlAmplifier.vue";
import CmpControlAir2 from "../../../ir/CmpControlAir2.vue";
import CmpTips from "../../../Cmp/public/CmpTips.vue";
import {ToastCustom} from "../../../../../public/assets/js/utils/util.js";
import Modal from "../../../Modal/Modal.vue";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command.js";
import {ErrorToastMsg, BetweenStr,} from "../../../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;
var showToast;
var timer;

export default {
  components: {
    CmpControlTv,
    CmpControlAmplifier,
    CmpControlAir2,
    CmpTips,
    Modal,
  },
  props: {
    data_infos: {type: Array},
    click: {},
    serial_number: {},
    type_code: {},
    uid: {},
  },
  data() {
    return {
      data_info: [this.serial_number, this.type_code, this.uid],
      title: "",
      info_modal: {
        title: this.$t('message.irTv.send'),
        subtile: this.$t('message.ModalCenter.wait'),
        msg: this.$t('message.irTv.long'),
        icon: "",
      },
      found_key: false,
      listactive: [
        {
          id: 0,
          active: false,
          code: "",
        },
        {
          id: 1,
          active: false,
          code: "",
        },
        {
          id: 2,
          active: false,
          code: "",
        },
        {
          id: 3,
          active: false,
          code: "",
        },
        {
          id: 4,
          active: false,
          code: "",
        },
        {
          id: 5,
          active: false,
          code: "",
        },
        {
          id: 6,
          active: false,
          code: "",
        },
        {
          id: 7,
          active: false,
          code: "",
        },
        {
          id: 8,
          active: false,
          code: "",
        },
        {
          id: 9,
          active: false,
          code: "",
        },
        {
          id: 10,
          active: false,
          code: "",
        },
        {
          id: 11,
          active: false,
          code: "",
        },
        {
          id: 12,
          active: false,
          code: "",
        },
        {
          id: 13,
          active: false,
          code: "",
        },
        {
          id: 14,
          active: false,
          code: "",
        },
        {
          id: 15,
          active: false,
          code: "",
        },
      ],
      list_air_keys: [],
      msg_air: [
        {
          power: "",
          mode: "MODE$HEAT-",
          fan: "FAN$LOW-",
          temp: "16",
        },
      ],
      key_count: "",
      index: 1,
      get_rs: 0,
      get_msg: false,
      key_info: [],
      control_count: "",
      tips_txt: "",
      isModalVisible: false,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          clearTimeout(timer);
          connection = true;
          // this.exitModalLoading();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  async mounted() {
    let self = this;
    this.onSubScribe();
    const promise = await self.lastStatus();
    if (promise) {
      let details = {
        title: this.title,
        noneHome: true,
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'IRBrandAdd');
    }
    this.tips_txt = this.$t('message.irTv.test');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    saveClick() {
      // this.showModal();
      this.switchTypeModal('show');
      this.getCountControl();
      this.onSubScribe();
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "config") {
        this.$router.push({
          name: "irtvlearnnone",
          params: {
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    saveControl() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var name = this.title;

      var data = {
        user_id: user_id,
        token: token,
        name: name,
        control_uid: this.uid,
        serial_number: this.serial_number,
        count: this.control_count,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.controlAdd;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.switchTypeModal('hide');
              self.$router.push({
                name: "irGatewaynone",
                params: {
                  serial_number: self.serial_number,
                },
              });
            } else {
              //error
              self.closeModal();
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    SendReguestKey(count) {
      let self = this;
      if (this.index <= this.key_count) {
        this.sendRequestMqtt(
            count,
            this.key_info[this.index - 1].key_count,
            this.key_info[this.index - 1].code
        );
        setTimeout(function () {
          if (self.get_msg) {
            self.index++;
            self.get_msg = false;
            self.SendReguestKey(self.control_count);
          } else {
            //errr
            self.$router.push({
              name: "irGatewaynone",
              params: {
                serial_number: self.serial_number,
              },
            });
            ErrorToastMsg.Msg();
            self.closeModal();
          }
        }, 5000);
      } else {
        if (this.key_count == this.get_rs) {
          this.saveControl();
        }
      }
    },
    onSubScribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);

    },
    sendRequestMqtt(count, key_count, code) {
      // ----------------------Data------------------//
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let URL = getUrl.nonemqttRequest;
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      var status = "NoBrand"
      var message = getCommand.getCommandOrder(count, key_count, status, code)
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // ---------------------Rseponse--------------------//

      p.then(function (response) {
        if (responseAjax(response)) {
        } else {
          //error
        }
      });
    },
    async lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        control_uid: this.uid,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.controlBrandTest;

      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Rseponse----------------//
      return p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.key_info = jsonArray.content.keys;
              self.list_air_keys = jsonArray.content.keys;
              self.title = jsonArray.content.control;
              self.key_count = self.key_info.length;
              self.exitModalLoading();
              self.key_info.forEach(function (item) {
                var key = item.key_count;
                for (let i = 0; i < 14; i++) {
                  if (i == key) {
                    self.listactive[i].active = true;
                    self.listactive[i].code = item.code;
                  }
                }
              });
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
              return new Promise((resolve, reject) => {
                resolve(false);
              });
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    getCountControl() {

      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.controlCount;

      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.control_count = jsonArray.content.control_count;

              if (self.type_code == 1003) {
                self.saveControl();
              }
              if (self.type_code == 1002 || self.type_code == 1001) {
                self.SendReguestKey(self.control_count);
              }
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    showGetMsg(msg) {

      var order = msg.includes("ORDEROK");
      this.exitModalLoading();
      if (order) {
        this.get_msg = true;
        this.get_rs++;
      } else {
        //errr
        //  ErrorToastMsg.Msg();
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    switchTypeModal(value) {
      if (this.type_code == "1003") {
        if (value == "show") {
          this.openModalLoading();
        }
        if (value == "hide") {
          this.exitModalLoading();
        }
      }
      if (this.type_code == "1001" || this.type_code == "1002") {
        if (value == "show") {
          this.showModal();
        }
        if (value == "hide") {
          this.closeModal();
        }
      }
    },
    getAirControlCmpCallback(msg) {

      this.checkAirList(msg);
    },
    getTvControlCmpCallback(msg) {

      if (msg == '') {
        this.unActive();
      } else {
        this.sendReguestTest(msg)
      }

    },
    getAmpliControlCmpCallback(msg) {

      if (msg == '') {
        this.unActive();
      } else {
        this.sendReguestTest(msg)
      }
    },
    checkAirList(msg) {
      let self = this;


      this.list_air_keys.forEach(function (item) {

        if (item.name == msg) {
          self.found_key = true;
          self.sendReguestTest(item.code);
        }
      });
      if (!this.found_key) {
        swal({
          title: this.$t('message.irAir.send'),
          text: this.$t('message.irAir.notfound'),
          type: "warning",
          confirmButtonColor: "#dc3545",
        });
      }
    },
    sendReguestTest(code) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      // var message = "IRTST" + "-" + code + "-END";
      var message = getCommand.getCommandIrTest(code);

      var topic_final = getTopics.getTopicActionSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          connection = false;
          var jsonArray = JSON.parse(response.responseText);
          self.found_key = false;
          // self.emptyMsg();
          timer = setTimeout(function () {

            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
                self.exitModalLoading();
              }
            }
            showToast = 0;
            connection = false;
          }, 5000);
        } else {
          //error
        }
      });
    },
    unActive() {
      ToastCustom.custom(this.$t('message.public.operation_failure'), "#fd7e14");
    },
  },
};
</script>
<style scoped>
</style>
