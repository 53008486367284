<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white" style="height: 812px;">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-confirm-sms.svg"
                            class="avatar-smart"
                        />
                        <h6 class="font-weight-bold px-30" style="line-height:25px !important;">
                          {{ $t('message.client.verify_manager') }}
                        </h6>
                        <p>{{ $t('message.client.enter') }}</p>
                      </div>
                    </div>
                    <br/>
                    <form class="px-40">
                      <div class="form-group">
                        <h6>{{ $t('message.client.OK') }}<span class="text-danger">*</span></h6>
                        <input
                            id="inputusersmscode"
                            type="number"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            maxlength="6"
                            :placeholder="$t('message.loginVerify.inputCode')"
                            v-model="smscode"

                        />
                      </div>

                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">
                          <button
                              id="btn-confirm-code"
                              type="button"
                              class="btn btn-info btn-block margin-top-10"
                              @click="confirmCode()"
                          >
                            {{ $t('message.public.submit') }}
                          </button>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>
                    <div class="text-center" style="height: 50px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
    </div>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {smsCodeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    verify: {},
    type: {},
    click: {}
  },
  data() {
    return {
      info: null,
      smscode: '',
      title: '',
    };
  },

  mounted() {
    this.title = this.$t('message.public.submit');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AddClientVerify');
    this.btnLoading();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {

    confirmCode() {
      let self = this;

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var admin_verify_id = this.verify;
      var verify_code = this.smscode;
      var home_id = this.home_id;
      var serial_number = this.serial_number;


      if (!smsCodeValidationInput(verify_code))
        return;

      var data = {
        user_id: user_id,
        token: token,
        admin_verify_id: admin_verify_id,
        verify_code: verify_code
      }
      //-----------------------Request ------------------------------//
      let URL = getUrl.clientAddVerify;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.$router.push({
                name: "managementclientinfo",
                params: {home_id: home_id, serial_number: serial_number, type: self.type}
              });

            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({
          name: "AddClientinfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            type: this.type
          },
        });
      }

    },

    btnLoading() {
      var $btn = $('#btn-confirm-code');
      $btn.click(function () {
        var $this = $(this);
        $this
            .attr("disabled", "disabled")
            .html(
                `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
            );
        setTimeout(function () {
          $this.removeAttr("disabled").html(this.$t('message.public.submit'));
        }, 2000);
      });
    }
  },
};
</script>


<style scoped>
</style>
