<template>
   
      <v-app class="bg m-0">
        <div class="mr-0 bg-white">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <!-- Main content -->
              <section class="content">
                <!-- row -->
                <div class="row">
                  <!-- col -->
                  <div class="col-12 col-lg-12 p-0">
                    <div class="box">
                      <div class="box-body p-0">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div style="text-align: center; margin-top: 10px">
                            <div class="fontawesome-icon-list m-0">
                              <img
                                src="/assets/images/smart/setpassword.svg"
                                style="width: 300px; height: 300px"
                              />
                              <h5 class="font-weight-bold">
                               {{$t('message.ModalAddHome.password_tips')}}
                              </h5>
                            </div>
                          </div>
                          <br />
                          <form class="px-20">
                            <div class="form-group">
                              <h6>{{$t('message.ModalAddHome.pin_code')}}<span class="text-danger">*</span></h6>
                              <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="32"
                                pattern="[0-9]"
                                id="set_pass"
                                autocomplete="off"
                                style="
                                  -webkit-text-security: disc;
                                  margin-top: 8px;
                                "
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder="$t('message.ModalAddHome.password_hint')"
                                v-model="pass"
                              />
                              <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="32"
                                pattern="[0-9]"
                                id="set_pass_rr"
                                autocomplete="off"
                                style="
                                  -webkit-text-security: disc;
                                  margin-top: 8px;
                                "
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder=" $t('message.ModalAddHome.password_repeat_hint')"
                                v-model="repeatpass"
                              />
                            </div>
                            <div class="row">
                              <!-- /.col -->
                              <div class="col-12 text-center">
                                <button
                                  type="button"
                                  class="btn btn-info btn-block margin-top-10"
                                  v-on:click.prevent="setPinCodeFunction()"
                                >
                                  {{$t('message.public.submit')}}
                                </button>
                              </div>
                              <!-- /.col -->
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.col -->
                </div>
              </section>
              <!-- /. Main content -->
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </v-app>
      <!-- /.modal -->
  
  </template>
  <script>
//   import LayoutDefault from "../../layouts/LayoutDefault";
  export default {
    name: "Modal",
    data: function () {
      return {
        pass: "",
        repeatpass: "",
        // title:'',
      };
    },
    props: {
    },
    components: {
    //   AppBar,
    },
    created() {
    //   this.title = this.$t('message.setting.defPIN')
    //   let details = {
    //     title: this.title,
    //   }
    //   localStorage.setItem("AppBar", JSON.stringify(details));
    //   this.$emit('update:layout', LayoutDefault);
    //   this.$emit('update:update', 'ModalSetPinCode');
      
    },
    methods: {
      setPinCodeFunction() {
        // errinit.innerHTML="";
        this.$emit("clicked", this.pass);
      },
      onClickChild(value){
        if(value=='back'){
        this.pass = "";
        this.repeatpass = "";
        $("#modal-init-pass").modal("hide");
        }
  }
    },
  };
  </script>
  