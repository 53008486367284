<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="px-3 mt-5">
        <v-card class="mx-auto">
          <v-row class="justify-center px-3 py-0">
            <v-col cols="4" class="align-self-center">
              <v-icon color="primary">mdi-format-color-text</v-icon>
              <label class="mt-1">
                {{ $t("message.relay.relay_name") }}
              </label>
            </v-col>
            <v-col cols="8" class="align-self-center">
              <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="nameboxrelay"
                  maxlength="32"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <v-container class="mt-5">
        <v-btn
            class="btn btn-block"
            color="bg-blue-light"
            elevation="1"
            @click="AddRelayAction()"
        >
          <v-icon class="text-dark mdi-24px" left> mdi-plus</v-icon>
          <label class="text-dark text-bold mt-1">
            {{ $t("message.relay.add.add_relay") }}</label
          >
        </v-btn>
        <CmpEmptyData
            class="mt-10"
            v-if="localRelaydata.length == 0"
            :btnShow="false"
            :txtCaptionShow="false"
        >
        </CmpEmptyData>
        <v-row class="mb-50 mt-1">
          <v-col cols="12" class="py-0" :key="index" v-for="(relayItem, index) in localRelaydata">
            <CmpRowList
                :infoItem="relayItem"
                :subTitleShow="true"
                :index="index"
                :ripple="false"
                @clicked="CmpCallDel"
            >
              <div v-if="relayItem.delayShow">
                <v-row>
                  <v-col class="px-0 mt-6">
                    <v-icon class="text-primary" small
                    >mdi-timer-sand
                    </v-icon
                    >
                  </v-col>
                  <v-col class="px-0 mt-3">
                    <v-select :items="listnum" v-model="relayItem.delay"
                              style="width: 90px"
                              solo
                              flat
                              background-color="#f5f5f5"
                              hide-details="true"
                              class="text-md-center">
                    </v-select>
                    <label class="text-lighter" style="font-size: 10px"
                    >{{ $t("message.relay.unit_momentary") }}
                    </label>
                  </v-col>
                </v-row>
              </div>
            </CmpRowList>
          </v-col>
        </v-row>
        <div class="fixed-bottom mb-1 px-4">
          <cmpbutton
              class="mb-1"
              :txtBtn="$t('message.public.edit')"
              :loading="loading"
              @clickedbtn="getCallBackBtn"
          ></cmpbutton>
        </div>
      </v-container>
      <DialogImg
          v-model="showDialogImg"
          :data="logos"
          @clicked="CmpCallImg"
      />
      <DialogInputImg
          v-model="showDialog"
          :data="type_relay"
          :datarelay="localRelaydata"
          @clicked="CmpCallTypeRelay"
      />
      <my-modal v-show="isModalVisible"></my-modal>
    </div>
  </v-app>
</template>
<script>
import DialogImg from "@/components/Cmp/DialogImg.vue";
import CmpEmptyData from "../../Cmp/public/CmpEmptyData.vue";
import DialogInputImg from "@/components/Cmp/DialogInputImg.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax.js";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler.js";
import {
  nameValidationInput,
  actionRelaydata,
} from "../../../../public/assets/js/utils/error_handler.js";
import {errOutletDelay} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    cmpbutton,
    DialogInputImg,
    DialogImg,
    CmpRowList,
    CmpEmptyData,
  },
  props: {
    home_id: {},
    serial_number: {},
    sub_serial: {},
    click: {}
  },
  data() {
    return {
      loading: false,
      isModalVisible: false,
      showDialog: false,
      showDialogImg: false,
      logos: [],
      type_relay: [],
      localRelaydata: [],
      serialboxrelay: "",
      nameboxrelay: "",
      relay_data: [],
      delayShow: false,
      errDelay: false,
      second: "ثانیه",
      selected: 1,
      indexfinal: '',
      delayfix: '',
      listnum: [],
      listdef: [1, 1, 1, 1, 1, 1, 1, 1]

    };
  },
  mounted() {
    let data = {
      title: this.$t('message.relay.edit.edit')
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'BoxRelayEdit');
    this.getRelaysItem();
  },
  created() {
    let self = this;
    for (let i = 1; i < 100; i++) {
      this.listnum.push(i)
    }
    setTimeout(function () {
      self.getImageRelay();
    }, 500);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    editRealy() {
      // this.isModalVisible = true;
      this.relay_data = [];

      let self = this;
      if (this.nameboxrelay == null) {
        this.nameboxrelay = "";
      }
      if (
          !nameValidationInput(this.nameboxrelay) ||
          !actionRelaydata(this.localRelaydata)
      )
        return;

      this.localRelaydata.forEach(function (item, i) {
        if (item.delayShow == true) {
          if (!errOutletDelay(item.delay)) {
            this.errDelay = true;
          }
        }
        self.relay_data.push({
          name: item.title,
          numbers: item.numbers,
          type_code: item.type_code,
          delay: item.delay,
        });
      });

      if (this.errDelay) return;
      var name = this.nameboxrelay;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.sub_serial;

      var object = {
        relay_data: this.relay_data,
      };
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        serial_number: serial_number,
        relay_data: JSON.stringify(object),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.relayEdit;
      const p = postAjaxCall(URL, data);
      this.loading = true;
      // -----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.loading = false;
              self.$router.push({
                name: "subboxrelay",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                },
              });
            } else {
              //error
              self.loading = false;
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "subboxrelay",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
    getCallBackBtn(msg) {
      this.editRealy();
    },
    AddRelayAction() {
      this.showDialogImg = true;
    },
    CmpCallImg(info) {
      this.type_relay = info;
      this.showDialog = true;
    },
    CmpCallTypeRelay(msg) {
      let self = this;
      var text = "";
      for (let i = 0; i < msg.numbers.length; i++) {
        text += this.$t("message.relay.relay") + msg.numbers[i];
      }
      if (msg.delay == 0) {
        this.delayShow = false;
      }

      if (msg.delay >= 1) {
        this.delayShow = true;
      }

      this.localRelaydata.push({
        title: msg.name,
        img: msg.logo_path,
        subTitle: text,
        icon: true,
        actions: "icon",
        numbers: msg.numbers,
        type_code: msg.type,
        delay: parseInt(msg.delay),
        delayShow: this.delayShow,
      });
      localStorage.setItem(
          "infoRelay8oLocal",
          JSON.stringify(this.localRelaydata)
      );
    },
    CmpCallDel(index) {
      this.showDelDialog(index);
    },
    getImageRelay() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var data = {
        user_id: user_id,
        token: token,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.relayType;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.logos = jsonArray;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getRelaysItem() {
      this.subRelaysItems = [];
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.sub_serial,
      };
      let URL = getUrl.relayItems;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        self.closeModalLoading();
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.nameboxrelay = jsonArray.content.device.name;
          self.serverInfoRelayItem = jsonArray.content.device.relays;
          self.serverInfoRelayItem.forEach(function (item, i) {

            var text = "";
            for (let i = 0; i < item.numbers.length; i++) {
              text += self.$t("message.relay.relay") + item.numbers[i];
            }
            if (item.delay == 0) {
              self.delayShow = false;
            }
            if (item.delay >= 1) {
              self.delayShow = true;
            }
            self.localRelaydata.push({
              title: item.name,
              img: item.type.logo_path,
              subTitle: text,
              icon: true,
              actions: "icon",
              numbers: item.numbers,
              type_code: item.type.type_code,
              delay: item.delay,
              delayShow: self.delayShow,
            });
          });
        } else {
          // self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showDelDialog(index) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.localRelaydata.splice(index, 1);
              localStorage.setItem(
                  "infoRelay8oLocal",
                  JSON.stringify(this.localRelaydata)
              );
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    setSelectedItem() {
      let self = this;
      this.localRelaydata.forEach(function (item, i) {

        self.delayfix = item.delay
        if (item.delay != 0) {

          self.listdef[item.numbers[0]] = self.listnum.indexOf(item.delay)
        }
      });
    }
  },
};
</script>
<style scoped>
</style>
