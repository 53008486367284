<template>
  <v-app class="bg">
  <!-- .wrapper  -->
    <!-- Main content -->
    <section class="form-control mt-6">
      <!-- row -->
      <div class="row">
        <!-- col -->
        <div class="col-12 col-lg-12 p-0">
          <div class="box">
            <div class="box-body p-0">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="bg-white">
                  <div style="text-align: center; margin-top: 10px">
                    <div class="fontawesome-icon-list m-0">
                      <img
                          src="/assets/images/smart/vector-addsub.svg"
                          class="avatar-smart"
                      />
                      <h5 class="font-weight-bold">
                        {{ $t('message.ModalAddSubDevicePol.title') }}
                      </h5>
                    </div>
                    <!-- <h6 class="mt-5 p-10"></h6> -->
                  </div>
                  <br/>
                  <form novalidate class="px-20">
                    <div class="form-group">
                      <h6>
                        {{ $t('message.ModalAddSubDevicePol.name') }}
                        <span class="text-danger">*</span></h6>
                      <input
                          type="text"
                          class="form-control"
                          required=""
                          aria-invalid="false"
                          maxlength="32"
                          autocomplete="off"
                          id="input6"
                          :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                      />
                    </div>
                    <div class="form-group">
                      <h6>
                        {{ $t('message.ModalAddSubDeviceFaraPol.serial') }}
                        <span class="text-danger">*</span>
                      </h6>
                      <input
                          type="number"
                          class="form-control"
                          required
                          aria-invalid="false"
                          onKeyPress="if(this.value.length==16) return false;"
                          autocomplete="off"
                          id="input7"
                          :placeholder="$t('message.ModalAddSubDeviceFaraPol.hintSerial')"
                      />
                    </div>
                    <div class="row">
                      <!-- /.col -->
                      <div class="col-12 text-center">

                        <v-btn
                            class="btn btn-info btn-block"
                            :loading="loading"
                            :disabled="loading"
                            color="bg-primary"
                            @click="myFunction()"
                        >
                          <span class="text-white"> {{ $t('message.public.submit') }} </span>
                        </v-btn>
                      </div>
                      <!-- /.col -->
                    </div>
                  </form>

                  <div class="text-center" style="height: 50px">
                    <!-- <p class="mt-15 mb-0">Already have an account?<a href="auth_login.html" class="text-danger ml-5"> Sign In</a></p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.col -->
      </div>
      <div class="col-lg-7 col-12"></div>

      <!-- /.row -->
    </section>
    <!-- /. Main content -->
    <!-- /.Content Wrapper. Contains page content -->
    <!-- /.modal Add Home-->
    <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <ModalCenter></ModalCenter> -->
  </v-app>
  <!-- ./wrapper -->
</template>

<script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput, serialDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  data() {
    return {
      title: '',
      loading: false,
    };
  },
  props: {
    click: {},
  },
  created() {
    let data = {
      title: this.$t('message.mainPage.add_device'),
      noneHome: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ModalAddDevice');
  },
  mounted() {
    this.title = this.$t('message.mainPage.submit_device')
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value === 'back') {
        this.$router.push('mainPage');
      }
    },
    myFunction() {
      let self = this
      //--------------------------------------  variable --------------------------------------//
      var device_name = document.getElementById("input6").value;
      var device_serial = document.getElementById("input7").value;
      var user_id = localStorage.getItem("user_id")
      var token = localStorage.getItem("token")
      //--------------------------------------  validation --------------------------------------//

      if (!nameDeviceValidationInput(device_name) || !serialDeviceValidationInput(device_serial))
        return;
      this.loading = true;
      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        name: device_name,
        serial_number: device_serial
      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.noneDeviceAdd;
      const p = postAjaxCall(URL, data, "");
      // $("#modal-center").modal('show');
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              window.location.href = "mainpage"
              // $("#modal-add-device").modal("hide");
              self.loading = false
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {


          });
    },

  },
};
</script>


<style scoped>
</style>
