<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!-- Content Wrapper. -->
    <div class="mr-0">
      <!-- Main content -->
      <div class="box-body">
        <section class="content">
          <!-- row -->
          <div class="text-center">
            <img
                src="/assets/images/smart/smart-ir.svg"
                class="avatar-smart"
            /><br/>
            <label class="text-primary">
              {{ $t('message.ir.model') }}
            </label>
          </div>
          <v-card class="mx-auto mt-20">
            <v-list>
              <v-list-group
                  v-bind:key="key"
                  v-for="(brand, key) in infos"
                  no-action
              >
                <template v-slot:activator>
                  <v-list-item>
                    <v-list-item-icon>
                      <div id="draggable-container">
                        <v-img
                            id="boximage"
                            :src="brand.logo_path"
                        ></v-img>
                      </div>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="px-5 text-primary">
                        {{ brand.en_name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item
                    v-bind:key="key"
                    v-for="(control, key) in brand.controls"
                    @click="setControlBrand(control.uid, control.learned)"
                >
                  <v-list-item-icon>
                    <!-- <v-icon >mdi-plus</v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-title> {{ control.model }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
          <!-- /.row -->
        </section>
        <!-- /. Main content -->
      </div>
    </div>
    <!-- /.Content Wrapper. -->
    <ModalIrAddControl v-bind:data_infos="data_info"></ModalIrAddControl>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>
<script>
import ModalIrAddControl from "./ModalIrAddControl";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {
    ModalIrAddControl,
  },
  props: {
    type_code: {},
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      data_info: [this.serial_number, this.home_id],
      infos: "",
      expandedGroup: [],
      expandedarrow: false,
      title: '',
      open: false,
      close: true,
      isModalVisible: false,
      backgroundImage: "https://file.pfas.ir/ir-controls/brand/noname.png",
    };
  },
  created() {
    let data = {
      title: this.$t('message.ir.list'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IRBrand');
  },
  mounted() {
    let self = this;
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");

    var data = {
      user_id: user_id,
      token: token,
      type_code: this.type_code
    };
    let URL = getUrl.controlBrand;


    this.openModalLoading();
    const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        self.closeModalLoading()
        if (responseAjax(response)) {
           self.infos = jsonArray.content.controls;
        var content = jsonArray.content.controls[0].controls;
        content.forEach(function (item) {
          self.data_info.push(item.uid);
        });
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({name: "home"});
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    setControlBrand(uid, learned) {
      if (learned == "0") {
        $("#modal-add-ir_control").modal("show");
      }
      if (learned == "1") {
        this.$router.push({
          name: "irtvbrand",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            type_code: this.type_code,
            uid: uid,
          },
        });
      }
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irtypeInfo",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },
};
</script>
<style scoped>
#draggable-container {
  /* position: absolute; */
  /* z-index: 100; */
  width: 50px;
  height: auto;
  /* box-sizing: border-box; */
  /* text-align: center; */
}

#boximage {
  height: 100%;
  width: 100%;
  /* display: block;
  resize: both; */
}
</style>
