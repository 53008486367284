<template>
  <v-app class="bg">
    <v-container>
      <v-card>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <v-icon class="mdi mdi-24px text-gray">mdi-water-pump</v-icon>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_pomp') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_0"
                elevation="0"
                @click="FunctionBtnPump11()"
            >
              <v-icon class="mdi mdi-24px text-white" id="imgdry">mdi-water</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <img src="/assets/images/smart/icon-fan-slow-black.svg"
                 style="width: 22px; height: 22px;"/>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_low') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_1"
                elevation="0"
                @click="FunctionBtnFan3()"
            >
              <img
                  id="imgfanlow"
                  src="/assets/images/smart/icon-fan-slow-white.svg"
                  style="width: 24px; height: 24px"
              />
            </v-btn>
          </div>
        </div>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <v-icon class="mdi mdi-24px text-gray">mdi-fan</v-icon>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_fast') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_2"
                elevation="0"
                @click="FunctionBtnFan4()"
            >
              <img
                  id="imgfanfast"
                  src="/assets/images/smart/icon-fan-fast-white.svg"
                  style="width: 24px; height: 24px"
              />
            </v-btn>
          </div>
        </div>
        <!-- /.col -->
      </v-card>
      <!-- /.row -->
      <v-card>
        <v-row class="rounded-16 mt-5">
          <v-col class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 px-5 rounded">
              <v-icon class="mdi mdi-24px text-gray">mdi-power</v-icon>
              <div class="media-body">
                <h6>{{ $t('message.public.off') }}</h6>
              </div>
              <v-btn
                  fab
                  depressed
                  class="mb-1"
                  :color="style_3"
                  elevation="0"
                  @click="FunctionBtnOff()"
              >
                <v-icon class="mdi text-white mdi-24px">mdi-power</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
</template>

<script>
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import TimerInput from "../../Cmp/TimerInput.vue";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import { passHomeValidationInput } from "../../../../public/assets/js/utils/error_handler";

import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var timeout;

export default {
  components: {
    CmpPasscode,
    TimerInput,

  },
  props: {
    serial_number: {},
    count: {},
    name: {},
    click: {},
  },
  data() {
    return {
      user_id:'',
      token:"",
      info:[],
      status: [],
      style_0: "",
      style_1: "",
      style_2: "",
      style_3: "red",
      pass: true,
      set_password: "",
      cooler_status: "",
      txtStatus: '',
      // main_admin: "",
      title: "",
      isModalVisible: false,

    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number && topic.split("/")[3] =='256') {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.showGetMsg(String(data));
          this.exitModal();
         
            SucessToastMsg.Msg();
         
        }
      }
    },
  },
  created() {
    this.status = [false, false, false, false];
  },
  mounted() {
    let self=this;
    try {
      this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
      this.set_password= this.info.set_password;
      // this.main_admin = this.$store.getters.getadmin;
      this.user_id = localStorage.getItem("user_id");
      this.token = localStorage.getItem("token");

      this.onSubscribe();
      setTimeout(function () {
        self.lastStatus();
      }, 100);

    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: self.$t("message.public.operation_failure"),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: self.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            // self.$router.push({
            //   name: "homeDevices",
            //   params: {
            //     home_id: self.home_id,
            //     serial_number: self.serial_number,
            //   },
            // });
            swal.close();
          }
        }
      );
    }
    let details = {
      title: this.info.name,
      noneHome_setting: true,
      noneHome: true
    };
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit("update:layout", LayoutDefault);
    this.$emit("update:update", "Ncooler");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    lastStatus() {
      let self = this;
      // ------------------Data--------------//
      let data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data);
      this.openModal();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            self.exitModal();
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.showGetMsg(message);
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
      if (value === 'noneHomeSetting') {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: 19,
        };
        localStorage.setItem("data_edit", JSON.stringify(data));
        this.$router.push({
          name: "SettingNoneCooler",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-name").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value === "shuttersetting") {
        $("#modal-outlet-momentray").modal("show");
      }
    },
    FunctionBtnPump11() {
      if (this.status[0] === false) {
        this.sendMessage("K1T#");
      } else {
        this.sendMessage("K1F#");
      }
    },
    FunctionBtnFan3() {
      if (this.status[1] === false) {
        this.sendMessage("K2T#");
      } else {
        this.sendMessage("K2F#");
      }
    },
    FunctionBtnFan4() {
      if (this.status[2] === false) {
        this.sendMessage("K3T#");
      } else {
        this.sendMessage("K3F#");
      }
    },
    FunctionBtnOff() {
      this.sendMessage("K5F#");
    },
    sendMessage(status) {
      if (this.set_password == 1) {
        this.cooler_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(status, "");
      }
    },
    sendReq(password) {
         this.sendRequest(this.cooler_status, password);
    },
    sendRequest(status, password) {
      var self = this;
      var topic_final = getTopics.getTopicActionSend(
        this.serial_number,
        256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: status,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModal();
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.openModal();
            // connection = false;
            $("#modal-pass-code").modal("hide");
            timeout = setTimeout(function () {
              self.exitModal();
              // connection = false;
              if (!connection) {
                ErrorToastMsg.Msg();
            }
              connection = false;
            }, 3000);
          } else {
            self.exitModal();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
       256
      );
      this.$mqtt.subscribe(topic1);
    },
    showGetMsg(msg) {
      let styles = ["#aaaaaa", "#aaaaaa", "#aaaaaa"];
      this.status = [false, false, false];
      for (var i = 0; i < msg.length - 1; i++) {
        if (msg[i] === "0") {
          this.status[i] = false;
          styles[i] = "#aaaaaa";
        } else {
          this.status[i] = true;
          styles[i] = "primary";
        }
      }
      this.style_0 = styles[0];
      this.style_1 = styles[1];
      this.style_2 = styles[2];
    },
  },
};
</script>
<style scoped>
</style>
