export const getMessageSub = {
  getMessageActionRelay: function (switch1,numbers) {
    var message='';
    var status='';
    if (switch1 == true) status = "T";
    if (switch1 == false) status = "F";
    if (switch1 == 'FF') status = "FF";
  if(numbers.length==1){
     message = "K" + numbers[0] + status + "#";
  }else{
      if(status=="T")
      message = 'FNC'+"(" +'K'+ numbers[0] +'F'+'-'+ 'K'+numbers[1] +'T'+')' + "#";
      if(status=="F")
      message = 'FNC'+"(" +'K'+ numbers[1] +'F'+'-'+ 'K'+numbers[0] +'T'+')' + "#";
      if(status=="FF")
      message = 'FNC'+"(" +'K'+ numbers[1] +'F'+'-'+ 'K'+numbers[0] +'F'+')' + "#";
  }
    return message
  },
  getMessagScenarioRelay: function (switch1,numbers) {
    var message='';
    var status='';
    if (switch1 == true) status = "T";
    if (switch1 == false) status = "F";
    if (switch1 == 'FF') status = "FF";
  if(numbers.length==1){
     message = "K" + numbers[0] + status;
  }else{
      if(status=="T")
      message = 'FNC'+"(" +'K'+ numbers[0] +'F'+'-'+ 'K'+numbers[1] +'T'+')';
      if(status=="F")
      message = 'FNC'+"(" +'K'+ numbers[1] +'F'+'-'+ 'K'+numbers[0] +'T'+')';
      if(status=="FF")
      message = 'FNC'+"(" +'K'+ numbers[1] +'F'+'-'+ 'K'+numbers[0] +'F'+')';
  }
    return message
  },
  getMessageToggleRelay:function (mode){
    var message='';
    if(mode==1){
        message="TOGGLEMODE:F";
    }
    if(mode==0){
        message="TOGGLEMODE:T";
    }
    return message;

  },
  getMessageInstantRelay:function(numbers,delay,keys_status){
    var message= "P"+numbers+":" + delay + "S#"+keys_status.join('')+'//';
      return message;
  },
  getMessageScenarioKeyAction:function(count,value){
    var message= "SCENARIO"+count+value+"#";
    return message;
  },
  timerALLOFF:function(){
    return "TIMERALL-OFF#"
  },
  timerItemOFF:function(count_timer){
    return "TIMER" + count_timer + "-OFF#";
  },
  wifiSettingStatus:function(status){
    return "WIFI"+status+'#';
  },
  settingDelayTime:function(keyOpen,delayOpen,keyClose,delayClose){
    return   "K" + keyOpen + ":" +
    delayOpen +"S" + "~" + "K" + keyClose +":" + delayClose + "S#";
  },
}



