<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

let status_checkbox = "TF";

export default {
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {}
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.subDevice.thermostat.thermostat'),
        status: false
      }],
      type: "thermostat"
    }
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler')
    this.$store.dispatch("InfoSubDevice");
    let checkbox1 = document.getElementById("timerthermostat");
    let status_checkbox = "TF";
    localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));

    // checkbox1.addEventListener("change", function (element) {
    //   if (checkbox1.checked) {
    //       status_checkbox =  "TT";
    //         localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));

    //   } else if (!checkbox1.checked) {
    //       status_checkbox = "TF";
    //             localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));

    //   }
    // });
  },
  methods: {
    onClick(name, val, status) {
      console.log(name, val, status);
      this.next1(status);
    },
    next1(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "TT";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      } else if (!status) {
        status_checkbox = "TF";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ThermostatStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "ThermostatTimerShow",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    },
    // checkBoxChange(checkBox) {
    //   let checkboxall = document.getElementById("checkall");
    //   let checkbox1 = document.getElementById("check1");
    //   let checkbox2 = document.getElementById("check2");
    //   let checkbox3 = document.getElementById("check3");
    //   let checkbox4 = document.getElementById("check4");

    //   if (checkBox == checkboxall) checkboxall.checked = true;
    //   else checkboxall.checked = false;
    //   if (checkBox == checkbox1) checkbox1.checked = true;
    //   else checkbox1.checked = false;
    //   if (checkBox == checkbox2) checkbox2.checked = true;
    //   else checkbox2.checked = false;
    //   if (checkBox == checkbox3) checkbox3.checked = true;
    //   else checkbox3.checked = false;
    //   if (checkBox == checkbox4) checkbox4.checked = true;
    //   else checkbox4.checked = false;
    // },
  },
};
</script>


<style scoped>
</style>
