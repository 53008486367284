<template>
  <v-app class="bg">
    <div>
      <div class="px-3 mt-5 mb-6" v-if="localScenariodata.length != 0">
        <v-card max-width="1750" class="mx-auto">
          <v-list subheader two-line>
            <v-list-item>
              <v-icon color="primary">mdi-palette</v-icon>
              <v-list-item-content>
                <v-list-item-title

                >
                  <h6 class="px-5 mt-1 text-bold"> {{ $t('message.scenario.scenario_icon') }}</h6>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="m-0">
                <v-list-item-avatar
                    rounded>
                  <v-img
                      :lazy-src="logoScenario"
                      max-height="50"
                      max-width="50"
                      :src="logoScenario"
                      @click="openDialog()"
                  ></v-img>
                </v-list-item-avatar>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-icon color="primary">mdi-format-color-text</v-icon>
              <v-list-item-content>
                <v-list-item-title

                >
                  <h6 class="px-5 mt-1 text-bold"> {{ $t('message.public.name') }}</h6>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="m-0 p-0">
                <div class="mt-5">
                  <v-text-field
                      background-color="#eceff1"
                      v-model="scenarioname"
                      :placeholder="$t('message.public.name')"
                      clearable
                      outlined
                  >
                  </v-text-field>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <v-container class="mt-4 py-0">
        <v-btn
            class="btn btn-block"
            :loading="loading"
            :disabled="loading"
            color="bg-blue-light"
            elevation="0"
            @click="AddSenarioAction()"
        >
          <v-icon class="text-dark mdi-24px" left> mdi-plus</v-icon>
          <label class="text-dark text-bold mt-1">
            {{ $t("message.scenario.add.add_status") }}</label
          >
        </v-btn>
        <div v-if="localScenariodata.actions.length == 0" class="mt-10">
          <CmpEmptyData
              class="d-flex align-items-center"
              :txtCaption="txtCaptionEmpty"
              :subTitleShow="true"
              :btnShow="false"
          ></CmpEmptyData>
        </div>
        <div v-if="localScenariodata.length != 0">
          <div class="mt-2 mb-50">
            <div
                class="mt-1"
                :key="index"
                v-for="(infos, index) in localScenariodata.actions"
            >
              <v-card>
                <v-list subheader two-line>
                  <v-list-item>
                    <v-img
                        lazy-src="https://picsum.photos/id/11/10/6"
                        :src="infos.path_logo"
                        contain
                        max-width="35"
                        max-height="35"
                    ></v-img>
                    <!-- </v-list-item-avatar> -->

                    <v-list-item-content class="mt-2 py-0 px-5">
                      <v-list-item-title>
                        <p
                            class="text-bold text-dark mt-2"
                            style="font-size: 16px"
                        >
                          {{ infos.name }}
                        </p>
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-1">
                        <p
                            class="text-regular text-primary"
                            style="font-size: 14px"
                        >
                          <!-- <label class="text-gray">{{ infos.serial }}</label> - -->
                          {{ getMsg(infos.status, infos.type_code) }}
                          <label
                              v-if="infos.type_code == '1001' || infos.type_code == '1002'"
                              class="text-primary"
                          >
                            {{ $t('message.scenario.add.btn_selected') }} :
                          </label>
                          <label v-if="infos.key_value == 11 && infos.type_code == '1001'"
                                 class="text-primary"
                                 style="font-size: 12px">
                            ok
                          </label>
                          <i
                              style="font-size: 14px"
                              :class="getIconControlStatus(
                                infos.key_value,
                                infos.type_code
                              )"
                              v-else-if="infos.type_code == '1001' || infos.type_code == '1002'"
                          ></i>
                        </p>
                      </v-list-item-subtitle>
                      <v-spacer></v-spacer>
                    </v-list-item-content>
                    <v-divider vertical class="mt-2"></v-divider>
                    <v-list-item-action>
                      <v-icon class="text-danger mr-2" @click="delItem(index)"
                      >mdi-close
                      </v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </div>
        </div>
        <div class="row rounded-16 px-4 mb-1 fixed-bottom">
          <div class="col-12 col-lg-12">
            <v-btn
                class="btn btn-info btn-block"
                color="bg-primary"
                :loading="loading"
                :disabled="loading"
                @click="editSenario()"
            >
              <span class="text-white">{{ $t("message.public.edit") }}</span>
            </v-btn>
          </div>
        </div>
        <DialogImg
            v-model="showDialogImg"
            :data="logos"
            @clicked="CmpCallImg"
        />
      </v-container>
    </div>
  </v-app>
</template>
<script>
import CmpEmptyData from "../Cmp/public/CmpEmptyData.vue";
import DialogImg from "@/components/Cmp/DialogImg.vue";
import {getKeyTouchStatus} from "../../../public/assets/js/utils/keytouch_parser";
import {getThermostatStatus} from "../../../public/assets/js/utils/thermostat_parser";
import {getControlTvStatus} from "../../../public/assets/js/utils/ir_control_tv_parser";
import {getControlamplifierStatus} from "../../../public/assets/js/utils/ir_control_amplifier_parser";
import {getControlAirStatus} from "../../../public/assets/js/utils/ir_control_air_parser";
import {getMessageScenario} from "../../../public/assets/js/utils/helper_scenario_message";
import {postAjaxCall} from "../../../public/assets/js/postAjax.js";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler.js";
import {nameValidationInput, actionsScenariodata} from "../../../public/assets/js/utils/error_handler.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {
    CmpEmptyData,
    CmpRowList,
    DialogImg,
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  data() {
    return {
      cmpShow: false,
      loading: false,
      txtCaptionEmpty: "",
      localScenariodata: {
        name: '',
        logo: '',
        actions: [],
      },
      scenariosItem: [],
      logos: [],
      scenarioname: "",
      logoScenario: "",
      showDialogImg: false,
    };
  },
  watch: {
    scenarioname(val) {
      this.scenariosItem.name = val;
      localStorage.setItem(
          "infoIrScenario",
          JSON.stringify(this.scenariosItem)
      );
    },
    click(val) {
      this.onClickChild(val);
    },
  },
  created() {
    let self = this;
    this.txtCaptionEmpty = this.$t("message.scenario.add.add_empty_caption");
    setTimeout(function () {
      self.getImageScenario();
    }, 500);
  },
  mounted() {
    this.cmpShow = true;
    this.$store.dispatch("infoScenarioLocal");
    this.$store.dispatch("infoIrScenario");
    this.localScenariodata = this.$store.getters.getScenarioLocal;
    this.scenariosItem = this.$store.getters.getScenario;
    this.scenarioname = this.scenariosItem.name;
    this.logoScenario = this.scenariosItem.logo_path;
    let details = {
      title: this.$t('message.scenario.edit.title_edit_scenario'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'scenarioEdit');
  },
  methods: {
    getMsg(str, type_code) {
      if (type_code == "10") return getThermostatStatus(str, "V1");
      if (type_code == "15") return getMessageScenario.getRelayStatus(str, "V1");
      if (type_code == "18") return getMessageScenario.getCurtainStatus(str, "V1");
      if (type_code == "21") return getMessageScenario.getWcStatus(str, "V1");
      if (type_code == "1") return getKeyTouchStatus(str, "V1");
      if (type_code == "1003") return getControlAirStatus(str, "V1");
      if (type_code == "17") return getMessageScenario.getCoolerStatus(str, "V1");
    },
    getIconControlStatus(str, type_code) {
      if (type_code == "1001") return getControlTvStatus(str, "V1");
      if (type_code == "1002") return getControlamplifierStatus(str, "V1");
    },
    onClickChild() {
      this.$router.replace({
        name: "ScenarioList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    AddSenarioAction() {
      this.$router.push({
        name: "SenarioDevices",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    delItem(index) {
      this.localScenariodata.actions.splice(index, 1);
      localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));
    },
    getMsgSenario() {
      var x = "";
      console.log("this.localScenariodata.actions",this.localScenariodata.actions)
      this.localScenariodata.actions.forEach(function (item) {
        x += item.msg_send;
      });
      return x;
    },
    editSenario(msg) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      if (this.scenariosItem.name == null) {
        this.scenariosItem.name = "";
      }
      if (
          !nameValidationInput(this.scenariosItem.name) || !actionsScenariodata(this.localScenariodata.actions)
      )
        return;

      var data = {
        user_id: user_id,
        token: token,
        scenario_uid: this.scenariosItem.uid,
        name: this.scenariosItem.name,
        msg: this.getMsgSenario(),
        actions: JSON.stringify(this.localScenariodata.actions),
        logo_path: this.logoScenario,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarioEdit;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false;
              self.$router.replace({
                name: "homeDevices",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                },
              });
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openDialog() {
      this.showDialogImg = true;
    },
    CmpCallImg(imgUrl) {
      this.logoScenario = imgUrl.logo_path;
      this.localScenariodata.logo = imgUrl.logo_path;
      localStorage.setItem("infoScenarioLocal", JSON.stringify(this.localScenariodata));

    },
    getImageScenario() {

      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var data = {
        user_id: user_id,
        token: token,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarioLogo;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.logos = jsonArray.content.logos;
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #f5f5f5;
}
</style>
