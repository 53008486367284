<template>
    <v-card max-width="1750" class="mx-auto mt-5">
            <v-row class="justify-center px-3 py-0">
            <v-col cols="1" class="align-self-center">
              <slot name="icon"></slot>
            </v-col
            >
            <v-col cols="3" class="align-self-center">
              <slot name="label"></slot>
            </v-col>
            <v-col cols="8" class="align-self-center">
              <slot name="actions"></slot>
            </v-col>
          </v-row>
        </v-card>
</template>
<script>
export default {
};
</script>
<style scoped>
</style>
