<template>
  <div class="bg">
    <CmpRelaySenario v-if="infoSub.type_code == '15' && cmpShow" :infos="infoRelay">
    </CmpRelaySenario>
    <CmpThermostatScenario class="mt-70" v-if="(infoSub.type_code == '10' || infoSub.type_code == '33') && cmpShow"
      @clicked="getThermostatCmpCallback">
    </CmpThermostatScenario>
    <CmpThermostaticScenario class="mt-70" v-if="(infoSub.type_code == '38') && cmpShow"
      @clicked="getThermostaticCmpCallback">
    </CmpThermostaticScenario>
    <CmpKeyTouchSenario v-if="(infoSub.type_code == '1' || infoSub.type_code == '30') && cmpShow"
      :infos="infoTouchKey">
    </CmpKeyTouchSenario>
    <CmpCurtainSenario v-if="(infoSub.type_code == '18' || infoSub.type_code == '32') && cmpShow" :infos="infoCurtain">
    </CmpCurtainSenario>
    <CmpCoolerSenario v-if="(infoSub.type_code == '17' || infoSub.type_code == '31') && cmpShow" :infos="infoCooler">
    </CmpCoolerSenario>
    <CmpElvatorSenario v-if="(infoSub.type_code == '20' || infoSub.type_code == '34') && cmpShow" :infos="infoElvator">
    </CmpElvatorSenario>
    <CmpWC v-if="(infoSub.type_code == '21' || infoSub.type_code == '36') && cmpShow" :infos="infoWc"></CmpWC>
    <br />
    <div class="row rounded-16 px-4 mb-1 fixed-bottom">
      <div class="col-12 col-lg-12 px-15 py-20">
        <v-btn class="btn btn-info btn-block" color="bg-primary" :loading="loading" :disabled="loading"
          @click="sendData()">
          <span class="text-white">{{ $t("message.public.OK") }}</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import CmpThermostatScenario from "@/components/Cmp/sub/CmpThermostatScenario";
import CmpThermostaticScenario from "@/components/Cmp/sub/CmpThermostaticScenario";
import CmpRelaySenario from "@/components/Cmp/sub/CmpRelaySenario";
import CmpKeyTouchSenario from "@/components/Cmp/sub/CmpKeyTouchSenario.vue";
import CmpCurtainSenario from "@/components/Cmp/sub/CmpCurtainSenario.vue";
import CmpCoolerSenario from "@/components/Cmp/sub/CmpCoolerSenario.vue";
import CmpElvatorSenario from "@/components/Cmp/sub/CmpElvatorSenario.vue";
import CmpWC from "@/components/Cmp/sub/CmpWC.vue";
import { getMessageSub } from "../../../../public/assets/js/utils/helper_sub_message.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    CmpThermostatScenario,
    CmpThermostaticScenario,
    CmpKeyTouchSenario,
    CmpRelaySenario,
    CmpCurtainSenario,
    CmpCoolerSenario,
    CmpElvatorSenario,
    CmpWC
  },
  props: {
    home_id: {},
    serial_number: {},
    subdevice_serial: {},
    click: {}
  },
  data() {
    return {
      temp_value: 10,
      title: "",
      cmpShow: false,
      localScenariodata: {
        name: "",
        logo: "",
        actions: [],
      },
      infoSub: [],
      infoTouchKey: [],
      infoRelay: [],
      infoCurtain: [],
      infoCooler: [],
      infoElvator: [],
      infoWc: [],
      loading: false,
      statusSub: "",
      msgSendAction: "",
      imgSrc: "/assets/images/smart/",
    };
  },
  watch: {
    value: function (update) {
      this.finalval = update;
    },
    click(val) {
      this.onClickChild(val);
    },
  },
  created() {
    let self = this;
    this.$store.dispatch("InfoIrSubScenario");
    this.infoSub = this.$store.getters.getIrSubScenario;
    //---------Info Relay8  for Secnairio CmpRelaySecnairio
    this.infoSub.relays.forEach(function (item, i) {
      self.infoRelay.push({
        id: i + 1,
        delay: item.delay,
        numbers: item.numbers,
        active: false,
        value: false,
        name: item.name,
        img: item.type.logo_path,
      });
    });
    //---------Info Curtain  for Secnairio CmpCurtainScenario
    this.infoCurtain.push(
      {
        id: 1,
        name: this.$t('message.public.open'),
        imgStatus: '/assets/images/smart/open-curtain.svg',
        active: false,
        img: 'null',
      },
      {
        id: 2,
        name: this.$t('message.public.stop'),
        imgStatus: '/assets/images/smart/pause-curtain.svg',
        active: false,
        img: 'null',
      },
      {
        id: 3,
        name: this.$t('message.public.close'),
        imgStatus: '/assets/images/smart/close-curtain.svg',
        active: false,
        img: 'null',
      }
    )
    this.infoCooler.push(
      {
        id: 1,
        name: this.$t('message.subDevice.cooler_pomp'),
        imgStatus: 'mdi-water-pump',
        active: false,
        value: false,
        img: 'null',
      },
      {
        id: 2,
        name: this.$t('message.subDevice.cooler_low'),
        imgStatus: 'mdi-fan',
        active: false,
        value: false,
        img: 'null',
      },
      {
        id: 3,
        name: this.$t('message.subDevice.cooler_fast'),
        value: false,
        imgStatus: 'mdi-fan',
        active: false,
        img: 'null',
      },
      {
        id: 5,
        name: this.$t('message.public.off'),
        value: false,
        imgStatus: 'mdi-power',
        active: false,
        img: 'null',
      }
    )
    //---------Info Elvator for Secnairio CmpElavtorScenario for show Page Elavtor in Scenario
    this.infoElvator.push(
      {
        id: 1,
        name: this.$t('message.relay.type.elevator'),
        imgStatus: '/assets/images/smart/sub_elavator.svg',
        active: false,
        img: 'null',
      },
    )
    //---------Info WC for Secnairio CmpWCScenario for show Page Wc in Scenario
    this.infoWc.push(
      {
        id: 1,
        name: this.$t('message.touch_key.key'),
        imgStatus: 'mdi-water-pump',
        active: false,
        value: false,
        img: 'null',
      },
      {
        id: 2,
        name: this.$t('message.touch_key.fan'),
        imgStatus: 'mdi-fan',
        active: false,
        value: false,
        img: 'null',
      },
      {
        id: 3,
        name: this.$t('message.touch_key.spray'),
        value: false,
        imgStatus: 'mdi-fan',
        active: false,
        img: 'null',
      },
      {
        id: 4,
        name: this.$t('message.touch_key.music'),
        value: false,
        imgStatus: 'mdi-power',
        active: false,
        img: 'null',
      }
    )
    //---------Info TouchKey  for Secnairio CmpTouchKeyScenario
    this.title = this.infoSub.name;
    for (let i = 1; i <= this.infoSub.pol; i++) {
    
        this.infoTouchKey.push({
          id: i ,
          pol: this.infoSub.pol,
          active: false,
          value: false,
          name: this.infoSub.labels[i-1].label,
          img: 'null',
        });
    }
    if (this.infoSub.pol != 1) {       
            this.infoTouchKey.push({
            id: 5,
            pol: this.infoSub.pol,
            active: false,
            value: false,
            name: this.$t('message.public.all'),
            img: 'null',
          });
                
     }
    this.cmpShow = true;
  },
  mounted() {
    this.$store.dispatch("infoScenarioLocal");
    this.$store.dispatch("flagEditScenario");
    this.localScenariodata = this.$store.getters.getScenarioLocal;
    this.flag = this.$store.getters.getFlag;
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'scenarioAddDevice');
  },
  methods: {
    onClickChild(val) {
      if (val === 'back')
        this.$router.push({
          name: "SenarioDevices",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
    },

    getThermostatCmpCallback(msg) {
      var newStr = msg.replace("#", "");
      (this.statusSub = newStr),
        (this.msgSendAction = "<" + this.subdevice_serial + "&" + newStr + ">");
    },
    getThermostaticCmpCallback(msg) {
     console.log("msg thermostatic",msg)
      var newStr = msg.replace("#", "");
      (this.statusSub = newStr),
        (this.msgSendAction = "<" + this.subdevice_serial + "&" + newStr + ">");
    },
    addTouchToScenario() {
      let self = this;
      this.loading = true;
      var value = "";
      //for button all K5 keytouch
      if (this.infoSub.pol == 2 && this.infoTouchKey[2].active) {
        this.saveKeyAll(this.infoTouchKey[2].value)
      }
      else if (this.infoSub.pol == 3 && this.infoTouchKey[3].active) {
        this.saveKeyAll(this.infoTouchKey[3].value)
      }
      else if (this.infoSub.pol == 4 && this.infoTouchKey[4].active) {
        this.saveKeyAll(this.infoTouchKey[4].value)
      }
      else {

        this.infoTouchKey.forEach(function (item) {
          if (item.active == true) {
            if (item.value) value = "T";
            else value = "F";

            self.statusSub = "K" + item.id + value;
            self.msgSendAction =
              "<" + self.subdevice_serial + "&K" + item.id + value + ">";

            self.localScenariodata.actions.push({
              name: self.infoSub.name,
              serial: self.subdevice_serial,
              type_code: self.infoSub.type_code,
              path_logo: self.infoSub.path_logo,
              status: self.statusSub,
              msg_send: self.msgSendAction,
            });
          }
        });
      }
      console.log("msgSendAction", self.msgSendAction)
    },
    saveKeyAll(value) {
      let self = this
      if (value) {
        value = "T";
      } else {
        value = "F";
      }

      this.statusSub = "K" + "5" + value;
      this.msgSendAction =
        "<" + this.subdevice_serial + "&K" + "5" + value + ">";

      this.localScenariodata.actions.push({
        name: self.infoSub.name,
        serial: self.subdevice_serial,
        type_code: self.infoSub.type_code,
        path_logo: self.infoSub.path_logo,
        status: self.statusSub,
        msg_send: self.msgSendAction,
      });
    },
    addCurtainToScenario() {
      let self = this;
      this.loading = true;
      var value = "";
      this.infoCurtain.forEach(function (item) {
        if (item.active == true) {
          value = "T";


          self.statusSub = "K" + item.id + value;
          self.msgSendAction =
            "<" + self.subdevice_serial + "&K" + item.id + value + ">";

          self.localScenariodata.actions.push({
            name: self.infoSub.name,
            serial: self.subdevice_serial,
            type_code: self.infoSub.type_code,
            path_logo: self.infoSub.path_logo,
            status: self.statusSub,
            msg_send: self.msgSendAction,
          });
        }
      });
    },
    addElavtorToScenario() {
      let self = this;
      this.loading = true;
      var value = "";
      this.infoElvator.forEach(function (item) {
        if (item.active == true) {
          value = "1";
          self.statusSub = "K" + item.id + value;
          self.msgSendAction =
            "<" + self.subdevice_serial + "&K" + item.id + "T" + ">";
          self.localScenariodata.actions.push({
            name: self.infoSub.name,
            serial: self.subdevice_serial,
            type_code: self.infoSub.type_code,
            path_logo: self.infoSub.path_logo,
            status: self.statusSub,
            msg_send: self.msgSendAction,
          });
        }
      });
    },
    addRelayScenario() {
      let self = this;
      this.loading = true;
      var value = "";
      this.infoRelay.forEach(function (item) {
        var typeMsgRelay = "";
        if (item.active == true) {
          if (item.delay == 0) {
            self.statusSub = "S01" + item.name + "E01" + "val" + self.getValue(item.value, item.numbers);
            typeMsgRelay = getMessageSub.getMessagScenarioRelay(
              item.value,
              item.numbers
            );
          }
          if (item.delay > 1) {
            self.statusSub = "S01" + item.name + "E01" + "valD" + item.delay + this.$t('message.public.seconds') + 'delay';
            typeMsgRelay = "P" + item.numbers + ":" + item.delay + "S";
          }
          self.msgSendAction =
            "<" + self.subdevice_serial + "&" + typeMsgRelay + ">";
          self.localScenariodata.actions.push({
            name: self.infoSub.name,
            serial: self.subdevice_serial,
            type_code: self.infoSub.type_code,
            path_logo: self.infoSub.path_logo,
            status: self.statusSub,
            msg_send: self.msgSendAction,
            delay: item.delay,
            number_relay: item.numbers
          });
        }

      });
    },
    addWcToScenario() {
      let self = this;
      this.loading = true;
      var value = "";

      this.infoWc.forEach(function (item) {
        if (item.active == true) {
          if (item.value) value = "T";
          else value = "F";

          self.statusSub = "K" + item.id + value;
          self.msgSendAction =
            "<" + self.subdevice_serial + "&K" + item.id + value + ">";

          self.localScenariodata.actions.push({
            name: self.infoSub.name,
            serial: self.subdevice_serial,
            type_code: self.infoSub.type_code,
            path_logo: self.infoSub.path_logo,
            status: self.statusSub,
            msg_send: self.msgSendAction,
          });
        }


      });
    },
    getValue(value, numbers) {
      //  it is ex : cutrain
      if (numbers.length > 1) {
        if (value == "FF") return "FF";
        else if (value) return "1";
        else return "0";
      }
      // it is ex:lamp and on//off
      else {
        if (value) return "T";
        else return "F";
      }
    },
    addCoolerToScenario() {
      let self = this;
      this.loading = true;
      var value = "";

      this.infoCooler.forEach(function (item) {
        if (item.active == true) {
          if (item.value) value = "T";
          else value = "F";

          self.statusSub = "K" + item.id + value;
          self.msgSendAction =
            "<" + self.subdevice_serial + "&K" + item.id + value + ">";
          self.localScenariodata.actions.push({
            name: self.infoSub.name,
            serial: self.subdevice_serial,
            type_code: self.infoSub.type_code,
            path_logo: self.infoSub.path_logo,
            status: self.statusSub,
            msg_send: self.msgSendAction,
          });
        }

      });
    },
    sendData() {
      if (this.infoSub.type_code == "1" || this.infoSub.type_code == "30") {
        this.addTouchToScenario();
      }
      if (this.infoSub.type_code == "15") {
        this.addRelayScenario();
      }
      if (this.infoSub.type_code == "18" || this.infoSub.type_code == "32") {
        this.addCurtainToScenario();
      }
      if (this.infoSub.type_code == "17" || this.infoSub.type_code == "31") {
        this.addCoolerToScenario();
      }
      if (this.infoSub.type_code == "20" || this.infoSub.type_code == "34") {
        this.addElavtorToScenario();
      }
      if (this.infoSub.type_code == "21" || this.infoSub.type_code == "36") {
        this.addWcToScenario();
      }
      if (this.infoSub.type_code == "10" || this.infoSub.type_code == "33") {
        this.localScenariodata.actions.push({
          name: this.infoSub.name,
          serial: this.subdevice_serial,
          type_code: this.infoSub.type_code,
          path_logo: this.infoSub.path_logo,
          status: this.statusSub,
          msg_send: this.msgSendAction,
        });
      }
      if (this.infoSub.type_code == "38") {
        this.localScenariodata.actions.push({
          name: this.infoSub.name,
          serial: this.subdevice_serial,
          type_code: this.infoSub.type_code,
          path_logo: this.infoSub.path_logo,
          status: this.statusSub,
          msg_send: this.msgSendAction,
        });
      }

      localStorage.setItem(
        "infoScenarioLocal",
        JSON.stringify(this.localScenariodata)
      );

      if (this.flag == "Edit") {
        this.$router.push({
          name: "ScenarioEditList",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
      if (this.flag == "Add") {
        this.$router.push({
          name: "ScenarioAddList",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
    },
  },
};
</script>