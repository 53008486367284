<template>
  <transition name="modal" id="modal-center">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header border-0">
            <slot name="header ">
              <v-row>
                <v-container>
                  <h5>{{ title }}</h5>
                  <v-progress-linear indeterminate color="bg-primary" class="mt-20">
                  </v-progress-linear>
                </v-container>
              </v-row>
            </slot>

          </div>
          <div class="modal-body m-0 mt-10">

            <slot name="body">
              <span class="pl-20">{{ subtile }}</span
              ><br/><br/>
              <div v-if="checkMsg==true">
                <i class="mdi mdi-information-outline  text-warning"></i> <label class="pl-20 ">{{ msg }}</label
              >
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  data() {
    return {
      title: '',
      subtile: '',
      msg: "",
      checkMsg: false,
    };
  },
  props: {
    data_txt: {},
  },
  mounted() {
    this.title= this.$t('message.ModalCenter.loading');
    this.subtile= this.$t('message.ModalCenter.wait')
    // console.log("this.data_txt",this.data_txt)
    var msg_modal = this.data_txt;
    if (typeof msg_modal != "undefined") {
      if (typeof msg_modal.title != "undefined") {
        this.title = msg_modal.title;
      } else {
        this.title = this.$t('message.ModalCenter.loading');
      }
      if (typeof msg_modal.subtile != "undefined") {
        this.subtile = msg_modal.subtile;
      } else {
        this.subtile = this.$t('message.ModalCenter.wait');
      }
      if (typeof msg_modal.msg != "undefined") {
        this.checkMsg = true;
        this.msg = msg_modal.msg;
      } else {
        this.msg = "";
        this.checkMsg = false;
      }
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.733) !important;
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 5px 10px;
  background-color: #fff !important;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(49, 49, 49, 0.3);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h6 {
  font-family: "IRANSansWeb_Medium";
  bottom: 2px;
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  font-family: "IRANSansWeb_Medium";
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>