<template>
  <v-app class="bg">
    <TimerList :isShow="isShow" :length="length" :infos="info" @addClock="addClock()" @addTimer="addTimer()"
               @deleteTimer="deleteTimer"/>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import TimerList from "@/components/Cmp/timer/show/TimerList.vue";
import {SetClock} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {Tools} from "../../../../public/assets/js/utils/util.js";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
export default {
  components: {
    TimerList
  },
  data: () => ({
    info: "",
    length: "",
    lang: '',
    count_timer: 0,
    isShow: false,
    isModalVisible: false,
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
  },
  mqtt: {
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler_show');
    setTimeout(function () {
      self.lastStatus();
    }, 100);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    lastStatus() {
      let self = this;
      this.info = '';

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;

      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.noneTimer;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              self.length = content.length;
              var len = content.length;
              if (len == 0) {
                self.isShow = true;
              }
              content.forEach(function (item) {
                const data = getTimerData(item.message);

                finaldata.push(data);
                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: this.$t('message.timer.timer'),
            text: this.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: this.$t('message.public.delete'),
            cancelButtonText: this.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {

              self.sendDelete(counttimer);
            }
          }
      );
    },
    sendDelete(counttimer) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.count_timer = counttimer;
      let message = "TIMER" + this.count_timer + "-OFF#";
      var topic_final = getTopics.getTopicTimerSend(this.serial_number, '256');
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                  self.exitModalLoading();
                }
              }, 10000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();

      let device_serial = this.serial_number;
      swal({
        title: this.$t('message.timer.timer'),
        text: this.$t('message.timer.timer_delete'),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      self.lastStatus();
      // location.reload();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");

      var topic_final = getTopics.getTopicClockSend(this.serial_number, '256');

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000)
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "GatewayNoneInfo",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "refresh") {
        // location.reload();
        this.lastStatus();
      }
      if (value == "add") {
        this.$router.push({
          name: "noutlettimer",
          params: {serial_number: this.serial_number},
        });
      }
    },
    addTimer() {
      this.$router.push({
        name: "noutlettimer",
        params: {serial_number: this.serial_number},
      });
    }
  },
};
</script>


<style scoped>
</style>
