<template>
  <v-app class="bg">
    <div v-if="learned == '1'">
      <template>
        <v-tabs v-model="tabs" fixed-tabs>
          <v-tab class="text-primary"> {{ $t('message.irAir.control') }}</v-tab>
          <v-tab class="text-primary"> {{ $t('message.irAir.save') }}</v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-card flat>
            <CmpControlAir2
                v-if="cmpShow"
                :command="learned"
                @clicked="getAirControlCmpCallback"
            ></CmpControlAir2>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <div class="content-wrapper mr-0">
              <div>
                <section class="content">
                  <div class="row mt-20 px-20" v-if="info.length == 0">
                    <CmpEmptyData
                        v-if="cmpShow"
                        @clicked="getAddClickCallback"
                    ></CmpEmptyData>
                  </div>
                  <div v-else>
                    <div
                        class="row mt-10 px-20"
                        v-for="(infos, index) in info"
                        :key="index"
                    >
                      <div
                          class="col-12 py-0 mt-5"
                          v-if="
                          infos.key_value
                            .split('POWER$')
                            .pop()
                            .split('-')[0] !== 'OFF'
                        "
                      >
                        <div class="col-12 col-lg-12 p-0">
                          <!-- AREA CHART -->
                          <div class="box">
                            <div class="box-header with-border">
                              <h6 class="box-title">
                                {{ $t('message.irAir.situation') }} {{ infos.key_count }}
                              </h6>
                            </div>
                            <div class="box-body">
                              <ul
                                  class="flexbox flex-justified text-center my-10"
                              >
                                <li
                                    class="px-30"
                                    @click="Done(infos.key_count)"
                                >
                                  <!-- <p class="mb-0">Traffic</p> -->
                                  <div class="font-size-10 mb-5">
                                    <span class="font-size-60"
                                    >{{
                                        infos.key_value
                                            .split("TEMP$")
                                            .pop()
                                            .split("-SWING")[0]
                                      }}°C</span
                                    >
                                  </div>
                                </li>
                                <li class="br-1">
                                  <div
                                      class="font-size-18 text-danger"
                                      v-if="
                                      infos.key_value
                                        .split('MODE$')
                                        .pop()
                                        .split('-')[0] == 'HEAT'
                                    "
                                  >
                                    <i
                                        class="
                                        mdi mdi-weather-sunny mdi-18px
                                        px-3
                                      "
                                    ></i
                                    ><label class="font-size-14">{{
                                      infos.key_value
                                          .split("MODE$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-orange"
                                      v-if="
                                      infos.key_value
                                        .split('MODE$')
                                        .pop()
                                        .split('-')[0] == 'DRY'
                                    "
                                  >
                                    <i class="mdi mdi-water mdi-18px px-3"></i
                                    ><label class="font-size-14">{{
                                      infos.key_value
                                          .split("MODE$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-primary"
                                      v-if="
                                      infos.key_value
                                        .split('MODE$')
                                        .pop()
                                        .split('-')[0] == 'COOL'
                                    "
                                  >
                                    <i
                                        class="mdi mdi-snowflake mdi-18px px-3"
                                    ></i
                                    ><label class="font-size-14">{{
                                      infos.key_value
                                          .split("MODE$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-primary"
                                      v-if="
                                      infos.key_value
                                        .split('MODE$')
                                        .pop()
                                        .split('-')[0] == 'AUTO'
                                    "
                                  >
                                    <i
                                        class="mdi mdi-autorenew mdi-18px px-3"
                                    ></i
                                    ><label class="font-size-14">{{
                                      infos.key_value
                                          .split("MODE$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18"
                                      v-if="
                                      infos.key_value
                                        .split('FAN$')
                                        .pop()
                                        .split('-')[0] == 'HIGH'
                                    "
                                  >
                                    <img
                                        src="/assets/images/smart/icon-fan-fast-on.svg"
                                        class="mr-2"
                                        style="width: 16px; height: 16px"
                                    /><label class="font-size-14">{{
                                      infos.key_value
                                          .split("FAN$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-primary"
                                      v-if="
                                      infos.key_value
                                        .split('FAN$')
                                        .pop()
                                        .split('-')[0] == 'LOW'
                                    "
                                  >
                                    <img
                                        src="/assets/images/smart/icon-fan-slow-on.svg"
                                        class="mr-2"
                                        style="width: 16px; height: 16px"
                                    /><label class="font-size-14">{{
                                      infos.key_value
                                          .split("FAN$")
                                          .pop()
                                          .split("-")[0]
                                    }}</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-primary"
                                      v-if="
                                      infos.key_value
                                        .split('FAN$')
                                        .pop()
                                        .split('-')[0] == 'MEDUIM'
                                    "
                                  >
                                    <img
                                        src="/assets/images/smart/icon-fan-meduim-on.svg"
                                        class="mr-2"
                                        style="width: 16px; height: 16px"
                                    /><label class="font-size-14">MED</label>
                                  </div>
                                  <div
                                      class="font-size-18 text-success"
                                      v-if="
                                      infos.key_value
                                        .split('SWING$V')
                                        .pop()
                                        .split('-')[0] == 'ON'
                                    "
                                  >
                                    <i
                                        class="mdi mdi-swap-vertical mdi-18px"
                                    ></i
                                    ><label class="font-size-14"> Swing </label>
                                  </div>
                                  <div
                                      class="font-size-18 text-success"
                                      v-if="
                                      infos.key_value
                                        .split('SWING$H')
                                        .pop()
                                        .split('-')[0] == 'ON'
                                    "
                                  >
                                    <i
                                        class="mdi mdi-swap-horizontal mdi-18px"
                                    ></i
                                    ><label class="font-size-14">Swing</label>
                                  </div>
                                </li>
                                <li>
                                  <div class="font-size-18 text-grey">
                                    <div>
                                      <i
                                          class="
                                          mdi mdi-border-color mdi-24px
                                          text-grey
                                          pull-right
                                          pr-20
                                        "
                                          @click="
                                          EditItem(infos.key_count, infos.value)
                                        "
                                      ></i>
                                    </div>
                                    <br/><br/>
                                    <div>
                                      <i
                                          @click="delItem(infos.key_count)"
                                          class="
                                          mdi mdi-delete-forever mdi-24px
                                          text-grey
                                          pull-right
                                          pr-20
                                        "
                                      ></i>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <!-- /.box-body -->
                          </div>
                          <!-- /.box -->
                        </div>
                      </div>
                    </div>
                    <div
                        class="row mt-20 px-20"
                        v-bind:key="index + 'B'"
                        v-for="(infos, index) in info"
                    >
                      <div
                          class="col-12 py-0"
                          v-if="
                          infos.key_value.split('POWER$').pop().split('-')[0] ==
                          'OFF'
                        "
                      >
                        <!-- .row device-->

                        <div class="col-12 col-lg-12 p-0">
                          <!-- AREA CHART -->
                          <div class="box">
                            <div class="box-header with-border">
                              <h6 class="box-title">
                                {{ $t('message.irAir.situation') }} {{ infos.key_count }}
                              </h6>
                            </div>
                            <div class="box-body">
                              <ul
                                  class="flexbox flex-justified text-center my-10"
                              >
                                <li
                                    class="px-30"
                                    @click="Done(infos.key_count)"
                                >
                                  <!-- <p class="mb-0">Traffic</p> -->
                                  <div class="font-size-20 mb-5">
                                    <span class="font-size-60">{{
                                        infos.key_value
                                            .split("POWER$")
                                            .pop()
                                            .split("-")[0]
                                      }}</span>
                                  </div>
                                </li>
                                <li class="br-1">
                                  <i
                                      class="mdi mdi-power mdi-36px text-danger"
                                      style="font-size: 120px"
                                  >
                                  </i>
                                </li>
                                <li>
                                  <div>
                                    <i
                                        class="
                                        mdi mdi-border-color mdi-24px
                                        text-grey
                                        pull-right
                                        pr-20
                                      "
                                        @click="
                                        EditItem(infos.key_count, infos.value)
                                      "
                                    ></i>
                                  </div>
                                  <br/><br/>
                                  <div>
                                    <i
                                        @click="delItem(infos.key_count)"
                                        class="
                                        mdi mdi-delete-forever mdi-24px
                                        text-grey
                                        pull-right
                                        pr-20
                                      "
                                    ></i>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <!-- /.box-body -->
                          </div>
                          <!-- /.box -->
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <!-- /.content -->
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div v-else class="mt-3">
      <div>
        <section class="content">
          <div class="row mt-20 px-20" v-if="info.length == 0">
            <CmpEmptyData
                v-if="cmpShow"
                @clicked="getAddClickCallback"
            ></CmpEmptyData>
          </div>
          <div v-else>
            <div
                class="row mt-2 px-20"
                v-bind:key="index + 'C'"
                v-for="(infos, index) in info"
            >
              <div
                  class="col-12 py-0 mt-5"
                  v-if="
                  infos.key_value.split('POWER$').pop().split('-')[0] !== 'OFF'
                "
              >
                <div class="col-12 col-lg-12 p-0">
                  <!-- AREA CHART -->
                  <div class="box">
                    <div class="box-header with-border">
                      <h6 class="box-title">
                        {{ $t('message.irAir.situation') }} {{ infos.key_count }}
                      </h6>
                    </div>
                    <div class="box-body">
                      <ul class="flexbox flex-justified text-center my-10">
                        <li class="px-30" @click="Done(infos.key_count)">
                          <!-- <p class="mb-0">Traffic</p> -->
                          <div class="font-size-10 mb-5">
                            <span class="font-size-60"
                            >{{
                                infos.key_value
                                    .split("TEMP$")
                                    .pop()
                                    .split("-SWING")[0]
                              }}°C</span
                            >
                          </div>
                        </li>
                        <li class="br-1">
                          <div
                              class="font-size-18 text-danger"
                              v-if="
                              infos.key_value
                                .split('MODE$')
                                .pop()
                                .split('-')[0] == 'HEAT'
                            "
                          >
                            <i class="mdi mdi-weather-sunny mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-orange"
                              v-if="
                              infos.key_value
                                .split('MODE$')
                                .pop()
                                .split('-')[0] == 'DRY'
                            "
                          >
                            <i class="mdi mdi-water mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                              infos.key_value
                                .split('MODE$')
                                .pop()
                                .split('-')[0] == 'COOL'
                            "
                          >
                            <i class="mdi mdi-snowflake mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                              infos.key_value
                                .split('MODE$')
                                .pop()
                                .split('-')[0] == 'AUTO'
                            "
                          >
                            <i class="mdi mdi-autorenew mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18"
                              v-if="
                              infos.key_value
                                .split('FAN$')
                                .pop()
                                .split('-')[0] == 'HIGH'
                            "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-fast-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">{{
                              infos.key_value.split("FAN$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                              infos.key_value
                                .split('FAN$')
                                .pop()
                                .split('-')[0] == 'LOW'
                            "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-slow-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">{{
                              infos.key_value.split("FAN$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                              infos.key_value
                                .split('FAN$')
                                .pop()
                                .split('-')[0] == 'MEDUIM'
                            "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-meduim-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">MED</label>
                          </div>
                          <div
                              class="font-size-18 text-success"
                              v-if="
                              infos.key_value
                                .split('SWING$V')
                                .pop()
                                .split('-')[0] == 'ON'
                            "
                          >
                            <i class="mdi mdi-swap-vertical mdi-18px"></i
                            ><label class="font-size-14"> Swing </label>
                          </div>
                          <div
                              class="font-size-18 text-success"
                              v-if="
                              infos.key_value
                                .split('SWING$H')
                                .pop()
                                .split('-')[0] == 'ON'
                            "
                          >
                            <i class="mdi mdi-swap-horizontal mdi-18px"></i
                            ><label class="font-size-14">Swing</label>
                          </div>
                        </li>
                        <li>
                          <div class="font-size-18 text-grey">
                            <div>
                              <i
                                  class="
                                  mdi mdi-border-color mdi-24px
                                  text-grey
                                  pull-right
                                  pr-20
                                "
                                  @click="EditItem(infos.key_count, infos.value)"
                              ></i>
                            </div>
                            <br/><br/>
                            <div>
                              <i
                                  @click="delItem(infos.key_count)"
                                  class="
                                  mdi mdi-delete-forever mdi-24px
                                  text-grey
                                  pull-right
                                  pr-20
                                "
                              ></i>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- /.box-body -->
                  </div>
                  <!-- /.box -->
                </div>
              </div>
            </div>
            <div
                class="row mt-20 px-20"
                :key="index + 'A'"
                v-for="(infos, index) in info"
            >
              <div
                  class="col-12"
                  v-if="
                  infos.key_value.split('POWER$').pop().split('-')[0] == 'OFF'
                "
              >
                <!-- .row device-->

                <div class="col-12 col-lg-12 p-0">
                  <!-- AREA CHART -->
                  <div class="box">
                    <div class="box-header with-border">
                      <h6 class="box-title">
                        {{ $t('message.irAir.situation') }} {{ infos.key_count }}
                      </h6>
                    </div>
                    <div class="box-body">
                      <ul class="flexbox flex-justified text-center my-10">
                        <li class="px-30" @click="Done(infos.key_count)">
                          <!-- <p class="mb-0">Traffic</p> -->
                          <div class="font-size-20 mb-5">
                            <span class="font-size-60">{{
                                infos.key_value
                                    .split("POWER$")
                                    .pop()
                                    .split("-")[0]
                              }}</span>
                          </div>
                        </li>
                        <li class="br-1">
                          <i
                              class="mdi mdi-power mdi-36px text-danger"
                              style="font-size: 120px"
                          >
                          </i>
                        </li>
                        <li>
                          <div>
                            <i
                                class="
                                mdi mdi-border-color mdi-24px
                                text-grey
                                pull-right
                                pr-20
                              "
                                @click="EditItem(infos.key_count, infos.value)"
                            ></i>
                          </div>
                          <br/><br/>
                          <div>
                            <i
                                @click="delItem(infos.key_count)"
                                class="
                                mdi mdi-delete-forever mdi-24px
                                text-grey
                                pull-right
                                pr-20
                              "
                            ></i>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- /.box-body -->
                  </div>
                  <!-- /.box -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- /.content -->
    </div>
    <EditName v-bind:data_name="title" @clicked="getNameCmpCallback"></EditName>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {BetweenStr} from "../../../../../public/assets/js/utils/util.js";
import {SucessToastMsg} from "../../../../../public/assets/js/utils/util.js";
import {ErrorToastMsg} from "../../../../../public/assets/js/utils/util.js";
import CmpControlAir2 from "../../../ir/CmpControlAir2.vue";
import EditName from "@/components/Cmp/EditName.vue";
import CmpEmptyData from "../../../Cmp/public/CmpEmptyData.vue";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command.js";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;
var timer;

export default {
  components: {
    CmpControlAir2,
    CmpEmptyData,
    EditName,
  },
  props: {
    serial_number: {},
    count: {},
    click: {}
  },
  data() {
    return {
      info: "",
      msgFan: "",
      title: "",
      json: [
        {
          control_count: "",
          key_count: "",
        },
      ],
      data_info: [this.serial_number, this.count],
      learned: "",
      list_air_keys: [],
      found_key: false,
      checkadd: true,
      tabs: null,
      cmpShow: false,
      key: "",
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timer);
          this.exitModalLoading();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
  },
  async mounted() {
    let self = this;
    await this.$store.dispatch("infoNoneHome");
    await this.$store.dispatch("infoIrControls");
    //Subscribe
    this.onSubScribe();
    const promise = await self.lastStatus();
    console.log(promise)
    if (promise) {
      console.log("test started")
      let details = {
        title: this.title,
        menu: true,
        menuAdd: true,
        pin: false,
        editName: true,
        del: true,
        noneHome: true,
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'irAirNone');
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    getAirControlCmpCallback(msg) {
      this.checkAirList(msg);
    },
    getAddClickCallback(msg) {
      if (msg == "add") {
        this.$router.push({
          name: "irairlistnone",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    deleteControlKey(key) {
      console.log("key", key)
      let self = this;
      var message = getCommand.getCommandClearKey(this.count, key);
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          "256"
      );
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            console.log("connection before", connection)
            setTimeout(function () {
              console.log("connection", connection)
              if (!connection) {
                swal("", self.$t('message.public.operation_failure'), "error");
                self.exitModalLoading();
              }
            }, 10000);
          },
          function (errr) {
            self.exitModalLoading();
          }
      );

    },
    checkAirList(msg) {
      let self = this;
      self.found_key = false;
      this.list_air_keys.forEach(function (item) {
        if (item.name == msg) {
          self.found_key = true;
          self.SendReguestTest(item.code);
        }
      });
      if (!this.found_key) {
        swal({
          title: self.$t('message.irAir.send'),
          text: self.$t('message.irAir.notfound'),
          type: "warning",
          confirmButtonColor: "#dc3545",
        });
      }
    },
    SendReguestTest(code) {
      let self = this;
      this.onSubScribe();
      var message = "IRTST" + "-" + code + "-END";
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        self.found_key = false;
        if (responseAjax(response)) {
          connection = false;
          var jsonArray = JSON.parse(response.responseText);
          self.found_key = false;
          self.exitModalLoading();
          // self.emptyMsg();
          timer = setTimeout(function () {
            // self.closeModal();
            if (!connection) {
              ErrorToastMsg.Msg();
            }
            connection = false;
          }, 5000);
        } else {
          //error
        }
      });
    },
    Done(value) {
      let self = this;
      var message = "CONTROL" + this.count + "-" + "KEY" + value + "-" + "DONE";
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          var jsonArray = JSON.parse(response.responseText);
          //  setTimeout(function(){ self.$emit("close"); }, 30000);
          connection = false;
          timer = setTimeout(function () {
            self.exitModalLoading();
            if (!connection) {
              ErrorToastMsg.Msg();
            }
            connection = false;
          }, 5000);
        } else {
          //error
        }
      });
    },
    async lastStatus() {
      let self = this;
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        control_uid: uid,
      };
      let URL = getUrl.controlInfo;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      return p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              var control = jsonArray.content.control[0];
              self.title = control.name;
              self.info = jsonArray.content.control[0].key_info;
              self.learned = jsonArray.content.control[0].learned;
              console.log("learn", jsonArray.content.control[0].learned);
              self.cmpShow = true;

              var key_info = jsonArray.content.control[0].key_info;
              var key_list_info = jsonArray.content.control[0].key_list_info;
              console.log("key_info", key_info);
              console.log("key_list_info", key_list_info);
              if (key_list_info !== 0) {
                self.list_air_keys = key_list_info;
              }
              key_info.forEach(function (item) {
                self.json.push({
                  control_count: item.control_count,
                  key_count: item.key_count,
                });
              });
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            } else {
              self.exitModalLoading();

              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
              return new Promise((resolve, reject) => {
                resolve(true);
              });
            }
          });
    },
    showGetMsg(msg) {
      var done = msg.includes("DONE");
      var clear = msg.includes("CLEAROK");
      var del = msg.includes("DELETEOK");
      var msg1 = BetweenStr.getBetweenStr(msg, "CONTROL", "-KEY");
      this.exitModalLoading();
      if (done) {
        if (msg1[1] == this.count) {
          // the instruction is true
          SucessToastMsg.Msg();
        }
      }
      if (del) {
        SucessToastMsg.Msg();
        this.$router.replace({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
      if (clear) {
        swal({
          title: this.$t('message.irAir.settings'),
          text: this.$t('message.irAir.delete'),
          type: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        this.lastStatus();
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    delItem(key) {
      this.$swal({
        title: this.$t('message.irAir.del_key'),
        text: this.$t('message.ir.txt'),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#0F6BB9",
        confirmButtonText: this.$t('message.public.delete'),
        cancelButtonText: this.$t('message.public.cancel'),
        allowOutsideClick: false,
      }).then(
          (result) => {
            if (result) {
              this.deleteControlKey(key);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    EditItem(key, name) {
      // const datairsenario = {
      //   name: name,
      // };
      // localStorage.setItem("infoIrStatus", JSON.stringify(datairsenario));
      console.log("Edit name", key);
      this.$router.push({
        name: "iraireditnone",
        params: {
          serial_number: this.serial_number,
          count: this.count,
          keyitem: key,
        },
      });
    },
    onSubScribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    getNameCmpCallback(msg) {
      let self = this;
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        name: msg,
        control_uid: uid,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.controlEdit;
      const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.lastStatus();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
      if (value == "add") {
        this.$router.push({
          name: "irairlistnone",
          params: {
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "del") {
        //  $("#modal-del-control-none").modal("show");
        this.$swal({
          title: this.$t('message.ir.delete'),
          text: this.$t('message.ir.txt_c'),
          type: "error",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#0F6BB9",
          confirmButtonText: this.$t('message.public.delete'),
          cancelButtonText: this.$t('message.public.cancel'),
          allowOutsideClick: false,
        }).then(
            (result) => {
              if (result) {
                this.deleteControl();
              }
            },
            (dismiss) => {
              if (dismiss === "cancel") {
                console.log("dissmis", dismiss);
              } else {
                throw dismiss;
              }
            }
        );
      }
      if (value == "edit") {
        $("#modal-edit-name").modal("show");
      }
      if (value == "refresh") {
        this.lastStatus();
      }
    },
    deleteControl() {
      let self = this;
      let saction = getCommand.getCommandDeleteControl(this.count);
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            setTimeout(function () {
              if (!connection) {
                swal("", this.$t('message.public.operation_failure'), "error");
                self.loading = false;
                self.exitModalLoading();
              }
            }, 5000);
          },
          function (errr) {
            self.exitModalLoading();
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
