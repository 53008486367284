export const getTopics = {

    getTopicActionSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~action"
    },
    getTopicActionReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/action"
    },
    getTopicElevatorSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~broadcast"
    },
    getTopicElevatorReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/setting"
    },
    getTopicVoltSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~volt'
    },
    getTopicVoltReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/volt'
    },
    getTopicTempSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~temp'
    },
    getTopicTempReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/temp'
    },
    getTopicAmpSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~amp'
    },
    getTopicAmpReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/amp'
    },
    getTopicResetSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~reset'
    },
    getTopicResetReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/reset'
    },
    getTopicAtvcSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~atvc'
    },
    getTopicAtvcReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/atvc'
    },
    getTopicBroadcastSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~broadcast'
    },

    getTopicBroadcastReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/setting'
    },
    getTopicTimerSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~timer'
    },
    getTopicTimerReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + '/timer'
    },
    getTopicDelaySend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~delay'
    },
    // getTopicDelayReceive: function () {
    //   return getStrTopic(Gsn, sn,false) + '/delay'
    // },
    getTopicClockSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + '/~clock'
    },
    getTopicScenarioSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~scenario"
    },
    getTopicScenarioReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/scenario"
    },
    getTopicToggleSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~broadcast"
    },
    getTopicToggleReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/setting"
    },
    getTopicSettingReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/setting"
    },
    ////////////////////////////////////
    getTopicSetingReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/seting"
    },
    ////////////////////////////////////////
    getTopicSettingSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~setting"
    },
    getTopicUpdateReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/update"
    },
    getTopicUpdateSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~update"
    },
    getTopicSecurityReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/security"
    },
    getTopicSecuritySend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~security"
    },
    getTopicRemoteReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/remote"
    },
    getTopicRemoteSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~remote"
    },
    getTopicSensorReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/sensor"
    },
    getTopicSensorSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~sensor"
    },
    getTopicZoneReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/zone"
    },
    getTopicZoneSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~zone"
    },
    getTopicRelayReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/relay"
    },
    getTopicRelaySend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~relay"
    },
    getTopicRgbReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/rgb"
    },
    getTopicRgbSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~rgb"
    },
    getTopicAssistantReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/assistant"
    },
    getTopicAssistantSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~assistant"
    },
    getTopicConvertSend: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, true) + "/~convert"
    },
    getTopicConvertReceive: function (Gsn, sn) {
        return getStrTopic(Gsn, sn, false) + "/convert"
    },
    
};

function getStrTopic(gatwaysn, sn, send) {
    var topic;
   
    if (send) {
        topic =  process.env.VUE_APP_BASE_MTT +'/'+ gatwaysn + "/send/" + sn;
    } else {
        topic =  process.env.VUE_APP_BASE_MTT + '/' + gatwaysn + "/receive/" + sn;
    }

    return topic
}


