<template>
  <!-- .wrapper  -->
  <!-- .wrapper  -->
 <div class="wrapper"   style="height:100% !important">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i
              class="ti-angle-right"
              style="font-size: 24px"
              @click="goBackDimmer()"
            ></i>
            <div class="media-body p-0">
              <strong>{{ title }}</strong>
            </div>
          </div>
        </div>
           <div class="navbar-custom-menu" v-if="main_admin == 1">
          <ul class="nav navbar-nav">
            <!-- Control Sidebar Toggle Button -->
            <li class="dropdown">
              <a data-toggle="dropdown" href="#" aria-expanded="false"
                ><i class="ti-more-alt rotate-100"></i
              ></a>
              <div
                class="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style="
                  position: absolute;
                  transform: translate3d(-43px, 21px, 0px);
                  top: 0px;
                  left: 50px;
                  will-change: transform;
                "
              >
                <a class="dropdown-item" v-on:click.prevent="subEditShow()">
                  <i class="mdi mdi-border-color"></i>{{$t('message.CmpEditName.edit_name')}}</a
                >
                <a class="dropdown-item" v-on:click.prevent="subDelShow()">
                  <i class="mdi mdi-delete"></i> {{$t('message.public.delete')}}</a
                >
                <!-- <a class="dropdown-item" v-on:click.prevent="subTimerShow()">
                  <i class="mdi mdi-clock"></i> زمانبندی</a
                > -->
                <!-- <a class="dropdown-item" @click="aboutSub()">
                  <i class="mdi mdi-information-outline"></i> درباره دستگاه
                </a> -->
                <a class="dropdown-item" v-on:click.prevent="subPassShow()">
                  <i class="mdi mdi-lock"></i>{{$t('message.ModalAddHome.pin_code')}}
                  <span
                    v-if="this.$store.getters.getSetPasswordSub == 1"
                    class="badge badge-success badge-sm p-2 ml-2 mr-2"
                    >{{$t('message.AppBar.active')}}</span
                  >
                  <span
                    v-if="this.$store.getters.getSetPasswordSub == 0"
                    class="badge badge-danger ml-2 mr-2 p-2"
                    >{{$t('message.AppBar.unActive')}}</span
                  >
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- Monitor air conditioner -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body">
                <div class="col-12 col-lg-12">
                  <div class="box-body text-center"></div>
                  <ul class="flexbox flex-justified text-center">
                    <li>
                      <h6 class="mb-0 text-bold"></h6>
                    </li>
                    <li>
                      <!-- <h6 class="mb-0 text-bold"> -->
                      <round-slider
                        dir="ltr"
                        v-model="sliderValue"
                        start-angle="315"
                        end-angle="+270"
                        line-cap="butt"
                        radius="120"
                        min="0"
                        max="100"
                        style="margin: auto;stroke-dasharray: 2 8;"
                        pathColor="#CFD8DC"
                        rangeColor="#007bff"
                        stroke-linecap="butt"
                        stroke-width="18"
                      />
                      <!-- </h6> -->
                    </li>
                    <li>
                      <h6 class="mb-0 text-bold"></h6>
                    </li>
                  </ul>
                  <ul
                    class="
                      flexbox
                      flex-justified
                      text-center
                      px-0">
                    <li>
                      <h6 class="mb-0 text-bold">
                       <!-- <i
                        v-if="powershow==true"
                              class="mdi mdi-power mdi-24px"
                              id="imgpower"
                              style="color: #20c997 !important"
                            ></i>
                       <i
                       v-else
                              class="mdi mdi-power mdi-24px"
                              id="imgpower"
                              style="color: #dc3545 !important;opacity: 1!important"
                            ></i> -->
                      </h6>
                      <span class="font-size-18">{{status}}</span>
                    </li>
                  </ul> 
                </div>
              </div>
            </div>
            <div class="box100">
              <div class="box-body">
                <div class="text-center py-1">
                  <ul
                    class="
                      box-body
                      flexbox
                      flex-justified
                      text-center
                      ml-20
                      mr-20
                      px-40
                    "
                  >
                    <li>
                      <button
                       
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-outline-primary mb-5"
                        @click="dim100()"
                      >
                        <img
                          id="imgfanlow"
                          src="/assets/images/smart/dim90.svg"
                          style="width: 24px; height: 24px"
                         
                        /><br>
                        <p style="margin:auto;font-size:12px">100%</p>
                      </button>
                      <!-- <button
                      v-else
                        type="button"
                          style="width: 55px; height: 55px"
                        class="btn btn-default mb-5"
                        @click="dim100()"
                      >
                        <img
                          id="imgfanlow"
                          src="/assets/images/smart/dim100.svg"
                          style="width: 24px; height: 24px"
                          
                        />
                      </button> -->
                    </li>
                    <li>
                      <button
                        
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-outline-primary mb-5"
                        @click="dim50()"
                      >
                        <img
                          id="imgfanlow"
                          src="/assets/images/smart/dim60.svg"
                          style="width: 24px; height: 24px"
                        /><br>
                         <p style="margin:auto;font-size:12px">50%</p>
                      </button>
                      <!-- <button
                      v-else
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-default mb-5"
                        @click="dim50()"
                      >
                        <img
                          id="imgfanlow"
                          src="/assets/images/smart/dim50.svg"
                          style="width: 24px; height: 24px"
                        />
                      </button> -->
                    </li>
                    <li>
                      <button
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-outline-primary mb-5"
                        @click="dim25()"
                      >
                        <img
                          id="imgfanmeduim"
                          src="/assets/images/smart/dim30.svg"
                          style="width: 24px; height: 24px"
                        />
                         <p style="margin:auto;font-size:12px">25%</p>
                      </button>
                      <!-- <button
                         v-else
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-default mb-5"
                        @click="dim25()"
                      >
                        <img
                          id="imgfanmeduim"
                          src="/assets/images/smart/dim25.svg"
                          style="width: 24px; height: 24px"
                        />
                      </button> -->
                    </li>
                    <li>
                      <h6 class="mb-0 text-bold"></h6>
                      <button
                      
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-outline-primary mb-5"
                        @click="dimOff()"
                      >
                        <img
                          id="imgfanfast"
                          src="/assets/images/smart/dimoff.svg"
                          style="width: 24px; height: 24px"
                        />
                         <p style="margin:auto;font-size:12px">0%</p>
                      </button>
                      <!-- <button
                      v-else
                        type="button"
                         style="width: 55px; height: 55px"
                        class="btn btn-default mb-5"
                        @click="dimOff()"
                      >
                        <img
                          id="imgfanfast"
                          src="/assets/images/smart/dimoff.svg"
                          style="width: 24px; height: 24px"
                        />
                      </button> -->
                    </li>
                  </ul> 
                </div>
                <div class="text-center py-1">
                  <ul class="flexbox flex-justified text-center my-10">
                    <li class="px-10 mb-20" style="bottom:0">
                      <button
                        type="button"
                        class="btn btn-primary btn-lg mb-5 px-70"
                        @click="saveLearn()">
                        {{$t('message.public.send')}}
                      </button>
                    </li>
                  </ul>
                  <ul>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- <ModalIr
      v-show="isModalVisibleIr"
      v-bind:data_infos="data_info"
      @close="closeModalIr"
    >
    </ModalIr> -->
  </div>
  <!-- /. Wrapper -->
</template>

 <script>
import { scaleService } from 'chart.js';
// import SubModalDel from "../../subdevice/SubModalDel";
// import SubModalEdit from "../../subdevice/SubModalEdit";
// import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {BetweenStr, ErrorToastMsg, Internets, SucessToastMsg} from "../../../../public/assets/js/utils/util.js";
// import { BetweenStr } from "../../../../public/assets/js/utils/util.js";
// import { SucessToastMsg } from "../../../../public/assets/js/utils/util.js";
// import { ErrorToastMsg } from "../../../../public/assets/js/utils/util.js";

import RoundSlider from "vue-round-slider";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";

var connection = false;
var status_admin = localStorage.getItem("status_admin");
var tapp = localStorage.getItem("t_app");
var status;
var door_status;
var set_password;
var showToast;
var timeout;

export default {
  components: {
    // SubModalDel,
    // SubModalEdit,
    // SubModalSetPass,
     RoundSlider,
  },
  props: {
    // home_id: {},
    // serial_number: {},
    // count: {},
    // name:{}
  },
  data() {
    return {
         sliderValue: 0,
         dim100Show:false,
         dim50Show:false,
         dim25Show:false,
         dimoffShow:false,
        //  powershow:false,
         status:"",
         main_admin: "",
    //   passdoor: "",
    //   data_info_door: [this.home_id, this.serial_number, this.count], 
      title:'',
    };
  },
  mqtt: {
     "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
            clearTimeout(timeout);
          this.exitModal();
           if(showToast==1){
          SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    this.status=this.$t('message.public.off')
//     try {
//     this.$store.dispatch("InfoSubDevice");
//     this.$store.dispatch("infoHome");
//     set_password = this.$store.getters.getSetPasswordSub;
//      this.main_admin=this.$store.getters.getadmin;
//      this.title=this.$store.getters.getNameSub;
//     var tapp = localStorage.getItem("t_app");
//     var topic1;
//     if (tapp == 1) {
//       topic1 = "rnd/" + this.serial_number + "/r/" + this.count + "/action";
//     } else {
//       topic1 =
//         "farahoush/" +
//         this.serial_number +
//         "/receive/" +
//         this.count +
//         "/action";
//     }
//     this.$mqtt.subscribe(topic1);
// }
// catch(err) {
//  let self=this;
//         swal({
//        title: "",
//         text: "در انجام فرایند خطایی رخ داده است ",
//         type: "error",
//         confirmButtonColor: "#dc3545",
//          confirmButtonText: "تایید",
//         closeOnConfirm: false,
//      }, function (isConfirm) {
//          if (isConfirm) {
//          self.$router.push({ name: "homeDevices" ,params: { home_id: self.home_id, serial_number: self.serial_number },});
//          swal.close();
          
//          } 
//      });
// }
    
  },
  methods: {
    subDelShow() {
      $("#modal-del-sub").modal("show");
    },
    subEditShow() {
      $("#modal-edit-sub").modal("show");
    },
    subPassShow() {
      $("#modal-set-pass-sub").modal("show");
    },
    dim25(){
      // this.dim100Show=false;
      // this.dim25Show=true;
      // this.dim50Show=false;
      // this.dimoffShow=false;
      // this.powershow=true;
      this.status=this.$t('message.public.on');
      this.sliderValue=25;
    },
    dim50(){
      // this.dim50Show=true;
      //  this.dim25Show=false;
      //  this.dim100Show=false;
      // this.dimoffShow=false;
      //  this.powershow=true;
      this.status=this.$t('message.public.on');
      this.sliderValue=50;
    },
    dim100(){

      // this.dim25Show=false;
      //  this.dim50Show=false;
      //  this.dim100Show=true;
      //  this.dimoffShow=false;
        // this.powershow=true;
      this.status=this.$t('message.public.on');
      this.sliderValue=100;
    },
    dimOff(){
      //  this.dim100Show=false;
      // this.dim25Show=false;
      // this.dim50Show=false;
      //  this.dimoffShow=true;
        // this.powershow=false;
      this.status=this.$t('message.public.off');
      this.sliderValue=0;
    },
    sendMessage(saction, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var device_serial = this.serial_number;
      var count = this.count;
      var password=this.passdoor;
      var topic_final;
      var topic_final = getTopics.getTopicActionSend(device_serial, count);
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModal();
       showToast=1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
            var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.openModal();
          connection=false;
              $("#modal-pass-door").modal("hide");
           timeout= setTimeout(function () {
              self.exitModal();
              if (!connection) {
                if(showToast==1){
                 ErrorToastMsg.Msg();
               } 
              }
               showToast=0;
                connection=false;
            }, 2500);
          } else {
            self.exitModal();
              if (response.status == 401){
              if(jsonArray.code == '4001'){
                 self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
        }
      );
    },
    openModal() {
      this.$parent.showModal();
    },
    exitModal() {
      this.$parent.closeModal();
    },
    sendPassDoor() {
       if (!passHomeValidationInput(this.passdoor)) return;
      this.sendMessage(door_status, this.passdoor);
      this.passdoor = "";
    },
    //  goBackDimmer(){
    //  this.$router.push({ name: "homeDevices" ,params: { home_id: this.home_id, serial_number: this.serial_number },});

    // }
  },
};
</script>
<style scoped>
</style>
