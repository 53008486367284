<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content mt-5">
        <!-- row -->
        <div class="row px-5" v-bind:key="index" v-for="(infos, index) in info">
          <div class="col-12 col-lg-12 py-0 mt-2">
            <div class="box">
              <div class="box-body">
                <div class="navbar-custom-menu" id="setting_curtain">

                </div>
                <table class="table borderless" role="grid">
                  <tbody>
                  <tr role="row">
                    <td class="sorting_1">
                      <span v-if="infos.admin == 1" class="label label-success"
                      >{{ $t('message.public.manager') }}</span
                      >
                      <span
                          v-if="infos.admin == 0"
                          class="label label label-warning"
                      >{{ $t('message.public.guest') }}</span
                      >
                      <strong class="px-2"> {{ infos.fname }} {{ infos.lname }}</strong>
                    </td>

                    <td></td>
                    <td>
                      <div v-if="userphone==infos.mobile">
                      </div>
                      <div v-else>
                        <ul class="box-controls pull-right">
                          <!-- Control Sidebar Toggle Button -->
                          <li class="dropdown">
                            <a data-toggle="dropdown" href="#" aria-expanded="false"
                            ><i class="ti-more-alt rotate-90"></i
                            ></a>
                            <div
                                class="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                                style="
                  position: absolute;
                  transform: translate3d(-43px, 21px, 0px);
                  top: 0px;
                  left: 50px;
                  will-change: transform;
                "
                            >
                              <a class="dropdown-item" v-if="infos.admin == 0 && admin_main==1"
                                 v-on:click.prevent="setAdmin(infos.mobile)">
                                <i class="mdi mdi-account"></i>{{ $t('message.client.changeToM') }}
                              </a>
                              <a class="dropdown-item" v-if="infos.admin == 0 && admin_main==1"
                                 v-on:click.prevent="setAdminEmail('Email')">
                                <i class="mdi mdi-account"></i>{{ $t('message.client.changeToMEmail') }}
                              </a>
                              <a class="dropdown-item" v-if="infos.admin == 1 && admin_main==1"
                                 v-on:click.prevent="setClient(infos.mobile)">
                                <i class="mdi mdi-account"></i>{{ $t('message.client.changeToG') }}
                              </a>
                         
                              <a class="dropdown-item" v-if="infos.admin == 1 && admin_main==1"
                                 v-on:click.prevent="setClientEmail('Email')">
                                <i class="mdi mdi-account"></i>{{ $t('message.client.changeToGEmail') }}
                              </a>
                              <a class="dropdown-item" v-if="infos.admin == 0 && admin_main==1"
                                 v-on:click.prevent="delClient(infos.mobile)">
                                <i class="mdi mdi-delete"></i>{{ $t('message.public.delete') }}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";
// import { Internets } from "../../public/assets/js/utils/util.js";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  data() {
    return {
      info: "",
      userphone: '',
      homeid: this.home_id,
      data_info: [this.home_id],
      admin_main: '',
      type: '',
      lang: '',
      isModalVisible: false,
      appMode:'farahoush'
    };
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoUser");
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    this.admin_main = this.$store.getters.getadmin;
    this.appMode=process.env.VUE_APP_MODE
    let details = {
      title: this.$t('message.client.management'),
      menu: this.$store.getters.getadmin == 1,
      menuAdd:true,
      menuAddEmail:true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneClient');
    this.type = this.$store.getters.getTypeGatway;
    var data = {
      user_id: user_id,
      token: token,
      home_id: this.home_id
    };
    let URL = getUrl.clientIndex;
    const p = postAjaxCall(URL, data, "");
    this.openModalLoading();
    p.then(function (response) {
      var jsonArray = JSON.parse(response.responseText);

      if (responseAjax(response)) {
        self.closeModalLoading();
        self.info = jsonArray.content.clients;
        self.userphone = this.$store.getters.getUserPhone;
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({name: "home"});
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      console.log("val",val)
      if (val === "back") {
        this.backMagmentClient()
      } 
      if (val === "add"){
        console.log("appMode",this.appMode)
          // if(this.appMode=="farahoush"){
            this.addUser()
          // }  
      }
      if(val==="menuAddEmail"){
        this.addUserEmail()
      } 
    }
  },
  methods: {
    addUser() {
      this.$router.push({
        name: "AddClientinfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type
        },
      });
    },
    addUserEmail() {
      console.log("addUserEmail")
      this.$router.push({
        name: "AddClientEmailInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          // type: this.type
        },
      });
    },
    delClient(mobile) {

      //  this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "DelClientinfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type
        },
      });
    },
    showModal() {
      //   this.isModalVisible0 = true;
      $("#modal-add-client").modal("show");
    },
    closeModal() {
      //   this.isModalVisible0 = false;
      $("#modal-add-client").modal("hide");
    },
    setAdmin(mobile) {

      // this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "SetAdmin",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type
        },
      });
    },
    setAdminEmail(email) {

// this.data_info.push(mobile);
// localStorage.setItem("mobile", mobile);
this.$router.push({
  name: "SetAdminEmail",
  params: {
    home_id: this.home_id,
    serial_number: this.serial_number,
    type: this.type
  },
});
},
    
    setClient(mobile) {

      // this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "SetClient",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type
        },
      });
    },
    setClientEmail(email) {
    //  localStorage.setItem("mobile", mobile);
    this.$router.push({
     name: "SetClientEmail",
     params: {
    home_id: this.home_id,
    serial_number: this.serial_number,
    type: this.type
  },
});
},
    
    backMagmentClient() {
      if (this.type == 7) {
        this.$router.push({
          name: "settings", params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (this.type == 9) {
        this.$router.push({
          name: "settingsir", params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>