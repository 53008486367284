export const getCommand = {

  getCommandOrder: function (count, key,status, code) {
    return getStrCommand(count, key,status, code, 'order') + "-END"
  },
  getCommandLearn: function (count, key) {
    return getStrCommand(count, key) + "-LEARN"
  },
  getCommandLearnAir: function (count, key,status ) {
    return getStrCommand(count=count, key,status,'','air') + "-END"
  },
  getCommandDone: function (count, key) {
    return getStrCommand(count, key) + "-DONE"
  },
  getTimerCommand: function (count, key) {
    return getStrCommand(count, key)
  },
  getCommandCancelLearn: function (count, key) {
    return getStrCommand(count, key) + "-CANCELREAD"
  },
  getCommandIrTest: function (code) {
    return   "IRTST" + "-" + code + "-END";
  },
  getCommandClearKey: function (count, key) {
    return getStrCommand(count, key) + "-CLEAR"
  },
  getCommandDeleteControl: function (count) {
    return "CONTROL" + count + "-DELETE"
  },
  // IR Gateway Scenario
  getCommandIrScenario: function (serial, count, value) {
    return getStrCommandScenario(serial, count, value)
  },
};

function getStrCommand(count, key, status='',code='', type='') {
  var message;
  if (type == 'order') { 
    message = 'CONTROL' + count + '-KEY' + key +'-MSG-'+ status+ '-ORDER-' + code
  }
  else if (type == 'air') { 
    message = 'CONTROL' + count + '-KEY' + key +'-LEARN-'+ status
  }
  else {
    message = 'CONTROL' + count + '-KEY' +key 
  }
  return message
};

function getStrCommandScenario(serial, count, value,type) {
  var message;
    message= "<" + serial + "&CONTROL" +  count + "-KEY" + value + ">";



  return message
};



