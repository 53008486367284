import { render, staticRenderFns } from "./NCurtainAbout.vue?vue&type=template&id=759c7a66&scoped=true"
import script from "./NCurtainAbout.vue?vue&type=script&lang=js"
export * from "./NCurtainAbout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "759c7a66",
  null
  
)

export default component.exports