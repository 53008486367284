<template>
  <v-app class="bg">
    <v-container>
      <div class="mt-5">
        <v-row class="justify-center px-3 py-0 text-center">
          <v-col cols="12" md="4" class="py-0">
            <img :src="'/assets/images/smart/img_curtain.png'"/>
          </v-col>
          <v-card class="mx-2 mt-5 py-5">
            <v-col cols="12" class="py-0 text-center  px-10">
              <v-row class="py-0 text-center">
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn
                      fab
                      large
                      outlined
                      color="primary"
                      elevation="3"
                      @click="openFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>

                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5">
                  <v-btn

                      fab
                      x-large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="stopFunction()"
                  >
                    <v-icon class="text-primary">mdi-pause</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn

                      fab
                      large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="closeFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>

      </div>
    </v-container>
    <SubModalDel v-bind:data_info_sub="data_info_curtain"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_curtain"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_curtain"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var status_admin = localStorage.getItem("status_admin");
var tapp = localStorage.getItem("t_app");
var password_code;
var status;
var curtain_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {},
  },
  data() {
    return {
      data_info_curtain: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      set_password: "",
      pass: true,
      title: "",
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.exitModal();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    try {
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      console.log("this.set_password",this.set_password)
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;

      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.count
      );

      this.$mqtt.subscribe(topic1);
    } catch (err) {
      let self = this;
      swal({
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      timer: true,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true,
      about:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCurtain');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
            if (value == "timer") {
        this.$router.push({
          name: "CurtainRFTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    openFunction() {
     
      if (this.set_password == 1) {
        curtain_status = "OPEN#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("OPEN#", "");
      }
    },
    stopFunction() {
      if (this.set_password == 1) {
        curtain_status = "STOP#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("STOP#", "");
      }
    },
    closeFunction() {
      if (this.set_password == 1) {
        curtain_status = "CLOSE#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("CLOSE#", '');
      }
    },
    sendReq(password) {
      this.sendMessage(curtain_status, password);
    },
    sendMessage(saction, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModal();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModal();
              $("#modal-pass-code").modal("hide");
              connection = false;
              timeout = setTimeout(function () {
                self.exitModal();

                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
  
  },
};
</script>
<style scoped>
img {
  display: block;
  object-fit: cover;
}
</style>
