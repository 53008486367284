<template>
  <v-app class="bg">
    <div>
      <v-container class="mt-2">
        <v-row>
          <v-col
              lg="4"
              class="py-0"
              :key="index" v-for="(subRelaysItem, index) in subRelaysItems">
            <CmpRowList
                :infoItem="subRelaysItem"
                :subTitleShow="true"
                :ripple="false"
            >
              <v-card v-if="subRelaysItem.delay >= 1" class="px-3 pt-2">
                <v-icon class="text-primary px-1" small>mdi-timer</v-icon>
                <label>{{ subRelaysItem.delay }}
                  {{ $t("message.public.seconds") }}</label>
              </v-card>
              <v-btn
                  :ripple="false"
                  v-if="subRelaysItem.delay >= 1"
                  fab
                  color="primary"
                  elevation="0"
                  @click="
                instantSetting(
                  subRelaysItem.delay,
                  subRelaysItem.numbers,
                  subRelaysItem.keys_status
                )
              "
              >
                <v-icon class="text-white">mdi-power</v-icon>
              </v-btn>
              <div v-if="subRelaysItem.delay == 0">
                <v-switch
                    v-if="subRelaysItem.number_relay == 1"
                    v-model="subRelaysItem.switch"
                    inset
                    @click="
                  clickSwitch(
                    subRelaysItem.switch,
                    subRelaysItem.numbers,
                    subRelaysItem.keys_status
                  )
                "
                >
                </v-switch>
                <div v-if="subRelaysItem.number_relay == 2">
                <!-- {{subRelaysItem.switch}} -->
                  <v-btn
                      class="mx-1"
                      fab
                      outlined
                      color="primary"
                      elevation="0"
                      @click="
                    click2modes(
                      false,
                      subRelaysItem.numbers,
                      subRelaysItem.keys_status
                    )
                  "
                  >
                    <v-icon class="text-gray" v-if="subRelaysItem.switch == 2"
                    >mdi-arrow-up-bold
                    </v-icon
                    >
                    <v-icon class="text-primary" v-else-if="subRelaysItem.switch"
                    >mdi-arrow-up-bold
                    </v-icon
                    >
                    <v-icon class="text-gray" v-else>mdi-arrow-up-bold</v-icon>
                  </v-btn>
                  <v-btn
                      fab
                      class="mx-1"
                      outlined
                      color="primary"
                      elevation="0"
                      @click="
                    click2modes(
                      true,
                      subRelaysItem.numbers,
                      subRelaysItem.keys_status
                    )
                  "
                  >
                  
                    <v-icon class="text-gray" v-if="subRelaysItem.switch == 2">
                      mdi-arrow-down-bold
                    </v-icon>
                    <v-icon class="text-primary" v-else-if="!subRelaysItem.switch">
                      mdi-arrow-down-bold
                    </v-icon>
                    <v-icon class="text-gray" v-else>
                      mdi-arrow-down-bold
                    </v-icon>
                  </v-btn>
                  
                  <v-btn
                      class="mx-1"
                      fab
                      color="primary"
                      elevation="0"
                      @click="
                    click2modes(
                      'FF',
                      subRelaysItem.numbers,
                      subRelaysItem.keys_status
                    )
                  ">
                    <v-icon class="text-white">mdi-power</v-icon>
                   
                  </v-btn>
                </div>
              </div>
            </CmpRowList>
          </v-col>
        </v-row>
        <!-- </div> -->
        <CmpPasscode @sendPassCode="sendReq" @cancel="cancel"></CmpPasscode>
        <my-modal v-show="isModalVisible"></my-modal>
        <SubModalSetPass
            v-bind:data_info_sub="data_info_Relay"
            :device="'relay'">
        </SubModalSetPass>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import ActionSwitch from "@/components/Cmp/ActionSwitch.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import {BetweenStr, ErrorToastMsg, Internets, SucessToastMsg} from "../../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {getMessageSub} from "../../../../public/assets/js/utils/helper_sub_message.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import SubModalSetPass from "../../subdevice/SubModalSetPass"
import CmpPasscode from "../../Cmp/general/CmpPasscode"
import LayoutDefault from "../../../layouts/LayoutDefault";

var showToast;
var connection = false;
var timeout = false;
var timer;
export default {
  components: {
    ActionSwitch,
    CmpRowList,
    SubModalSetPass,
    CmpPasscode
  },
  props: {
    home_id: {},
    serial_number: {},
    sub_serial: {},
    click: {},
  },
  data() {
    return {
      serverInfoRelayItem: [],
      subRelaysItems: [],
      data_info_Relay: [this.home_id, this.serial_number, this.sub_serial],
      set_password: "",
      pass: true,
      statusAllInfo: [],
      isModalVisible: false,
      message: "",
      topic: "",
      count: "",
      msgStatus: [1, 1, 1, 1, 1, 1, 1, 1],
      keys_status: [],
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        if (BetweenStr.getBetweenStr(topic, "receive/", "/action")[1] === this.sub_serial) {
          connection = true;
          clearTimeout(timeout);
          this.showGetMsg(String(data));
        }
      }
    },

  },
  created() {
    let self = this;
    let data = JSON.parse(JSON.stringify(localStorage.getItem("infoSubDevice")));
    this.data = JSON.parse((localStorage.getItem("infoHome")))
    this.pass = this.data.password === 1;
    this.set_password = data;
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    let details = {
      title: this.$t('message.relay.relays'),
      pin: this.pass,
      passcode: this.set_password,
      menu: this.pass
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'BoxRelayItems');
    let self = this;
    setTimeout(function () {
      self.getRelaysItem();
    }, 50);

    var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.sub_serial
    );
    this.$mqtt.subscribe(topic1);
  },
  methods: {
    cancel() {
      this.getRelaysItem();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    getRelaysItem() {
      this.subRelaysItems = [];
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.sub_serial,
      };
      let URL = getUrl.relayItems;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.count = jsonArray.content.device.count;
          self.lastStausSub();
          self.serverInfoRelayItem = jsonArray.content.device.relays;
          self.keys_status = jsonArray.content.keys_status;
          self.serverInfoRelayItem.forEach(function (item, i) {
            if (item.numbers.length > 1) {
              self.msgStatus[item.numbers[0] - 1] = 0;
              self.msgStatus[item.numbers[1] - 1] = 0;
            }
            var text = "";
            for (let i = 0; i < item.numbers.length; i++) {
              text +=
                  self.$t("message.relay.relay_num") +
                  Array(1).fill("\xa0").join("") +
                  item.numbers[i] +
                  Array(1).fill("\xa0").join("");
            }
            self.subRelaysItems.push({
              index: i,
              title: item.name,
              img: item.type.logo_path,
              subTitle: text,
              icon: true,
              // actions: "switch",
              switch: false,
              numbers: item.numbers,
              number_relay: item.type.number_relay,
              keys_status: self.keys_status,
              delay: item.delay,
            });
          });
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "subboxrelay",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    instantSetting(delay, numbers, keys_status) {
      this.message = getMessageSub.getMessageInstantRelay(
          numbers,
          delay,
          keys_status
      );
      this.topic = getTopics.getTopicDelaySend(
          this.serial_number,
          this.sub_serial
      );
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.message, this.topic, '');
      }
      // ----------------------Data------------------//
    },
    clickSwitch(status, numbers, keys_status) {
      this.message =
          getMessageSub.getMessageActionRelay(status, numbers) +
          keys_status.join("") +
          "//";
      this.topic = getTopics.getTopicActionSend(
          this.serial_number,
          this.sub_serial
      );
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.message, this.topic, '');
      }
    },
    click2modes(status, numbers, keys_status) {
      this.message =
          getMessageSub.getMessageActionRelay(status, numbers) +
          keys_status.join("") +
          "//";
      this.topic = getTopics.getTopicActionSend(
          this.serial_number,
          this.sub_serial
      );
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.message, this.topic, '');
      }
    },
    sendReq(password) {
      this.sendRequest(this.message, this.topic, password);
    },
    sendRequest(message, topic, password) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Request----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Response--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
                self.getRelaysItem();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.getRelaysItem();
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showGetMsg(msg) {
      this.closeModalLoading();
      SucessToastMsg.Msg();
      this.parseMsgRelay(msg);
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.sub_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    lastStausSub() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;

      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);

      // this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            self.closeModalLoading();
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              if (message == "NOTSET") {
              } else {
                self.parseMsgRelay(message);
              }
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    parseMsgRelay(message) {
      let self = this;
      const relayMg = String(message).split("");
      this.subRelaysItems.forEach(function (item, i) {
        var num0 = item.numbers[0];
        var num1 = item.numbers[1];

        if (item.numbers.length > 1) {
          var close = relayMg[--num0];
          var open = relayMg[--num1];
          if (parseInt(open)) {
            item.switch = 0;
          } else if (parseInt(close)) {
            item.switch = 1;
          } else if (!parseInt(open) && !parseInt(close)) {
            item.switch = 2;
          }
        } else if (item.numbers.length == 1) {
          if (relayMg[--num0] == 0) item.switch = false;
          else item.switch = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.v-btn--fab.v-size--default {
  width: 36px;
  height: 36px;
}
</style>
