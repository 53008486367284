<template>
    <div>
      <v-container max-width="100%" v-show="isShow" v-if="length === 0">
        <v-row>
          <v-col
              cols="12"
              class="d-flex flex-column justify-content-center align-center">
            <img
                src="/assets/images/smart/timenone.svg"
                style="width: 300px; height: 300px"
                class="align-self-center"
            />
            <br/>
            <label class="mt-0"><strong>{{ $t('message.timer.scheduler_not_add') }}</strong></label>
            <label class="text-center">
              {{ $t('message.timer.txt_1') }}
            </label>
            <v-btn
                color="primary"
                class="btn btn-primary btn-sm text-white"
                elevation="3"
                v-on:click.prevent="addTimer()"
            ><span class="text-white"> {{ $t('message.timer.scheduler_add') }}</span></v-btn>
          </v-col>
          <v-col
              cols="12"
              class="fixed-bottom"
          >
            <v-sheet class="alert bg-white p-0 m-4 d-flex flex-column align-center" style="border-radius:12px">
              <label class="text-center mt-2 mb-4">
                {{ $t('message.timer.txt_2') }}
              </label>
              <v-btn
                  color="#36bea6"
                  class="btn btn-success mt-4 mb-5"
                  elevation="3"
                  v-on:click.prevent="addClock()"
              ><span class="text-white"> {{ $t('message.timer.setTime_device') }}</span></v-btn>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row class="mt-0">    
          <TimerItem
              v-bind:key="index"
              v-for="(info, index) in infos"
              :infos="info"
              :type="type"
              @deleteTimer="deleteTimer(info.count)"
          />
        </v-row>
      </v-container>
    </div>
</template>

<script>
import TimerItem from "./TimerItem.vue"
// import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "timer",
  components: {
    TimerItem
  },
  props: {
    infos: {},
    isShow: {},
    length: {},
    type: {},
    time: {},
  },
  data() {
    return {
      onData: [],
    }
  },
  created() {  
    // this.$emit('update:layout', LayoutDefault);
    // this.$emit('update:update', 'Timer');
  },
  methods: {
    addTimer() {
      this.$emit('addTimer')
    },
    addClock() {
      this.$emit('addClock')
    },
    deleteTimer(countTimer) {
      console.log(countTimer)
      this.$emit('deleteTimer', countTimer);
    }
  }
};
</script>


<style scoped>

</style>