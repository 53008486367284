<template>
  <v-app class="bg">
    <v-container>
      <div :key="index" v-for="(subRelaysItem, index) in subRelaysItems">
        <CmpRowList :infoItem="subRelaysItem" :subTitleShow="true">
          <v-card v-if="subRelaysItem.delay >= 1" class="px-3 pt-2">
            <!-- <v-icon class="text-primary">mdi-timer-sand</v-icon> -->
            <v-icon class="text-primary px-1" small>mdi-timer</v-icon>
            <label>{{ subRelaysItem.delay }} {{ $t('message.public.seconds') }}</label>
          </v-card>
          <v-btn
              :ripple="false"
              v-if="subRelaysItem.delay >= 1"
              fab
              color="primary"
              elevation="0"
              @click="
                instantSetting(
                  subRelaysItem.delay,
                  subRelaysItem.numbers,
                  subRelaysItem.keys_status
                )
              "
          >
            <v-icon class="text-white">mdi-power</v-icon>
          </v-btn>
          <div   v-if="subRelaysItem.delay == 0"> 
          <v-switch
              v-if="subRelaysItem.numbers.length == 1"
              v-model="subRelaysItem.switch"
              inset
              @click="clickSwitch(subRelaysItem.switch, subRelaysItem.numbers,subRelaysItem.keys_status)"
          >
          </v-switch>
          
          <div v-if="subRelaysItem.numbers.length == 2">
                <v-btn
                    class="mx-1"
                    fab
                    outlined
                    color="primary"
                    elevation="0"
                    @click="
                  click2modes(
                    false,
                    subRelaysItem.numbers,
                    subRelaysItem.keys_status
                  )
                "
                >
                  <v-icon class="text-gray" v-if="subRelaysItem.switch == 2"
                  >mdi-arrow-up-bold
                  </v-icon
                  >
                  <v-icon class="text-primary" v-else-if="subRelaysItem.switch"
                  >mdi-arrow-up-bold
                  </v-icon
                  >
                  <v-icon class="text-gray" v-else>mdi-arrow-up-bold</v-icon>
                </v-btn>
                <v-btn
                    fab
                    class="mx-1"
                    outlined
                    color="primary"
                    elevation="0"
                    @click="
                  click2modes(
                    true,
                    subRelaysItem.numbers,
                    subRelaysItem.keys_status
                  )
                "
                >
                
                  <v-icon class="text-gray" v-if="subRelaysItem.switch == 2">
                    mdi-arrow-down-bold
                  </v-icon>
                  <v-icon class="text-primary" v-else-if="!subRelaysItem.switch">
                    mdi-arrow-down-bold
                  </v-icon>
                  <v-icon class="text-gray" v-else>
                    mdi-arrow-down-bold
                  </v-icon>
                </v-btn>
                
                <v-btn
                    class="mx-1"
                    fab
                    color="primary"
                    elevation="0"
                    @click="
                  click2modes(
                    'FF',
                    subRelaysItem.numbers,
                    subRelaysItem.keys_status
                  )
                ">
                  <v-icon class="text-white">mdi-power</v-icon>
                 
                </v-btn>
              </div>
        </div>
        </CmpRowList>
        <div v-if="subRelaysItem.delayShow">
          <v-row>
            <v-col class="px-0 mt-6">
              <v-icon class="text-primary"
              >mdi-timer-sand
              </v-icon
              >
            </v-col
            >
            <v-col class="px-0 mt-3">
              <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.relay.relay_momentary')"
                  solo
                  flat
                  hide-details="true"
                  v-model="subRelaysItem.delay"
                  class="text-center"
                  height="10"
                  style="width: 100px"
                  maxlength="2"
              >
              </v-text-field>
              <label class="text-lighter" style="font-size: 10px"
              >{{ $t('message.relay.unit_momentary') }} </label>
            </v-col>
          </v-row>
        </div>
      </div>
      <my-modal v-show="isModalVisible"></my-modal>
    
      <Modal8RelayResetFactory :infoItem="dataReset" v-if="CmpResetShow"></Modal8RelayResetFactory>
    </v-container>
  </v-app>
</template>

<script>
import ActionSwitch from "@/components/Cmp/ActionSwitch.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {getMessageSub} from "../../../../public/assets/js/utils/helper_sub_message.js";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import Modal8RelayResetFactory from "./noneModal8RelayResetFactory.vue";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout = false;
var timer;
export default {
  components: {
    ActionSwitch,
    CmpRowList,
    Modal8RelayResetFactory,
  },
  props: {
    serial_number: {},
    click: {},
  },
  data() {
    return {
      serverInfoRelayItem: [],
      subRelaysItems: [],
      statusAllInfo: [],
      isModalVisible: false,
      switch1: false,
      switchall: false,
      message: "",
      count: "",
      dataReset: "",
      CmpResetShow: false,
      toggle: "",
      toggleMode: "",
      toggleStatus: "",
      keys_status:[],
      msgStatus: [1, 1, 1, 1, 1, 1, 1, 1],
    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {

      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
      }
      
      this.showGetMsg(String(data));
      //  }
    },
    "+/+/receive/256/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
      }
     
      this.showGetMsg(String(data));
      //  }
    },
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    let data = {
      title: this.$t('message.relay.relays'),
      noneHome: true,
      noneHome_setting: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneHomeRelay');
  },
  mounted() {
    //  this.onSubscribe();
    // try {
    this.dataReset = this.serial_number;
    this.CmpResetShow = true;
    let self = this;
    setTimeout(function () {
      self.getRelaysItem();
    }, 50);
    var topic1 = getTopics.getTopicActionReceive(this.serial_number, 256);
    this.$mqtt.subscribe(topic1);
    // } catch (err) {}
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    click2modes(status, numbers, keys_status) {
      this.message =
          getMessageSub.getMessageActionRelay(status, numbers) +
          keys_status.join("") +
          "//";
      this.topic = getTopics.getTopicActionSend(
          this.serial_number,
          this.sub_serial
      );
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.message, this.topic, '');
      }
    },
    getRelaysItem() {
      this.subRelaysItems = [];
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      let URL = getUrl.noneRelayItems;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        console.log(jsonArray);
        if (responseAjax(response)) {
          self.closeModalLoading();
          self.lastStatusSub();
          // self.toggleShowStatus();
          self.serverInfoRelayItem = jsonArray.content.relays;
          self.keys_status = jsonArray.content.keys_status;
          self.serverInfoRelayItem.forEach(function (item, i) {
            if (item.numbers.length > 1) {
              self.msgStatus[item.numbers[0] - 1] = 0;
              self.msgStatus[item.numbers[1] - 1] = 0;
            }
            var text = "";
            for (let i = 0; i < item.numbers.length; i++) {
              text += self.$t('message.relay.relay_num') + Array(1).fill('\xa0').join('') + item.numbers[i];
            }
            if (item.delay == 0) {
              self.delayShow = false;
            }

            if (item.delay >= 1) {
              self.delayShow = true;
            }
            self.subRelaysItems.push({
              index: i,
              title: item.name,
              img: item.type.logo_path,
              subTitle: text,
              icon: true,
              // actions: "switch",
              switch: false,
              numbers: item.numbers,
              number_relay: item.type.number_relay,
              keys_status: self.keys_status,
              delay: item.delay,
              // delayShow: self.delayShow,
            });
          });
          self.closeModalLoading();
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    onClickChild(value) {
      console.log('value',value)
      if (value === "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
      if (value === 'noneHomeSetting') {
        let data = {type: 16,};
        localStorage.setItem("data_edit", JSON.stringify(data));
        this.$router.push({
          name: "SettingNone",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    showDelDialog(sn) {
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
              this.resetRelay(sn);
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    resetRelay(sn) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.loading = true;
      let saction = "RESET#";
      var topic_final = getTopics.getTopicResetSend(
          this.serial_number,
          256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            self.delRelay(sn)
          },
          function (errr) {
            //errr
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic);
    },
    delRelay(serial_number) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      this.openModalLoading()
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneDeviceDel;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              setTimeout(function () {
                self.subBoxRelays = [];
                self.closeModalLoading();
                self.$router.push({
                  name: "mainpage"
                })
              }, 500);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    clickSwitch(status, numbers, keys_status) {
      this.message = getMessageSub.getMessageActionRelay(status, numbers)+
          keys_status.join("") +
          "//";;
      this.sendRequest(this.message);
    },
    sendRequest(message) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var message = message;
      console.log(message, this.serial_number);
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number, 256);
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: this.passthermostat,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          // $("#modal-pass-thermostat").modal("hide");
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
                self.getRelaysItem();

                // self.statusAllInfo.switch = !self.statusAllInfo.switch;
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showGetMsg(msg) {
      SucessToastMsg.Msg();
      this.parseMsgRelay(msg);
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicToggleReceive(
          this.serial_number,
          256
      );
      this.$mqtt.subscribe(topic1);
    },
    instantSetting(delay, numbers,keys_status) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var message = getMessageSub.getMessageInstantRelay(
          numbers,
          delay,
          keys_status
      );
      var topic_final = getTopics.getTopicDelaySend(
          this.serial_number,
          256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: this.passthermostat,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          // $("#modal-pass-thermostat").modal("hide");
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();

              }
            }
            showToast = 0;
            connection = false;
          }, 4000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    lastStatusSub() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");

      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data, "");

      // this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            // self.exitModalLoading();
            if (responseAjax(response)) {
              let message = jsonArray.content.message;


              if (message == "NOTSET") {
              } else {
                self.parseMsgRelay(message);
              }
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    parseMsgRelay(message) {
      const relayMg = String(message).split("");
      this.subRelaysItems.forEach(function (item, i) {
        var num0 = item.numbers[0];
        var num1 = item.numbers[1];

        if (item.numbers.length > 1) {


          var close = relayMg[--num0];
          var open = relayMg[--num1];
          if (parseInt(open)) {
            item.switch = 0;
          } else if (parseInt(close)) {
            item.switch = 1;
          }else if (!parseInt(open) && !parseInt(close)) {
            item.switch = 2;
          }
        } else  if (item.numbers.length == 1) {
          if (relayMg[--num0] == 0) item.switch = false;
          else item.switch = true;
        }
      });

    },
  },
};
</script>
<style scoped>
</style>
