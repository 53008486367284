import { render, staticRenderFns } from "./CurtainEditRGBPro.vue?vue&type=template&id=2668c1fc&scoped=true"
import script from "./CurtainEditRGBPro.vue?vue&type=script&lang=js"
export * from "./CurtainEditRGBPro.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2668c1fc",
  null
  
)

export default component.exports