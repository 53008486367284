<template>
  <v-app class="bg">
    <v-container class="content mt-40 px-30">
      <v-row class="d-flex justify-content-center">
        <v-col cols="12" lg="8" class="">
          <v-card color="primary">
            <v-card-title class="justify-content-center">
              <v-avatar width="100" height="100" style="margin-top: -50px;" color="white">
                <img src="../../../public/assets/images/smart/profile.png" alt="">
              </v-avatar>
            </v-card-title>
            <v-card-text v-bind:key="index" v-for="(infos, index) in info" style="background-color: #ffffff">
              <v-row>
                <v-col cols="12" sm="4">
                  <div class="description-block">
                    <div class="d-flex justify-content-center" style="padding:5px">
                      <v-icon class="text-primary">mdi-account-circle</v-icon>
                      <h5 class="text-primary mb-0 mr-1">{{ $t('message.public.name') }}</h5>
                    </div>
                    <h5 class="text-black mb-0" style="padding: 5px;">{{ infos.fname + " " + infos.lname }}</h5>
                  </div>
                </v-col>

                <div class="col-sm-4">
                  <div class="description-block">
                    <div class="d-flex justify-content-center" style="padding:5px">
                      <v-icon class="text-primary">mdi-cellphone</v-icon>
                      <h5 class="text-primary mb-0 mr-1 mt-1">{{ $t('message.profile.tel') }}</h5>
                    </div>
                    <h5 class="text-black mt-2">{{ infos.mobile }}</h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="description-block">
                    <div class="d-flex justify-content-center" style="padding:5px">
                      <v-icon class="text-primary">mdi-email</v-icon>
                      <h5 class="text-primary mb-0 mr-1 mt-1">{{ $t('message.public.email') }}</h5>
                    </div>
                    <h5 class="text-black mt-2">{{ infos.email }}</h5>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="description-block">
                    <div class="d-flex justify-content-center" style="padding:5px">
                      <v-icon class="text-primary">mdi-map-marker-outline</v-icon>
                      <h5 class="text-primary mb-0 mr-1 mt-1">{{ $t('message.profile.address') }}</h5>
                    </div>

                    <h5 class="text-black mt-2">{{ infos.address }}</h5>
                  </div>
                </div>
              </v-row>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

    <div class="mt-5">
      <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="goToChangeEmail()"
                            >
                            <span class="text-white"> 
                              {{$t('message.profile.change_and_add_email')}}
                             </span>
                            </v-btn>
                            <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="goToChangePassword()"
                            >
                            <span class="text-white"> 
                              {{$t('message.profile.change_password')}}
                             </span>
                            </v-btn>
    </div>
    </v-container>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {},
  data() {
    return {
      info: null,
      title: '',
      isModalVisible: false,
    };
  },
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  created() {
    let data = {
      title: this.$t('message.profile.profile'),
      profile: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Profile');
  },
  mounted() {
    var self = this;
    setTimeout(function () {
      self.lastStatus()
    }, 300);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "mainpage"});
      }
      if (value == 'edit') {
        this.$router.push({name: "profiledit"});
      }

    },
    goToChangeEmail(){
      this.$router.push({name: "profilchangeemail"});
    },
    goToChangePassword(){
      this.$router.push({name: "profilchangepassword"});
    },
    lastStatus() {
      let self = this;
      //--------------------------------------  variable --------------------------------------//
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.profile;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              var content = jsonArray.content;
              self.closeModalLoading();
              self.info = content;

            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },

  },
};
</script>


<style scoped>
</style>
