<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="300px">
      <v-card>
        <v-card-title class="text-bold" style="font-size: 16px"
          ><label>{{
            $t("message.dialog.dialog_image.title")
          }}</label></v-card-title
        > 
        <v-divider dark></v-divider>
        <v-card-text style="height: 300px">
          <v-row>
            <v-col
              :key="index"
              v-for="(itemData, index) in data"
               class="d-flex child-flex"
              cols="3">
              <v-img
                :src="itemData.logo_path"
                :lazy-src="itemData.logo_path"
                aspect-ratio="1"
                @click="SelectImage(itemData)"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn  text @click="show = false">
           <label class="text-blue-gray">{{ $t("message.public.cancel") }}</label>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      selected: "",
    };
  },
  props: {
    value: Boolean,
    data: {},
  },
  watch: {
    showm1(val) {
    },
  },
  computed: {
    show: {
      get() {
       
        return this.value;
      },
      set(value) {

        this.$emit("input", value);
      },
    },
  },
  created() {
    let self=this
   setTimeout(() => {
          self.overlay = false
        }, 3000)
  

  },
  methods: {
    Save() {

      this.$emit("clicked", this.selected);
      this.show = false;
    },
    SelectImage(img) {
      this.selected = img;
      this.$emit("clicked", this.selected);
      this.show = false;
    },
  },
};
</script>
