import { render, staticRenderFns } from "./IrAirLearnNone.vue?vue&type=template&id=2fca58c6&scoped=true"
import script from "./IrAirLearnNone.vue?vue&type=script&lang=js"
export * from "./IrAirLearnNone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fca58c6",
  null
  
)

export default component.exports