<template>
  <!-- .wrapper  -->
  <div
    class="modal modal-fill fade"
    data-backdrop="false"
    id="modal-reset-factory-none-ir"
    tabindex="-1"
  >
    <div class="wrapper m-0">
      <section class="error-page bg-img mt-20 rtl">
        <div class="container px-40">
          <div
            class="
              row
              h-p100
              align-items-center
              justify-content-center
              text-center
            "
          >
            <div class="col-lg-7 col-md-10 col-12">
              <h1
                class="
                  text-warning
                  font-size-180 font-weight-bold
                  error-page-title
                "
              ></h1>
              <img
                src="/assets/images/smart/vector-reset-factory.svg"
                class="avatar-smart"
              />
              <br />
              <h4 class="text-danger mt-10">
                <strong>{{ $t("message.public.warning") }}</strong>
              </h4>
              <br />
              <h6 style="line-height: 25px !important">
                {{ $t("message.noneHome.reset_txt") }}
              </h6>
              <button
                id="btn-reset"
                type="button"
                class="btn btn-danger mb-5 mt-10 m-5"
                v-on:click.prevent="DelFunction()"
              >
                {{ $t("message.public.OK") }}
              </button>
              <button
                type="button"
                class="btn btn-dark mb-5 mt-10 m-5"
                v-on:click.prevent="CancelFunction()"
              >
                {{ $t("message.public.cancel") }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
      <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="txtloading">
                {{ $t("message.ModalCenter.loading") }}
              </h5>
            </div>
            <div class="modal-body">
              <div class="spinner-border textprimary" role="status">
                <span class="sr-only">{{
                  $t("message.ModalCenter.loading")
                }}</span>
              </div>
              <span class="pl-20" id="txtwait">{{
                $t("message.ModalCenter.wait")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
</template>
 <script>
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";

var connection = false;
//--------------------------------------  variable --------------------------------------//
import AppBar from "@/components/AppBar.vue";
import { getTopics } from "@/../public/assets/js/utils/topics.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  components: {
    AppBar,
  },
  data() {
    return {
      title: "",
      loading: false,
      device_serial: "",
    };
  },
  props: {
    serialnumbers: { type: Array },
  },
  mqtt: {
   
    "+/+/receive/256/reset"(data, topic) {
      console.log(data);
      if (topic.split("/")[1] === this.serialnumbers[0]) {
        this.checkMqtt(String(data));
        connection = true;
      }
    },
  },
  created() {
    this.title = this.$t("message.noneHome.reset");
    this.device_serial = this.serialnumbers[0];
  },
  mounted() {
    this.btnLoading();
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        $("#modal-reset-factory-none-ir").modal("hide");
      }
    },
    CancelFunction() {
      $("#modal-reset-factory-none-ir").modal("hide");
    },
    DelFunction() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.loading = true;
      let saction = "RESET#";
      // this.device_serial=this.serialnumbers[0];
      var topic_final = getTopics.getTopicResetSend(this.device_serial, "256");
      console.log("saction", saction);
      console.log("topic_final", topic_final);
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          self.MqttSend();
          setTimeout(function () {
            if (!connection) {
              swal("", self.$t("message.public.operation_failure"), "error");
            }
          }, 10000);
        },
        function (errr) {
          loading.style.visibility = "hidden";
          err.innerHTML = JSON.parse(errr).message;
        }
      );
    },
    MqttSend() {
      var device_serial = this.serialnumbers[0];
      var topic = getTopics.getTopicResetReceive(device_serial, "256");
      this.$mqtt.subscribe(topic);
    },
    checkMqtt(msg) {
      let self = this;

      // let device_serial=this.serialnumbers[0]
      if (msg == "RESET") {
        localStorage.setItem("relay_delay" + this.device_serial, "");
        swal({
          title: this.$t("message.irAir.settings"),
          text: this.$t("message.noneHome.r_txt"),
          type: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(function () {
          self.$router.push({ name: "mainpage" });
        }, 2005);
      }
    },
    btnLoading() {
      var $btn = $("#btn-reset");
      $btn.click(function () {
        var $this = $(this);
        $this
          .attr("disabled", "disabled")
          .html(
            `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
          );
        setTimeout(function () {
          $this.removeAttr("disabled").html(this.$t("message.public.submit"));
        }, 2000);
      });
    },
  },
};
</script>



<style scoped>
</style>
