<template>
  <div class="row px-3 mt-1">   
    <div class="col-12 col-lg-12 py-0">
      <div class="box" >
        <div class="box-body">
          <div class="col-12 col-lg-12">
            <div class="box-body text-center py-0"></div>
            <ul class="box-body flexbox flex-justified text-center py-0 px-0">
              <li class="py-2" v-if="show_item == 0">
                <button
                  v-if="msg_swingV == 'SWING$VON'"
                  id="btnswinv"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="swingVerticalLearn('SWING$VOFF')"
                >
                  <i class="mdi mdi-swap-vertical mdi-24px" id="ic_SV"></i>
                </button>
                <button
                  v-else
                  id="btnswinv"
                  type="button"
                  class="btn btn-default mb-5"
                  @click="swingVerticalLearn('SWING$VON')"
                >
                  <i
                    class="mdi mdi-swap-vertical mdi-24px"
                    id="ic_SV"
                  ></i></button
                ><br />
                Swing V
              </li>
              <li class="py-2" v-if="show_item == 0">
                <button
                  v-if="msg_swingH == 'SWING$HON'"
                  id="btnswinh"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="swingHorizLearn('SWING$HOFF')"
                >
                  <i class="mdi mdi-swap-horizontal mdi-24px" id="ic_SH"></i>
                </button>
                <button
                  v-else
                  id="btnswinh"
                  type="button"
                  class="btn btn-default mb-5"
                  @click="swingHorizLearn('SWING$HON')"
                >
                  <i
                    class="mdi mdi-swap-horizontal mdi-24px"
                    id="ic_SH"
                  ></i></button
                ><br />
                Swing H
              </li>
              <li class="py-2"></li>
              <li class="py-2"></li>
              <li class="py-2">
                <button
                  v-if="msg_power == 'POWER$OFF'"
                  type="button"
                  class="btn btn-circle btn-danger btn-lg mb-5"
                  @click="powerLearn('POWER$OFF')"
                >
                  <i class="mdi mdi-power mdi-24px"></i>
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-circle btn-success btn-lg mb-5"
                  @click="powerLearn('POWER$OFF')"
                >
                  <i class="mdi mdi-power mdi-24px"></i>
                </button>
              </li>
            </ul>
            <ul class="box-body flexbox flex-justified text-center py-0">
              <li>
                <h6 class="mb-0 text-bold"></h6>
              </li>
              <li>
                <round-slider
                  dir="ltr"
                  v-model="sliderValue"
                  start-angle="315"
                   end-angle="+270"
                  style="stroke: rgb(0, 230, 134);"
                  radius="120"
                  min="16"
                  max="31"
                   strokeLinecap="round"
                   lineCap="round"
                  stroke-width="10"
                  :change="changeSlider(sliderValue)"
               >
               </round-slider>
                <!-- </h6> -->
              </li>
              <li>
                <h6 class="mb-0 text-bold"></h6>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-body">
          <div class="text-center">
            <ul
              class="
                box-body
                flexbox
                flex-justified
                text-center
                ml-20
                mr-20
                px-0
              "
            >
              <li>
                <h6 class="mb-0 text-bold"></h6>
                <button
                  v-if="msg_fan == 'FAN$HIGH'"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="fan('FAN$HIGH')"
                >
                  <img
                    src="/assets/images/smart/icon-fan-fast-white.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-default mb-5"
                  @click="fan('FAN$HIGH')"
                >
                  <img
                    src="/assets/images/smart/icon-fan-fast.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
              </li>
              <li>
                <button
                  v-if="msg_fan == 'FAN$MED'"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="fan('FAN$MED')"
                >
                  <img
                    id="imgfanmeduim"
                    src="/assets/images/smart/icon-fan-meduim-white.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-default mb-5"
                  @click="fan('FAN$MED')"
                >
                  <img
                    id="imgfanmeduim"
                    src="/assets/images/smart/icon-fan-meduim.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
              </li>
              <li>
                <button
                  v-if="msg_fan == 'FAN$LOW'"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="fan('FAN$LOW')"
                >
                  <img
                    id="imgfanlow"
                    src="/assets/images/smart/icon-fan-slow-white.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-default mb-5"
                  @click="fan('FAN$LOW')"
                >
                  <img
                    id="imgfanlow"
                    src="/assets/images/smart/icon-fan-slow.svg"
                    style="width: 24px; height: 24px"
                  />
                </button>
              </li>
            </ul>
            <ul
              class="box-body flexbox flex-justified text-center py-0 px-0 mt-2"
            >
              <li class="py-2">
                <button
                  v-if="msg_mode == 'MODE$DRY'"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="modeClick('MODE$DRY')"
                >
                  <i id="imgdry" class="mdi mdi-water mdi-24px"></i>
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-default mb-5"
                  @click="modeClick('MODE$DRY')"
                >
                  <i id="imgdry" class="mdi mdi-water mdi-24px"></i></button
                ><br />
                <span class="font-size-16">Dry</span>
              </li>
              <li class="py-2">
                <button
                  v-if="msg_mode == 'MODE$COOL'"
                  type="button"
                  class="btn btn-primary mb-5"
                  @click="modeClick('MODE$COOL')"
                >
                  <i id="imgcool" class="mdi mdi-snowflake mdi-24px"> </i>
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-default mb-5"
                  @click="modeClick('MODE$COOL')"
                >
                  <i id="imgcool" class="mdi mdi-snowflake mdi-24px">
                  </i></button
                ><br />
                <span class="font-size-18">Cool</span>
              </li>
              <li class="py-2">
                <button
                  v-if="msg_mode == 'MODE$HEAT'"
                  type="button"
                  class="btn btn-primary mb-5"
                >
                  <i
                    id="imgheat"
                    class="mdi mdi-weather-sunny mdi-24px"
                    @click="modeClick('MODE$HEAT')"
                  ></i>
                </button>
                <button v-else type="button" class="btn btn-default mb-5">
                  <i
                    id="imgheat"
                    class="mdi mdi-weather-sunny mdi-24px"
                    @click="modeClick('MODE$HEAT')"
                  ></i>
                </button>
                <br />
                <span class="font-size-18">Heat</span>
              </li>

              <li class="py-2" v-if="show_item == 0">
                <button
                  v-if="msg_mode == 'MODE$AUTO'"
                  type="button"
                  class="btn btn-primary mb-5"
                >
                  <i
                    id="imgauto"
                    class="mdi mdi-autorenew mdi-24px"
                    @click="modeClick('MODE$AUTO')"
                  ></i>
                </button>
                <button v-else type="button" class="btn btn-default mb-5">
                  <i
                    id="imgauto"
                    class="mdi mdi-autorenew mdi-24px"
                    @click="modeClick('MODE$AUTO')"
                  ></i></button
                ><br />
                <span class="font-size-18">Auto</span>
              </li>
            </ul>
            <ul class="flexbox flex-justified text-center my-10">
              <li class="px-2"></li>
              <li class="px-2"></li>
            </ul>
          </div>
          <div class="col-12 col-lg-12 px-15 py-0 text-center">
            <button
              type="button"
              class="btn btn-dark btn-lg px-50 mb-14 shadow"
              v-on:click.prevent="getMsg('sendMsg')"
            >
              {{$t('message.public.send')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.row -->
</template>

<script>
import RoundSlider from "vue-round-slider";

export default {
  components: {
    RoundSlider,
  },
  props: {
    command: "",
     status: "",
  },
  data() {
    return {
      sliderValue: 16,
      msg_power: "",
      swimVShow: false,
      swimHShow: false,
      msg_mode: "",
      msg_fan: "",
      msg_swingH: "",
      msg_swingV: "",
      show_item: 0,
      msgStr: "",
      msgDefault: "MODE$HEAT-FAN$HIGH-TEMP$16-SWING$HOFF-SWING$VOFF",
      msgDefaultBrand: "MODE$HEAT-FAN$HIGH-TEMP$16",
    };
  },
  mounted() {
    let self = this;
    this.show_item = this.command;

    if (this.command == "0") {
      this.msg_swingH = "SWING$HOFF";
      this.msg_swingV = "SWING$VOFF";
    }
    if (typeof this.status == "undefined") {
      if (this.command == "0") {
        this.msgStr = this.msgDefault;
      } else {
        this.msgStr = this.msgDefaultBrand;
      }
    } else {
      this.msgStr = this.status;
    }
    this.defaultShowItem(this.msgStr);
  },
  methods: {
    getMsg(val) {
      if (val == "sendMsg") this.$emit("clicked", this.msgStr);
    },
    changeSlider(value) {
      this.sliderValue = value;
      this.ceateStatus();
      if (value > 16) {
        this.powerShow = false;
        this.msg_power = "";
      }
    },
    ceateStatus() {
      if (this.msg_power == "POWER$OFF") {
        this.msgStr = this.msg_power;
      } else {
       
        if (!this.msg_mode) this.msg_mode = "MODE$HEAT";
        if (!this.msg_fan) this.msg_fan = "FAN$LOW";
        if (!this.msg_swingH) this.msg_swingH = "SWING$HOFF";
        if (!this.msg_swingV) this.msg_swingV = "SWING$VOFF";
        if (this.command == 0) {
          this.msgStr =
            this.msg_mode +
            "-" +
            this.msg_fan +
            "-" +
            "TEMP$" +
            this.sliderValue +
            "-" +
            this.msg_swingH +
            "-" +
            this.msg_swingV;
        } else if (this.command == 1) {
          this.msgStr =
            this.msg_mode +
            "-" +
            this.msg_fan +
            "-" +
            "TEMP$" +
            this.sliderValue;
        }
      }
      this.defaultShowItem(this.msgStr);
    },
    fan(value) {
      console.log(value);
      this.powerShow = false;
      this.msg_power = "";
      this.msg_fan = value;
      this.ceateStatus();
    },
    modeClick(value) {
      this.powerShow = false;
      this.msg_power = "";
      this.msg_mode = value;
      this.ceateStatus();
    },
    swingVerticalLearn(value) {
      this.powerShow = false;
      this.msg_power = "";
      this.msg_swingV = value;
      this.ceateStatus();
      if (this.swimVShow) {
        this.swimVShow = false;
      } else {
        this.swimVShow = true;
      }
    },
    swingHorizLearn(value) {
      this.powerShow = false;
      this.msg_power = "";
      this.msg_swingH = value;
      this.ceateStatus();
      if (this.swimHShow) {
        this.swimHShow = false;
      } else {
        this.swimHShow = true;
      }
    },
    powerLearn(value) {
      this.msg_power = value;
      this.ceateStatus();
      this.msg_mode = "";
      this.msg_fan = "";
      this.msg_swingH = "";
      this.msg_swingV = "";
      this.sliderValue = 16;
    },
    emptyMsg() {
      this.msg_power = "";
      this.msg_mode = "";
      this.msg_fan = "";
      this.found_key = false;
    },
    defaultShowItem(msgStr) {
      if (msgStr == "POWER$OFF") {
        this.msg_power = "POWER$OFF";
      } else {
        this.sliderValue=msgStr.split("TEMP$").pop().split("-")[0];
        if (msgStr.split("MODE$").pop().split("-")[0] == "AUTO") {
          this.msg_mode = "MODE$AUTO";
        } else if (msgStr.split("MODE$").pop().split("-")[0] == "HEAT") {
          this.msg_mode = "MODE$HEAT";
        } else if (msgStr.split("MODE$").pop().split("-")[0] == "COOL") {
          this.msg_mode = "MODE$COOL";
        } else if (msgStr.split("MODE$").pop().split("-")[0] == "DRY") {
          this.msg_mode = "MODE$DRY";
        }
        if (msgStr.split("FAN$").pop().split("-")[0] == "LOW") {
          this.msg_fan = "FAN$LOW";
        } else if (msgStr.split("FAN$").pop().split("-")[0] == "MED") {
          this.msg_fan = "FAN$MED";
        } else if (msgStr.split("FAN$").pop().split("-")[0] == "HIGH") {
          this.msg_fan = "FAN$HIGH";
        }
        if (msgStr.split("SWING$H").pop().split("-")[0] == "ON") {
          this.msg_swingH = "SWING$HON";
        }
        if (msgStr.split("SWING$V").pop().split("-")[0] == "ON") {
          this.msg_swingV = "SWING$VON";
        }
        if (msgStr.split("SWING$H").pop().split("-")[0] == "OFF") {
          this.msg_swingH = "SWING$HOFF";
        }
        if (msgStr.split("SWING$V").pop().split("-")[0] == "OFF") {
          this.msg_swingV = "SWING$VOFF";
        }
       
      }
    },
  },
};
</script>
<style scoped>
.dot {
  height: 198px;
  width: 198px;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: inline-block;
  border-color: #007bff;
  border: 1px solid #adadad;
  margin: auto;
}
</style>
