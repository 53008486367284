<template>
  <v-app class="bg">
    <section class="error-page bg-img mt-70">
      <div class="container mt-70 px-40">
        <div
            class="row h-p100 align-items-center justify-content-center text-center"
        >
          <div class="col-lg-7 col-md-10 col-12">
            <h1
                class="text-warning font-size-180 font-weight-bold error-page-title"
            ></h1>
            <img
                src="/assets/images/smart/vector-set-client.svg"
                class="avatar-smart"
            />
            <br/>
            <h6 class="mt-10" style="line-height: 25px !important">
              {{ $t('message.client.verify_manager') }}
            </h6>
            <button
                id="setclient"
                type="button"
                class="btn btn-danger mb-5 mt-10 m-5"
                v-on:click.prevent="setAdmin()"
            >
              {{ $t('message.public.OK') }}
            </button>

            <button
                type="button"
                class="btn btn-dark mb-5 mt-10 m-5"
                v-on:click.prevent="cancelFunction()"
            >
              {{ $t('message.public.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {},
  props: {
    click: {},
    home_id: {},
    serial_number: {},
    type: {}
  },
  data() {
    return {
      data_info: [],
      title: '',
    };
  },

  mounted() {
    this.title = this.$t('message.client.change');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SetClient');
    this.btnLoading();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    setAdmin() {
      //--------------------------------------  variable --------------------------------------//
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let serial_number = this.serial_number;
      let mobile = localStorage.getItem("mobile");

      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        client_mobile: mobile
      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.clientSet;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              var admin_verify_id = jsonArray.content.admin_verify_id;
              self.data_info.push(home_id);
              self.data_info.push(admin_verify_id);

              self.$router.push({
                name: "SetClientVerify",
                params: {
                  home_id: home_id,
                  serial_number: serial_number,
                  verify: admin_verify_id,
                  type: self.type
                },
              });


            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    cancelFunction() {
      this.$router.replace({
        name: "managementclientinfo",
        params: {home_id: this.home_id, serial_number: this.serial_number, type: this.type}
      });
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.replace({
          name: "managementclientinfo",
          params: {home_id: this.home_id, serial_number: this.serial_number, type: this.type}
        });
      }
    },

    btnLoading() {

      var $btn = $('#setclient');
      $btn.click(function () {
        var $this = $(this);
        $this
            .attr("disabled", "disabled")
            .html(
                `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
            );
        setTimeout(function () {
          $this.removeAttr("disabled").html(this.$t('message.public.submit'));
        }, 2000);
      });
    }
  },
};
</script>
<style scoped>
</style>
