<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <h5 class="text-bold">{{ $t("message.relay.add.add_relay") }}</h5>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align-self="center">
              <v-col cols="7" class="mb-2" align-self="center">
                <label class="mt-1 justify-center" style="font-size: 14px">{{
                  $t("message.relay.selected_type")
                }}</label>
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-col cols="6" class="px-0 text-center">
                    <label class="mt-1" style="font-size: 14px">{{
                      ShowTypeRelay(data.name)
                    }}</label></v-col
                  >
                  <v-col class="px-2" cols="6">
                    <v-img
                      :src="data.logo_path"
                      max-height="25"
                      max-width="25"
                      alt="relay image"
                      class="mt-0 align-self-center"
                    ></v-img>
                  </v-col>
                </v-row>
                <!-- </div> -->
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.relay.relay_name_input')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="name"
                  maxlength="20"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-bind:key="index"
              v-for="(info, index) in data.number_relay"
            >
            <div v-if="data.number_relay==2" class="px-4 mt-1">
            <label v-if="info==1" class="text-primary">{{$t('message.relay.select_open_relay')}}</label>
            <label v-else class="text-primary">{{$t('message.relay.select_closed_relay')}}</label>
            </div>
            <div v-if="data.number_relay==1" class="px-4 mt-1">
            <label v-if="info==1" class="text-primary">{{$t('message.relay.select_relay')}}</label>
            </div>
              <v-col cols="12 py-1" >
                <v-select
                  v-model="items[index]"
                  :items="listnum"
                  :label="$t('message.relay.number_relay')"
                  background-color="#f5f5f5"
                  required
                  solo
                  hide-details="true"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12 py-0" v-if="data.number_relay == 1" >  
            <label class="text-primary">{{$t('message.relay.select_type_relay')}} </label>
                <v-select
                  background-color="#f5f5f5"
                  v-model="selectedMode"
                  :items="dataMode"
                  :label="$t('message.relay.type_of_relay')"
                  item-text="name"
                  return-object
                  required
                  solo
                  hide-details="true"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <!-- <CmpTips
                v-if="showModeWarning"
                :data_txt="$t('message.relay.err_relay_momentary')"
                :data_type="'default'"
              ></CmpTips> -->
              <v-alert
                color="red"
                type="error"
                dense
                outlined
                v-if="errName"
                align-self="center"
                class="mx-3 mt-2"
              >
                <span class="text-danger">{{
                  $t("message.error_handler.empty", {
                    name: $t("message.relay.relay_name"),
                  })
                }}</span>
              </v-alert>
              <v-alert color="red" type="error" dense outlined v-if="showError">
                <span class="text-danger">{{ textError }}</span>
              </v-alert>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text-secondray" text @click="show = false">
             <label class="text-blue-gray"> {{ $t("message.public.cancel") }}</label>
          </v-btn>
          <v-btn color="bg-primary" @click="Save()">
            <label class="text-white mt-1">
              {{ $t("message.public.submit") }}</label
            >
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { getTypeRelay8 } from "../../../public/assets/js/utils/relay8_type_parser.js";
import CmpTips from "../Cmp/public/CmpTips.vue";

export default {
  data() {
    return {
      items: [],
      selectedMode: "روشن/ خاموش",
      showError: false,
      name: "",
      textError: "",
      infoDialog: {
        name: "",
        numbers: [],
        logo_path: "",
        type: "",
        delay: "0",
      },
      dataMode: [],
      listnum: ["1", "2", "3", "4", "5", "6", "7", "8"],
      selectedDelay: "0",
      errName: false,
      numbersRelayArr: [],
    };
  },
  components: {
    CmpTips,
  },
  props: {
    value: Boolean,
    data: {},
    datarelay: {},
  },
  watch: {
    show(val) {
      if (val) {
       this.checkSelectedDataNumbers()
        this.emptyData();
        this.errName=false
      }
    },
    name(newName, oldName) {
      if(newName!=null){
        if (newName.length != 0) {
        this.errName = false;
       
      }
      }
    },
    items(newItem, oldName) {
      if(newItem.length!=0){
        this.showError=false;
      }
     
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.dataMode.push(
      { id: "1", name: this.$t("message.AppBar.instantSettings") },
      { id: "0", name: this.$t("message.relay.relay_on_off") }
    );
    this.errName = false;
  },
  methods: {
    Save() {
      if(this.name==null){
        this.errName = true;
        return;
      }
      else if (this.name === 0 || this.name.length == 0) {
        this.errName = true;
        return;
      }
      this.validSelect();
    },
    toFindDuplicates() {
      let resultToReturn = false;
      // call some function with callback function as argument
      resultToReturn = this.items.some((element, index) => {
        return this.items.indexOf(element) !== index;
      });
      if (resultToReturn) {
        this.showError = true;
        this.textError = this.$t("message.relay.dialog.err_equal_relay");
      } else {
        this.showError = false;
        this.sendData();
      }
    },
    validSelect() {
      if (this.data.number_relay == "2") {
        if (this.items.length < 2 || typeof this.items[0] == "undefined") {
          this.showError = true;
          this.textError = this.$t("message.relay.dialog.err_empty_relay");
        } else {
          this.toFindDuplicates();
        }
      }
      if (this.data.number_relay == "1") {
        if (this.items.length < 1 || typeof this.items[0] == "undefined") {
          this.showError = true;
          this.textError =this.$t("message.relay.dialog.err_empty_relay"); 
        } else {
          this.toFindDuplicates();
        }
      } 
      // else {
      //   this.toFindDuplicates();
      // }
    },
    sendData() {
      this.infoDialog.name = this.name;
      this.infoDialog.numbers = this.items;
      this.infoDialog.logo_path = this.data.logo_path;
      this.infoDialog.type = this.data.type_code;
      this.infoDialog.delay = this.selectedMode.id;

      if (typeof this.infoDialog.delay == "undefined") {
        this.infoDialog.delay = "0";
      }
      this.$emit("clicked", this.infoDialog);
      this.show = false;
    },
    emptyData() {
      this.showError = false;
      this.infoDialog.name = "";
      this.name = "";
      this.selectedMode = this.$t("message.relay.relay_on_off");;
      this.items = [];
    },
    checkSelectedDataNumbers(){
     let self = this;
     this.listnum=["1", "2", "3", "4", "5", "6", "7", "8"];
        this.datarelay.forEach(function (item, i) {
          item.numbers.forEach(function (item, i) {
            const index = self.listnum.indexOf(item);
            if (index > -1) {
              self.listnum.splice(index, 1); // 2nd parameter means remove one item only
            }else{
              

            }
          });
        });
    },
    ShowTypeRelay(code) {
      return getTypeRelay8(code);
    },
 
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
.v-text-field input {
  font-family: "IRANSans";
  font-size: 16px !important;
}
