<template>
  <!-- .wrapper  -->
  <v-app class="bg">
      <!-- Content Wrapper. Contains page content -->
      <!-- Main content -->
      <section class="content mt-6 pl-4 ml-1">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 5px">
                      <div class="fontawesome-icon-list m-0 px-10">
                        <img
                            src="/assets/images/smart/vector-add-home.svg"
                            style="width: auto; height: 200px"
                        />
                        <h5 class="font-weight-bold">
                          {{ $t('message.ModalAddHome.title') }}
                        </h5>
                      </div>
                      <!-- <h6 class="mt-5 p-10"></h6> -->
                    </div>
                    <form novalidate class="px-20">
                      <!-- <h3 class="mt-15 mb-10">احراز هویت پیامکی</h3> -->
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddHome.name') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            type="text"
                            class="form-control"
                            required=""
                            aria-invalid="false"
                            maxlength="32"
                            autocomplete="off"
                            id="namehome"
                            v-model="namehome"
                            :placeholder="$t('message.ModalAddHome.hintName')"
                        />
                      </div>
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddSubDevicePol.name') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            type="text"
                            class="form-control"
                            required
                            aria-invalid="false"
                            maxlength="16"
                            autocomplete="off"
                            id="namedevice"
                            v-model="namedevice"
                            :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                        />
                      </div>
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddSubDeviceFaraPol.serial') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            type="number"
                            class="form-control"
                            required
                            aria-invalid="false"
                            autocomplete="off"
                            id="serialdevice"
                            v-model="serialdevice"
                            :placeholder="$t('message.ModalAddSubDeviceFaraPol.hintSerial')"
                        />
                      </div>
                      <div class="form-group">

                        <div class="col-12 mt-5 p-0">
                          <div class="alert alert-secondary p-0 m-0">
                            <div class="media">
                              <i class="mdi mdi-alert-circle-outline mdi-18px"></i>
                              <div class="media-body">
                                <label id="lblaccountlogin">
                                  {{ $t('message.ModalAddHome.password_tips') }}
                                </label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6 class="mt-20">
                          {{ $t('message.ModalAddHome.pin_code') }}<small>({{
                            $t('message.public.optionally')
                          }})</small>
                        </h6>
                        <div class="input-group">
                          <input
                              type="number"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              id="password"
                              v-model="password"
                              pattern="[0-9]"
                              maxlength="4"
                              autocomplete="off"
                              style="-webkit-text-security: disc; margin-top: 8px"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :placeholder="$t('message.ModalAddHome.password_hint')"
                          />
                          <!-- <span class="input-group-addon" id="basic-addon1"
                              ><i class="fa fa-dollar"></i
                            ></span> -->
                        </div>
                      </div>
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddHome.password_repeat') }}
                        </h6>
                        <input
                            type="number"
                            class="form-control"
                            required=""
                            aria-invalid="false"
                            id="password_repeat"
                            v-model="password_repeat"
                            pattern="[0-9]"
                            maxlength="4"
                            autocomplete="off"
                            style="-webkit-text-security: disc; margin-top: 8px"
                            oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            :placeholder="$t('message.ModalAddHome.password_repeat_hint')"
                        />
                      </div>
                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">

                          <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="myFunction()"
                          >
                            <span class="text-white"> {{ $t('message.public.submit') }}  </span>
                          </v-btn>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>

                    <div class="text-center" style="height: 50px">
                      <!-- <p class="mt-15 mb-0">Already have an account?<a href="auth_login.html" class="text-danger ml-5"> Sign In</a></p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <div class="col-lg-7 col-12"></div>

        <!-- /.row -->
      </section>
      <!-- /. Main content -->
      <!-- /.Content Wrapper. Contains page content -->
    <!-- /.modal Add Home-->
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
  <!-- <ModalCenter></ModalCenter> -->
  <!-- ./wrapper -->
</template>

<script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {
  nameDeviceValidationInput,
  nameHomeValidationInput,
  serialDeviceValidationInput
} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  data() {
    return {
      title: '',
      namehome: "",
      namedevice: "",
      serialdevice: "",
      password: "",
      password_repeat: "",
      loading: false,
    };
  },
  props:{
    click:{}
  },
  created() {
    let data = {
      title: this.$t("message.mainPage.add_device"),
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AddHome');
  },
  mounted() {
    this.title = this.$t('message.ModalAddHome.ttl');
  },
  watch: {
    click(val){
      this.onClickChild(val);
    }
  },
  methods: {
    myFunction() {
      let self = this;
      //--------------------------------------  variable --------------------------------------//
    
      var home_name = this.namehome;
      var device_name = this.namedevice;
      var device_serial = this.serialdevice;
      var password = this.password;
      var password_repeat = this.password_repeat;
      // device_serial_fix = fixNumbers(device_serial);
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      //--------------------------------------  validation --------------------------------------//
      if (
          !nameHomeValidationInput(home_name) ||
          !nameDeviceValidationInput(device_name) ||
          !serialDeviceValidationInput(device_serial)
      )
        return;

      if (password !== password_repeat) {
        swal("", self.$t('message.ModalAddHome.password_error'), "error");
        return;
      }
      this.loading = true;
      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        home_name: home_name,
        device_name: device_name,
        serial_number: device_serial,
        password: password,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.homeAdd;
      //  $("#modal-center").modal('show');
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              window.location.href = "mainpage";
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },

    onClickChild(value) {
      if (value === "back") {
        this.$router.push("mainpage");
      }
    },

  },
};
</script>


<style scoped>

</style>
