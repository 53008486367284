<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <h5 id="txtloading">{{  $t('message.irAir.send')}}</h5>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div style="text-align: center; margin-top: 1px">
                <img
                  src="/assets/images/smart/airlearn.svg"
                  style="width: 200px; height: auto"
                />
              </div>
              <div class="form-group mt-2">
                <!-- <h6>نکات</h6> -->
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{$t('message.ModalCenter.loading')}}</span>
                </div>
                <span class="pl-20" id="txtwait">{{$t('message.ModalCenter.wait')}}</span>
              </div>
              <div class="row">
                <!-- /.col -->
                <div class="col-12 text-center">
                  <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    v-on:click.prevent="onCanelButton()"
                  >
                    {{$t('message.public.cancel')}}
                  </button>
                </div>
                <!-- /.col -->
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "Modal",
  props: {
    data_infos: { type: Array },
  },
  data() {
    return {
      passoutlet: "",
    };
  },

  mounted() {
   
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onCanelButton(event) {
      this.$emit("clicked",'cancel');
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  /* margin: 0px auto; */
  /* padding: 5px 10px; */
  background-color: #414755;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h6 {
  font-family: "IRANSansWeb_Medium";
  bottom: 2px;
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  font-family: "IRANSansWeb_Medium";
  /* margin: 20px 0; */
  padding:0px 20px 20px 20px;
  margin: 5px 20px;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>