<template>
  <v-app class="bg">

    <div>
      <div class="px-4 mt-5">
      <v-card class="mx-auto">
      <div :key="index" v-for="(item, index) in itemsInfo">
        <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true"   @clickedRow="CmpCallItemInfo">
          <template v-slot:line>
            <v-divider inset v-if="index < itemsInfo.length - 1" class="mb-0 mt-0"></v-divider>
             </template>
        </CmpRowListSetting>
      </div>
        </v-card>
        </div>
        <div class="px-4 mt-2">
      <v-card class="mx-auto">
      <div :key="index" v-for="(item, index) in itemsSetting">
        
        <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true"   @clickedRow="CmpCallItemSetting">
          <template v-slot:line>
            <v-divider inset v-if="index < itemsSetting.length - 1" class="mb-0 mt-0"></v-divider>
             </template>
        </CmpRowListSetting>
      </div>
        </v-card>
        </div>

        <div class="px-4 mt-2 mb-4">
      <v-card class="mx-auto">
      <div :key="index" v-for="(item, index) in itemsDel">
        <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true"   @clickedRow="CmpCallItemDel">
          <template v-slot:line>
            <v-divider inset v-if="index < itemsDel.length - 1" class="mb-0 mt-0"></v-divider>
             </template>
        </CmpRowListSetting>
      </div>
        </v-card>
        </div>
    </div>

    <!-- <div class="mt-5">
      <section class="content">
        <div class="media-list media-list-divided media-list-hover" v-bind:key="index" v-for="(item, index) in items">
          <div class="media align-items-center setting" v-on:click.prevent="ClickItem(item.click)">
            <v-icon>{{ item.icon }}</v-icon>
            <div class="media-body">
              <p class="pt-2">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </section>
    </div> -->
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <ModalResetFactoryKey :infoItem="dataReset"></ModalResetFactoryKey>
    <SubModalDel v-bind:data_info_sub="data_info_keytouch_pro"></SubModalDel>
    <!-- <SubModalEdit v-bind:data_info_sub="data_info_keytouch_pro"></SubModalEdit> -->
    <SubModalSetPass v-bind:data_info_sub="data_info_keytouch_pro"></SubModalSetPass>
    <SubModalUpdate v-bind:data_info_sub="data_info_keytouch_pro" @clicked="clickUpdate"></SubModalUpdate>

  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import CmpRowListSetting from "@/components/Cmp/CmpRowListSetting.vue";

import CmpRowCard from "@/components/Cmp/CmpRowCard.vue";
import { getTopics } from "@/../public/assets/js/utils/topics.js";
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import { getMessageSub } from "@/../public/assets/js/utils/helper_sub_message.js";

import SubModalUpdate from "../../subdevice/SubModalUpdate";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { Internets } from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryKey from "../../subdevice/keytouchpro/ModalResetFactoryKey";


var showToast;
var connection = false;
var timeout;

export default {
  components: {
    CmpRowCard,
    CmpRowList,
    CmpPasscode,
    SubModalUpdate,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    ModalResetFactoryKey,
    CmpRowListSetting
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      subdevice_serial: "",
      title: "",
      deviceID: '',
      pass:false,
      // status_wifi: "",
      // status_switch: false,
      // wifi_setting: 1,
      isModalVisible: false,
      data_info_keytouch_pro: [this.home_id, this.serial_number, this.count],
      info: [],
      // List: [],
      dataReset: [],
      labels: [],
      sub_device_info:[],
      set_password: 0,
      setting_status: "",
      pol:"",
      itemsInfo:[],
      itemsSetting: [],
      itemsDel: [],
      // items: [
      //   { title: this.$t("message.public.edit"), icon: 'mdi mdi-border-color', click: "edit_name" },
      //   { title: this.$t("message.public.delete"), icon: 'mdi mdi-delete', click: "soft_del" },
      //   { title: this.$t("message.AppBar.scheduler"), icon: 'mdi mdi-clock', click: "timer" },
      //   { title: this.$t("message.public.refresh_device"), icon: 'mdi mdi-update', click: "update" },
      //   { title: this.$t("message.public.setting") + " RGB ", icon: 'mdi mdi-white-balance-incandescent', click: "rgb" },
      //   { title: this.$t("message.public.wifi_setting_status"), icon: 'mdi  mdi-toggle-switch-off', click: "wifi" },
      //   { title: this.$t("message.public.del_hardware"), icon: 'mdi mdi-delete-forever', click: "del_hard" },
      //   { title: this.$t("message.ModalAddHome.pin_code"), icon: 'mdi mdi-lock', click: "pin" },
      //   { title: this.$t("message.AppBar.about_device"), icon: 'mdi mdi-information-outline', click: "about" },
        
      // ],

    };
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        console.log("connection mqtt",connection)

        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));

      }
      // SucessToastMsg.Msg();
    },
    "+/+/receive/+/seting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));

        // this.showStatus(String(data))
      }
      SucessToastMsg.Msg();
    },
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));
        // this.showStatus(String(data))
      }
    SucessToastMsg.Msg();
    },
  },
  created() {
    // this.List.push({ index: 1, title: this.$t("message.public.wifi_setting_status"), actions: 'switch', switch: false, icon: "mdi mdi-toggle-switch-off" })
  },
  mounted() {
    this.info = JSON.parse(localStorage.getItem("infoSubDevice"));

    this.subdevice_serial = this.info.serial_number
    this.set_password = this.info.set_password;
    // this.List[0].switch = this.info.wifi_setting ? true : false;
    // this.wifi_setting = this.info.wifi_setting ? true : false;
    this.pol=this.info.pol;
    this.labels = JSON.parse(this.info.labels);
    this.pass = this.info.set_password === 1

    this.initData();

    this.title = this.$t("message.public.setting")
    this.dataReset.push({ serial_number: this.serial_number, subdevice_serial: this.subdevice_serial })

    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'TochProSetting');
    this.lastStatus();
    this.onSubscribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubKeyTouchProInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    initData(){
     this.itemsInfo.push(
        { index:1, title: this.$t("message.public.edit"), icon: 'mdi mdi-pencil-outline' , actions: 'icon', more: 'ti-angle-left' , more_en: 'ti-angle-right' },
        { index:2, title: this.$t("message.AppBar.about_device"), icon: 'mdi mdi-information-outline', click: "about" ,actions: 'icon', more: 'ti-angle-left' ,more_en: 'ti-angle-right'  },  )
      this.itemsSetting.push(
        { index:1, title: this.$t("message.AppBar.scheduler"), icon: 'mdi mdi-timer-outline' , actions: 'icon', more: 'ti-angle-left' ,more_en: 'ti-angle-right'},
        { index:2, title: this.$t("message.public.setting") + " RGB ", icon: 'mdi mdi-lightbulb-outline' ,actions: 'icon', more: 'ti-angle-left',more_en: 'ti-angle-right'},
        { index:3,title: this.$t("message.public.wifi_setting_status"), icon: 'mdi  mdi-wifi-check', actions: 'icon', more: 'ti-angle-left' ,more_en: 'ti-angle-right'},
        { index:4,title: this.$t("message.public.refresh_device"), icon: 'mdi mdi-update', actions: 'icon', more: 'ti-angle-left' ,more_en: 'ti-angle-right'},
        { index:5, title: this.$t("message.ModalAddHome.pin_code"), icon: 'mdi mdi-lock-outline', actions: 'switch' ,switch:this.pass},
      )
     this.itemsDel.push(
      {  index:1, title: this.$t("message.public.delete"), icon: 'mdi mdi-trash-can-outline', click: "soft_del" , actions: 'icon', more: 'ti-angle-left' ,more_en: 'ti-angle-right'},
        {  index:2, title: this.$t("message.public.del_hardware"), icon: 'mdi mdi-delete-alert-outline', click: "del_hard" , actions: 'icon', more: 'ti-angle-left',more_en: 'ti-angle-right'},
     )
      },
    CmpCallItemInfo(index){
      switch (index) {
        case 1:
        let data = {
            labels: this.labels,
            pol: this.pol,
            type: "PRO",
            type_code: this.info.type.type_code,
          }
          localStorage.setItem("data_edit_sub", JSON.stringify(data));
          this.$router.push({
            name: "SubKeyTouchEdit",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          });
          break;
        case 2:
        this.$router.push({
            name: "SubAboutKeyPro",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          });
      }
    },
    CmpCallItemSetting(index) {
      switch (index) {
        case 1:
        let data0 = {
          labels: this.labels,
          pol: this.pol,
          type: "PRO",
          type_code:this.info.type.type_code,
        }
        console.log("data",data0)
        localStorage.setItem("data_edit_sub", JSON.stringify(data0));
        this.$router.push({
          name: "TouchProTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
          break;
        case 2:
        this.$router.push({
            name: "EditRGBPro",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          });
          break;
        case 3:
        this.$router.push({
            name: "TouchProStatusWifi",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: this.count,
            },
          });
          break;
        case 4:
        $("#modal-update-sub").modal("show");
          break;
        case 5:
        $("#modal-set-pass-sub").modal("show");
          break;
      }
    },
    CmpCallItemDel(item) {
      switch (item) {
        case 1:
          $("#modal-del-sub").modal("show");
          break;
        case 2:
        this.type_Selected_item = "reset"
           this.setting_status="RESET#"
          $("#modal-reset-touchPro").modal('show');
          break;
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSetingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic3 = getTopics.getTopicSettingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
      this.$mqtt.subscribe(topic3);
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    clickUpdate(value){
     this.onSubscribe();
            if (this.set_password == 1) {
              this.setting_status = "UPDATE#"
              this.type_Selected_item = "update"
              $("#modal-update-sub").modal("hide");
              $("#modal-pass-code").modal("show");
            } else {
              this.type_Selected_item = "update"
              this.sendRequest('UPDATE#', '');
            }
    },
    sendMessage(status) {
      this.setting_status = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.setting_status, '');
      }
    },
    sendReq(password) {
      this.sendRequest(this.setting_status, password);
    },
    sendRequest(message, password) {

      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final = "";

      
      console.log(" this.type_Selected_item", this.type_Selected_item)
     
       if(this.type_Selected_item == 'update'){
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial)
       }
       else if(this.type_Selected_item == 'reset'){
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial)
       }
       else{
        topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
       }

      // this.type_Selected_item == 'reset' ?
      //   topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial) :
      //   topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);

      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            console.log("connection",connection)

            if (!connection) {
              // setTimeout(function () {
              //   self.lastStatus();
              // }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 5000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    // showUpdateDialog() {
    //   this.$swal({
    //     title: this.$t("message.public.refresh"),
    //     text: "آیا میخواهید به روز رسانی  کنید؟",
    //     type: "info",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: this.$t("message.public.refresh"),
    //     cancelButtonText: this.$t("message.public.cancel"),
    //   }).then(
    //     (result) => {
    //       if (result) {
    //         this.onSubscribe();
    //         if (this.set_password == 1) {
    //           this.setting_status = "UPDATE#"
    //           $("#modal-pass-code").modal("show");
    //         } else {
    //           this.type_Selected_item = "update"
    //           this.sendRequest('UPDATE#', '');
    //         }
    //       }
    //     },
    //     (dismiss) => {
    //       if (dismiss === "cancel") {
    //       } else {
    //         throw dismiss;
    //       }
    //     }
    //   );
    // },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // this.openModalLoading();
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.subdevice_serial,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      // ------------------Response----------------//
      p.then(
          function (response) {
          
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              // ****** code save data in subdevice
              self.sub_device_info.push({
                  subDevices: jsonArray.content.device_info
              })
              localStorage.setItem("infoSubDevice",JSON.stringify(self.sub_device_info[0].subDevices))
              self.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];

               // ****** code save data in subdevice
              self.showLabel(self.info);
              console.log("showLabel",self.info)
              // self.showStatus(message);
              // self.parserRgbSetting(); 
              // self.cmpShow=true
              // self.closeModalLoading();
            } else {
              // self.cmpShow=true
              // self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },
    showLabel(infoSubDevice){
      if (!JSON.parse(infoSubDevice.labels)){
       this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
        pol: "3",
        label: "L4"
      }];}
    else{
      this.labels = JSON.parse(infoSubDevice.labels);
    }
    },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      if(msg=="UPDATEOK"){
        $("#modal-update-sub").modal("hide");
        swal({
        // title: self.$t('message.timer.timer'),
        title:this.$t("message.public.refresh"),
        text: this.$t("message.public.update_done"),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      }
    },
  },
};

</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}

.v-list-item__content {
  overflow: visible;
}
</style>
