<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var status_remote;

export default {
  components: {
    Timer
  },
  data() {
    return {
      title: "",
      data: {}
    };
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.public.button') + " A",
        status: true
      },
        {
          name: this.$t('message.public.button') + " B",
          status: true
        },
        {
          name: this.$t('message.public.button') + " C",
          status: true
        },
        {
          name: this.$t('message.public.button') + " D",
          status: true
        }],
      type: "remote"
    }
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
    this.$store.dispatch("InfoSubDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
    this.title = this.$t('message.timer.scheduler')
    status_remote = "PR" + this.count + "*" + "K1T";
    localStorage.setItem("statusTimer", JSON.stringify(status_remote));
  },
  methods: {
    onClick(name, val, status) {
      switch (val) {
        case 0:
          this.next1();
          break;
        case 1:
          this.next2();
          break;
        case 2:
          this.next3();
          break;
        case 3:
          this.next4();
          break;
      }
    },
    next1() {
      status_remote = "PR" + this.count + "*" + "K1T";
      localStorage.setItem("statusTimer", JSON.stringify(status_remote));
      this.$router.push({
        name: "RemoteStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    next2() {
      status_remote = "PR" + this.count + "*" + "K1F";
      localStorage.setItem("statusTimer", JSON.stringify(status_remote));
      this.$router.push({
        name: "RemoteStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    next3() {
      status_remote = "PR" + this.count + "*" + "K2T";
      localStorage.setItem("statusTimer", JSON.stringify(status_remote));
      this.$router.push({
        name: "RemoteStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    next4() {
      status_remote = "PR" + this.count + "*" + "K2F";
      localStorage.setItem("statusTimer", JSON.stringify(status_remote));
      this.$router.push({
        name: "RemoteStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "RemoteTimerShow",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    },
  },
};
</script>


<style scoped>
</style>
