import i18n from '../../../../src/i18n'
import {getControlTvStatus} from "./ir_control_tv_parser";
import {getControlamplifierStatus} from "./ir_control_amplifier_parser";

export function getTimerData(message) {
    var myTimer = {
        'name': getTimerName(message),
        'nameStr': getTimerNameStr(message),
        'count': getTimerCount(message),
        'time1': getTimerTime1(message),
        'time2': getTimerTime2(message),
        'status': getTimerStatus(message),
        'day1': getTimerDay1(message),
        'day2': getTimerDay2(message),
        'day3': getTimerDay3(message),
        'day4': getTimerDay4(message),
        'day5': getTimerDay5(message),
        'day6': getTimerDay6(message),
        'day7': getTimerDay7(message),
    };
    return myTimer;
}
/// if the timer has no end time, you should use this function.
export function getTimerDataNoEndTime(message) {
    var myTimer = {
        'name': getTimerName(message),
        'nameStr': getTimerNameStr(message),
        'count': getTimerCount(message),
        'time1': getTimerTime1(message),
        'time2': '',
        'status': getTimerStatus(message),
        'day1': getTimerDay1(message),
        'day2': getTimerDay2(message),
        'day3': getTimerDay3(message),
        'day4': getTimerDay4(message),
        'day5': getTimerDay5(message),
        'day6': getTimerDay6(message),
        'day7': getTimerDay7(message),
    };
    return myTimer;
}
export function getTimerDataIR(message) {
    function getTimerNameStrIR(message) {
        let type = message["control-category"].type_code;
        switch (type) {
            case 1003:
                return i18n.t('message.irAir.scenario') + " " + message["control-key"].key_count;
        }
        return i18n.t('message.public.button') + " ";
    }

    function getTimerCountIR(message) {
        return message.count;
    }

    function getTimerStatusIR(message) {
        let type = message["control-category"].type_code;
        switch (type) {
            case 1001:
                return getControlTvStatus(message["control-key"].key_count, "V1");
            case 1002:
                return getControlamplifierStatus(message["control-key"].key_count, "V1");
        }
    }

    var myTimer = {
        'name': message["control-name"],
        'nameStr': getTimerNameStrIR(message),
        'count': getTimerCountIR(message),
        'time1': getTimerTime1(message.message),
        'time2': getTimerTime2(message.message),
        'status': getTimerStatusIR(message),
        'day1': getTimerDay1(message.message),
        'day2': getTimerDay2(message.message),
        'day3': getTimerDay3(message.message),
        'day4': getTimerDay4(message.message),
        'day5': getTimerDay5(message.message),
        'day6': getTimerDay6(message.message),
        'day7': getTimerDay7(message.message),
    }
    return myTimer;
}

export function getTimerCooler(message) {
    let name = "";
    if (message.match("PS")) {
        // console.log("PS")
        if (message.match("K")) {
            // console.log("psK")
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                name = i18n.t('message.subDevice.cooler_pomp');
            } else if (msg == "2") {
                name = i18n.t('message.subDevice.cooler_low');
            } else if (msg == "3") {
                name = i18n.t('message.subDevice.cooler_fast');
            } else if (msg == "5") {
                name = i18n.t('message.public.off');
            }
            let myTimer = {
                'name': name,
                'nameStr': getTimerNameStr(message),
                'count': getTimerCount(message),
                'time1': getTimerTime1(message),
                'time2': getTimerTime2(message),
                'status': getTimerStatus(message),
                'day1': getTimerDay1(message),
                'day2': getTimerDay2(message),
                'day3': getTimerDay3(message),
                'day4': getTimerDay4(message),
                'day5': getTimerDay5(message),
                'day6': getTimerDay6(message),
                'day7': getTimerDay7(message),
            }
            return myTimer;
        }
    }
}

export function getTimerCurtain(message) {
    let name = "";
    if (message.match("PS")) {
        // console.log("PS")
        if (message.match("K")) {
            // console.log("psK")
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                name = i18n.t('message.relay.type.curtain') + " " + i18n.t('message.public.open');
            } else if (msg == "3") {
                name = i18n.t('message.relay.type.curtain') + " " + i18n.t('message.public.close');
            }
            let myTimer = {
                'name': name,
                'nameStr': name,
                'count': getTimerCount(message),
                'time1': getTimerTime1(message),
                'time2': getTimerTime2(message),
                'status': getTimerStatus(message),
                'day1': getTimerDay1(message),
                'day2': getTimerDay2(message),
                'day3': getTimerDay3(message),
                'day4': getTimerDay4(message),
                'day5': getTimerDay5(message),
                'day6': getTimerDay6(message),
                'day7': getTimerDay7(message),
            }
            return myTimer;
        }
    }
}

export function getTimerName(message) {
    if (message.match("PR")) {
        if (message.match("#R")) {
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 2);
            if (msg == "1T") {
                return "A";
            } else if (msg == "1F") {
                return "B";
            } else if (msg == "2T") {
                return "C";
            } else if (msg == "2F") {
                return "D";
            }
        } else {
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                return "L1";
            } else if (msg == "2") {
                return "L2";
            } else if (msg == "3") {
                return "L3";
            } else if (msg == "4") {
                return "L4";
            } else if (msg == "5") {
                return "All";
            }
        }
    }
    if (message.match("PS")) {
        if (message.match("K")) {
            // console.log("psK")
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                return "L1";
            } else if (msg == "2") {
                return "L2";
            } else if (msg == "3") {
                return "L3";
            } else if (msg == "4") {
                return "L4";
            } else if (msg == "5") {
                return "All";
            }


        } else if (message.match("T")) {
            return localStorage.getItem("subdevice_name");
        }

    }
    if (message.match("P1")) {
        return localStorage.getItem("device_name");
    }
}

export function getTimerNameStr(message) {
    if (message.match("PR")) {
        if (message.match("#R")) {
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 2);
            if (msg == "1T") {
                return "A";
            } else if (msg == "1F") {
                return "B";
            } else if (msg == "2T") {
                return "C";
            } else if (msg == "2F") {
                return "D";
            }
        } else {
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                return "L1";
            } else if (msg == "2") {
                return "L2";
            } else if (msg == "3") {
                return "L3";
            } else if (msg == "4") {
                return "L4";
            } else if (msg == "5") {
                return "All";
            }
        }
    }
    else if (message.match("PS")) {
        if (message.match("K")) {
            let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
            if (msg == "1") {
                return i18n.t('message.subDevice.keytouch.pol1');
            } else if (msg == "2") {
                return i18n.t('message.subDevice.keytouch.pol2');
            } else if (msg == "3") {
                return i18n.t('message.subDevice.keytouch.pol3');
            } else if (msg == "4") {
                return i18n.t('message.subDevice.keytouch.pol4');
            } else if (msg == "5") {
                return i18n.t('message.public.all');
            }


        } else if (message.match("T")) {
            return localStorage.getItem("subdevice_name");
        }

    }
    else if (message.match("P1")) {
        return localStorage.getItem("device_name");
    } else {
        let msg = message.substr(message.indexOf(message.match("K")) + 1, 1);
        if (msg == "1") {
            return i18n.t('message.relay.relay') + " 1";
        } else if (msg == "2") {
            return i18n.t('message.relay.relay') + " 2";
        } else if (msg == "3") {
            return i18n.t('message.relay.relay') + " 3";
        } else if (msg == "4") {
            return i18n.t('message.relay.relay') + " 4";
        } else if (msg == "5") {
            return i18n.t('message.relay.relay') + " 5";
        } else if (msg == "6") {
            return i18n.t('message.relay.relay') + " 6";
        } else if (msg == "7") {
            return i18n.t('message.relay.relay') + " 7";
        } else if (msg == "8") {
            return i18n.t('message.relay.relay') + " 8";
        }
    }
}

export function getTimerTime1(message) {
    return message.substr(message.indexOf("D7") + 4, 5);
}

export function getTimerCount(message) {
    return message.substring(
        message.lastIndexOf("TIMER") + 5,
        message.indexOf("-")
    );
}

export function getTimerTime2(message) {
    return message.substr(message.indexOf("D7") + 10, 5);
}

export function getTimerStatus(message) {
    if (message.match("PR")) {
        let msg = message.substr(message.indexOf("K") + 2, 1);
        if (msg == "F") {
            return i18n.t('message.public.off');
        } else if (msg == "T") {
            return i18n.t('message.public.on');
        }
    } else if (message.match("PS")) {
        if (message.match("K")) {
            let msg = message.substr(message.indexOf("K") + 2, 1);

            if (msg == "F") {
                return i18n.t('message.public.off');
            } else if (msg == "T") {
                return i18n.t('message.public.on');
            }
        }
        if (message.match("TF-")) {
            return i18n.t('message.public.off');
        }
        if (message.match("TT-")) {
            return i18n.t('message.public.on');
        }
    } else if (message.match("P1")) {
        status = message.substr(message.indexOf("P1") + 2, 1);
    } else status = message.substr(message.indexOf("K") + 2, 1)
    if (status == "F") {
        return i18n.t('message.public.off');
    } else if (status == "T") {
        return i18n.t('message.public.on');
    }
}

export function getTimerDay(message) {
    let D1 = message.substr(message.indexOf("D1") + 2, 1);
    let D2 = message.substr(message.indexOf("D2") + 2, 1);
    let D3 = message.substr(message.indexOf("D3") + 2, 1);
    let D4 = message.substr(message.indexOf("D4") + 2, 1);
    let D5 = message.substr(message.indexOf("D5") + 2, 1);
    let D6 = message.substr(message.indexOf("D6") + 2, 1);
    let D7 = message.substr(message.indexOf("D7") + 2, 1);
    return D1 + D2 + D3 + D4 + D5 + D6 + D7;

}

function getTimerDay1(message) {
    let D1 = message.substr(message.indexOf("D1") + 2, 1);
    return D1;
}

function getTimerDay2(message) {
    let D2 = message.substr(message.indexOf("D2") + 2, 1);

    return D2;
}

function getTimerDay3(message) {
    let D3 = message.substr(message.indexOf("D3") + 2, 1);

    return D3;
}

function getTimerDay4(message) {
    let D4 = message.substr(message.indexOf("D4") + 2, 1);

    return D4;
}

function getTimerDay5(message) {
    let D5 = message.substr(message.indexOf("D5") + 2, 1);
    return D5;
}

function getTimerDay6(message) {
    let D6 = message.substr(message.indexOf("D6") + 2, 1);
    return D6;
}

function getTimerDay7(message) {
    let D7 = message.substr(message.indexOf("D7") + 2, 1);
    return D7;
}

export function listOfDays() {
    let select = localStorage.getItem("startOfWeek")
    if (!select) {
        localStorage.removeItem("startOfWeek");
        localStorage.setItem("startOfWeek", 2);
        return [
            {
                id: 2,
                name: i18n.t('message.timer.D1'),
                value: "D1",
                sort: 1,
            },
            {
                id: 3,
                name: i18n.t('message.timer.D2'),
                value: "D2",
                sort: 2,
            },
            {
                id: 4,
                name: i18n.t('message.timer.D3'),
                value: "D3",
                sort: 3,
            },
            {
                id: 5,
                name: i18n.t('message.timer.D4'),
                value: "D4",
                sort: 4,
            },
            {
                id: 6,
                name: i18n.t('message.timer.D5'),
                value: "D5",
                sort: 5,
            },
            {
                id: 0,
                name: i18n.t('message.timer.D6'),
                value: "D6",
                sort: 6,
            },
            {
                id: 1,
                name: i18n.t('message.timer.D7'),
                value: "D7",
                sort: 7,
            },];
    }
    switch (select) {
        case '0':
            return [
                {
                    id: 0,
                    name: i18n.t('message.timer.D6'),
                    value: "D6",
                    sort: 6,
                },
                {
                    id: 1,
                    name: i18n.t('message.timer.D7'),
                    value: "D7",
                    sort: 7,
                },
                {
                    id: 2,
                    name: i18n.t('message.timer.D1'),
                    value: "D1",
                    sort: 1,
                },
                {
                    id: 3,
                    name: i18n.t('message.timer.D2'),
                    value: "D2",
                    sort: 2,
                },
                {
                    id: 4,
                    name: i18n.t('message.timer.D3'),
                    value: "D3",
                    sort: 3,
                },
                {
                    id: 5,
                    name: i18n.t('message.timer.D4'),
                    value: "D4",
                    sort: 4,
                },
                {
                    id: 6,
                    name: i18n.t('message.timer.D5'),
                    value: "D5",
                    sort: 5,
                },
            ];
        case '1':
            return [
                {
                    id: 1,
                    name: i18n.t('message.timer.D7'),
                    value: "D7",
                    sort: 7,
                },
                {
                    id: 2,
                    name: i18n.t('message.timer.D1'),
                    value: "D1",
                    sort: 1,
                },
                {
                    id: 3,
                    name: i18n.t('message.timer.D2'),
                    value: "D2",
                    sort: 2,
                },
                {
                    id: 4,
                    name: i18n.t('message.timer.D3'),
                    value: "D3",
                    sort: 3,
                },
                {
                    id: 5,
                    name: i18n.t('message.timer.D4'),
                    value: "D4",
                    sort: 4,
                },
                {
                    id: 6,
                    name: i18n.t('message.timer.D5'),
                    value: "D5",
                    sort: 5,
                },
                {
                    id: 0,
                    name: i18n.t('message.timer.D6'),
                    value: "D6",
                    sort: 6,
                },
            ];
        case '2':
            return [
                {
                    id: 2,
                    name: i18n.t('message.timer.D1'),
                    value: "D1",
                    sort: 1,
                },
                {
                    id: 3,
                    name: i18n.t('message.timer.D2'),
                    value: "D2",
                    sort: 2,
                },
                {
                    id: 4,
                    name: i18n.t('message.timer.D3'),
                    value: "D3",
                    sort: 3,
                },
                {
                    id: 5,
                    name: i18n.t('message.timer.D4'),
                    value: "D4",
                    sort: 4,
                },
                {
                    id: 6,
                    name: i18n.t('message.timer.D5'),
                    value: "D5",
                    sort: 5,
                },
                {
                    id: 0,
                    name: i18n.t('message.timer.D6'),
                    value: "D6",
                    sort: 6,
                },
                {
                    id: 1,
                    name: i18n.t('message.timer.D7'),
                    value: "D7",
                    sort: 7,
                },
            ];
    }
}

