<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-change-model"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <div class="content-wrapper mr-0 bg-white">
        <section class="content">
          <div class="row">
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="bg-white">
                      <div style="text-align: center; margin-top: 10px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                              src="/assets/images/smart/vector-model.svg"
                              class="avatar-smart mt-50"
                          />
                          <h5 class="font-weight-bold">
                            {{ $t('message.subDevice.change_device') }}
                          </h5>
                        </div>
                      </div>
                      <br/>
                      <div class="px-40">

                        <h6>{{ $t('message.subDevice.model') }}</h6>
                        <div class="box-body">

                          <div class="demo-radio-button" v-if="model==1">
                            <input name="group5" type="radio" id="radio_40" class="with-gap radio-col-blue" checked=""
                                   @click="oneModel()">
                            <label style="text-align:right" for="radio_40">{{ $t('message.AppBar.model1') }}</label>
                            <input name="group5" type="radio" id="radio_41" class="with-gap radio-col-blue"
                                   @click="twoModel()">
                            <label style="text-align:right" for="radio_41">{{ $t('message.AppBar.model2') }}</label>

                          </div>

                          <div class="demo-radio-button" v-if="model==2">
                            <input name="group5" type="radio" id="radio_40" class="with-gap radio-col-blue"
                                   @click="oneModel()">
                            <label style="text-align:right" for="radio_40">{{ $t('message.AppBar.model1') }}</label>
                            <input name="group5" type="radio" id="radio_41" class="with-gap radio-col-blue" checked=""
                                   @click="twoModel()">
                            <label style="text-align:right" for="radio_41">{{ $t('message.AppBar.model2') }}</label>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 text-center">
                            <v-btn
                                class="btn btn-info btn-block"
                                :loading="loading"
                                :disabled="loading"
                                color="bg-primary"
                                @click="changeFunction()"
                            >
                              <span class="text-white"> {{ $t('message.public.submit') }} </span>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="height: 50px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="modal center-modal fade" id="modal-center" tabindex="-1" data-keyboard="false"
             data-backdrop="static">
          <div class="modal-dialog p-20">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
              </div>
              <div class="modal-body">
                <div class="spinner-border textprimary" role="status">
                  <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
                </div>
                <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
var model = 1;


export default {
  components: {
    AppBar

  },
  props: {
    data_info_sub: {type: Array},
    click: {}
  },
  data() {
    return {
      model: '',
      title: '',
      loading: false,
    };
  },
  created() {
    this.title = this.$t('message.subDevice.change_model')
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
    document.getElementById("inputeditnamesub").value = this.$store.getters.getNameSub
    this.btnLoading();
    this.model = this.$store.getters.getModel;
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        $("#modal-change-model").modal("hide");
      }
    },
    oneModel() {

      model = 1;
    },
    twoModel() {

      model = 2;
    },
    changeFunction() {
      var name = document.getElementById("inputeditnamesub").value
      let self = this;
      var name = name;

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      var home_id = this.data_info_sub[0];
      var serial_number = this.data_info_sub[1];
      var count = this.data_info_sub[2]
      this.loading = true;


      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: subdevice_serial,
        model: model,
        count: count
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.modelChange;
      const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//

      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              self.$router.push({name: "homeDevices", params: {home_id: home_id, serial_number: serial_number}});
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
            self.loading = false
          }
      );
    },
    btnLoading() {

      var $btn = $('#btn-model');
      $btn.click(function () {
        var $this = $(this);
        $this.attr('disabled', 'disabled').html("<span class=\"spinner-border spinner-border-sm\" role=\"status\" aria-hidden=\"true\"></span>");
        setTimeout(function () {
          $this.removeAttr('disabled').html();
        }, 2000)
      });
    }
  },
};
</script>


<style scoped>
</style>
