<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <div class="row">
          <div class="col-12">
            <div class="box">
              <div class="row no-gutters justify-content-md-center">
                <div class="col-lg-3 col-md-5 col-12">
                  <div style="text-align: center" class="mt-3">
                    <img :src="'/assets/images/smart/anim-door.jpg'"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="box">
              <div class="row no-gutters justify-content-md-center">
                <div class="box-body">
                  <div class="text-center py-5 mt-5">
                    <ul class="flexbox flex-justified text-center my-10">
                      <li class="px-2">
                        <a
                            class="btn btn-app btn-outline-secondray btn-light"
                            v-on:click.prevent="TopFunction()"
                        >
                          <i class="mdi mdi-garage-open mdi-36px"></i>
                        </a>
                      </li>
                      <li class="px-2">
                        <a
                            class="btn btn-app btn-outline-secondary btn-light"
                            v-on:click.prevent="StopFunction()"
                        >
                          <i class="mdi mdi-stop mdi-36px"></i>
                        </a>
                      </li>
                      <li class="px-2">
                        <a
                            class="btn btn-app btn-outline-secondary btn-light"
                            v-on:click.prevent="BottomFunction()"
                        >
                          <i class="mdi mdi-garage mdi-36px"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <SubModalDel v-bind:data_info_sub="data_info_door"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_door"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_door"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {}
  },
  data() {
    return {
      pass: true,
      set_password: "",
      door_status:"",
      data_info_door: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.exitModal();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    try {
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      this.onSubscribe();
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({
                name: "homeDevices",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                },
              });
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCooler');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    TopFunction() {
      
      if (this.set_password == 1) {
        this.door_status = "OPEN#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("OPEN#", "");
      }
    },
    StopFunction() {
      if (this.set_password == 1) {
        this.door_status = "STOP#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("STOP#", "");
      }
    },
    BottomFunction() {
      if (this.set_password == 1) {
        this.door_status = "CLOSE#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("CLOSE#");
      }
    },
    sendReq(password) {
      this.sendMessage(this.door_status, password);
    },
    sendMessage(saction, password) {
      var self = this;

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModal();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModal();
              connection = false;
              $("#modal-pass-code").modal("hide");
              timeout = setTimeout(function () {
                self.exitModal();
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
    onSubscribe(){
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.count
      );
      this.$mqtt.subscribe(topic1);
    }
  },
};
</script>
<style scoped>
</style>
