<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i
              class="ti-angle-right"
              style="font-size: 24px"
              @click="goBackTimer()"
            ></i>
            <div class="media-body p-0">
              <strong>{{ $t('message.AppBar.Scheduler') }}</strong>
            </div>
          </div>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row 1-->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box" style="height: 812px">
              <div class="box-body p-0">
                <!-- row All -->
                <div
                  class="media-list media-list-hover media-list-divided"
                  id="row_all"
                  style="display: none"
                >
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">ALL</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input
                          name="checkoutlet"
                          id="checkall"
                          type="checkbox"
                        />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="nextAll()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- row L1 -->
                <div
                  class="media-list media-list-hover media-list-divided"
                  id="row_l1"
                >
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">L1</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input name="checkoutlet" id="check1" type="checkbox" />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="nextL1()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- row L2 -->
                <div
                  class="media-list media-list-hover media-list-divided"
                  id="row_l2"
                  style="display: none"
                >
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">L2</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input name="checkoutlet" id="check2" type="checkbox" />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="nextL2()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- row L3 -->
                <div
                  class="media-list media-list-hover media-list-divided"
                  id="row_l3"
                  style="display: none"
                >
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">L3</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input name="checkoutlet" id="check3" type="checkbox" />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="nextL3()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- row L4 -->
                <div
                  class="media-list media-list-hover media-list-divided"
                  id="row_l4"
                  style="display: none"
                >
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">L4</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input name="checkoutlet" id="check4" type="checkbox" />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="nextL4()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. -->
  </div>
  <!-- /. Wrapper -->
</template>

 <script>
var status_checkbox = "K5F";

export default {
  components: {},
  data: () => ({}),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
  },
  mounted() {
    let self = this;
    this.$store.dispatch("InfoSubDevice");
    let checkboxall = document.getElementById("checkall");
    let checkbox1 = document.getElementById("check1");
    let checkbox2 = document.getElementById("check2");
    let checkbox3 = document.getElementById("check3");
    let checkbox4 = document.getElementById("check4");
    var pol = this.$store.getters.getPolSub;

    var rowall = document.getElementById("row_all");
    var rowl1 = document.getElementById("row_l1");
    var rowl2 = document.getElementById("row_l2");
    var rowl3 = document.getElementById("row_l3");
    var rowl4 = document.getElementById("row_l4");

    if (pol == 1) {
      rowl1.style.display = "block";
    }
    if (pol == 2) {
      rowl1.style.display = "block";
      rowl2.style.display = "block";
      rowall.style.display = "block";
    }
    if (pol == 3) {
      rowl1.style.display = "block";
      rowl2.style.display = "block";
      rowl3.style.display = "block";
      rowall.style.display = "block";
    }
    if (pol == 4) {
      rowl1.style.display = "block";
      rowl2.style.display = "block";
      rowl3.style.display = "block";
      rowl4.style.display = "block";
      rowall.style.display = "block";
    }

    checkboxall.addEventListener("change", function (element) {
      if (checkboxall.checked) {
        self.checkBoxChange(checkboxall);
      }
    });
    // ********************  get checkBox1 value
    checkbox1.addEventListener("change", function (element) {
      if (checkbox1.checked) {
        self.checkBoxChange(checkbox1);
      }
    });
    // ********************  get checkBox2 value
    checkbox2.addEventListener("change", function (element) {
      if (checkbox2.checked) {
        self.checkBoxChange(checkbox2);
      }
    });
    // ********************  get checkBox3 value
    checkbox3.addEventListener("change", function (element) {
      if (checkbox3.checked) {
        self.checkBoxChange(checkbox3);
      }
    });
    // ********************  get checkBox4 value
    checkbox4.addEventListener("change", function (element) {
      if (checkbox4.checked) {
       self.checkBoxChange(checkbox4);
      }
    });
  },
  methods: {
    nextAll() {
      let checkboxall = document.getElementById("checkall");
      if (checkboxall.checked) {
        status_checkbox = "K5T";
      } else if (!checkboxall.checked) {
        status_checkbox = "K5F";
      }
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "LampStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL1() {
      let checkbox1 = document.getElementById("check1");
      if (checkbox1.checked) {
        status_checkbox = "K1T";
      } else if (!checkbox1.checked) {
        status_checkbox = "K1F";
      }
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "LampStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL2() {
      let checkbox2 = document.getElementById("check2");
      if (checkbox2.checked) {
        status_checkbox = "K2T";
      } else if (!checkbox2.checked) {
        status_checkbox = "K2F";
      }
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "LampStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL3() {
      let checkbox3 = document.getElementById("check3");
      if (checkbox3.checked) {
        status_checkbox = "K3T";
      } else if (!checkbox3.checked) {
        status_checkbox = "K3F";
      }
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "LampStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    nextL4() {
      let checkbox4 = document.getElementById("check4");
      if (checkbox4.checked) {
        status_checkbox = "K4T";
      } else if (!checkbox4.checked) {
        status_checkbox = "K4F";
      }
      var saction = "PR" + this.count + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "LampStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    goBackTimer() {
      this.$router.push({
        name: "LampTimerShow",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
        },
      });
    },
    checkBoxChange(checkBox) {
      let checkboxall = document.getElementById("checkall");
      let checkbox1 = document.getElementById("check1");
      let checkbox2 = document.getElementById("check2");
      let checkbox3 = document.getElementById("check3");
      let checkbox4 = document.getElementById("check4");

      if (checkBox == checkboxall) checkboxall.checked = true;
      else checkboxall.checked = false;

      if (checkBox == checkbox1) checkbox1.checked = true;
      else checkbox1.checked = false;

      if (checkBox == checkbox2) checkbox2.checked = true;
      else checkbox2.checked = false;

      if (checkBox == checkbox3) checkbox3.checked = true;
      else checkbox3.checked = false;

      if (checkBox == checkbox4) checkbox4.checked = true;
      else checkbox4.checked = false;
    },
  },
};
</script>



<style scoped>
</style>
