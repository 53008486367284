<template>
    <v-app>
      <section >
        <v-container class="text-center">
          <v-row >
            <v-col cols="12" >
              <v-img
               src="/assets/images/smart/vector-reset-factory.svg"
                 class="avatar-smart mx-auto"
              />
              <h4 class="text-danger"><strong>{{  $t('message.public.warning')}}</strong></h4>
              <h6 style="line-height: 25px !important">
                {{txtDescript}}
              </h6>
              <v-btn
                color="bg-danger"
                type="button"
                class="mb-5 mt-10 m-5"
                v-on:click.prevent="DelFunction()"
               :loading="loading"
              >
               <label class="mt-1 text-white"> {{$t('message.public.OK')}}</label>
              </v-btn>
              <v-btn
                type="button"
                class="btn btn-dark bg-dark mb-5 mt-10 m-5"
                v-on:click.prevent="CancelFunction()">
                <span class="text-white"> {{ $t('message.public.cancel') }} </span>
              </v-btn>
            </v-col>
          </v-row>
            <div v-if="countshow">
            <h6  class="mt-20"> {{ $t('message.ModalCenter.wait') }} <label class="text-primary"> ({{ countDown }} {{ $t('message.public.seconds') }})</label> </h6>
          </div>
        </v-container>
      </section>
    </v-app>
</template>
 <script>

import AppBar from "@/components/AppBar.vue";

export default {
 components:{
    AppBar,
  },
   data() {
    return {
       title:'',
      description:'',
      countDown:20
      
    };
  },
    props: {
    loading:'',
    txtDescript:'',
    countshow:{
      default:false
    },
 
  },
  
  created() {
    this.title= this.$t('message.noneHome.reset');
  },
  methods: {
    CancelFunction(){
     this.$emit("clicked", 'cancel');
     this.countDown=20
},
    DelFunction(){
      let self=this;
     this.$emit("clicked", 'delete');
     this.countDownTimer();
      setTimeout(function () {
            self.countDown=20
          }, 21000);
},
countDownTimer(){
   if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
}
  },
  

};
</script>



<style scoped>
</style>
