<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i :class=" {'ti-angle-left':this.lang === 'en',
                'ti-angle-right':this.lang !== 'en'}" style="font-size:24px" @click="goBackLamp()"></i>
            <div class="media-body p-0">
              <strong>{{ title }}</strong></div>
          </div>
        </div>
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav" v-if="main_admin==1">
            <!-- Control Sidebar Toggle Button -->
            <li
                class="dropdown"
                v-on:click.prevent="goRefresh()()"
            >
              <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="mdi mdi-refresh mdi-24px"></i>
              </a>
            </li>
            <li class="dropdown">
              <a data-toggle="dropdown" href="#" aria-expanded="false"
              ><i class="ti-more-alt rotate-90"></i
              ></a>
              <div
                  class="dropdown-menu dropdown-menu-right"
                  x-placement="bottom-end"
                  style="
                  position: absolute;
                  transform: translate3d(-43px, 21px, 0px);
                  top: 0px;
                  left: 50px;
                  will-change: transform;
                "
              >
                <a class="dropdown-item" v-on:click.prevent="subEditShow()">
                  <i class="mdi mdi-border-color"></i
                  > {{ $t('message.CmpEditName.edit_name') }}
                  <!-- {{ $t('message.edit_name', { edit_name: 'ویرایش نام' }) }} --></a
                >
                <a class="dropdown-item" v-on:click.prevent="subDelShow()">
                  <i class="mdi mdi-delete"></i> {{ $t('message.public.delete') }}
                  <!-- {{ $t('message.delete', { delete: 'حذف' }) }} --></a
                >
                <a class="dropdown-item" v-on:click.prevent="subTimerShow()">
                  <i class="mdi mdi-clock"></i> {{ $t('message.AppBar.scheduler') }}
                  <!-- {{ $t('message.scheduler', { scheduler: 'زمانبندی' }) }} --></a
                >
                <a class="dropdown-item" v-on:click.prevent="subPassShow()">
                  <i class="mdi mdi-lock"></i>{{ $t('message.ModalAddHome.pin_code') }}
                  <!-- {{ $t('message.password', { password: 'گذرواژه' }) }} -->
                  <span v-if="this.$store.getters.getSetPasswordSub==1"
                        class="badge badge-success badge-sm p-2 ml-2 mr-2">{{ $t('message.AppBar.active') }}
                    <!-- {{ $t('message.active', { active: 'فعال' }) }} --></span>
                  <span v-if="this.$store.getters.getSetPasswordSub==0"
                        class="badge badge-danger ml-2 mr-2 p-2">{{ $t('message.AppBar.unActive') }}
                    <!-- {{ $t('message.unactive', { unactive: 'غیرفعال' }) }} --></span>
                </a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0 bg-white">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box-body p-0">
              <div id="trall" style="display: none">
                <div class="media media-single px-30 py-30">

                  <img
                      src="/assets/images/smart/lampoff.svg"
                      id="imgall"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>All</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionLAllOn()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionLAllOff()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
              <div id="trl1" style="display: none">
                <div class="media media-single px-30 py-30">

                  <img
                      src="/assets/images/smart/lampoff.svg"
                      id="imgl1"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>L1</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionL1On()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionL1Off()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
              <div id="trl2" style="display: none">
                <div class="media media-single px-30 py-30">

                  <img
                      src="/assets/images/smart/lampoff.svg"
                      id="imgl2"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>L2</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionL2On()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionL2Off()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
              <div id="trl3" style="display: none">
                <div class="media media-single px-30 py-30">

                  <img
                      src="/assets/images/smart/lampoff.svg"
                      id="imgl3"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>L3</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionL3On()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionL3Off()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
              <div id="trl4" style="display: none">
                <div class="media media-single px-30 py-30">

                  <img
                      src="/assets/images/smart/lampoff.svg"
                      id="imgl4"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>L4</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionL4On()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionL4Off()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
            </div>

            <!-- /.col -->
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- Modal -->
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- ./Modal Pass-->
    <div class="modal center-modal fade" id="modal-pass-lamp" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> {{ $t('message.public.security') }} </h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" style="font-size:28px;">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>{{ $t('message.ModalAddHome.pin_code') }}
                <!-- {{ $t('message.password', { password: 'گذرواژه' }) }} --><span
                    class="text-danger">*</span></h6>
              <input
                  type="number"
                  class="form-control"
                  required=""
                  aria-invalid="false"
                  maxlength="4"
                  pattern="[0-9]"
                  autocomplete="off"
                  style="-webkit-text-security: disc;margin-top:8px"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  id="idpassoutlet"
                  :placeholder=" $t('message.ModalAddHome.password_hint') "
                  v-model="passlamp"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassLamp()"
                >
                  <span class="text-white">{{ $t('message.public.send') }}
                    <!-- {{ $t('message.send', { send: 'ارسال' }) }} --></span>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal -->
    <SubModalDel v-bind:data_info_sub="data_info_lamp"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_lamp"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_lamp"></SubModalSetPass>
  </div>
  <!-- /. Wrapper -->
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {SucessToastMsg} from "../../../../public/assets/js/utils/util.js";
import {ErrorToastMsg} from "../../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {getTopics} from "@/../public/assets/js/utils/topics.js";

var connection = false;
var set_password;
var lmap_status;
var showToast;
var timeout;


export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  data() {
    return {
      passlamp: "",
      data_info_lamp: [this.home_id, this.serial_number, this.count],
      main_admin: '',
      title: '',
      isHidden: false,
      lang: ''
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;

          this.showGetMsg(String(data));
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted: function mounted() {
    try {
      // init run
      let self = this;
      Internets.Internet;
      setTimeout(function () {
        self.lastStausLamp()
      }, 300);
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      set_password = this.$store.getters.getSetPasswordSub;
      var pol = this.$store.getters.getPolSub;
      var topic1;
      var tapp = localStorage.getItem("t_app");
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      //Show and Hide pol in views
      var trall = document.getElementById("trall");
      var trl1 = document.getElementById("trl1");
      var trl2 = document.getElementById("trl2");
      var trl3 = document.getElementById("trl3");
      var trl4 = document.getElementById("trl4");
      if (pol == 1) {
        trl1.style.display = "block";
      }
      if (pol == 2) {
        trl1.style.display = "block";
        trl2.style.display = "block";
        trall.style.display = "block";
      }
      if (pol == 3) {
        trl1.style.display = "block";
        trl2.style.display = "block";
        trl3.style.display = "block";
        trall.style.display = "block";
      }
      if (pol == 4) {
        trl1.style.display = "block";
        trl2.style.display = "block";
        trl3.style.display = "block";
        trl4.style.display = "block";
        trall.style.display = "block";
      }
      // subscribe
      var topic1 = getTopics.getTopicActionReceive( this.serial_number,  this.count);
      this.$mqtt.subscribe(topic1);
    } catch (err) {
      let self = this;
      swal({
        title: "",
        text: self.$t('message.public.operation_failure'),
        type: "error",
        confirmButtonColor: "#DD6B55",
        confirmButtonText: self.$t('message.public.OK'),
        closeOnConfirm: false,
      }, function (isConfirm) {
        if (isConfirm) {
          self.$router.push({name: "homeDevices", params: {home_id: self.home_id, serial_number: self.serial_number},});
          swal.close();

        }
      });
    }

  },
  methods: {
    subDelShow() {
      $("#modal-del-sub").modal("show");
    },
    subEditShow() {
      $("#modal-edit-sub").modal("show");
    },
    subPassShow() {
      $("#modal-set-pass-sub").modal("show");
    },
    subTimerShow() {
      this.$router.push({
        name: "LampTimerShow",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          count: this.count,
        },
      });
    },
    functionLAllOn() {
      if (set_password == 1) {
        lmap_status = "K5T#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K5T#");
      }
    },
    functionLAllOff() {
      if (set_password == 1) {
        lmap_status = "K5F#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K5F#");
      }
    },
    functionL1On() {
      if (set_password == 1) {
        lmap_status = "K1T#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K1T#");
      }
    },
    functionL1Off() {
      if (set_password == 1) {
        lmap_status = "K1F#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K1F#");
      }
    },
    functionL2On() {
      if (set_password == 1) {
        lmap_status = "K2T#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K2T#");
      }
    },
    functionL2Off() {
      if (set_password == 1) {
        lmap_status = "K2F#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K2F#");
      }
    },
    functionL3On() {
      if (set_password == 1) {
        lmap_status = "K3T#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K3T#");
      }
    },
    functionL3Off() {
      if (set_password == 1) {
        lmap_status = "K3F#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K3F#");
      }
    },
    functionL4On() {
      if (set_password == 1) {
        lmap_status = "K4T#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K4T#");
      }
    },
    functionL4Off() {
      if (set_password == 1) {
        lmap_status = "K4F#";
        $("#modal-pass-lamp").modal("show");
      } else {
        this.sendMessage("K4F#");
      }
    },
    sendMessage(saction) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var device_serial = this.serial_number;
      var count = this.count;
      var password = this.passlamp;
      var topic_final;
      let self = this;
      var topic_final = getTopics.getTopicActionSend(device_serial, count);
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.openModalLoading();
              $("#modal-pass-lamp").modal("hide");

              // connection=false;
              timeout = setTimeout(function () {
                self.exitModalLoading();


                if (!connection) {

                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {

          }
      );
    },
    openModalLoading() {
      this.$parent.showModal();
    },
    exitModalLoading() {
      this.$parent.closeModal();
    },
    showGetMsg(msg) {

      var imgall = document.getElementById("imgall");
      var imgl1 = document.getElementById("imgl1");
      var imgl2 = document.getElementById("imgl2");
      var imgl3 = document.getElementById("imgl3");
      var imgl4 = document.getElementById("imgl4");
      if (msg == "K1F") {
        imgl1.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K1T") {
        imgl1.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K2F") {
        imgl2.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K2T") {
        imgl2.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K3F") {
        imgl3.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K3T") {
        imgl3.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K4F") {
        imgl4.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K4T") {
        imgl4.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K5T") {

        imgall.src = "/assets/images/smart/lampon.svg";
        imgl1.src = "/assets/images/smart/lampon.svg";
        imgl2.src = "/assets/images/smart/lampon.svg";
        imgl3.src = "/assets/images/smart/lampon.svg";
        imgl4.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K5F") {

        imgall.src = "/assets/images/smart/lampoff.svg";
        imgl1.src = "/assets/images/smart/lampoff.svg";
        imgl2.src = "/assets/images/smart/lampoff.svg";
        imgl3.src = "/assets/images/smart/lampoff.svg";
        imgl4.src = "/assets/images/smart/lampoff.svg";
      }
    },
    sendPassLamp() {
      if (!passHomeValidationInput(this.passlamp)) return
      this.sendMessage(lmap_status, this.passlamp);
      this.passlamp = "";
    },
    goBackLamp() {
      this.$router.push({name: "homeDevices", params: {home_id: this.home_id, serial_number: this.serial_number},});
    },
    lastStausLamp() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = "https://server.pfas.ir/api/user/sub/device/status";
      const p = postAjaxCall(URL, data, "");
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {

              let message = jsonArray.content.message;

              self.showStatusLamp(message);
            } else {
              if (response.status == 401) {
                if (jsonArray.code == '4001') {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {

          }
      );
    },
    showStatusLamp(message) {
      try {
        var imgall = document.getElementById("imgall");
        var imgl1 = document.getElementById("imgl1");
        var imgl2 = document.getElementById("imgl2");
        var imgl3 = document.getElementById("imgl3");
        var imgl4 = document.getElementById("imgl4");

        var L1_r = message.charAt(0);
        var L2_r = message.charAt(1);
        var L3_r = message.charAt(2);
        var L4_r = message.charAt(3);

        if (L1_r == 0) {
          imgl1.src = "/assets/images/smart/lampoff.svg";
        }
        if (L1_r == 1) {
          imgl1.src = "/assets/images/smart/lampon.svg";
        }
        if (L2_r == 0) {
          imgl2.src = "/assets/images/smart/lampoff.svg";
        }
        if (L2_r == 1) {
          imgl2.src = "/assets/images/smart/lampon.svg";
        }
        if (L3_r == 0) {
          imgl3.src = "/assets/images/smart/lampoff.svg";
        }
        if (L3_r == 1) {
          imgl3.src = "/assets/images/smart/lampon.svg";
        }
        if (L4_r == 0) {
          imgl4.src = "/assets/images/smart/lampoff.svg";
        }
        if (L4_r == 1) {
          imgl4.src = "/assets/images/smart/lampon.svg";
        }
        if (message == '1111') {
          imgall.src = "/assets/images/smart/lampon.svg";
        }
        if (message == '0000') {
          imgall.src = "/assets/images/smart/lampoff.svg";
        }
      } catch (err) {
        let self = this;
        swal({
          title: "",
          text: self.$t('message.public.operation_failure'),
          type: "error",
          confirmButtonColor: "#DD6B55",
          confirmButtonText: self.$t('message.public.OK'),
          closeOnConfirm: false,
        }, function (isConfirm) {
          if (isConfirm) {
            self.$router.push({name: "mainpage"});
            swal.close();

          }
        });
      }


    },
    goRefresh() {
      location.reload();
    }
  },
};
</script>


<style scoped>
</style>
