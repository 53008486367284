<template>
  <v-app>
    <div>
      <v-card>
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab>
            <label class="text-primary font-weight-bold"> {{ $t("message.ModalConfig.android") }}</label>
          </v-tab>
          <v-tab>
            <label class="text-primary font-weight-bold">{{ $t("message.ModalConfig.ios") }}</label>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                {{ $t("message.ModalConfig.txt1") }}
                <v-carousel v-model="model" :show-arrows="false" hide-delimiter-background class="mt-10" height="auto">
                  <v-carousel-item
                      v-for="(item, i) in itemsAndroid"
                      :key="i">
                    <v-img
                        max-height="350"
                        contain
                        :src="src + item.img"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                {{ $t("message.ModalConfig.txt1") }}
                <v-carousel v-model="model" :show-arrows="false" hide-delimiter-background class="mt-10" height="auto">
                  <v-carousel-item
                      v-for="(item, i) in itemsIos"
                      :key="i">
                    <v-img
                        max-height="350"
                        contain
                        :src="src + item.img"
                    ></v-img>
                  </v-carousel-item>
                </v-carousel>

              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div class="row rounded-16 px-15 fixed-bottom">
      <div class="col-12 col-lg-12 px-15">
        <a
            class="btn btn-primary btn-block btn-lg mb-5 text-white"
            role="button"
            href="http://192.168.100.76/config"
            target="_blank"
        >
          {{ $t("message.public.configuration") }}
        </a>
      </div>
    </div>
  </v-app>
</template>
<script>
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  props: {
    click: {},
  },
  data() {
    return {
      tab: null,
      model: 0,
      src: "/assets/images/smart/",
      colors: ["#007bff", "#ffc107", "#28a745", "#dc3545"],
      itemsAndroid: [
        {
          img: "step0.jpg",
        },
        {
          img: "step1.jpg",
        },
        {
          img: "step2.jpg",
        },
        {
          img: "step3.jpg",
        },
      ],
      itemsIos: [
        {
          img: "ios_config1.jpg",
        },
        {
          img: "ios_config2.jpg",
        },
        {
          img: "ios_config3.jpg",
        },
        {
          img: "ios_config4.jpg",
        },
      ],
    };
  },
  created() {
    let data = {
      title: this.$t("message.mainPage.configuration"),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'config');
  },
  mounted() {
  },
  watch: {
    click: function(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    Config() {
      window.location.href = "http://192.168.100.76/config";
    },
    onClickChild(value) {
      if (value === "back") {
        let login_id = localStorage.getItem("login_id");
        if (login_id == 1)
          this.$router.push({name: "mainpage"});
        else
          this.$router.push({name: "welcome"});
      }
    },
  },
};
</script>


<style scoped>
</style>
