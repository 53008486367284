<template>
    <!-- .wrapper  -->
    <v-app>
      <!-- Content Wrapper. Contains page content -->
      <v-stepper v-model="e1" editable >
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          color="bg-primary"
          @click="test()"
        >
          step 0
        </v-stepper-step>
  
        <v-divider></v-divider>
        <v-stepper-step step="2"
       >
          step 1
        </v-stepper-step>
      </v-stepper-header>
  
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12" height="450px">
          <section class="content">
            <v-row>
              <v-col cols="12">
                            <h6>
                              {{ $t('message.public.email') }}
                              <span class="text-danger">*</span>
                            </h6>
                            <input
                                id="inputsubfarahoushpol"
                                type="text"
                                class="form-control"
                                aria-invalid="false"
                                autocomplete="off"
                                maxlength="32"
                                :placeholder="$t('message.loginEmail.inputEmail')"
                                v-model="email"
                            />
                          <div class="row fixed-bottom mb-5 px-4">
                            <!-- /.col -->
                            <div class="col-12 text-center">
                              <v-btn
                                  class="btn btn-info btn-block"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="forgotPassFunction()"
                              >
                                <span class="text-white"> {{ $t('message.public.submit') }} </span>
                              </v-btn>
                            </div>
                            </div>
              </v-col>
            </v-row>
          </section>
          </v-card>
        </v-stepper-content>
  
        <v-stepper-content step="2">
          <v-card
            class="mb-12" height="450px">
          <section class="content">
            <v-row>
              <v-col cols="12">
                            <h6>
                              {{ $t('message.client.OK') }}
                              <span class="text-danger">*</span>
                            </h6>
                            <input
                                id="inputsubfarahoushpol"
                                type="text"
                                class="form-control"
                                aria-invalid="false"
                                autocomplete="off"
                                maxlength="32"
                                :placeholder="$t('message.loginVerify.inputCode')"
                                v-model="verify_code"
                            />  
                          <div class="row fixed-bottom mb-5 px-4">
                            <!-- /.col -->
                            <div class="col-12 text-center">
                              <v-btn
                                  class="btn btn-info btn-block"
                                  :loading="loading"
                                  :disabled="loading"
                                  color="bg-primary"
                                  @click="forgotPassVerifyFunction()"
                              >
                                <span class="text-white"> {{ $t('message.public.submit') }} </span>
                              </v-btn>
                            </div>
                            </div>
              </v-col>
            </v-row>
          </section>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    </v-app>
  </template>
  
  <script>
  //--------------------------------------  variable --------------------------------------//
  import {postAjaxCall} from "@/../public/assets/js/postAjax";
  import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
  import {
    EmailValidationInput ,
    smsCodeValidationInput,
    passEmailValidationInput,
    passCompareValidationInput
  } from "@/../public/assets/js/utils/error_handler";
  import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
  import LayoutDefault from "../../../layouts/LayoutDefault";
  
  
  export default {
    // components:{
    //   ModalCenter,
  
    // },
    props: {
      home_id: {},
      serial_number: {},
      id: {},
      new_user: {},
      click: {},
    },
    data: () => ({
          isModalVisible:false,
          email:'',
          verify_id:'',
          new_password:"",
          new_password_confirm:"",
          e1: 1,
          loading:false,
          verify_code:''
      // UserID: this.$route.params.userID,
    }),
    mounted() {
      //----------------- Language
      let details = {
        title: this.$t('message.public.reset_password'),
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'AddClientEmail');
      this.countDownTimer()
      let self = this;
  
      if (localStorage.getItem("log") === 0) {
        console.log = function () {
        };
      }
    },
    watch:{
      click(val) {
        this.onClickChild(val);
      }
    },
    methods: {
      onClickChild(val) {
        let self=this;
        if (val === 'back') {
          this.$router.push({
                name: "managementclientinfo",
                params: {home_id: self.home_id, serial_number: self.serial_number, type: ''}
              });
        }
      },
      test(){
       console.log("test")
       this.e1=1
      },
      step1(){
       
        this.e1=2
      },
      forgotPassVerifyFunction() {
        // this.$router.push({
        //   name: "loginEmail",
        // });
        let self = this;
        if (
            !smsCodeValidationInput(this.verify_code) ||
            !passEmailValidationInput(this.new_password) ||
            !passEmailValidationInput(this.new_password_confirm) ||
            !passCompareValidationInput(this.new_password,this.new_password_confirm)
        )
          return;
  
        var data = {
          verify_id: this.verify_id,
          verify_code: this.verify_code,
          new_password:this.new_password,
          new_password_confirm:this.new_password_confirm
  
        };
        //--------------------------------------Request --------------------------------------//
        let URL = getUrl.forgotPassEmailVerify;
        const p = postAjaxCall(URL, data);
        this.openModal();
        //--------------------------------------Response --------------------------------------//
        p.then(
            function (response) {
              if (responseAjax(response)) {
                self.closeModal();
  
                let jsonArray = JSON.parse(response.responseText);
               
                console.log("jsonArray",jsonArray)
                self.$router.push({
                 name: "loginEmail",
                  });
                // let dataparse = jsonArray.content.user;
                // dataparse.forEach(function (item) {
                //   localStorage.setItem("user_id", item.user_id);
                //   localStorage.setItem("token", item.token);
                // });
                // localStorage.setItem("login_id", 1);
                // self.$router.replace({name: "mainpage"});
              } else {
                self.closeModal();
              }
            },
            //--------------------------------------Err --------------------------------------//
  
            function (errr) {
  
              setTimeout(function () {
                self.closeModal();
              }, 1000);
            }
        );
      },
      forgotPassFunction() {
        let self = this;
        if (!EmailValidationInput(this.email))
          return;
  
        var data = {
          email:this.email
  
        };
        //--------------------------------------Request --------------------------------------//
        let URL = getUrl.forgotPassEmail;
        const p = postAjaxCall(URL, data);
        this.openModal();
        //--------------------------------------Response --------------------------------------//
        p.then(
            function (response) {
              if (responseAjax(response)) {
                self.closeModal();
                let jsonArray = JSON.parse(response.responseText);
                console.log("jsonArray",jsonArray)
                self.verify_id=jsonArray.content.verify_id
                self.e1=2;
              } else {
                self.closeModal();
              }
            },
            //--------------------------------------Err --------------------------------------//
  
            function (errr) {
  
              setTimeout(function () {
                self.closeModal();
              }, 1000);
            }
        );
      },
      // sendDataAgain() {
      //   let self = this
      //   let mobile = localStorage.getItem("mobile_number");
      //   var data = {
      //     mobile: mobile,
      //   };
      //   //--------------------------------------Request --------------------------------------//
      //   let URL = getUrl.login;
      //   const p = postAjaxCall(URL, data);
      //   this.openModal();
      //   //--------------------------------------Response --------------------------------------//
      //   p.then(
      //       function (response) {
      //         self.closeModal();
      //         if (responseAjax(response)) {
      //           let jsonArray = JSON.parse(response.responseText);
      //           localStorage.setItem("verifyid", jsonArray.content.verify_id);
      //         } else {
      //         }
      //       },
      //       //--------------------------------------Err --------------------------------------//
      //       function (errr) {
      //       }
      //   );
      // },
      countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1
            this.countDownTimer()
          }, 1000)
        }
      },
      openModal() {
        this.isModalVisible = true;
      },
      closeModal() {
       this.isModalVisible = false;
      },
    },
  };
  </script>
  <style scoped>
  </style>
  