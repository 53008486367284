<template>
  <v-sheet>
    <v-app-bar class="sticky-top" id="navbar">
      <AppBar
              style="width: 100%;  top: 0"
        @clicked="clicked"
        :update="update"
      ></AppBar>
    </v-app-bar>
    <main class="LayoutDefault__main" >
        <slot />

      </main>
  </v-sheet>
</template>

<script>
import AppBar from "../components/AppBar";
import {Internets} from "../../public/assets/js/utils/util.js";
export default {
  name: "LayoutDefault",
  props: {
    update: {
      type: String,
      default: "",
    },
  },
  components: {AppBar},
  data() {
    return {
      data: {},
      click: '',
      scroll: true
    }
  },
   mounted() {
    Internets.Internet();
   },
  watch: {
    update() {
      // window.scrollTo(10,10)
      // this.$el.scrollTo(0, 55)
      // let main = document.getElementById("scroll")
      // let scroll = document.getElementById("scroll")
      // scroll.scrollTop = 55
      // $('#scroll').scrollTop(55);
      // var nyedva = document.getElementById('scroll');
      // nyedva.scrollTop = 400;
      // $('#scroll').scrollTop = 50
      // console.log($('#scroll').offset())
      // window.scrollTop = 0
      // window.scrollTo(0, 55)
      // this.scroll = false
      // setTimeout(function () {
      //   this.scroll = true
      // },1)
    }
  },
  methods: {
    clicked(val) {
      this.click = val
      this.$emit('onClickChild', val);
      console.log("clicked", this.click);
      
      // this.$destroy()
    },
  },
}
</script>

<style>
.sticky-top {
    position: sticky;
    top: 0;
}
#navbar{
  background-color: #0F6BB9 !important;
  border-radius: 0px 0px 15px 15px !important;
  position: fixed;
}

.per-style{
  margin-top: 60px !important;
}

.v-application--wrap {
    min-height: 80vh !important;
    margin-top: 60px ;
}
@media screen and (max-width: 959px) {
 .v-application--wrap {
    min-height: 80vh !important;
    margin-top: 55px ;
}
.per-style{
    margin-top: 55px !important;
}
}
.v-application .v-application--wrap  {
font-family: 'IRANSans' !important;
}
.v-application {
font-family: 'IRANSans' !important;
}
.v-btn__content{
  letter-spacing: 0px !important;

}
</style>