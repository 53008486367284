<template>
  <v-container fill-height class="p-0">
  <v-row align="center"
      justify="center">
      <v-col align="center" class="p-0"
      justify="center">
     <v-img v-if="imgShow"
      class="align-center"
      height="60px"
      width="60px"
      :src="srcimg+img">
    </v-img>
    <v-card-text class="text-primary text-center">
      <div><label style="font-size:12px" v-if="txtShow">{{title}}</label></div>
      <div class="text-secondary" v-if="txtCaptionShow"><label>{{caption}}</label></div>
       <v-btn  @click="addClick()"  small   class="mt-1 btn btn-primary btn-outline" text v-if="btnShow">
      <small  class="text-primary">{{btn}}</small >
      </v-btn>
    </v-card-text>
      </v-col>
   </v-row>
  </v-container>

</template>

<script>
export default {
  props: {
    txt:'',
    txtShow:{
      default:true
    },
    txtCaption:'',
    txtCaptionShow:{
       default:true
     },
   
    imgName:'',
    imgShow:{
      default:true
    },
    txtBtn:'',
    btnShow: {
      default: true
    },
  },
  data() {
    return {
      title: this.$t('message.CmpEmptyData.NotFound'),
      caption:this.$t('message.CmpEmptyData.caption'),//TODO ar and en not found
      btn:this.$t('message.public.add'),
      img:'box.svg',
      srcimg:'/assets/images/smart/',
    };
  },
  created() {
    if (typeof (this.txt) != "undefined") {
      this.title=this.txt
    }
    if (typeof (this.txtCaption) != "undefined") {
      this.caption=this.txtCaption
    }
    if (typeof (this.txtBtn) != "undefined") {
      this.btn=this.txtBtn
    }
    if (typeof (this.imgName) != "undefined") {
      this.img=this.imgName
    }
    // if (typeof (this.btnShow) != "undefined") {
    //   this.btncmpShow=true
    // }


  },
  methods: {
  addClick() {
     this.$emit("clicked", 'add');
  },
  },
};
</script>
<style  scoped>
</style>
