<template>
  <v-app class="bg">
    <v-container class="pt-0">
      <v-row
          v-if="items.length > 0"
          class="d-flex align-center mt-2"
      >
        <v-card
            width="100%"
            elevation="0"
            style="border-radius: 10px"
            class="m-2 px-1 align-center d-flex"
            v-for="(info,index) in data.items"
            :key="index"
        >
          <v-col class="p-0" cols="9">
            <div v-if="data.type === '8Relay' || data.type === 'IR'">
              <CmpRowList
                  :infoItem="info"
                  :subTitleShow="true"
                  :ripple="false"
                  :type="data.type"
                  @clicked="clicked"
              ></CmpRowList>
            </div>
            <TimerRow v-else :info="info" :index="index" :type="data.type" @boxChange="checkBoxChange"/>
          </v-col>
          <v-col class="d-flex align-center justify-end" cols="3">
            <v-btn
                color="primary"
                class="btn btn-primary btn-sm text-white"
                elevation="3"
                v-on:click.prevent="next(index)"
            ><span class="text-white">{{ $t('message.public.next') }}</span></v-btn>
          </v-col>
        </v-card>
      </v-row>
      <v-row v-if="items.length===0 && isFinished" class="mt-5">
        <v-col cols="12" class="d-flex flex-column align-center justify-center">
          <label v-if="data.type==='IR'">{{ $t('message.timer.IRNotFound') }}</label>
          <label v-else>{{ $t('message.timer.notfound') }}</label>
          <v-btn
              color="primary"
              class="btn btn-primary btn-sm text-white mt-2"
              elevation="3"
              v-on:click.prevent="back()"
          ><span class="text-white">{{ $t('message.public.return') }}</span></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import TimerRow from "./TimerRow";
import CmpRowList from "../../../CmpRowList";

export default {
  name: "timer",
  components: {TimerRow, CmpRowList},
  props: {
    data: {},
    isFinished: {
      default: false,
    },
  },
  data() {
    return {
      items: [""],
    };
  },
  mounted() {
    this.items = this.data.items;
    console.log("mounted", this.data)
  },
  methods: {
    back() {
      this.$emit("back");
    },
    next(val) {
      if (this.data.type === "8Relay")
        this.$emit('next', val, this.data.items[val].switch);
      else if (this.data.type === "IR")
        this.$emit('next', val, this.data.items[val]);
      else
        this.$emit('next', this.data.items[val].name, val, this.data.items[val].status);
    },
    checkBoxChange(checkBox) {
      let check = this.data.items[checkBox].status;
      for (let i = 0; i < this.data.items.length; i++) {
        if (checkBox === i)
          this.data.items[checkBox].status = check;
        else
          this.data.items[i].status = false;
      }
    },
    clicked(checkBox) {
      let check = this.data.items[checkBox.index].switch;
      for (let i = 0; i < this.data.items.length; i++) {
        if (checkBox.index === i)
          this.data.items[checkBox.index].switch = check;
        else
          this.data.items[i].switch = false;
      }
    },
  }
}
</script>

<style scoped>

</style>