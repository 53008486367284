<template>
  <v-app class="bg">
    <div class="mr-0">
      <v-container>
        <v-card>
          <div class="box-footer with-border p-0">
            <div class="media media-single px-5 py-20">
              <v-icon class="mdi mdi-24px text-gray">mdi-water-pump</v-icon>
              <div class="media-body">
                <h6>{{ $t('message.subDevice.cooler_pomp') }}</h6>
              </div>
              <v-btn fab depressed class="mb-1" :color="style_0" elevation="0" @click="FunctionBtnPump()">
                <v-icon class="mdi mdi-24px text-white" id="imgdry">mdi-water</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="box-footer with-border p-0">
            <div class="media media-single px-5 py-20">
              <img src="/assets/images/smart/icon-fan-slow-black.svg" style="width: 22px; height: 22px;" />
              <div class="media-body">
                <h6>{{ $t('message.subDevice.cooler_low') }}</h6>
              </div>
              <v-btn fab depressed class="mb-1" :color="style_1" elevation="0" @click="FunctionBtnFan3()">
                <img id="imgfanlow" src="/assets/images/smart/icon-fan-slow-white.svg"
                  style="width: 24px; height: 24px" />
              </v-btn>
            </div>
          </div>
          <div class="box-footer with-border p-0">
            <div class="media media-single px-5 py-20">
              <v-icon class="mdi mdi-24px text-gray">mdi-fan</v-icon>
              <div class="media-body">
                <h6>{{ $t('message.subDevice.cooler_fast') }}</h6>
              </div>
              <v-btn fab depressed class="mb-1" :color="style_2" elevation="0" @click="FunctionBtnFan4()">
                <img id="imgfanfast" src="/assets/images/smart/icon-fan-fast-white.svg"
                  style="width: 24px; height: 24px" />
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card>
          <v-row class="rounded-16 mt-2">
            <v-col class="col-xl-12 col-md-12 col-12 rounded-16">
              <div class="media align-items-center bg-white py-20 px-5 rounded">
                <v-icon class="mdi mdi-24px text-gray">mdi-power</v-icon>
                <div class="media-body">
                  <h6>{{ $t('message.public.off') }}</h6>
                </div>
                <v-btn fab depressed class="mb-1" :color="style_3" elevation="0" @click="FunctionBtnOff()">
                  <v-icon class="mdi text-white mdi-24px">mdi-power</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <!-- <ModalOutletMomentray
      v-bind:serials="serial_number"
      :v-bind:subdevice_serial="subdevice_serial"
      v-bind:opentime="opentime" 
      v-bind:closetime="closetime" 
      type="doorShutter"
      @set_time_shutter="setTimeShutter"
    ></ModalOutletMomentray> -->
    <ModalResetFactoryCooler :infoItem="dataReset"></ModalResetFactoryCooler>
    <SubModalDel v-bind:data_info_sub="data_info_cooler"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_cooler"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_cooler"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { getTopics } from "@/../public/assets/js/utils/topics";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { Color, hexToRgb } from "@/../public/assets/js/utils/colorRGB";
import { Solver } from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { Internets } from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryCooler from "../../subdevice/coolerpro/ModalResetFactoryCooler";
// import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import { getMessageSub } from "@/../public/assets/js/utils/helper_sub_message.js";
import { passHomeValidationInput } from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    ModalResetFactoryCooler,
    // ModalOutletMomentray,

  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      sub_device_info:[],
      type_Selected_item: "action",
      // passkeytouchfara: "",
      // data_info_keytouch_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      // pass: true,
      // pol: 1,
      // labels: [],
      isModalVisible: false,
      subdevice_serial: "",
      message_status: "",
      pass: 0,
      data: [],
      info: [],
      // rgbInfo:[],
      dataReset: [],
      txtStatus: '',
      data_info_cooler: [this.home_id, this.serial_number, this.count],
      delayOpen: "",
      keyOpen: "",
      delayClose: "",
      keyClose: "",
      typeRequest: 0,
      status: [],
      style_0: "",
      style_1: "",
      style_2: "",
      style_3: "red",
      // polall:false,
      // pol1:false,
      // pol2:false,
      // pol3:false,
      // pol4:false,
      // result1: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result2: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result3: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      // result4: {
      //   onFilter: "",
      //   offFilter: "",
      //   check: false,
      // },
      set_password: 0,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }

    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data))
      }
    },
  },
  created() {
    let self = this;
    let data = localStorage.getItem("infoSubDevice");
    data = JSON.parse(data);
    this.status = [false, false, false, false];
    // if (!JSON.parse(data.labels))
    //   this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
    //     pol: "3",
    //     label: "L4"
    //   }];
    // else
    //   this.labels = JSON.parse(data.labels);
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
  },
  mounted: function mounted() {

    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    // this.pol = this.$store.getters.getPolSub;
    this.main_admin = this.$store.getters.getadmin;
    this.title = this.$store.getters.getNameSub;

    // this.showHidePol();
    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    // console.log("info",this.info.type.type_code)
    this.subdevice_serial = this.info.serial_number;
    this.dataReset.push({ serial_number: this.serial_number, subdevice_serial: this.subdevice_serial })
    // init run
    // try {

    this.lastStatus();
    // subscribe
    this.onSubscribe();


    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      sub_setting:true,
      // pin: this.pass,
      // passcode: this.set_password,
      // menu: true,
      // edit: true,
      // del: true,
      // refresh: true,
      // timer: true,
      // about: true,
      // update: true,
      // editRgb: true,
      // // momentary: true,
      // wifi_sub_onoff: true,
      // subReset: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCoolerPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {

    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSetingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
    },
    // setTimeShutter(delayOpen, keyOpen, delayClose, keyClose) {
    //   if (this.set_password == 1) {
    //     $("#modal-pass-code").modal("show");
    //     this.delayOpen=delayOpen;
    //     this.keyOpen=keyOpen;
    //     this.delayClose=delayClose;
    //     this.keyClose=keyClose;
    //     this.typeRequest=1;

    //   } else {
    //     this.sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, "");
    //   }
    // },
    sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var sendDelayTime = getMessageSub.settingDelayTime(keyOpen, delayOpen, keyClose, delayClose)
      var topic_final = getTopics.getTopicElevatorSend(
        this.serial_number,
        this.subdevice_serial
      );

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: sendDelayTime,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            connection = false;
            console.log('jsonArray', jsonArray);
            $("#modal-pass-code").modal("hide");
            $("#modal-outlet-momentray").modal("hide");
            timeout = setTimeout(function () {
              self.closeModalLoading();
              // showToast = 0;
              // connection = false;
              if (!connection) {
                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                }
              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.closeModalLoading();
            $("#modal-outlet-momentray").modal("hide");
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) { }
      );
    },
    // openFunction() {
    //   this.sendMessage("K1T#");
    //   this.typeRequest=0;
    // },
    // stopFunction() {
    //   this.sendMessage("K2T#");
    //   this.typeRequest=0;
    // },
    // closeFunction() {
    //   this.sendMessage("K3T#");
    //   this.typeRequest=0;
    // },
    FunctionBtnPump() {
      if (this.status[0] === false) {
        this.sendMessage("K1T#");
      } else {
        this.sendMessage("K1F#");
      }
    },
    FunctionBtnFan3() {
      if (this.status[1] === false) {
        this.sendMessage("K2T#");
      } else {
        this.sendMessage("K2F#");
      }
    },
    FunctionBtnFan4() {
      if (this.status[2] === false) {
        this.sendMessage("K3T#");
      } else {
        this.sendMessage("K3F#");
      }
    },
    FunctionBtnOff() {
      this.sendMessage("K5F#");
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
        function (response) {
          self.closeModalLoading();
          let jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            let message = jsonArray.content.message;
            // ****** code save data in subdevice
            self.sub_device_info.push({
              subDevices: jsonArray.content.device_info
            })
            localStorage.setItem("infoSubDevice", JSON.stringify(self.sub_device_info[0].subDevices))
            // ****** code save data in subdevice
            self.parseStatusCooler(message);
          } else {
            self.closeModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function () {
          // console.log("err", errr);
        }
      );
    },

    // parserRgbSetting(){
    //   let self=this;
    //   if(this.info.rgb_setting==null){
    //     this.info.rgb_setting='L1ON00ff00L1OFFff0000L2ON00ff00L2OFFff0000L3ON00ff00L3OFFff0000L4ON00ff00L4OFFff0000'
    //   }
    //   var arrRgb =this.info.rgb_setting.split('L');
    //   arrRgb.forEach((item, index) => { 
    //           let str="";
    //           if(item.match("ON")){
    //             str = item.substr(0 + 3, 6);
    //           }
    //           if(item.match("OFF")){
    //             str = item.substr(0 + 4, 6);
    //           }
    //           self.rgbInfo.push({"id":index,"color":'#'+str})
    //    })
    //    this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
    //    onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color,self.rgbInfo[5].color,self.rgbInfo[7].color],
    //    offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
    // };

    //   },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if(value== "sub_setting"){
        this.$router.push({
          name: "CoolerProSetting",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      // if (value === "momentary") {
      //   $("#modal-outlet-momentray").modal("show");
      // }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "AboutCoolerPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "wifi_sub_onoff") {
        this.$router.push({
          name: "CoolerProStatusWifi",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "subReset") {
        $("#modal-reset-coolerPro").modal('show');
      }
      if (value == "update") {
        this.showUpdateDialog();
      }
      if (value == "editRgb") {
        this.$router.push({
          name: "CoolerEditRGBPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStatus();
        // location.reload();
      }
      if (value == "timer") {
        let data = {
          // labels: this.labels,
          // pol: this.pol,
          type: "PRO",
          type_code: this.info.type.type_code,
        }
        localStorage.setItem("data_edit_sub", JSON.stringify(data));
        this.$router.push({
          name: "CoolerProTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {

      this.message_status = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.message_status, '');
      }
    },
    sendReq(password) {

      // if(this.typeRequest==0){
      console.log("this.message_status", this.message_status)
      this.sendRequest(this.message_status, password);
      // }
      // else if(this.typeRequest==1){
      //   this.sendRequestLong(this.delayOpen, this.keyOpen, this.delayClose, this.keyClose,password);
      // }
    },
    sendRequest(message, password) {
      console.log("msg", message)
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final = "";


      this.type_Selected_item == 'update' ?
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial) :
        topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      console.log("topic_final", topic_final)
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      console.log("data", data)
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
        (result) => {
          if (result) {
            this.onSubscribe();
            if (this.set_password == 1) {
              $("#modal-pass-code").modal("show");
              this.message_status = "UPDATE#";
              this.type_Selected_item = "update";
              this.typeRequest = 0;
            } else {
              this.type_Selected_item = "update"
              this.sendRequest('UPDATE#', '');
            }
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          } else {
            throw dismiss;
          }
        }
      );
    },

    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      this.parseStatusCooler(msg)
      if (msg == "UPDATEOK") {
        swal({
          // title: self.$t('message.timer.timer'),
          title: this.$t("message.public.refresh"),
          text: this.$t("message.public.update_done"),
          type: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    parseStatusCooler(msg) {
      console.log("showGetMsg", msg);
      let styles = ["#aaaaaa", "#aaaaaa", "#aaaaaa"];
      this.status = [false, false, false];
      for (var i = 0; i < msg.length - 1; i++) {
        if (msg[i] === "0") {
          this.status[i] = false;
          styles[i] = "#aaaaaa";
        } else {
          this.status[i] = true;
          styles[i] = "primary";
        }
      }
      this.style_0 = styles[0];
      this.style_1 = styles[1];
      this.style_2 = styles[2];
      console.log('msg mqtt', styles);
    },
  },
}
</script>

<style scoped></style>