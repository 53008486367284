<template>
  <!-- .wrapper  -->
  <v-app class="bg mr-0">
    <div class="mr-0">
      <!-- Main content -->
      <section class="content mt-5">
        <div class="row px-20" id="app">
          <div class="col-12 py-0">
            <div class="row">
              <div class="col-lg-12 col-12">
                <div class="box bg-facebook">
                  <div class="box-body">
                    <p class="font-size-20 text-white">
                      <i class="mdi mdi-chart-arc"></i> {{ $t('message.ir.report') }}
                    </p>
                    <ul
                        class="flexbox flex-justified text-center py-2"
                        style="border-radius: 10px"
                    >
                      <li class="text-right px-5">
                        <div class="font-size-30">
                          <i
                              class="mdi mdi-water-percent mdi-24px text-white"
                          ></i>
                          <span class="font-size-20 text-white"> {{ $t('message.ir.humidity') }} </span>
                        </div>
                      </li>
                      <li class="px-10 text-left">
                        <div class="font-size-30">
                          <span class="font-size-30 text-white">
                            {{ humidity_val }}
                          </span>
                        </div>
                      </li>
                    </ul>
                    <ul
                        class="
                        flexbox
                        flex-justified
                        text-center
                        py-2
                        bg-twitter
                      "
                        style="border-radius: 10px"
                    >
                      <li class="text-right px-5">
                        <div class="font-size-30">
                          <i
                              class="mdi mdi-thermometer mdi-24px text-white"
                          ></i>
                          <span class="font-size-20 text-white"> {{ $t('message.RelayKey.T') }} </span>
                        </div>
                      </li>
                      <li class="px-10 text-left">
                        <div class="font-size-30">
                          <span class="font-size-30 text-white">
                            {{ temp_val }}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                      class="
                      col-xl-12 col-md-12 col-12
                      rounded-16
                      py-0
                      text-center
                    "
                  >
                    <div class="box-footer border-0 bg-facebook px-10">
                      <div class="row">
                        <div class="col-12 text-right p-0">
                          <VueLoadingButton
                              @click.native="ATVC()"
                              class="btn btn-default btn-sm"
                              :loading="isLoading"
                              :style="isStyled"
                          >
                            <small class="text-primary">{{ $t('message.public.update') }}</small>
                          </VueLoadingButton>
                          <i class="mdi mdi-clock ml-1"></i>
                          <small class="text-white mr-1">
                            {{ clock_val }}</small
                          >
                          <small class="text-white pull-right mt-2 mr-1">
                            SN : {{ serial_number }}</small
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- .row device-->
           <div class="row mt-20 px-20" v-if="info.length == 0 && main_admin!=0">
             <CmpEmptyData
                 v-if="cmpShow"
                 @clicked="getAddClickCallback"
             ></CmpEmptyData>
           </div>
           <div class="mb-20">
             <div
                 class="row rounded-16 mt-0 "
                 v-bind:key="index"
                 v-for="(infos, index) in info"
                 @click="
                sendData(
                 infos.type_code,
                 infos.name,
                  infos.count,
                  infos.uid,
                infos.learned,
                 infos.key_count)">
               <div class="col-xl-12 col-md-12 col-12 rounded-16">
                 <div class="media align-items-center bg-white py-0 rounded">
                   <img
                       :src="infos.logo_path"
                       width="50px"
                       height="auto"
                       alt="..."
                   />
                   <div class="media-body">
                     <strong>{{ infos.name }}</strong>
                   </div>
                   <div id="sample" v-if="infos.type_code == 1001">
                     <img
                         src="/assets/images/smart/ir-television.svg"
                         width="120px"
                         height="120px"
                         alt="..."
                         style="opacity: 0.1"
                     />
                   </div>
                   <div id="sample" v-if="infos.type_code == 1002">
                     <img
                         src="/assets/images/smart/ir-speaker.svg"
                         width="120px"
                         height="120px"
                         alt="..."
                         style="opacity: 0.1"
                     />
                   </div>
                   <div id="sample" v-if="infos.type_code == 1003">
                     <img
                         src="/assets/images/smart/ir-air-conditioner.svg"
                         width="120px"
                         height="auto"
                         alt="..."
                         style="opacity: 0.1"
                     />
                   </div>
                 </div>
               </div>
             </div>
           </div>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>
    <!-- <ModalEditIR v-bind:data_infos="data_info"></ModalEditIR> -->
    <EditName v-bind:data_name="title" @clicked="getNameCmpCallback"></EditName>
    <ModalPassOutlet v-bind:data_infos="data_info"></ModalPassOutlet>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import {BetweenStr} from "../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../public/assets/js/utils/topics.js";
// import ModalEditIR from "./ModalEditIR";
import EditName from "@/components/Cmp/EditName.vue";
import CmpEmptyData from "../Cmp/public/CmpEmptyData.vue";
import VueLoadingButton from "vue-loading-button";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import ModalPassOutlet from "../devicewifi/ModalPassOutlet";
import LayoutDefault from "../../layouts/LayoutDefault";

var count;
var countSn;
var connection;

export default {
  components: {
    VueLoadingButton,
    EditName,
    CmpEmptyData,
    ModalPassOutlet
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      info: [],
      data_info: [this.home_id, this.serial_number],
      isLoading: false,
      isStyled: true,
      title: "",
      clock_val: "00.00.00",
      temp_val: "0.0",
      humidity_val: "0.0",
      cmpShow: false,
      set_password: "",
      pass: true,
      main_admin: '',
      isModalVisible: false,
    };
  },
  mqtt: {
    "+/+/receive/+/atvc"(data, topic) {
      if (topic.split("/")[1] == this.serial_number) {
        if (topic.split("/")[4] === "atvc") {
          connection = true;
          this.showGetMsgATVC(data + "");
        }
      }
    },
  },
  watch: {
    click(val){
      this.onClickChild(val);
    }
  },
  created() {
    this.$store.dispatch("infoDevice");
    this.$store.dispatch("infoHome");
    this.data = JSON.parse(localStorage.getItem("infoDevice"));
    this.pass = this.data.password === 1;
    this.set_password = this.data.set_password;
    this.title = this.$store.getters.getNameDevice;
    this.data_info.push(this.title);
    this.main_admin = this.$store.getters.getadmin;
  },
  mounted() {
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    let self = this;
    countSn = "256";
    setTimeout(function () {
      self.ATVC();
      self.lastStatus(user_id, token);
    }, 50);
    this.onSubscribe();
    let data = {
      title: this.title,
      timer: true,
      menu: true,
      editName: true,
      about: true,
      add: true,
      pin: this.pass,
      passcode: this.set_password
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IRGateway');
  },
  methods: {
    sendData(type_code, name, count, uid, learned, key_count) {
      const dataircontrols = {
        name: name,
        uid: uid,
        type_code: type_code,
      };

      localStorage.setItem("infoIrControls", JSON.stringify(dataircontrols));
      switch (type_code) {
        case 1001:
          this.$router.push({
            name: "irtvinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 1002:
          this.$router.push({
            name: "iramplifierinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 1003:
          this.$router.push({
            name: "irairinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        default:
          // code block
      }
    },
    ATVC() {
      this.isLoading = true;
      let self = this;
      setTimeout(function () {
        self.isLoading = false;
      }, 3000);
      var topic_final;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      countSn = "256";
      var topic_final = getTopics.getTopicAtvcSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: "ATVC?",
        client_id: "atv" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.message;
            } else {
              //error
            }
          },
          function (errr) {
            let jsonArray = JSON.parse(errr);
          }
      );
    },
    showGetMsgATVC(msg) {
      var T = BetweenStr.getBetweenStr(msg, "~T=", "~C");
      var H = BetweenStr.getBetweenStr(msg, "H=", "~T");
      var C = BetweenStr.getBetweenStr(msg, "~C", "#");

      this.temp_val = T[1] + "°C";
      this.humidity_val = H[1] + "%";
      this.clock_val = C[1];
    },
    lastStatus(user_id, token) {
      let self = this;
      var serial_number = this.serial_number;
      var data = {
        user_id: user_id,
        token: token,
        serial_number: serial_number,
      };
      let URL = getUrl.controlsInfo;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);

        if (responseAjax(response)) {
          self.closeModalLoading();
          self.info = jsonArray.content.control;
          self.cmpShow = true;
          var content = jsonArray.content.control;
          content.forEach(function (item) {
            count = item.count;
          });
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "pass") {
        $("#modal-pass-status-outlet").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-name").modal("show");
      }
      if (value == "add") {
        this.$router.push({
          name: "irtypeInfo",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "about") {
        this.$router.push({
          name: "aboutGatewayIrInfo",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "timer") {
        this.$router.push({
          name: "IRTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicAtvcReceive(this.serial_number, countSn);
      this.$mqtt.subscribe(topic1);
    },
    getNameCmpCallback(msg) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        name: msg,
        serial_number: this.serial_number,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.deviceEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.$router.push({
                name: "homeDevices",
                params: {
                  serial_number: self.serial_number,
                  home_id: self.home_id,
                },
              });
              self.lastStatus(user_id, token);
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    getAddClickCallback(msg) {
      if (msg == 'add') {
        this.$router.push({
          name: "irtypeInfo",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },
};
</script>
<style scoped>
#sample {
  width: 80px;
  height: 80px;
  overflow: hidden;
  position: relative;
  /* border: 1px solid black; */
  margin: 0;
}

#sample img {
  position: absolute;
  top: -10px;
  left: -10px;
}
</style>
