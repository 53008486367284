import i18n from "../../../src/i18n";
import Swal from 'sweetalert2'

export function postAjaxCall(url, data, page) {
    let tapp = process.env.VUE_APP_TEST || false

    // console.log('process.env.VUE_APP_TEST',process.env.VUE_APP_TEST)
    if (tapp === "true") {
        url = url.replace("https://", "https://t");
    }
    // console.log("url",url)
    if (navigator.onLine) {
        return new Promise(function (resolve, reject) {
            var ajax = new XMLHttpRequest();
            ajax.open('POST', url);
            ajax.setRequestHeader('Content-type', "application/json; charset=UTF-8")
            ajax.setRequestHeader('Accept-Language', i18n.locale)
            ajax.timeout = 1000000; // time in milliseconds
            ajax.ontimeout = function (e) {
                $("#modal-center").modal('hide');
                alert(i18n.t('message.postAjax.connection'));
            };
            ajax.addEventListener('load', function () {
                resolve(ajax)

            });
            ajax.onerror = function (error) {
                reject(error)
                $("#modal-center").modal('hide');
                setTimeout(function () {
                    
                }, 5000);
                // if (page === "mainPage")
                //     Swal.fire({
                //         title: i18n.t('message.public.error'),
                //         text: i18n.t('message.postAjax.connect'),
                //         type: "error",
                //         confirmButtonColor: '#DD6B55',
                //         confirmButtonText: i18n.t('message.postAjax.try'),
                //         closeOnConfirm: true,
                //     },
                //     function (isConfirm) {
                //         if (isConfirm) {
                //             ;
                //         } else if (result.isDenied) {
                //             //   Swal.fire('Changes are not saved', '', 'info')
                //         }
                //     });
                Swal.fire({
                    title: i18n.t('message.public.error'),
                    text: i18n.t('message.postAjax.connect'),
                    type: "error",
                    // showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: i18n.t('message.postAjax.try'),
                    // denyButtonText: `Don't save`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        // Swal.fire('Saved!', '', 'success')
                        // ;
                    } else if (result.isDenied) {
                        //   Swal.fire('Changes are not saved', '', 'info')
                    }
                })
            };

            ajax.send(JSON.stringify(data));

        });
    } else {
        $("#modal-center").modal('hide');
        if (page !== "mainPage")
            swal({
                title: i18n.t('message.public.error'),
                text: i18n.t('message.postAjax.internet'),
                type: "error",
                confirmButtonColor: '#DD6B55',
                confirmButtonText: i18n.t('message.postAjax.try'),
                closeOnConfirm: true,
            },
                function (isConfirm) {
                    if (isConfirm) {
                        //    console.log("isConfirm")
                        $("#modal-center").modal('hide');
                        setTimeout(function () {
              location.reload();
                        }, 5500);
                    }
                });
        else
        setTimeout(function () {
                        
            Swal.fire({
                title: i18n.t('message.public.error'),
                text: i18n.t('message.postAjax.internet'),
                type: "error",
                confirmButtonColor: '#DD6B55',
                confirmButtonText: i18n.t('message.postAjax.try'),
                // cancelButtonText: "config",
                // showCancelButton: true,
                closeOnConfirm: true,
            }).then(result => {
                if (result.isConfirmed) {
                    //    console.log("isConfirm")
                    $("#modal-center").modal('hide');
                    setTimeout(function () {
         location.reload();

                    }, 1000);
                }
                if (result.isDenied) {
                    this.$router.push({ name: "ModalConfig" }).then(r => r)
                }
            });
        }, 5000);
    }

}





