import { render, staticRenderFns } from "./IrGatewayNone.vue?vue&type=template&id=4a941471&scoped=true"
import script from "./IrGatewayNone.vue?vue&type=script&lang=js"
export * from "./IrGatewayNone.vue?vue&type=script&lang=js"
import style0 from "./IrGatewayNone.vue?vue&type=style&index=0&id=4a941471&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a941471",
  null
  
)

export default component.exports