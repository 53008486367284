<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <!-- .header -->
    <!--    <AppBar v-bind:title="title" v-bind:nonehome="true" @clicked="onClickChild"></AppBar>-->
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="mt-5 mr-0">
      <!-- Main content -->

      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickEditName()"
          >
            <i class="mdi mdi-border-color fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.CmpEditName.edit_name') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="TimerNone()"
          >
            <i class="mdi mdi-clock fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.AppBar.scheduler') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickManage()"
          >
            <i class="mdi mdi-account-settings mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.client.management') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickMomentray()"
          >
            <i class="mdi mdi-timer-sand fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.AppBar.instantSettings') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickTemp()"
          >
            <i class="mdi mdi-thermometer fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.setT') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickVolt()"
          >
            <i class="mdi mdi-pulse fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.setV') }}</p>
            </div>
          </div>
          <div class="media align-items-center" v-on:click.prevent="ClickAmp()">
            <i class="mdi mdi-tilde fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.setI') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickCLock()"
          >
            <i class="mdi mdi-clock fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.timer.setTime_device') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickAbout()"
          >
            <i class="mdi mdi-information-outline fa-2x"></i>
            <div class="media-body">
              <p> {{ $t('message.AppBar.about_device') }}</p>
            </div>
          </div>


          <div
              class="media align-items-center"
              v-on:click.prevent="ClickResetFactory()"
          >
            <i class="mdi mdi-format-rotate-90 fa-2x"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.reset') }}</p>
            </div>
          </div>
        </div>

        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <ModalNoneResetFactory v-bind:serialnumbers="serialnumber"></ModalNoneResetFactory>
    <ModalEditNameNone v-bind:serialnumbers="serialnumber"></ModalEditNameNone>
    <ModalNoneMomentray v-bind:serialnumbers="serialnumber"></ModalNoneMomentray>

    <!-- <EditName v-bind:data_name="'14141114'"></EditName> -->

  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import ModalEditNameNone from "./ModalEditNameNone";
import ModalNoneResetFactory from "./ModalNoneResetFactory";
import ModalNoneMomentray from "./ModalNoneMomentray";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {
    ModalEditNameNone,
    ModalNoneResetFactory,
    ModalNoneMomentray,
  },
  props: {
    serial_number: {},
    click: {},
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      homeid: this.home_id,
      serialnumber: this.serial_number,
      title: '',
      name: ''
    };
  },
  created() {
    this.$store.dispatch("infoNoneHome");
    this.name = this.$store.getters.getNameNoneHome;
    let data = {
      title: this.$t('message.public.settings'),
      noneHome: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNone');
  },
  mounted() {
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
  },
  watch:{
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    ClickManage() {
      localStorage.setItem("type", "7")
      this.$router.push({
        name: "noneHomeClientInfo",
        params: {serial_number: this.serial_number, type: "7"},
      });
    },
    ClickTemp() {

      this.$router.push({
        name: "noneGatewayTemp", params: {serial_number: this.serial_number},
      });
    },
    ClickVolt() {
      this.$router.push({
        name: "noneGatewayVolt", params: {serial_number: this.serial_number},
      });
    },
    ClickAmp() {

      this.$router.push({
        name: "noneGatewayCurrent", params: {serial_number: this.serial_number},
      });
    },
    TimerNone() {
      this.$router.push({
        name: "nOutletTimerShow",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    ClickEditName() {
      $("#modal-edit-home-none").modal('show');
      // console.log("ffiifjij")
      //  $("#modal-edit-name").modal("show");
    },
    ClickMomentray() {
      $("#modal-outlet-momentray-none").modal('show');
    },
    ClickCLock() {
      this.$router.push({
        name: "noneSetClock", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickAbout() {
      this.$router.push({name: "aboutnoneGatewayInfo", params: {serial_number: this.serial_number},});

    },

    ClickResetFactory() {
      $("#modal-reset-factory-none").modal('show');
    },
    goBackSetting() {
      this.$router.push({name: "GatewayNoneInfo", params: {serial_number: this.serial_number},});
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "GatewayNoneInfo", params: {serial_number: this.serial_number},});
      }
    },

  },
};
</script>


<style scoped>
</style>
