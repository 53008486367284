<template>
  <v-app class="bg">
    <v-container>
      <v-card>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <v-icon class="mdi mdi-24px text-gray">mdi-water-pump</v-icon>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_pomp') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_0"
                elevation="0"
                @click="FunctionBtnPump"
            >
              <v-icon class="mdi mdi-24px text-white" id="imgdry">mdi-water</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <img src="/assets/images/smart/icon-fan-slow-black.svg"
                 style="width: 22px; height: 22px;"/>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_low') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_1"
                elevation="0"
                @click="FunctionBtnFan3()"
            >
              <img
                  id="imgfanlow"
                  src="/assets/images/smart/icon-fan-slow-white.svg"
                  style="width: 24px; height: 24px"
              />
            </v-btn>
          </div>
        </div>
        <div class="box-footer with-border p-0">
          <div class="media media-single px-5 py-20">
            <v-icon class="mdi mdi-24px text-gray">mdi-fan</v-icon>
            <div class="media-body">
              <h6>{{ $t('message.subDevice.cooler_fast') }}</h6>
            </div>
            <v-btn
                fab
                depressed
                class="mb-1"
                :color="style_2"
                elevation="0"
                @click="FunctionBtnFan4()"
            >
              <img
                  id="imgfanfast"
                  src="/assets/images/smart/icon-fan-fast-white.svg"
                  style="width: 24px; height: 24px"
              />
            </v-btn>
          </div>
        </div>
        <!-- /.col -->
      </v-card>
      <!-- /.row -->
      <v-card>
        <v-row class="rounded-16 mt-5">
          <v-col class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 px-5 rounded">
              <v-icon class="mdi mdi-24px text-gray">mdi-power</v-icon>
              <div class="media-body">
                <h6>{{ $t('message.public.off') }}</h6>
              </div>
              <v-btn
                  fab
                  depressed
                  class="mb-1"
                  :color="style_3"
                  elevation="0"
                  @click="FunctionBtnOff()"
              >
                <v-icon class="mdi text-white mdi-24px">mdi-power</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <SubModalDel v-bind:data_info_sub="data_info_cooler"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_cooler"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_cooler"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {getTopics} from "../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {BetweenStr, ErrorToastMsg, SucessToastMsg} from "../../../../public/assets/js/utils/util";
import SubModalSetPass from "../SubModalSetPass";
import SubModalDel from "../SubModalDel";
import SubModalEdit from "../SubModalEdit";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var cooler_status;
var showToast;
var timeout;
export default {
  name: "SubKeyCoolerFara",
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {},
  },
  data() {
    return {
      title: "",
      set_password: "",
      pass: true,
      status: [],
      style_0: "",
      style_1: "",
      style_2: "",
      style_3: "red",
      sub_serial_number: "",
      data_info_cooler: [this.home_id, this.serial_number, this.count],
      passcooler: "",
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.sub_serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  created() {
    this.title = this.$store.getters.getNameSub;
    this.status = [false, false, false, false];
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    try {
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.title = this.$store.getters.getNameSub;
      let details = {
        title: this.title,
        pin: this.pass,
        passcode: this.set_password,
        menu: true,
        refresh: true,
        about: true,
        setting: true,
        timer: true,
        editName: true,
        del: true
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'SubCooler');
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.main_admin = this.$store.getters.getadmin;
      this.sub_serial_number = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.onSubscribe();
      setTimeout(function () {
        self.lastStatus();
      }, 50);
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassCooler() {
      if (!passHomeValidationInput(this.passcooler)) return;
      this.sendRequest(cooler_status, this.passcooler);
      this.passcooler = "";
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);

      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            self.exitModalLoading();
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              console.log("message", message);
              self.showGetMsg(message);
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    sendMessage(status) {
      if (this.set_password == 1) {
        cooler_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(status, '');
      }
    },
    sendReq(password) {
      this.sendRequest(cooler_status, password);
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "about") {
        this.$router.push({
          name: "SubCoolerFaraAbout",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value === "timer") {
        this.$router.push({
          name: "CoolerFaraTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStatus()
      }
    },
    showGetMsg(msg) {
      console.log("showGetMsg", msg);
      let styles = ["#aaaaaa", "#aaaaaa", "#aaaaaa"];
      this.status = [false, false, false];
      for (var i = 0; i < msg.length - 1; i++) {
        if (msg[i] === "0") {
          this.status[i] = false;
          styles[i] = "#aaaaaa";
        } else {
          this.status[i] = true;
          styles[i] = "primary";
        }
      }
      this.style_0 = styles[0];
      this.style_1 = styles[1];
      this.style_2 = styles[2];
      console.log('msg mqtt', styles);
    },

    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.sub_serial_number
      );
      this.$mqtt.subscribe(topic1);
    },
    sendRequest(saction, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.sub_serial_number
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              $("#modal-pass-cooler").modal("hide");
              self.status[0] = true;
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },

    FunctionBtnPump() {
      if (this.status[0] === false) {
        this.sendMessage("K1T#");
      } else {
        this.sendMessage("K1F#");
      }
    },
    FunctionBtnFan3() {
      if (this.status[1] === false) {
        this.sendMessage("K2T#");
      } else {
        this.sendMessage("K2F#");
      }
    },
    FunctionBtnFan4() {
      if (this.status[2] === false) {
        this.sendMessage("K3T#");
      } else {
        this.sendMessage("K3F#");
      }
    },
    FunctionBtnOff() {
      this.sendMessage("K5F#");
    },
  }
}
</script>

<style scoped>

</style>