<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <!-- row -->
        <div class="row">
          <div class="col-12">
            <div class="box">
              <div class="row no-gutters justify-content-md-center">
                <div class="col-lg-3 col-md-5 col-12">
                  <div style="text-align: center" class="mt-5">
                    <img :src="'/assets/images/smart/remote.jpg'"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <ul class="todo-list px-5 mt-3">
                  <li class="p-5">
                    <div
                        class="box p-5 mb-0 d-block d-flex align-items-center"
                    >
                      <button
                          type="button"
                          class="
                          btn btn-rounded btn-primary btn-lg btn-block
                          mb-3
                        "
                          @click="RemoteA()"
                      >
                        A
                      </button>
                    </div>
                  </li>
                  <li class="p-5">
                    <div
                        class="box p-5 mb-0 d-block d-flex align-items-center"
                    >
                      <button
                          type="button"
                          class="
                          btn btn-rounded btn-primary btn-lg btn-block
                          mb-3
                        "
                          @click="RemoteB()"
                      >
                        B
                      </button>
                    </div>
                  </li>
                  <li class="p-5">
                    <div
                        class="box p-5 mb-0 d-block d-flex align-items-center"
                    >
                      <button
                          type="button"
                          class="
                          btn btn-rounded btn-primary btn-lg btn-block
                          mb-3
                        "
                          @click="RemoteC()"
                      >
                        C
                      </button>
                    </div>
                  </li>
                  <li class="p-5">
                    <div
                        class="box p-5 mb-0 d-block d-flex align-items-center"
                    >
                      <button
                          type="button"
                          class="
                          btn btn-rounded btn-primary btn-lg btn-block
                          mb-3
                        "
                          @click="RemoteD()"
                      >
                        D
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <SubModalDel v-bind:data_info_sub="data_info_remote"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_remote"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_remote"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {
  SucessToastMsg,
  ErrorToastMsg,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var status_admin = localStorage.getItem("status_admin");
var tapp = localStorage.getItem("t_app");

var status;
var remote_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {}
  },
  data() {
    return {
      passremote: "",
      data_info_remote: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      set_password: "",
      pass: true,
      title: "",
      data: [],
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.exitModal();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    try {
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      var tapp = localStorage.getItem("t_app");

      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.count
      );
      this.$mqtt.subscribe(topic1);
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true,
      timer: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubRemote');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "timer") {
        this.$router.push({
          name: "RemoteTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    sendReq(password) {
      this.sendMessage(remote_status, password);
    },
    RemoteA() {
      if (this.set_password == 1) {
        remote_status = "K1T#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("K1T#", "");
      }
    },
    //--------------------------------------Stop Function --------------------------------------//
    RemoteB() {
      if (this.set_password == 1) {
        remote_status = "K1F#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("K1F#", "");
      }
    },
    //--------------------------------------Bottom Function--------------------------------------//
    RemoteC() {
      if (this.set_password == 1) {
        remote_status = "K2T#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("K2T#", '');
      }
    },
    //--------------------------------------Bottom Function--------------------------------------//
    RemoteD() {
      if (this.set_password == 1) {
        remote_status = "K2F#";
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage("K2F#", '');
      }
    },
    sendMessage(saction, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              $("#modal-pass-remote").modal("hide");
              // connection=false;
              self.openModalLoading();
              timeout = setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    },
    sendPassRemote() {
      if (!passHomeValidationInput(this.passremote)) return;
      this.sendMessage(remote_status, this.passremote);
      this.passremote = "";
    },
  },
};
</script>
<style scoped>
</style>
