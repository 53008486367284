<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    Timer
  },
  data: () => ({
    title: '',
    data: {},
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.title = this.$t('message.timer.scheduler');
    this.data = {
      items: [
        {
          name: this.$t('message.timer.outlet'),
          status: false
        }
      ],
      type: "NOutlet"
    }
  },
  mounted() {
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(name, val, status);
      this.next1(status);
    },
    next1() {
      let self = this;
      let status_checkboxL1;
      if (status) {
        status_checkboxL1 = "P1T";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkboxL1));
        self.$root.$emit('did-something', status_checkboxL1);
      } else if (!status) {
        status_checkboxL1 = "P1F";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkboxL1));
        self.$root.$emit('did-something', status_checkboxL1);
      }

      this.$router.push({
        name: "nOutletStep2Timerinfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    onClickChild(value) {

      if (value == 'back') {
        this.$router.push({
          name: "nOutletTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,

          },
        });
      }
    },
  },
};
</script>


<style scoped>
</style>
