<template>
  <div>
    <v-card class="pt-3 bg" style="border-radius: 0" v-if="data.controls.length !== 0">
      <v-card-title class="py-0">
        <h6 class="text-bold" style="color: #455a64">
          {{ $t("message.Rooms.controls") }}
        </h6>
      </v-card-title>
    </v-card>
    <section class="overflow-y-auto px-5 bg" :style="{ 'max-height': maxHeight }">
      <v-row class="mt-1 mb-1">
        <v-col cols="4" lg="1" class="p-2" v-bind:key="index" v-for="(control, index) in data.controls">
          <v-card @click="gotoControl(index, control)">
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" class="py-1 px-0" align="center">
                  <v-img :src="control.type_logo" alt="Sunny image" width="35" class="mt-2"></v-img>
                  <v-card-title class="justify-center text-dark px-0 pb-2 pt-3">
                    <label v-if="control.name.length > 12" style="font-size: 12px" class="text-bold">{{
                        control.name.slice(0, 12) + "..."
                    }}</label>
                    <label v-else style="font-size: 12px; color: #082b5d" class="text-bold">{{ control.name }}</label>
                  </v-card-title>
                  <v-card-subtitle class="text-center px-1 py-1">
                    <label style="font-size: 10px" class="text-regular">
                      {{ control.type }}
                    </label>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <v-card class="pt-3 bg" style="border-radius: 0" v-if="data.subDevices.length !== 0">
      <v-card-title class="py-0">
        <h6 class="text-bold" style="color: #455a64">
          {{ $t("message.Rooms.sub_devices") }}
        </h6>
      </v-card-title>
    </v-card>
    <section class="overflow-y-auto px-5 bg" :style="{ 'max-height': maxHeight }">
      <v-row class="mt-1 mb-1">
        <v-col cols="4" lg="1" class="p-2" v-bind:key="index" v-for="(subDevice, index) in data.subDevices">
          <v-card @click="gotoSubDevice(index, subDevice)">
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" class="py-1 px-0" align="center">
                  <v-img :src="subDevice.type.logo_path" alt="Sunny image" width="35" class="mt-2"></v-img>
                  <v-card-title class="justify-center text-dark px-0 pb-2 pt-3">
                    <label v-if="subDevice.name.length > 12" style="font-size: 12px" class="text-bold">{{
                        subDevice.name.slice(0, 12) + "..."
                    }}</label>
                    <label v-else style="font-size: 12px; color: #082b5d" class="text-bold">{{ subDevice.name }}</label>
                  </v-card-title>
                  <v-card-subtitle class="text-center px-1 py-1">
                    <label style="font-size: 10px" class="text-regular">
                      {{ lang === "en" ? subDevice.type.label.en : subDevice.type.label.fa }}
                    </label>
                  </v-card-subtitle>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <v-card class="pt-3 bg" style="border-radius: 0" v-if="data.relays.length !== 0">
      <v-card-title class="py-0">
        <h6 @click="clickSettingRelay" class="text-bold" style="color: #455a64">
          {{ $t("message.relay.relays") }}
        </h6>
        <v-spacer></v-spacer>
        <div class="py-2" @click="clickSettingRelay()">
          <v-icon small v-if="lang === 'en' || lang === 'tur'"> ti-angle-right</v-icon>
          <v-icon small v-else>ti-angle-left</v-icon>
        </div>
      </v-card-title>
    </v-card>
    <section class="overflow-y-auto px-5 bg">
      <v-row class="mt-1 mb-5">
        <v-col :cols="item.relay_type_number_relay === 2 ? 12 : 6" class="p-2" v-bind:key="index"
          v-for="(item, index) in data.relays">
          <WidgetRelay :infoItem="item" @clicked="clickSwitch">
            <v-btn v-if="item.relay_delay >= 1" class="mx-2" fab color="primary" elevation="0" @click="instantSetting(
              item.relay_delay,
              item.sub_device.serial_number,
              item.relay_numbers,
              item.keys_status,
              item.sub_device.set_password
            )
            ">
              <v-icon class="text-white">mdi-power</v-icon>
            </v-btn>
            <div v-if="item.relay_delay == 0">
              <v-switch v-if="item.relay_type_number_relay == 1" hide-details="true" v-model="item.status" inset @click="
                clickSwitch(
                  item.status,
                  item.relay_numbers,
                  item.sub_device.serial_number,
                  item.keys_status,
                  item.sub_device.set_password
                )
              ">
              </v-switch>
              <div v-if="item.relay_type_number_relay == 2">
                <v-btn class="mx-2" fab outlined color="primary" elevation="0" @click="click2modes(false, item.relay_numbers, item.sub_device.serial_number, item.keys_status,
                item.sub_device.set_password)">
               

                  <v-icon class="text-gray" v-if="item.status == 2">mdi-arrow-up-bold
                  </v-icon>
                  <v-icon class="text-primary" v-else-if="item.status==1">mdi-arrow-up-bold
                  </v-icon>
                  <v-icon class="text-gray" v-else>mdi-arrow-up-bold
                  </v-icon>
                </v-btn>

                <v-btn class="mx-2" fab outlined color="primary" elevation="0" @click="
                  click2modes(
                    true,
                    item.relay_numbers,
                    item.sub_device.serial_number,
                    item.keys_status
                  )
                ">
                  <v-icon class="text-gray" v-if="item.status == 2">mdi-arrow-down-bold
                  </v-icon>
                  <v-icon class="text-primary" v-else-if="item.status==0">mdi-arrow-down-bold
                  </v-icon>
                  <v-icon class="text-gray" v-else>mdi-arrow-down-bold
                  </v-icon>
                </v-btn>
                <v-btn class="mx-2" fab color="primary" elevation="0" @click="
                  click2modes(
                    'FF',
                    item.relay_numbers,
                    item.sub_device.serial_number,
                    item.keys_status
                  )
                ">
                  <v-icon class="text-white">mdi-power</v-icon>
                </v-btn>
              </div>
            </div>
          </WidgetRelay>
        </v-col>
      </v-row>
    </section>
    <!-- <CmpPasscode @sendPassCode="sendReqRelay"></CmpPasscode> -->
  </div>
</template>

<script>
import LayoutDefault from "../../layouts/LayoutDefault";
import { postAjaxCall } from "../../../public/assets/js/postAjax";
import { responseAjax } from "../../../public/assets/js/utils/response_ajax_handler";
import WidgetRelay from "../Cmp/Widgets/WidgetRelay";
import { getMessageSub } from "../../../public/assets/js/utils/helper_sub_message";
import { getTopics } from "../../../public/assets/js/utils/topics";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../Cmp/general/CmpPasscode";

export default {
  name: "showRoom",
  components: { CmpPasscode, WidgetRelay },
  props: {
    data: {},
    serial_number: {},
    home_id: {},
  },
  created() {
    // this.data = localStorage.getItem('RoomIndex') ? JSON.parse(localStorage.getItem('RoomIndex'))
    //     : {}
    // console.log(this.data)
    this.lang = localStorage.getItem("Lang");
  },
  mounted() {
    console.log("data",this.data)
  },
  data() {
    return {
      lang: '',
      message: '',
      topic: '',
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  computed: {
    maxHeight() {
      if (this.data.relays.length === 0)
        return 'fit-content'
      else
        return '285px'
    },
  },
  methods: {
    sendReqRelay(password) {
      console.log('password',password)
      // this.$emit("sendRequestRelay", this.message, this.topic, password)
    },
    onClickChild(val) {
      if (val === "back") {
        this.$router.replace({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if (val === "refresh") {
        this.lastStatus()
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    lastStatus() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
        room_uid: this.uid
      };
      let URL = getUrl.rooms;

      const p = postAjaxCall(URL, data);
      self.openModalLoading()
      p.then(function (response) {
        self.closeModalLoading()
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.index = jsonArray.content
          self.index.rooms.forEach((item, index) => {
            self.rooms.push({
              index: index,
              img: item.logo_path,
              title: item.name,
              actions: 'menu',
              uid: item.uid,
              devices: JSON.parse(item.info)
            })
          })
          if (self.index.rooms.length === 0) {
            console.log("emptyShow")
            self.emptyShow = true
          }
          console.log(self.index)
        }
      })
    },
    gotoControl(index, control) {
      console.log(index, control)
      this.sendData(control.type_code, control.name, control.count, control.uid, control.learned, control.key_info)
    },
    gotoSubDevice(index, subdevice) {
      console.log(subdevice)
      this.sendItemSub(subdevice.count, subdevice.serial_number, subdevice.name, this.serial_number,
        subdevice.type.farahoush_device, subdevice.type.type_code, subdevice.pol, subdevice.set_password, '',
        subdevice.model, subdevice)
    },
    clickSwitch(status, numbers, subdevice_serial, keys_status, set_password) {
      console.log(set_password)
      this.message =
        getMessageSub.getMessageActionRelay(status, numbers) +
        keys_status.join('') +
        "//";
      this.topic = getTopics.getTopicActionSend(
        this.serial_number,
        subdevice_serial
      );
      if (set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.$emit("sendRequestRelay", this.message, this.topic, "")
      }
    },
    click2modes(status, numbers, subdevice_serial, keys_status, set_password) {
      console.log('keys_status', keys_status)
      this.message =
        getMessageSub.getMessageActionRelay(status, numbers) +
        keys_status.join("") +
        "//";
      this.topic = getTopics.getTopicActionSend(
        this.serial_number,
        subdevice_serial
      );
      if (set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.$emit("sendRequestRelay", this.message, this.topic, "")
      }
    },
    instantSetting(delay, sub_serial, numbers, keys_status, set_password) {
      this.message = getMessageSub.getMessageInstantRelay(
        numbers,
        delay,
        keys_status
      );
      this.topic = getTopics.getTopicDelaySend(
        this.serial_number,
        sub_serial
      );
      if (set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.$emit("sendRequestRelay", this.message, this.topic, "")
      }
    },
    sendItemSub(count,
      serial,
      name,
      gateway,
      farahoush_device,
      type_code,
      pol,
      set_password,
      password,
      model,
      info) {
        console.log("infoooooooooooooo",info)
      localStorage.setItem("infoSubDevice", JSON.stringify(info));
      console.log("infoSubDevice Room",JSON.parse(localStorage.getItem("infoSubDevice")));
      var serial_number = this.serial_number;
      console.log("type_code Room",type_code)
      switch (type_code) {
        
        case 1:
          this.$router.push({
            name: "SubKeyTouchFaraInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "SublampInfo",
            params: {
              home_id: this.home_id,
              serial_number: serial_number,
              count: count,
            },
          });
          break;
        case 3:
          this.$router.push({
            name: "remoteInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 4:
          this.$router.push({
            name: "SublockInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 5:
          this.$router.push({
            name: "SubdoorInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 6:
          this.$router.push({
            name: "curtainInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        // case 7:
        //   window.location.href = "relaykeys";
        //   break;
        case 8:
          this.$router.push({
            name: "SubKeyTouchInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 10:
          this.$router.push({
            name: "thermostatInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 11:
          // window.location.href = "subrelaykeys";
          this.$router.push({
            name: "SubrelayInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 13:
          // window.location.href = "subrelaykeys";
          this.$router.push({
            name: "SubcoolerInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 17:
          // window.location.href = "subrelaykeys";
          this.$router.push({
            name: "SubCoolerFaraInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 18:
          this.$router.push({
            name: "curtainFaraInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 20:
          this.$router.push({
            name: "subElevatorInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
        case 21:
          this.$router.push({
            name: "SubServiceInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 22:
          this.$router.push({
            name: "SubScenarioKeyInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 23:
          this.$router.push({
            name: "SubDoorShutter",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 24:
          this.$router.push({
            name: "SubAlarmFaraInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 30:
          this.$router.push({
            name: "SubKeyTouchProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 31:
          this.$router.push({
            name: "SubCoolerProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 32:
          this.$router.push({
            name: "SubCurtainProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 33:
          this.$router.push({
            name: "SubThermostatProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 34:
          this.$router.push({
            name: "SubElevatorProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 35:
          this.$router.push({
            name: "SubDoorShutterProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 36:
          this.$router.push({
            name: "SubServiceProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
          case 38:
          this.$router.push({
            name: "SubThermostaticProInfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          })
          break;
        default:
      }
    },
    sendData(type_code, name, count, uid, learned, key_count) {
      const dataircontrols = {
        name: name,
        uid: uid,
        type_code: type_code,
      };

      localStorage.setItem("infoIrControls", JSON.stringify(dataircontrols));
      switch (type_code) {
        case 1001:
          this.$router.push({
            name: "irtvinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 1002:
          this.$router.push({
            name: "iramplifierinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        case 1003:
          this.$router.push({
            name: "irairinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              count: count,
            },
          });
          break;
        default:
        // code block
      }
    },
    clickSettingRelay() {
      this.$router.push({
        name: "subboxrelay",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
  }
}
</script>

<style scoped>

</style>