<template>
  <!-- .wrapper  -->
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-exit-account"
      tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <span aria-hidden="true" style="font-size: 36px">&times;</span>
          </button>
        </div>
        <section class="error-page bg-img h-p100 rtl">
          <div class="container h-p100">
            <div
                class="row h-p100 align-items-center justify-content-center text-center"
            >
              <div class="col-lg-7 col-md-10 col-12">
                <h1
                    class="text-warning font-size-180 font-weight-bold error-page-title"
                ></h1>
                <img
                    src="/assets/images/smart/exit-account.svg"
                    style="width: 300px; height: 300px"
                />
                <br/>
                <h6 class="mt-20">
                  {{ $t('message.ModalExitAccount.exit_title') }}
                </h6>
                <div class="flex justify-space-around">
                  <v-btn
                      class="btn mt-5 mr-1 ml-1"
                      :loading="loading"
                      :disabled="loading"
                      color="bg-danger"
                      @click="exits()"
                  >
                  <span class="text-white">
                    {{ $t('message.ModalExitAccount.exit_ok') }} </span>
                  </v-btn>
                  <v-btn
                      class="btn mt-5 mr-1 ml-1"
                      color="bg-primary"
                      v-on:click.prevent="cancelAccount()"
                  >
                  <span class="text-white">
                 {{ $t('message.public.cancel') }}</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
</template>
<script>
export default {

  data() {
    return {
      loading: false,
    };
  },

  mounted() {

  },
  methods: {
    exits() {
      let self = this;
      this.loading = true;
      localStorage.clear()
      localStorage.setItem('Lang', this.$i18n.locale)
      window.location.href = 'welcome';
      setTimeout(function () {
        self.loading = false
      }, 3000);

    },
    cancelAccount() {
      $("#modal-exit-account").modal('hide');
    },
  },
};
</script>
<style scoped>
</style>
