<template>
  <v-app class="bg">
    <div class="mr-0 mt-5">
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center setting"
          >
            <i class="mdi mdi-power-socket mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</p>
              <p class="mt-2 text-primary">{{ serialnumber }}</p>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
    </div>
  </v-app>
</template>

<script>

import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      homeid: this.home_id,
      serialnumber: this.serial_number,
      title: ''
    };
  },
  created() {
    let data = {
      title: this.$t('message.AppBar.about_device'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutIr');
  },
  mounted() {
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irGatewayInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    },

  },
};
</script>


<style scoped>
</style>
