<template>
  <v-app class="bg">
    <div class="mt-5 mr-0 bg-white">
      <div class="box">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row" id="all-page" ref="allpage">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="">
                <div class="p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div style="text-align: center; margin-top: 0">
                      <div class="fontawesome-icon-list mt-20">
                        <img
                            src="/assets/images/smart/vector-volt.svg"
                            class="avatar-smart"
                        />
                      </div>
                      <!-- <h6 class="mt-5 p-10"></h6> -->
                    </div>
                  </div>

                  <div class="form-check px-40">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkbox1"
                    />
                    <label class="form-check-label" for="checkbox1"
                    ><span id="txtactive" ref="txtactive">
                          {{ $t('message.noneHome.active') + " " + $t('message.RelayKey.V') }}
                        </span></label
                    >
                  </div>
                </div>
                <div class="box-body px-40">
                  <div class="text-center py-10" id="Main_div" ref="maindiv">
                    <h6 style="line-height: 25px !important">
                      {{ $t('message.noneHome.V_text') }}
                    </h6>
                    <ul
                        class="flexbox flex-justified text-center my-20 mt-50"
                    >
                      <li>
                        <p class="mb-0 text-bold">{{ $t('message.noneHome.delay') }}</p>
                        <div class="form-group mt-10">
                          <input
                              id="inputtime"
                              type="number"
                              value="60"
                              min="00"
                              max="800"
                              style="direction:ltr"
                              class="form-control text-center"
                              required=""
                              aria-invalid="false"
                              autocomplete="off"
                              onKeyPress="if(this.value.length==3) return false;"
                          />
                          <small>{{ $t('message.public.seconds') }}</small>
                        </div>
                      </li>
                      <li class="br-1">
                        <p class="mb-0 text-bold">{{ $t('message.public.from') }}</p>
                        <div class="form-group mt-10">
                          <input
                              id="inputvolt2"
                              type="number"
                              value="160"
                              min="160"
                              max="249"
                              class="form-control text-center"
                              required=""
                              style="direction:ltr"
                              aria-invalid="false"
                              autocomplete="off"
                              onKeyPress="if(this.value.length==3) return false;"
                          />
                        </div>
                      </li>

                      <li>
                        <p class="mb-0 text-bold">{{ $t('message.public.to') }}</p>
                        <div class="form-group mt-10">
                          <input
                              id="inputvolt3"
                              type="number"
                              class="form-control text-center"
                              required=""
                              aria-invalid="false"
                              value="250"
                              min="160"
                              max="60"
                              style="direction:ltr"
                              autocomplete="250"
                              onKeyPress="if(this.value.length==3) return false;"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <br/>
                  <button
                      type="button"
                      class="btn btn-primary btn-lg btn-block mb-10"
                      v-on:click.prevent="sendMessage()"
                  >
                    {{ $t('message.public.submit') }}
                  </button>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>

          <!-- /.row -->
        </section>
        <!-- /. Main content -->
      </div>
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- <ModalCenter></ModalCenter> -->
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import {SucessToastMsg, ErrorToastMsg, Internets, BetweenStr} from "../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {volCompareValidationInput, voltValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

var connection = false;
var showToast;

localStorage.setItem("count", "256");
export default {
  components: {},
  props: {
    serial_number: {},
    click: {}
  },
  data() {
    return {
      title: '',
      isModalVisible: false
    };
  },
  mqtt: {
    "rnd/+/r/+/volt"(data, topic) {

      var serail = BetweenStr.getBetweenStr(topic, "rnd/", "/r");
      var count = BetweenStr.getBetweenStr(topic, "r/", "/volt");
      if (serail[1] == this.serial_number && count[1] == "256") {
        if (topic.split("/")[4] === "volt") {

          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
    "+/+/receive/+/volt"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3]== "256") {
      if (topic.split("/")[4] === "volt") {

          connection = true;
          this.exitModalLoading();

          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  created() {
    let data = {
      title: this.$t('message.noneHome.setV'),
      noneHome: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingNoneVolt');
  },
  mounted: function () {
    var self = this;
    this.openModalLoading();
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    // var tapp = localStorage.getItem("t_app");
    //---------/get Last Status from Device
    var topic = getTopics.getTopicVoltReceive(this.serial_number, '256');
    this.$mqtt.subscribe(topic);
    let allpage = this.$refs.allpage;
    allpage.style.display = "none";
    setTimeout(function () {
      self.getInfoLastStatus();
    }, 300);
    var checkbox = document.getElementById("checkbox1");
    checkbox.addEventListener("change", function () {
      self.setStyleStatus(checkbox.checked);
    });
    //---------./Show Last Status from Device
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    setStyleStatus: function (status) {
      let main_div = this.$refs.maindiv;
      if (status) {
        main_div.style.display = "block";
      } else {
        main_div.style.display = "none";
      }
    },
    sendMessage() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
// var tapp = localStorage.getItem("t_app");
      let self = this;
      let input1 = document.getElementById("inputtime").value;
      let input2 = document.getElementById("inputvolt2").value;
      let input3 = document.getElementById("inputvolt3").value;

      var formattedNumber1;

      if (input1.length == 2) {
        formattedNumber1 = ("0" + input1).slice(-2);
      }
      if (input1.length == 3) {
        formattedNumber1 = ("0" + input1).slice(-3);
      }
      var formattedNumber2 = ("0" + input2).slice(-3);
      var formattedNumber3 = ("0" + input3).slice(-3);
      //--------------------------------------Message-------------------------------------//
      var status_checkbox;
      var checkbox = document.getElementById("checkbox1");
      if (checkbox.checked) {
        //--------------------------------------Validation-------------------------------------//
        if (!voltValidationInput(input1, input2, input3) || !volCompareValidationInput(formattedNumber2, formattedNumber3)) return;
        status_checkbox =
            "VOLT" + formattedNumber2 + "-" + formattedNumber3 + "TIME" + formattedNumber1 + "#";
      } else {
        status_checkbox = "DEACTIVEV#";
      }
      //--------------------------------------function for Post Method --------------------------------------//


      var topic_final = getTopics.getTopicVoltSend(this.serial_number, '256');

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: status_checkbox,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;

      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {

              self.openModalLoading();
              setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
            }
          },
          function (errr) {
            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == '4001') {
                self.$router.push({name: "home"});
              }
            }
          }
      );
    },
    getInfoLastStatus() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var tapp = localStorage.getItem("t_app");
      var self = this;
      var main_div = this.$refs.maindiv;
      var allpage = this.$refs.allpage;
      let device_serial = this.serial_number;
      let txt_active = this.$refs.txtactive;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.nonevolt;

      const p = postAjaxCall(URL, data);
      main_div.style.display = "none";
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.exitModalLoading();
              allpage.style.display = "block";
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.volt;
              var checkbox = document.getElementById("checkbox1");
              if (message == "DEACTIVEVOLT") {

                main_div.style.display = "none";
                // txt_active.innerHTML = "غیر فعال سازی ولتاژ";
                checkbox.checked = false;
              } else {
                checkbox.checked = true;
                main_div.style.display = "block";
                // txt_active.innerHTML = "فعال سازی ولتاژ";
                var value1 = message.substr(message.indexOf("VOLT") + 4, 3);
                var value2 = message.substr(message.indexOf("VOLT") + 8, 3);
                var value3 = message.substr(message.indexOf("TIME") + 4, 3);
                document.getElementById("inputtime").value = value3;
                document.getElementById("inputvolt2").value = value1;
                document.getElementById("inputvolt3").value = value2;
              }
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    goBackVolt() {
      this.$router.push({name: "SettingsNone", params: {serial_number: this.serial_number},});
    },
    onClickChild(value) {

      if (value == 'back') {
        this.$router.push({name: "SettingsNone", params: {serial_number: this.serial_number},});
      }
    },

  },
};
</script>


<style scoped>
</style>
