<template>
  <v-app class="bg">
    <TimerList :isShow="isShow" :length="length" :infos="info" @addClock="addClock()" @addTimer="addTimer()"
               @deleteTimer="deleteTimer"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import TimerList from "@/components/Cmp/timer/show/TimerList.vue";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import {SetClock} from "../../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {Tools} from "../../../../../public/assets/js/utils/util.js";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var count_timer;
var connection = false;


export default {
  components: {
    TimerList,
    CmpPasscode
  },
  data: () => ({
    info: "",
    length: "",
    lang: '',
    set_password: '',
    counter: '',
    isShow: false,
    title: '',
    isModalVisible: false
  }),
  created() {
    this.lang = localStorage.getItem("Lang");
    this.set_password = JSON.parse(localStorage.getItem("infoSubDevice")).set_password;
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  mqtt: {

    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
  },
  mounted() {
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
    let self = this;
    this.title = this.$t('message.timer.scheduler_show')
    this.$store.dispatch("infoHome");
    this.$store.dispatch("InfoSubDevice");
    setTimeout(function () {
      self.lastStatus();
    }, 100);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    lastStatus() {
      let self = this;

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let sucdevice_sn = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.info = '';

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: sucdevice_sn,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.timers;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              self.length = content.length;
              var len = content.length;
              if (len == 0) {
                self.isShow = true;
              }

              content.forEach(function (item) {
                const data = getTimerData(item.message);

                finaldata.push(data);
                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: self.$t('message.timer.timer'),
            text: self.$t('message.timer.timer_isDelete'),
            type: "error",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: self.$t('message.public.delete'),
            cancelButtonText: self.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true
          },
          function (isConfirm) {
            if (isConfirm) {
              if (self.set_password == 1) {
                self.counter = counttimer;
                $("#modal-pass-code").modal("show");
              } else
                self.sendDelete(counttimer, '');
            }
          }
      );
    },
    sendReq(pass) {
      this.sendDelete(this.counter, pass);
    },
    sendDelete(counttimer, pass) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      count_timer = counttimer;
      let message = "TIMER" + count_timer + "-OFF#";

      var topic_final = getTopics.getTopicTimerSend(this.serial_number, this.count);

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        password: pass,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  self.exitModalLoading()
                  swal("", self.$t('message.public.operation_failure'), "error");
                }
              }, 5000);
            } else {
              self.exitModalLoading()
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
            self.exitModalLoading()
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();

      let device_serial = this.serial_number;
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      });

      self.lastStatus()
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");

      var topic_final = getTopics.getTopicClockSend(this.serial_number, '256');


      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {

              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000);
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "remoteInfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "refresh") {
        this.lastStatus();
      }
      if (value == "add") {
        this.$router.push({
          name: "Remotetimer",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    addTimer() {
      this.$router.push({
        name: "Remotetimer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
  },
};
</script>


<style scoped>
</style>
