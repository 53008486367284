<template>
 <v-container class="grey lighten-5 py-5 px-12" v-if="cmpShow">
   <v-card 
    class="mx-auto px-20 py-20 ">
    <v-row no-gutters @click="clickAirKey(info.key_count)">
      <v-col
        cols="4"
        class="d-flex align-items-center">
          <h1 v-if="info.power=='OFF'"> {{info.power}}</h1>
          <h1 v-else> {{info.temp}}&deg;C</h1>
           <v-divider vertical class="ml-20"></v-divider>
      </v-col>
      <v-col
        cols="8"
        class="d-flex align-items-center">
      <v-row>
        <v-col> 
           <v-icon v-if="info.mode=='----'">mdi-water</v-icon>
           <v-icon  class="text-gray" v-if="info.mode=='DRY'">mdi-water</v-icon>
           <v-icon  class="text-cyan" v-else-if="info.mode=='COOL'">mdi-snowflake</v-icon>
           <v-icon  class="text-danger" v-else-if="info.mode=='HEAT'">mdi-white-balance-sunny</v-icon>
           <v-icon  class="text-green" v-else-if="info.mode=='AUTO'">mdi-autorenew</v-icon>
         <br/>{{info.mode}}</v-col>
        <v-col>
          <v-img v-if="info.fan=='----'" src="/assets/images/smart/icon-fan-slow-on.svg" max-height="20" max-width="20" class="mt-5"></v-img>
          <v-img v-if="info.fan=='HIGH'" src="/assets/images/smart/icon-fan-fast-on.svg" max-height="20" max-width="20" class="mt-5"></v-img>
          <v-img v-else-if="info.fan=='LOW'" src="/assets/images/smart/icon-fan-slow-on.svg" max-height="20" max-width="20" class="mt-5"></v-img>
          <v-img v-else-if="info.fan=='MEDUIM'" src="/assets/images/smart/icon-fan-meduim-on.svg"  max-height="20" max-width="20" class="mt-5"></v-img>
         {{info.fan}}</v-col>
        <v-col><v-icon>mdi-swap-horizontal</v-icon><br/>{{info.swimH}}</v-col>
        <v-col><v-icon>mdi-swap-vertical</v-icon><br/>{{info.swimV}}</v-col>
      </v-row>
      </v-col>
    </v-row>
     <!-- <v-divider></v-divider>
    <v-card-actions>
     <v-row
          align="center"
          justify='end'
          class="py-5 px-10 d-flex justify-content-end"
        >
          <v-icon class="mr-2">
            mdi-heart
          </v-icon>
         
          <span class="mr-2">·</span>
          <v-icon class="mr-2">
            mdi-share-variant
          </v-icon>
         
        </v-row>
    </v-card-actions> -->
     </v-card> 
  </v-container>
</template>
<script>
  export default {
     props: ["info"],
    data () {
      return {
       todo:[],
       cmpShow:false,
      }
      
    },
    created(){
      console.log("this.info",this.info)
      this.cmpShow=true
   },
   methods:{
     clickAirKey(value){
       console.log("this.info11",this.info)
       console.log("value",value)
      this.$emit("clicked", this.info);
     }
     
   }
  }
</script>
<style scoped>
.v-sheet.v-card {
    border-radius: 12px;
    box-shadow: none!important;;
}
</style>