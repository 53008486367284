<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <div class="row mt-30 px-20" id="trall" v-if="polall">
          <div class="col-12 py-0">
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 py-0">
                <div
                    class="media align-items-center bg-white mb-20 py-20 rounded">
                  <img
                      src="/assets/images/smart/switch-off.svg"
                      id="imgall"
                      width="32px"
                      height="32px"
                  />
                  <div class="media-body">
                    <h6>{{ $t('message.public.all') }}</h6>
                  </div>
                  <v-switch
                            v-model="resultAll.check"
                            @click="MyFunctionPower(0, resultAll.check)"
                            inset
                            id="checkL1"
                        ></v-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row px-20">
          <div class="col-lg-12 col-12 py-0">
            <div class="box rounded-50">

              <div class="box-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-12">
                    <div id="trl1" v-if=pol1>
                      <div class="media media-single px-0 py-20">
                        <img
                            src="/assets/images/smart/switch-off.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                        />

                        <div class="media-body">
                          <h6>{{ labels[0].label }}</h6>
                        </div>
                        <v-switch
                            v-model="result1.check"
                            @click="MyFunctionPower(1, result1.check)"
                            inset
                            id="checkL1"
                        ></v-switch>
                      </div>
                    </div>
                    <div id="trl2" v-if="pol2">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl2"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[1].label }}</h6>
                          </div>
                          <v-switch
                              v-model="result2.check"
                              @click="MyFunctionPower(2, result2.check)"
                              inset
                              id="checkL2"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl3" v-if="pol3">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl3"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[2].label }}</h6>
                          </div>
                          <v-switch
                              v-model="result3.check"
                              @click="MyFunctionPower(3, result3.check)"
                              inset
                              id="checkL3"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl4" v-if="pol4">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl4"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body">
                            <h6>{{ labels[3].label }}</h6>
                          </div>
                          <v-switch
                              v-model="result4.check"
                              @click="MyFunctionPower(4, result4.check)"
                              inset
                              id="checkL4"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
            </div>
          </div>
        </div>
      </div>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
    <SubModalDel v-bind:data_info_sub="data_info_keytouch"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_keytouch"></SubModalEdit>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_keytouch"
    ></SubModalSetPass>
  </v-app>
</template>
<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "@/../public/assets/js/utils/util.js";
import {BetweenStr} from "@/../public/assets/js/utils/util.js";
import {ErrorToastMsg,SucessToastMsg} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {passHomeValidationInput} from "@/../public/assets/js/utils/error_handler";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";


var connection = false;
var keytouch_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      passkeytouchfara: "",
      data_info_keytouch: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      pass: true,
      set_password: '',
      pol: 1,
      labels: [],
      isModalVisible: false,
      polall:false,
      pol1:false,
      pol2:false,
      pol3:false,
      pol4:false,
      resultAll: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result1: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result2: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result3: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result4: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
    };
  },
  created() {
    let data = localStorage.getItem("infoSubDevice");
    data = JSON.parse(data);
    if (!JSON.parse(data.labels))
      this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
        pol: "3",
        label: "L4"
      }];
    else
      this.labels = JSON.parse(data.labels);

      console.log(" this.labels", this.labels)
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
   
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) { 
      {
        if (topic.split("/")[1] === this.serial_number && 
            topic.split("/")[3] ===  JSON.parse(localStorage.getItem("infoSubDevice")).serial_number &&
            topic.split("/")[4] === "action") {
              console.log("data if",data)
          connection = true;
          clearTimeout(timeout);
          this.showStatusLamp(String(data));
          SucessToastMsg.Msg();
          this.exitModalLoading();
          if (showToast == 1) {
          }
        }
      }
    },
  },
  mounted: function mounted() {
    console.log("mounted")
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.pol = this.$store.getters.getPolSub;

    this.main_admin = this.$store.getters.getadmin;
    this.title = this.$store.getters.getNameSub;
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      edit: true,
      del: true,
      refresh: true,
      timer: true,
      about: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubKeyTouchFara');

    // try {
      let self = this;
      Internets.Internet;
      setTimeout(function () {
        self.lastStausLamp();
      }, 100);
     
      //Show and Hide pol in views
     
      // subscribe
      let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      console.log("topic1",topic1)
      this.showHidePol();
    // } 
    // catch (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t("message.ListDevice.err_process"),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t("message.public.OK"),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
   
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    showHidePol(){
     if (this.pol === 1) {
        this.pol1=true
      }
      else if (this.pol === 2) {
        this.pol1=true
        this.pol2=true
        this.polall=true
      }
     else if (this.pol === 3) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.polall=true
      }
      else if (this.pol === 4) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.pol4=true
        this.polall=true
      }
    //   if (!this.info.label) {
    //   this.labels = [{pol: "1", label: "L1"}, {pol: "2", label: "L2"}, {pol: "3", label: "L3"}, {
    //     pol: "4",
    //     label: "L4"
    //   }];
    //   let data = {
    //       labels: this.labels,
    //       // pol: this.pol,
    //       // type: 19,
    //     };
    //     localStorage.setItem("data_edit", JSON.stringify(data));
    // } else {
    //   this.labels = JSON.parse(this.info.label);
    // }
   
    },
    MyFunctionPower(index, value) {
      if (this.pol === 1) {
        if (index === 1)
          if (value)
            this.keytouch_status = "K1T#";
          else
            this.keytouch_status = "K1F#";
      } else {
        switch (index) {
          case 0:
            if (value)
              this.keytouch_status = "K5T#";
            else
              this.keytouch_status = "K5F#";
            break;
          case 1:
            if (value)
              this.keytouch_status = "K1T#";
            else
              this.keytouch_status = "K1F#";
            break;
          case 2:
            if (value)
              this.keytouch_status = "K2T#";
            else
              this.keytouch_status = "K2F#";
            break;
          case 3:
            if (value)
              this.keytouch_status = "K3T#";
            else
              this.keytouch_status = "K3F#";
            break;
          case 4:
            if (value)
              this.keytouch_status = "K4T#";
            else
              this.keytouch_status = "K4F#";
            break;
        }
      }
      this.sendMessage(this.keytouch_status);
    },
    sendReq(password) {
      this.sendMessage(keytouch_status, password);
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "SubAboutKeyFara",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "edit") {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: "2RF",
        }
        localStorage.setItem("data_edit_sub", JSON.stringify(data));
        this.$router.push({
          name: "SubKeyTouchEdit",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStausLamp();
        // location.reload();
      }
      if (value == "timer") {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: "2RF",
        }
        localStorage.setItem("data_edit_sub", JSON.stringify(data));
        this.$router.push({
          name: "TouchFaraTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    sendMessage(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let self = this;

      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              //  connection=false;
              self.openModalLoading();
              $("#modal-pass-code").modal("hide");
              timeout = setTimeout(function () {
                self.exitModalLoading();

                if (!connection) {
                  setTimeout(function () {
                    self.lastStausLamp();
                  }, 1000);
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }

                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      var imgall = document.getElementById("imgall");
      var imgl1 = document.getElementById("imgl1");
      var imgl2 = document.getElementById("imgl2");
      var imgl3 = document.getElementById("imgl3");
      var imgl4 = document.getElementById("imgl4");
      if (msg == "K1F") {
        imgl1.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K1T") {
        imgl1.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K2F") {
        imgl2.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K2T") {
        imgl2.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K3F") {
        imgl3.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K3T") {
        imgl3.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K4F") {
        imgl4.src = "/assets/images/smart/lampoff.svg";
      }
      if (msg == "K4T") {
        imgl4.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K5T") {
        // console.log("K5T");
        imgall.src = "/assets/images/smart/lampon.svg";
        imgl1.src = "/assets/images/smart/lampon.svg";
        imgl2.src = "/assets/images/smart/lampon.svg";
        imgl3.src = "/assets/images/smart/lampon.svg";
        imgl4.src = "/assets/images/smart/lampon.svg";
      }
      if (msg == "K5F") {
        // console.log("K5F");
        imgall.src = "/assets/images/smart/lampoff.svg";
        imgl1.src = "/assets/images/smart/lampoff.svg";
        imgl2.src = "/assets/images/smart/lampoff.svg";
        imgl3.src = "/assets/images/smart/lampoff.svg";
        imgl4.src = "/assets/images/smart/lampoff.svg";
      }
    },
    sendPassKeyTouchFara() {
      if (!passHomeValidationInput(this.passkeytouchfara)) return;
      this.sendMessage(keytouch_status, this.passkeytouchfara);
      this.passkeytouchfara = "";
    },
    lastStausLamp() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModalLoading();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.showStatusLamp(message);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    showStatusLamp(message) {
      // let checkboxAll = document.getElementById("checkall");
      // let checkbox1 = document.getElementById("checkL1");
      // let checkbox2 = document.getElementById("checkL2");
      // let checkbox3 = document.getElementById("checkL3");
      // let checkbox4 = document.getElementById("checkL4");

      // var imgall = document.getElementById("imgall");
      // var imgl1 = document.getElementById("imgl1");
      // var imgl2 = document.getElementById("imgl2");
      // var imgl3 = document.getElementById("imgl3");
      // var imgl4 = document.getElementById("imgl4");

      // var L1_r = message.charAt(0);
      // var L2_r = message.charAt(1);
      // var L3_r = message.charAt(2);
      // var L4_r = message.charAt(3);

      // if (L1_r == 0) {
      //   imgl1.src = "/assets/images/smart/switch-off.svg";
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   checkbox1.checked = false;
      //   checkboxAll.checked = false;
      // }
      // if (L1_r == 1) {
      //   imgl1.src = "/assets/images/smart/switch-on.svg";
      //   checkbox1.checked = true;
      // }
      // if (L2_r == 0) {
      //   imgl2.src = "/assets/images/smart/switch-off.svg";
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   checkbox2.checked = false;
      //   checkboxAll.checked = false;
      // }
      // if (L2_r == 1) {
      //   imgl2.src = "/assets/images/smart/switch-on.svg";
      //   checkbox2.checked = true;
      // }
      // if (L3_r == 0) {
      //   imgl3.src = "/assets/images/smart/switch-off.svg";
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   checkbox3.checked = false;
      //   checkboxAll.checked = false;
      // }
      // if (L3_r == 1) {
      //   imgl3.src = "/assets/images/smart/switch-on.svg";
      //   checkbox3.checked = true;
      // }
      // if (L4_r == 0) {
      //   imgl4.src = "/assets/images/smart/switch-off.svg";
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   checkbox4.checked = false;
      //   checkboxAll.checked = false;
      // }
      // if (L4_r == 1) {
      //   imgl4.src = "/assets/images/smart/switch-on.svg";
      //   checkbox4.checked = true;
      // }
      // if (message == "1111") {
      //   checkboxAll.checked = true;
      //   checkbox1.checked = true;
      //   checkbox2.checked = true;
      //   checkbox3.checked = true;
      //   checkbox4.checked = true;
      //   imgall.src = "/assets/images/smart/switch-on.svg";
      //   imgl1.src = "/assets/images/smart/switch-on.svg";
      //   imgl2.src = "/assets/images/smart/switch-on.svg";
      //   imgl3.src = "/assets/images/smart/switch-on.svg";
      //   imgl4.src = "/assets/images/smart/switch-on.svg";
      // }
      // if (message == "1110" && this.pol == "3") {
      //   checkboxAll.checked = true;
      //   checkbox1.checked = true;
      //   checkbox2.checked = true;
      //   checkbox3.checked = true;
      //   checkbox4.checked = true;
      //   imgall.src = "/assets/images/smart/switch-on.svg";
      //   imgl1.src = "/assets/images/smart/switch-on.svg";
      //   imgl2.src = "/assets/images/smart/switch-on.svg";
      //   imgl3.src = "/assets/images/smart/switch-on.svg";
      //   imgl4.src = "/assets/images/smart/switch-on.svg";
      // }
      // if (message == "1100" && this.pol == "2") {
      //   checkboxAll.checked = true;
      //   checkbox1.checked = true;
      //   checkbox2.checked = true;
      //   checkbox3.checked = true;
      //   checkbox4.checked = true;
      //   imgall.src = "/assets/images/smart/switch-on.svg";
      //   imgl1.src = "/assets/images/smart/switch-on.svg";
      //   imgl2.src = "/assets/images/smart/switch-on.svg";
      //   imgl3.src = "/assets/images/smart/switch-on.svg";
      //   imgl4.src = "/assets/images/smart/switch-on.svg";
      // }
      // if (message == "0000") {
      //   checkboxAll.checked = false;
      //   checkbox1.checked = false;
      //   checkbox2.checked = false;
      //   checkbox3.checked = false;
      //   checkbox4.checked = false;
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   imgl1.src = "/assets/images/smart/switch-off.svg";
      //   imgl2.src = "/assets/images/smart/switch-off.svg";
      //   imgl3.src = "/assets/images/smart/switch-off.svg";
      //   imgl4.src = "/assets/images/smart/switch-off.svg";
      // }
      // if (message == "NOTSET") {
      //   checkboxAll.checked = false;
      //   checkbox1.checked = false;
      //   checkbox2.checked = false;
      //   checkbox3.checked = false;
      //   checkbox4.checked = false;
      //   imgall.src = "/assets/images/smart/switch-off.svg";
      //   imgl1.src = "/assets/images/smart/switch-off.svg";
      //   imgl2.src = "/assets/images/smart/switch-off.svg";
      //   imgl3.src = "/assets/images/smart/switch-off.svg";
      //   imgl4.src = "/assets/images/smart/switch-off.svg";
      // }
      if (message === "NOTSET") {
        this.result1.check = false;
        this.result2.check = false;
        this.result3.check = false;
        this.result4.check = false;
        this.resultAll.check = false;
        return;
      }

      let L1_r = message.charAt(0);
      let L2_r = message.charAt(1);
      let L3_r = message.charAt(2);
      let L4_r = message.charAt(3);

      this.result1.check = L1_r === "1";
      this.result2.check = L2_r === "1";
      this.result3.check = L3_r === "1";
      this.result4.check = L4_r === "1";
      this.resultAll.check = message === "1111" || this.pol === 3 && message === "1110" || this.pol === 2 && message === "1100";
    },
    closePass() {
      // location.reload();
      this.lastStausLamp();
    },
  },
};
</script>


<style scoped>
</style>