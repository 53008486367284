<template>
  <div
    class="modal modal-fill fade"
    tabindex="-1"
    role="dialog"
    id="modal-init-pass"
    ref="vuemodal"
  >
    <v-app class="bg m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <div class="mr-0 bg-white">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <!-- Main content -->
            <section class="content">
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-12 col-lg-12 p-0">
                  <div class="box">
                    <div class="box-body p-0">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div style="text-align: center; margin-top: 10px">
                          <div class="fontawesome-icon-list m-0">
                            <img
                              src="/assets/images/smart/setpassword.svg"
                              style="width: 300px; height: 300px"
                            />
                            <h5 class="font-weight-bold">
                             {{$t('message.ModalAddHome.password_tips')}}
                            </h5>
                          </div>
                        </div>
                        <br />
                        <form class="px-20">
                          <div class="form-group">
                            <h6>{{$t('message.ModalAddHome.pin_code')}}<span class="text-danger">*</span></h6>
                            <input
                              type="number"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              maxlength="32"
                              pattern="[0-9]"
                              id="set_pass"
                              autocomplete="off"
                              style="
                                -webkit-text-security: disc;
                                margin-top: 8px;
                              "
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :placeholder="$t('message.ModalAddHome.password_hint')"
                              v-model="pass"
                            />
                            <input
                              type="number"
                              class="form-control"
                              required=""
                              aria-invalid="false"
                              maxlength="32"
                              pattern="[0-9]"
                              id="set_pass_rr"
                              autocomplete="off"
                              style="
                                -webkit-text-security: disc;
                                margin-top: 8px;
                              "
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              :placeholder=" $t('message.ModalAddHome.password_repeat_hint')"
                              v-model="repeatpass"
                            />
                          </div>
                          <div class="row">
                            <!-- /.col -->
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-info btn-block margin-top-10"
                                v-on:click.prevent="FunInitPassword()"
                              >
                                {{$t('message.public.submit')}}
                              </button>
                            </div>
                            <!-- /.col -->
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>
            </section>
            <!-- /. Main content -->
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </v-app>
    <!-- /.modal -->
  </div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput, passRepeatHomeValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";
export default {
  name: "Modal",
  data: function () {
    return {
      pass: "",
      repeatpass: "",
      title:'',
    };
  },
  props: {
    homeids: { type: Array },
  },
  components: {
    AppBar,
  },
  created() {
    this.title = this.$t('message.setting.defPIN')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingModalInitPass');
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
  },
  methods: {
    FunInitPassword() {
      // errinit.innerHTML="";
      var self = this;
      var getpass = this.pass;
      var getpassrepeat = this.repeatpass;
     
      if (
        !passHomeValidationInput(getpass) ||
        !passRepeatHomeValidationInput(getpassrepeat)
      )
        return;
      if (getpass !== getpassrepeat) {
        swal("", this.$t('message.ModalAddHome.pasword_error'), "error");
      } else {
        let user_id = localStorage.getItem("user_id");
        let token = localStorage.getItem("token");
        // let home_id = localStorage.getItem("home_id");
        var data = {
          user_id: user_id,
          token: token,
          home_id: this.homeids[0],
          password: getpass,
        };
        //--------------------------------------Request --------------------------------------//
        let URL = getUrl.passSet;
        const p = postAjaxCall(URL, data, "");
        // loading.style.visibility = "visible";
        //--------------------------------------Response --------------------------------------//
        p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.pass = "";
              self.repeatpass = "";
              $("#modal-init-pass").modal("hide");
              self.$emit("passInit");
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
        );
      }
    },
    onClickChild(value){
      if(value=='back'){
      this.pass = "";
      this.repeatpass = "";
      $("#modal-init-pass").modal("hide");
      }
}
  },
};
</script>
