<template>
  <v-app class="bg">
    <div class="px-3 mt-5 mb-10">
      <v-card
          max-width="1750"
          class="mx-auto">
        <v-list
            subheader
            two-line>
          <v-list-item>
            <v-icon color="primary">mdi-palette</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="px-5 mt-1 text-bold"> {{ $t('message.Rooms.avatar') }}</h6>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="m-0">
              <v-list-item-avatar
                  rounded>
                <v-img
                    :lazy-src="logoRoom"
                    max-height="50"
                    max-width="50"
                    :src="logoRoom"
                    @click="openDialog()"
                ></v-img>
              </v-list-item-avatar>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="border-gray-light"></v-divider>
          <v-list-item>
            <v-icon color="primary">mdi-format-color-text</v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <h6 class="px-5 mt-1 text-bold"> {{ $t('message.public.name') }}</h6>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="m-0 p-0">
              <div class="mt-1">
                <v-text-field
                    background-color="#f5f5f5"
                    v-model="roomName"
                    :placeholder="$t('message.Rooms.name')"
                    clearable
                    outlined
                    maxlength="32"
                >
                </v-text-field>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <v-container class="mt-4 py-0 mb-5">
      <v-btn
          class="btn btn-block"
          :loading="loading"
          :disabled="loading"
          color="bg-blue-light"
          elevation="0"
          @click="addRoomDevice"
      >
        <v-icon class="text-dark mdi-24px" left> mdi-plus</v-icon>
        <label class="text-dark text-bold mt-1">
          {{ $t("message.mainPage.add_device") }}</label>
      </v-btn>
      <div v-if="localRoomData.devices.length === 0" class="mt-10">
        <CmpEmptyData
            class="d-flex align-items-center"
            :txtCaption="txtCaptionEmpty"
            :btnShow="false"
        ></CmpEmptyData>
      </div>
      <div v-if="localRoomData.devices.length !== 0">
        <div class="mt-2 mb-50">
          <label class="mt-3" v-if="localRoomData.devices.controls.length !== 0">{{
              $t('message.Rooms.controls')
            }}</label>
          <div class="mt-1" :key="index+'A'" v-for="(infos, index) in localRoomData.devices.controls">
            <v-card>
              <v-list subheader two-line>
                <v-list-item>
                  <v-img
                      lazy-src=""
                      :src="infos.img"
                      contain
                      max-width="35"
                      max-height="35"
                  ></v-img>
                  <!-- </v-list-item-avatar> -->
                  <v-list-item-content class="mt-2 py-0 px-5">
                    <v-list-item-title
                        class="text-bold text-dark mt-2"
                        style="font-size: 16px"
                    >
                      <label>{{ infos.title }}</label>
                    </v-list-item-title>

                    <v-list-item-subtitle class="mt-1">
                        <span
                            class="text-regular text-primary"
                            style="font-size: 12px"
                        >
                          <label
                              class="text-primary"
                              style="font-size: 12px"
                          >
                              {{ infos.subTitle }}
                          </label>
                        </span>
                    </v-list-item-subtitle>
                    <v-spacer></v-spacer>
                  </v-list-item-content>
                  <v-divider vertical class="mt-2"></v-divider>
                  <v-list-item-action>
                    <v-icon class="text-danger mr-2" @click="delItem(index,'C')"
                    >mdi-close
                    </v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
          <label class="mt-3" v-if="localRoomData.devices.subDevices.length !== 0">{{
              $t('message.Rooms.sub_devices')
            }}</label>
          <div class="mt-1" :key="index" v-for="(infos, index) in localRoomData.devices.subDevices">
            <v-card>
              <v-list subheader two-line>
                <v-list-item>
                  <v-img
                      lazy-src=""
                      :src="infos.img"
                      contain
                      max-width="35"
                      max-height="35"
                  ></v-img>
                  <!-- </v-list-item-avatar> -->
                  <v-list-item-content class="mt-2 py-0 px-5">
                    <v-list-item-title
                        class="text-bold text-dark mt-2"
                        style="font-size: 16px"
                    >
                      <label>{{ infos.title }}</label>
                    </v-list-item-title>

                    <v-list-item-subtitle class="mt-1">
                        <span
                            class="text-regular text-primary"
                            style="font-size: 12px"
                        >
                          <label
                              class="text-primary"
                              style="font-size: 12px"
                          >
                              {{ infos.subTitle }}
                          </label>
                        </span>
                    </v-list-item-subtitle>
                    <v-spacer></v-spacer>
                  </v-list-item-content>
                  <v-divider vertical class="mt-2"></v-divider>
                  <v-list-item-action>
                    <v-icon class="text-danger mr-2" @click="delItem(index,'S')"
                    >mdi-close
                    </v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
          <label class="mt-3" v-if="localRoomData.devices.relays.length !== 0">{{ $t('message.relay.relays') }}</label>
          <div class="mt-1" :key="'A'+index" v-for="(infos, index) in localRoomData.devices.relays">
            <v-card>
              <v-list subheader two-line>
                <v-list-item>
                  <v-img
                      lazy-src=""
                      :src="infos.img"
                      contain
                      max-width="35"
                      max-height="35"
                  ></v-img>
                  <!-- </v-list-item-avatar> -->
                  <v-list-item-content class="mt-2 py-0 px-5">
                    <v-list-item-title
                        class="text-bold text-dark mt-2"
                        style="font-size: 16px"
                    >
                      <label>{{ infos.title }}</label>
                    </v-list-item-title>

                    <v-list-item-subtitle class="mt-1">
                        <span
                            class="text-regular text-primary"
                            style="font-size: 12px"
                        >
                          <label
                              class="text-primary"
                              style="font-size: 12px"
                          >
                              {{ infos.subTitle }}
                          </label>
                        </span>
                    </v-list-item-subtitle>
                    <v-spacer></v-spacer>
                  </v-list-item-content>
                  <v-divider vertical class="mt-2"></v-divider>
                  <v-list-item-action>
                    <v-icon class="text-danger mr-2" @click="delItem(index,'R')">
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card>
          </div>
        </div>
      </div>
      <div
          class="row rounded-16 px-4 mb-1 fixed-bottom"
          v-if="localRoomData.devices.length !== 0"
      >
        <div class="col-12 col-lg-12 px-2">
          <v-btn
              class="btn btn-info btn-block"
              color="bg-primary"
              :loading="loading"
              :disabled="loading"
              @click="submit"
          >
            <span class="text-white">{{ $t("message.Rooms.save_room") }}</span>
          </v-btn>
        </div>
      </div>
      <DialogImg v-model="showDialogImg" :data="logos" @clicked="CmpCallImg"/>
    </v-container>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import LayoutDefault from "../../layouts/LayoutDefault";
import DialogImg from "../Cmp/DialogImg.vue"
import CmpEmptyData from "../Cmp/public/CmpEmptyData";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  name: "RoomAdd",
  components: {
    CmpEmptyData,
    DialogImg
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  data() {
    return {
      loading: false,
      txtCaptionEmpty: "",
      localRoomData: {
        name: "",
        logo: "",
        devices: []
      },
      roomName: '',
      showDialogImg: false,
      logos: [],
      logoRoom: '',
      imgShow: false,
      isModalVisible: false
    };
  },
  created() {
    let self = this;
    let details
    this.txtCaptionEmpty = this.$t("message.Rooms.add_empty_caption");
    setTimeout(function () {
      self.getImageScenario();
    }, 500);
    let update = localStorage.getItem("update") ? JSON.parse(localStorage.getItem("update")) : {};
    if (update.update)
      details = {
        title: this.$t('message.Rooms.edit_room'),
      }
    else
      details = {
        title: this.$t('message.Rooms.add'),
      }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'roomAdd');
    this.localRoomData = localStorage.getItem('infoRoomLocal') ?
        JSON.parse(localStorage.getItem('infoRoomLocal')) : {
          name: "",
          logo: "",
          devices: []
        }
    this.roomName = localStorage.getItem("nameRoom") ?
        JSON.parse(localStorage.getItem("nameRoom")) : "";
    this.logoRoom = this.localRoomData.logo
    this.localRoomData.devices = localStorage.getItem('roomDevices') ? JSON.parse(localStorage.getItem('roomDevices')) : {
      controls: [],
      subDevices: [],
      relays: []
    }
    console.log(this.localRoomData)
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    onClickChild(val) {
      if (val === 'back') {
        localStorage.removeItem('roomDevices')
        localStorage.removeItem('nameRoom')
        this.$router.replace({
          name: "settingRoom",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
    },
    delItem(index, name) {
      switch (name) {
        case "C":
          this.localRoomData.devices.controls.splice(index, 1);
          break
        case "S":
          this.localRoomData.devices.subDevices.splice(index, 1);
          break
        case "R":
          this.localRoomData.devices.relays.splice(index, 1);
          break
      }
    },
    submit() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let update = localStorage.getItem("update") ? JSON.parse(localStorage.getItem("update")) : {};
      console.log(update)
      let data, URL
      if (update.update) {
        data = {
          user_id: user_id,
          token: token,
          name: this.roomName,
          serial_number: this.serial_number,
          logo_path: this.logoRoom,
          info: JSON.stringify(this.localRoomData.devices),
          room_uid: update.uid
        }
        URL = getUrl.roomsUpdate;

      } else {
        data = {
          user_id: user_id,
          token: token,
          name: this.roomName,
          serial_number: this.serial_number,
          logo_path: this.logoRoom,
          info: JSON.stringify(this.localRoomData.devices)
        }
         URL = getUrl.roomsAdd;
      }
      this.openModalLoading()
      const p = postAjaxCall(URL, data, "");
      p.then(function (response) {
            self.closeModalLoading()
            localStorage.removeItem('roomDevices')
            localStorage.removeItem('nameRoom')
            if (responseAjax(response)) {
              self.$router.push({
                name: "settingRoom",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                },
              });
              let jsonArray = JSON.parse(response.responseText);
              console.log(jsonArray)
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getImageScenario() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var data = {
        user_id: user_id,
        token: token,
      };
      //-----------------------Request ------------------------------//
     
      let URL = getUrl.scenarioLogo;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.logos = jsonArray.content.logos;
              if (self.logoScenario == null) {
                self.logoRoom = self.logos[0].logo_path
              }
              self.imgShow = true
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    openDialog() {
      this.showDialogImg = true
    },
    CmpCallImg(info) {
      this.logoRoom = info.logo_path;
      this.localRoomData.logo = info.logo_path
      localStorage.setItem('infoRoomLocal', JSON.stringify(this.localRoomData))
    },
    addRoomDevice() {
      localStorage.setItem("roomDevices", JSON.stringify(this.localRoomData.devices))
      localStorage.setItem("nameRoom", JSON.stringify(this.roomName))
      this.$router.push({
        name: "RoomDevices",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      })
    }
  }
}
</script>

<style scoped>

</style>