<template>
  <!-- .wrapper  -->
    <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-privacy"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" style="font-size: 36px">&times;</span>
            </button>
          </div>
          <section class="error-page bg-img ">
            <div class="container">
              <div
                class="row  align-items-center justify-content-center px-10"
              >
                <div class="col-lg-7 col-md-10 col-12">
                  <h6 class="text-warning">{{ $t('message.privacy.privacy') }}</h6>
                  
                  <div>
                    <p v-if="appMode=='farahoush'">
                    {{ $t('message.privacy.txt1') }}
                  </p>
                  <p v-else>
                    {{ $t('message.privacy.txt1_bmsx') }}
                  </p>
                  </div> 
                  <h6
                    class="text-primary"
                  >{{ $t('message.privacy.personal') }}</h6>

                  <div >
                    <p v-if="appMode=='farahoush'">
                    {{ $t('message.privacy.txt2') }}
                  </p>
                  <p v-else>
                    {{ $t('message.privacy.txt2_bmsx') }}
                  </p>
                  </div>
                  <div>
                    <p v-if="appMode=='farahoush'">
                  {{ $t('message.privacy.txt3') }}
                </p>
                <p v-else>
                  {{ $t('message.privacy.txt3_bmsx') }}
                </p>
                  </div>  
                  <h6
                    class="text-primary"
                  > {{ $t('message.privacy.location') }}</h6>
                  <div>
                    <p v-if="appMode=='farahoush'">
                    {{ $t('message.privacy.txt4') }}
                  </p>
                  <p v-else>
                    {{ $t('message.privacy.txt4') }}
                  </p>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  <!-- ./wrapper -->
</template>

 <script>

//--------------------------------------  variable --------------------------------------//

export default {

  data() {
    return {
      appMode:'farahoush'

    };
  },
    
  mounted() {
    this.appMode=process.env.VUE_APP_MODE
  },

   
 
  

};
</script>



<style scoped>
</style>
