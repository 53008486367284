<template>
  <v-app class="bg">
    <v-main>
      <v-container fluid justify-center class="d-flex">
        <v-flex class="pa-3 p-4 justify-content-center">
          <v-list two-line class="m-0">
            <draggable v-model="items" handle=".handle">
              <template v-for="(v, i) in items" class="items">
                <v-list-item :key="v.count">
                  <v-list-item-avatar>
                    <v-img
                        :src="v.type_logo"
                        alt="Sunny image"
                        class="mt-2"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="v.name"></v-list-item-title>
                    <v-list-item-subtitle v-html="v.type"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon class="handle">mdi-menu</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </draggable>
          </v-list>
        </v-flex>
      </v-container>
      <div class="row rounded-16 px-4 mb-1 fixed-bottom">
        <div class="col-12 col-lg-12">
          <v-btn
              class="btn btn-info btn-block"
              color="bg-primary"
              @click="Done"
          >
            <span class="text-white">{{ $t("message.public.submit") }}</span>
          </v-btn>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  name: "Selection_Drag",
  components: {
    draggable
  },
  props: {
    home_id: {},
    serial_number: {},
    type_code: {},
    click: {}
  },
  data() {
    return {
      title: "",
      editing: true,
      items: []
    }
  },
  created() {
    let subDevices = localStorage.getItem("sub_devices");
    if (subDevices) {
      this.items = JSON.parse(subDevices);
    }
    this.title = this.$t('message.subDevice.selection')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Selection_Drag');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    if (localStorage.getItem("counts")) {
      let counts = JSON.parse(localStorage.getItem("counts"));
      let temp = [];
      let temp2 = [];
      let counter = 0;
      for (const item in counts) {
        for (const item2 in this.items) {
          if (this.items[item2].count == counts[item]) {
            temp[counter] = this.items[item2];
            temp2[counter] = this.items[item2].count;
            this.items.splice(item2, 1);
            counter++;
          }
        }
      }
      if (this.items.length > 0) {
        for (const item in this.items) {
          temp[counter] = this.items[item];
          temp2[counter] = this.items[item].count;
          counter++;
        }
      }
      console.log(temp, temp2);
      this.items = temp;
    }
  },
  methods: {
    action() {
      let count = []
      for (let i = 0; i < this.items.length; i++) {
        count[i] = this.items[i].count;
      }
      localStorage.setItem("counts", JSON.stringify(count));
      console.log(count);
    },
    Done() {
      this.action();
      this.onClickChild("done");
    },
    onClickChild(value) {
      if (value === "back" || value === "done")
        switch (localStorage.getItem("selection")) {
          case '9m':
            this.$router.push({
              name: "settingsir",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type: 9,
              }
            })
            break;
          case "9g":
            this.$router.push({
              name: "SettingClientIR",
              params: {home_id: this.home_id, serial_number: this.serial_number, type: 9,},
            });
            break;
          case "7m":
            this.$router.push({
              name: "settings",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type: 7,
              }
            })
            break;
          case "7g":
            this.$router.push({
              name: "settingclient",
              params: {home_id: this.home_id, serial_number: this.serial_number, type: 7},
            });
            break;
        }
    }
  }
}
</script>

<style scoped>

</style>