<template>
  <v-app class="bg">
   
    <!-- <CmpUpdate v-if="finish" :data_txt="time[0] +' '+ time[1]" :data_type="'default'" class="mt-3 align-self-center"
               @refresh="getTime"></CmpUpdate> -->
    <TimerList :isShow="isShow" :length="length" :infos="info" @addClock="addClock()" @addTimer="addTimer()"
               @deleteTimer="deleteTimer"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {SetClock} from "../../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import {getMessageSub} from "@/../public/assets/js/utils/helper_sub_message.js";
import {Tools} from "../../../../../public/assets/js/utils/util.js";
import TimerList from "@/components/Cmp/timer/show/TimerList.vue";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
// import CmpUpdate from "@/components/Cmp/public/CmpUpdate.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var count_timer;
var connection = false;

export default {
  components: {
    TimerList,
    CmpPasscode,
    // CmpUpdate
  },
  data: () => ({
    info: "",
    lang: '',
    length: "",
    isShow: false,
    title: '',
    set_password: '',
    counter: '',
    sub_serial_number: '',
    isModalVisible: false,
    finish: false,
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  created() {
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true,
      // menu:true,
      // menuAdd:true,
      // refresh: true,
      // delAll:true
      
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
    this.lang = localStorage.getItem("Lang");
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.sub_serial_number = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
    this.set_password = JSON.parse(localStorage.getItem("infoSubDevice")).set_password;
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.setTime((String(data).split("-")))
        connection = true;
      }
    },
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler_show')
    this.$store.dispatch("infoHome");
    this.$store.dispatch("InfoSubDevice");
    setTimeout(function () {
      self.lastStatus();
      // self.getTime();
    }, 100);
  },

  methods: {
    lastStatus() {
      let self = this;

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let sucdevice_sn = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.info = '';

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: sucdevice_sn,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.timers;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              var len = content.length;
              self.length = content.length;
              if (len == 0) {
                self.isShow = true;
              }
              console.log("content", content)
              content.forEach(function (item) {
                const data = getTimerData(item.message);

                finaldata.push(data);
                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    // setTime(time) {
    //   time[1] = time[1].substring(0, 1);
    //   time[0] = time[0].substring(1,);
    //   this.time = time;
    //   switch (time[1]) {
    //     case "1":
    //       this.time[1] = this.$t("message.timer.monday");
    //       break;
    //     case "2":
    //       this.time[1] = this.$t("message.timer.tuesday");
    //       break;
    //     case "3":
    //       this.time[1] = this.$t("message.timer.wednesday");
    //       break;
    //     case "4":
    //       this.time[1] = this.$t("message.timer.thursday");
    //       break;
    //     case "5":
    //       this.time[1] = this.$t("message.timer.friday");
    //       break;
    //     case "6":
    //       this.time[1] = this.$t("message.timer.saturday");
    //       break;
    //     case "7":
    //       this.time[1] = this.$t("message.timer.sunday");
    //       break;
    //   }
    //   this.finish = true;
    // },
    // getTime() {
    //   let self = this;
    //   let user_id = localStorage.getItem("user_id");
    //   let token = localStorage.getItem("token");
    //   let serial_number = this.serial_number;
    //   var topic_final = getTopics.getTopicToggleSend(this.serial_number, "256");
    //   let message = "DCTN?"
    //   var data = {
    //     user_id: user_id,
    //     token: token,
    //     message: message,
    //     topic: topic_final,
    //     serial_number: serial_number,
    //     count: 256,
    //     client_id: "fara" + Date.now(),
    //   };

    //   //-----------------------Request ------------------------------//
    //   let URL = getUrl.mqttRequest;
    //   const p = postAjaxCall(URL, data);
    //   this.openModalLoading();
    //   //-----------------------Response ------------------------//
    //   p.then(
    //       function (response) {
    //         var jsonArray = JSON.parse(response.responseText);
    //         if (responseAjax(response)) {
    //           self.exitModalLoading();
    //           self.subcribeTime();
    //         } else {
    //           //error

    //           self.exitModalLoading();
    //           if (response.status == 401) {
    //             if (jsonArray.code == "4001") {
    //               self.$router.push({name: "home"});
    //             }
    //           }
    //         }
    //       },
    //       //----------------------Err ---------------------//
    //       function (errr) {
    //       }
    //   );
    // },
    deleteTimer(counttimer) {
      let self = this;

      swal(
          {
            title: this.$t('message.timer.timer'),
            text: this.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: this.$t('message.public.delete'),
            cancelButtonText: this.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {
              if (self.set_password == 1) {
                self.counter = counttimer;
                $("#modal-pass-code").modal("show");
              } else
                self.sendDelete(counttimer, '');
            }
          }
      );
    },
    sendReq(pass) {
      this.sendDelete(this.counter, pass);
    },
    sendDelete(counttimer, pass) {
      let self = this;
      // let tapp = localStorage.getItem("t_app");
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      // let device_serial = this.serial_number;
      let count = this.count;
      count_timer = counttimer;
      let message = getMessageSub.timerItemOFF(count_timer);
      var topic_final = getTopics.getTopicTimerSend(this.serial_number, this.sub_serial_number);
      
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        password: pass,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.exitModalLoading();
              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                }
              }, 10000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, 256);
      this.$mqtt.subscribe(topic);
    },
    subcribeTime(){
      var topic1 = getTopics.getTopicToggleReceive(this.serial_number, 256);
      this.$mqtt.subscribe(topic1);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();

      let device_serial = this.serial_number;
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      // location.reload();
      self.lastStatus();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicClockSend(this.serial_number, this.sub_serial_number);

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000);
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "ElevatorProSetting",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "refresh") {
        this.lastStatus();
      }
      if (value == "add") {
        this.$router.push({
          name: "ElevatorProStep2Timer",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    addTimer() {
      this.$router.push({
        name: "ElevatorProStep2Timer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
  },
};
</script>


<style scoped>
</style>
