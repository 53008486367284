<template>
  <v-app class="bg d-flex">
    <CmpUpdate v-if="finish" :data_txt="time[0] +' '+ time[1]" :data_type="'default'" class="mt-3 align-self-center"
               @refresh="getTime"></CmpUpdate>
    <TimerList :isShow="isShow" :length="length" :infos="info" :type="type" @addClock="addClock()"
               @addTimer="addTimer()" @deleteTimer="deleteTimer"/>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import TimerList from "@/components/Cmp/timer/show/TimerList.vue";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import {SetClock, Tools} from "../../../../../public/assets/js/utils/util";
import {getTopics} from "../../../../../public/assets/js/utils/topics.js";
import CmpUpdate from "../../../Cmp/public/CmpUpdate";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection = false;
var count_timer;

export default {
  name: "BoxRelayTimerShow",
  components: {
    CmpUpdate,
    TimerList,
    CmpPasscode,
  },
  data: () => ({
    info: "",
    lang: '',
    length: "",
    isShow: false,
    title: '',
    time: '',
    day: '',
    type: '8Relay',
    finish: false,
    set_password: '',
    counter: '',
    isModalVisible: false,
  }),
  created() {
    this.lang = localStorage.getItem("Lang");
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
    this.set_password = JSON.parse(JSON.stringify(localStorage.getItem("infoSubDevice")));
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.setTime((String(data).split("-")))
        // this.checkMessage(String(data))
        connection = true;
      }
    },
  },
  props: {
    serial_number: {},
    home_id: {},
    sub_serial: {},
    click: {}
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler_show')
    this.$store.dispatch("infoHome");
    this.$store.dispatch("InfoSubDevice");
    setTimeout(function () {
      self.getTime();
      self.lastStatus();
    }, 300);
  },
  methods: {
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let subdevice_sn = this.sub_serial;
      this.info = '';
      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: subdevice_sn,
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/device/timers";
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              var len = content.length;
              self.length = content.length;
              if (len == 0) {
                self.isShow = true;
              }

              content.forEach(function (item) {
                const data = getTimerData(item.message);

                finaldata.push(data);

                self.info = finaldata;
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getTime() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let subdevice_sn = this.sub_serial;
      var topic_final = getTopics.getTopicToggleSend(this.serial_number, this.sub_serial);
      let message = "DCTN?"
      var data = {
        user_id: user_id,
        token: token,
        message: message,
        topic: topic_final,
        gateway_sn: serial_number,
        password: '',
        count: subdevice_sn,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              self.subscrib();
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    setTime(time) {
      time[1] = time[1].substring(0, 1);
      time[0] = time[0].substring(1,);
      this.time = time;
      switch (time[1]) {
        case "1":
          this.time[1] = this.$t("message.timer.monday");
          break;
        case "2":
          this.time[1] = this.$t("message.timer.tuesday");
          break;
        case "3":
          this.time[1] = this.$t("message.timer.wednesday");
          break;
        case "4":
          this.time[1] = this.$t("message.timer.thursday");
          break;
        case "5":
          this.time[1] = this.$t("message.timer.friday");
          break;
        case "6":
          this.time[1] = this.$t("message.timer.saturday");
          break;
        case "7":
          this.time[1] = this.$t("message.timer.sunday");
          break;
      }
      this.finish = true;
    },
    deleteTimer(counttimer) {
      let self = this;
      swal(
          {
            title: self.$t('message.timer.timer'),
            text: self.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.delete'),
            cancelButtonText: self.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {
              if (self.set_password == 1) {
                self.counter = counttimer;
                $("#modal-pass-code").modal("show");
              } else
                self.sendDelete(counttimer, '');
            }
          }
      );
    },
    sendReq(pass) {
      this.sendDelete(this.counter, pass);
    },
    sendDelete(counttimer, pass) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      count_timer = counttimer;
      let message = "TIMER" + count_timer + "-OFF#";
      var topic_final = getTopics.getTopicTimerSend(this.serial_number, this.sub_serial);
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: pass,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.subscribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                  self.exitModalLoading();
                } else {
                  self.exitModalLoading()
                  self.lastStatus()
                }
              }, 7000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subscribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, 256);
      this.$mqtt.subscribe(topic);
    },
    subscrib() {
      var topic = getTopics.getTopicToggleReceive(this.serial_number, this.sub_serial);
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false,
      });
   
      // location.reload();
      self.lastStatus()
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicClockSend(this.serial_number, this.sub_serial);

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000)
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "subboxrelay",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            sub_serial: this.sub_serial,
          },
        });
      }
      if (value == "refresh") {
        this.lastStatus();
      }
      if (value == "add") {
        this.addTimer();
      }
    },
    addTimer() {
      this.$router.push({
        name: "boxRelayTimer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          sub_serial: this.sub_serial,
        },
      });
    },
  }
};
</script>

<style scoped>

</style>