import { render, staticRenderFns } from "./SettingVolt.vue?vue&type=template&id=76b82ada&scoped=true"
import script from "./SettingVolt.vue?vue&type=script&lang=js"
export * from "./SettingVolt.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b82ada",
  null
  
)

export default component.exports