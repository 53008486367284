import i18n from "../../../../src/i18n";
import Swal from 'sweetalert2'

export const responseAjax = (ajax) => {
    if (ajax.status == 200 && ajax.readyState == 4) {
        return true;
    } else if (ajax.status == 401) {
        let jsonArray = JSON.parse(ajax.responseText);
        if (jsonArray.code == '4001')
            localStorage.clear();
        return false;
    } else if (ajax.status == 500) {
        let jsonArray = JSON.parse(ajax.responseText);
        Swal.fire({
            icon: 'error',
            title: i18n.t('message.postAjax.connection'),
            text: jsonArray.message,
            confirmButtonColor: "#d33",
            // button:  i18n.t('message.public.OK'),
        })
        setTimeout(function () {
            location.reload();
        }, 100);

    } else if (ajax.status == 403) {
        let jsonArray = JSON.parse(ajax.responseText);
        Swal.fire({
            icon: 'error',
            title: i18n.t('message.public.error'),
            text: jsonArray.message,
            confirmButtonColor: "#d33",
            // button:  i18n.t('message.public.OK'),
        })
    } else if (ajax.status == 404) {
        // let jsonArray = JSON.parse(ajax.responseText);
        Swal.fire({
            icon: 'error',
            title: i18n.t('message.public.error'),
            text: i18n.t('message.error_handler.not_found'),
            confirmButtonColor: "#d33",
            // button:  i18n.t('message.public.OK'),
        })
    } else if (ajax.status == 400) {
        let jsonArray = JSON.parse(ajax.responseText);
        Swal.fire({
            icon: 'error',
            title: i18n.t('message.public.error'),
            text: jsonArray.message,
            confirmButtonColor: "#d33",
            // button:  i18n.t('message.public.OK'),
        })
    } else if (ajax.status == 422) {
        let jsonArray = JSON.parse(ajax.responseText);
        console.log("424")
        Swal.fire({
            icon: 'error',
            title: i18n.t('message.public.error'),
            text: jsonArray.message,
            confirmButtonColor: "#d33",
            // button:  i18n.t('message.public.OK'),
        })
    }
    return false;
}