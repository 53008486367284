<template>
  <v-app class="bg">
    <section class="content mt-5">
      <div class="row px-20" id="app">
        <div
            class="col-4 col-lg-4 px-1 py-2"
            v-bind:key="index"
            v-for="(infos, index) in info"
        >
          <div class="flexbox flex-justified text-center">
            <div
                class="b-1 box rounded-12 py-20 m-0"
                v-on:click="
                  sendId(
                    infos.id,
                    infos.label,
                    infos.category,
                    infos.farahoush_device,
                    infos.type_code
                  )
                "
            >
              <img
                  style="
                    max-height: 50px !important;
                    min-height: 50px !important;
                  "
                  :src="infos.logo_path"
                  alt=""
              />
              <p class="mb-0 font-weight-300 mt-10">{{ infos.label }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";
//--------------------------------------  variable --------------------------------------//

export default {
  props: {
    home_id: {},
    serial_number: {},
    type_code: {},
    click: {}
  },
  data() {
    return {
      info: null,
      nameDevice: "",
      isModalVisible: false,
      data_info: [this.home_id, this.serial_number],
      localRelaydata: [],
    };
  },

  created() {
    let data = {
      title: this.$t("message.mainPage.add_device"),
      refresh:true,
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AddDevice');
  },
  mounted() {
    let self = this;
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");

    var data = {
      user_id: user_id,
      token: token,
    };
    let URL = getUrl.types;
    const p = postAjaxCall(URL, data);
    this.openModalLoading();
    p.then(function (response) {
      var jsonArray = JSON.parse(response.responseText);

      if (responseAjax(response)) {
        self.closeModalLoading();
        self.info = jsonArray.content.types;
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({name: "home"});
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendId(id, label, category, farahoush_device, type_code) {
      this.data_info[2] = type_code;
      this.data_info[3] = label;
      this.data_info[4] = farahoush_device;
      this.nameDevice = label;
      if (farahoush_device == 1) {
        if (category == "WIFI") {
          this.$router.push({
            name: "ModalAddSubdeviceFara",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              type_code: type_code,
              farahoush: farahoush_device,
              nameDevice: label
            }
          })
        }
        if (category === "2RF") {
           if (type_code === 1) {
            this.$router.push({
              name: "ModalAddSubdeviceFarapol",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code == 10) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code == 18) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 15) {
            localStorage.setItem(
                "infoRelay8oLocal",
                JSON.stringify(this.localRelaydata)
            );
            this.$router.push({
              name: "boxrelayadd",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
              },
            });
          }
          if (type_code === 20) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 21) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 22) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 17) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 23) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 24) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
        } 
        if(category ==="2RF-PRO"){
          if (type_code === 30) {
            this.$router.push({
              name: "ModalAddSubdeviceFarapol",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 31) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 32) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 33) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 34) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 35) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 36) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
          if (type_code === 38) {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
        }
      } else if (farahoush_device == 0) {
        if (category == "RF") {
          if (type_code == 2) {
            this.$router.push({
              name: "ModalAddSubdeviceFarapol",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          } else if (type_code == 8) {
            this.$router.push({
              name: "ModalAddSubdeviceFarapol",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          } else {
            this.$router.push({
              name: "ModalAddSubdeviceFara",
              params: {
                home_id: this.home_id,
                serial_number: this.serial_number,
                type_code: type_code,
                farahoush: farahoush_device,
                nameDevice: label
              }
            })
          }
        }
      }
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
      if (value == "refresh") {
        location.reload();
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
  }
};
</script>


<style scoped>
</style>
