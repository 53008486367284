<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="box-body chart-responsive">
        <div>
          <!-- Main content -->
          <section class="content">
            <!-- row -->
            <div class="row">
              <!-- col -->
              <div class="col-12 col-lg-12 p-0">
                <div class="box">
                  <div class="box-body p-0">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="bg-white" style="height: 812px">
                        <div style="text-align: center; margin-top: 10px">
                          <div class="fontawesome-icon-list m-0">
                            <img
                                src="/assets/images/smart/vector-confirm-sms.svg"
                                class="avatar-smart"
                            />
                            <h6
                                class="font-weight-bold px-30"
                                style="line-height: 25px !important"
                            >
                              {{ $t('message.client.verify_manager') }}
                            </h6>
                            <p>{{ $t('message.client.enter') }}</p>
                          </div>
                        </div>
                        <br/>
                        <form class="px-40">
                          <div class="form-group">
                            <h6>{{ $t('message.client.OK') }}<span class="text-danger">*</span></h6>
                            <input
                                id="inputcodeclient"
                                type="number"
                                class="form-control"
                                aria-invalid="false"
                                autocomplete="off"
                                maxlength="6"
                                :placeholder="$t('message.loginVerify.inputCode')"
                                v-model="smscodeadmin"
                            />
                          </div>

                          <div class="row">
                            <!-- /.col -->
                            <div class="col-12 text-center">
                              <button
                                  id="btnsetclient"
                                  type="button"
                                  class="btn btn-info btn-block margin-top-10"
                                  @click="confirmCode()"
                              >
                                {{ $t('message.public.submit') }}
                              </button>
                            </div>
                            <!-- /.col -->
                          </div>
                        </form>
                        <div class="text-center" style="height: 50px"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.col -->
            </div>
            <!-- /.row -->
          </section>
          <!-- /. Main content -->
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    data_infos: {type: Array},
    home_id: {},
    serial_number: {},
    verify: {},
    type: {},
    click: {},
  },
  data() {
    return {
      info: null,
      smscodeadmin: "",
      title: '',
    };
  },

  mounted() {
    this.title = this.$t('message.public.submit');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AddClient');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    confirmCode() {
      //--------------------------------------  variable --------------------------------------//
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let serial_number = this.serial_number;
      let verify_code = this.smscodeadmin;
      let admin_verify_id = this.verify;

      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        admin_verify_id: admin_verify_id,
        verify_code: verify_code,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.clientVerify;

      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.$router.replace({
                name: "managementclientinfo",
                params: {
                  home_id: home_id,
                  serial_number: serial_number,
                  type: self.type,
                },
              });
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    cancelFunction() {
      this.$router.replace({
        name: "SetClient",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type,
        },
      });
    },
    goBackSetClientVerify() {
      this.$router.replace({
        name: "SetClient",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type: this.type,
        },
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "SetClient",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            type: this.type,
          },
        });
      }
    },

    // btnLoading() {
    //   var $btn = $("#btnsetclient");
    //   $btn.click(function () {
    //     var $this = $(this);
    //     $this
    //         .attr("disabled", "disabled")
    //         .html(
    //             `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
    //         );
    //     setTimeout(function () {
    //       $this.removeAttr("disabled").html(this.$t('message.public.submit'));
    //     }, 2000);
    //   });
    // },
  },
};
</script>
<style scoped>
</style>
