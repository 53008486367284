<template>
  <!-- .wrapper  -->
  <div class="wrapper m-0">
    <section class="content">
      <!-- row -->
      <div class="row">
        <!-- col -->
        <div class="col-12 col-lg-12 p-0">
          <div class="box">
            <div class="box-body p-0">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="bg-white">
                  <div style="text-align: center; margin-top: 10px">
                    <div class="fontawesome-icon-list m-0">
                      <img
                        src="/assets/images/smart/profile.svg"
                        style="width: 300px; height: 300px"
                      />
                      <h5 class="font-weight-bold">
                          {{ $t('message.profile.edit') }}
                      </h5>
                    </div>
                    <!-- <h6 class="mt-5 p-10"></h6> -->
                  </div>
                  <br />
                  <form novalidate class="px-20">
                    <!-- <h3 class="mt-15 mb-10">احراز هویت پیامکی</h3> -->
                    <div class="form-group">
                      <h6>{{$t('message.public.name')}} <span class="text-danger">*</span></h6>
                      <input
                        type="text"
                        class="form-control"
                        required=""
                        aria-invalid="false"
                        maxlength="32"
                        autocomplete="off"
                        id="firstname"
                        :placeholder="$t('message.loginVerify.inputFname')"
                      />
                    </div>
                    <div class="form-group">
                      <h6>{{ $t('message.profile.last_name') }} <span class="text-danger">*</span></h6>
                      <input
                        type="text"
                        class="form-control"
                        required
                        aria-invalid="false"
                        maxlength="16"
                        autocomplete="off"
                        id="lastname"
                        :placeholder="$t('message.loginVerify.inputLname')"
                      />
                    </div>
                    <div class="form-group">
                      <h6>
                        {{ $t('message.profile.address') }}
                        <span class="text-danger">*</span>
                      </h6>
                      <input
                        type="text"
                        class="form-control"
                        required
                        aria-invalid="false"
                        autocomplete="off"
                        id="address"
                        :placeholder="$t('message.profile.input_address')"
                      />
                    </div>
                    <!-- <br />  -->
                    <div class="row">
                      <!-- /.col -->
                      <div class="col-12 text-center">
                        <!-- <button
                          id="btn-add-home"
                          type="button"
                          class="btn btn-info btn-block margin-top-10"
                          v-on:click.prevent="myFunction()"
                        >
                          ثبت
                        </button> -->
                         <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="myFunction()"
                            >
                            <span class="text-white"> {{$t('message.public.submit')}} </span>
                            </v-btn>
                      </div>
                      <!-- /.col -->
                    </div>
                  </form>

                  <div class="text-center" style="height: 50px">
                    <!-- <p class="mt-15 mb-0">Already have an account?<a href="auth_login.html" class="text-danger ml-5"> Sign In</a></p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.col -->
      </div>
      <div class="col-lg-7 col-12"></div>

      <!-- /.row -->
    </section>
    <!-- /. Main content -->
    <!-- /.Content Wrapper. Contains page content -->
  </div>
  <!-- ./wrapper -->
</template>

 <script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {
  addressUserValidationInput,
  lnameUserValidationInput, nameValidationInput
} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {},
  data() {
    return {
      title: '',
      loading: false,
    };
  },
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.title= this.$t('message.AppBar.editProfile');
        this.$store.dispatch("infoUser");
    document.getElementById("firstname").value =
      this.$store.getters.getFnameUser;
    document.getElementById("lastname").value =
      this.$store.getters.getLnameUser;
    document.getElementById("address").value =
      this.$store.getters.getAddressUser;
  },
  created() {
    let data = {
      title: this.$t('message.AppBar.editProfile'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ProfileEdit');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    myFunction() {
      let self = this;
       
      //--------------------------------------  variable --------------------------------------//
      var fname = document.getElementById("firstname").value;
      var lname = document.getElementById("lastname").value;
      var address = document.getElementById("address").value;

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      //     //--------------------------------------  validation --------------------------------------//
      if (
        !nameValidationInput(fname) ||
        !lnameUserValidationInput(lname) ||
        !addressUserValidationInput(address)
      )
        return;
      this.loading=true;
      //     //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        fname: fname,
        lname: lname,
        // gender: ,
        address: address,
      };
      //     //--------------------------------------Request --------------------------------------//
      let URL = getUrl.profileEdit;
      //  $("#modal-center").modal('show');
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
                 self.loading=false
            self.$router.push({ name: "profile" });
          } else {
                 self.loading=false
            //error
          }
        },
        //         //--------------------------------------Err --------------------------------------//
        function (errr) {
         
        }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({ name: "profile" });
      }
    },
  },
};
</script>



<style scoped>
</style>
