export function getControlTvStatus(message, version) {
    var type = '';
    if (version == 'V1') {
        switch (message) {
            case 0:
                type = 'mdi mdi-power';
                break;
            case 1:
                type = 'mdi mdi-volume-off';
                break;
            case 2:
                type = 'mdi mdi-import';
                break;
            case 3:
                type = 'mdi mdi-plus';
                break;
            case 4:
                type = 'mdi mdi-minus';
                break;
            case 5:
                type = 'ti-angle-up';
                break;
            case 6:
                type = 'ti-angle-down';
                break;
            case 7:
                type = 'ti-angle-up';
                break;
            case 8:
                type = 'ti-angle-down';
                break;
            case 9:
                type = 'ti-angle-left';
                break;
            case 10:
                type = 'ti-angle-right';
                break;
            case 11:
                type = 'mdi-checkbox-marked-circle';
                break;
            case 12:
                type = 'mdi mdi-export';
                break;
            case 13:
                type = 'mdi mdi-keyboard-return';
                break;
            case 14:
                type = 'mdi mdi-information-variant';
                break;
            case 15:
                type = 'mdi mdi-menu';
                break;
            default:
        }
        return type;
    }
}