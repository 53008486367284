import { render, staticRenderFns } from "./noneModal8RelayResetFactory.vue?vue&type=template&id=33771588&scoped=true"
import script from "./noneModal8RelayResetFactory.vue?vue&type=script&lang=js"
export * from "./noneModal8RelayResetFactory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33771588",
  null
  
)

export default component.exports