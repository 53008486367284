<template>
  <v-app class="bg">
    <v-container>
      <v-row>
        <v-col cols="12" class="mt-2">
          <!-- Outlet Show -->
          <div v-if="type_code == 7">
            <v-card class="bg-info-gradient px-2">
              <v-row v-on:click="
                sendItemDevice(
                  info_device.id,
                  content.gateway.name,
                  info_device.serial_number,
                  info_device.type,
                  info_device.type.type_code,
                  content.gateway.set_password,
                  info_device.password,
                  content.gateway
                )">
                <v-col cols="12">
                  <img src="/assets/images/smart/vector-outlet.svg" width="35px" height="35px" alt="..." />
                  <label class="px-2">
                    <strong class="text-white">{{ content.gateway.name }}</strong>
                  </label>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <!-- Ir Show -->
          <div v-if="type_code == 9">
            <v-card class="bg-info-gradient px-2">
              <v-row v-on:click="
                sendItemDevice(
                  info_device.id,
                  content.gateway.name,
                  info_device.serial_number,
                  info_device.type,
                  info_device.type.type_code,
                  content.gateway.set_password,
                  info_device.password,
                  content.gateway
                )
              ">
                <v-col>
                  <img class="avatar" src="/assets/images/smart/icon-ir-remote-white.svg" width="40px" height="40px"
                    alt="..." />
                  <label class="px-2">
                    <strong class="text-white">{{ content.gateway.name }}</strong>
                  </label>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <!-- Senario Show -->
        <v-col md="12" cols="12" class="py-0 mt-0" v-if="type_code == 9">
          <div class="mt-0">
            <v-card color="transparent" class="mt-2">
              <v-card-title class="py-0">
                <h6 class="text-bold mt-2" style="color: #455a64">
                  {{ $t("message.scenario.scenarios") }}
                </h6>
                <v-spacer></v-spacer>
                <div class="py-2" @click="clickSettingScenario()" v-if="main_admin != 0">
                  <v-icon small v-if="lang === 'en' || lang === 'tur'"> ti-angle-right</v-icon>
                  <v-icon small v-else>ti-angle-left</v-icon>
                </div>
              </v-card-title>
            </v-card>
            <div v-if="senarios.length == 0" class="bg">
              <CmpEmptyData v-if="cmpShow" :imgShow="true" :txt="txtTitleSenarioEmpty" :txtCaptionShow="false"
                :btnShow="false" :txtBtn="$t('message.scenario.add.add_scenario')"></CmpEmptyData>
            </div>
            <div style="overflow-x: auto; white-space: nowrap" class="bg" v-else>
              <div class="flexbox flex-justified text-center mt-1 px-2" style="width: 50px !important; height: auto">
                <div class="rounded-12 px-2" v-bind:key="index" v-for="(senario, index) in senarios">
                  <div class="box d-flex justify-center" style="width: 60px !important; height: 60px !important"
                    @click="runScenario(senario.count, senario.msg, senario.set_password)">
                    <div style="width: 60px !important; height: 60px !important" class="align-self-center">
                      <img :src="senario.logo_path" alt="" class="p-5 align-self-center" />
                    </div>
                  </div>
                  <div class="d-flex justify-center">
                    <label v-if="senario.name.length > 10">{{
                        senario.name.slice(0, 10) + "..."
                    }}</label>
                    <label v-else>{{ senario.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <!-- Sub Device Show -->
        <div class="col-12 py-0">
          <v-card color="transparent" class="mt-5">
            <v-card-title class="py-0">
              <h6 class="text-bold" style="color: #455a64">
                {{ $t("message.ListDevice.Rooms") }}
              </h6>
              <v-spacer></v-spacer>
              <div class="py-2" @click="clickSettingRooms()" v-if="main_admin != 0">
                <v-icon small v-if="lang === 'en' || lang === 'tur'"> ti-angle-right</v-icon>
                <v-icon small v-else>ti-angle-left</v-icon>
              </div>
            </v-card-title>
          </v-card>
          <v-sheet>
            <v-tabs v-model="tab" center-active color="primary" background-color="#f2f3f8">
              <v-tab v-for="item in items" :key="item.tab">
                <label class="text-bold">{{ item.tab }}</label>
              </v-tab>
              <v-menu v-if="ifMore" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text class="align-self-center" v-bind="attrs" v-on="on">
                    <v-icon left>
                      mdi-menu-down
                    </v-icon>
                    {{ $t('message.public.menu') + ' . . . . . .' }}
                  </v-btn>
                </template>
                <v-list class="grey lighten-3">
                  <v-list-item v-for="item in more" :key="item.tab" @click="addItem(item)">
                    <label>{{ item.tab }}</label>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in items" :key="item.tab">
                <ShowRoom :data="item.content" :serial_number="info_device.serial_number" :home_id="home_id"
                  @sendRequestRelay="sendRequestRelay"></ShowRoom>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </div> 
      </v-row>
    </v-container>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq" @cancel="cancel"></CmpPasscode>
  </v-app>
</template>

<script>
import CmpEmptyData from "./Cmp/public/CmpEmptyData.vue";
import WidgetRelay from "@/components/Cmp/Widgets/WidgetRelay.vue";
import { getTopics } from "@/../public/assets/js/utils/topics.js";
import { getMtt } from "@/../public/assets/js/utils/helper_mtt.js";

import { getMessageSub } from "../../public/assets/js/utils/helper_sub_message.js";
import CmpPasscode from "./Cmp/general/CmpPasscode"
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../public/assets/js/utils/util.js";
import { ToastCustom } from "../../public/assets/js/utils/util.js";
import { postAjaxCall } from "../../public/assets/js/postAjax";
import { responseAjax } from "../../public/assets/js/utils/response_ajax_handler";
import LayoutDefault from "../layouts/LayoutDefault";
import ShowRoom from "./rooms/showRoom";
import LampStep2Timer from "./subdevice/lamp/timer/LampStep2Timer";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

//--------------------------------------  variable --------------------------------------//
var showToast;
var connection;
var timeout = false;
var timer;

export default {
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  components: {
    LampStep2Timer,
    ShowRoom,
    CmpEmptyData,
    WidgetRelay,
    CmpPasscode,
  },
  data() {
    return {
      info: null,
      infoRooms: [],
      tab: null,
      items: [],
      ifMore: false,
      more: [],
      cols: "",
      senarios: [],
      info_device: null,
      type_code: null,
      main_admin: "",
      isModalVisible: false,
      cmpShow: false,
      txtTitleSenarioEmpty: "",
      txtCaptionSenarioEmpty: "",
      localScenariodata: {
        name: "",
        logo: "",
        actions: [],
      },
      countSenario: "",
      msgSenario: "",
      message: "",
      topic: "",
      set_password: {},
      lang: "",
      switch1: true,
      scenario: false,
      content: {},
      delay: "1",
    };
  },
  mqtt: {
    mqttAction(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        clearTimeout(timeout);
        connection = true;
        this.showGetMsgRelay(String(data));
      
    }
    },
    "+/+/receive/256/scenario"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsgScenario(String(data));
      }
    },
  },
  created() {
    this.lang = this.$i18n.locale
    this.main_admin = this.$store.getters.getadmin;
    let data = {
       listDevice: true,
       refresh: true,
       main_admin: this.main_admin,
      

    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ListDevice');
  },
  mounted() {
    try {
      let self = this;
      this.cmpShow = true;
      this.$store.dispatch("infoHome");
      this.$store.dispatch("infoUser");
      this.$store.dispatch("infoScenarioLocal");
      this.txtTitleSenarioEmpty = this.$t(
        "message.scenario.empty_title_scenario"
      );
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      setTimeout(function () {
        self.lastStatus(user_id, token);
        self.lastStatusGadgets(user_id, token);
      }, 100);
    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: this.$t("message.ListDevice.err_process"),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: this.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({ name: "mainpage" });
            swal.close();
          }
        }
      );
    }
  },
  computed: {
    maxHeight() {
      return 'fit-content'
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    mqttAction(){
     return getMtt.action;
    },
    addItem(item) {
      const removed = this.items.splice(0, 1)
      this.items.push(
        ...this.more.splice(this.more.indexOf(item), 1),
      )
      this.more.push(...removed)
      this.tab = item.tab
    },
    onClickChild(val) {
      if (val === "setting") {
        if (this.type_code === 7) {
          this.goSetting()
        } else if (this.type_code === 9) {
          this.goSettingIR()
        }
      }
      if (val === "back") {
        this.$router.push({ name: "mainpage" });
      }
      if (val === "add") {
        this.$router.push({
          name: "homeAddDevice",
          params: { home_id: this.home_id },
        });
      }
      if (val === "refresh") {
        location.reload();
      }
    },
    cancel() {
      this.lastStatus(localStorage.getItem("user_id"), localStorage.getItem("token"));
    },
    back() {
      this.$router.push({ name: "mainpage" });
    },
    clickSettingRooms() {
      this.$router.push({
        name: "settingRoom",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    gotoRoom(index, room) {

      localStorage.setItem("RoomIndex", JSON.stringify({
        index: index,
        room: room
      }));
      this.$router.push({
        name: "RoomInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          uid: room.uid,
        },
      });
    },
    getInfo() {
      if (localStorage.getItem("counts")) {
        let counts = JSON.parse(localStorage.getItem("counts"));
        let temp = [];
        let temp2 = [];
        let counter = 0;
        for (const item in counts) {
          let i = 0;
          for (const item2 in this.infoSub) {
            if (this.infoSub[item2].count == counts[item]) {
              i++;
              temp[counter] = this.infoSub[item2];
              temp2[counter] = this.infoSub[item2].count;
              this.infoSub.splice(item2, 1);
              counter++;
            }
          }
        }
        if (this.infoSub.length > 0) {
          for (const item in this.infoSub) {
            temp[counter] = this.infoSub[item];
            temp2[counter] = this.infoSub[item].count;
            counter++;
          }
        }
        this.infoSub = temp;
      }
    },
    sendItemDevice(id, name, serial, type, type_code, set_password, password, info) {
      const datadevice = {
        name: name,
        serial: serial,
        type_code: type_code,
        set_password: set_password,
        password: password,
      };

      if (type_code == 7) {
        localStorage.setItem("infoDevice", JSON.stringify(datadevice));
        this.$router.push({
          name: "outletGatewayInfo",
          params: { home_id: this.home_id, serial_number: serial },
        });
      }
      if (type_code == 9) {
        localStorage.setItem("infoDevice", JSON.stringify(datadevice));
        this.$router.push({
          name: "irGatewayInfo",
          params: { home_id: this.home_id, serial_number: serial },
        });
      }
    },
    lastStatus(user_id, token) {
      this.relayItems = [];
      this.items = [];
      this.more = [];
      let self = this;
      var data = {
        user_id: user_id,
        token: token,
        home_id: this.home_id,
      }; 
      let URL = getUrl.roomInfo;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.info = jsonArray.content;
          self.infoRooms = jsonArray.content[0].rooms;
          self.ifMore = self.infoRooms.length > 4;
          for (let i = 0; i < self.infoRooms.length; i++) {
            for (let j = 0; j < self.infoRooms[i].relays.length; j++) {
              if (self.infoRooms[i].relays[j].relay_type_number_relay == 1) {
                
                self.infoRooms[i].relays[j].status = self.infoRooms[i].relays[j].status === "1";
               
              }

            }
            if (i === 0) self.tab = self.infoRooms[i].name
            if (i < 4)
              self.items.push({
                tab: self.infoRooms[i].name,
                content: {
                  controls: self.infoRooms[i].controls,
                  relays: self.infoRooms[i].relays,
                  subDevices: self.infoRooms[i].subDevices
                }
              })
            else
              self.more.push({
                tab: self.infoRooms[i].name,
                content: {
                  controls: self.infoRooms[i].controls,
                  relays: self.infoRooms[i].relays,
                  subDevices: self.infoRooms[i].subDevices
                }
              })
          }
          self.content = jsonArray.content[0];
          self.info_device = self.content.gateway.device;
          self.type_code = self.content.gateway.device.type.type_code;
          if (self.type_code == "9") {
            self.getSenaroList();
          }
          self.closeModalLoading();
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    goSetting() {
      var admin = this.$store.getters.getadmin;
      localStorage.setItem("sub_devices", JSON.stringify(this.infoSub));
      if (admin == 0) {
        this.$router.push({
          name: "settingclient",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if (admin == 1) {
        this.$router.push({
          name: "settings",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            type: this.type_code,
          },
        });
      }
    },
    goSettingIR() {
      var admin = this.$store.getters.getadmin;
      localStorage.setItem("sub_devices", JSON.stringify(this.infoSub));
      if (admin == 0) {
        this.$router.push({
          name: "SettingClientIR",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if (admin == 1) {
        this.$router.push({
          name: "settingsir",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            type: this.type_code,
          },
        });
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    clickAddScenario() {
      localStorage.setItem("flagEditScenario", "Add");
      localStorage.setItem(
        "infoScenarioLocal",
        JSON.stringify(this.localScenariodata)
      );
      this.$router.push({
        name: "ScenarioAddList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickAddSubEmpty() {
      this.$router.push({
        name: "homeAddDevice",
        params: { home_id: this.home_id },
      });
    },
    getSenaroList() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      self.senarios = [];
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarios;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            let item;
            for (item in jsonArray.content.scenarios) {
              self.senarios.push(jsonArray.content.scenarios[item].scenario);
              self.senarios[item].set_password = jsonArray.content.scenarios[item].set_passcode;
            }
          } else {
            //error
          }
        },
        //----------------------Err ---------------------//
        function (errr) {
        }
      );
    },
    runScenario(count, msg, set_password) {
      this.topic = getTopics.getTopicScenarioSend(
        this.serial_number,
        "256"
      );
      this.message = "SCENARIO" + count + msg + "#";
      if (set_password == "1") {
        this.scenario = true;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.message, this.topic, '');
      }
    },
    sendMessage(message, topic, passcode) {
      this.scenario = false;  // close modal pass code
      let self = this;
      this.onSubScribeSecnario();
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      this.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic,
        message: message,
        password: passcode,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      showToast = 1;
      // self.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          var jsonArray = JSON.parse(response.responseText);

          setTimeout(function () {
            // self.exitModalLoading();

            if (!connection) {
              if (showToast == 1) {
                // ErrorToastMsg.Msg();
                self.closeModalLoading();
              }
              setTimeout(function () {
              }, 3000);
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          // self.lastStatusMqtt(token, user_id);
          if (response.status == 401) self.$router.push({ name: "home" });
          //error
        }
      });
    },
    editScenario(msg, actions, index) {
      let self = this;
      localStorage.setItem(
        "infoScenarioLocal",
        JSON.stringify(this.localScenariodata)
      );
      this.localScenariodata = JSON.parse(actions);
      localStorage.setItem(
        "infoScenarioLocal",
        JSON.stringify(this.localScenariodata)
      );
      this.$router.push({
        name: "SenarioAddList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickSettingScenario() {
      this.$router.push({
        name: "ScenarioList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    clickSettingRelay() {
      this.$router.push({
        name: "subboxrelay",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    showGetMsgRelay(msg) {
      let self = this
      this.items.forEach(function (item, i) {
      self.parseMsgRelay(msg);
      // }
      });
      if (showToast == 1) {
        var running = msg.includes("RUNNING");
        if (running) {
          ToastCustom.custom(
            this.$t("message.scenario.toast_success_scenario"),
            "#28a745",
            "#fff"
          );
          connection = false;
        }
      }
      this.closeModalLoading();
    },
    showGetMsgScenario(msg) {
      if (showToast == 1) {
        var running = msg.includes("RUNNING");
        if (running) {
          ToastCustom.custom(
            this.$t("message.scenario.toast_success_scenario"),
            "#28a745",
            "#fff"
          );
          connection = false;
        }
      }
      this.closeModalLoading();
    },
    onSubScribeSecnario() {
      var topic1 = getTopics.getTopicScenarioReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    onSubScribeRelay(sub_serial) {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        sub_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    sendReq(password) {
      if (this.scenario)
        this.sendMessage(this.message, this.topic, password);
      else
        this.sendRequestRelay(this.message, this.topic, password);
    },
    sendRequestRelay(message, topic, password) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.isModalVisible = true;
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              if (showToast == 1) {
                // ErrorToastMsg.Msg();
                self.lastStatus(user_id, token);
              }
            }
            showToast = 0;
            connection = false;
          }, 5000);
        } else {
          self.lastStatus(user_id, token);
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    parseMsgRelay(message) {
      const relayMg = String(message).split("");
      this.items[0].content.relays.forEach(function (item, i) {
        var num0 = item.relay_numbers[0];
        var num1 = item.relay_numbers[1];
        if (item.relay_numbers.length > 1) {
          var close = relayMg[--num0];
          var open = relayMg[--num1];
          if (parseInt(open)) {
            item.status = 0;
          } else if (parseInt(close)) {
            item.status = 1;
          } else if (!parseInt(open) && !parseInt(close)) {
            item.status = 2;

          }
          // }

        } else if (item.relay_numbers.length == 1) {
          if (relayMg[--num0] == 0) item.status = false;
          else item.status = true;
          // }

        }
      });
    },
    lastStatusGadgets(user_id, token) {
      let self = this;
      var data = {
        user_id: user_id,
        token: token,
        home_id: this.home_id,
      };
      let URL = getUrl.homeInfo;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.info = jsonArray.content;
          self.infoSub = jsonArray.content[0].sub_device;
          self.relays = jsonArray.content[0].relays;
          self.relays.forEach(function (item, i) {
            self.onSubScribeRelay(item.serial);
            var status = false;

          });

          self.closeModalLoading();
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
  },
 
};
</script>
<style scoped>
.v-btn--fab.v-size--default {
  width: 40px;
  height: 40px;
}

.data {
  overflow-x: scroll;
  display: flex;
}

.v-sheet.v-card {
  border-radius: 15px;
  box-shadow: none !important;
}

.gradient {
  background: linear-gradient(45deg,
      rgba(0, 105, 173, 1) 0%,
      rgb(180, 216, 248) 100%);
}

.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__next,
.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__prev {
  display: none !important;
}
</style>
