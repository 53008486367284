<template>
  <v-app class="bg">
      <section class="content">
        <div class="col-12" >
          <div style="text-align: center; margin-top: 50px">
            <img
                src="/assets/images/smart/vector-verify.svg"
                style="width: 200px; height: 200px"
                alt="Verify"
            />
            <h6 class="p-30" id="txtmobileloginverify">
              {{ $t('message.loginEmail.register.registerAccount') }}
             
            </h6>
          </div>
          <br/>
        </div>
        <div class="col-12">
          <div class="box">
            <div class="box-body">
              <form novalidate>
                <h6 class="mb-20 font-weight-bold">
                  {{ $t('message.loginEmail.account') }}
                </h6>
                <div class="form-group">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-email-outline"></i
                          ></span>
                    </div>
                    <input
                        v-model="email"
                        class="form-control pl-15"
                        required
                        :placeholder="$t('message.loginEmail.inputEmail')"
                        autocomplete="off"
                    />
                  </div>
                </div>
             
                <div class="row">
                  <div class="col-12 text-center">
                    <button
                        type="button"
                        id="submitButton"
                        class="btn btn-info btn-block"
                        @click="RegisterVerifyFunction"
                    >
                      {{ $t('message.public.submit') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
     <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import {
  EmailValidationInput,
} from "../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";


export default {
  // components:{
  //   ModalCenter,

  // },
  props: {
    id: {},
    new_user: {},
    click: {},
  },
  data: () => ({
    isModalVisible:false,
    email:""
  }),
  mounted() {
    let details = {
      title: this.$t('message.loginEmail.login_with_email'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'RegisterEmail');

    //--------------------------------------  storage --------------------------------------//

    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
  },
  watch:{
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({name: "loginEmail"})
      }
    },
    RegisterVerifyFunction() {
      // this.$router.push({
      //   name: "registerEmailVerify",
      // });
      let self = this;
     
        if (!EmailValidationInput(this.email))
        return;

      var data = {
       email: this.email,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.registerEmail;
      const p = postAjaxCall(URL, data);
      this.openModal();
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModal();

              let jsonArray = JSON.parse(response.responseText);
              // console.log('jsonArray',jsonArray)
              self.$router.replace({
                name: "registerEmailVerify",
                params: {
                  id: jsonArray.content.verify_id,
                  new_user: jsonArray.content.new_user,
                },
              });
            } else {
              self.closeModal();
            }
          },
          //--------------------------------------Err --------------------------------------//

          function (errr) {

            setTimeout(function () {
              self.closeModal();
            }, 1000);
          }
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
     this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
</style>
