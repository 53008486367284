/* eslint-disable no-console */
'use strict';
import {register} from 'register-service-worker'

register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {scope: process.env.BASE_URL},
    ready() {
        // console.log(
        //     'App is being served from cache by a service worker.\n' +
        //     'For more details, visit https://goo.gl/AFskqB'
        // )
    },
    registered() {
        // console.log('Service worker has been registered01.')
    },
    cached() {
        // console.log("cached")
    },
    updatefound() {
        // console.log('New content is downloading.')
        window['isUpdateAvailable']
            .then(isAvailable => {
                if (isAvailable) {
                    const toast = this.toastCtrl.create({
                        message: 'New Update available! Reload the webapp to see the latest juicy changes.',
                        position: 'bottom',
                        showCloseButton: true,
                    });
                    toast.present();
                }
            });
    },
    forceSWupdate() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.update()
                }
            })
        }
    },

    offline() {
        // console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
        // console.error('Error during service worker registration:', error)
    }
})
