<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-update-sub"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <section class="error-page bg-img mt-50 rtl">
        <div class="container  px-40">
          <div class="row  align-items-center justify-content-center text-center ">
            <div class="col-lg-7 col-md-10 col-12">
              <h1 class="text-warning font-size-180 font-weight-bold error-page-title"></h1>
              <img src="/assets/images/smart/vector-delete.svg" class="avatar-smart">
              <br>
              <h4 class="text-primary mt-10"><strong>{{ $t('message.public.update') }}</strong></h4>
              <br>
              <h6 class="mt-10" style=" line-height:25px !important;">
                <strong>{{ $t('message.dialog.update_caption') }}</strong></h6>
              <v-btn
                  class="btn btn-primary bg-primary mb-5 mt-10 m-5"
                  :loading="loading"
                  :disabled="loading"
                  color="bg-primary"
                  @click="updateFunction()"
              >
                <span class="text-white"> {{ $t('message.public.update') }} </span>
              </v-btn>
              <v-btn
                  class="btn btn-dark bg-dark mb-5 mt-10 m-5"
                  @click="cancelFunction()"
              >
                <span class="text-white"> {{ $t('message.public.cancel') }} </span>
              </v-btn>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import AppBar from "../AppBar.vue";

export default {
  components: {
    AppBar,
  },
  data() {
    return {
      title: '',
      loading: false,
    };
  },
  props: {
    data_info_sub: {type: Array},
  },
  created() {
    this.title = this.$t('message.public.update')
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
   
  },
  mounted() {
    

  },
  methods: {
    onClickChild(value) {
      if (value == 'back') {
        $("#modal-update-sub").modal('hide');
      }
    },
    cancelFunction() {
      $("#modal-update-sub").modal('hide');
    },
    updateFunction() {
      this.$emit("clicked", "update");
      // let self = this;
      //--------------------------------------  variable --------------------------------------//
      // let user_id = localStorage.getItem("user_id")
      // let token = localStorage.getItem("token")
      // var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      // var home_id = this.data_info_sub[0];
      // var serial_number = this.data_info_sub[1];
      // this.loading = true;
      //--------------------------------------  data --------------------------------------//

      // var data = {
      //   user_id: user_id,
      //   token: token,
      //   serial_number: subdevice_serial

      // }
      //--------------------------------------Request --------------------------------------//
      // let URL = getUrl.subDelete;
      // const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//
     
          //--------------------------------------Err --------------------------------------//
          // function (errr) {
          // }
      // );
    },
  },
};
</script>


<style scoped>
</style>
