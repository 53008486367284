<template>
  <v-col
      sm="12"
      cols="12"
      md="6"
      lg="6"
      xl="6"
      class="d-flex p-0 align-center">
    <v-card
        width="100%"
        height="100px"
        elevation="0"
        style="border-radius: 10px"
        class="py-2 m-1 d-flex align-center"
    >
      <v-col class="d-flex align-center" cols="12">
        <v-col cols="2" class="p-0">
          <img
              src="/assets/images/smart/timer.png"
              style="width: 40px; height: 40px;"
              class="align-self-center"
          />
        </v-col>
        <v-col class="d-flex flex-column" cols="8">
          <v-row class="d-flex" style="width: 100%">
            <v-col class="d-flex flex-column px-0" cols="6">
              <p class="mt-2 text-bold" style="font-size: 13px;">{{ $t('message.timer.timer') }} {{ infos.count }}</p>
              <div class="d-flex align-center">
                <p v-if="!!infos.nameStr" class="text-bold"> {{ infos.nameStr }}</p>
                <div v-if="type === 'IR'">
                  <i v-if="!!infos.status" :class="infos.status" class="text-bold"></i>
                </div>
                <p v-else class="blue1 px-1">{{ infos.status }}</p>
              </div>
            </v-col>
            <v-col class="mt-2 d-flex flex-column align-center" cols="6">
              <div class="d-flex align-center">
                <p class="">{{ $t('message.timer.start') }}: </p>
                <p class="blue1" style="padding-left: 2px; padding-right: 2px"> {{ infos.time1 }} </p>
              </div>
              <p v-if="!!infos.name && type === 'IR'">{{ infos.name }}</p>
              <div class="d-flex" v-if="!!infos.time2 &&  infos.time2!=='25:00'">
                <p class="">{{ $t('message.timer.end') }}: </p>
                <p class="blue1 px-1"> {{ infos.time2 }} </p>
              </div>
            </v-col>
          </v-row>
          <v-row class="d-flex mt-3 mb-2">
            <div class="d-flex"
                 v-for="(info,index) in daysdata"
                 :key="index"
            >
              <p
                  style="margin-right: 3px; margin-left:3px"
                  :class="{'blue2': (onData[index] ==='1'),'bla': (onData[index] ==='0') }"
              >
                {{ $t('message.timer.' + info.value) }}
              </p>
            </div>
          </v-row>
        </v-col>
        <v-col class="align-center" cols="2" align="center">
          <v-menu bottom left v-if="menuShow">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in items" :key="i" style="width: fit-content; cursor: pointer"
                           @click="deleteTimer(infos.count)">
                <v-list-item-icon class="px-0">
                  <v-icon size="20px">
                    ti-trash
                  </v-icon>
                  <label style="margin-bottom:0; font-size: 14px;" class="px-2"> {{
                      item.name
                    }}</label>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import {listOfDays} from "../../../../../public/assets/js/utils/timer_parser";

export default {
  name: "TimerItem",
  props: {
    infos: {},
    type: {}
  },
  data() {
    return {
      lang: '',
      daysdata: [],
      onData: [],
      menuShow: {
        default: true
      },
      items: []
    }
  },
  created() {
    this.items = [
      {
        name: this.$t('message.public.delete'),
      }
    ]
    this.daysdata = listOfDays();
    this.lang = localStorage.getItem("Lang");
    let select = localStorage.getItem("startOfWeek")
    switch (select) {
      case "0":
        this.onData = [
          this.infos.day6,
          this.infos.day7,
          this.infos.day1,
          this.infos.day2,
          this.infos.day3,
          this.infos.day4,
          this.infos.day5,
        ];
        break;
      case "1":
        this.onData = [
          this.infos.day7,
          this.infos.day1,
          this.infos.day2,
          this.infos.day3,
          this.infos.day4,
          this.infos.day5,
          this.infos.day6,
        ];
        break;
      case "2":
        this.onData = [
          this.infos.day1,
          this.infos.day2,
          this.infos.day3,
          this.infos.day4,
          this.infos.day5,
          this.infos.day6,
          this.infos.day7,
        ];
        break;
    }
  },
  methods: {
    deleteTimer(countTimer) {
      this.$emit('deleteTimer', countTimer);
    }
  }
}
</script>

<style scoped>
@media only screen and (max-width: 960px) {
  .blue1 {
    padding: 0;
  }
}

.blue1 {
  color: #0F6BB9;
}

.blue2 {
  font-size: 12px;
  background-color: #0F6BB9;
  border-radius: 25px;
  color: #fff;
  width: 25px;
  height: 17px;
  text-align: center;
}

.bla {
  font-size: 12px;
  background-color: lightgray;
  border-radius: 25px;
  color: #0f1017;
  width: 25px;
  height: 17px;
  text-align: center;
}

p {
  margin-bottom: 4px !important;
}
</style>