<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="box-body chart-responsive">
        <div style="height: 812px">
          <section class="content">
            <!-- row -->
            <div class="media-list media-list-divided media-list-hover">
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickManage"
              >
                <i class="mdi mdi-account-settings fa-2x"></i>
                <div class="media-body">
                  <p>{{ $t('message.client.management') }}</p>
                </div>
              </div>
              
              <div
                  class="media align-items-center"
                  v-on:click.prevent="ClickDelHome"
              >
                <i class="mdi mdi-delete-forever fa-2x"></i>
                <div class="media-body">
                  <p>{{ $t('message.setting.del_home') }}</p>
                </div>
              </div>
            </div>

            <!-- /.row -->
          </section>
          <!-- /. Main content -->
        </div>
      </div>
    </div>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      // serial: this.serial_number,
      homeid: [this.home_id],
      serialnumber: this.serial_number,
      title: '',
      type: 7,
    };
  },
  mounted() {
    this.$store.dispatch("infoHome");
    this.title = this.$t('message.public.settings');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingClient');
    var row_init_pass = document.getElementById("row_init_pass");
    var row_change_pass = document.getElementById("row_change_pass");
    var password = localStorage.getItem("password");
    if (password == 1) {
      row_init_pass.style.display = "none";
    }
    if (password == 0) {
      row_change_pass.style.display = "none";
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    ClickSelection() {
      localStorage.setItem('selection', this.type + "g")
      this.$router.push({
        name: "selection_drag",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type_code: this.type,
        }
      },)
    },
    clickRelays() {
      this.$router.push({
        name: "subboxrelay",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    ClickDelHome() {
      // $("#modal-client-del-home").modal('show');
      this.$router.push({
        name: "clientdelhome", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickManage() {
      this.$router.push({
        name: "managementclientclientinfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({name: "homeDevices", params: {home_id: this.home_id, serial_number: this.serial_number},});

      }
    }
  },
};
</script>


<style scoped>
</style>
