<template>
  <v-app class="bg m-0">
    <section class="error-page bg-img h-p100 rtl">
      <div class="container h-p100 px-40">
        <div
            class="
            row
            h-p100
            align-items-center
            justify-content-center
            text-center
          "
        >
          <div class="col-lg-7 col-md-10 col-12">
            <h1
                class="
                text-warning
                font-size-180 font-weight-bold
                error-page-title
              "
            ></h1>
            <img
                src="/assets/images/smart/vector-houses.svg"
                class="avatar-smart"
            />
            <br/>
            <h4 class="text-danger mt-10"><strong>{{$t('message.public.warning')}}</strong></h4>
            <br/>
            <h6 class="mt-10" style="line-height: 25px !important">
              <strong
              >{{ $t('message.client.del_home') }}
              </strong
              >
            </h6>
            <h6>{{ $t('message.client.if_del_home') }}</h6>
            <button
                id="delhomeclient"
                type="button"
                class="btn btn-danger mb-5 mt-10 m-5"
                v-on:click.prevent="DelFunction()"
            >
              {{ $t('message.public.delete') }}
            </button>
            <button
                type="button"
                class="btn btn-dark mb-5 mt-10 m-5"
                v-on:click.prevent="CancelFunction()"
            >
              {{ $t('message.public.cancel') }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <!-- /. Main content -->
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{$t('message.ModalCenter.loading')}}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{$t('message.ModalCenter.loading')}}</span>
            </div>
            <span class="pl-20" id="txtwait">{{$t('message.ModalCenter.wait')}}</span>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {},

  data() {
    return {
      title: '',
    };
  },

  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  mounted() {
    this.title= this.$t('message.client.delete_home');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingClientDelHome');

    this.btnLoading();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "settingclient",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
    CancelFunction() {
      this.$router.replace({
        name: "settingclient",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    DelFunction() {
      //--------------------------------------  variable --------------------------------------//
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      this.$store.dispatch("infoUser");
      var mobile = this.$store.getters.getUserPhone;
      //--------------------------------------  data --------------------------------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        client_mobile: mobile,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.clinetDel;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              // self.exitModalLoading();
              var varify = jsonArray.content.admin_verify_id;
              self.$router.replace({
                name: "clientdelhomeverify",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                  verify: varify,
                },
              });
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    btnLoading() {
      var $btn = $("#delhomeclient");
      $btn.click(function () {
        var $this = $(this);
        $this
            .attr("disabled", "disabled")
            .html(
                `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
            );
        setTimeout(function () {
          $this.removeAttr("disabled").html(this.$t('message.public.submit'));
        }, 2000);
      });
    },
  },
};
</script>
<style scoped>
</style>
