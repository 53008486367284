<template>
  <div class="per-style">
    <div v-if="typecode == '1001'">
      <CmpControlTv
          @clicked="getTvControlCmpCallback"
          :data_list="listactive"
          :mode="'Test'"
      ></CmpControlTv>
    </div>
    <div v-if="typecode == '1002'">
      <CmpControlAmplifier
          @clicked="getAmpliControlCmpCallback"
          :data_list="listactive"
          :mode="'Test'"
      ></CmpControlAmplifier>
    </div>
    <div v-if="typecode == '1003'">
      <div v-bind:key="index" v-for="(airKey, index) in airList">
        <CmpRowAir :info="airKey" @clicked="CmpControlAir"></CmpRowAir>
      </div>
    </div>
    <div v-if="typecode == '1001' || '1002'">
      <CmpTips
          :data_txt="tips_txt"
          :data_type="'default'"

      ></CmpTips>
    </div>
    <div v-else-if="typecode == '1003'">
      <CmpTips
          :data_txt="tips_txt_air"
          :data_type="'default'"
      ></CmpTips>
    </div>
  </div>
</template>
<script>
import CmpRowAir from "@/components/Cmp/ir/CmpRowAir.vue";
import CmpControlTv from "../../ir/CmpControlTv.vue";
import CmpControlAmplifier from "../../ir/CmpControlAmplifier.vue";
import {getCommand} from "../../../../public/assets/js/utils/get_ir_gatway_command.js";
import CmpTips from "../../Cmp/public/CmpTips.vue"
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    CmpControlTv,
    CmpControlAmplifier,
    CmpRowAir,
    CmpTips,
  },
  props: {
    home_id: {},
    serial_number: {},
    typecode: {},
    count: {},
    click: {},
  },
  data() {
    return {
      title: "",
      tips_txt: "",
      tips_txt_air: "",
      localScenariodata: {
        name: "",
        logo: "",
        actions: [],
      },
      listactive: [
        {
          id: 0,
          active: false,
          code: "",
        },
        {
          id: 1,
          active: false,
          code: "",
        },
        {
          id: 2,
          active: false,
          code: "",
        },
        {
          id: 3,
          active: false,
          code: "",
        },
        {
          id: 4,
          active: false,
          code: "",
        },
        {
          id: 5,
          active: false,
          code: "",
        },
        {
          id: 6,
          active: false,
          code: "",
        },
        {
          id: 7,
          active: false,
          code: "",
        },
        {
          id: 8,
          active: false,
          code: "",
        },
        {
          id: 9,
          active: false,
          code: "",
        },
        {
          id: 10,
          active: false,
          code: "",
        },
        {
          id: 11,
          active: false,
          code: "",
        },
        {
          id: 12,
          active: false,
          code: "",
        },
        {
          id: 13,
          active: false,
          code: "",
        },
        {
          id: 14,
          active: false,
          code: "",
        },
        {
          id: 15,
          active: false,
          code: "",
        },
      ],
      keysInfo: [],
      airList: [],
      dataAirRow: "",
      irControlInfo: [],
      flag: "",
    };
  },
  created() {
    let self = this;
    this.$store.dispatch("infoScenarioLocal");
    //  if list empty dont show  1003   for infoTRControls
    this.$store.dispatch("infoIrControls");
    this.$store.dispatch("flagEditScenario");
    this.flag = this.$store.getters.getFlag;
    this.irControlInfo = this.$store.getters.getIrControl;

    this.title = this.irControlInfo.name;

    this.keysInfo = this.$store.getters.getKeysControl;
    this.localScenariodata = this.$store.getters.getScenarioLocal;
    this.tips_txt = this.$t("message.scenario.hint_scenario_controls");
    this.tips_txt_air = this.$t("message.scenario.hint_scenario_controls_air");
    //  if list empty dont show  1003   for infoTRControls

    if (this.typecode == "1003") {
      let mode = "";
      let fan = "";
      let temp = "";
      let swimH = "";
      let swimV = "";
      let power = "";
      let key_count = "";

      this.keysInfo.forEach(function (item) {
        if (item.value.split("POWER$").pop().split("-")[0] == "OFF") {
          mode = "----";
          fan = "----";
          temp = "----";
          swimH = "----";
          swimV = "----";
          power = item.value.split("POWER$").pop().split("-")[0];
          key_count = item.key_count;
        } else {
          mode = item.value.split("MODE$").pop().split("-")[0];
          fan = item.value.split("FAN$").pop().split("-")[0];
          temp = item.value.split("TEMP$").pop().split("-SWING")[0];
          swimH = item.value.split("SWING$H").pop().split("-")[0];
          swimV = item.value.split("SWING$H").pop().split("-")[0];
          power = "ON";
          key_count = item.key_count;
        }

        self.airList.push({
          mode: mode,
          fan: fan,
          temp: temp,
          swimH: swimH,
          swimV: swimV,
          power: power,
          key_count: key_count,
        });
      });

      this.airList.forEach(function (item) {
      });
    }
    if (this.typecode == "1001" || this.typecode == "1002") {
      this.keysInfo.forEach(function (item) {
        var key = item.key_count;
        for (let i = 0; i <= 15; i++) {
          if (i == key) {
            self.listactive[i].active = true;
            if (item.key_count !== null) {
              self.listactive[i].code = item.key_count;
            }
          }
        }
      });
    }
  },
  mounted() {
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'GetScenarioControlStr');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back')
        this.$router.push({
          name: "SenarioDevices",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
    },
    getTvControlCmpCallback(msg) {
      if (msg !== "") {
        this.makeSenarioStr(msg);
      }
    },
    getAmpliControlCmpCallback(msg) {
      if (msg !== "") {
        this.makeSenarioStr(msg);
      }
    },
    CmpControlAir(msg) {
      this.dataAirRow = msg;
      this.makeSenarioStr(msg.key_count);
    },
    makeSenarioStr(msg) {
      let self = this;
      var message = getCommand.getCommandIrScenario(
          this.serial_number,
          this.count,
          msg
      );

      this.localScenariodata.actions.push({
        name: this.$store.getters.getIrControl.name,
        serial: this.serial_number,
        type_code: this.typecode,
        key_count: this.count,
        path_logo: this.irControlInfo.path_logo,
        key_value: msg,
        status: this.dataAirRow,
        msg_send: message,
      });

      localStorage.setItem(
          "infoScenarioLocal",
          JSON.stringify(this.localScenariodata)
      );
      if (this.flag == "Edit") {
        this.$router.push({
          name: "ScenarioEditList",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
      if (this.flag == "Add") {
        this.$router.push({
          name: "ScenarioAddList",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
    },
  },
};
</script>
