import i18n from "../../../../src/i18n";
export function getKeyTouchStatus(message,version) {
  console.log("message",message)
    var pol='';
    var status='';
    if(version=='V1'){
        if(message.substr(message.indexOf("K") + 2, 1)=='T'){
            status=i18n.t('message.public.on');
        }
        else{
            status=i18n.t('message.public.off');
        }
        switch(message.substr(message.indexOf("K") + 1, 1)) {
            case '1':
               pol=i18n.t('message.subDevice.keytouch.pol1');
              break;
            case '2':
                pol=i18n.t('message.subDevice.keytouch.pol2');
              break;
            case '3':
                pol=i18n.t('message.subDevice.keytouch.pol3');
              break;
            case '4':
                pol=i18n.t('message.subDevice.keytouch.pol4');
              break;
            case '5':
                pol=i18n.t('message.public.all');
              break;
            default:
              // code block
          }
      return pol+Array(1).fill('\xa0').join('')+':'+status;   
    } 
}