import { render, staticRenderFns } from "./EditRGBPro.vue?vue&type=template&id=f3c3bf36&scoped=true"
import script from "./EditRGBPro.vue?vue&type=script&lang=js"
export * from "./EditRGBPro.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3c3bf36",
  null
  
)

export default component.exports