import { render, staticRenderFns } from "./Relay4TimerShow.vue?vue&type=template&id=228c3b17&scoped=true"
import script from "./Relay4TimerShow.vue?vue&type=script&lang=js"
export * from "./Relay4TimerShow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228c3b17",
  null
  
)

export default component.exports