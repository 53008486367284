<template>
  <div style="background-color:#0f1017;position:fixed!important;width:100%;height:100%">
    <img v-if="appMode=='farahoush'" :src="'/assets/images/smart/logogif.gif'" width="400px" height="100%" alt="Farahoush"/>
    <img v-else :src="'/assets/images/smart/splashbmsx.jpg'" width="400px" height="100%" alt="bmsx"/>
  </div>
</template>
<script>

import LayoutNoAppBar from "../layouts/LayoutNoAppBar";

export default {
  data: () => ({
    appMode:'farahoush'
  }),
  created() {
    this.$emit('update:layout', LayoutNoAppBar);
  },
  mounted() {
    let self = this;
    localStorage.setItem("log", 0);
    localStorage.setItem("t_app", 0);
    localStorage.setItem('Lang', this.$i18n.locale)
    this.appMode=process.env.VUE_APP_MODE
    setTimeout(function () {
      let login_id = localStorage.getItem("login_id");
      let id_code_security = localStorage.getItem("id_code_security");

      if (login_id == null || login_id == "") {
        self.$router.push({name: "welcome"});
      } else if (login_id == 1) {
        if (id_code_security == 1) {
          self.$router.push({name: "login_security"});
        } else {
          self.$router.push({name: "mainpage"});
        }
      }
    }, 1500);
  },
  methods: {},
};
</script>
<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .responsive {
    background: brown;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-color: brown;
  }
}
</style>
