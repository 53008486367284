<template>
  <!-- <v-app class="bg"> -->
    <!-- <section class="content"> -->
          <Timer :data="data" @next="onClick"/>
    <!-- </section> -->
  <!-- </v-app> -->
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

let status_checkbox = "TF";

export default {
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {}
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.subDevice.thermostat.thermostat'),
        status: false
      }],
      type: "thermostat"
    }
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  mounted() {
    this.title = this.$t('message.timer.scheduler')
    this.$store.dispatch("InfoSubDevice");
    let status_checkbox = "TF";
    localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      this.next1(status);
    },
    next1(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "TT";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      } else if (!status) {
        status_checkbox = "TF";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "ThermostaticProStep2Timer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    goBackTimer() {
      this.$router.push({
        name: "ThermostaticProTimerShow",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
        },
      });
    }
    ,
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "ThermostaticProTimerShow",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    }
    ,
  }
  ,
};
</script>


<style scoped>
</style>
