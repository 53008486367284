<template>
  <v-app class="bg">
    <Step2Timer :end="false" :daysData="daysdata" @myFunction="getCount"/>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import Step2Timer from "@/components/Cmp/timer/create/Step2Timer.vue"
import {listOfDays} from "../../../../../public/assets/js/utils/timer_parser";
import {BetweenStr, ErrorToastMsg, Tools} from "../../../../../public/assets/js/utils/util";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {DayValidationInput} from "../../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var showToast;
let timer_count;
var connection = false;
var send = false;

export default {
  name: "BoxRelayStep2Timer",
  components: {
    Step2Timer
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.daysdata = listOfDays();
  },
  data() {
    return {
      lang: '',
      title: "",
      time1: Tools.getNow(),
      time2: Tools.getNow(),
      daysdata: [],
      days: [],
      temparray: [],
      selectedday: "",
      isModalVisible: false
    };
  },
  props: {
    serial_number: {},
    click: {}
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler')
    this.$store.dispatch("statusTimer");
    //------subscribe
    var topic1 = getTopics.getTopicTimerReceive(
        this.serial_number,
        256
    );
    this.$mqtt.subscribe(topic1);
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer3');
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) {
      console.log("data", String(data))
      connection = true;
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == this.sub_serial
      ) {
        if (topic.split("/")[4] === "timer") {
          this.exitModalLoading();
          if (showToast == 1) {
            Tools.sweetAlert(this.$t('message.timer.timer'), this.$t('message.timer.added'), 'success', 3000)
          }
        }
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    MyFunction(days, time1) {
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var timer_status = this.$store.getters.getTimerStatus;
      if (
          !DayValidationInput(days.length)
      )
        return;
      var msgday = this.getDaySelected(days);
      console.log(timer_count)
      message =
          "TIMER" +
          timer_count +
          "-" +
          timer_status +
          "-" +
          msgday +
          time1 +
          "#";

      var topic_final = getTopics.getTopicTimerSend(
          this.serial_number,
          256);
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data, "");
      showToast = 1;
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              timer_count = jsonArray.content.timer_count;
              setTimeout(function () {
                if (connection) {
                  self.exitModalLoading();
                  self.$router.push({
                    name: "nRelayTimerShow",
                    params: {serial_number: self.serial_number},
                  });
                }
                if (!connection) {
                  self.exitModalLoading();
                  console.log("connection", connection)
                  if (showToast == 1) {
                    console.log("connection", connection)
                    ErrorToastMsg.Msg();
                    self.$router.push({
                      name: "nRelayTimerShow",
                      params: {serial_number: self.serial_number},
                    });
                  }
                }
                showToast = 0;
                connection = false;
              }, 5000);
            } else {
              //error

              self.exitModalLoading();
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getCount(days, time1) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var tapp = localStorage.getItem("t_app");

      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneTimerCount;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              timer_count = jsonArray.content.timer_count;
              send = true;
              self.MyFunction(days, time1);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    getDaySelected(days) {
      this.selectedday = "";
      var check = false;
      for (var i of this.daysdata) {
        this.temparray.push(i);
      }
      this.temparray.sort((firstItem, secondItem) => firstItem.sort - secondItem.sort);
      this.temparray.forEach((element) => {
        days.forEach((values) => {
          if (values.value == element.value) check = true;
        });
        if (check) this.selectedday += element.value + "1" + "-";
        else this.selectedday += element.value + "0" + "-";
        check = false;
      });
      return this.selectedday;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "nRelayTimer",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
  }
}
</script>

<style scoped>

</style>