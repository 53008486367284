<template>
  <div class="wrapper mr-0">
    <div class="content-wrapper mr-0">
      <div class="box-body chart-responsive">
        <div style="height: 812px"></div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </div>
</template>

<script>
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  data: function () {
    return {
      types: '',
      lang: "",
      isModalVisible: false
    };
  },
  props: {
    home_id: {},
    serial_number: {},
    type: {},
  },

  mounted() {
    let self = this;
    this.openModalLoading();
    this.types = this.$store.getters.getTypeGatway;
    setTimeout(function () {
      self.sendClock();
    }, 500);
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  methods: {

    sendClock() {
      let self = this;
      var topic_final;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var year = parseInt(new Date().getFullYear().toString().substr(2, 2));
      var month = parseInt(new Date().getMonth() + 1);
      var date = parseInt(new Date().getDate());
      var d = parseInt(new Date().getDay());
      var hours = parseInt(new Date().getHours());
      var minute = parseInt(new Date().getMinutes());
      var second = parseInt(new Date().getSeconds());

      var formattedmonth = ("0" + month).slice(-2);
      var formatteddate = ("0" + date).slice(-2);
      var formattedhours = ("0" + hours).slice(-2);
      var formattedminute = ("0" + minute).slice(-2);
      var formattedsecond = ("0" + second).slice(-2);
      if (d == 0) {
        d = d + 7;
      }

      let datefinal = year + "/" + formattedmonth + "/" + formatteddate + "/" + d;
      let timefinal =
          formattedhours + ":" + formattedminute + ":" + formattedsecond;

      let saction = "CLOCK" + datefinal + "," + timefinal + "#";

      var topic_final = getTopics.getTopicClockSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");

      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            setTimeout(function () {
              self.exitModalLoading();


              if (self.types == 7) {
                self.$router.push({
                  name: "settings", params: {home_id: self.home_id, serial_number: self.serial_number, type: self.types},
                });
              }
              if (self.types == 9) {
                self.$router.push({
                  name: "settingsir",
                  params: {home_id: self.home_id, serial_number: self.serial_number, type: self.types},
                });
              }
              

            }, 3000);
            swal({
              title: self.$t('message.irAir.settings'),
              text: self.$t('message.noneHome.clock_ok'),
              type: "success",
              timer: 2000,
              showConfirmButton: false
            });

          },
          function (errr) {

          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>


<style scoped>
</style>
