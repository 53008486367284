<template>
  <v-app class="bg">
    <div class="mr-0" >
      <v-container>
      <v-col cols="12" md="5" class="py-0 d-flex" style="margin:auto;">
        <img style="display: block; margin:auto; height:100%; width: 100%"
             :src="'/assets/images/smart/elevator_door.jpg'">
      </v-col>
      <v-card>
        <v-row class="rounded-16 mt-5">
          <v-col class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 px-5 rounded">
              <img
                  src="/assets/images/smart/sub_elavator.svg"
                  alt="smart"
                  width="32px"
                  height="32px"
              />
              <div class="media-body">
                <h6>{{ $t('message.relay.type.elevator') }}</h6>
              </div>
              <v-btn   
                  :loading="loadingInstant"
                  :disabled="loadingInstant"
                  color="bg-primary"
                  fab
                  @click="clickElvator()"
              >
                <v-icon class="text-white">
                  mdi mdi-swap-vertical
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <!-- <ModalOutletMomentray
      v-bind:serials="serial_number"
      :v-bind:subdevice_serial="subdevice_serial"
      v-bind:delay_elvator="delay_elvator" 
      type="elevator"
      @set_delayEl="SetDelayEl"
    ></ModalOutletMomentray> -->
    <!-- <ModalResetFactoryElevator :infoItem="dataReset"></ModalResetFactoryElevator>
    <SubModalDel v-bind:data_info_sub="data_info_curtain"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_curtain"></SubModalEdit>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_curtain"
    ></SubModalSetPass> -->
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {ErrorToastMsg, SucessToastMsg} from "@/../public/assets/js/utils/util";
import {getTopics} from "@/../public/assets/js/utils/topics";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import {Color, hexToRgb} from "@/../public/assets/js/utils/colorRGB";
import {Solver} from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
// import SubModalDel from "../../subdevice/SubModalDel";
// import SubModalEdit from "../../subdevice/SubModalEdit";
// import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets} from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryElevator from "../../subdevice/elevatorpro/ModalResetFactoryElevator";
// import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import {getMessageSub} from "@/../public/assets/js/utils/helper_sub_message.js";
import {passHomeValidationInput} from "@/../public/assets/js/utils/error_handler";
import { getMessageElavator } from "@/../public/assets/js/utils/elvatorParser.js";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    // SubModalDel,
    // SubModalEdit,
    // SubModalSetPass,
    CmpPasscode,
    // ModalResetFactoryElevator,
    // ModalOutletMomentray,

  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      sub_device_info:[],
      type_Selected_item:"action",
      // passkeytouchfara: "",
      // data_info_keytouch_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      // pass: true,
      // pol: 1,
      // labels: [],
      isModalVisible: false,
      loadingInstant: false,
      subdevice_serial:"",
      elevator_status: "",
      message_status: "",
      pass: 0,
      data: [],
      info: [],
      // rgbInfo:[],
      dataReset:[],
      txtStatus: '',
      // data_info_curtain: [this.home_id, this.serial_number, this.count],
     
      typeRequest:0,
      delay_elvator:'',
      set_password: 0,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));
        if (showToast == 1) {
          this.loadingInstant = false;
          SucessToastMsg.Msg();
        }
      }
 
    },
    // "+/+/receive/+/setting"(data, topic) {
    //   if (topic.split("/")[1] === this.serial_number) {
    //     connection = true;
    //     clearTimeout(timeout);
    //     this.closeModalLoading();
    //     this.showGetMsg(String(data))
    //     this.showGetMsgBroadCast(String(data));
    //     if (showToast == 1) {
    //       SucessToastMsg.Msg();
    //     }
    //   }
    // },
  },
  created() {
    let self=this;
    let data = localStorage.getItem("infoSubDevice");
    data = JSON.parse(data);
    // if (!JSON.parse(data.labels))
    //   this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
    //     pol: "3",
    //     label: "L4"
    //   }];
    // else
    //   this.labels = JSON.parse(data.labels);
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    console.log("this.data.password",this.data.password)
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
  },
  mounted: function mounted() {
   
    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      // this.pol = this.$store.getters.getPolSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;

    // this.showHidePol();
    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    console.log("info",this.info)
    this.subdevice_serial = this.info.serial_number;
    this.dataReset.push({serial_number:this.serial_number,subdevice_serial:this.subdevice_serial})
    // init run
    // try {
      if(this.$store.getters.getInfoSub.elevator_setting==null){
        this.delay_elvator='01'
      }else{
        this.delay_elvator=getMessageElavator.getMessageElvatorInstant(this.$store.getters.getInfoSub.elevator_setting);
      }
      this.lastStatus();
      // subscribe
     this.onSubscribe();
   
     
    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      sub_setting:true,
      }
      localStorage.setItem("AppBar", JSON.stringify(details));
      this.$emit('update:layout', LayoutDefault);
      this.$emit('update:update', 'SubElevatorPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    

    onSubscribe(){
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );
      // var topic2 = getTopics.getTopicSettingReceive(
      //     this.serial_number,
      //     this.subdevice_serial
      // );
      this.$mqtt.subscribe(topic1);
      // this.$mqtt.subscribe(topic2);
    },

    SetDelayEl(value) {
      if (this.set_password === 1) {
        $("#modal-outlet-momentray").modal("hide");
        $("#modal-pass-code").modal("show");
        this.typeRequest=1;
        this.delay_elvator=value
      } else {
        this.sendSettingDelay(value, "");
      }
    },
    sendSettingDelay(delay,password){
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      if (delay == null || delay === "") {
        delay = "01";
      }
      let saction = "K1:" + delay + "S#";
      var topic_final = getTopics.getTopicBroadcastSend(
        this.serial_number,
        this.subdevice_serial
      ); 
      showToast = 1;
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      console.log("data",data)
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);
          timeout = setTimeout(function () {
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            $("#modal-outlet-momentray").modal("hide");
            showToast = 0;
            connection = false;
          }, 3000);}
      });
    },
    // openFunction() {
    //   this.sendMessage("K1T#");
    //   this.typeRequest=0;
    // },
    // stopFunction() {
    //   this.sendMessage("K2T#");
    //   this.typeRequest=0;
    // },
    // closeFunction() {
    //   this.sendMessage("K3T#");
    //   this.typeRequest=0;
    // },
    clickElvator() {
         this.typeRequest=0;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
        this.message_status="K1T#"
      } else {
        this.sendMessage('K1T#')
      }
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
          function (response) {
            self.closeModalLoading();
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              // ****** code save data in subdevice
              self.sub_device_info.push({
                  subDevices: jsonArray.content.device_info
              })
              localStorage.setItem("infoSubDevice",JSON.stringify(self.sub_device_info[0].subDevices))
               // ****** code save data in subdevice
             self.parseStatusCurtain(message);
            } else {
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function () {
            // console.log("err", errr);
          }
      );
    },

    sendMessage(status) {
     
      this.elevator_status=status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.elevator_status, '');
      }
    },
    sendReq(password) {
      console.log("this.typeRequest",this.typeRequest)
    
      if(this.typeRequest==0){
        this.sendRequest(this.message_status, password);
        }
      else if(this.typeRequest==1){
        this.sendSettingDelay(this.delay_elvator,password);
      }
    },
    sendRequest(message, password) {

      console.log("message injaaaaaaaaaaaaaaaaaaaaaaaaa",message)
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final="";

    
      this.type_Selected_item=='update'?
      topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial):
      topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      console.log("topic_final",topic_final)
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      console.log("data",data)
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.onSubscribe();
             if (this.set_password == 1) {
               $("#modal-pass-code").modal("show");
               this.message_status="UPDATE#";
               this.type_Selected_item="update";
               this.typeRequest=0;
             } else {
              this.type_Selected_item="update"
              this.sendRequest('UPDATE#','');
            }  
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
  //   showGetMsgBroadCast(msg) {
  //     console.log("showGetMsgBroadCast",msg)
     
  //    this.opentime = msg.split("K1:").pop().split("S")[0];
  //    this.closetime = msg.split("K3:").pop().split("S")[0];
  //    localStorage.setItem("openTimeShutter", this.opentime);
  //    localStorage.setItem("closeTimeShutter", this.closetime);
     
  //  },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      this.parseStatusCurtain(msg)
      if(msg=="UPDATEOK"){
        swal({
        // title: self.$t('message.timer.timer'),
        title:this.$t("message.public.refresh"),
        text: this.$t("message.public.update_done"),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      }
    },
    parseStatusCurtain(msg) {
      if (msg == '1000') {
        this.txtStatus = this.$t('message.public.open')
      }
      if (msg == '0000') {
        this.txtStatus = this.$t('message.public.stop')
      }
      if (msg == '0010') {
        this.txtStatus = this.$t('message.public.close')
      }
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if(value== "sub_setting"){
        this.$router.push({
          name: "ElevatorProSetting",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      // if (value === "momentary") {
      //   $("#modal-outlet-momentray").modal("show");
      // }
      // if (value == "del") {
      //   $("#modal-del-sub").modal("show");
      // }
      // if (value == "about") {
      //   this.$router.push({
      //     name: "AboutElevatorPro",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "wifi_sub_onoff") {
      //   this.$router.push({
      //     name: "ElevatorProStatusWifi",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "subReset") {
      //   $("#modal-reset-elevatorPro").modal('show');
      // }
      // if (value == "update") {
      //   this.showUpdateDialog();
      // }
      // if (value == "editRgb") {
      //   this.$router.push({
      //     name: "ElevatorEditRGBPro",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
      // if (value == "edit") {
      //   $("#modal-edit-sub").modal("show");
      // }
      // if (value == "pass") {
      //   $("#modal-set-pass-sub").modal("show");
      // }
      // if (value == "refresh") {
      //   this.lastStatus();
      //   // location.reload();
      // }
      // if (value == "timer") {
      //   let data = {
      //     // labels: this.labels,
      //     // pol: this.pol,
      //     type: "PRO",
      //     type_code:this.info.type.type_code,
      //   }
      //   localStorage.setItem("data_edit_sub", JSON.stringify(data));
      //   this.$router.push({
      //     name: "ElevatorProTimerShow",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
  },
}
</script>

<style scoped>

</style>