import i18n from "../../../../src/i18n";

export function msgOutlet(msg, checkbox) {
    if (msg == "NOTSET") {
        checkbox.checked = true;
    }
    if (msg == "P1ON") {
        checkbox.checked = true;
    }
    if (msg == "P1OFF") {
        checkbox.checked = false;
    }
    if (msg == "P1OFF-TEMP UP") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.up',{name: i18n.t('message.RelayKey.T')}), "error");
    }
    if (msg == "P1OFF-TEMP DOWN") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.down',{name: i18n.t('message.RelayKey.T')}), "error");
    }
    if (msg == "P1OFF-AMP DOWN") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.down',{name: i18n.t('message.msg.I')}), "error");
    }
    if (msg == "P1OFF-AMP UP") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.up',{name: i18n.t('message.msg.I')}), "error");
    }
    if (msg == "P1OFF-VOLT UP") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.up',{name: i18n.t('message.RelayKey.V')}), "error");
    }
    if (msg == "P1OFF-VOLT DOWN") {
        checkbox.checked = false;
        swal("", i18n.t('message.msg.down',{name: i18n.t('message.RelayKey.V')}), "error");
    }
}