<template>
  <v-app class="bg">
    <!-- <label class="p-10 mt-5">
       {{ $t('message.subDevice.KeyTouchRgb.rgb_caption') }}
     </label> -->
<v-row class="p-10">
      <v-col cols="12">
        <form novliate style="background-color: rgb(255, 255, 255); padding:10px; border-radius: 10px ">
          <v-row>
            <v-col cols="2" >
            <label> {{ $t('message.public.on') }}</label>
            </v-col>
            <v-col cols="8"  class="text-center"> 
              <label>
                {{ $t('message.ModalAddSubDevicePol.pols') }}
              </label>
            </v-col>
            <v-col cols="2">
              <label> 
                {{ $t('message.public.off') }}
              </label>
            </v-col>
           </v-row>
          
          <div class="form-group p-1 mt-2" v-for="(name,index) in labels.length" :key="index">
           <v-row>
            <v-col cols="2" >
        
              <div @click="onColor(index)"  style="border-radius: 8px"
                   :style="{'width': '40px', 'height': '40px','padding': '10px','background-color': data.onColors[index]}">
              </div>

            </v-col>
            <v-col cols="8"> 
              <label
                  type="text"
                  class="form-control"
                  aria-invalid="false"
                  style="margin-left: 10px; margin-right: 10px;border-radius: 8px"
              >
                {{ labels[index].label  }}
              </label>

            </v-col>
            <v-col cols="2">
              <div @click="offColor(index)" style="border-radius: 8px"
                   :style="{'width': '40px', 'height': '40px','padding': '10px','background-color': data.offColors[index]}">
              </div>

            </v-col>
           </v-row>
          </div>
        </form>
        <div class="text-center mt-4">
      <v-btn color=bg-blue-gray500 @click="defaultColor()">
        <i class="mdi mdi-sword icon-color-white  rotate-90 mdi-24px"></i>
        <span style="color: white!important;"> {{ $t('message.public.default_color') }}</span>
      </v-btn>
    </div>
      </v-col>
      </v-row>
   

      <v-col
          cols="12"
          class="fixed-bottom d-flex flex-column justify-content-center align-center mt-5"
      >
        <div class="col-12 text-center ">
          <v-btn
              class="btn btn-info btn-block"
              :loading="loading"
              :disabled="loading"
              color="bg-primary"
              @click="sendColor()"
          >
            <span class="text-white"> {{ $t('message.public.submit') }} </span>
          </v-btn>
        </div>
      </v-col>
    <v-row justify="center" v-if="dialog">
      <v-dialog
          class="bg"
          v-model="dialog"
          persistent
          width="300px"
          transition="dialog-bottom-transition"
      >
      
        <v-card class="d-flex flex-column">
          <v-card-title>
            color pallet
          </v-card-title>
          <div class="d-flex flex-column">
          <v-color-picker justify="center" v-model="color"  hide-canvas show-inputs show-swatches></v-color-picker>
        </div>
          <v-card-actions>
            <v-btn color="primary" text @click="submitColor">
              <span class="text-primary">{{ $t("message.public.OK") }}</span>
            </v-btn>
            <v-btn color="primary" text @click="dialog = false">
              <span class="text-primary">{{ $t("message.public.close") }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {hexToRgb, rgbToHex} from "../../../../public/assets/js/utils/colorRGB";
import LayoutDefault from "../../../layouts/LayoutDefault";
import {BetweenStr, ErrorToastMsg} from "../../../../public/assets/js/utils/util";
import {getTopics} from "../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
// import verte from 'verte';



var connection = false;
var showToast;
var timeout;
export default {
  name: "EditRGB",
  components: {
    CmpPasscode
    // verte,
    
  },
  props: {
    serial_number: {},
    type: {},
    click: {}
  },
  data() {
    return {
    
    
      title: '',
      loading: false,
      color: '',
      labels: [{ pol: "0", label: "L1" }, { pol: "1", label: "L2" }, { pol: "2", label: "L3" }, { pol: "3", label: "L4" }],
      data: {
        onColors: [],
        offColors: [],
      },
      dialog: false,
      target: [],
      rgbInfo:[],
      isModalVisible: false,
      info:[],
      set_password: 0,
    };
  },
  mqtt: {
    "+/+/receive/+/rgb"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == 256
      ) {
        if (topic.split("/")[4] === "rgb") {
          connection = true;
          // clearTimeout(timeout);
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  created() {
    let data = {
      title: this.$t('message.public.edit') + ' ' + 'RGB',
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneHomeKeyTouchEditRGB');
  },
  mounted() {
    let self=this;
    // this.labels = localStorage.getItem("data_edit") ? JSON.parse(localStorage.getItem("data_edit")) : [];
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    this.set_password= this.info.set_password;
    if(this.info.rgb_setting==null){
    this.data = localStorage.getItem("data_color" + this.serial_number) ?
      JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
      onColors: ['#00ff00', '#00ff00', '#00ff00', '#00ff00'],
      offColors: ['#ff0000', '#ff0000', '#ff0000', '#ff0000'],
    };
    }
    else{
      this.parserRgbSetting();

      this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
      onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color,self.rgbInfo[5].color,self.rgbInfo[7].color],
      offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
    };
    }
    this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
    let topic2 = getTopics.getTopicRgbReceive(
        this.serial_number,
        256
    )
    this.$mqtt.subscribe(topic2);
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingNoneService",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
    },
    defaultColor() {
      let self = this
      this.$swal({
        // title: this.$t("message.dialog.default_color"),
        text: this.$t("message.dialog.other.title_rgb_change"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$t("message.public.OK"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
        (result) => {
          if (result) {
            self.data = localStorage.getItem("data_color" + self.serial_number) ?
              JSON.parse(localStorage.getItem("data_color" + self.serial_number)) : {
                onColors: ['#00ff00', '#00ff00', '#00ff00', '#00ff00'],
                offColors: ['#ff0000', '#ff0000', '#ff0000', '#ff0000'],
              };
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          } else {
            throw dismiss;
          }
        }
      );
    },
    sendColor() {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest('');
      }
    },
    sendReq(password) {
      this.sendRequest(password);
    },
    async sendRequest(password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      let message = "L1ON" + this.data.onColors[0].replace('#', '') +
          "L1OFF" + this.data.offColors[0].replace('#', '') + "L2ON" +
          this.data.onColors[1].replace('#', '') + "L2OFF" +
          this.data.offColors[1].replace('#', '') + "L3ON" +
          this.data.onColors[2].replace('#', '') + "L3OFF" +
          this.data.offColors[2].replace('#', '') + "L4ON" +
          this.data.onColors[3].replace('#', '') + "L4OFF" +
          this.data.offColors[3].replace('#', '')+'#';

      var topic_final = getTopics.getTopicRgbSend(
          this.serial_number,
          256
      );
      self.openModalLoading();
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      return p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              //  connection=false;
            
              setTimeout(function () {
               
                if (!connection) {
                  if (showToast === 1)
                  self.exitModalLoading();
                    ErrorToastMsg.Msg();
                } else{
                  self.exitModalLoading();
                  // self.$router.push({
                  //   name: "mainpage",
                  // });
                  self.$router.push({name: "NServiceInfo", params: {serial_number: self.serial_number},}); 
                }
                  
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    myFunction() {
      localStorage.setItem("data_color" + this.serial_number, JSON.stringify(this.data));
      this.$router.push({name: "mainpage",});
    },
    onColor(index) {
      this.color = this.data.onColors[index]
      this.target = [index, "on"]
      this.dialog = true;
      let rgb = hexToRgb(this.color)
      let hex = rgbToHex(rgb[0], rgb[1], rgb[2])
    },
    offColor(index) {
      this.color = this.data.offColors[index]
      this.target = [index, "off"]
      this.dialog = true;
    },
    submitColor() {
      this.dialog = false;
      if (this.target[1] === "on") {
        this.data.onColors[this.target[0]] = this.color
      } else {
        this.data.offColors[this.target[0]] = this.color
      }
    },
    parserRgbSetting(){

      let self=this;
      if(this.info.rgb_setting==null){
        this.info.rgb_setting='L1ON1B6550L1OFFCF3412L2ON1B6550L2OFFCF3412L3ON1B6550L3OFFCF3412L4ON1B6550L4OFFCF3412'
      }
      
      var arrRgb =this.info.rgb_setting.split('L');
      arrRgb.forEach((item, index) => { 
              let str="";
              if(item.match("ON")){
                str = item.substr(0 + 3, 6);
              }
              if(item.match("OFF")){
                str = item.substr(0 + 4, 6);
              }
              
              self.rgbInfo.push({"id":index,"color":'#'+str})
            

       })
    },
  },

}
</script>

<style scoped>

</style>