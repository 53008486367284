import { render, staticRenderFns } from "./AboutNoneRelay.vue?vue&type=template&id=55ac7fed&scoped=true"
import script from "./AboutNoneRelay.vue?vue&type=script&lang=js"
export * from "./AboutNoneRelay.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55ac7fed",
  null
  
)

export default component.exports