<template>
  <v-app class="bg">
     <v-container>
 <v-card
   class="mx-auto py-4"
   max-width="344"
 >
   <v-img
     class="mt-10  ml-auto"
     :src=imgAnt
     height="130px"
     width="200px"
     
     >
 </v-img>
 <v-card-title>
  {{ $t('message.public.update_time') }}
 </v-card-title>
   <v-card-actions>
     <div class="fixed-bottom mb-1 px-4">
     <cmpbutton
           class="mb-1"
           :txtBtn="$t('message.alarm.update_antenaa')"
           :loading="loading"
           @clickedbtn="CallBackBtn"
       ></cmpbutton>
     </div>
     <v-spacer></v-spacer>
     <v-icon v-if="show"  @click="show = !show">mdi mdi-chevron-up</v-icon>
     <v-icon v-else  @click="show = !show">mdi mdi-chevron-down</v-icon>
   </v-card-actions>
   <v-expand-transition>
     <div v-show="show">
       <v-divider></v-divider>
       <v-card-text>
         توضیحات کامل در مورد این بخش
       </v-card-text>
     </div>
   </v-expand-transition>
 </v-card>
</v-container>
</v-app>
</template>
<script>

import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";
import {CommandAlarm} from "@/../public/assets/js/utils/get_alarm_command.js"
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

var connection = false;
var showToast;
var timeout;

export default {
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {}
  },
  data() {
    return {
      // set_password: "",
      // main_admin: "",
      subdevice_serial: '',
      // isModalVisible: false
      show: false,
      loading: false,
      title:'',
      imgAnt:"/assets/images/smart/alarm/ant0.webp",
      antCommand:'',

    };
  },
  components: {
    cmpbutton,
  },
  props: {
    home_id:{},
    serial_number: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        connection = true;
        clearTimeout(timeout);
        this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  mounted() {
    try {
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.title = this.$t('message.alarm.antenaa')
      // this.data = JSON.parse(localStorage.getItem("infoHome"))
      // this.pass = this.data.password === 1;
      // this.set_password = this.$store.getters.getSetPasswordSub;
      // this.main_admin = this.$store.getters.getadmin;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      this.onSubscribe();
      setTimeout(function () {
        // self.lastStausCurtain();
      }, 100);


    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'َAnt');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingAlarmInfo",
          params: {home_id:this.home_id,serial_number: this.serial_number},
        });
      }
    },
    CallBackBtn(msg) { 
      this.loading=true;
        this.sendMessage(CommandAlarm.getCommandAnt());
    },
    sendMessage(saction) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      var topic_final = getTopics.getTopicSettingSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                self.loading=false;
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
     openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicSettingReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    showGetMsg(msg) {
      if(msg!=null){
        this.parseStatusAnt(msg)
      }
    },
    parseStatusAnt(msg) {
     var value=BetweenStr.getBetweenStr(msg,'ANT=','#')[1];
     if(value==0){
      this.imgAnt="/assets/images/smart/alarm/ant0.webp"
     }
     else if(value>=0 && value<8){
      this.imgAnt="/assets/images/smart/alarm/ant1.webp"
     }
     else if(value>=8 && value<16){
      this.imgAnt="/assets/images/smart/alarm/ant2.webp"
     }
     else if(value>=16 && value<24){
      this.imgAnt="/assets/images/smart/alarm/ant3.webp"
     }
     else if(value>=24 && value<35){
      this.imgAnt="/assets/images/smart/alarm/ant4.webp"
     }
    },
  },
};
</script>
<style scoped>

</style>
