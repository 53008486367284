<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-reset"
      tabindex="-1">
    <div class="wrapper m-0">
      <CmpResetFactory @clicked="CallCmpReset"
                       :txtDescript="$t('message.relay.relay_reset_txt')"
                       :loading="loading"
                       :countshow="countshow">
      </CmpResetFactory>
      <div class="modal-dialog p-20">
      </div>
    </div>
  </div>
</template>
<script>
var connection = false;
//--------------------------------------  variable --------------------------------------//
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import CmpResetFactory from "@/components/Cmp/general/CmpResetFactory.vue";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
export default {
  components: {
    CmpResetFactory,
  },
  data() {
    return {
      title: "",
      dataReset: "",
      serial_number: "",
      sub_serial: "",
      loading: false,
      countshow: false,
    };
  },
  props: {
    infoItem: {},
  },
  mqtt: {

    "+/+/receive/+/reset"(data, topic) {
      // if (topic.split("/")[1] === this.serialnumbers[0]) {
      this.checkMqtt(String(data));
      connection = true;
      // }
    },
  },
  created() {
    this.title = this.$t("message.noneHome.reset");
    // let self = this;
  },
  mounted() {},
  methods: {
    CallCmpReset(value) {
      this.dataReset = this.infoItem;
      this.serial_number = this.dataReset[0].serial;
      this.sub_serial = this.dataReset[1].sub_serial;
      this.countshow = true;
      if (value == "cancel") this.CancelFunction();
      if (value == "delete") {
        this.loading = true;
        this.ResetFunction();

      }
    },
    CancelFunction() {
      $("#modal-reset").modal("hide");
      this.loading = false;
      this.countshow = false
    },
    ResetFunction() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      this.loading = true;
      let saction = "RESET#";
      var topic_final = getTopics.getTopicResetSend(
          this.serial_number,
          this.sub_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.onSubScribe();
              setTimeout(function () {
                if (!connection) {
                  self.loading = false
                  swal("", self.$t("message.public.operation_failure"), "error");
                  $("#modal-reset").modal("hide");
                }
              }, 20000);
            } else {
              //error
              //  self.closeModalLoading();
              self.loading = false
              self.countshow = false
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          function (errr) {
            //errr
            self.loading = false
          }
      );
    },
    onSubScribe() {
      var topic = getTopics.getTopicResetReceive(
          this.serial_number,
          this.sub_serial
      );
      this.$mqtt.subscribe(topic);
    },
    checkMqtt(msg) {
      let self = this;

      // let device_serial=this.serialnumbers[0]
      if (msg == "RESET") {
        this.loading = false
        // localStorage.setItem("relay_delay"+ this.device_serial,"")
        swal({
          title: this.$t("message.irAir.settings"),
          text: this.$t("message.noneHome.r_txt"),
          type: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        setTimeout(function () {
          self.$router.push({name: "mainpage"});
        }, 2005);
      }
    },
    onClickChild(value) {
      if (value == "back") {
        $("#modal-reset").modal("hide");
      }
    },
  },
};
</script>


<style scoped>
</style>
