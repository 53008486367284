<template>
  <v-app class="bg">
    <div>
      <div class="px-4 mt-5">
        <v-card class="mx-auto">
        <div :key="index" v-for="(item, index) in itemsInfo">
          <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true" 
            @clickedRow="CmpCallItemInfo">
            <template v-slot:line>
              <v-divider inset v-if="index < itemsInfo.length - 1" class="mb-0 mt-0"></v-divider>
            </template>
          </CmpRowListSetting>
        </div>
        </v-card>
      </div>
      <div class="px-4 mt-2">
        <v-card class="mx-auto">
        <div :key="index" v-for="(item, index) in itemsSetting">

          <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true"
            @clickedRow="CmpCallItemSetting">
            <template v-slot:line>
              <v-divider inset v-if="index < itemsSetting.length - 1" class="mb-0 mt-0"></v-divider>
            </template>
          </CmpRowListSetting>
        </div>
        </v-card>
      </div>

      <div class="px-4 mt-2 mb-4" >
        <v-card class="mx-auto">
        <div :key="index" v-for="(item, index) in itemsDel">
          <CmpRowListSetting :infoItem="item" :subTitleShow="true" :icon="true" 
            @clickedRow="CmpCallItemDel">
            <template v-slot:line>
              <v-divider inset v-if="index < itemsDel.length - 1" class="mb-0 mt-0"></v-divider>
            </template>
          </CmpRowListSetting>
        </div>
        </v-card>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <ModalResetFactoryService :infoItem="dataReset"></ModalResetFactoryService>
    <SubModalDel v-bind:data_info_sub="data_info_service_pro"></SubModalDel>
    <ModalOutletMomentray  :serials="serial_number" @set_delay="setDelay" type="WC">
    </ModalOutletMomentray>
    <SubModalEdit v-bind:data_info_sub="data_info_service_pro"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_service_pro"></SubModalSetPass>
    <SubModalUpdate v-bind:data_info_sub="data_info_service_pro" @clicked="clickUpdate"></SubModalUpdate>

  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRowListSetting from "@/components/Cmp/CmpRowListSetting.vue";
import CmpRowCard from "@/components/Cmp/CmpRowCard.vue";
import { getTopics } from "@/../public/assets/js/utils/topics.js";
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import { getMessageSub } from "@/../public/assets/js/utils/helper_sub_message.js";
import { getMessageElavator } from "@/../public/assets/js/utils/elvatorParser.js";


import SubModalUpdate from "../../subdevice/SubModalUpdate";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { Internets } from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryService from "../../subdevice/servicepro/ModalResetFactoryService";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";


var showToast;
var connection = false;
var timeout;

export default {
  components: {
    CmpRowCard,
    CmpRowListSetting,
    CmpPasscode,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    ModalResetFactoryService,
    ModalOutletMomentray,
    SubModalUpdate
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      subdevice_serial: "",
      title: "",
      // opentime: 30,
      // closetime: 30,
      isModalVisible: false,
      data_info_service_pro: [this.home_id, this.serial_number, this.count],
      info: [],
      info_home: [],
      pass: true,
      dataReset: [],
      labels: [],
      sub_device_info: [],
      set_password: 0,
      setting_status: "",
      pol: "",
      itemsInfo: [],
      itemsSetting: [],
      itemsDel: [],
      // delay_elvator: '',
      lang: "",
      isModalVisibles: false,
      delayStatus:false,

    };
  },
  mqtt: {
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data))
        // this.showGetMsgBroadCast(String(data));
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
    "+/+/receive/+/seting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data));
        // this.showStatus(String(data))
      }
      SucessToastMsg.Msg();
    },
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        // this.showStatus(String(data))
      }
      SucessToastMsg.Msg();
    },
  },
  created() {
  },
  mounted() {
    // this.lang = this.$i18n.locale
    this.info = JSON.parse(localStorage.getItem("infoSubDevice"));
    this.info_home = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.info.set_password === 1

    this.initData();

    this.subdevice_serial = this.info.serial_number
    this.set_password = this.info.set_password;

    this.pol = this.info.pol;
    this.labels = JSON.parse(this.info.labels);


    this.title = this.$t("message.public.setting")
    this.dataReset.push({ serial_number: this.serial_number, subdevice_serial: this.subdevice_serial })

    this.lastStatus();
    // if (this.info.elevator_setting == null) {
    //   this.delay_elvator = '01'
    // } else {
    //   this.delay_elvator = getMessageElavator.getMessageElvatorInstant(this.info.elevator_setting);
    // }
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ServiceProSetting');
    // this.onSubscribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubServiceProInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
    initData() {
      this.itemsInfo.push(
        { index: 1, title: this.$t("message.public.edit"), icon: 'mdi mdi-pencil-outline', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 2, title: this.$t("message.AppBar.about_device"), icon: 'mdi mdi-information-outline', click: "about", actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },)
      this.itemsSetting.push(
        // { index: 1, title: this.$t("message.AppBar.scheduler"), icon: 'mdi mdi-timer-outline', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 2, title: this.$t("message.public.setting") + ' '.repeat(10) + this.$t("message.AppBar.instantSettings"), icon: 'mdi mdi-timer-sand', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 3, title: this.$t("message.public.setting") + " RGB ", icon: 'mdi mdi-lightbulb-outline', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 4, title: this.$t("message.public.wifi_setting_status"), icon: 'mdi  mdi-wifi-check', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 5, title: this.$t("message.public.refresh_device"), icon: 'mdi mdi-update', actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 6, title: this.$t("message.ModalAddHome.pin_code"), icon: 'mdi mdi-lock-outline', actions: 'switch', switch: this.pass },
      )
      this.itemsDel.push(
        { index: 1, title: this.$t("message.public.delete"), icon: 'mdi mdi-trash-can-outline', click: "soft_del", actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
        { index: 2, title: this.$t("message.public.del_hardware"), icon: 'mdi mdi-delete-alert-outline', click: "del_hard", actions: 'icon', more: 'ti-angle-left', more_en: 'ti-angle-right' },
      )
    },
    CmpCallItemInfo(index) {
      switch (index) {
        case 1:
          $("#modal-edit-sub").modal("show");
          break;
        case 2:
        this.$router.push({
          name: "AboutServicePro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    CmpCallItemSetting(index) {
      switch (index) {
        case 1:
          break;
        case 2:
          $("#modal-outlet-momentray").modal("show");
          break;
        case 3:
        this.$router.push({
          name: "ServiceEditRGBPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
          break;
        case 4:
        this.$router.push({
          name: "ServiceProStatusWifi",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
          break;
        case 6:
          $("#modal-set-pass-sub").modal("show");
          break;
        case 5:
          // this.showUpdateDialog();
          $("#modal-update-sub").modal("show");
          break;
      }
    },
    CmpCallItemDel(item) {
      switch (item) {
        case 1:
          $("#modal-del-sub").modal("show");
          break;
        case 2:
          this.type_Selected_item = "reset"
          this.setting_status = "RESET#"
          $("#modal-reset-servicePro").modal('show');
          break;
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSetingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic3 = getTopics.getTopicSettingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
      this.$mqtt.subscribe(topic3);
    },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.subdevice_serial,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      // this.openModalLoading();
      // ------------------Response----------------//
      p.then(
        function (response) {
          // self.closeModalLoading();
          let jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            let message = jsonArray.content.message;
            // ****** code save data in subdevice
            self.sub_device_info.push({
              subDevices: jsonArray.content.device_info
            })
            localStorage.setItem("infoSubDevice", JSON.stringify(self.sub_device_info[0].subDevices))
            self.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
            // ****** code save data in subdevice
            // self.cmpShow = true
            // self.showStatus(message);
            // self.parserRgbSetting();
          } else {
            // self.closeModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function () {
          // console.log("err", errr);
        }
      );
    },
    // parseInstantSetting(msg) {
    //   this.opentime = msg.split("K1:").pop().split("S")[0];
    //   this.closetime = msg.split("K3:").pop().split("S")[0];
    // },
    // showGetMsgBroadCast(msg) {

    //   this.opentime = msg.split("K1:").pop().split("S")[0];
    //  this.closetime = msg.split("K3:").pop().split("S")[0];
    //  localStorage.setItem("openTimeShutter", this.opentime);
    //  localStorage.setItem("closeTimeShutter", this.closetime);

    // },
    setDelay(delay) {
      if (this.set_password == 1) {
        this.setting_status = delay;
        this.delayStatus = true;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendSettings(delay, "");
      }
    },
    sendSettings(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      let message = "AUTO:" + saction.auto + "TFAN:" + saction.fan + "TSPRY:" + saction.spray + "#";
      let topic2 = getTopics.getTopicToggleReceive(
        this.serial_number,
        this.subdevice_serial
      )
      this.$mqtt.subscribe(topic2);
      var topic_final = getTopics.getTopicToggleSend(
        this.serial_number,
        this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            //  connection=false;
            self.openModalLoading();
            $("#modal-pass-keytouch").modal("hide");
            timeout = setTimeout(function () {
              self.exitModalLoading();

              if (!connection) {
                setTimeout(function () {
                  self.lastStausLamp();
                }, 1000);
                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                }

              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.exitModalLoading();
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    },
    sendMessage(status) {
      this.setting_status = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.setting_status, '');
      }
    },
    sendReq(password) {
      // this.sendRequest(this.setting_status, password);
      if (this.delayStatus) {
        this.sendSettings(this.setting_status, password);
        this.delayStatus = false;
      } else{
        this.sendRequest(this.setting_status, password);
      }
        
    },
    sendRequest(message, password) {
      console.log("message",message)

      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final = "";



      if (this.type_Selected_item == 'update') {
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial)
      }
      else if (this.type_Selected_item == 'reset') {
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial)
      }
      else {
        topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      }

      // this.type_Selected_item == 'reset' ?
      //   topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial) :
      //   topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);

      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();

            if (!connection) {
              // setTimeout(function () {
              //   self.lastStatus();
              // }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 6000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    clickUpdate(value) {
      this.onSubscribe();
      if (this.set_password == 1) {
        this.setting_status = "UPDATE#"
        this.type_Selected_item = "update"
        $("#modal-update-sub").modal("hide");
        $("#modal-pass-code").modal("show");
      } else {
        this.type_Selected_item = "update"
        this.sendRequest('UPDATE#', '');
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      if (msg == "UPDATEOK") {
        $("#modal-update-sub").modal("hide");
        swal({
          // title: self.$t('message.timer.timer'),
          title: this.$t("message.public.refresh"),
          text: this.$t("message.public.update_done"),
          type: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
  },
};

</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}

.v-list-item__content {
  overflow: visible;
}
</style>
