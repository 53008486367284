export const CommandAlarm = {
  getCommandDeactive: function () {
    return "DEACTIVE#"
  },
  getCommandActive: function () {
    return "ACTIVE#"
  },
  getCommandSemiActive: function () {
    return "STAF#"
  },
  getCommandAnt: function () {
    return "ANT?"
  },
  getCommandAlarmTime: function (time) {
    return  "TALARM"+time+"#"
  },
  getCommandZoneSms: function (count,txt) {
    return  "S"+count+"MSG"+txt+"#"
  },
  getCommandSound: function (sound,melodi) {
    // SZ1-8#
    return  "SZ"+sound+"-"+melodi+"#"
  },
  getCommandPrivacy: function (value) {
    var status="";
    value ? status="ON" : status="OFF"
    return  "STATUS"+status+"#"
  },
  getCommandAddRemote: function (count) {
    return  "REMOTE"+count+"#"
  },
  getCommandStatusRemote: function (count) {
    return  "STATUSREMOTE#"
  },
  getCommandDelRemote: function (count) {
    return  "CLEANR"+count+"#"
  },
  getCommandAddZone: function (zone,sms,call,alarm,status24) {
    var smsStatus="";
    var callStatus="";
    var alarmStatus="";
    var s24="";
    sms ? smsStatus="ON" : smsStatus="OFF"
    call ? callStatus="ON" : callStatus="OFF"
    alarm ? alarmStatus="ON" : alarmStatus="OFF"
    s24 ? status24="ON" : status24="OFF"
    return  "INPUT-"+"I"+zone+"-SMS"+smsStatus+"-CALL"+callStatus+"-ALARM"+alarmStatus+"-24"+s24+"#"
  },
  getCommandRelay4: function (count,actions) {
    var relay4Status="";
    actions ? relay4Status="ON" : relay4Status="OFF"
    return  "R"+count+relay4Status+"#"
  },
  getCommandRelay4Instant: function (count,delay) {
    return  "P"+count+":-"+delay+"S#"
  },
  //Commad Of Wireless Sensor 24H
  getCommandAddWS24: function (count,sms,call,alarm) {
    var smsStatus="";
    var callStatus="";
    var alarmStatus="";
    sms ? smsStatus="ON" : smsStatus="OFF"
    call ? callStatus="ON" : callStatus="OFF"
    alarm ? alarmStatus="ON" : alarmStatus="OFF"
    return  "SENSOR"+count+'-SMS'+smsStatus+'-CALL'+callStatus+'-ALARM'+alarmStatus+"#"
  },
  getCommandDelWs24: function (count) {
    return  "CLEANSEN"+count+"#"
  },
  getCommandDelWs24All: function (count) {
    return  'CLEANSENALL#'
  },
  getCommandStatusWs24: function (count) {
    return 'STATUSW24H#'
  },
  //Commad Of Wireless Sensor 
  getCommandAddWS: function (count,sms,call,alarm) {
    var smsStatus="";
    var callStatus="";
    var alarmStatus="";
    sms ? smsStatus="ON" : smsStatus="OFF"
    call ? callStatus="ON" : callStatus="OFF"
    alarm ? alarmStatus="ON" : alarmStatus="OFF"
    return  "SECURITY"+count+'-SMS'+smsStatus+'-CALL'+callStatus+'-ALARM'+alarmStatus+"#"
  },
  getCommandDelWs: function (count) {
    return  "CLEANSEN"+count+"#"
  },
  getCommandDelWsAll: function (count) {
    return  'CLEANSENALL#'
  },
  getCommandStatusWs: function (count) {
    return 'STATUSWSEN#'
  },
  getCommandDone: function (count, key) {
    return getStrCommand(count, key) + "-DONE"
  },
  getTimerCommand: function (count, key) {
    return getStrCommand(count, key)
  },
  getCommandCancelLearn: function (count, key) {
    return getStrCommand(count, key) + "-CANCELREAD"
  },
  getCommandIrTest: function (code) {
    return   "IRTST" + "-" + code + "-END";
  },
  getCommandClearKey: function (count, key) {
    return getStrCommand(count, key) + "-CLEAR"
  },
  getCommandDeleteControl: function (count) {
    return "CONTROL" + count + "-DELETE"
  },
  // IR Gateway Scenario
  getCommandIrScenario: function (serial, count, value) {
    return getStrCommandScenario(serial, count, value)
  },
};
function getStrCommand(count, key, status='',code='', type='') {
  var message;
  if (type == 'order') { 
    message = 'CONTROL' + count + '-KEY' + key +'-MSG-'+ status+ '-ORDER-' + code
  }
  else if (type == 'air') { 
    message = 'CONTROL' + count + '-KEY' + key +'-LEARN-'+ status
  }
  else {
    message = 'CONTROL' + count + '-KEY' +key 
  }
  return message
};
function getStrCommandScenario(serial, count, value,type) {
  var message;
    message= "<" + serial + "&CONTROL" +  count + "-KEY" + value + ">";
  return message
};



