<template>
  <div>
    <div class="box-body bg-thermostat-temp mx-10">
      <ul class="
                      
                        d-flex justify-content-between
                        py-5
                        px-5
                        bg-tempEl
                      " style="border-radius: 10px">
        <li class="text-right list-unstyled">
          <div class="font-size-30">
            <i class="mdi mdi-thermometer mdi-24px text-white"></i>
            <span class="font-size-20 text-white ">
              {{ $t("message.subDevice.thermostatTemp.thermostat_temp") }}
            </span>
          </div>
        </li>
        <li class="text-left list-unstyled">
          <div class="font-size-20 text-white mx-2">
            <h6 class="mb-0 font-size-30" id="lbl-temp" v-if="!Number.isNaN(parseInt(msg_temp))">
              {{ temp_home }}<sup class="font-size-20 opacity: 0.8!important">°C</sup>
            </h6>
          </div>
        </li>
      </ul>
    </div>
    <div class="row mt-1 px-20">
      <div class="col-12">
        <div class="box">
          <div class="box-body">
            <div class="text-center py-1">
              <ul class="flexbox flex-justified text-center ">
                <li class="px-3">
                  <button v-if="msg_pump == 'PON'" type="button" class="
                    btn btn-circle btn-primary btn-lg
                    shadow
                    border border-grey
                  " @click="pumpClick('POFF')">
                    <i id="imgauto" class="mdi mdi-water mdi-24px"></i>
                  </button>
                  <button v-else type="button" class="
                    btn btn-circle btn-light btn-lg   
                    shadow
                    border border-grey
                  " @click="pumpClick('PON')">
                    <i id="imgauto" class="mdi mdi-water mdi-24px"></i>
                  </button>
                </li>
                <li class="px-3">
                </li>
                <li class="px-3">
                  <button v-if="msg_power == 'TON'" type="button" class="
                    btn btn-circle btn-success btn-lg       
                    shadow
                    border border-grey
                  " @click="powerClick('TOFF')">
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button v-else type="button" class="
                    btn btn-circle btn-danger btn-lg
                    shadow
                    border border-grey
                  " @click="powerClick('TON')">
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div class="text-center mt-3" style="border: 1px solid rgb(207 219 232); border-radius: 50px">
              <ul class="flexbox flex-justified text-center my-2">
                <li class=" px-2 d-flex justify-content-center align-items-center">
                  <button type="button" class="
                    btn btn-circle btn-light btn-lg
               
                    shadow
                    border border-grey
                  " @click="TempMinus()" :disabled="isActive">
                    <i class="mdi mdi-minus mdi-24px"></i>
                  </button>
                </li>
                <li class="px-2 d-flex justify-content-center align-items-center">
                  <span class="text-grey font-size:20px">دما</span>
                </li>
                <li class="px-2">
                  <button type="button" class="
                    btn btn-circle btn-light btn-lg
               
                    shadow
                    border border-grey
                  " @click="TempPlus()" :disabled="isActive">
                    <i class="mdi mdi-plus mdi-24px"></i>
                  </button>
                </li>
              </ul>
            </div>
            <div class="text-center py-1">
              <ul class="flexbox flex-justified text-center my-5">

                <li class="px-1">
                  <button v-if="msg_fan == 'FANAUTO'" type="button" class="
                    btn btn-circle btn-primary btn-lg

                    shadow
                    border border-grey
                  " @click="fanClick('OFF')">
                    <i id="imgauto" class="mdi mdi-autorenew mdi-24px"></i>
                  </button>
                  <button v-else type="button" class="
                    btn btn-circle btn-light btn-lg
                    shadow
                    border border-grey
                  " @click="fanClick('FANAUTO')" :disabled="isActive">
                    <i id="imgauto" class="mdi mdi-autorenew mdi-24px"></i>
                  </button>
                </li>
                <li class="px-1">
                  <button v-if="msg_fan == 'FANHIGH'" type="button" class="
                    btn btn-circle btn-primary btn-lg
                    shadow
                    border border-grey
                  " @click="fanClick('OFF')">
                    <img id="imgfanfast" src="/assets/images/smart/icon-fan-fast-white.svg"
                      style="width: 24px; height: 24px" />
                  </button>
                  <button v-else type="button" class="
                    btn btn-circle btn-light btn-lg
                    shadow
                    border border-grey
                  " @click="fanClick('FANHIGH')" :disabled="isActive">
                    <img id="imgfanfast" src="/assets/images/smart/icon-fan-fast.svg"
                      style="width: 24px; height: 24px" />
                  </button>
                </li>
                <li class="px-1">
                  <button v-if="msg_fan == 'FANLOW'" type="button" class="
                    btn btn-circle btn-primary btn-lg
                    shadow
                    border border-grey
                  " @click="fanClick('OFF')">
                    <img id="imgfanfast" src="/assets/images/smart/icon-fan-slow-white.svg"
                      style="width: 24px; height: 24px" />
                  </button>
                  <button v-else type="button" class="
                    btn btn-circle btn-light btn-lg
                
                    shadow
                    border border-grey
                  " @click="fanClick('FANLOW')" :disabled="isActive">
                    <img id="imgfanfast" src="/assets/images/smart/icon-fan-slow.svg"
                      style="width: 24px; height: 24px" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- /. Wrapper -->
</template>
<script>

// var count_temp = 16;
export default {
  components: {
  },
  props: {
    status: {},
    //  temp_home:{},
  },

  data() {
    return {
      temp_value: 10,
      temp_home: 16,
      data_info: [this.temp_home],
      plusShow: false,
      minusShow: false,
      isActive: false,
      msg_auto: "",
      msg_pump: "",
      msg_power: "",
      msg_mode: "",
      msg_fan: "",
      msg_temp: "16",
      msgStr: "",
      msgDefault: "TOFF#",
    };
  },
  watch: {
    status: function (newVal, oldVal) {
      this.parseMessageFromDevice(newVal)
    }
  },
  created() {
    if (typeof this.status == "undefined") {
      this.msgStr = this.msgDefault;

      this.$emit("clicked", this.msgStr)

    } else if (this.status == "NOTSET") {
      this.msgStr = this.msgDefault;
      this.$emit("clicked", this.msgStr)
    } else {
      this.msgStr = this.status;
    }
    this.defaultShowItem(this.msgStr);
  },
  mounted() {
    let self = this;
    setTimeout(function () {
    }, 2000);
    // if( count_temp =localStorage.getItem("tempHome")== null){
    //   count_temp=this.data_info[0]
    // }
  },
  methods: {
    ceateStatus() {
      if (this.msg_power == "TOFF") {
        this.msgStr = this.msg_power + '#';
        this.msg_pump = '';
        this.msg_fan = '';
        this.$emit("clicked", this.msgStr)
      } else {
        if (!this.msg_pump) {
          this.msg_pump = "POFF";
        }
        if (!this.msg_fan) {

          this.msg_fan = "FANLOW";
        }
        this.msgStr =
          this.msg_power +
          "-" +
          this.msg_pump +
          "-" +
          "TP" +
          this.temp_home +
          "-" +
          this.msg_fan +
          "#";
        this.$emit("clicked", this.msgStr)
      }
      this.defaultShowItem(this.msgStr);
      // localStorage.setItem("HomeTemp", count_temp);
    },
    fanClick(value) {
      this.msg_power = "TON";
      this.msg_fan = value;
      this.ceateStatus();
    },
    autoClick(value) {
      this.msg_power = "TON";
      this.msg_mode = value;
      this.ceateStatus();
    },
    powerClick(value) {
      this.msg_power = value;
      this.ceateStatus();
    },
    pumpClick(value) {
      this.msg_pump = value;
      this.ceateStatus();
    },

    TempMinus() {
      this.plusShow = false;
      this.minusShow = false;
      if (this.temp_home <= 16) {
      } else {
        this.temp_home--;
      }
      document.getElementById("lbl-temp").innerHTML =
        this.temp_home + '<sup class="font-size-20 opacity: 0.8!important">°C</sup>';
      // this.msg_temp = count_temp;
      this.ceateStatus();
    },
    TempPlus() {

      this.plusShow = false;
      this.minusShow = false;
      if (this.temp_home >= 31) {
      } else {
        this.temp_home++;
      }
      document.getElementById("lbl-temp").innerHTML =
        this.temp_home + '<sup class="font-size-20 opacity: 0.8!important">°C</sup>';
      // this.msg_temp = count_temp;
      this.ceateStatus();
    },
    defaultShowItem(msgStr) {
      if (msgStr.match('TOFF')) {
        this.msg_power = "TOFF";
      } else {
        this.msg_power = "TON";
        this.parseMessage(msgStr)
      }
    },
    parseMessage(msgStr) {
      // this.msg_temp = msgStr.substr(msgStr.indexOf(msgStr.match("TP")) + 2, 2);
      if (msgStr.match("HEAT")) {
        this.msg_mode = "HEAT";
      } else if (msgStr.match("COOL")) {
        this.msg_mode = "COOL";
      }
      if (msgStr.match("FANHIGH")) {
        this.msg_fan = "FANHIGH";
      } else if (msgStr.match("FANLOW")) {
        this.msg_fan = "FANLOW";
      } else if (msgStr.match("FANMED")) {
        this.msg_fan = "FANMED";
      } else if (msgStr.match("FANAUTO")) {
        this.msg_fan = "FANAUTO";
      }
    },
    parseMessageFromDevice(msgStr) {
      if (msgStr.match('TOFF')) {
        this.msg_power = "TOFF";
        this.parseMessage(msgStr)
      } else {
        this.msg_power = "TON";
        this.parseMessage(msgStr)
      }
    },
  },
};
</script>
<style scoped>
.bg-thermostat-temp {
  background-color: #0c3064;
}

.bg-tempEl {
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.mdi-clock:before {
  color: #fff;
}
</style>