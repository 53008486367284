<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-add-ir_control_none"
      tabindex="-1">
    <div class="wrapper m-0">
      <!-- .header -->
      <AppBar v-bind:title="title" :nonehome="true" @clicked="onClickChild"></AppBar>
      <!-- ./header -->
      <div class="content-wrapper bg-white mr-0 ">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body  p-0">
                  <div style="text-align: center; margin-top: 10px">
                    <div class="fontawesome-icon-list m-0">
                      <img
                          src="/assets/images/smart/vector-addsub.svg"
                          class="avatar-smart"
                      />
                      <h5 class="font-weight-bold">
                        {{ $t('message.public.submit') + " " + $t('message.irAir.control') }}
                      </h5>
                    </div>
                  </div>
                  <br/>
                  <form class="px-40">
                    <div class="form-group">
                      <h6>{{ $t('message.ir.name') }} <span class="text-danger">*</span></h6>
                      <input
                          id="inputcontrolname"
                          type="text"
                          class="form-control"
                          aria-invalid="false"
                          autocomplete="off"
                          maxlength="32"
                          :placeholder="$t('message.ir.hintName')"
                          v-model="nameircontrol"
                      />
                    </div>

                    <div class="row">
                      <!-- /.col -->
                      <div class="col-12 text-center">
                        <button
                            type="button"
                            id="btn-add-control"
                            class="btn btn-info btn-block margin-top-10"
                            v-on:click.prevent="addIrControl()"
                        >
                          <span class="text-white"> {{ $t('message.public.submit') }} </span>
                        </button>
                      </div>
                      <!-- /.col -->
                    </div>
                  </form>
                  <div class="text-center" style="height: 50px"></div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
      </div>
    </div>
    <!-- /.modal Add Home-->
  </div>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {nameDeviceValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

export default {
  components: {
    AppBar
  },
  props: {
    data_infos: {type: Array}
  },
  data() {
    return {
      //   info: null,
      nameircontrol: '',
      title: ''
    };
  },
  created() {
    this.title = this.$t('message.public.add')
  },
  mounted() {
    this.btnLoading();

  },
  methods: {

    addIrControl() {
      let self = this
      var name = this.nameircontrol;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.data_infos[0];
      var home_id = this.data_infos[1];
      var control_uid = this.data_infos[2];
      var tapp = localStorage.getItem("t_app");


      if (!nameDeviceValidationInput(name))
        return;

      var data = {
        user_id: user_id,
        token: token,
        name: name,
        control_uid: control_uid,
        serial_number: serial_number

      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.controlAdd;

      const p = postAjaxCall(URL, data, "");
      // setTimeout(function(){   $("#modal-center").modal('show');  }, 50);
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);


              self.$router.push({name: "irGatewaynone", params: {serial_number: serial_number}});
              self.nameircontrol = "";

            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value === "back") {
        var serial_number = this.data_infos[0];
        this.nameircontrol = "";
        this.$router.push({name: "irtypenone", params: {serial_number: serial_number}});
      }
    },
    btnLoading() {
      var $btn = $("#btn-add-control");
      $btn.click(function () {
        var $this = $(this);
        $this
            .attr("disabled", "disabled")
            .html(
                `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>`
            );
        setTimeout(function () {
          $this.removeAttr("disabled").html(this.$t('message.public.submit'));
        }, 2000);
      });
    },

  },
};
</script>


<style scoped>
</style>
