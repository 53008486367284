<template>
  <v-app class="bg">
    <TimerList :isShow="isShow" :length="length" :infos="info" :type="type" @addClock="addClock()"
               @addTimer="addTimer()"
               @deleteTimer="deleteTimer"
    />
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
</template>

<script>
import {SetClock} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {Tools} from "../../../../public/assets/js/utils/util.js";
import TimerList from "@/components/Cmp/timer/show/TimerList.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerDataIR} from "../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpPasscode from "../../Cmp/general/CmpPasscode";


var count_timer;
var connection = false;

export default {
  name: "IRTimerShow",
  components: {
    TimerList,
    CmpPasscode
  },
  data: () => ({
    info: "",
    length: "",
    isShow: false,
    set_password: '',
    title: '',
    counter: '',
    lang: '',
    type: 'IR',
    isModalVisible: false,
  }),
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  mqtt: {
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        this.checkMessage(String(data));
        connection = true;
      }
    },
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    let data = {
      title: this.$t('message.timer.scheduler_show'),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer');
    let detail = JSON.parse(localStorage.getItem("infoDevice"));
    this.set_password = detail.set_password;
  },
  mounted() {
    let self = this;
    this.title = this.$t('message.timer.scheduler_show')
    this.$store.dispatch("infoHome");
    setTimeout(function () {
      self.lastStatus();
    }, 100);
    this.data = {
      isShow: this.isShow,
      length: this.length,
      infos: this.info,
    }
    console.log(this.data)
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      this.info = '';

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: "256",
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.timers;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              let content = jsonArray.content.timers;

              var finaldata = [];
              self.length = content.length;
              var len = content.length;
              if (len == 0) {
                self.isShow = true;
              }
              content.forEach(function (item) {
                // console.log("item", item.message);
                const data = getTimerDataIR(item);
                console.log("data timer",data);
                finaldata.push(data);
                self.info = finaldata;
                console.log(self.info);
              });
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    deleteTimer(counttimer) {
      let self = this;
      console.log(counttimer)
      swal(
          {
            title: this.$t('message.timer.timer'),
            text: this.$t('message.timer.timer_isDelete'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            confirmButtonText: this.$t('message.public.delete'),
            cancelButtonText: this.$t('message.public.cancel'),
            closeOnConfirm: true,
            closeOnCancel: true,
          },
          function (isConfirm) {
            if (isConfirm) {
              if (self.set_password == 1) {
                self.counter = counttimer;
                $("#modal-pass-code").modal("show");
              } else
                self.sendDelete(counttimer, '');
            }
          }
      );
    },
    sendReq(pass) {
      this.sendDelete(this.counter, pass);
    },
    sendDelete(counttimer, pass) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      count_timer = counttimer;
      let message = "IRTMR" + count_timer + "-OFF#";
      var topic_final = getTopics.getTopicTimerSend(this.serial_number, '256');
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        password: pass,
        message: message,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                  self.exitModalLoading();
                }
              }, 10000);
            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {
      var topic = getTopics.getTopicTimerReceive(this.serial_number, '256');
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();
      swal({
        title: this.$t('message.timer.timer'),
        text: this.$t('message.timer.timer_delete'),
        type: "success",
        timer: 3000,
        showConfirmButton: false,
      });

      // location.reload();
      this.lastStatus();
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    addClock() {
      let self = this;
      var saction = SetClock.getSetClock();
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;      
      var topic_final = getTopics.getTopicClockSend(serial_number, "256");

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        client_id: "fara" + Date.now(),
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModalLoading();
              Tools.sweetAlert(self.$t('message.timer.setTime'), self.$t('message.timer.setTime_dialog'), 'success', 3000)
            } else {
              //error

              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irGatewayInfo",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "refresh") {
        this.lastStatus();
        // location.reload();
      }
      if (value == "add") {
        this.$router.push({
          name: "IRTimerList",
          params: {serial_number: this.serial_number, home_id: this.home_id},
        });
      }
    },
    addTimer() {
      this.$router.push({
        name: "IRTimerList",
        params: {serial_number: this.serial_number, home_id: this.home_id},
      });
    }
  },
}
</script>

<style scoped>

</style>