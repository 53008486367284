<template>
    <Timer :data="data" @next="onClick"/>
</template>

<script>
import Timer from "../../../Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "ncuartainTimer",
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {},
    // status_checkbox: '',
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.data = {
      items: [
        {
          name: this.$t('message.relay.type.curtain')+" "+this.$t('message.public.open'),
          status: true
        },
        {
          name: this.$t('message.relay.type.curtain')+" "+this.$t('message.public.close'),
          status: true
        }
      ],
      type: "curtainFara"
    }
    this.title = this.$t("message.timer.scheduler");
    let info = localStorage.getItem("data_edit") ? JSON.parse(localStorage.getItem("data_edit")) : [];
   
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(val, status);
      switch (val) {
        case 0:
          this.nextL1();
          break;
        case 1:
          this.nextL2();
          break;
      }
    },
    nextL1() {
      let self=this;
      // var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let status_checkbox = "K1T";
      var saction = "PS" + this.serial_number + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCurtainStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL2() {
      let self=this;
      // var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      let status_checkbox = "K3T";
      var saction = "PS" + this.serial_number + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCurtainStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    onClickChild(value) {
      let self=this;
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "NCurtainTimerShow",
          params: {
            serial_number: self.serial_number,
          },
        });
      }
    },
  }
}
</script>

<style scoped>

</style>