<template>
    <Timer :data="data" @next="onClick"/>
</template>

<script>
import Timer from "../../../Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "NCoolerTimer",
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {},
    // status_checkbox: '',
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.data = {
      items: [
        {
        name: this.$t('message.public.off'),
        status: true
      },
        {
          name: this.$t('message.subDevice.cooler_pomp'),
          status: false
        },
        {
          name: this.$t('message.subDevice.cooler_low'),
          status: false
        },
        {
          name: this.$t('message.subDevice.cooler_fast'),
          status: false
        },
      ],
      type: "ncoolerFara"
    }
    this.title = this.$t("message.timer.scheduler");
    let info = localStorage.getItem("data_edit") ? JSON.parse(localStorage.getItem("data_edit")) : [];
   
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      switch (val) {
        case 0:
          this.nextAll();
          break;
        case 1:
          this.nextL1(status);
          break;
        case 2:
          this.nextL2(status);
          break;
        case 3:
          this.nextL3(status);
          break;
      }
    },
  
    nextAll() {
      let self=this;
      let status_checkbox = "K5F";
      const saction = "PS" +  this.serial_number + "*" + status_checkbox;
      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCoolerStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL1(status) {
      let self=this;
      let status_checkbox;
      if (status) {
        status_checkbox = "K1T";
      } else if (!status) {
        status_checkbox = "K1F";
      }
      var saction = "PS" +  this.serial_number + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCoolerStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL2(status) {
      let self=this;
      let status_checkbox;
      if (status) {
        status_checkbox = "K2T";
      } else if (!status) {
        status_checkbox = "K2F";
      }
      var saction = "PS" +  this.serial_number + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCoolerStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },
    nextL3(status) {
      let self=this;
      let status_checkbox;
      if (status) {
        status_checkbox = "K3T";
      } else if (!status) {
        status_checkbox = "K3F";
      }
      var saction = "PS" +  this.serial_number + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NCoolerStep2Timer",
        params: {
          serial_number: this.serial_number,
        },
      });
    },







    onClickChild(value) {
      let self=this;
      if (value == "back") {
        this.$router.push({
          name: "NCoolerTimerShow",
          params: {
            serial_number: self.serial_number,
          },
        });
      }
    },
  }
}
</script>

<style scoped>

</style>