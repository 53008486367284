<template>
  <v-app class="bg">
    <div class=" mr-0">
      <section class="content">
        <div class="row mt-5 px-20">
          <div class="col-lg-12 col-12 py-0">
            <div class="box rounded-50">
              <div class="box-body">
                <div class="row" v-if="cmpShow">
                  <div class="col-xl-12 col-md-12 col-12">
                    <div id="trl1">
                      <div class="media media-single px-0 py-20">
                        <img src="/assets/images/smart/switch-off.svg" id="imgl1" width="32px" height="32px"
                          :style="result1.check ? result1.onFilter : result1.offFilter" />
                        <div class="media-body">
                          <h6> {{ $t('message.touch_key.key') }} </h6>
                        </div>
                        <v-switch :color="data.onColors[0]" inset v-model="result1.check"
                          @click="MyFunctionPower(0, result1.check)" id="checkL1"></v-switch>
                      </div>
                    </div>
                    <div id="trl3">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img src="/assets/images/smart/icon-fan-fast.svg" id="imgl3" width="32px" height="32px"
                            :style="result2.check ? result2.onFilter : result2.offFilter" />

                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.fan') }} </h6>
                          </div>
                          <v-switch :color="data.onColors[1]" v-model="result2.check"
                            @click="MyFunctionPower(1, result2.check)" inset id="checkL2"></v-switch>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img src="/assets/images/smart/music.svg" id="imgl2" width="32px" height="32px"
                            :style="result4.check ? result4.onFilter : result4.offFilter" />

                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.music') }} </h6>
                          </div>
                          <v-switch :color="data.onColors[2]" v-model="result3.check"
                            @click="MyFunctionPower(2, result3.check)" inset id="checkL2"></v-switch>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img src="/assets/images/smart/spray.svg" id="imgl4" width="32px" height="32px"
                            :style="result3.check ? result3.onFilter : result3.offFilter" />
                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.spray') }} </h6>
                          </div>
                          <v-switch :color="data.onColors[3]" v-model="result4.check"
                            @click="MyFunctionPower(3, result4.check)" inset id="checkL4"></v-switch>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <CmpPasscode @sendPassCode="sendReq" @cancel="closePass"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
    <ModalOutletMomentray v-show="isModalVisibles" :serials="serial_number" @set_delay="setDelay" type="WC">
    </ModalOutletMomentray>
    <ModalResetFactoryService :infoItem="dataReset"></ModalResetFactoryService>
    <SubModalDel v-bind:data_info_sub="data_info_service_pro"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_service_pro"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_service_pro"></SubModalSetPass>
  </v-app>
</template>

<script>
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { getTopics } from "@/../public/assets/js/utils/topics";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { Color, hexToRgb } from "@/../public/assets/js/utils/colorRGB";
import { Solver } from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { Internets } from "@/../public/assets/js/utils/util.js";
import ModalResetFactoryService from "../../subdevice/servicepro/ModalResetFactoryService";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import { passHomeValidationInput } from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    ModalOutletMomentray,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    ModalResetFactoryService
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      cmpShow: false,
      sub_device_info: [],
      type_Selected_item: "action",
      passkeytouchfara: "",
      delayStatus:false,
      data_info_service_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      // pass: true,
      pol: 1,
      labels: [],
      isModalVisible: false,
      subdevice_serial: "",
      service_status: "",
      isModalVisibles: false,
      pass: 0,
      data: [],
      info: [],
      rgbInfo: [],
      dataReset: [],
      delay: {
        "fan": 0,
        "spray": 0,
        "auto": 0,
      },
      polall: false,
      pol1: false,
      pol2: false,
      pol3: false,
      pol4: false,
      resultAll: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result1: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result2: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result3: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result4: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      set_password: 0,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showStatus(String(data))
      }
      SucessToastMsg.Msg();
      //  }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data))
      }
    },
    "+/+/receive/+/seting"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        clearTimeout(timeout);
        this.closeModalLoading();
        this.showGetMsg(String(data))
      }
    },
  },
  created() {
    let self = this;
    let data = localStorage.getItem("infoSubDevice");
   
    data = JSON.parse(data);
    let delay =  data.wc_setting.substr(6,).split(":");


    // this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.delay.auto = data.wc_setting.substr(5, 1);
    this.delay.fan = delay[1].split("T")[0];
    this.delay.spray = delay[2];

    localStorage.setItem("delay", JSON.stringify(this.delay));

    if (!JSON.parse(data.labels))
      this.labels = [{ pol: "0", label: "L1" }, { pol: "1", label: "L2" }, { pol: "2", label: "L3" }, {
        pol: "3",
        label: "L4"
      }];
    else
    this.labels = JSON.parse(data.labels);
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;




   
    

  },
  mounted: function mounted() {

    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.pol = this.$store.getters.getPolSub;
    this.main_admin = this.$store.getters.getadmin;
    this.title = this.$store.getters.getNameSub;
    this.subdevice_serial=JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,

    
    this.dataReset.push({ serial_number: this.serial_number, subdevice_serial: this.subdevice_serial })
    // init run
    // try {

    this.lastStatus();
    
    // subscribe
    this.onSubscribe();


    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      sub_setting:true,
      // pin: this.pass,
      // passcode: this.set_password,
      // menu: true,
      // edit: true,
      // del: true,
      // refresh: true,
      // // timer: true,
      // about: true,
      // update: true,
      // editRgb: true,
      // wifi_sub_onoff: true,
      // set_service: true,
      // subReset: true,
      // delayShow: false,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubServicePro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic2 = getTopics.getTopicSetingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      var topic3 = getTopics.getTopicSettingReceive(
        this.serial_number,
        this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);
      this.$mqtt.subscribe(topic3);

    },
    MyFunctionPower(index, value) {
      index += 1
      if (value)
        this.service_status = "K" + index + "T#";
      else
        this.service_status = "K" + index + "F#";
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.service_status, "");
      }
    },
    // setColors() {
    //   let onColors = this.data.onColors.map(color => {
    //     return hexToRgb(color);
    //   });
    //   let offColors = this.data.offColors.map(color => {
    //     return hexToRgb(color);
    //   });
    //   let colorAll = new Color(0, 0, 255);
    //   let color1 = new Color(onColors[0][0], onColors[0][1], onColors[0][2]);
    //   let color1_2 = new Color(offColors[0][0], offColors[0][1], offColors[0][2]);
    //   let color2 = new Color(onColors[1][0], onColors[1][1], onColors[1][2]);
    //   let color2_2 = new Color(offColors[1][0], offColors[1][1], offColors[1][2]);
    //   let color3 = new Color(onColors[2][0], onColors[2][1], onColors[2][2]);
    //   let color3_2 = new Color(offColors[2][0], offColors[2][1], offColors[2][2]);
    //   let color4 = new Color(onColors[3][0], onColors[3][1], onColors[3][2]);
    //   let color4_2 = new Color(offColors[3][0], offColors[3][1], offColors[3][2]);
    //   this.resultAll.onFilter = new Solver(colorAll).solve().filter;
    //   this.resultAll.offFilter = '';
    //   this.result1.onFilter = new Solver(color1).solve().filter;
    //   this.result1.offFilter = new Solver(color1_2).solve().filter;
    //   this.result2.onFilter = new Solver(color2).solve().filter;
    //   this.result2.offFilter = new Solver(color2_2).solve().filter;
    //   this.result3.onFilter = new Solver(color3).solve().filter;
    //   this.result3.offFilter = new Solver(color3_2).solve().filter;
    //   this.result4.onFilter = new Solver(color4).solve().filter;
    //   this.result4.offFilter = new Solver(color4_2).solve().filter;
    // },
    lastStatus() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.subdevice_serial,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
        function (response) {
          self.closeModalLoading();
          let jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            let message = jsonArray.content.message;
            // ****** code save data in subdevice
            self.sub_device_info.push({
              subDevices: jsonArray.content.device_info
            })
            localStorage.setItem("infoSubDevice", JSON.stringify(self.sub_device_info[0].subDevices))

            self.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
           
            // ****** code save data in subdevice
            self.cmpShow = true
            self.showStatus(message);
            self.parserRgbSetting();
          } else {
            self.closeModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function () {
          // console.log("err", errr);
        }
      );
    },
    showStatus(message) {
      if (message === "NOTSET") {
        this.result1.check = false;
        this.result2.check = false;
        this.result3.check = false;
        this.result4.check = false;
        this.resultAll.check = false;
        return;
      }

      let L1_r = message.charAt(0);
      let L2_r = message.charAt(1);
      let L3_r = message.charAt(2);
      let L4_r = message.charAt(3);

      this.result1.check = L1_r === "1";
      this.result2.check = L2_r === "1";
      this.result3.check = L3_r === "1";
      this.result4.check = L4_r === "1";
      // this.resultAll.check = message === "1111" || this.pol === 3 && message === "1110" || this.pol === 2 && message === "1100";
    },
    parserRgbSetting() {
      let self = this;
      if (this.info.rgb_setting == null) {
        this.info.rgb_setting = 'L1ON00ff00L1OFFff0000L2ON00ff00L2OFFff0000L3ON00ff00L3OFFff0000L4ON00ff00L4OFFff0000'
      }

      var arrRgb = this.info.rgb_setting.split('L');
      arrRgb.forEach((item, index) => {
        let str = "";
        if (item.match("ON")) {
          str = item.substr(0 + 3, 6);
        }
        if (item.match("OFF")) {
          str = item.substr(0 + 4, 6);
        }
        self.rgbInfo.push({ "id": index, "color": '#' + str })
      })
      this.data = localStorage.getItem("data_color" + this.serial_number) ? JSON.parse(localStorage.getItem("data_color" + this.serial_number)) : {
        onColors: [self.rgbInfo[1].color, self.rgbInfo[3].color, self.rgbInfo[5].color, self.rgbInfo[7].color],
        offColors: [self.rgbInfo[2].color, self.rgbInfo[4].color, self.rgbInfo[6].color, self.rgbInfo[8].color],
      };

    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if(value== "sub_setting"){
        this.$router.push({
          name: "ServiceProSetting",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value === "set_service") {
        this.isModalVisibles = true;
        $("#modal-outlet-momentray").modal("show");
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "AboutServicePro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "wifi_sub_onoff") {
        this.$router.push({
          name: "ServiceProStatusWifi",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "subReset") {
        $("#modal-reset-servicePro").modal('show');
      }
      if (value == "update") {
        this.showUpdateDialog();
      }
      if (value == "editRgb") {
        this.$router.push({
          name: "ServiceEditRGBPro",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStatus();
        // location.reload();
      }
      // if (value == "timer") {
      //   let data = {
      //     labels: this.labels,
      //     pol: this.pol,
      //     type: "PRO",
      //     type_code:this.info.type.type_code,
      //   }
      //   localStorage.setItem("data_edit_sub", JSON.stringify(data));
      //   this.$router.push({
      //     name: "TouchProTimerShow",
      //     params: {
      //       home_id: this.home_id,
      //       serial_number: this.serial_number,
      //       count: this.count,
      //     },
      //   });
      // }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendMessage(status) {

      this.service_status = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.service_status, '');
      }
    },
    sendReq(password) {
      if (this.delayStatus) {
        this.sendSettings(this.service_status, password);
        this.delayStatus = false;
      } else
        this.sendRequest(this.service_status, password);
    },
    setDelay(delay) {
      if (this.set_password == 1) {
        this.service_status = delay;
        this.delayStatus = true;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendSettings(delay, "");
      }
    },
    sendSettings(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      let message = "AUTO:" + saction.auto + "TFAN:" + saction.fan + "TSPRY:" + saction.spray + "#";
      let topic2 = getTopics.getTopicToggleReceive(
        this.serial_number,
        this.subdevice_serial
      )
      this.$mqtt.subscribe(topic2);
      var topic_final = getTopics.getTopicToggleSend(
        this.serial_number,
        this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = "https://server.pfas.ir/api/user/rsay/app/request/send";
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            //  connection=false;
            self.openModalLoading();
            $("#modal-pass-keytouch").modal("hide");
            timeout = setTimeout(function () {
              self.exitModalLoading();

              if (!connection) {
                setTimeout(function () {
                  self.lastStausLamp();
                }, 1000);
                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                }

              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.exitModalLoading();
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
          // console.log("err", errr);
        }
      );
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final = "";

      this.type_Selected_item == 'update' ?
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial) :
        topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    showUpdateDialog() {
      this.$swal({
        title: this.$t("message.public.refresh"),
        text: "آیا میخواهید به روز رسانی  کنید؟",
        type: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("message.public.refresh"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
        (result) => {
          if (result) {
            this.onSubscribe();
            if (this.set_password == 1) {
              this.service_status="UPDATE#"
              $("#modal-pass-code").modal("show");
            } else {
              this.type_Selected_item = "update"
              this.sendRequest('UPDATE#', '');
            }
          }
        },
        (dismiss) => {
          if (dismiss === "cancel") {
          } else {
            throw dismiss;
          }
        }
      );
    },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      if (msg == "UPDATEOK") {
        swal({
          // title: self.$t('message.timer.timer'),
          title: this.$t("message.public.refresh"),
          text: this.$t("message.public.update_done"),
          type: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
    },
    closePass() {
      // location.reload();
      this.lastStausService();
    }
  },
}
</script>

<style scoped></style>