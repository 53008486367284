<template>
 <v-app class="bg mt-0">
  <div class="modal modal-fill fade" tabindex="-1" role="dialog" id="modal-change-pass0" ref="vuemodal1">
   
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <div class="mr-0 bg-white">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <!-- Main content -->
            <section class="content">
              <!-- row -->
              <div class="row">
                <!-- col -->
                <div class="col-12 col-lg-12 p-0">
                  <div class="box">
                    <div class="box-body p-0">
                      <div class="col-lg-12 col-md-12 col-12">
                        <div style="text-align: center">
                          <div class="fontawesome-icon-list m-0">
                            <img
                                src="/assets/images/smart/setpassword.svg"
                                style="width: 300px; height: 300px"
                            />
                            <h5 class="font-weight-bold">
                              {{ $t('message.ModalAddHome.password_hint') }}
                            </h5>
                          </div>
                        </div>
                        <br>
                        <form class="px-20">
                          <div class="form-group">
                            <h6>{{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
                            <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="4"
                                pattern="[0-9]"
                                id="old_pass"
                                autocomplete="off"
                                style="-webkit-text-security: disc;margin-top:8px"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder="$t('message.setting.oldPIN')"
                                v-model="oldpass"
                            />
                            <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="4"
                                pattern="[0-9]"
                                id="new_pass"
                                autocomplete="off"
                                style="-webkit-text-security: disc;margin-top:8px"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder="$t('message.ModalAddHome.password_hint')"
                                v-model="newpass"
                            />
                            <input
                                type="number"
                                class="form-control"
                                required=""
                                aria-invalid="false"
                                maxlength="4"
                                pattern="[0-9]"
                                id="new_pass_repeat"
                                autocomplete="off"
                                style="-webkit-text-security: disc;margin-top:8px"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                :placeholder=" $t('message.ModalAddHome.password_repeat_hint') "
                                v-model="newrepeatpass"
                            />
                          </div>
                          <div class="row">
                            <!-- /.col -->
                            <div class="col-12 text-center">
                              <button
                                  type="button"
                                  class="btn btn-info btn-block margin-top-10"
                                  v-on:click.prevent="FunChangePassword()"
                              >
                                {{ $t('message.public.submit') }}
                              </button>
                            </div>
                            <!-- /.col -->
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
                <!-- /.col -->
              </div>
            </section>
            <!-- /. Main content -->
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    
  </div>
</v-app><!-- /.modal -->

</template>
<script>
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {
  passHomeValidationInput,
  passOldHomeValidationInput,
  passRepeatHomeValidationInput
} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  name: "Modal",
  data: function () {
    return {
      oldpass: '',
      newpass: '',
      newrepeatpass: '',
      title: '',
    }
  },
  props: {
    homeids: {type: Array}
  },
  components: {
    AppBar,
  },
  created() {
    this.title= this.$t('message.setting.defPIN');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingModalChangePass');
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    FunChangePassword() {

      // errchange.innerHTML="";

      var getPasswordOld = this.oldpass;
      var getPasswordNew = this.newpass
      var getPasswordNewRepeat = this.newrepeatpass;

      if (!passOldHomeValidationInput(getPasswordOld) || !passHomeValidationInput(getPasswordNew) || !passRepeatHomeValidationInput(getPasswordNewRepeat)) return
      if (getPasswordNew !== getPasswordNewRepeat) {
        swal("", this.$t('message.ModalAddHome.pasword_error'), "error");
      } else {
        let user_id = localStorage.getItem("user_id");
        let token = localStorage.getItem("token");
        let home_id = localStorage.getItem("home_id");
        var data = {
          user_id: user_id,
          token: token,
          home_id: this.homeids[0],
          old_password: getPasswordOld,
          new_password: getPasswordNew

        }
        //--------------------------------------Request --------------------------------------//
        let URL = getUrl.homePassChange;
        const p = postAjaxCall(URL, data, "");
        // loading.style.display = "block";
        //--------------------------------------Response --------------------------------------//
        p.then(
            function (response) {
              if (responseAjax(response)) {
                let jsonArray = JSON.parse(response.responseText);

                //  errchange.innerHTML="";
                $("#modal-change-pass0").modal('hide');
              } else {
                //error
              }
            },
            //--------------------------------------Err --------------------------------------//
            function (errr) {
              // console.log("err", errr);
            }
        );

      }
    },
    onClickChild(value) {

      if (value == 'back') {
        $("#modal-change-pass0").modal('hide');
      }
    },


  },
};
</script>
<style>