<template>
  <v-app class="bg">
    <div class="mr-0">
      <!-- Main content -->
      <section class="content mt-4">
        <!-- row -->
        <div class="row px-5" v-bind:key="index" v-for="(infos, index) in info">
          <div class="col-12 col-lg-12 py-0 mt-3">
            <div class="box">
              <div class="box-body">
                <table class="table borderless" role="grid">
                  <tbody>
                  <tr role="row">
                    <td class="sorting_1">
                      <span v-if="infos.admin == 1" class="label label-success"
                      >{{ $t('message.public.manager') }}</span
                      >
                      <span
                          v-if="infos.admin == 0"
                          class="label label label-warning"
                      >{{ $t('message.public.guest') }}</span
                      >
                      <strong class="px-2"> {{ infos.fname }} {{ infos.lname }}</strong>
                    </td>

                    <td></td>
                    <td>
                      <div v-if="userphone==infos.mobile">
                      </div>
                      <div v-else>
                        <button v-if="infos.admin == 0 && admin_main==1" type="button" class="btn btn-success  btn-sm"
                                @click="setAdmin(infos.mobile)"><i class="mdi mdi-account mdi-24px"></i>مدیر
                        </button>
                        <button v-if="infos.admin == 1 && admin_main==1" type="button" class="btn btn-yellow btn-sm"
                                @click="setClient(infos.mobile)"><i class="mdi mdi-account mdi-24px"></i>مهمان
                        </button>
                        <button v-if="infos.admin == 0 && admin_main==1"
                                type="button"
                                class="btn btn-circle btn-danger mb-5 m-2"
                                @click="delClient(infos.mobile)"
                        >
                          <i class="mdi mdi-delete mdi-24px"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <my-modal v-show="isModalVisible"></my-modal>
    </div>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
// import { Internets } from "../../public/assets/js/utils/util.js";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  data() {
    return {
      info: "",
      userphone: '',
      homeid: this.home_id,
      data_info: [this.home_id],
      admin_main: '',
      lang: '',
      isModalVisible: false
    };
  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoUser");
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    var tapp = localStorage.getItem("t_app");

    this.admin_main = this.$store.getters.getadmin;
    let details = {
      title: this.$t('message.client.management'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneClient');
    var data = {
      user_id: user_id,
      token: token,
      home_id: this.home_id
    };
    let URL = getUrl.clientIndex;

    const p = postAjaxCall(URL, data, "");
    this.openModalLoading();
    p.then(function (response) {
      var jsonArray = JSON.parse(response.responseText);

      if (responseAjax(response)) {
        self.exitModalLoading();
        self.info = jsonArray.content.clients;
        self.userphone = self.$store.getters.getUserPhone;
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({name: "home"});
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      if (val === "back") {
        this.backMagmentClient()
      }
    }
  },
  methods: {
    addUser() {

      this.$router.push({
        name: "AddClientinfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    delClient(mobile) {

      //  this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "DelClientinfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    showModal() {
      //   this.isModalVisible0 = true;
      $("#modal-add-client").modal("show");
    },
    closeModal() {
      //   this.isModalVisible0 = false;
      $("#modal-add-client").modal("hide");
    },
    setAdmin(mobile) {

      // this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "SetAdmin",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    setClient(mobile) {

      // this.data_info.push(mobile);
      localStorage.setItem("mobile", mobile);
      this.$router.push({
        name: "SetClient",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    backMagmentClient() {

      this.$router.push({
        name: "settingclient", params: {home_id: this.home_id, serial_number: this.serial_number},
      });


    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>