<template>
<div class="text-center">
   <v-btn  @click="callback('btn')" 
       :class="type" 
       :fab="fab"
       :small="small"
       :x-large="xlarge"
       :large="large"
       elevation="2"
       :color="color"
       :block="block"
       height="45"
       :loading="loading"
       
      >
      <v-icon :class="iconColor" v-if="iconShow">
       {{icon}}
      </v-icon>
      <label :class="txtcolor">
       {{txtBtn}}
      </label>
    </v-btn>
</div>
</template>

<script>
export default {
  props: {
     type: String,
     elevation:String,
     icon:String,
     iconColor:String,
     small:false,
     xlarge:false,
     large:false,
     fab:false,
     txtBtn:'',
     iconShow:{
       default:false,
     },
     color:{
       default:'#0F6BB9'
     },
     txtcolor:{
       default:'text-white'
     },
     block: {
       default:true
     },
     loading:{
     default:false
     }
     
  },
  data() {
    return {};
  },
  created() {},
  methods: {
  callback(value) {
      this.$emit("clickedbtn", value);
  },
  },
};
</script>
<style  scoped>
</style>
