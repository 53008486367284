<template>
  <v-row justify="center">
    <!-- <v-dialog v-model="show" persistent max-width="600px"> -->
      <v-card class="py-20 px-2" >
        <v-card-title>
          <slot  name="title"></slot>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align-self="center">
              <v-col cols="12" class="mb-2" align-self="center"> 
                <slot  name="caption"></slot>
              </v-col>
            </v-row>
            <slot  name="body"></slot>
            <v-row>
              <br/>
              <slot  name="alert"></slot>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot  name="btn"></slot>
        </v-card-actions>
      </v-card>
    <!-- </v-dialog> -->
  </v-row>
</template>
<script>
import CmpTips from "../Cmp/public/CmpTips.vue";

export default {
  data() {
    return {
      items: [],
      showError: false,
      textError: "",
      infoDialog: {
        numbers:'',
        logo_path: "",
        secnario: "",
      },
      errName: false,
    };
  },
  components: {
    CmpTips,
  },
  props: {
    value: Boolean,
    data: {},
  },
  watch: {
    show(val) {
      if (val) {
        this.emptyData();
        this.errName=false
      }
    },
    items(newItem, oldName) {
      if(newItem.length!=0){
        this.showError=false;
      }
     
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.errName = false;
  },
  methods: {
    Save() {
      this.validSelect();
    },

    validSelect() {
      console.log('err0',this.selectedScenario)
      console.log('err1',this.number)
        if (this.number==0 || this.selectedScenario==="") {
          console.log('err')
          this.showError = true;
          this.textError = this.$t("message.subDevice.scenarioKey.dialog.message_err");
        } else {
          console.log('ok')
          this.showError = false;
          this.sendData();
        }
    },
    sendData() {
      this.infoDialog.secnario = this.selectedScenario;
      this.infoDialog.numbers = this.number;
      
      this.$emit("clicked", this.infoDialog);
      this.show = false;
    },
    emptyData() {
      this.showError = false;
      // this.infoDialog.name = "";
      // this.name = "";
      this.selectedMode = this.$t("message.relay.relay_on_off");
      this.items = [];
    },
  },
};
</script>
<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
.v-text-field input {
  font-family: "IRANSans";
  font-size: 16px !important;
}
</style>
