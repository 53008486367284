<template>
    <Timer :data="data" @next="onClick"/>
</template>

<script>
import Timer from "../../../Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  name: "nserviceTimer",
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {},
    // status_checkbox: '',
  }),
  props: {
    serial_number: {},
    click: {}
  },
  created() {
    this.data = {
      items: [{
        name: this.$t('message.subDevice.thermostat.thermostat'),
        status: false
      }],
      type: "thermostat"
    }
    this.title = this.$t("message.timer.scheduler");
    // let info = localStorage.getItem("data_edit") ? JSON.parse(localStorage.getItem("data_edit")) : [];
   
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(name, val, status);
      this.next1(status);
    },
    next1(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let status_checkbox;
      if (status) {
        status_checkbox = "TT";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      } else if (!status) {
        status_checkbox = "TF";
        localStorage.setItem("statusTimer", JSON.stringify(status_checkbox));
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "NThermostatStep2Timer",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    onClickChild(value) {
      let self=this;
      if (value == "back") {
        this.$router.push({
          name: "NThermostatTimerShow",
          params: {
            serial_number: self.serial_number,
          },
        });
      }
    },
  }
}
</script>

<style scoped>

</style>