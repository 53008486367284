<template>
  <div>
    
    <div v-if="data.listDevice">
         <nav class="d-flex justify-content-between text-white">
          <div class="d-flex">
            <div class="media align-items-center p-0">
              <i
                  :class="{
                  'ti-angle-left': this.lang === 'en' || this.lang === 'tur',
                  'ti-angle-right': this.lang !== 'en',
                }"
                  style="font-size: 24px"
                  @click="itmeClick('back')"
              ></i>
              <div class="media-body p-0 h5">
                <label>
                  <strong>
                    {{ $t("message.ListDevice.list_device") }}
                  </strong>
                </label>
              </div>
            </div>
          </div>
          <div class="navbar-custom-menu">
            <ul class="list-unstyled d-flex">
              <li
                  class="align-self-center dropdown mx-5 "
                  @click="itmeClick('refresh')"
              >
              <a
                    class="dropdown-toggle"
                    data-toggle="dropdown">
                  <i class="mdi mdi-refresh mdi-24px"></i>
                </a>
              </li>
              <li
                  class="dropdown mx-5 px-5"
                  v-if="data.main_admin != 0"
                  @click="itmeClick('add')"
              >
                <a class="dropdown-toggle" data-toggle="dropdown" >
                  <i class="mdi mdi-plus mdi-36px"></i>
                </a>
              </li>
              <li class="align-self-center mx-5n ">
                <a
                    @click="itmeClick('setting')"
                    data-toggle="control-sidebar"
                ><i class="mdi mdi-settings mdi-24px"></i
                ></a>
              </li>
            </ul>
          </div>
        </nav>
    </div>
    <div v-else-if="data.mainPage">
        <nav class="d-flex justify-content-between ">
          <div class="d-flex">
            <div class="media align-items-center p-0">
              <div @click="itmeClick('menu')">
                <i class="mdi mdi-menu mdi-24px " style="color: white"></i>
              </div>
              <div class="media-body  h5 text-white">
                <label>
                  <strong>
                    {{ $t("message.public.farahoush") }}
                  </strong>
                </label>
              </div>
            </div>
          </div>

          <div class="navbar-custom-menu toolbar">
           
                <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    @click="itmeClick('refresh')"
                >
                  <i class="mdi mdi-refresh mdi-24px"></i>
                </a>
          </div>
        </nav>
   
    </div>
   <div v-else-if="data.noneHome">
      <!-- <header class="main-header"> -->
        <nav class="d-flex justify-content-between text-white">
          <div class="d-flex">
            <div class="media align-items-center p-0">
              <i
                  :class="{
                  'ti-angle-right': this.lang === 'fa',
                  'ti-angle-left': this.lang !== 'fa',
                }"
                  style="font-size: 24px"
                  @click="itmeClick('back')"
              ></i>
              <div class="media-body p-0 h5">
                <label>
                  <strong>{{ data.title }}</strong></label>
              </div>
            </div>
          </div>
          <div class="navbar-custom-menu">
            <!-- <div  v-if="data.noneHome_setting">
                <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    v-on:click.prevent="itmeClick('noneHomeSetting')"
                >
                <i class="mdi mdi-settings mdi-24px"  ></i
                >
              </a>
            </div> -->
            <ul class="nav navbar-nav">
              <li v-if="data.noneHome_setting" class="dropdown">
                <a
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    v-on:click.prevent="itmeClick('noneHomeSetting')"
                >
                <i class="mdi mdi-settings mdi-24px"  ></i
                >
              </a>
              </li>
              <li v-if="data.refresh" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-refresh mdi-24px"
                      v-on:click.prevent="itmeClick('refresh')"
                  ></i>
                </a>
              </li>
              <li v-if="data.add" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-plus mdi-36px"
                      v-on:click.prevent="itmeClick('add')"
                  ></i>
                </a>
              </li>
            </ul>
            
            <ul class="list-unstyled d-flex">
        
              <li class="dropdown" v-if="data.menu">
                <a class="dropdown-toggle" data-toggle="dropdown"
                ><i class="mdi mdi-dots-vertical mdi-24px"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >              
                  <div v-if="data.menuAdd">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('add')"
                    >
                      <i class="mdi mdi-plus"></i
                      >{{ $t("message.public.add") }}</a
                    >
                  </div>
                  <div v-if="data.refreshNone" class="dropdown">
                    <a class="dropdown-item"
                       v-on:click.prevent="itmeClick('refresh')">
                      <i
                          class="mdi mdi-refresh mdi-18px"
                          v-on:click.prevent="itmeClick('refresh')"
                      ></i>
                      {{ $t('message.public.refresh') }}
                    </a>
                  </div>
                  <div v-if="data.config">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('config')"
                    >
                      <i class="mdi mdi-wrench"></i
                      >{{ $t("message.public.configuration") }}</a
                    >
                  </div>
                  <div v-if="data.editName">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('edit')"
                    >
                      <i class="mdi mdi-border-color"></i>
                      {{ $t("message.CmpEditName.edit_name") }}
                    </a>
                  </div>
                  <div v-if="data.change_model">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('change_model')"
                    >
                      <i class="mdi mdi-lock"></i
                      >{{ $t("message.AppBar.change_model") }}
                      <span
                          v-if="this.$store.getters.getModel == 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.model1") }}
                      </span>
                      <span
                          v-if="this.$store.getters.getModel == 2"
                          class="badge badge-success ml-2 mr-2 p-2"
                      >{{ $t("message.AppBar.model2") }}</span
                      >
                    </a>
                  </div>
                  <div v-if="data.del">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('del')"
                    >
                      <i class="mdi mdi-delete"></i
                      >{{ $t("message.public.delete") }}</a
                    >
                  </div>
                   <div v-if="data.shuttersetting">
                    <a class="dropdown-item" @click="itmeClick('shuttersetting')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings") + Array(1).fill('\xa0').join('') +
                        $t("message.AppBar.instantSettings")
                      }}
                    </a>
                  </div>
                  <div v-if="data.timer">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('timer')"
                    >
                      <i class="mdi mdi-clock"></i>
                      {{ $t("message.AppBar.scheduler") }}</a
                    >
                  </div>
                  <div v-if="data.about">
                    <a class="dropdown-item" @click="itmeClick('about')">
                      <i class="mdi mdi-information-outline"></i>
                      {{ $t("message.AppBar.about_device") }}
                    </a>
                  </div>
                  <div v-if="data.pin">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('pass')"
                    >
                      <i class="mdi mdi-lock"></i>
                      {{ $t("message.ModalAddHome.pin_code") }}
                      <span
                          v-if="data.passcode === 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.active") }}</span>
                      <span
                          v-if="data.passcode === 0"
                          class="badge badge-danger ml-2 mr-2 p-2"
                      >
                        {{ $t("message.AppBar.unActive") }}</span>
                    </a>
                  </div>
                </div>
              </li>
            </ul>

            <ul class="nav navbar-nav" v-if="data.noneHome_Relay">
              <li class="dropdown">
                <a data-toggle="dropdown" aria-expanded="false"
                ><i class="ti-more-alt rotate-90"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >
                  <a
                      class="dropdown-item"
                      v-on:click.prevent="itmeClick('edit')"
                  >
                    <i class="mdi mdi-border-color"></i>
                    {{ $t("message.public.edit") }}</a>
                  <a
                      class="dropdown-item"
                      v-on:click.prevent="itmeClick('timer')"
                  >
                    <i class="mdi mdi-clock"></i>
                    {{ $t("message.AppBar.scheduler") }}</a>
                  <a class="dropdown-item" @click="itmeClick('del')">
                    <i class="mdi mdi-delete"></i>
                    {{ $t("message.public.delete") }}
                  </a>
                  <a class="dropdown-item" @click="itmeClick('reset')">
                    <i class="mdi mdi-format-rotate-90"></i>
                    {{ $t("message.noneHome.reset") }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      <!-- </header> -->
    </div>
    <div v-else>
        <header class="main-header personal-header-style">
        <nav class="navbar navbar-static-top personal-nav-style" >
          <div class="navbar-custom-menu toolbar">
            <div class="media p-0">
              <i
                  :class="{
                  'ti-angle-left': this.lang === 'en' || this.lang === 'tur',
                  'ti-angle-right': this.lang !== 'en',
                }"
                  style="font-size: 24px"
                  @click="itmeClick('back')"
              ></i>
              <div class="media-body p-0">
                <label>
                  <strong>{{ data.title }}</strong></label
                >
              </div>
            </div>
          </div>
          <div class="navbar-custom-menu">
            <ul class="nav navbar-nav"
                style="padding-left: 10px"
                v-if="data.noneHome_setting"
            >
              <!-- Control Sidebar Toggle Button -->
              <li>
                <a    
                    data-toggle="control-sidebar"
                >
                <i class="mdi mdi-settings mdi-24px"  v-on:click.prevent="itmeClick('noneHomeSetting')"></i
                >
              </a>
              </li>
            </ul>
            <ul class="nav navbar-nav" v-if="main_admin != 0">
              <!-- Control Sidebar Toggle Button -->
              <li v-if="data.refresh" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-refresh mdi-24px"
                      v-on:click.prevent="itmeClick('refresh')"
                      style="padding:0px;margin-top: 5px;"
                  ></i>
                </a>
              </li>
              <li v-if="data.add" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-plus mdi-36px"
                      v-on:click.prevent="itmeClick('add')"
                  ></i>
                </a>
              </li>
              <li class="dropdown" v-if="data.menu">
                <a data-toggle="dropdown" href="#" aria-expanded="false"
                style="padding:0px;margin-top: 5px;"
                ><i class="ti-more-alt rotate-90"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >
                  <div v-if="data.menuAdd">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('add')"
                    >
                      <i class="mdi mdi-plus"></i
                      >{{ $t("message.public.add") }}</a
                    >
                  </div>
                  <div v-if="data.delAll">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('delAll')"
                    >
                      <i class="mdi mdi-delete"></i
                      >حذف همه</a
                    >
                  </div>
                  <div v-if="data.menuAddEmail">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('menuAddEmail')"
                    >
                      <i class="mdi mdi-plus"></i
                      >
                      <!-- {{ $t("message.public.add") }} -->
                      اضافه کردن با ایمیل
                      </a
                    >
                  </div>
                  <div v-if="data.editName">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('edit')"
                    >
                      <i class="mdi mdi-border-color"></i>
                      {{ $t("message.CmpEditName.edit_name") }}
                    </a>
                  </div>
                  <div v-if="data.edit">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('edit')"
                    >
                      <i class="mdi mdi-border-color"></i>
                      {{ $t("message.public.edit") }}
                    </a>
                  </div>
                  <div v-if="data.menuSetting">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('menuSetting')"
                    >
                    <i class="mdi mdi-settings"></i>
                    {{ $t("message.public.setting") }}
                    </a>
                  </div>
                  <div v-if="data.config">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('config')"
                    >
                      <i class="mdi mdi-wrench"></i
                      >{{ $t("message.public.configuration") }}</a
                    >
                  </div>
                  <div v-if="data.change_model">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('change_model')"
                    >
                      <i class="mdi mdi-lock"></i
                      >{{ $t("message.AppBar.change_model") }}
                      <span
                          v-if="this.$store.getters.getModel == 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.model1") }}
                      </span>
                      <span
                          v-if="this.$store.getters.getModel == 2"
                          class="badge badge-success ml-2 mr-2 p-2"
                      >{{ $t("message.AppBar.model2") }}</span
                      >
                    </a>
                  </div>
                  <div v-if="data.del">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('del')"
                    >
                      <i class="mdi mdi-delete"></i
                      >{{ $t("message.public.delete") }}</a
                    >
                  </div>
                  <div v-if="data.momentary">
                    <a class="dropdown-item" @click="itmeClick('momentary')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings") + Array(1).fill('\xa0').join('') +
                        $t("message.AppBar.instantSettings")
                      }}
                    </a>
                  </div>
                  <div v-if="data.shuttersetting">
                    <a class="dropdown-item" @click="itmeClick('shuttersetting')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings") + Array(1).fill('\xa0').join('') +
                        $t("message.AppBar.instantSettings")
                      }}
                    </a>
                  </div>
                  <div v-if="data.set_service">
                    <a class="dropdown-item" @click="itmeClick('service')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings")
                      }}
                    </a>
                  </div>
                  <div v-if="data.timer">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('timer')"
                    >
                      <i class="mdi mdi-clock"></i>
                      {{ $t("message.AppBar.scheduler") }}</a
                    >
                  </div>
                  <div v-if="data.update">
                    <a class="dropdown-item" @click="itmeClick('update')">
                      <i class="mdi mdi-cached"></i>
                      <!-- {{ $t("message.AppBar.about_device") }} -->
                      به روز رسانی
                    </a>
                  </div>
                  <div v-if="data.editRgb">
                    <a class="dropdown-item" @click="itmeClick('editRgb')">
                      <i class="mdi mdi-white-balance-incandescent"></i>
                      <!-- {{ $t("message.AppBar.about_device") }} -->
                     تنظیمات RGB
                    </a>
                  </div>
                  <div v-if="data.wifi_sub_onoff">
                    <a class="dropdown-item" @click="itmeClick('wifi_sub_onoff')">
                      <i class="mdi mdi-toggle-switch-off"></i>
                      <!-- {{ $t("message.AppBar.about_device") }} -->
                    وضعیت wifi دستگاه
                    </a>
                  </div>
                  <div v-if="data.subReset">
                    <a class="dropdown-item" @click="itmeClick('subReset')">
                      <i class="mdi mdi-delete-forever"></i>
                      <!-- {{ $t("message.AppBar.about_device") }} -->
                    حذف سخت افزاری
                    </a>
                  </div>
                  <div v-if="data.pin">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('pass')"
                    >
                      <i class="mdi mdi-lock"></i
                      >{{ $t("message.ModalAddHome.pin_code") }}
                      <span
                          v-if="data.passcode === 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.active") }}</span
                      >
                      <span
                          v-if="data.passcode === 0"
                          class="badge badge-danger ml-2 mr-2 p-2"
                      >
                        {{ $t("message.AppBar.unActive") }}</span
                      >
                    </a>
                  </div>
                  <div v-if="data.about">
                    <a class="dropdown-item" @click="itmeClick('about')">
                      <i class="mdi mdi-information-outline"></i>
                      {{ $t("message.AppBar.about_device") }}
                    </a>
                  </div>
                </div>
              </li>
              <li v-if="data.sub_setting" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-settings mdi-24px"
                      v-on:click.prevent="itmeClick('sub_setting')"
                      style="padding:0px;margin-top: 5px;"
                  ></i>
                </a>
              </li> 
            </ul>
            <ul class="nav navbar-nav" v-if="data.none">
              <!-- Control Sidebar Toggle Button -->
              <li v-if="data.refresh" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-refresh mdi-24px"
                      v-on:click.prevent="itmeClick('refresh')"
                  ></i>
                </a>
              </li>
              <li v-if="data.add" class="dropdown">
                <a class="dropdown-toggle" data-toggle="dropdown">
                  <i
                      class="mdi mdi-plus mdi-36px"
                      v-on:click.prevent="itmeClick('add')"
                  ></i>
                </a>
              </li>
              <li class="dropdown" v-if="data.menu">
                <a data-toggle="dropdown" href="#" aria-expanded="false"
                ><i class="ti-more-alt rotate-90"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >
                  <!-- <div v-if="data.menuAdd">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('add')"
                    >
                      <i class="mdi mdi-plus"></i
                      >{{ $t("message.public.add") }}</a
                    >
                  </div> -->
                  <div v-if="data.editName">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('edit')"
                    >
                      <i class="mdi mdi-border-color"></i>
                      {{ $t("message.CmpEditName.edit_name") }}
                    </a>
                  </div>
                 
                  <div v-if="data.config">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('config')"
                    >
                      <i class="mdi mdi-wrench"></i
                      >{{ $t("message.public.configuration") }}</a
                    >
                  </div>
                  <div v-if="data.change_model">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('change_model')"
                    >
                      <i class="mdi mdi-lock"></i
                      >{{ $t("message.AppBar.change_model") }}
                      <span
                          v-if="this.$store.getters.getModel == 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.model1") }}
                      </span>
                      <span
                          v-if="this.$store.getters.getModel == 2"
                          class="badge badge-success ml-2 mr-2 p-2"
                      >{{ $t("message.AppBar.model2") }}</span
                      >
                    </a>
                  </div>
                  <div v-if="data.del">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('del')"
                    >
                      <i class="mdi mdi-delete"></i
                      >{{ $t("message.public.delete") }}</a
                    >
                  </div>
               
                  <div v-if="data.momentary">
                    <a class="dropdown-item" @click="itmeClick('momentary')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings") + Array(1).fill('\xa0').join('') +
                        $t("message.AppBar.instantSettings")
                      }}
                    </a>
                  </div>
                   <div v-if="data.shuttersetting">
                    <a class="dropdown-item" @click="itmeClick('shuttersetting')">
                      <i class="mdi mdi-timer-sand"></i
                      >{{
                        $t("message.public.settings") + Array(1).fill('\xa0').join('') +
                        $t("message.AppBar.instantSettings")
                      }}
                    </a>
                  </div>
                  <div v-if="data.timer">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('timer')"
                    >
                      <i class="mdi mdi-clock"></i>
                      {{ $t("message.AppBar.scheduler") }}</a
                    >
                  </div>
                  <div v-if="data.about">
                    <a class="dropdown-item" @click="itmeClick('about')">
                      <i class="mdi mdi-information-outline"></i>
                      {{ $t("message.AppBar.about_device") }}
                    </a>
                  </div>
                  <div v-if="data.pin">
                    <a
                        class="dropdown-item"
                        v-on:click.prevent="itmeClick('pass')"
                    >
                      <i class="mdi mdi-lock"></i
                      >{{ $t("message.ModalAddHome.pin_code") }}
                      <span
                          v-if="data.passcode === 1"
                          class="badge badge-success badge-sm p-2 ml-2 mr-2"
                      >{{ $t("message.AppBar.active") }}</span
                      >
                      <span
                          v-if="data.passcode === 0"
                          class="badge badge-danger ml-2 mr-2 p-2"
                      >
                        {{ $t("message.AppBar.unActive") }}</span
                      >
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="nav">
              <li class="dropdown" v-if="data.noneHome_Relay">
                <a data-toggle="dropdown" aria-expanded="false"
                ><i class="ti-more-alt rotate-90"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >
                </div>
              </li>
              <li v-if="data.check">
                <v-btn icon @click="itmeClick('done')">
                  <v-icon color="#ffffff">mdi-check</v-icon>
                </v-btn>
              </li>
            </ul>
          </div>
          <div class="navbar-custom-menu" v-if="data.profile">
            <ul class="nav navbar-nav">
              <!-- Control Sidebar Toggle Button -->
              <li class="dropdown ">
                <a data-toggle="dropdown" href="#" aria-expanded="false"
                ><i class="ti-more-alt rotate-90"></i
                ></a>
                <div
                    class="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                    :style="[lang=='fa' ? dotMenuFa : dotMenuEn]"
                >
                  <a
                      class="dropdown-item"
                      v-on:click.prevent="itmeClick('edit')"
                  >
                    <i class="mdi mdi-border-color"></i>
                    {{ $t("message.AppBar.editProfile") }}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <!-- /.Header Navbar -->
      </header>
        <!-- /.Header Navbar -->
   
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  props: {
    update: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      // admin: 0,
      lang: "",
      data: {
        title: "",
        setting: false,
        add: false,
        refresh: false,
        timer: false,
        menu: false,
        momentary: false,
        shuttersetting: false,
        about: false,
        profile: false,
        noneHome_setting: false,
        noneHome_Relay: false,
        toggle: {},
        change_model: false,
        noneHome: false,
        config: false,
        menuAdd: false,
        menuAddEmail:false,
        menuSetting: false,
        pin: false,
        passcode: false,
        editName: false,
        edit: false,
        del: false,
        delAll: false,
        none: false,
        check: false,
        refreshNone: false,
        listDevice: false,
        main_admin: 0,
        set_service: false,
        update:false,
        editRgb:false,
        subReset:false,
        wifi_sub_onoff:false,
      },
      condition: true,
      dotMenuEn: {
        position: "absolute",
        transform: " translate3d(20px, 21px, 0px)",
        top: "0px",
        right: "50px",
        willChange: "transform",
      },
      dotMenuFa: {
        position: "absolute",
        transform: " translate3d(-43px, 21px, 0px)",
        top: "0px",
        left: '50px',
        willChange: "transform",
      },
    };
  },
  created() {
    // this.lang = localStorage.getItem("Lang");
    this.lang = this.$i18n.locale
    if (localStorage.getItem("infoHome") != null) {
      this.$store.dispatch("infoHome");
      this.main_admin = this.$store.getters.getadmin;
    }
    this.updated()
  },
  watch: {
    update() {
      this.updated()
    }
  },
  mounted() {
    if (localStorage.getItem("infoHome") != null) {
      this.$store.dispatch("infoHome");
      this.main_admin = this.$store.getters.getadmin;
    }
  },
  methods: {
    updated() {
      this.data = {
        title: "",
        setting: false,
        add: false,
        refresh: false,
        timer: false,
        menu: false,
        sub_setting:false,
        momentary: false,
        shuttersetting: false,
        about: false,
        profile: false,
        noneHome_setting: false,
        noneHome_Relay: false,
        toggle: {},
        change_model: false,
        noneHome: false,
        config: false,
        menuAdd: false,
        pin: false,
        passcode: false,
        editName: false,
        edit: false,
        del: false,
        delAll: false,
        none: false,
        check: false,
        refreshNone: false,
        listDevice: false,
        main_admin: 0,
        set_service: false,
        update:false,
        editRgb:false,
        subReset:false,
        wifi_sub_onoff:false,
        
      }
      if (localStorage.getItem("infoHome") != null) {
      this.$store.dispatch("infoHome");
      this.data.main_admin = this.$store.getters.getadmin;
    }
      let data = localStorage.getItem("AppBar") ? JSON.parse(localStorage.getItem("AppBar")) : {};
      for (const item in data) {
        this.data[item] = data[item];
      }
    },
    itmeClick(val) {
      if (val == "back") {
        this.$emit("clicked", "back");
      }
      if (val == "edit") {
        this.$emit("clicked", "edit");
      }
      if (val == "menuSetting") {
        this.$emit("clicked", "menuSetting");
      }
      if (val == "del") {
        this.$emit("clicked", "del");
      }
      if (val == "delAll") {
        this.$emit("clicked", "delAll");
      }
      if (val == "timer") {
        this.$emit("clicked", "timer");
      }
      if (val == "pass") {
        this.$emit("clicked", "pass");
      }
      if (val == "about") {
        this.$emit("clicked", "about");
      }
      if (val == "momentary") {
        this.$emit("clicked", "momentary");
      }
        if (val == "shuttersetting") {
        this.$emit("clicked", "shuttersetting");
      }
      if (val == "refresh") {
        this.$emit("clicked", "refresh");
      }
      if (val == "add") {
        this.$emit("clicked", "add");
      }
      if (val == "menuAddEmail") {
        this.$emit("clicked", "menuAddEmail");
      }
      if (val === "noneHomeSetting") {
        this.$emit("clicked", "noneHomeSetting");
      }
      if (val == "setting") {
        this.$emit("clicked", "setting");
      }
      if (val == "change_model") {
        this.$emit("clicked", "change_model");
      }
      if (val == "config") {
        this.$emit("clicked", "config");
      }
      if (val == "reset") {
        this.$emit("clicked", "reset");
      }
      if (val == 'toggle') {
        this.$emit('clicked', 'toggle')
      }
      if (val == 'done') {
        this.$emit('clicked', 'done')
      }
      if (val == 'service') {
        this.$emit('clicked', 'set_service')
      }
      if (val == 'menu') {
        this.$emit('clicked', 'menu')
      }
      if (val == 'sub_setting') {
        this.$emit('clicked', 'sub_setting')
      }
      if (val == 'update') {
        this.$emit('clicked', 'update')
      }
      if (val == 'editRgb') {
        this.$emit('clicked', 'editRgb')
      }
      if (val == 'subReset') {
        this.$emit('clicked', 'subReset')
      }
      if (val == 'wifi_sub_onoff') {
        this.$emit('clicked', 'wifi_sub_onoff')
      }
      // if (val == 'menuAdd') {
      //   this.$emit('clicked', 'menuAdd')
      // }
    }
  },
};
</script>
<style scope>
.v-application ol,
.v-application ul {
  padding-left: 10px !important;
}

.dotMenuEn {
  position: absolute;
  transform: translate3d(20px, 21px, 0px);
  top: 0px;
  right: 50px;
  will-change: transform;
}

.dotMenuFa {
  position: absolute;
  transform: translate3d(-43px, 21px, 0px);
  top: 0px;
  left: 50px;
  will-change: transform;
}
.personal-header-style{
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  height: 65px !important;
}
.personal-nav-style{
  box-shadow: none !important;
}
.v-toolbar__content{
  /* padding: 0 !important; */
}
</style>

