<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="m-10">
        <v-row>
          <v-col md="6" cols="12" class="py-0">
            <div class="box mt-20">
              <div class="box-header with-border">
                <h5 class="box-title"><i class="mdi mdi-tilde mdi-24px"></i>{{ $t('message.report.chart') }}
                  {{ $t('message.RelayKey.I') }}</h5>
                <ul class="box-controls pull-right">
                  <li><a class="box-btn-slide" href="#"></a></li>
                </ul>
              </div>
              <div class="box-body">
                <div class="chart">
                  <iframe
                      class="chartjs-hidden-iframe"
                      style="
              width: 100%;
              display: block;
              border: 0px;
              height: 0px;
              margin: 0px;
              position: absolute;
              inset: 0px;
            "
                  ></iframe>
                  <line-chart :chart-data="datacollectiona"></line-chart>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
          </v-col>
          <v-col md="6" cols="12" class="py-0">
            <div class="box mt-20">
              <div class="box-header with-border">
                <h5 class="box-title"><i class="mdi mdi-thermometer mdi-24px"></i>{{ $t('message.report.chart') }}
                  {{ $t('message.RelayKey.T') }}</h5>

                <ul class="box-controls pull-right">
                  <li><a class="box-btn-slide" href="#"></a></li>
                </ul>
              </div>
              <div class="box-body">
                <div class="chart">
                  <iframe
                      class="chartjs-hidden-iframe"
                      style="
              width: 100%;
              display: block;
              border: 0px;
              height: 0px;
              margin: 0px;
              position: absolute;
              inset: 0px;
            "
                  ></iframe>
                  <line-chart :chart-data="datacollection"></line-chart>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
          </v-col>
          <v-col md="6" cols="12" class="py-0">
            <div class="box mt-20">
              <div class="box-header with-border">
                <h5 class="box-title"><i class="mdi mdi-pulse mdi-24px"></i>{{ $t('message.report.chart') }}
                  {{ $t('message.RelayKey.V') }}</h5>
                <ul class="box-controls pull-right">
                  <li><a class="box-btn-slide" href="#"></a></li>
                </ul>
              </div>
              <div class="box-body">
                <div class="chart">
                  <iframe
                      class="chartjs-hidden-iframe"
                      style="
              width: 100%;
              display: block;
              border: 0px;
              height: 0px;
              margin: 0px;
              position: absolute;
              inset: 0px;
            "
                  ></iframe>
                  <line-chart :chart-data="datacollectionv"></line-chart>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import LineChart from "./LineChart.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {
    LineChart
  },
  data() {
    return {
      datacollection: null,
      datacollectionv: null,
      datacollectiona: null,
      title: '',
      isModalVisible: false
    };
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  mounted() {
    this.title = this.$t('message.setting.report');
    var self = this;
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingReport');
    setTimeout(function () {
      self.ChartFunction();
    }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    ChartFunction() {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var home_id = this.home_id;
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.deviceReport;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      setTimeout(() => {
        self.closeModalLoading()
      }, 5000)
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.closeModalLoading();
              let content = jsonArray.content;

              content.forEach(function (item) {
                var Current = item.device_current;
                var Volt = item.device_voltage;
                var Temp = item.device_temperature;
                var date = item.date;


                self.datacollection = {
                  labels: date,
                  datasets: [
                    {
                      label: this.$t('message.RelayKey.T'),
                      backgroundColor: "#ffc107",
                      borderColor: "#dc3545",
                      data: Temp,
                    },
                  ],
                };
                self.datacollectionv = {
                  labels: date,
                  datasets: [
                    {
                      label: this.$t('message.RelayKey.V'),
                      backgroundColor: "#96BB7C",
                      borderColor: "#28a745",
                      data: Volt,
                    },
                  ],
                };
                self.datacollectiona = {
                  labels: date,
                  datasets: [
                    {
                      label: this.$t('message.RelayKey.I'),
                      backgroundColor: "#00BCD4",
                      borderColor: "#3282B8",
                      data: Current,
                    },
                  ],
                };
              });
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({
          name: "settings", params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    }
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>