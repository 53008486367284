<template>
  <v-app class="bg">
    <div class="mt-2 px-4">
      <section class="content">
        <!-- <div class="media-list media-list-divided media-list-hover">
          <div class="media align-items-center setting">
            <i class="mdi mdi-barcode mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</p>
           
                <h2 class="mt-2 text-primary">
                {{deviceID}}

                </h2>
            </div>
          </div>
        </div> -->
        <div :key="index" v-for="(item, index) in List">
          <CmpRowList :infoItem="item" :subTitleShow="true" :icon="true" :ripple="false" ></CmpRowList>
        </div>
      </section>
    </div>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../layouts/LayoutDefault";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";

export default {
  components: {CmpRowList},
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      homeid: this.home_id,
      serialnumber: this.serial_number,
      title: "",
      deviceID:'',
      List: [],
    };
  },
  mounted() {
    this.deviceID = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
    this.$store.dispatch("InfoSubDevice");
    this.title = this.$t('message.AppBar.about_device');
    this.List.push({
      index: 1,
      title: this.$t("message.ModalAddSubDeviceFaraPol.serial"),
      actions: '',
      switch: false,
      subTitle: this.deviceID,
      icon: "mdi mdi-barcode"
    });
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutCurtainPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "ElevatorProSetting",
          params: {
            serial_number: this.serial_number,
            // home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
