import i18n from "../../../../src/i18n";
export function getThermostatStatus(message,version) {
    console.log(message)
    var temp='';
    var fan='';
    var mode='';

    if(version=='V1'){
        if(message.match("TOFF")){
            return i18n.t('message.public.off');
        }
        if(message.match("TON")){
            temp = message.substr(message.indexOf(message.match("TP")) + 2, 2);
            if (message.match("HEAT")) mode=i18n.t('message.subDevice.thermostat.heat')
            if (message.match("COOL")) mode=i18n.t('message.subDevice.thermostat.cool')
            if (message.match("FANHIGH")) fan=i18n.t('message.subDevice.thermostat.fan_high')
            if (message.match("FANLOW")) fan=i18n.t('message.subDevice.thermostat.fan_low')
            if (message.match("FANMED")) fan=i18n.t('message.subDevice.thermostat.fan_med')
            if (message.match("FANAUTO")) fan=i18n.t('message.subDevice.thermostat.fan_auto')
        }
      return temp+'°'+Array(3).fill('\xa0').join('')+mode +Array(3).fill('\xa0').join('')+ fan ;   
    } 
}