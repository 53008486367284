<template>
  <div>
    
    <button
     v-if="deferredPrompt"
      type="button"
      ref="addBtn"
      class="btn btn-success btn-block mt-10"
      v-on:click.prevent="clickCallback"
    >
      <storng class="text-white">{{$t('message.public.install')}} </storng>
    </button>
  </div>
</template>

<script>
// import VueLoadingButton from "vue-loading-button";
export default {
  name: "AddToHomeScreen",
  data: () => ({
    deferredPrompt: null,

  }),
  components: {
    // VueLoadingButton,
  },
  mounted() {
    this.captureEvent();
   
  },
  methods: {
    captureEvent() {
      window.addEventListener("beforeinstallprompt", (e) => {
        // ! Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // console.log("e", e);
      });

    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt();
       localStorage.setItem("cache",false);
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // Call another function?
        }else{
        }
        this.deferredPrompt = null;
      });
    },
  },
};
</script>