<template>
  <v-app  class="bg">
   <div class="px-3 mt-8">
      <v-card max-width="1750" class="mx-auto">
          <v-row class="justify-center px-3 py-0">
          <v-col cols="1" class="align-self-center">
            <v-icon color="primary">mdi-format-color-text</v-icon>
          </v-col
          >
          <v-col cols="4" class="align-self-center">
            <label class="mt-1">
              <!-- {{ $t("message.relay.relay_name") }} -->
         نام سنسور
            </label>
          </v-col>
          <v-col cols="7" class="align-self-center">
            <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.relay.relay_name_input')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                   v-model="name"
                  maxlength="32"
              >
              </v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card max-width="1750" class="mx-auto mt-5">
      <div :key="index" v-for="(item, index) in info" >
              <CmpRowList
                  :infoItem="item"
                  :subTitleShow="false"
                  :ripple="false"
                  :icon="true"
                  :img="false"
              ></CmpRowList>
              </div>
            </v-card>
    </div>
    <div class="fixed-bottom mb-1 px-4">
   <cmpbutton
         class="mb-1"
         :txtBtn="'ارسال دستور'"
         :loading="loading"
         @clickedbtn="CallBackBtn">
  </cmpbutton>
   </div>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../../layouts/LayoutDefault";
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";

import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import {CommandAlarm} from "@/../public/assets/js/utils/get_alarm_command.js"
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {SucessToastMsg,ErrorToastMsg,BetweenStr,} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";

var connection = false;
var showToast;
var timeout;

export default {
  components: {
    cmpbutton,
    CmpRowList
  },
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      title: "",
      loading:false,
      name:'',
      info:[],
      isModalVisible: false,
      countWs:'',
      user_id:'',
      token :'',
      subdevice_serial:'',
    };
  },
  mqtt: {
    "+/+/receive/+/sensor"(data, topic) {
      console.log('DATA',String(data));
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        connection = true;
        this.loading=false;
        clearTimeout(timeout);
        // this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1 && String(data).match("SECURITY")) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  mounted() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    this.info.push(
      { index:1, title:'پیامک', actions:'switch', icon:"mdi-email-outline"},
      { index:2, title:'تماس', actions:'switch', icon:"mdi-phone"},
      { index:3, title:'هشدار', actions:'switch', icon:"mdi-bell-ring-outline"}
      ) 
    this.title = 'افزودن سنسور بی سیم';
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'wirelessAdd');

    let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      // this.main_admin = this.$store.getters.getadmin;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.onSubscribe();
      this.sendCountRequest();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "Wireless24Info",
          params: {home_id:this.home_id,serial_number: this.serial_number},
        });
      }
    },
    sendReq(password) {
      this.sendRequest(curtain_status, password);
    },
    sendRequest(saction, password) {
      var self = this;
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModalLoading();
              // $("#modal-pass-curtain").modal("hide");
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                self.loading=false
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    sendCountRequest() {
      var self = this;
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.subdevice_serial,
        type:1,
        };
      // --------------------Requsest----------------------//
      let URL = getUrl.subAlarmCount;
      const p = postAjaxCall(URL, data);
     
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.countWs24=jsonArray.content.alarm_sensor_count;
              console.log("count ",self.countWs24)
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicSensorReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    CallBackBtn(){
      this.loading=true;
      if(this.countWs.toString.length==1){
        this.countWs='0'+this.countWs;
      }
      this.sendRequest(CommandAlarm.getCommandAddWS(self.countWs,this.info[0].switch,this.info[1].switch,this.info[2].switch), '')
    }
  },
};
</script>
<style scoped>
</style>
