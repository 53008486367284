<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i class="ti-angle-right" style="font-size:24px" @click="goBackTimerShow()"></i>
            <div class="media-body p-0">
              <strong>{{ $t('message.timer.scheduler_show') }}</strong></div>
          </div>
        </div>
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <!-- Notifications -->
            <li class="dropdown" v-if="this.$store.getters.getadmin==1" v-on:click.prevent="AddTimer()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="mdi mdi-plus mdi-36px"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row" v-bind:key="index" v-for="(infos, index) in info">
          <div class="col-12 col-lg-12">
            <div class="box">
              <div class="box-body">
                <div class="table-responsive">
                  <table class="table product-overview mb-0">
                    <tbody>
                    <tr>
                      <td class="border-0">
                        <!-- <img src="../../images/product/product-1.png" alt="" width="80"> -->
                        <i
                            class="mdi mdi-timer mdi-24px"
                            style="color: #007bff"
                        ></i>
                      </td>
                      <td class="border-0">
                        <h6></h6>
                        <p>{{ $t('message.timer.timer') }} {{ infos.count }}</p>
                        <div style="display: flex">
                          <p width="15px"
                             height="15px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day6 === '1')||(lang==='en' && infos.day1 ==='1') }"
                          >
                            {{ $t('message.timer.D1') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day7 === '1')||(lang==='en' && infos.day2 ==='1') }"
                          >
                            {{ $t('message.timer.D2') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day1 === '1')||(lang==='en' && infos.day3 ==='1') }"
                          >
                            {{ $t('message.timer.D3') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day2 === '1')||(lang==='en' && infos.day4 ==='1') }"
                          >
                            {{ $t('message.timer.D4') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day3 === '1')||(lang==='en' && infos.day5 ==='1') }"
                          >
                            {{ $t('message.timer.D5') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day4 === '1')||(lang==='en' && infos.day6 ==='1') }"
                          >
                            {{ $t('message.timer.D6') }}
                          </p>
                          <p width="12px"
                             height="12px"
                             style="margin-right:3px"
                             :class="{'blue': (lang==='fa' && infos.day5 === '1')||(lang==='en' && infos.day7 ==='1') }"
                          >
                            {{ $t('message.timer.D7') }}
                          </p>
                        </div>
                      </td>
                      <td class="border-0">
                        <p class="font-size-12 mt-4">
                          {{ $t('message.timer.start') }} : {{ infos.time1 }}
                        </p>
                        <p class="font-size-12">{{ $t('message.timer.end') }} : {{ infos.time2 }}</p>
                      </td>
                      <td class="border-0" width="50" align="center">{{ infos.status }}</td>
                      <td class="border-0" align="center" @click="deleteTimer(infos.count)">
                        <i class="ti-trash text-danger" style=" font-size: 18px;"></i>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
  </div>
  <!-- /. Wrapper -->
</template>

<script>

import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import {getTimerData} from "../../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
var count_timer;
var connection = false;

export default {
  components: {},
  data: () => ({
    info: '',
    lang: '',
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {}

  },
  created() {
    this.lang = localStorage.getItem("Lang");
  },
  mqtt: {
    "+/+/receive/256/timer"(data, topic) {
      if (topic.split("/")[1] === this.$store.getters.getSerialSub) {
        console.log("topic:", topic);
        console.log("data1 mqtt", data + "");
        this.checkMessage(String(data));
      }
    },
  },
  mounted() {
    let self = this
    this.$store.dispatch("infoHome");
    this.$store.dispatch("InfoSubDevice");
    setTimeout(function () {
      self.lastStatus();
    }, 100);

  },

  methods: {
    lastStatus() {
      let self = this

      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = this.serial_number;
      let sucdevice_sn = this.$store.getters.getSerialSub;

      var data = {
        user_id: user_id,
        token: token,
        gateway_sn: serial_number,
        count: sucdevice_sn
      }

      //-----------------------Request ------------------------------//
      let URL = getUrl.timers;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {

              console.log("jsonArray", jsonArray);
              self.exitModalLoading();
              let content = jsonArray.content.timers;
              var finaldata = [];
              console.log("content", content);
              content.forEach(function (item) {
                console.log("item", item.message);
                const data = getTimerData(item.message);

                finaldata.push(data);
                self.info = finaldata;


              });

            } else {
              //error
              console.log("err")
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == '4001') {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    deleteTimer(counttimer) {
      let self = this;

      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_isDelete'),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: self.$t('message.public.delete'),
        cancelButtonText: self.$t('message.public.cancel'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        if (isConfirm) {
          // swal("Deleted!", "Your imaginary file has been deleted.", "success");
          self.sendDelete(counttimer);
        }
      });
    },
    sendDelete(counttimer) {
      let self = this
      let tapp = localStorage.getItem("t_app");
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      let subdevice_serial = this.$store.getters.getSerialSub;
      let count = this.count;
      var topic_final;
      count_timer = counttimer;
      let message = "TIMER" + count_timer + "-OFF#"

      if (tapp == 1) {
        topic_final = "rnd/" + subdevice_serial + "/s/" + "256" + "/~timer";
      } else {
        topic_final = "farahoush/" + subdevice_serial + "/send/" + "256" + "/~timer";
      }

      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        client_id: 'fara' + Date.now()
      }

      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              console.log("jsonArray", jsonArray);
              self.subcribe();
              setTimeout(function () {
                if (!connection) {
                  swal("", self.$t('message.public.operation_failure'), "error");
                  self.exitModalLoading();
                }
              }, 10000);

            } else {
              //error
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    subcribe() {

      let tapp = localStorage.getItem("t_app");
      var device_serial = this.serial_number;
      console.log("mqttSend", device_serial)
      var topic;
      if (tapp == 1) {
        topic = "rnd/" + device_serial + "/r/256/timer";
      } else {
        topic = "farahoush/" + device_serial + "/receive/256/timer";
      }
      this.$mqtt.subscribe(topic);
    },
    checkMessage(msg) {
      let self = this;
      this.exitModalLoading();
      console.log("checkMqtt", msg)
      let device_serial = this.serial_number
      swal({
        title: self.$t('message.timer.timer'),
        text: self.$t('message.timer.timer_delete'),
        type: "success",
        timer: 2000,
        showConfirmButton: false
      });
      setTimeout(function () {
        //  self.$router.push({name: "mainpage"});

        location.reload();
      }, 2005);


    },
    openModalLoading() {
      this.$parent.showModal();
    },
    exitModalLoading() {
      this.$parent.closeModal();
    },
    goBackTimerShow() {
      // this in mistake
      this.$router.push({
        name: "SubrelayInfo",
        params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
      });

    },
    AddTimer() {
      this.$router.push({
        name: "SubRelaytimer",
        params: {serial_number: this.serial_number, home_id: this.home_id, count: this.count},
      });

    }

  },
};
</script>


<style scoped>
</style>
