<template>
  <!-- .wrapper  -->
  <div class="wrapper">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
       <div class="navbar-custom-menu toolbar">
         <div class="media p-0">
           <i class="ti-angle-right" style="font-size:24px" @click="goBackTimer()"></i>
           <div  class="media-body p-0">
             <strong>{{ $t('message.AppBar.Scheduler') }}</strong>
           </div>
         </div>     
        </div>

      </nav>
      <!-- /.Header Navbar -->
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box" style="height: 812px">
              <div class="box-body p-0">
                <div class="media-list media-list-hover media-list-divided">
                  <div class="media media-single p-20">
                    <div class="media-body">
                      <h6><a href="#">{{ $t('message.timer.outlet') }}</a></h6>
                    </div>
                    <div class="media-body">
                      <label class="switch">
                        <input
                          name="checkoutlet"
                          id="checktimer"
                          type="checkbox"
                        />
                        <span class="switch-indicator"></span>
                      </label>
                    </div>
                    <div class="media-right">
                      <a
                        class="btn btn-block btn-primary btn-sm text-white"
                        v-on:click.prevent="next1()"
                        >{{ $t('message.public.next') }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- Modal -->
    <div
      class="modal center-modal fade"
      id="modal-center"
      tabindex="-1"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{$t('message.ModalCenter.loading')}}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{$t('message.ModalCenter.loading')}}</span>
            </div>
            <span class="pl-20" id="txtwait">{{$t('message.ModalCenter.wait')}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal -->
  </div>
  <!-- /. Wrapper -->
</template>

 <script>

export default {
  components: {},
  data: () => ({}),
  props: {
    serial_number: {},
   
  
  },
  mounted() {
    let self=this;
    let checkbox1 = document.getElementById("checktimer");
    let status_checkboxL1="P1F";
  localStorage.setItem("statusTimer", JSON.stringify(status_checkboxL1));

  checkbox1.addEventListener("change", function (element) {
    if (checkbox1.checked) {
        status_checkboxL1 =  "P1T";
          localStorage.setItem("statusTimer", JSON.stringify(status_checkboxL1));
         self.$root.$emit('did-something', status_checkboxL1);
    } else if (!checkbox1.checked) {
        status_checkboxL1 = "P1F";  
              localStorage.setItem("statusTimer", JSON.stringify(status_checkboxL1));

         self.$root.$emit('did-something', status_checkboxL1);
    }
  });
  },
  methods: {
    next1() {
      
       this.$router.push({
            name: "SubRelayStep2Timerinfo",
            params: {
             
              serial_number: this.serial_number,
             
            },
          });
    },
    goBackTimer(){
       this.$router.push({
            name: "SubRelayTimerShow",
            params: {
             
              serial_number: this.serial_number,
             
            },
          });
    }
  },
};
</script>



<style scoped>
</style>
