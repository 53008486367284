var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"bg"},[_c('section',{staticClass:"content mt-5"},[_c('div',{staticClass:"row px-20",attrs:{"id":"app"}},_vm._l((_vm.info),function(infos,index){return _c('div',{key:index,staticClass:"col-4 col-lg-4 px-1 py-2"},[_c('div',{staticClass:"flexbox flex-justified text-center"},[_c('div',{staticClass:"b-1 box rounded-12 py-20 m-0",on:{"click":function($event){return _vm.sendId(
                  infos.id,
                  infos.label,
                  infos.category,
                  infos.farahoush_device,
                  infos.type_code
                )}}},[_c('img',{staticStyle:{"max-height":"50px !important","min-height":"50px !important"},attrs:{"src":infos.logo_path,"alt":""}}),_c('p',{staticClass:"mb-0 font-weight-300 mt-10"},[_vm._v(_vm._s(infos.label))])])])])}),0)]),_c('my-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isModalVisible),expression:"isModalVisible"}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }