<template>
  <v-app class="bg">
    <v-card round  class="mt-5 mx-4">
      <v-container>
      <v-row class="flex-column">
        <div class="fontawesome-icon-list mt-20" style="text-align: center; margin-top:0px">
          <img v-if="appMode=='farahoush'"
              src="/img/icons/android-chrome-96x96.png"
              style="width: 120px; height: 120px"
          />
          <img v-else
                src="/assets/images/smart/logo_bmsx_3.png"
                alt="BMSX"
                style="width: 120px; height: 120px"
            />
          <div class="px-40 mt-30">
           <div>
            <p  v-if="appMode=='farahoush'" class="font-weight-bold" style="line-height:25px !important;font-size:1.1rem">
              {{ $t('message.AboutUs.txt1') }}
            </p>
            <p v-else class="font-weight-bold" style="line-height:25px !important;font-size:1.1rem">
              {{ $t('message.AboutUs.bmsx') }}
            </p>
           </div>
           <div>
            <p v-if="appMode=='farahoush'" class="font-weight-bold" style="line-height:25px !important;font-size:1.1rem">
              {{ $t('message.AboutUs.txt2') }}
            </p>
            <p v-else class="font-weight-bold" style="line-height:25px !important;font-size:1.1rem">
              <!-- {{ $t('message.AboutUs.txt2forign') }} -->
            </p>
           </div>
           <div>
            <p v-if="appMode=='farahoush'" class="font-weight-bold" style="line-height:24px !important;font-size:1.1rem">
              {{ $t('message.AboutUs.txt3') }}
            </p>
            <p v-else class="font-weight-bold" style="line-height:24px !important;font-size:1.1rem">
              <!-- {{ $t('message.AboutUs.txt3forign') }} -->
            </p>
           </div>
           <div>
            <p v-if="appMode=='farahoush'" class="font-weight-bold" style="line-height:24px !important;font-size:1.1rem">
              {{ $t('message.AboutUs.txt4') }}
            </p>
            <p v-else class="font-weight-bold" style="line-height:24px !important;font-size:1.1rem">
              <!-- {{ $t('message.AboutUs.txt4forign') }} -->
            </p>
           </div>
          </div>
        </div>
        <div class="text-center" v-if="appMode=='farahoush'">
          <h3 class="my-5">
            {{ $t('message.public.farahoush') }}
          </h3>
          <h6 class="user-info mt-0 mb-3 text-lighter">
            {{ $t('message.AboutUs.fara') }}</h6>
          <div class="gap-items user-social font-size-16 p-10">
            <a href="https://www.instagram.com/farahoush/"><i
                class="mdi mdi-instagram mdi-24px p-1"></i></a>
            <a href="https://farahoush.ir/"><i class="mdi mdi-web mdi-24px p-1"></i></a>
          </div>
        </div>
      </v-row>
    </v-container>
    </v-card>
  </v-app>
</template>
<script>
import LayoutDefault from "../layouts/LayoutDefault";

export default {
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: '',
      lan:'',
      appMode:'farahoush'
    };
  },
  created() {
    let data = {
      title: this.$t('message.public.about_us'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutUs');
  },
  mounted() {
    this.lan=process.env.VUE_APP_I18N_LOCALE;
    this.appMode=process.env.VUE_APP_MODE

  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value === 'back') {
        this.$router.push({name: "mainpage"});
      }
    },
  },
};
</script>


<style scoped>
</style>
