<template>
  <v-app class="bg">
    <Timer :data="data" @next="onClick"/>
  </v-app>
</template>

<script>
import Timer from "@/components/Cmp/timer/create/timer/Timer.vue";
import LayoutDefault from "../../../../../../layouts/LayoutDefault";

var status_checkbox = "K5F";

export default {
  components: {
    Timer
  },
  data: () => ({
    title: "",
    data: {}
  }),
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  created() {
    this.title = this.$t('message.timer.scheduler')
    let info = localStorage.getItem("data_edit_sub") ? JSON.parse(localStorage.getItem("data_edit_sub")) : [];
    if (info.pol == 1) {
      this.data = {
        items: [
          {
            name: info.labels[0].label,
            status: false
          }
        ]
      }
    }
    if (info.pol == 2) {
      this.data = {
        items: [{
          name: "All",
          status: false
        },
          {
            name: info.labels[0].label,
            status: false
          },
          {
            name: info.labels[1].label,
            status: false
          },
        ]
      }
    }
    if (info.pol == 3) {
      this.data = {
        items: [{
          name: "All",
          status: false
        },
          {
            name: info.labels[0].label,
            status: false
          },
          {
            name: info.labels[1].label,
            status: false
          },
          {
            name: info.labels[2].label,
            status: false
          },
        ]
      }
    }
    if (info.pol == 4) {
      this.data = {
        items: [{
          name: "All",
          status: false
        },
          {
            name: info.labels[0].label,
            status: false
          },
          {
            name: info.labels[1].label,
            status: false
          },
          {
            name: info.labels[2].label,
            status: false
          },
          {
            name: info.labels[3].label,
            status: false
          }]
      }
    }
    this.data.type = "touchFara";
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2');
    this.$store.dispatch("InfoSubDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClick(name, val, status) {
      console.log(name, val, status);
      if (this.pol === 1)
        this.nextL1(status);
      else
        switch (val) {
          case 0:
            this.nextAll(status);
            break;
          case 1:
            this.nextL1(status);
            break;
          case 2:
            this.nextL2(status);
            break;
          case 3:
            this.nextL3(status);
            break;
          case 4:
            this.nextL4(status);
            break;
        }
    },
    nextAll(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (status) {
        status_checkbox = "K5T";
      } else if (!status) {
        status_checkbox = "K5F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "TouchFaraStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
    ,
    nextL1(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (status) {
        status_checkbox = "K1T";
      } else if (!status) {
        status_checkbox = "K1F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "TouchFaraStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
    ,
    nextL2(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      if (status) {
        status_checkbox = "K2T";
      } else if (!status) {
        status_checkbox = "K2F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "TouchFaraStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
    ,
    nextL3(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (status) {
        status_checkbox = "K3T";
      } else if (!status) {
        status_checkbox = "K3F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "TouchFaraStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    }
    ,
    nextL4(status) {
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      if (status) {
        status_checkbox = "K4T";
      } else if (!status) {
        status_checkbox = "K4F";
      }
      var saction = "PS" + subdevice_serial + "*" + status_checkbox;

      localStorage.setItem("statusTimer", JSON.stringify(saction));
      this.$router.push({
        name: "TouchFaraStep2Timerinfo",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
          count: this.count,
        },
      });
    },
    goBackTimer() {
      this.$router.push({
        name: "TouchFaraTimerShow",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
        },
      });
    }
    ,
    onClickChild(value) {
      // console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "TouchFaraTimerShow",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
    }
    ,
  }
  ,
};
</script>


<style scoped>
</style>
