<template>
  <v-card class="mt-2">
    <v-card>
      <v-card-text>
        <v-row >
          <v-col cols="12" class="pt-4 px-4 py-1" >
            <v-row class="px-2" style="height:30px">
              <v-img
                :src="infoItem.relay_type_icon_path"
                max-height="35"
                 max-width="35"
                alt="relay image"
                class="mt-0 align-self-center"
              ></v-img>
              <v-spacer></v-spacer>
              <div class="mt-2">
                <slot ></slot>
              </div>
            </v-row>
            <v-card-title class="text-dark px-0 pb-2 pt-8">
            </v-card-title>
            <v-card-subtitle class=" px-1 py-1">
              <label style="font-size: 12px" class="text-bold px-0 text-primary mt-2">
                {{ infoItem.relay_name }}
              </label>
           </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
// import ActionSwitch from "./ActionSwitch";
export default {
  props: {
    index: "",
    infoItem: [],
    items: [],
    menuShow: {
      default: true,
    },
    captionShow: {
      default: false,
    },
    bgList: {
      default: "bg-white",
    },
    actions: "",
  },
  components: {
    // ActionSwitch
  },

  data() {
    return {
      switch1: false,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    selectSection(i, index) {
      switch (i) {
        case 0:
          this.$emit("clicked", {
            itemId: i,
            index: index,
          });
          break;
        case 1:
          this.$emit("clicked", {
            itemId: i,
            index: index,
          });
          break;
        case 2:
          this.$emit("clicked", {
            itemId: i,
            index: index,
          });
      }
    },
    selectRow(index) {
      this.$emit("clickedRow", index);
    },
    switchclick(status, index) {
      this.$emit("clicked", {
        status: status,
        index: index,
      });
    },
    iconClick(index) {
      this.$emit("clicked", index);
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}
.v-list-item__content {
  overflow: visible;
}
</style>
