<template>
  <v-app class="bg">
    <!-- .header -->
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="mt-6 mr-0 bg-white">
      <!-- Main content -->
      <section class="content">
        <!-- row -->
        <div class="row">
          <!-- col -->
          <div class="col-12 col-lg-12 p-0">
            <div class="box">
              <div class="box-body p-0">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center; margin-top: 10px">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-addsub.svg"
                            class="avatar-smart"
                        />
                        <h5 class="font-weight-bold">
                          {{ $t('message.ModalAddSubDevicePol.title') }}
                        </h5>
                      </div>
                    </div>
                    <br/>
                    <form class="px-40">
                      <div class="form-group">
                        <h6>
                          {{ $t('message.ModalAddSubDevicePol.name') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            id="inputsubfarahoushpol"
                            type="text"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            maxlength="32"
                            :placeholder="$t('message.ModalAddSubDevicePol.hintName')"
                            v-model="namesubfarapol"
                        />
                      </div>
                      <div class="form-group" v-if="farahoush">
                        <h6>
                          {{ $t('message.ModalAddSubDeviceFaraPol.serial') }}
                          <span class="text-danger">*</span>
                        </h6>
                        <input
                            id="inputserialpol"
                            type="number"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            :placeholder="$t('message.ModalAddSubDeviceFaraPol.hintSerial')"
                            v-model="serialsubfarapol"
                        />
                      </div>
                      <div class="box-body">
                        <div class="box-body">
                          <div class="demo-radio-button">
                            <input
                                name="group5"
                                type="radio"
                                id="radio_36"
                                class="with-gap radio-col-blue"
                                checked=""
                                @click="oneBridageSubFara()"
                            />
                            <label for="radio_36"
                            > {{ $t('message.ModalAddSubDevicePol.pol1') }} </label
                            >
                            <input
                                name="group5"
                                type="radio"
                                id="radio_37"
                                class="with-gap radio-col-blue"
                                @click="twoBridageSubFara()"
                            />
                            <label for="radio_37"
                            > {{ $t('message.ModalAddSubDevicePol.pol2') }} </label
                            >
                            <input
                                name="group5"
                                type="radio"
                                id="radio_38"
                                class="with-gap radio-col-blue"
                                @click="threeBridageSubFara()"
                            />
                            <label for="radio_38"
                            > {{ $t('message.ModalAddSubDevicePol.pol3') }} </label
                            >
                            <input
                                name="group5"
                                type="radio"
                                id="radio_39"
                                class="with-gap radio-col-blue"
                                @click="fourBridageSubFara()"
                            />
                            <label for="radio_39"
                            > {{ $t('message.ModalAddSubDevicePol.pol4') }} </label
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <!-- /.col -->
                        <div class="col-12 text-center">
                          <v-btn
                              class="btn btn-info btn-block"
                              :loading="loading"
                              :disabled="loading"
                              color="bg-primary"
                              @click="addSubdevice()"
                          >
                            <span class="text-white"> {{ $t('message.public.submit') }} </span>
                          </v-btn>
                        </div>
                        <!-- /.col -->
                      </div>
                    </form>
                    <div class="text-center" style="height: 50px"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
      <div
          class="modal center-modal fade"
          id="modal-center"
          tabindex="-1"
          data-keyboard="false"
          data-backdrop="static"
      >
        <div class="modal-dialog p-20">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
            </div>
            <div class="modal-body">
              <div class="spinner-border textprimary" role="status">
                <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
              </div>
              <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <!-- /.modal Add Home-->
  </v-app>
</template>

<script>
// import ModalCenter from "./ModalCenter";
// import { Internets } from "../../public/assets/js/utils/util.js";
import {SucessToastAdd} from "../../../public/assets/js/utils/util.js";
import AppBar from "@/components/AppBar.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {nameDeviceValidationInput, serialDeviceValidationInput} from "../../../public/assets/js/utils/error_handler";
import LayoutDefault from "../../layouts/LayoutDefault";


//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    // ModalCenter,
    AppBar,
  },
  props: {
    home_id: {},
    serial_number: {},
    farahoush: {},
    type_code: {},
    click: {},
    nameDevice: {}
  },
  data() {
    return {
      // info: null,
      namesubfarapol: "",
      serialsubfarapol: "",
      loading: false,
      pol: 1
    };
  },
  created() {
    let data = {
      title: this.nameDevice
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'ModalAddSubDeviceFara')
  },
  mounted() {

  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    oneBridageSubFara() {
      this.pol = 1;
    },
    twoBridageSubFara() {
      this.pol = 2;
    },
    threeBridageSubFara() {
      this.pol = 3;
    },
    fourBridageSubFara() {
      this.pol = 4;
    },
    addSubdevice() {
      if (this.farahoush)
        this.addSubdeviceFarahoush()
      else
        this.addSubdevicePol()
    },
    addSubdeviceFarahoush() {
      let self = this;
      var name = this.namesubfarapol;
      var serial = this.serialsubfarapol;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      if (
          !nameDeviceValidationInput(name) ||
          !serialDeviceValidationInput(serial)
      )
        return;
      let labels = []
      for (let i = 0; i < 4; i++) {
        if (i >= this.pol) {
          labels.push({
            pol: i,
            label: "",
          });
        } else {
          labels.push({
            pol: i,
            label: "L" + parseInt(i + 1),
          })
        }
      }
      labels = JSON.stringify(labels)
      this.loading = true;
      var data = {
        user_id: user_id,
        token: token,
        name: name,
        home_id: this.home_id,
        type_code: this.type_code,
        serial_number: serial,
        pol: this.pol,
        labels: labels,
      };
      //-----------------------------Request ---------------------------//

      let URL = getUrl.subAdd;
    
      const p = postAjaxCall(URL, data, "");
      // $("#modal-center").modal("show");
      //------------------------------Response ---------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              SucessToastAdd.MsgAdd(self.$t('message.ModalAddSubDevice.register'));
              setTimeout(function () {
                self.$router.push({
                  name: "homeDevices",
                  params: {
                    home_id: self.home_id,
                    serial_number: self.serial_number
                  }
                });
              }, 50);
            } else {
              //error
              self.loading = false
            }
          },
          //------------------------------Err ---------------------------//
          function (errr) {
          }
      );
    },
    addSubdevicePol() {
      let self = this;
      let name = this.namesubfarapol;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      if (!nameDeviceValidationInput(name)) return;
      this.loading = true;

      let labels = []
      for (let i = 0; i < 4; i++) {
        if (i >= this.pol) {
          labels.push({
            pol: i,
            label: "",
          });
        } else {
          labels.push({
            pol: i,
            label: "L" + parseInt(i + 1),
          })
        }
      }
      labels = JSON.stringify(labels)

      var data = {
        user_id: user_id,
        token: token,
        name: name,
        home_id: this.home_id,
        type_code: this.type_code,
        pol: this.pol,
        labels: labels,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.subAdd;
      const p = postAjaxCall(URL, data);
      // $("#modal-center").modal("show");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.loading = false;
              SucessToastAdd.MsgAdd(self.$t('message.ModalAddSubDevice.register'));
              setTimeout(function () {
                self.$router.push({
                  name: "homeDevices",
                  params: {
                    home_id: self.home_id,
                    serial_number: self.serial_number
                  }
                });
              }, 50);
            } else {
              //error
              self.loading = false
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeAddDevice",
          params: {home_id: this.home_id},
        });
      }
    },

  },
};
</script>


<style scoped>
</style>
