export const getMessageGadget = {
  getMessageConvert: function (sn_guest,guest_pol,host_pol) {
    var message ="CNV"+sn_guest+"K"+guest_pol+"$"+"K"+host_pol+"#"
    console.log("message",message)
    return message
  },
}



