<template>
  <v-app class="bg">
    <div class="mt-3">
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div
              class="media align-items-center setting"
              v-on:click.prevent="ClickTemp"
          >
            <i class="mdi mdi-thermometer mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.public.settings') }} {{ $t('message.RelayKey.T') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickVolt"
          >
            <i class="mdi mdi-pulse mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.public.settings') }} {{ $t('message.RelayKey.V') }}</p>
            </div>
          </div>
          <div class="media align-items-center" v-on:click.prevent="ClickAmp">
            <i class="mdi mdi-tilde mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.public.settings') }} {{ $t('message.RelayKey.I') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickReport"
          >
            <i class="mdi mdi-chart-arc mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.setting.report') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickEditHome()"
          >
            <i class="mdi mdi-border-color mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.setting.edit') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickManage"
          >
            <i class="mdi mdi-account-settings mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.client.management') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickCLock"
          >
            <i class="mdi mdi-clock mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.timer.setTime_device') }}</p>
            </div>
          </div>
          <div
              v-if="this.$store.getters.getPassDeviceFromHome==0"
              class="media align-items-center"
              id="row_init_pass"
              v-on:click.prevent="clickInitPass()"
          >
            <i class="mdi mdi-security mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.setting.defPIN') }}</p>
            </div>
          </div>
          <div
              v-if="this.$store.getters.getPassDeviceFromHome!==0"
              class="media align-items-center"
              id="row_change_pass"
              v-on:click.prevent="clickChangePass()"
          >
            <i class="mdi mdi-lock-outline mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.setting.changePIN') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickDelHome()"
          >
            <i class="mdi mdi-delete-forever mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.client.delete_home') }}</p>
            </div>
          </div>
          <div
              class="media align-items-center"
              v-on:click.prevent="ClickResetFactory()"
          >
            <i class="mdi mdi-format-rotate-90 mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.noneHome.reset') }}</p>
            </div>
          </div>
        </div>

        <!-- /.row -->
      </section>
      <!-- /. Main content -->

    </div>
    <!-- /.Content Wrapper. Contains page content -->
    <SettingModalResetFactory v-bind:serialnumbers="serialnumber"></SettingModalResetFactory>
    <SettingModalDelHome v-bind:homeids="homeid"></SettingModalDelHome>
    <SettingModalEditHome v-bind:homeids="homeid"></SettingModalEditHome>
    <SettingModalInitPass
        v-show="isShow"
        v-bind:homeids="homeid"
        @passInit="passInit"
    ></SettingModalInitPass>
    <SettingModalChangePass
        v-show="isShowChange"
        v-bind:homeids="homeid"
    ></SettingModalChangePass>
  </v-app>
</template>

<script>
import SettingModalEditHome from "./SettingModalEditHome";
import SettingModalDelHome from "./SettingModalDelHome";
import SettingModalResetFactory from "./SettingModalResetFactory";
import SettingModalInitPass from "./SettingModalInitPass";
import SettingModalChangePass from "./SettingModalChangePass";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {
    SettingModalEditHome,
    SettingModalDelHome,
    SettingModalResetFactory,
    SettingModalInitPass,
    SettingModalChangePass,
  },
  props: {
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      isShow: false,
      isShowChange: false,
      homeid: [this.home_id],
      serialnumber: this.serial_number,
      title: '',
      type: 7
    };
  },
  mounted() {
    this.title = this.$t('message.public.settings')
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Settings');
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {

      if (value == 'back') {
        this.$router.push({name: "homeDevices", params: {home_id: this.home_id, serial_number: this.serial_number},});

      }
    },
    ClickSelection() {
      localStorage.setItem('selection', this.type + "m")
      this.$router.push({
        name: "selection_drag",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          type_code: this.type,
        }
      },)
    },
    clickRelays() {
      this.$router.push({
        name: "subboxrelay",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    ClickTemp() {
      this.$router.push({
        name: "outletGatewayTemp", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickVolt() {
      this.$router.push({
        name: "outletGatewayVolt", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickAmp() {
      this.$router.push({
        name: "outletGatewayCurrent", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    ClickReport() {
      this.$router.push({name: "SettingReport",});

    },
    ClickEditHome() {
      $("#modal-edit-home").modal('show');
    },
    ClickManage() {
      this.$router.push({
        name: "managementclientinfo", params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    passInit() {
      this.$router.push({name: "mainpage"})
    },
    ClickCLock() {
      this.$router.push({
        name: "setClock", params: {home_id: this.home_id, serial_number: this.serial_number},
      });

    },
    ClickDelHome() {
      $("#modal-del-home").modal('show');
    },
    ClickResetFactory() {
      $("#modal-reset-factory").modal('show');
    },
    clickInitPass() {
      $("#modal-init-pass").modal("show");
    },
    clickChangePass() {
      this.isShowChange = true;
      $("#modal-change-pass0").modal("show");
    },
    goBackSetting() {
      this.$router.push({name: "homeDevices", params: {home_id: this.home_id, serial_number: this.serial_number},});
    }
  },
};
</script>


<style scoped>
</style>
