<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content ">
        <CmpControlTv
            :data_list="listactive"
            @clicked="getTvControlCmpCallback"
            :mode="'Learn'"
        ></CmpControlTv>
        <CmpTips :data_txt="tips_txt" :data_type="'default'" class="mt-5"></CmpTips>
      </section>
    </div>
    <ModalIr
        v-show="isModalVisibleIr"
        @clicked="getCancelModalIrCallback"
        @close="closeModalIr">
    </ModalIr>
  </v-app>
</template>

<script>
import ModalIr from "../ModalIr";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {
  ErrorAlert,
  SuccessAlert,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {getCommand} from "../../../../public/assets/js/utils/get_ir_gatway_command.js";
import CmpControlTv from "../CmpControlTv.vue";
import CmpTips from "../../Cmp/public/CmpTips.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";


var connection = false;
var timer;
var showToast;

export default {
  components: {
    ModalIr,
    CmpTips,
    CmpControlTv,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      isModalVisibleIr: false,
      listactive: [
        {
          id: 0,
          active: false,
          code: 0,
        },
        {
          id: 1,
          active: false,
          code: 1,
        },
        {
          id: 2,
          active: false,
          code: 2,
        },
        {
          id: 3,
          active: false,
          code: 3,
        },
        {
          id: 4,
          active: false,
          code: 4,
        },
        {
          id: 5,
          active: false,
          code: 5,
        },
        {
          id: 6,
          active: false,
          code: 6,
        },
        {
          id: 7,
          active: false,
          code: 7,
        },
        {
          id: 8,
          active: false,
          code: 8,
        },
        {
          id: 9,
          active: false,
          code: 9,
        },
        {
          id: 10,
          active: false,
          code: 10,
        },
        {
          id: 11,
          active: false,
          code: 11,
        },
        {
          id: 12,
          active: false,
          code: 12,
        },
        {
          id: 13,
          active: false,
          code: 13,
        },
        {
          id: 14,
          active: false,
          code: 14,
        },
        {
          id: 15,
          active: false,
          code: 15,
        },
      ],
      key: '',
      cancelModal: false,
      tips_txt: this.$t('message.irTv.tip')
    };
  },
  mqtt: {

    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3]== "256") {
        if (topic.split("/")[4] === "action") {
          connection = true;

          this.closeModalIr();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  mounted() {
    let data = {
      title: this.$t('message.irTv.config'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IrTvLearn');
    this.onSubscribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    Learn(key) {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      this.key = key;
      var message = getCommand.getCommandLearn(this.count, key);
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      this.showModalIr();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          timer = setTimeout(function () {
            self.closeModalIr();
            if (!connection && !self.cancelModal) {
              if (showToast == 1) {
                ErrorAlert.Alert();
              }
            }
            showToast = 0;
            connection = false;
          }, 30000);
        } else {
          self.closeModalIr();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
          //error
        }
      });
    },
    sendDataLearn(value) {
      this.Learn(value);
    },
    showModalIr() {
      this.isModalVisibleIr = true;
    },
    closeModalIr() {
      this.isModalVisibleIr = false;
    },
    showGetMsg(msg) {
      let self = this;
      var sucess = msg.includes("LEARNOK");
      var err = msg.includes("LEARNERROR");
      var cancel = msg.includes("CANECLERROR");
      if (sucess) {
        if (showToast == 1) {
          self.closeModalIr();
          clearTimeout(timer);
          SuccessAlert.Alert();
          connection = false;
        }
      }
      if (err) {
        if (showToast == 1) {
          self.closeModalIr();
          ErrorAlert.Alert();
          connection = false;
        }
      }
      if (cancel) {
        self.closeModalIr();
        clearTimeout(timer);
        connection = false;
      }
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irtvinfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    getTvControlCmpCallback(msg) {
      this.Learn(msg);
    },
    getCancelModalIrCallback(msg) {
      this.cancelModal = true;
      if (msg == 'cancel') {
        let self = this;
        var user_id = localStorage.getItem("user_id");
        var token = localStorage.getItem("token");
        var message = getCommand.getCommandCancelLearn(this.count, this.key);
        var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
        // ----------------------Data------------------//
        var data = {
          user_id: user_id,
          token: token,
          topic: topic_final,
          message: message,
          // password: password,
          client_id: "fara" + Date.now(),
        };
        // --------------------Requsest----------------------//
        let URL = getUrl.mqttRequestIR;
        const p = postAjaxCall(URL, data, "");
        // ---------------------Rseponse--------------------//
        p.then(function (response) {
          if (responseAjax(response)) {
            var jsonArray = JSON.parse(response.responseText);
            self.closeModalIr();
          } else {


            //error
          }
        });
      }
    }
  },
};
</script>
<style scoped>
</style>
