<template>
  <v-row class="d-flex align-center">
    <v-col cols="8">
      <div>
        <h6 class="px-4">{{ info.name }}</h6>
      </div>
    </v-col>
    <v-col cols="4">
      <v-switch
          v-if="!(type === 'remote' || type === 'curtainFara' || (type === 'coolerFara' && index === 0))"
          v-model="info.status"
          inset
          @change="checkBoxChange(index)"
          :id="`check+${index}`"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TimerRow",
  props: {
    info: {},
    index: {},
    type: {}
  },
  methods: {
    checkBoxChange(val) {
      this.$emit('boxChange', val);
    }
  }
}
</script>

<style scoped>

</style>