<template>
  <v-app class="bg">
    <div class=" mr-0">
      <!-- Main content -->
      <section class="content">
        <div class="row mt-30 px-20">
          <div class="col-lg-12 col-12 py-0">
            <!-- Default box -->
            <div class="box rounded-50">
              <!-- <div class="box-header with-border">
                    <h4 class="box-title">گزارش ها</h4>
                  </div> -->
              <div class="box-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-12">
                    <div id="trl1">
                      <div class="media media-single px-0 py-20">
                        <img
                            src="/assets/images/smart/switch-off.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                            :style="result1.check ? result1.onFilter : result1.offFilter"
                        />
                        <div class="media-body">
                          <h6> {{ $t('message.touch_key.key') }} </h6>
                        </div>
                        <v-switch
                            :color="data.onColors[0]"
                            inset
                            v-model="result1.check"
                            @click="MyFunctionPower(0, result1.check)"
                            id="checkL1"
                        ></v-switch>
                      </div>
                    </div>
                    <div id="trl3">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/icon-fan-fast.svg"
                              id="imgl3"
                              width="32px"
                              height="32px"
                              :style="result2.check ? result2.onFilter : result2.offFilter"
                          />

                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.fan') }} </h6>
                          </div>
                          <v-switch
                              :color="data.onColors[1]"
                              v-model="result2.check"
                              @click="MyFunctionPower(1, result2.check)"
                              inset
                              id="checkL2"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl4">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/spray.svg"
                              id="imgl4"
                              width="32px"
                              height="32px"
                              :style="result3.check ? result3.onFilter : result3.offFilter"
                          />
                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.spray') }} </h6>
                          </div>
                          <v-switch
                              :color="data.onColors[2]"
                              v-model="result3.check"
                              @click="MyFunctionPower(2, result3.check)"
                              inset
                              id="checkL4"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                    <div id="trl2">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              src="/assets/images/smart/music.svg"
                              id="imgl2"
                              width="32px"
                              height="32px"
                              :style="result4.check ? result4.onFilter : result4.offFilter"
                          />

                          <div class="media-body">
                            <h6> {{ $t('message.touch_key.music') }} </h6>
                          </div>
                          <v-switch
                              :color="data.onColors[3]"
                              v-model="result4.check"
                              @click="MyFunctionPower(3, result4.check)"
                              inset
                              id="checkL2"
                          ></v-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
            <!-- /.box -->
          </div>
        </div>
      </section>
      <!-- /. Main content -->
    </div>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./Modal Pass-->
    <CmpPasscode @sendPassCode="sendReq" @cancel="closePass"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
    <ModalOutletMomentray
        v-show="isModalVisibles"
        :serials="serial_number"
        @set_delay="setDelay"
        type="WC"
    ></ModalOutletMomentray>
    <SubModalDel v-bind:data_info_sub="data_info_keytouch"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_keytouch"></SubModalEdit>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_keytouch"
    ></SubModalSetPass>
  </v-app>
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {Internets, SucessToastMsg} from "../../../../public/assets/js/utils/util.js";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {ErrorToastMsg} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray";
import {Color, hexToRgb, Solver} from "../../../../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var keytouch_status;
var showToast;
var timeout;
var delayShow = false;

export default {
  name: "SubService",
  components: {
    ModalOutletMomentray,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      passkeytouchfara: "",
      data_info_keytouch: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      pass: true,
      set_password: false,
      pol: 1,
      loadingInstant: false,
      isModalVisible: false,
      isModalVisibles: false,
      subdevice_serial: "",
      info: [],
      data: [],
      delay: {
        "fan": 0,
        "spray": 0,
        "auto": 0,
      },
      result1: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result2: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result3: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
      result4: {
        onFilter: "",
        offFilter: "",
        check: false,
      },
    };
  },
  async created() {
    let data = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.subdevice_serial = data.serial_number
    let delay = data.wc_setting.substr(6,).split(":");
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.delay.auto = +data.wc_setting.substr(5, 1);
    this.delay.fan = delay[1].split("T")[0];
    this.delay.spray = delay[2];
    localStorage.setItem("delay", JSON.stringify(this.delay));
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      // var seraildevice = BetweenStr.getBetweenStr(
      //     topic,
      //     "farahoush/",
      //     "/receive"
      // );
      // var serailsubdevice = BetweenStr.getBetweenStr(
      //     topic,
      //     "receive/",
      //     "/action"
      // );
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.showStatus(String(data));
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          clearTimeout(timeout);
          this.exitModalLoading();
          SucessToastMsg.Msg();
        }
      }
    },
  },
  mounted: function mounted() {

    let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
    // init run
    try {
      setTimeout(() => {
        this.data = localStorage.getItem("data_color" + this.subdevice_serial) ? JSON.parse(localStorage.getItem("data_color" + this.subdevice_serial)) : {
          onColors: ['#0000ff', '#0000ff', '#0000ff', '#0000ff'],
          offColors: ['#a9a9a9', '#a9a9a9', '#a9a9a9', '#a9a9a9'],
        };
        this.setColors();
      }, 50)
      let self = this;
      Internets.Internet;
      setTimeout(function () {
        // self.lastStausService();
      }, 100);
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");

      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      // subscribe
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: this.$t("message.ListDevice.err_process"),
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t("message.public.OK"),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      edit: true,
      del: true,
      refresh: true,
      set_service: true,
      about:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubService');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    setColors() {
      let onColors = this.data.onColors.map(color => {
        return hexToRgb(color);
      });
      let offColors = this.data.offColors.map(color => {
        return hexToRgb(color);
      });
      let color1 = new Color(onColors[0][0], onColors[0][1], onColors[0][2]);
      let color1_2 = new Color(offColors[0][0], offColors[0][1], offColors[0][2]);
      let color2 = new Color(onColors[1][0], onColors[1][1], onColors[1][2]);
      let color2_2 = new Color(offColors[1][0], offColors[1][1], offColors[1][2]);
      let color3 = new Color(onColors[2][0], onColors[2][1], onColors[2][2]);
      let color3_2 = new Color(offColors[2][0], offColors[2][1], offColors[2][2]);
      let color4 = new Color(onColors[3][0], onColors[3][1], onColors[3][2]);
      let color4_2 = new Color(offColors[3][0], offColors[3][1], offColors[3][2]);
      this.result1.onFilter = new Solver(color1).solve().filter;
      this.result1.offFilter = new Solver(color1_2).solve().filter;
      this.result2.onFilter = new Solver(color2).solve().filter;
      this.result2.offFilter = new Solver(color2_2).solve().filter;
      this.result3.onFilter = new Solver(color3).solve().filter;
      this.result3.offFilter = new Solver(color3_2).solve().filter;
      this.result4.onFilter = new Solver(color4).solve().filter;
      this.result4.offFilter = new Solver(color4_2).solve().filter;
    },
    MyFunctionPower(index, value) {
      index += 1
      if (value)
        this.keytouch_status = "K" + index + "T#";
      else
        this.keytouch_status = "K" + index + "F#";
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.keytouch_status, "");
      }
    },
    sendReq(password) {
      if (delayShow) {
        this.sendSettings(keytouch_status, password);
        delayShow = false;
      } else
        this.sendMessage(this.keytouch_status, password);
    },
    setDelay(delay) {
      if (this.set_password == 1) {
        keytouch_status = delay;
        delayShow = true;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendSettings(delay, "");
      }
    },
    sendSettings(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      let message = "AUTO:" + saction.auto + "TFAN:" + saction.fan + "TSPRY:" + saction.spray + "#";
      let topic2 = getTopics.getTopicToggleReceive(
          this.serial_number,
          this.subdevice_serial
      )
      this.$mqtt.subscribe(topic2);
      var topic_final = getTopics.getTopicToggleSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              //  connection=false;
              self.openModalLoading();
              $("#modal-pass-code").modal("hide");
              timeout = setTimeout(function () {
                self.exitModalLoading();

                if (!connection) {
                  setTimeout(function () {
                    self.lastStausService();
                  }, 1000);
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }

                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value === "set_service") {
        this.isModalVisibles = true;
        $("#modal-outlet-momentray").modal("show");
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "aboutservice",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
          
      if (value == "edit") {
        this.$router.push({
          name: "EditDevice",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
            type: "WC",
            active: false
          },
        });
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStausService();
        // location.reload();
      }
      if (value == "timer") {
        this.$router.push({
          name: "ServiceTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    sendMessage(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;

      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              //  connection=false;
              self.openModalLoading();
              $("#modal-pass-code").modal("hide");
              timeout = setTimeout(function () {
                self.exitModalLoading();

                if (!connection) {
                  setTimeout(function () {
                    self.lastStausService();
                  }, 1000);
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }

                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassKeyTouchFara() {
      if (!passHomeValidationInput(this.passkeytouchfara)) return;
      this.sendMessage(this.keytouch_status, this.passkeytouchfara);
      this.passkeytouchfara = "";
    }
    ,
    lastStausService() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModalLoading();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.showStatus(message);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    }
    ,
    showStatus(message) {
      if (message === "NOTSET") {
        this.result1.check = false;
        this.result2.check = false;
        this.result3.check = false;
        this.result4.check = false;
        return;
      }

      let L1_r = message.charAt(0);
      let L2_r = message.charAt(1);
      let L3_r = message.charAt(2);
      let L4_r = message.charAt(3);

      this.result1.check = L1_r === "1";
      this.result2.check = L2_r === "1";
      this.result3.check = L3_r === "1";
      this.result4.check = L4_r === "1";
    },
    closePass() {
      // location.reload();
      this.lastStausService();
    }
    ,
  },
};
</script>

<style scoped>

</style>