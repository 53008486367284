<template>
  <v-app class="bg">
    <div class="mt-5">
      <section class="content">
        <!-- row -->
        <div class="media-list media-list-divided media-list-hover">
          <div class="media align-items-center setting">
            <i class="mdi mdi-barcode mdi-24px"></i>
            <div class="media-body">
              <p>{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</p>
        
                 <h2>{{deviceID}}</h2>
           
            </div>
          </div>
        </div>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <!-- /.Content Wrapper. Contains page content -->
  </v-app>
</template>

<script>

import LayoutDefault from "../../layouts/LayoutDefault.vue";

export default {
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      homeid: this.home_id,
      serialnumber: this.serial_number,
      title: "",
      deviceID:''
      
    };
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
    this.title = this.$t('message.AppBar.about_device');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutKeyFara');
     this.deviceID = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number

  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubScenarioKeyInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
