<template>
    <div
        class="modal modal-fill fade"
        data-backdrop="false"
        id="modal-none-set-pincode"
        tabindex="-1">
      <div class="wrapper m-0">
        <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
        <CmpSetPinCode
         @clicked="CallCmpPinCode"
         :txtDescript="$t('message.relay.relay_reset_txt')"
         :loading="loading"
         ></CmpSetPinCode>
        <div class="modal-dialog p-20">
        </div>
      </div>
    </div>
  </template>
  <script>
  var connection = false;
  //--------------------------------------  variable --------------------------------------//
  import AppBar from "@/components/AppBar.vue";
  import {getTopics} from "@/../public/assets/js/utils/topics.js";
  import {postAjaxCall} from "@/../public/assets/js/postAjax";
  import CmpSetPinCode from "@/components/Cmp/general/CmpSetPinCode.vue";
  import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
  import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
  
  export default {
    components: {
      AppBar,
      CmpSetPinCode,
    },
    data() {
      return {
        title: "",
        dataReset: "",
        serial_number: "",
        loading: false,
      };
    },
    props: {
      infoItem: {},
    },
    mqtt: {
      "+/+/receive/+/reset"(data, topic) {
        // if (topic.split("/")[1] === this.serialnumbers[0]) {
        console.log("data", String(data));
        this.checkMqtt(String(data));
        connection = true;
        // }
      },
    },
    created() {
      // this.title = this.$t("message.noneHome.reset");
      this.title="ست کردن پین کد "
    },
    mounted() {
    },
    methods: {
      CallCmpPinCode(value) {
        console.log("value", value);
       
        //   this.loading = true;
          this.setPinCodeFunction(value);
  
        
      },
      // CancelFunction() {
      //   $("#modal-reset").modal("hide");
      //   this.loading = false;
      // },
      setPinCodeFunction(pin_code) {
        let self = this;
        let user_id = localStorage.getItem("user_id");
        let token = localStorage.getItem("token");
        let serial_number=this.infoItem
        // this.loading = true;
        // let saction = "RESET#";
        console.log("data",this.infoItem)
        // ----------------------Data------------------//
        var data = {
          user_id: user_id,
          token: token,
          serial_number: serial_number,
          password:pin_code ,
        };
        console.log("data",data)
        // --------------------Requsest----------------------//
        let URL = getUrl.noneSetPin;
        const p = postAjaxCall(URL, data, "");
        // ---------------------Rseponse--------------------//
        p.then(
            function (response) {
              self.onSubScribe();
              setTimeout(function () {
                if (!connection) {
                  self.loading=false
                  swal("", self.$t("message.public.operation_failure"), "error");
                  $("#modal-reset").modal("hide");
                }
              }, 25000);
            },
            function (errr) {
              //errr
              self.loading=false
            }
        );
      },
      onSubScribe() {
        var topic = getTopics.getTopicResetReceive(
            this.serial_number,
            256);
        this.$mqtt.subscribe(topic);
      },
      checkMqtt(msg) {
        let self = this;
  
        // let device_serial=this.serialnumbers[0]
        if (msg == "RESET") {
          self.loading=false
          swal({
            title: this.$t("message.irAir.settings"),
            text: this.$t("message.noneHome.r_txt"),
            type: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          setTimeout(function () {
            self.$router.push({name: "mainpage"});
          }, 2005);
        }
      },
      onClickChild(value) {
        if (value == "back") {
          $("#modal-reset").modal("hide");
        }
      },
    },
  };
  </script>
  
  
  <style scoped>
  </style>
  