<template>
  <v-app class="bg">
    <div class="row rounded-16 px-5">
      <div class="col-12 col-lg-12">
        <div class="box mt-5 px-5">
          <!-- Main content -->
          <section class="content">
            <!-- row -->
            <div class="row rounded-16" id="all-page" ref="allpage">
              <!-- col -->
              <div class="col-12 col-lg-12 ">
                <div class="box">
                  <div class="box-body">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div style="text-align: center; margin-top: 0px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                              src="/assets/images/smart/vector-volt.svg"
                              class="avatar-smart mt-10"
                          />
                        </div>
                        <!-- <h6 class="mt-5 p-10"></h6> -->
                      </div>
                    </div>

                    <div class="form-check px-40">
                      <input
                          class="form-check-input"
                          type="checkbox"
                          value="checkbox1"
                          v-model="checked"
                          id="checkbox1"
                      />
                      <label class="form-check-label" for="checkbox1">
                        {{ $t('message.noneHome.active') + " " + $t('message.RelayKey.V') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.col -->
            </div>

            <!-- /.row -->
          </section>
          <!-- /. Main content -->

        </div>
      </div>
    </div>
    <div class="row rounded-16 px-5 mt-0">
      <div class="col-12 col-lg-12">
        <div class="box">
          <div class="box-body px-5">
            <div class="text-center py-5" id="Main_div" ref="maindiv">
              <h6 style="line-height: 25px !important">
                {{ $t('message.noneHome.V_text') }}
              </h6>
              <ul class="flexbox flex-justified text-center my-10 mt-30">
                <li class="px-2">
                  <p class="mb-0 text-bold">{{ $t('message.noneHome.delay') }}</p>
                  <div class="form-group mt-10">
                    <input
                        id="inputtemp1"
                        v-model="temp1"
                        type="number"
                        value="60"
                        min="00"
                        max="800"
                        style="direction: ltr"
                        class="form-control text-center"
                        required=""
                        aria-invalid="false"
                        autocomplete="off"
                        :disabled="!checked"
                        onKeyPress="if(this.value.length==3) return false;"
                    />
                    <small>{{ $t('message.public.seconds') }}</small>
                  </div>
                </li>
                <li class="br-1 px-2">
                  <p class="mb-0 text-bold">{{ $t('message.public.from') }}</p>
                  <div class="form-group mt-10">
                    <input
                        id="inputvolt2"
                        v-model="volt2"
                        type="number"
                        value="160"
                        min="160"
                        max="249"
                        style="direction: ltr"
                        class="form-control text-center"
                        required=""
                        :disabled="!checked"
                        aria-invalid="false"
                        autocomplete="off"
                        onKeyPress="if(this.value.length==3) return false;"
                    />
                  </div>
                </li>
                <li class="px-2">
                  <p class="mb-0 text-bold">{{ $t('message.public.to') }}</p>
                  <div class="form-group mt-10">
                    <input
                        id="inputvolt3"
                        v-model="volt3"
                        type="number"
                        class="form-control text-center"
                        required=""
                        aria-invalid="false"
                        value="250"
                        min="160"
                        style="direction: ltr"
                        max="60"
                        :disabled="!checked"
                        autocomplete="250"
                        onKeyPress="if(this.value.length==3) return false;"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row rounded-16 px-5 fixed-bottom">
      <div class="col-12 col-lg-12 px-15">
        <button
            type="button"
            class="btn btn-primary btn-lg btn-block mb-10 px-40"
            v-on:click.prevent="sendMessage()"
        >
          {{ $t('message.public.submit') }}

        </button>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {SucessToastMsg, ErrorToastMsg, Internets, BetweenStr} from "../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import {volCompareValidationInput, voltValidationInput} from "../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

var connection = false;
var showToast;
localStorage.setItem("count", "256");
export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      checked: false,
      temp1: "60",
      volt2: "160",
      volt3: "250",
      title: this.$t('message.noneHome.setV'),
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/volt"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "volt") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted: function () {
    this.title = this.$t('message.noneHome.setV');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingVolt');
    var self = this;
    this.openModalLoading();
    //---------subscribe
    var topic = getTopics.getTopicVoltReceive(this.serial_number, '256');
    this.$mqtt.subscribe(topic);
    //---------./subscribe
    let allpage = this.$refs.allpage;
    allpage.style.display = "none";
    setTimeout(function () {
      //---------./get Last Status from Device
      self.getInfoLastStatus();
    }, 300);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendMessage() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      //--------------------------------------Message-------------------------------------//
      var status_checkbox;
      if (this.checked == true) {
        let input1 = this.temp1;
        let input2 = this.volt2;
        let input3 = this.volt3;
        var formattedNumber1;
        console.log("input1", input1.length)

        if (input1.length == 1) {
          formattedNumber1 = ("0" + input1).slice(-2);
        }
        if (input1.length == 2) {
          formattedNumber1 = ("0" + input1).slice(-2);
        }
        if (input1.length == 3) {
          formattedNumber1 = ("0" + input1).slice(-3);
        }
        var formattedNumber2 = ("0" + input2).slice(-3);
        var formattedNumber3 = ("0" + input3).slice(-3);
        //--------------------------------------Validation-------------------------------------//
        if (
            !voltValidationInput(input1, input2, input3) ||
            !volCompareValidationInput(formattedNumber2, formattedNumber3)
        )
          return;
        status_checkbox =
            "VOLT" +
            formattedNumber2 +
            "-" +
            formattedNumber3 +
            "TIME" +
            formattedNumber1 +
            "#";
      } else if (this.checked == false) {
        status_checkbox = "DEACTIVEV#";
      }
      //--------------------------------------function for Post Method --------------------------------------//
      var topic_final;

      var topic_final = getTopics.getTopicVoltSend(this.serial_number, '256');
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: status_checkbox,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.closeModalLoading();
              setTimeout(function () {
                self.closeModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
            }
          },
          function (errr) {
            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({name: "home"});
              }
            }
          }
      );
    },
    getInfoLastStatus() {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var self = this;
      var allpage = this.$refs.allpage;
      let device_serial = this.serial_number;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.volt;
      const p = postAjaxCall(URL, data, "");
      this.checked = false;
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              self.closeModalLoading();
              allpage.style.display = "block";
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.volt;

              var checkbox = document.getElementById("checkbox1");
              if (message == "DEACTIVEVOLT") {
                self.checked = false;
              } else {
                self.checked = true;
                var value1 = message.substr(message.indexOf("VOLT") + 4, 3);
                var value2 = message.substr(message.indexOf("VOLT") + 8, 3);
                var value3 = message.substr(message.indexOf("TIME") + 4, 3);

                self.temp1 = value3;
                self.volt2 = value1;
                self.volt3 = value2;
              }
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            console.log("err", errr);
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == 'back') {
        this.$router.push({
          name: "settings",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },
};
</script>


<style scoped>
</style>
