<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <div class="row mt-1 px-5" id="trall" v-if="polall">
          <div class="col-12">
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16">
                <div class="media align-items-center bg-white py-5 rounded">
                  <img
                      v-if="!imgall"
                      src="/assets/images/smart/switch-off.svg"
                      id="imgall"
                      width="32px"
                      height="32px"/>
                  <img
                      v-if="imgall"
                      src="/assets/images/smart/switch-on.svg"
                      id="imgall"
                      width="32px"
                      height="32px"
                  />

                  <div class="media-body">
                    <h6>{{ $t('message.public.all') }}</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-success"
                      @click="functionOn('K5T#')"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                  <button
                      type="button"
                      class="btn btn-circle btn-danger"
                      @click="functionOff('K5F#')"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- .row report-->
          </div>
        </div>
        <div class="row px-5 mt-2">
          <div class="col-lg-12 col-12">
            <!-- Default box -->
            <div class="box rounded-50">
              <div class="box-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12 col-12">
                    <div id="trl1" v-if=pol1>
                      <div class="media media-single px-0 py-20">
                        <img
                            v-if="img1 == false"
                            src="/assets/images/smart/switch-off.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                        />
                        <img
                            v-if="img1 == true"
                            src="/assets/images/smart/switch-on.svg"
                            id="imgl1"
                            width="32px"
                            height="32px"
                        />

                        <div class="media-body" v-if="labels.length >= 1">
                          <h6>{{ labels[0].label }}</h6>
                        </div>
                        <button
                            type="button"
                            class="btn btn-circle btn-success"
                            @click="functionOn('K1T#')"
                        >
                          <i class="mdi mdi-power mdi-24px"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-circle btn-danger"
                            @click="functionOff('K1F#')"
                        >
                          <i class="mdi mdi-power mdi-24px"></i>
                        </button>
                      </div>
                    </div>
                    <div id="trl2" v-if="pol2">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              v-if="img2 == false"
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl2"
                              width="32px"
                              height="32px"
                          />
                          <img
                              v-if="img2 == true"
                              src="/assets/images/smart/switch-on.svg"
                              id="imgl2"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body" v-if="labels.length >= 2">
                            <h6>{{ labels[1].label }}</h6>
                          </div>
                          <button
                              type="button"
                              class="btn btn-circle btn-success"
                              @click="functionOn('K2T#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                          <button
                              type="button"
                              class="btn btn-circle btn-danger"
                              @click="functionOff('K2F#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="trl3" v-if="pol3">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              v-if="img3 == false"
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl3"
                              width="32px"
                              height="32px"
                          />
                          <img
                              v-if="img3 == true"
                              src="/assets/images/smart/switch-on.svg"
                              id="imgl3"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body" v-if="labels.length >= 3">
                            <h6>{{ labels[2].label }}</h6>
                          </div>
                          <button
                              type="button"
                              class="btn btn-circle btn-success"
                              @click="functionOn('K3T#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                          <button
                              type="button"
                              class="btn btn-circle btn-danger"
                              @click="functionOff('K3F#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div id="trl4" v-if="pol4">
                      <div class="box-footer with-border p-0">
                        <div class="media media-single px-0 py-20">
                          <img
                              v-if="img4 == false"
                              src="/assets/images/smart/switch-off.svg"
                              id="imgl4"
                              width="32px"
                              height="32px"
                          />
                          <img
                              v-if="img4 == true"
                              src="/assets/images/smart/switch-on.svg"
                              id="imgl4"
                              width="32px"
                              height="32px"
                          />

                          <div class="media-body" v-if="labels.length === 4">
                            <h6>{{ labels[3].label }}</h6>
                          </div>
                          <button
                              type="button"
                              class="btn btn-circle btn-success"
                              @click="functionOn('K4T#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                          <button
                              type="button"
                              class="btn btn-circle btn-danger"
                              @click="functionOff('K4F#')"
                          >
                            <i class="mdi mdi-power mdi-24px"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
            <!-- /.box -->
          </div>
        </div>
      </section>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <SubModalDel v-bind:data_info_sub="data_info_keytouch"></SubModalDel>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_keytouch"
    ></SubModalSetPass>
  </v-app>
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {
  ErrorToastMsg,
  Internets,
  SucessToastMsg,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var lmap_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalSetPass,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {}
  },
  data() {
    return {
      passlamp: "",
      pass: "",
      data_info_keytouch: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      set_password: "",
      isHidden: false,
      img1: false,
      img2: false,
      img3: false,
      img4: false,
      imgall: false,
      pol: "",
      data: {},
      labels: {},
      isModalVisible: false,
      polall:false,
      pol1:false,
      pol2:false,
      pol3:false,
      pol4:false,
    };
  },
  created() {
    this.data = JSON.parse(localStorage.getItem("infoSubDevice"));
    let data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = data.password === 1;
    this.set_password = this.$store.getters.getSetPasswordSub;
    if (!JSON.parse(this.data.labels))
      this.labels = [{pol: "0", label: "L1"}, {pol: "1", label: "L2"}, {pol: "2", label: "L3"}, {
        pol: "3",
        label: "L4"
      }];
    else
      this.labels = JSON.parse(this.data.labels);
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;

          this.showGetMsg(String(data));
          this.exitModalLoading();
          if (showToast == 1) {
            // SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted: function mounted() {
    try {
      let self = this;
      Internets.Internet;
      setTimeout(function () {
        self.lastStausLamp();
      }, 300);
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.pol = this.$store.getters.getPolSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;

      //Show and Hide pol in views
      // var trall = document.getElementById("trall");
      // var trl1 = document.getElementById("trl1");
      // var trl2 = document.getElementById("trl2");
      // var trl3 = document.getElementById("trl3");
      // var trl4 = document.getElementById("trl4");
      // console.log("this.pol", trl1);

      if (this.pol == 1) {
        this.pol1=true
        // trl1.style.display = "block";
      }
      if (this.pol == 2) {
        this.pol1=true
        this.pol2=true
        this.polall=true
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trall.style.display = "block";
      }
      if (this.pol == 3) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.polall=true
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trl3.style.display = "block";
        // trall.style.display = "block";
      }
      if (this.pol == 4) {
        this.pol1=true
        this.pol2=true
        this.pol3=true
        this.pol4=true
        this.polall=true
        // trl1.style.display = "block";
        // trl2.style.display = "block";
        // trl3.style.display = "block";
        // trl4.style.display = "block";
        // trall.style.display = "block";
      }
      //............ subscribe
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.count
      );
      this.$mqtt.subscribe(topic1);
      //............/ subscribe
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: this.$t("message.ListDevice.err_process"),
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t("message.public.OK"),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      refresh: true,
      timer: true,
      editName: true,
      del: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubKeyTouch');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: "RF",
        }
        localStorage.setItem("data_edit_sub", JSON.stringify(data));
        console.log(this.home_id, this.serial_number, this.count)
        this.$router.push({
          name: "SubKeyTouchEdit",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        this.lastStausLamp();
      }
      if (value == "timer") {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: "RF",
        }
        localStorage.setItem("data_edit_sub", JSON.stringify(data));
        this.$router.push({
          name: "TouchTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    functionOn(status) {
      if (this.set_password == 1) {
        lmap_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(status, '');
      }
    },
    functionOff(status) {
      if (this.set_password == 1) {
        lmap_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(status, '');
      }
    },
    sendReq(password) {
      this.sendMessage(lmap_status, password);
    },
    sendMessage(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.openModalLoading();
              $("#modal-pass-lamp").modal("hide");
              timeout = setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      if (msg == "K1F") {
        this.img1 = false;
        this.imgall = false;
      }
      if (msg == "K1T") {
        this.img1 = true;
        this.showItemAll();
      }
      if (msg == "K2F") {
        this.img2 = false;
        this.imgall = false;
      }
      if (msg == "K2T") {
        this.img2 = true;
        this.showItemAll();
      }
      if (msg == "K3F") {
        this.img3 = false;
        this.imgall = false;
      }
      if (msg == "K3T") {
        this.img3 = true;
        this.showItemAll();
      }
      if (msg == "K4F") {
        this.img4 = false;
        this.imgall = false;
      }
      if (msg == "K4T") {
        this.img4 = true;
        this.showItemAll();
      }
      if (msg == "K5T") {
        this.img1 = true;
        this.img2 = true;
        this.img3 = true;
        this.img4 = true;
        this.imgall = true;
      }
      if (msg == "K5F") {
        this.img1 = false;
        this.img2 = false;
        this.img3 = false;
        this.img4 = false;
        this.imgall = false;
      }
    },
    showItemAll() {
      if (this.pol == 2 && this.img1 == true && this.img2 == true) {
        this.imgall = true;
      }
      if (
          this.pol == 3 &&
          this.img1 == true &&
          this.img2 == true &&
          this.img3 == true
      ) {
        this.imgall = true;
      }
      if (
          this.pol == 4 &&
          this.img1 == true &&
          this.img2 == true &&
          this.img3 == true &&
          this.img4 == true
      ) {
        this.imgall = true;
      }
    },
    sendPassLamp() {
      if (!passHomeValidationInput(this.passlamp)) return;
      this.sendMessage(lmap_status, this.passlamp);
      this.passlamp = "";
    },
    lastStausLamp() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: this.serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data, "");
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.showStatusLamp(message);
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    showStatusLamp(message) {
      // console.log("messag",message)
      var L1_r = message.charAt(0);
      var L2_r = message.charAt(1);
      var L3_r = message.charAt(2);
      var L4_r = message.charAt(3);

      if (L1_r == 0) {
        this.img1 = false;
        this.imgall = false;
      }
      if (L1_r == 1) {
        this.img1 = true;
      }
      if (L2_r == 0) {
        this.img2 = false;
        this.imgall = false;
      }
      if (L2_r == 1) {
        this.img2 = true;
      }
      if (L3_r == 0) {
        this.img3 = false;
        this.imgall = false;
      }
      if (L3_r == 1) {
        this.img3 = true;
      }
      if (L4_r == 0) {
        this.img4 = false;
        this.imgall = false;
      }
      if (L4_r == 1) {
        this.img4 = true;
      }
      if (this.pol == 4 && message == "1111") {
        this.img1 = true;
        this.img2 = true;
        this.img3 = true;
        this.img4 = true;
        this.imgall = true;
      }
      if (this.pol == 3 && message == "1110") {
        this.img1 = true;
        this.img2 = true;
        this.img3 = true;
        this.imgall = true;
      }
      if (this.pol == 2 && message == "1100") {
        this.img1 = true;
        this.img2 = true;
        this.imgall = true;
      }
      if (message == "0000") {
        this.img1 = false;
        this.img2 = false;
        this.img3 = false;
        this.img4 = false;
        this.imgall = false;
      }
    },
  },
};
</script>


<style scoped>
</style>
