
<template>
  <div>
    <div class="row mt-1 px-20">
      <div class="col-12">
        <div class="row rounded-16 mt-0">
          <div class="col-xl-12 col-md-12 col-12 rounded-16">
            <section class="content">
          <div class="row ">
            <div class="col-12 ">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div class="box bg-thermostat-temp mb-2">
                    <div class="box-body">
                      <p class="font-size-20 text-white">
                        <i class="mdi mdi-chart-arc"></i>
                        {{ $t("message.subDevice.thermostatTemp.reports") }}
                      </p>
                      <ul
                        class="d-flex justify-content-between bg-tempEl py-3"
                        style="border-radius: 10px"
                      >
                        <li class="text-right list-unstyled">
                          <div class="font-size-30 d-flex">
                            <v-img src="/assets/images/smart/home-temp.svg" max-height="30" max-width="30" class="m-0 p-0"></v-img>
                            <span class="font-size-20 text-white align-self-center ">
                              {{ $t("message.subDevice.thermostatTemp.environment_temp") }}
                            </span>
                          </div>
                        </li>
                          <li class="text-left list-unstyled">
                          <div class="font-size-30 mx-2">
                            <span class="font-size-30 text-white">
                              {{temp_value 
                              }}<sup class="font-size-20 opacity: 0.8!important"
                                >°C</sup
                              >

                              <h6
                                class="mb-0 text-bold font-size-100"
                                id=""
                                v-if="!Number.isNaN(parseInt(temp_value))"
                              ></h6>
                              <span v-else class="mb-0 p-0 text-white font-size-30">
                                --</span>
                            </span>
                          </div>
                        </li>
                      
                      </ul>
                      <ul
                        class="
                          d-flex justify-content-between
                          py-3
                          bg-tempEl
                        "
                        style="border-radius: 10px"
                      >
                        <li class="text-right list-unstyled">
                          <div class="font-size-30">
                            <i
                              class="mdi mdi-thermometer mdi-24px text-white"
                            ></i>
                            <span class="font-size-20 text-white ">
                              {{ $t("message.subDevice.thermostatTemp.thermostat_temp") }}
                            </span>
                          </div>
                        </li>
                          <li class="text-left list-unstyled">
                          <div class="font-size-20 text-white mx-2">
                              <h6 class="mb-0 font-size-30" id="lbl-temp" v-if="!Number.isNaN(parseInt(msg_temp))">
                        {{ temp_home }}<sup class="font-size-20 opacity: 0.8!important">°C</sup
                      >
                      </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="
                        col-xl-12 col-md-12 col-12
                        rounded-16
                        py-0
                        text-center
                      "
                    >
                      <div class="box-footer border-0 bg-thermostat-temp px-5">
                        <div class="row">
                          <div class="col-12 text-right p-0 d-flex justify-content-between">
                          <div class="d-flex">
                              <VueLoadingButton
                              @click.native="BroadCast"
                              class="btn btn-default btn-sm "
                              :loading="isLoading"
                              :style="isStyled"
                            >
                              <small class="text-primary">{{
                                $t("message.public.update")
                              }}</small>
                            </VueLoadingButton>
                          </div>
                            <small class="text-white pull-right align-self-center mr-1">
                              SN : {{ subdevice_serial }}</small
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  <!-- /. Wrapper -->
</template>
<script>
import VueLoadingButton from "vue-loading-button";
export default {
  components: {
       VueLoadingButton,
  },
  props: {
    serial_number: {},
    home_id: {},
    subdevice_serial:{},
    status: {},
    temp_val:{},
    temp_home:{},
    
  },
  mqtt: {
    
   
  },
  data() {
    return {
      msg_temp: "16",
      temp_value: this.temp_val,
      isLoading: false,
      isStyled: true,
    };
  },
  watch: {
    status: function (newVal, oldVal) {
      this.parseMessageFromDevice(newVal)
    }
  },
  created() {
    this.BroadCast()
  },
   mounted() {
    this.temp_value = localStorage.getItem("primeryTemp")
    if(localStorage.getItem("primeryTemp") === undefined || localStorage.getItem("primeryTemp") === null || localStorage.getItem("primeryTemp") === 0 ){
    this.temp_value === ""
    }else{
this.BroadCast()
    }
  },
  methods: {
 BroadCast() {
      this.isLoading = true;
      let self = this;
     
        this.$emit('callThermostatTempRefresh'); // emit an event to parent
      setTimeout(function () {
        self.isLoading = false;
      }, 3000);
    }
  },
};
</script>
<style scoped>
.bg-thermostat-temp{
  background-color: #0c3064;
}
.bg-tempEl{
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.mdi-clock:before{
  color: #fff;
}
</style>
