import { render, staticRenderFns } from "./IrAirTimer.vue?vue&type=template&id=5279b976&scoped=true"
import script from "./IrAirTimer.vue?vue&type=script&lang=js"
export * from "./IrAirTimer.vue?vue&type=script&lang=js"
import style0 from "./IrAirTimer.vue?vue&type=style&index=0&id=5279b976&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5279b976",
  null
  
)

export default component.exports