<template>
  <v-app class="bg">
    <div class="mr-0">
      <!-- Main content -->
      <section class="content">
        <div class="row mt-5 px-20" id="app">
          <div class="col-12  py-0">
            <div class="alert alert-secondary p-0 m-0">
              <div class="media">
                <i class="mdi mdi-alert-circle-outline mdi-18px"></i>
                <div class="media-body">
                  <label id="lblaccountlogin">
                    {{ $t('message.irAir.txt1') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12  py-0">
            <div
                class="row rounded-16 mt-0"
                v-bind:key="index"
                v-for="(item, index) in items"
            >
              <div class="col-xl-12 col-md-12 col-12 rounded-16">
                <div

                    class="media align-items-center bg-white py-5 rounded-16"
                    @click="tst(item.key, item.key_value, item.message)"
                >
                  <img
                      class="avatar"
                      src="/assets/images/smart/air-conditioner.svg"
                      width="40px"
                      height="40px"
                      alt="..."
                  />
                  <div class="media-body">
                    <p>
                      <strong>{{ item.message }}</strong>

                    </p>
                    <div v-bind:key="index" v-for="(info, index) in infos">
                      <p v-if="info.key_count==item.key" class="mt-10 text-success">
                        {{ $t('message.irNone.active_scenario') }} <i class="mdi mdi-border-color mdi-18px"></i></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

export default {
  components: {},
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {}
  },
  data() {
    return {
      items: [],
      infos: [],
      key_value: [],
      title: '',
      isModalVisible: false
    };
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    this.title = this.$t('message.irAir.list');
    let self = this;
    this.$store.dispatch("infoIrControls");
    setTimeout(function () {
      self.lastStatus();
    }, 50);
    let details = {
      title: this.title,
      noneHome: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SettingsNoneIrClock');
  },
  methods: {
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    tst(key, key_value) {
      const datairstatus = {
        key_value: key_value,
      };
      localStorage.setItem("infoIrStatus", JSON.stringify(datairstatus));
      this.$router.push({
        name: "irairlearnnone",
        params: {serial_number: this.serial_number, count: this.count, keyitem: key},
      });
    },
    lastStatus() {
      let self = this;

      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let uid = this.$store.getters.getUIDIrControl;

      var data = {
        user_id: user_id,
        token: token,
        control_uid: uid,
      };
      let URL = getUrl.controlInfo;
      const p = postAjaxCall(URL, data);
      this.openModalLoading()
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.exitModalLoading()
          self.infos = jsonArray.content.control[0].key_info;

          var check = "";
          for (var i = 1; i <= 14; i++) {
            var infoAll = jsonArray.content.control[0].key_info;
            infoAll.forEach(function (item) {
              if (item.key_count == i) check = item.key_value;
            });
            if (check != "")
              self.items.push({
                message: self.$t('message.irAir.scenario') + i,
                key: i,
                key_value: check,
              });
            else
              self.items.push({
                message: self.$t('message.irAir.scenario') + i,
                key: i,
                key_value: "MODE$AUTO-SWING$VOFF-FAN$LOW-TEMP$16",
              });
            check = false;
          }
        } else {
          self.exitModalLoading();

          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.replace({
          name: "irairnone",
          params: {serial_number: this.serial_number, count: this.count},
        });
      }
    },

  },
};
</script>
<style scoped>
</style>
