<template>

    <main class="LayoutDefault__main">
      <slot/>
    </main>
 
</template>

<script>
export default {
  name: "LayoutNoAppBar"
}
</script>

<style scoped>

</style>