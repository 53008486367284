<template>
  <v-app  class="bg">
   <div class="px-3 mt-8">
      <label>توضیحات گزارش محرمانگی </label>
      <v-card max-width="1750" class="mx-auto ">
     <div :key="index" v-for="(item, index) in info">
            <CmpRowList
                :infoItem="item"
                :subTitleShow="false"
                :ripple="false"
                @clicked="clicked"
            ></CmpRowList>
            </div>
          <!-- </v-col> -->
        <!-- </v-row> -->
      </v-card>
    </div>
    <div class="fixed-bottom mb-1 px-4">
   <cmpbutton
         class="mb-1"
         :txtBtn="'ارسال دستور' "
         :loading="loading"
         @clickedbtn="CallBackBtn">
  </cmpbutton>
   </div>
  </v-app>
</template>
<script>
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import LayoutDefault from "../../../../layouts/LayoutDefault";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";

import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import {CommandAlarm} from "@/../public/assets/js/utils/get_alarm_command.js"
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {
SucessToastMsg,
ErrorToastMsg,
BetweenStr,
} from "@/../public/assets/js/utils/util.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";

var connection = false;
var showToast;
var timeout;
export default {
props: {
  home_id:{},
  serial_number: {},
  click: {}
},
mqtt: {
  "+/+/receive/+/security"(data, topic) {
    if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
      connection = true;
      this.loading=false;
      clearTimeout(timeout);
      this.showGetMsg(String(data));
      this.exitModalLoading();
      if (showToast == 1) {
        SucessToastMsg.Msg();
      }
    }
  },
},
data() {
  return {
    loading: false,
    isModalVisible: false,
    info:[]
  };
},
components: {
  cmpbutton,
  CmpRowList
},
mounted() {
  let data = {
    title: "گزارش محرمانگی",
  }
  localStorage.setItem("AppBar", JSON.stringify(data));
  this.$emit('update:layout', LayoutDefault);
  this.$emit('update:update', 'PrivacyReport');

  let self = this;
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = this.$store.getters.getSetPasswordSub;
    this.main_admin = this.$store.getters.getadmin;
    this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
    this.onSubscribe();
},
created() {
  this.info.push({ index:1, title:'گزارش محرمانگی', actions:'switch', switch:false, img:"/assets/images/smart/alarm/ant0.webp"}) 
},
watch: {
  click(val) {
    this.onClickChild(val)
  }
},
methods: {

  sendReq(password) {
    this.sendRequest(curtain_status, password);
  },
  sendRequest(saction, password) {
    console.log("saction",saction)
    var self = this;
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    var topic_final = getTopics.getTopicSecuritySend(
        this.serial_number,
        this.subdevice_serial
    );
    // ----------------------Data------------------//
    var data = {
      user_id: user_id,
      token: token,
      topic: topic_final,
      message: saction,
      password: password,
      client_id: "fara" + Date.now(),
    };
    // --------------------Requsest----------------------//
    let URL = getUrl.mqttRequest;
    const p = postAjaxCall(URL, data);
    this.openModalLoading();
    showToast = 1;
    // ---------------------Rseponse--------------------//
    p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.openModalLoading();
            // $("#modal-pass-curtain").modal("hide");
            connection = false;
            timeout = setTimeout(function () {
              self.exitModalLoading();
              self.loading=false
              if (!connection) {
                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                }
              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            self.exitModalLoading();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({name: "home"});
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {
        }
    );
  },
  openModalLoading() {
    this.isModalVisible = true;
    setTimeout(() => {
      this.isModalVisible = false;
    }, 10000)
  },
  exitModalLoading() {
    this.isModalVisible = false;
  },
  onSubscribe() {
    var topic1 = getTopics.getTopicSecurityReceive(
        this.serial_number,
        this.subdevice_serial
    );
    this.$mqtt.subscribe(topic1);
  },
  onClickChild(value) {
    if (value == "back") {
      this.$router.push({
        name: "SettingAlarmInfo",
        params: {home_id:this.home_id,serial_number: this.serial_number},
      });
    }
  },
  CallBackBtn(){
     this.loading=true;
    this.sendRequest(CommandAlarm.getCommandPrivacy(this.info[0].switch), '')
    console.log('switch',CommandAlarm.getCommandPrivacy(9,this.volumn))
  },
  clicked(){
    console.log('switch',this.info[0].switch)
  },
  showGetMsg(msg) {
    if(msg!=null){
      this.parseStatus(msg);
    }
  },
  parseStatus(msg) {
    
    var status="";
    msg=='STATUSON' ? status=true : status=false;
    console.log('status',typeof(status));
    console.log('status SS',typeof(this.info[0].switch))
    this.info[0].switch=status;
  },
},
};
</script>t
<style scoped>
</style>