<template>
  <v-app class="bg">
    <div class="mr-0">
        <!-- Main content -->
        <section class="content">
          <!-- row -->
          <div class="row">
            <!-- col -->
            <div class="col-12 col-lg-12 p-0">
              <div class="box">
                <div class="box-body p-0">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="bg-white" style="height: 812px;">
                      <div style="text-align: center; margin-top: 40px">
                        <div class="fontawesome-icon-list m-0">
                          <img
                            src="/assets/images/smart/vector-add-user.svg"
                            class="avatar-smart"
                          />
                          <h6 class="font-weight-bold">
                            {{$t('message.client.add_phone')}}
                          </h6>
                        </div>
                      </div>
                      <br />
                      <form class="px-40">
                        <div class="form-group">
                         <h6>{{$t('message.profile.tel')}} <span class="text-danger">*</span></h6>
                          <input
                            id="inputaddclient"
                            type="number"
                            class="form-control"
                            aria-invalid="false"
                            autocomplete="off"
                            maxlength="32"
                            placeholder="**********09"
                            v-model="userphone"
                          />
                        </div> 
                        
                        <div class="row">
                          <!-- /.col -->
                          <div class="col-12 text-center">
                            <button
                            id="btn-add-client"
                              type="button"
                              class="btn btn-info btn-block margin-top-10"
                              v-on:click.prevent="addClient()"
                            >
                              {{$t('message.public.submit')}}
                            </button>
                          </div>
                          <!-- /.col -->
                        </div>
                      </form>
                      <div class="text-center" style="height: 50px"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </section>
        <!-- /. Main content -->

      </div>
      <!-- /.Content Wrapper. Contains page content -->
    </v-app>
    <!-- /.modal Add Home-->
</template>
 <script>
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {MobileValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";
var admin_verify_id;
export default {
  components: {},
 props: { 
     serial_number: {},
     home_id: {},
     type:{},
     click: {}
    },
  data() {
    return {
      info: null,
      userphone:'',
      title: '',
      data_info: [
         this.data_infos
      ]
    };
  },

  mounted() {
   this.title= this.$t('message.client.add_user');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AddClient');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
   addClient(){
      let self=this;
      //--------------------------------------  variable --------------------------------------//
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var userphone = this.userphone;
      var home_id=this.home_id;
      if (!MobileValidationInput(userphone))
        return;
      //--------------------------------------  data --------------------------------------//
      var data = {
      user_id: user_id,
      token: token,
      home_id: home_id,
      client_mobile: userphone
    }
      //-----------------------Request ------------------------------//
      let URL = getUrl.clientAdd;
     const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            admin_verify_id= jsonArray.content.admin_verify_id;
            self.data_info.push(admin_verify_id);
            self.userphone='';
            self.showModal();

          } else {
            //error
          }
        },
       //--------------------------------------Err --------------------------------------//

        function (errr) {}
      );

   },
    showModal() {
      console.log("-add-client-verify")
        this.$router.push({
            name: "AddClientVerifyinfo",
            params: {
              home_id: this.home_id,
              serial_number: this.serial_number,
              verify:admin_verify_id,
              type:this.type
            },
          });
    },

    goBackAddClient(){
    this.$router.push({ name: "managementclientinfo", params: {home_id:this.home_id,serial_number:this.serial_number,type:this.type}});
    },
    onClickChild(value){
      if(value=='back'){
    this.$router.push({ name: "managementclientinfo", params: {home_id:this.home_id,serial_number:this.serial_number,type:this.type}});
      }
    },
  },
};
</script>
<style scoped>
</style>
