<template>
  <v-app class="bg">
    <v-container>
      <div class="mt-5">
        <v-row class="justify-center px-3 py-0 text-center">
          <v-col cols="12" md="4" class="py-0">
            <img :src="'/assets/images/smart/img_curtain.png'"/>
          </v-col>
          <v-card class="mx-2 mt-5 py-5">
            <v-col cols="12" class="py-0 text-center  px-10">
              <v-row class="py-0 text-center">
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn
                      fab
                      large
                      outlined
                      color="primary"
                      elevation="3"
                      @click="openFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>

                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5">
                  <v-btn

                      fab
                      x-large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="stopFunction()"
                  >
                    <v-icon class="text-primary">mdi-pause</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn

                      fab
                      large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="closeFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>

      </div>
    </v-container>
    <my-modal v-show="isModalVisible"></my-modal>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
</template>

<script>
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import TimerInput from "../../Cmp/TimerInput.vue";
// import ModalOutletMomentray from "../../devicewifi/ModalOutletMomentray.vue";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import { passHomeValidationInput } from "../../../../public/assets/js/utils/error_handler";

import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var timeout;

export default {
  components: {
    CmpPasscode,
    // ModalOutletMomentray,
    TimerInput,

  },
  props: {
    serial_number: {},
    count: {},
    name: {},
    click: {},
    // subdevice_serial:{},
  },
  data() {
    return {
      user_id:'',
      token:"",
      info:[],
      pass: true,
      set_password: "",
      door_status: "",
      txtStatus: '',
      data_info_door: [this.serial_number],
      main_admin: "",
      title: "",
      isModalVisible: false,
      long_time: "",
      subdevice_serial: "",
      // data for  instant setting
      delayOpen:"",
      keyOpen:"",
      delayClose:"",
      keyClose:"",
      // type Request 0  for action and 1  for instant Setting
      typeRequest:0,
      opentime :20,
      closetime : 20
    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {
      console.log("data action",String(data))
      if (topic.split("/")[1] === this.serial_number && topic.split("/")[3] =='256') {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.showGetMsg(String(data));
          this.exitModal();
         
            SucessToastMsg.Msg();
         
        }
      }
    },
    "+/+/receive/256/setting"(data, topic) {
      var TimeRes = BetweenStr.getBetweenStr(String(data), "K1:", "S")[1];
      this.showGetMsgBroadCast(String(data));
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == this.subdevice_serial
      ) {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          localStorage.setItem("shutterLong" + this.subdevice_serial, TimeRes);
          clearTimeout(timeout);
          // $("#modal-outlet-momentray").modal("hide");
         
            SucessToastMsg.Msg();
          
        }
      }
    },
  },

  mounted() {
    let self=this;
    try {
      this.info = localStorage.getItem("infoNoneHome") ? JSON.parse(localStorage.getItem("infoNoneHome")) : [];
      // this.pass = this.data.password === 1;
      this.set_password= this.info.set_password;
      // this.main_admin = this.$store.getters.getadmin;
    //  console.log("info name",info.name)

      this.user_id = localStorage.getItem("user_id");
      this.token = localStorage.getItem("token");

      this.onSubscribe();
      this.onSubscribeSetting();
      setTimeout(function () {
        self.lastStaus2Relay();
      }, 100);

    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: self.$t("message.public.operation_failure"),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: self.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            // self.$router.push({
            //   name: "homeDevices",
            //   params: {
            //     home_id: self.home_id,
            //     serial_number: self.serial_number,
            //   },
            // });
            swal.close();
          }
        }
      );
    }
    let details = {
      title: this.info.name,
      noneHome_setting: true,
      noneHome: true
    };
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit("update:layout", LayoutDefault);
    this.$emit("update:update", "NCurtain");
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    lastStaus2Relay() {
      let self = this;
      // ------------------Data--------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        serial_number: this.serial_number,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.noneStatus;
      const p = postAjaxCall(URL, data);
      this.openModal();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModal();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              console.log('message',message)
              self.parseStatusShutter(message);
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    parseStatusShutter(msg) {
      console.log('parseStatusShutter',msg)
      if (msg == '1000') {
        this.txtStatus = this.$t('message.public.open')
      }
      if (msg == '0000') {
        this.txtStatus = this.$t('message.public.stop')
      }
      if (msg == '0010') {
        this.txtStatus = this.$t('message.public.close')
      }
    },
    setTimeShutter(delayOpen, keyOpen, delayClose, keyClose) {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
        this.delayOpen=delayOpen;
        this.keyOpen=keyOpen;
        this.delayClose=delayClose;
        this.keyClose=keyClose;
        this.typeRequest=1;
       
      } else {
        this.sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, "");
      }
    },
    sendRequestLong(delayOpen, keyOpen, delayClose, keyClose, password) {
      var self = this;

      var sendDelayTime =
        "K" +
        keyOpen +
        ":" +
        delayOpen +
        "S" +
        "~" +
        "K" +
        keyClose +
        ":" +
        delayClose +
        "S#";
      var topic_final = getTopics.getTopicElevatorSend(
        this.serial_number,
        this.subdevice_serial
      );

      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: sendDelayTime,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            connection = false;
            console.log('jsonArray',jsonArray);
            $("#modal-pass-code").modal("hide");
            $("#modal-outlet-momentray").modal("hide");
            timeout = setTimeout(function () {
              self.exitModal();
              // connection = false;
              if (!connection) {
             
                ErrorToastMsg.Msg();
              }
              
              connection = false;
            }, 3000);
          } else {
            self.exitModal();
            $("#modal-outlet-momentray").modal("hide");
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    onClickChild(value) {
      if (value === "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
      if (value === 'noneHomeSetting') {
        let data = {
          labels: this.labels,
          pol: this.pol,
          type: 19,
        };
        localStorage.setItem("data_edit", JSON.stringify(data));
        this.$router.push({
          name: "SettingNoneCurtain",
          params: {
            serial_number: this.serial_number,
          },
        });
      }
      // if (value == "del") {
      //   $("#modal-del-sub").modal("show");
      // }
      // if (value == "edit") {
      //   $("#modal-edit-name").modal("show");
      // }
      // if (value == "pass") {
      //   $("#modal-set-pass-sub").modal("show");
      // }
      // if (value === "shuttersetting") {
      //   $("#modal-outlet-momentray").modal("show");
      // }
    },
    
    openFunction() {
      this.sendMessage("K1T#");
      this.typeRequest=0;
    },
    stopFunction() {
      this.sendMessage("K2T#");
      this.typeRequest=0;
    },
    closeFunction() {
      this.sendMessage("K3T#");
      this.typeRequest=0;
    },
    sendMessage(status) {
      if (this.set_password == 1) {
        this.door_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(status, "");
      }
    },
    sendReq(password) {
      if(this.typeRequest==0){
         this.sendRequest(this.door_status, password);
        }
      else if(this.typeRequest==1){
        this.sendRequestLong(this.delayOpen, this.keyOpen, this.delayClose, this.keyClose,password);
      }
    },
    sendRequest(status, password) {
      var self = this;
    
      var topic_final = getTopics.getTopicActionSend(
        this.serial_number,
        256
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: status,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.nonemqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModal();
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          var jsonArray = JSON.parse(response.responseText);
          if (responseAjax(response)) {
            self.openModal();
            // connection = false;
            $("#modal-pass-code").modal("hide");
            timeout = setTimeout(function () {
              self.exitModal();
              // connection = false;
              if (!connection) {
                ErrorToastMsg.Msg();
            }
              connection = false;
            }, 3000);
          } else {
            self.exitModal();
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function (errr) {}
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
    showGetMsgBroadCast(msg) {
      this.opentime = msg.split("K1:").pop().split("S")[0];
      this.closetime = msg.split("K3:").pop().split("S")[0];
      localStorage.setItem("openTimeShutter", this.opentime);
      localStorage.setItem("closeTimeShutter", this.closetime);
      console.log('opentime',this.opentime)
      console.log('closetime',this.closetime)
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
       256
      );
      this.$mqtt.subscribe(topic1);
      console.log("topic1",topic1)
    },
    onSubscribeSetting() {
      var topic1 = getTopics.getTopicElevatorReceive(
        this.serial_number,
        256
      );
      this.$mqtt.subscribe(topic1);
    },
    showGetMsg(msg) {
      this.parseStatusShutter(msg)
    },
    getNameCmpCallback(name_value) {
      let self = this;
      
      var data = {
        user_id: this.user_id,
        token: this.token,
        name: name_value,
        serial_number: this.subdevice_serial,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.$router.push({
                name: "homeDevices",
                params: {
                  serial_number: self.serial_number,
                  home_id: self.home_id,
                },
              });
              self.lastStatus(this.user_id, this.token);
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>
<style scoped>
</style>
