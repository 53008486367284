<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <!-- row -->
        <CmpControlAir2
          v-if="cmpShow"
          :command="learned"
          :status="statusAir"
          @clicked="getAirControlCmpCallback"
        ></CmpControlAir2>
        <!-- /.row -->
      </section>
      <!-- /. Main content -->
    </div>
    <ModalIr
      v-show="isModalVisibleIr"
      v-bind:data_infos="data_info"
      @close="closeModalIr"
    >
    </ModalIr>
  </v-app>
  <!-- /. Wrapper -->
</template>

 <script>
import ModalIr from "../ModalIr";
import {ErrorToastMsg, Internets} from "../../../../public/assets/js/utils/util.js";
import {
  SuccessAlert,
  ErrorAlert,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../public/assets/js/utils/topics.js";
import { getCommand } from "../../../../public/assets/js/utils/get_ir_gatway_command.js";
import CmpControlAir2 from "../CmpControlAir2.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var timer;
var showToast;


export default {
  components: {
    ModalIr,
    CmpControlAir2,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    keyitem: {},
    click: {}
  },
  data() {
    return {
      isModalVisibleIr: false,
      data_info: [this.serial_number, this.count, this.key],
      title: '',
      learned: 0,
      found_key: false,
      cmpShow: false,
      statusAir:'',
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          connection = true;
          this.closeModalIr();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  created() {
    this.lastStatusControlInfo();
    let data = {
      title: this.$t('message.irAir.edit_AC')
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IrAirListEdit');
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoIrStatus");
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoIrControls");
    // this.lastStatus();
    this.onSubScribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    Learn(msg) {
      let self = this;
      // this.data_info.push(value);
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var message = getCommand.getCommandLearnAir(this.count, this.keyitem, msg);
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      this.showModalIr();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          timer = setTimeout(function () {
            if (!connection) {
              self.closeModalIr();
              if (showToast == 1) {
                ErrorAlert.Alert();
              }
              self.$router.push({
                name: "irairinfo",
                params: {
                  home_id: self.home_id,
                  serial_number: self.serial_number,
                  count: self.count,
                },
              });
            }
            showToast = 0;
            connection = false;
          }, 30000);
        } else {
          self.closeModalIr();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
          //error
        }
      });
    },
    showModalIr() {
      this.isModalVisibleIr = true;
    },
    closeModalIr() {
      this.isModalVisibleIr = false;
    },
    showGetMsg(msg) {
      let self = this;
      var sucess = msg.includes("LEARNOK");
      var err = msg.includes("LEARNERROR");
      var cancel = msg.includes("CANECLERROR");
       var order = msg.includes("ORDEROK");
      this.exitModalLoading();
      if (sucess) {
        if (showToast == 1) {
          self.closeModalIr();
          SuccessAlert.Alert();
        }
        this.goIrAir();
      }
      if (err) {
        if (showToast == 1) {
          self.closeModalIr();
          ErrorAlert.Alert();
        }
        this.goIrAir();
      }
      if (cancel) {
        self.closeModalIr();
        clearTimeout(timer);
       this.goIrAir();
      }
       if (order) {
        self.closeModalIr();
        clearTimeout(timer);
        this.goIrAir();
      }
    },
      goIrAir(){
     this.$router.push({
          name: "irairinfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irairinfo",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    onSubScribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    lastStatusControlInfo() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: user_id,
        token: token,
        control_uid: uid,
      };      
      let URL = getUrl.controlInfo;
      const p = postAjaxCall(URL, data, "");
      // this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          // self.exitModalLoading();

          // self.info = jsonArray.content.control[0].key_info;
          self.learned = jsonArray.content.control[0].learned;
          self.cmpShow = true;
          var key_info = jsonArray.content.control[0].key_info;
          var key_list_info = jsonArray.content.control[0].key_list_info;

          key_info.forEach(function (item) {
            // var message = item.key_code;
          
              if ((item.key_count == self.keyitem)) {
                
                self.statusAir=item.key_value;
            
            }
          });
          if (key_list_info !== 0) {
            self.list_air_keys = key_list_info;
          }
        } else {
          // self.exitModalLoading();

          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    getAirControlCmpCallback(msg) {
    
      if (this.learned == 0) {
        this.Learn(msg);
      } else {
        this.checkAirList(msg);
      }
    },
    checkAirList(msg) {
      // compare for list string server with string  air user
      let self = this;
      
      this.list_air_keys.forEach(function (item) {
        if (item.name == msg) {
          self.found_key = true;
          self.SendReguestTest(item.code, msg);
        }
      });
      if (!this.found_key) {
        swal({
          title: this.$t('message.irAir.send'),
          text: this.$t('message.irAir.notfound'),
          type: "warning",
          confirmButtonColor: "#dc3545",
        });
      }
    },
    SendReguestTest(code, msg) {
    
      let self = this;
      this.onSubScribe();
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var message = getCommand.getCommandOrder(this.count, this.keyitem, msg, code);
     
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      // this.showModal();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          connection = false;
          var jsonArray = JSON.parse(response.responseText);
        
          self.found_key = false;
          // self.emptyMsg();
          timer = setTimeout(function () {
            // self.closeModal();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            showToast = 0;
            connection = false;
          }, 5000);
        } else {
          //error
        }
      });
    },
  },
};
</script>
<style scoped>
</style>
