<template>
  <v-app class="bg mr-0">
    <div class="mr-0">
      <section class="content px-25">
        <div class="row">
          <div
            class="col-4 col-lg-4 px-0"
            v-bind:key="index"
            v-for="(infos, index) in info"
          >
            <div class="flexbox flex-justified text-center mt-20">
              <div class="b-1 box rounded-12 py-10">
                <img
                  v-if="infos.type_code == 1001"
                  style="
                    max-height: 60px !important;
                    min-height: 60px !important;
                  "
                  v-on:click="sendId(infos.name, infos.type_code)"
                  src="/assets/images/smart/ir-television.svg"
                  alt=""
                />
                <img
                  v-if="infos.type_code == 1002"
                  style="
                    max-height: 60px !important;
                    min-height: 60px !important;
                  "
                  v-on:click="sendId(infos.name, infos.type_code)"
                  src="/assets/images/smart/ir-speaker.svg"
                  alt=""
                />
                <img
                  v-if="infos.type_code == 1003"
                  style="
                    max-height: 60px !important;
                    min-height: 60px !important;
                  "
                  v-on:click="sendId(infos.name, infos.type_code)"
                  src="/assets/images/smart/ir-air-conditioner.svg"
                  alt=""
                />
                <p class="mb-0 font-weight-300 mt-10">{{ infos.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <ModalIrAddControl v-bind:data_infos="data_info"></ModalIrAddControl>
  </v-app>
</template>

 <script>
import ModalIrAddControl from "./ModalIrAddControl";
import { postAjaxCall } from "../../../public/assets/js/postAjax";
import { responseAjax } from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";

import axios from "axios";
import LayoutDefault from "../../layouts/LayoutDefault";
//--------------------------------------  variable --------------------------------------//

export default {
  components: {
    ModalIrAddControl,
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {},
  },
  data() {
    return {
      info: null,
      data_info: [this.home_id, this.serial_number],
      title: "",
      isModalVisible: false
    };
  },
  created() {
    let data = {
      title: this.$t("message.ir.type"),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'IRType');
  },
  mounted() {
    let self = this;
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    var data = {
      user_id: user_id,
      token: token,
    };
    this.openModalLoading();
    let URL = getUrl.controlTypes;
    const p = postAjaxCall(URL, data, "");
    this.openModalLoading();
    p.then(function (response) {
      var jsonArray = JSON.parse(response.responseText);
      console.log("response.status", response.status);
      if (responseAjax(response)) {
        self.closeModalLoading();
        self.info = jsonArray.content.control;
      } else {
        if (response.status == 401) {
          if (jsonArray.code == "4001") {
            self.$router.replace({ name: "home" });
          }
        }
      }
    });
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    sendId(name, type_code) {
      this.data_info.push(type_code);
      this.$router.push({
        name: "irbrandInfo",
        params: {
          home_id: this.home_id,
          type_code: type_code,
          serial_number: this.serial_number,
        },
      });
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "irGatewayInfo",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
    },
  },
};
</script>



<style scoped>
</style>
