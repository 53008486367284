<template>
  <v-app class="bg">
    <Step2Timer :end="false" :daysData="daysdata" @myFunction="MyFunction" />
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../../public/assets/js/utils/util.js";
import { Tools } from "../../../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../../../public/assets/js/utils/topics.js";
import { postAjaxCall } from "../../../../../public/assets/js/postAjax";
import { responseAjax } from "../../../../../public/assets/js/utils/response_ajax_handler";
import {
  DayValidationInput,
  TimerValidationInput,
} from "../../../../../public/assets/js/utils/error_handler";
import Step2Timer from "@/components/Cmp/timer/create/Step2Timer.vue";
import { listOfDays } from "../../../../../public/assets/js/utils/timer_parser";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var showToast;
var connection = false;

export default {
  name: "CoolerFaraStep2Timer",
  components: {
    Step2Timer,
    CmpPasscode,
  },
  created() {
    this.lang = localStorage.getItem("Lang");
    this.daysdata = listOfDays();
    this.getCount();
  },
  data() {
    return {
      lang: "",
      title: "",
      time1: Tools.getNow(),
      time2: Tools.getNow(),
      daysdata: [],
      set_password: "",
      timer_count: "",
      days: [],
      temparray: [],
      selectedday: "",
      isModalVisible: false,
    };
  },
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {},
  },
  mounted() {
    let self = this;
    let data = {
      title: this.$t("message.timer.scheduler"),
    };
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit("update:layout", LayoutDefault);
    this.$emit("update:update", "Timer3");
    this.$store.dispatch("statusTimer");
    this.$store.dispatch("InfoSubDevice");
    this.set_password = JSON.parse(
      localStorage.getItem("infoSubDevice")
    ).set_password;
    setTimeout(function () {
      self.getCount();
    }, 50);
    //------subscribe

    var topic1 = getTopics.getTopicTimerReceive(this.serial_number, this.count);
    this.$mqtt.subscribe(topic1);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  mqtt: {
    "+/+/receive/+/timer"(data, topic) { 
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] ==
          this.count
      ) {
        if (topic.split("/")[4] === "timer") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            Tools.sweetAlert(
              this.$t("message.timer.timer"),
              this.$t("message.timer.added"),
              "success",
              3000
            );
          }
        }
      }
    },
  },
  methods: {
    MyFunction(days, time1) {
      this.days = days;
      this.time1 = time1;
      if (!DayValidationInput(days.length)) return;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.days, this.time1, "");
      }
    },
    sendReq(password) {
      this.sendMessage(this.days, this.time1, password);
    },
    sendMessage(days, time1, password) {
      let self = this;
      let message;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      var subdevice_serial = JSON.parse(
        localStorage.getItem("infoSubDevice")
      ).serial_number;
      let time = time1.split(":");
      let h, m;
      if (parseInt(time[1]) === 59) {
        if (parseInt(time[0]) === 23) {
          time[0] = 0;
          time[1] = 0;
        } else {
          time[1] = parseInt(time[1]) + 1;
          time[0] = 0;
        }
      } else {
        h = parseInt(time[0]);
        m = parseInt(time[1]) + 1;
      }
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }
      let time2 = h + ":" + m;
      console.log(time2);
      let msgday = this.getDaySelected(days);
      let timer_status = localStorage.getItem("statusTimer");
      timer_status = timer_status.replaceAll('"', "");
      message =
        "TIMER" +
        this.timer_count +
        "-" +
        timer_status +
        "-" +
        msgday +
        time1 +
        "-" +
        time2 +
        "#";
      var topic_final = getTopics.getTopicTimerSend(
        this.serial_number,
        this.count
      );
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      //-----------------------Response ------------------------//

      p.then(
        function (response) {
          if (responseAjax(response)) {
            self.exitModalLoading();
            let jsonArray = JSON.parse(response.responseText);
            setTimeout(function () {
              if (connection)
                self.$router.push({
                  name: "CurtainRFTimerShow",
                  params: {
                    serial_number: self.serial_number,
                    home_id: self.home_id,
                    count: self.count,
                  },
                });
              if (!connection) {
                self.exitModalLoading();

                if (showToast == 1) {
                  ErrorToastMsg.Msg();
                  self.$router.push({
                    name: "CurtainRFTimerShow",
                    params: {
                      serial_number: self.serial_number,
                      home_id: self.home_id,
                      count: self.count,
                    },
                  });
                }
              }
              showToast = 0;
              connection = false;
            }, 3000);
          } else {
            //error

            self.exitModalLoading();
          }
        },
        //----------------------Err ---------------------//
        function (errr) {}
      );
    },
    getCount() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let device_serial = this.serial_number;
      var tapp = localStorage.getItem("t_app");

      var data = {
        user_id: user_id,
        token: token,
        serial_number: device_serial,
      };
      //-----------------------Request ------------------------------//
      let URL = getUrl.timerCount;
      const p = postAjaxCall(URL, data);
      //-----------------------Response ------------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);

            self.timer_count = jsonArray.content.timer_count;
          } else {
            //error
          }
        },
        //----------------------Err ---------------------//
        function (errr) {}
      );
    },
    getDaySelected(days) {
      this.selectedday = "";
      this.temparray = [];
      var check = false;
      for (var i of this.daysdata) {
        this.temparray.push(i);
      }
      this.temparray.sort(
        (firstItem, secondItem) => firstItem.sort - secondItem.sort
      );
      this.temparray.forEach((element) => {
        days.forEach((values) => {
          if (values.value == element.value) check = true;
        });
        if (check) this.selectedday += element.value + "1" + "-";
        else this.selectedday += element.value + "0" + "-";
        check = false;
      });
      return this.selectedday;
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000);
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "CurtainRFTimer",
          params: { serial_number: this.serial_number, home_id: this.home_id },
        });
      }
    },
  },
};
</script>

<style scoped>
</style>