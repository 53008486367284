<template>
<v-app class="bg">
  <div>
    <div class="row px-20 mt-20 mb-30">
      <div class="col-lg-12 col-12 py-0">
        <div class="box rounded-16">
          <div class="box-body pb-0">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-12 py-0">
                <div
                  class="box-footer with-border p-0"
                  v-bind:key="index"
                  v-for="(info, index) in infos"
                >
                  <CmpRow2RF :info="info">
                    <div v-if="info.delay == 0">
                      <label class="switch" v-if="info.numbers.length == 1">
                        <input
                          name="checkL4"
                          type="checkbox"
                          v-model="info.value"
                          v-bind="$attrs"
                          :disabled="!info.active"
                        />
                        <span class="switch-indicator"></span>
                      </label>
                      <div v-if="info.numbers.length == 2">
                        <v-row>
                          <v-col cols="4">
                          <v-btn
                          class="mx-2"
                          fab
                          x-small
                          outlined
                          color="primary"
                          elevation="0"
                          :disabled="!info.active"
                          @click="click2modes(false, info.numbers,index)"
                        >
                          <v-icon class="text-primary" small>mdi-arrow-up</v-icon>
                        </v-btn>
                          </v-col>
                          <v-col cols="4">
                             <v-btn
                          class="mx-2"
                          fab
                          x-small
                          outlined
                          color="primary"
                          elevation="0"
                          :disabled="!info.active"
                          @click="click2modes(true, info.numbers,index)"
                        >
                          <v-icon class="text-primary" small>mdi-arrow-down</v-icon>
                        </v-btn>
                          </v-col>
                          <v-col cols="4">
                             <v-btn
                          class="mx-2"
                          fab
                          x-small
                          color="primary"
                          elevation="0"
                          :disabled="!info.active"
                          @click="click2modes('FF', info.numbers,index)">
                          <v-icon class="text-white">mdi-power</v-icon>
                        </v-btn> 
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div v-if="info.delay >= 1">
                      <v-icon class="text-primary px-1" small>mdi-timer</v-icon>
                      <label>{{ info.delay }} {{ $t("message.public.seconds")}}</label>
                    </div>
                     <template v-slot:select>
                       <v-checkbox v-model="info.active" class="mt-0" ></v-checkbox>
                       
                    </template>
                  </CmpRow2RF>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</v-app>
</template>
<script>
import CmpRow2RF from "@/components/Cmp/CmpRow2RF.vue";

export default {
  components: {
    CmpRow2RF,
  },
  props: ["infos"],

  data() {
    return {
      cmpShow: false,
    };
  },

  created() {},
  mounted() {},

  methods: {
    click2modes(status,info,index){
      this.infos[index].value=status
      
    }
  },
};
</script>
