<template>
  <v-app class="bg">
    <div class="mr-0">
      <section class="content">
        <div class="row rounded-16 px-2 mt-3">
          <div class="col-12 col-lg-12 rounded-16 py-0">
            <div class="box">
              <div class="box-body">
                <div class="media media-single px-2 py-5">
                  <i class="mdi mdi-water-pump mdi-24px text-gray"></i>
                  <div class="media-body">
                    <h6>{{ $t('message.subDevice.cooler_pomp') }}</h6>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-primary"
                      @click="FunctionBtnPump()"
                  >
                    <i class="mdi mdi-water mdi-24px" id="imgdry"></i>
                  </button>
                </div>
                <div class="box-footer with-border p-0">
                  <div class="media media-single px-2 py-5">
                    <img
                        id="imgfanlow"
                        src="/assets/images/smart/icon-fan-slow-black.svg"
                        style="width: 22px; height: 22px; background-color:#000"
                    />
                    <div class="media-body">
                      <h6>{{ $t('message.subDevice.cooler_low') }}</h6>
                    </div>
                    <button
                        type="button"
                        class="btn btn-circle btn-primary"
                        @click="FunctionBtnPump1()"
                    >
                      <img
                          id="imgfanlow"
                          src="/assets/images/smart/icon-fan-slow-white.svg"
                          style="width: 24px; height: 24px"
                      />
                    </button>
                  </div>
                </div>
                <div class="box-footer with-border p-0">
                  <div class="media media-single px-2 py-5">
                    <i class="mdi mdi-fan mdi-24px text-gray"></i>
                    <div class="media-body">
                      <h6>{{ $t('message.subDevice.cooler_fast') }}</h6>
                    </div>
                    <button
                        type="button"
                        class="btn btn-circle btn-primary"
                        @click="FunctionBtnPump2()"
                    >
                      <img
                          id="imgfanfast"
                          src="/assets/images/smart/icon-fan-fast-white.svg"
                          style="width: 24px; height: 24px"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.col -->
          </div>
        </div>
        <div class="row rounded-16 px-2 mt-2">
          <div class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white px-2 py-5 rounded">
              <i class="mdi mdi-power mdi-24px text-gray"></i>

              <div class="media-body">
                <h6>{{ $t('message.public.off') }}</h6>
              </div>
              <button
                  type="button"
                  class="btn btn-circle btn-danger"
                  @click="FunctionBtnOff()"
              >
                <i class="mdi mdi-power mdi-24px"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
    </div>
    <div class="modal center-modal fade" id="modal-pass-cooler" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('message.public.security') }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" style="font-size: 28px">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>{{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
              <input
                  type="number"
                  class="form-control"
                  required=""
                  aria-invalid="false"
                  maxlength="4"
                  pattern="[0-9]"
                  autocomplete="off"
                  id="idpassoutlet"
                  style="-webkit-text-security: disc; margin-top: 8px"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  :placeholder=" $t('message.ModalAddHome.password_hint') "
                  v-model="passcooler"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassCooler()"
                >
                  <span class="text-white">{{ $t('message.public.send') }}</span>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <SubModalDel v-bind:data_info_sub="data_info_cooler"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_cooler"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_cooler"></SubModalSetPass>
    <SubModalChangModel
        v-bind:data_info_sub="data_info_cooler"
    ></SubModalChangModel>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import SubModalChangModel from "../../subdevice/SubModalChangModel";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {SucessToastMsg, ErrorToastMsg,} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var cooler_status;
var showToast;
var model;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    SubModalChangModel,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      passcooler: "",
      data_info_cooler: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      set_password: "",
      pass: true,
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    this.title = this.$store.getters.getNameSub;
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      setting: true,
      change_model: true,
      editName: true,
      del: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCooler');
    let self = this;
    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    model = this.$store.getters.getModel;
    this.main_admin = this.$store.getters.getadmin;
    //............... subscribe
    var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.count
    );
    this.$mqtt.subscribe(topic1);
    //.............../ subscribe
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  }
  ,
  methods: {
    onClickChild(value) {
      console.log("value", value);
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "change_model") {
        $("#modal-change-model").modal("show");
      }
    }
    ,
    FunctionBtnOff() {
      if (this.set_password == 1) {
        if (model == 1) {
          cooler_status = "K1T#";
          $("#modal-pass-code").modal("show");
        }
        if (model == 2) {
          cooler_status = "K2F#";
          $("#modal-pass-code").modal("show");
        }
      } else {
        if (model == 1) {
          this.sendMessage("K1T#", '');
        }
        if (model == 2) {
          this.sendMessage("K2F#", '');
        }
      }
    },
    FunctionBtnPump() {
      if (this.set_password == 1) {
        if (model == 1) {
          cooler_status = "K2F#";
          $("#modal-pass-code").modal("show");
        }
        if (model == 2) {
          cooler_status = "K1T#";
          $("#modal-pass-code").modal("show");
        }
      } else {
        if (model == 1) {
          this.sendMessage("K2F#", '');
        }
        if (model == 2) {
          this.sendMessage("K1T#", '');
        }
      }
    },
    FunctionBtnPump1() {
      if (this.set_password == 1) {
        if (model == 1) {
          cooler_status = "K2T#";
          $("#modal-pass-code").modal("show");
        }
        if (model == 2) {
          cooler_status = "K1F#";
          $("#modal-pass-code").modal("show");
        }
      } else {
        if (model == 1) {
          this.sendMessage("K2T#", '');
        }
        if (model == 2) {
          this.sendMessage("K1F#", '');
        }
      }
    },
    FunctionBtnPump2() {
      if (this.set_password == 1) {
        if (model == 1) {
          cooler_status = "K1F#";
          $("#modal-pass-code").modal("show");
        }
        if (model == 2) {
          cooler_status = "K2T#";
          $("#modal-pass-code").modal("show");
        }
      } else {
        if (model == 1) {
          this.sendMessage("K1F#", '');
        }
        if (model == 2) {
          this.sendMessage("K2T#", '');
        }
      }
    },
    sendReq(password) {
      this.sendRequest(cooler_status, password);
    },
    sendMessage(saction, password) {
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let self = this;
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            let jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModalLoading();
              $("#modal-pass-cooler").modal("hide");
              setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    }
    ,
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassCooler() {
      if (!passHomeValidationInput(this.passcooler)) return;
      this.sendMessage(cooler_status, this.passcooler);
      this.passcooler = "";
    }
    ,
    goBackLamp() {
      this.$router.push({
        name: "homeDevices",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    }
    ,
  }
  ,
}
;
</script>


<style scoped>
</style>
