<template>
  <v-app class="bg">
    <div class="mt-5 mx-2">
      <section class="content">
        <div v-if="listRemote.length == 0">
          <CmpEmptyData 
            class="mt-10"
            :btnShow="false"
            :txtCaptionShow="false"
        >
        </CmpEmptyData>
        </div>
        <div :key="index" v-for="(item, index) in listRemote">
        <CmpRowList
                :infoItem="item"
                :subTitleShow="false"
                :ripple="false"
                :items="itemsMenu"
                @clicked="clickItemMenu"
                :icon="true"
                :img="false"
            ></CmpRowList>
        </div>
      </section>
    </div>
  </v-app>
</template>

<script>
import LayoutDefault from "../../../../layouts/LayoutDefault";
import CmpEmptyData from "../../../Cmp/public/CmpEmptyData.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import {CommandAlarm} from "@/../public/assets/js/utils/get_alarm_command.js"
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {SucessToastMsg,ErrorToastMsg,BetweenStr,} from "@/../public/assets/js/utils/util.js";import {getTopics} from "@/../public/assets/js/utils/topics.js";
import {postAjaxCall} from "@/../public/assets/js/postAjax";
import {responseAjax} from "@/../public/assets/js/utils/response_ajax_handler";
var connection = false;
var showToast;
var timeout;

export default {
  components: {
    CmpEmptyData,
    CmpRowList
  },
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      title: "",
      isModalVisible:false,
      user_id:'',
      token:'',
      itemsMenu:[],
      listRemote:[],
    };
  },
  mqtt: {
    "+/+/receive/+/remote"(data, topic) {
      console.log('DATA',String(data));
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        connection = true;
        this.loading=false;
        clearTimeout(timeout);
        this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  created(){
    var item1 = this.$t("message.public.edit");
    var item2 = this.$t("message.public.delete");
    this.itemsMenu.push({title: item1, icon: 'mdi-border-color'}, {title: item2, icon: 'mdi-delete'});
  },
  mounted() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");  
    this.title = 'ریموت';
    let details = {
      title: this.title,
      add:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'remoteAlarm');

    let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.sendStatusRequest(CommandAlarm.getCommandStatusRemote(), '')
      this.onSubscribe();
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SettingAlarmInfo",
          params: {home_id:this.home_id,serial_number: this.serial_number},
        });
      }
      if (value == "add") {
        this.$router.push({
          name: "RemoteAdd",
          params: {home_id:this.home_id,serial_number: this.serial_number},
        });
      }
      if (value == "refresh") {
        this.sendStatusRequest(CommandAlarm.getCommandStatusRemote(), '')
      }
    },
    clickItemMenu(value) {
      let self =this
      switch (value.itemId) {
        case 0:
        // console.log('value.itemId',value.itemId)
          break;
        case 1:
        swal({
        title: 'ریموت ',
        text: '?ایا میخواهید ریموت را حذف کنید',
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#007bff",
        confirmButtonText: this.$t('message.public.delete'),
        cancelButtonText: this.$t('message.public.cancel'),
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        if (isConfirm) {
          var count=value.index
          if(count.length==1){
            count='0'+ count;
          }
          self.sendDelRemote(CommandAlarm.getCommandDelRemote(count), '') 
        }
      });
          break;
      }
    },
    sendDelRemote(saction, password) {
      console.log('saction',saction)
      var self = this;
      
      var topic_final = getTopics.getTopicRemoteSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              // self.openModalLoading();
              // $("#modal-pass-curtain").modal("hide");
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                self.loading=false
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    sendStatusRequest(saction, password) {
      console.log('saction',saction)
      var self = this;
      var topic_final = getTopics.getTopicRemoteSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: saction,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              // self.openModalLoading();
              // $("#modal-pass-curtain").modal("hide");
              console.log('jsonArray',jsonArray)
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();
                self.loading=false
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicRemoteReceive(
          this.serial_number,
          this.subdevice_serial
      );
      this.$mqtt.subscribe(topic1);
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    showGetMsg(msg) {
      if(msg!=null){
        this.parseStatus(msg);
      }
    },
    parseStatus(msg){
      // var msg="SW24h01000+02110+03010";
      var final=BetweenStr.getBetweenStr(msg,'REMOTE','')[1];
      const arr1 = final.split('+');
      let self=this;
      arr1.forEach(function (item) {
        const arr2=item.split('');
        self.listRemote.push({
          index:arr2[1],
          id:arr2[1],
          count:arr2[0]+arr2[1],
          title:"remote"+arr2[1], 
          icon:"mdi-remote",
          actions: 'menu'
        })
       });
       console.log('listRemote',this.listRemote);
    },
  },
};
</script>
<style scoped>
</style>
