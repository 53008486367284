<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <div class="mr-0">
      <!-- Main content -->
      <section class="content">
        <div class="row mt-5 px-20" id="app">
          <div class="col-12">
            <!-- .row device-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16">
                <div class="media align-items-center bg-white py-20 rounded">
                  <img
                    class="avatar text-primary"
                    src="/assets/images/smart/device.svg"
                    width="24px"
                    height="24px"
                    alt="..."
                  />
                  <div class="media-body">
                    <p>
                      <strong>{{ this.$store.getters.getNameDevice }}</strong>
                    </p>
                    <p class="mt-5">{{ serial }}</p>
                  </div>
                  <label class="switch">
                    <input
                      name="checkoutlet"
                      id="checkoutlet"
                      type="checkbox"
                    />
                    <span class="switch-indicator"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- .row lahzeie-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16 py-0">
                <div class="media align-items-center bg-white py-20 rounded">
                  <i class="mdi mdi-timer-sand mdi-24px text-primary"></i>
                  <div class="media-body">
                    <p>
                      <strong>{{
                        $t("message.AppBar.instantSettings")
                      }}</strong>
                    </p>
                    <p></p>
                  </div>
                  <v-btn
                    :loading="loadingInstant"
                    :disabled="loadingInstant"
                    color="bg-primary"
                    fab
                    @click="instant()"
                  >
                    <v-icon class="text-white"> mdi mdi-power </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <!-- .row report-->
            <div class="row">
              <div class="col-lg-12 col-12">
                <!-- Default box -->
                <div class="box rounded-50">
                  <div class="box-header with-border">
                    <h4 class="box-title">
                      {{ $t("message.RelayKey.reports") }}
                    </h4>
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-12">
                        <div class="text-center py-10 bb-1">
                          <h4>{{ $t("message.RelayKey.last_update") }}</h4>
                          <label id="clock" style="font-size: 16px"
                            >00.00.00</label
                          >

                          <div class="row">
                            <div class="col-4">
                              <h6 class="mb-0 text-bold">
                                {{ $t("message.RelayKey.I") }}
                              </h6>
                              <label
                                id="amp"
                                class="p-4"
                                style="font-size: 16px"
                                >0.0</label
                              >
                            </div>
                            <div class="col-4">
                              <h6 class="mb-0 text-bold">
                                {{ $t("message.RelayKey.T") }}
                              </h6>
                              <label
                                id="temp"
                                class="p-4"
                                style="font-size: 16px"
                                >0.0</label
                              >
                            </div>
                            <div class="col-4">
                              <h6 class="mb-0 text-bold">
                                {{ $t("message.RelayKey.V") }}
                              </h6>
                              <label
                                id="volt"
                                class="p-4"
                                style="font-size: 16px"
                                >0.0</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                    <div class="d-flex justify-content-center mt-20">
                  
                      <VueLoadingButton
                        @click.native="ATVC()"
                        class="btn btn-primary mb-5"
                        :loading="isLoading"
                        :style="isStyled"
                      >
                        {{ $t("message.public.update") }}
                      </VueLoadingButton>
                    </div>
                  </div>
                  <!-- /.box-body -->
                </div>
                <!-- /.box -->
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <!-- Modal Laoding-->
    <ModalOutletMomentray
      v-show="isModalVisible"
      v-bind:serials="serial"
      type="outlet"
    ></ModalOutletMomentray>
    <ModalEditOutlet v-bind:data_infos="data_info"></ModalEditOutlet>
    <ModalPassOutlet v-bind:data_infos="data_info"></ModalPassOutlet>
    <!-- ./Modal Pass-->
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <my-modal v-show="isModalVisibles"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>
<script>
import { BetweenStr } from "../../../public/assets/js/utils/util.js";
import ModalOutletMomentray from "./ModalOutletMomentray";
import ModalEditOutlet from "./ModalEditOutlet";
import ModalPassOutlet from "./ModalPassOutlet";
import {
  SucessToastMsg,
  ErrorToastMsg,
  Internets,
  SucessToast,
} from "../../../public/assets/js/utils/util.js";
import { getTopics } from "../../../public/assets/js/utils/topics.js";
import VueLoadingButton from "vue-loading-button";
import { postAjaxCall } from "../../../public/assets/js/postAjax";
import { responseAjax } from "../../../public/assets/js/utils/response_ajax_handler";
import { msgOutlet } from "../../../public/assets/js/utils/msg_handler_outlet";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../Cmp/general/CmpPasscode";
import LayoutDefault from "../../layouts/LayoutDefault";

var connection = false;
var switch_status = "P1OFF#";
var showToast;

export default {
  components: {
    ModalOutletMomentray,
    ModalEditOutlet,
    ModalPassOutlet,
    VueLoadingButton,
    CmpPasscode,
  },
  props: {
    serial_number: {},
    home_id: {},
    click: {},
  },
  data() {
    return {
      title: "",
      set_password: "",
      pass: true,
      isModalVisiblePass: false,
      isShowOutlet: false,
      isModalVisible: false,
      isModalVisibles: false,
      serial: this.serial_number,
      homeid: this.home_id,
      data_info: [this.home_id, this.serial_number],
      passoutlet: "",
      lang: "",
      isLoading: false,
      isStyled: true,
      loadingInstant: false,
      data: [],
    };
  },
  created() {
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoDevice");
    this.title = this.$store.getters.getNameDevice;
    this.lang = localStorage.getItem("Lang");
    this.data = JSON.parse(localStorage.getItem("infoDevice"));
    this.pass = this.data.password === 1;
    this.set_password = this.data.set_password;
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsg(data + "");
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
    "+/+/receive/256/atvc"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        this.showGetMsgATVC(data + "");
      }
    },
  },
  mounted() {
    try {
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;

      // this.btnLoading();
      //------------- GET DATA VUEX
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.$store.dispatch("infoDevice");
      // set_password = this.$store.getters.getSetPassDevice;
      this.data_info.push(this.$store.getters.getNameDevice);
      //------------GET STATUS
      setTimeout(function () {
        self.lastStatusMqtt(token, user_id);
        self.ATVC();
      }, 50);

      var checkbox = document.getElementById("checkoutlet");

      //-----------------------------------  subscribe-----------------------------------
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      var topic2 = getTopics.getTopicAtvcReceive(this.serial_number, "256");

      this.$mqtt.subscribe(topic1);
      this.$mqtt.subscribe(topic2);

      checkbox.addEventListener("change", function () {
        if (checkbox.checked) {
          console.log("checked");
          if (self.set_password == 1) {
            switch_status = "P1ON#";
            $("#modal-pass-code").modal("show");
          } else {
            self.switchAction("P1ON#", "");
          }
        } else {
          console.log("checked");
          if (self.set_password == 1) {
            switch_status = "P1OFF#";
            $("#modal-pass-code").modal("show");
          } else {
            self.switchAction("P1OFF#", "");
          }
        }
      });
      let data = {
        title: this.title,
        refresh: true,
        timer: true,
        menu: true,
        about: true,
        editName: true,
        momentary: true,
        pin: this.pass,
        passcode: this.set_password,
      };
      localStorage.setItem("AppBar", JSON.stringify(data));
      this.$emit("update:layout", LayoutDefault);
      this.$emit("update:update", "RelayKey");
    } catch (err) {
      let self = this;
      swal(
        {
          title: "",
          text: this.$t("message.ListDevice.err_process"),
          type: "error",
          confirmButtonColor: "#dc3545",
          confirmButtonText: this.$t("message.public.OK"),
          closeOnConfirm: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            self.$router.push({ name: "mainpage" });
            swal.close();
          }
        }
      );
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    },
  },
  methods: {
    instant() {
      this.loadingInstant = true;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.MyFunctionPower("");
      }
    },
    sendReq(password) {
      if (this.loadingInstant) this.MyFunctionPower(password);
      else this.switchAction(switch_status, password);
    },
    switchAction(saction, password) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      showToast = 1;
      self.openModalLoading();
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          var jsonArray = JSON.parse(response.responseText);

          setTimeout(function () {
            self.exitModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
              setTimeout(function () {
                var checkbox = document.getElementById("checkoutlet");
                checkbox.checked = !checkbox.checked;
              }, 3000);
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.exitModalLoading();
          self.lastStatusMqtt(token, user_id);
          if (response.status == 401) self.$router.push({ name: "home" });
          //error
        }
      });
    },
    MyFunctionPower(password) {
      //  this.isLoadRefresh = true;
      this.loadingInstant = true;
      let self = this;
      setTimeout(function () {
        // self.isLoadRefresh = false;
        self.loadingInstant = false;
      }, 3000);
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var topic_final;
      let relay_delay = localStorage.getItem(
        "relay_delay" + this.serial_number
      );

      if (relay_delay == null || relay_delay == "") {
        relay_delay = "01";
      }
      let ssaction = "P1:" + relay_delay + "S#";
      var topic_final = getTopics.getTopicDelaySend(this.serial_number, "256");
    
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: ssaction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);
          SucessToast.delay();
        } else {
          //error
        }
      });
    },
    lastStatusMqtt(token, user_id) {
      let self = this;
      //------DATA
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };

      let URL = getUrl.deviceStatus;

      const p = postAjaxCall(URL, data, "");
      self.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.exitModalLoading();
          var checkbox = document.getElementById("checkoutlet");
          var msg_relay = jsonArray.content.message.status;
          if (jsonArray.content.message == "NOTSET") {
            checkbox.checked = true;
          }

          msgOutlet(msg_relay, checkbox);
        } else {
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    showGetMsg(msg) {
      var checkbox = document.getElementById("checkoutlet");
      msgOutlet(msg, checkbox);
    },
    ATVC() {
      this.isLoading = true;
      let self = this;
      setTimeout(function () {
        self.isLoading = false;
      }, 2000);

      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      // var topic_final;
      var topic_final = getTopics.getTopicAtvcSend(this.serial_number, "256");
     
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: "ATVC?",
        client_id: "atv" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;      
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            let message = jsonArray.content.message;
          } else {
            //error
          }
        },
        function (errr) {
          // loading.style.visibility = "hidden";
          let jsonArray = JSON.parse(errr);
          // err.innerHTML = jsonArray.message;
          // EnableItem();
        }
      );
    },
    showGetMsgATVC(msg) {
      var amp = document.getElementById("amp");
      var temp = document.getElementById("temp");
      var volt = document.getElementById("volt");
      var clock = document.getElementById("clock");

      var A = BetweenStr.getBetweenStr(msg, "A=", "~T");
      var T = BetweenStr.getBetweenStr(msg, "~T=", "~V");
      var V = BetweenStr.getBetweenStr(msg, "~V=", "~C");
      var C = BetweenStr.getBetweenStr(msg, "~C", "#");

      amp.textContent = A[1];
      temp.textContent = T[1];
      volt.textContent = V[1];
      clock.textContent = C[1];
    },
    outletPassShow() {
      this.isShowOutlet = true;
    },
    openModalPass() {
      this.isModalVisiblePass = true;
      //  $("#modal-pass").modal("show");
    },
    closeModalPass() {
      this.isModalVisiblePass = false;
      // $("#modal-pass").modal("hide");
    },
    sendPassOutlet() {
      var msg;
      $("#modal-pass-outlet").modal("hide");
      this.switchAction(switch_status, this.passoutlet);
      this.passoutlet = "";
    },
    statusPass() {
      $("#modal-pass-status-outlet").modal("show");
    },
    closePass() {
      var checkbox = document.getElementById("checkoutlet");
      checkbox.checked = !checkbox.checked;
    },
    openModalLoading() {
      this.isModalVisibles = true;
      setTimeout(() => {
        console.log(56);
        this.isModalVisibles = false;
      }, 10000);
    },
    exitModalLoading() {
      this.isModalVisibles = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
      if (value == "momentary") {
        this.isModalVisible = true;
        $("#modal-outlet-momentray").modal("show");
      }
      if (value == "about") {
        this.$router.push({
          name: "aboutGatewayInfo",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
          },
        });
      }
      if (value == "edit") {
        $("#modal-edit-outlet").modal("show");
      }
      if (value == "pass") {
        $("#modal-pass-status-outlet").modal("show");
      }
      if (value == "refresh") {
        var user_id = localStorage.getItem("user_id");
        var token = localStorage.getItem("token");
        let self = this;
        setTimeout(function () {
          self.lastStatusMqtt(token, user_id);
          self.ATVC();
        }, 50);
      }
      if (value == "timer") {
        this.$router.push({
          name: "OutletTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
          },
        });
      }
    },
  },
};
</script>
<style scope>
.v-btn--fab.v-size--default {
  width: 36px !important;
  height: 36px !important;
}
</style>
