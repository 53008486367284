<template>
  <v-app class="bg">
    <div class="mr-0">
      <div>
        <section class="content">
          <div class="row mt-20 px-20" v-if="info.length == 0">
          </div>
          <div v-else>
            <div
                class="row mt-2 px-20"
                v-bind:key="index+'C'"
                v-for="(infos, index) in info"
            >
              <div
                  class="col-12 py-0 mt-5"
                  v-if="
                infos.key_value.split('POWER$').pop().split('-')[0] !== 'OFF'
              "
              >
                <div class="col-12 col-lg-12 p-0">
                  <!-- AREA CHART -->
                  <div class="box">
                    <div class="box-header with-border">
                      <h6 class="box-title">
                        {{ $t('message.irAir.situation') }} {{ infos.key_count }}
                      </h6>
                    </div>
                    <div class="box-body">
                      <ul class="flexbox flex-justified text-center my-10">
                        <li class="px-30" @click="Done(infos.key_count)">
                          <div class="font-size-10 mb-5">
                          <span class="font-size-60"
                          >{{
                              infos.key_value
                                  .split("TEMP$")
                                  .pop()
                                  .split("-SWING")[0]
                            }}°C</span
                          >
                          </div>
                        </li>
                        <li class="br-1">
                          <div
                              class="font-size-18 text-danger"
                              v-if="
                            infos.key_value.split('MODE$').pop().split('-')[0] ==
                            'HEAT'
                          "
                          >
                            <i class="mdi mdi-weather-sunny mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-orange"
                              v-if="
                            infos.key_value.split('MODE$').pop().split('-')[0] ==
                            'DRY'
                          "
                          >
                            <i class="mdi mdi-water mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                            infos.key_value.split('MODE$').pop().split('-')[0] ==
                            'COOL'
                          "
                          >
                            <i class="mdi mdi-snowflake mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                            infos.key_value.split('MODE$').pop().split('-')[0] ==
                            'AUTO'
                          "
                          >
                            <i class="mdi mdi-autorenew mdi-18px px-3"></i
                            ><label class="font-size-14">{{
                              infos.key_value.split("MODE$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18"
                              v-if="
                            infos.key_value.split('FAN$').pop().split('-')[0] ==
                            'HIGH'
                          "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-fast-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">{{
                              infos.key_value.split("FAN$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                            infos.key_value.split('FAN$').pop().split('-')[0] ==
                            'LOW'
                          "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-slow-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">{{
                              infos.key_value.split("FAN$").pop().split("-")[0]
                            }}</label>
                          </div>
                          <div
                              class="font-size-18 text-primary"
                              v-if="
                            infos.key_value.split('FAN$').pop().split('-')[0] ==
                            'MEDUIM'
                          "
                          >
                            <img
                                src="/assets/images/smart/icon-fan-meduim-on.svg"
                                class="mr-2"
                                style="width: 16px; height: 16px"
                            /><label class="font-size-14">MED</label>
                          </div>
                          <div
                              class="font-size-18 text-success"
                              v-if="
                            infos.key_value
                              .split('SWING$V')
                              .pop()
                              .split('-')[0] == 'ON'
                          "
                          >
                            <i class="mdi mdi-swap-vertical mdi-18px"></i
                            ><label class="font-size-14"> Swing </label>
                          </div>
                          <div
                              class="font-size-18 text-success"
                              v-if="
                            infos.key_value
                              .split('SWING$H')
                              .pop()
                              .split('-')[0] == 'ON'
                          "
                          >
                            <i class="mdi mdi-swap-horizontal mdi-18px"></i
                            ><label class="font-size-14">Swing</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <!-- /.box-body -->
                  </div>
                  <!-- /.box -->
                </div>
              </div>
            </div>
            <div
                class="row px-20"
                :key="index+'A'"
                v-for="(infos, index) in info"
            >
              <div
                  class="col-12"
                  v-if="infos.key_value.split('POWER$').pop().split('-')[0] == 'OFF'"
              >
                <!-- .row device-->

                <div class="col-12 col-lg-12 p-0">
                  <!-- AREA CHART -->
                  <div class="box">
                    <div class="box-header with-border">
                      <h6 class="box-title">
                        {{ $t('message.irAir.scenario') }} {{ infos.key_count }}
                      </h6>
                    </div>
                    <div class="box-body">
                      <ul class="flexbox flex-justified text-center my-10">
                        <li class="px-30" @click="Done(infos.key_count)">
                          <!-- <p class="mb-0">Traffic</p> -->
                          <div class="font-size-20 mb-5">
                          <span class="font-size-60">{{
                              infos.key_value.split("POWER$").pop().split("-")[0]
                            }}</span>
                          </div>
                        </li>
                        <li class="br-1">
                          <i
                              class="mdi mdi-power mdi-36px text-danger"
                              style="font-size: 120px"
                          >
                          </i>
                        </li>
                      </ul>
                    </div>
                    <!-- /.box-body -->
                  </div>
                  <!-- /.box -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- /.content -->
    </div>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import CmpEmptyData from "../../../Cmp/public/CmpEmptyData.vue";
import {BetweenStr, ErrorToastMsg, SucessToastMsg, ToastCustom} from "../../../../../public/assets/js/utils/util";
import {getCommand} from "../../../../../public/assets/js/utils/get_ir_gatway_command";
import {getTopics} from "../../../../../public/assets/js/utils/topics";
import {postAjaxCall} from "../../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../../layouts/LayoutDefault";

var connection;
var timer;

export default {
  components: {
    CmpEmptyData,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {}
  },
  data() {
    return {
      info: "",
      title: "",
      msgFan: "",
      json: [
        {
          control_count: "",
          key_count: "",
        },
      ],
      data_info: [this.serial_number, this.count, this.home_id],
      learned: "",
      list_air_keys: [],
      found_key: false,
      checkadd: true,
      tabs: null,
      cmpShow: false,
      key: '',
      showToast: 1,
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == "256") {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timer);
          this.exitModalLoading();
          this.showGetMsg(String(data));
        }
      }
    },
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    let data = {
      title: this.$t('message.timer.scheduler'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Timer2.5');
  },
  mounted() {
    let self = this;
    this.$store.dispatch("infoHome");
    this.$store.dispatch("infoIrControls");
    // this.title = this.$store.getters.getNameIrControl;
    //Subscribe
    this.onSubScribe();

    setTimeout(function () {
      self.lastStatus();

    }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    getAirControlCmpCallback(msg) {
      this.checkAirList(msg);
    },
    getDelKeyCmpCallback(msg) {
      if (msg == 'deleteKey') {
        let self = this;

        var message = getCommand.getCommandClearKey(this.count, this.key);
       var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
     
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          // err.innerHTML = "دستور با موفقیت ارسال شد";
          setTimeout(function () {
            if (!connection) {
              swal("", self.$t('message.public.operation_failure') , "error");
            }
          }, 10000);
        },
        function (errr) {
          loading.style.visibility = "hidden";
          err.innerHTML = JSON.parse(errr).message;
        }
      );
  }
    },
    checkAirList(msg) {
      let self = this;
      this.list_air_keys.forEach(function (item) {
        if (item.name == msg) {
          self.found_key = true;
          self.SendReguestTest(item.code);
        }
      });
      if (!this.found_key) {
        swal({
          title: this.$t('message.irAir.send'),
          text: this.$t('message.irAir.notfound'),
          type: "warning",
          confirmButtonColor: "#dc3545",
        });
      }
    },
    SendReguestTest(code) {
      let self = this;
      this.onSubScribe();
      var message = "IRTST" + "-" + code + "-END";
      var topic_final = getTopics.getTopicActionSend(this.serial_number, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: this.user_id,
        token: this.token,
        topic: topic_final,
        message: message,
        // password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequestIR;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      this.showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          connection = false;
          var jsonArray = JSON.parse(response.responseText);
          self.found_key = false;
          self.exitModalLoading();
          // self.emptyMsg();
          timer = setTimeout(function () {
            // self.closeModal();
            if (!connection) {
              if (this.showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            this.showToast = 0;
            connection = false;
          }, 5000);
        } else {
          //error
        }
      });
    },
    Done(value) {
      var message = "CONTROL" + this.count + "-" + "KEY" + value;
      localStorage.setItem("code", message);
      this.$router.push({
        name: 'IRTimerStep2',
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          count: this.count,
          type_code: 1001
        }
      });
      // ----------------------Data------------------//
    },
    lastStatus() {
      let self = this;
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        control_uid: uid,
      };
      let URL = getUrl.controlInfo;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.exitModalLoading();
          var control = jsonArray.content.control[0]
          self.title = control.name
          self.info = jsonArray.content.control[0].key_info;
          self.learned = jsonArray.content.control[0].learned;
          self.cmpShow = true;

          var key_info = jsonArray.content.control[0].key_info;
          var key_list_info = jsonArray.content.control[0].key_list_info;
          if (key_list_info !== 0) {
            self.list_air_keys = key_list_info;
          }
          key_info.forEach(function (item) {
            self.json.push({
              control_count: item.control_count,
              key_count: item.key_count,
            });
            // var message = item.key_value;
            // self.msgFan = message.includes("FAN$HIGH");
            // if (message.includes("FAN$HIGH")) {
            //   self.msgFan = "FAN$HIGH";
            // }
            // if (message.includes("FAN$LOW")) {
            //   self.msgFan = "FAN$LOW";
            // }
            // if (message.includes("FAN$MEDUIM")) {
            //   self.msgFan = "FAN$MEDUIM";
            // }
          });
        } else {
          self.exitModalLoading();

          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showGetMsg(msg) {
      let self = this;
      var done = msg.includes("DONE");
      var clear = msg.includes("CLEAROK")
      var del = msg.includes("DELETEOK");
      var msg1 = BetweenStr.getBetweenStr(msg, "CONTROL", "-KEY");

      if (done) {
        if (msg1[1] == this.count) {
          // the instruction is true
          if (this.showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
      if (del) {
        SucessToastMsg.Msg();
        this.$router.replace({
          name: "irGatewaynone",
          params: {serial_number: this.serial_number},
        });
      }
      if (clear) {
        // the instruction is true
        if (this.showToast == 1) {
          swal({
            title: this.$t('message.irAir.settings'),
            text: this.$t('message.irAir.delete'),
            type: "success",
            timer: 2000,
            showConfirmButton: false,
          });
          this.lastStatus();
          setTimeout(function () {
            $("#modal-del-key").modal("hide");
          }, 2005);
        }
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onSubScribe() {
      var topic1 = getTopics.getTopicActionReceive(this.serial_number, "256");
      this.$mqtt.subscribe(topic1);
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "IRTimerList",
          params: {serial_number: this.serial_number, home_id: this.home_id},
        });
      }
    },
    getNameCmpCallback(msg) {
      let self = this
      let uid = this.$store.getters.getUIDIrControl;
      var data = {
        user_id: this.user_id,
        token: this.token,
        name: msg,
        control_uid: uid,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.controlEdit;
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              $("#modal-edit-name").modal("hide");
              self.lastStatus();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
  },
};
</script>
<style scoped>
span {
  size: 12 !important;
}

.scroll {
  overflow: scroll !important;
}
</style>
