<template>
  <div
      class="modal modal-fill fade"
      data-backdrop="false"
      id="modal-del-sub"
      tabindex="-1"
  >
    <div class="wrapper m-0">
      <AppBar v-bind:title="title" @clicked="onClickChild"></AppBar>
      <!-- <CmpPasscode @sendPassCode="sendReq"></CmpPasscode> -->
      <section class="error-page bg-img mt-50 rtl">
        <div class="container  px-40">
          <div class="row  align-items-center justify-content-center text-center ">
            <div class="col-lg-7 col-md-10 col-12">
              <h1 class="text-warning font-size-180 font-weight-bold error-page-title"></h1>
              <img src="/assets/images/smart/vector-delete.svg" class="avatar-smart">
              <br>
              <h4 class="text-danger mt-10"><strong>{{ $t('message.public.warning') }}</strong></h4>
              <br>
              <h6 class="mt-10" style=" line-height:25px !important;">
                <strong>{{ $t('message.subDevice.del_Device') }}</strong></h6>
              <v-btn
                  class="btn btn-danger bg-danger mb-5 mt-10 m-5"
                  :loading="loading"
                  :disabled="loading"
                  color="bg-primary"
                  @click="delFunction()"
              >
                <span class="text-white"> {{ $t('message.public.delete') }} </span>
              </v-btn>
              <v-btn
                  class="btn btn-dark bg-dark mb-5 mt-10 m-5"
                  @click="cancelFunction()"
              >
                <span class="text-white"> {{ $t('message.public.cancel') }} </span>
              </v-btn>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
        class="modal center-modal fade"
        id="modal-center"
        tabindex="-1"
        data-keyboard="false"
        data-backdrop="static"
    >
      <div class="modal-dialog p-20">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="txtloading">{{ $t('message.ModalCenter.loading') }}</h5>
          </div>
          <div class="modal-body">
            <div class="spinner-border textprimary" role="status">
              <span class="sr-only">{{ $t('message.ModalCenter.loading') }}</span>
            </div>
            <span class="pl-20" id="txtwait">{{ $t('message.ModalCenter.wait') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//--------------------------------------  variable --------------------------------------//
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "@/components/Cmp/general/CmpPasscode";

import AppBar from "../AppBar.vue";

export default {
  components: {
    AppBar,
    CmpPasscode
  },
  data() {
    return {
      title: '',
      loading: false,
      set_password: 0,
    };
  },
  props: {
    data_info_sub: {type: Array},
  },
  created() {
    this.title = this.$t('message.subDevice.delete')
    let details = {
      title: this.title
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
   
  },
  mounted() {
    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.set_password= this.info.set_password;

  },
  methods: {
    onClickChild(value) {
      if (value == 'back') {
        $("#modal-del-sub").modal('hide');
      }
    },
    cancelFunction() {
      $("#modal-del-sub").modal('hide');
    },
    delFunction() {
      if (this.set_password == 1) {
               $("#modal-del-sub").modal("hide");
               $("#modal-pass-code").modal("show");
             } else {
              this.sendRequest('');
            }  
    },
    sendReq(password) {
      console.log("sendReqPass in del",password)

      this.openModalLoading();
      this.sendRequest(password);
    },
     sendRequest(password){
      let self = this;
      //--------------------------------------  variable --------------------------------------//
      let user_id = localStorage.getItem("user_id")
      let token = localStorage.getItem("token")
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      var home_id = this.data_info_sub[0];
      var serial_number = this.data_info_sub[1];
      this.loading = true;
      //--------------------------------------  data --------------------------------------//

      var data = {
        user_id: user_id,
        token: token,
        password:password,
        serial_number: subdevice_serial

      }
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.subDelete;
      const p = postAjaxCall(URL, data);
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              // let jsonArray = JSON.parse(response.responseText);
              self.loading = false
              console.log("del then")
              self.$router.push({name: "homeDevices", params: {home_id: home_id, serial_number: serial_number}});
              // location.reload();
            } else {
              //error
              self.loading = false
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
     }
  },
};
</script>


<style scoped>
</style>
