<template>
  <v-app class="bg">
    <section class="content">
      <div class="col-12 py-0">
        <div class="mt-20 text-center">
          <img
              src="/assets/images/smart/vector-login.svg"
              style="width: 200px; height: 200px"
              alt="login"
          />
          <h5 class="mt-10 font-weight-bold" id="txtmobilelogin">
            {{ $t('message.login.txt1') }}
          </h5>
          <p id="txtcodelogin">
            {{ $t('message.login.txt2') }}
          </p>
        </div>
      </div>
      <!-- col -->
      <div class="col-12">
        <div class="box">
          <div class="box-body">
            <form>
              <h3 class="mb-20 font-weight-bold" id="txtlogin">
                {{ $t('message.public.title') }}
              </h3>
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-email-outline"></i
                          ></span>
                  </div>
                  <input
                      id="mobile"
                      type="number"
                      class="form-control pl-15"
                      placeholder="**********09"
                      autocomplete="off"
                      onKeyPress="if(this.value.length==11) return false;"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                      type="button"
                      class="btn btn-info btn-block"
                      @click="myFunction"
                      id="btnsubmit"
                  >
                    {{ $t('message.public.submit') }}
                  </button>
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- /.col -->
      <!-- col -->
      <div class="col-12 mt-5 py-0">
        <div class="alert alert-secondary p-0 m-0">
          <div class="media">
            <i class="mdi mdi-alert-circle-outline mdi-18px"></i>
            <div class="media-body">
              <label id="lblaccountlogin">
                {{ $t('message.login.tips') }} </label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="px-20 mt-10 text-center">
        <label>{{ $t('message.privacy.login') }}
          <u class="text-primary" @click="privacyPlicy">{{ $t('message.privacy.privacy') }} </u>
          {{ $t('message.privacy.accept') }}</label>
      </div>
      <!-- /.col -->
    </section>
    <ModalPrivacy></ModalPrivacy>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import ModalPrivacy from "@/components/Modal/ModalPrivacy";
import {MobileValidationInput} from "../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";

export default {
  components: {
    ModalPrivacy
  },
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    showModal: false,
    isModalVisible: false
  }),
  created() {
    let data = {
      title: this.$t('message.loginVerify.title'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Login');
  },
  mounted() {
    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    myFunction() {
      let self = this;
      let mobile = document.getElementById("mobile").value;
      if (!MobileValidationInput(mobile)) return;
      localStorage.setItem("mobile_number", mobile);

      var data = {
        mobile: mobile,
      };
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.login;
      this.openModal();
      // eslint-disable-next-line no-undef
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            // eslint-disable-next-line no-undef
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.closeModal();
              self.$router.replace({
                name: "loginverify",
                params: {
                  id: jsonArray.content.verify_id,
                  new_user: jsonArray.content.new_user,
                },
              });
            } else {
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    privacyPlicy() {
      $("#modal-privacy").modal("show");
    },
    openEventCourse: function () {
      this.$router.replace({name: "loginverify", params: {id: id}});
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({name: "welcome"})
      }
    },
  },
};
</script>


<style scoped>
</style>
