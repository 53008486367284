<template>
  <v-app class="bg">
    <div class="mt-5">
      <section class="content">
        <div class="media-list media-list-divided media-list-hover">
          <div class="media align-items-center setting">
            <i class="mdi mdi-barcode mdi-24px"></i>
            <div class="media-body">
              <h6>{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</h6>
              <h6 class="mt-4">{{deviceID}}</h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  </v-app>
  <!-- <v-app class="bg">
    <v-card class="mt-2">
    <v-list subheader two-line>
      <v-list-item>
        <v-icon color="primary">mdi mdi-barcode mdi-24px</v-icon>
        <v-list-item-content class="mt-2 px-5">
          <v-list-item-title>
            <label class="text-bold">{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</label>
          </v-list-item-title>
          <v-list-item-subtitle>
            <label class="text-regular text-primary" style="font-size: 14px;">
              {{deviceID}}
              </label>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <v-icon color="primary">mdi mdi-barcode mdi-24px</v-icon>
        <v-list-item-content class="mt-2 px-5">
          <v-list-item-title>
            <label class="text-bold">{{ $t('message.ModalAddSubDeviceFaraPol.serial') }}</label>
          </v-list-item-title>
          <v-list-item-subtitle>
            <label class="text-regular text-primary" style="font-size: 14px;">
              {{deviceID}}
              </label
            >
          </v-list-item-subtitle>
  
        </v-list-item-content>

      </v-list-item>
    </v-list>
  </v-card> -->
</template>

<script>

import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  props: {
    serial_number: {},
    home_id: {},
    count: {},
    click: {}
  },
  data() {
    return {
      homeid: this.home_id,
      serialnumber: this.serial_number,
      title: "",
      deviceID:''
      
    };
  },
  mounted() {
    this.$store.dispatch("InfoSubDevice");
    this.title = this.$t('message.AppBar.about_device');
    let details = {
      title: this.title,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'AboutShutter');
     this.deviceID = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number

  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "SubDoorShutter",
          params: {
            serial_number: this.serial_number,
            home_id: this.home_id,
            count: this.count,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
</style>
