<template>
  <v-app class="bg">
      <CmpThermostatTemp v-if="cmpShow" @callThermostatTempRefresh="ThermostatTempRefresh" :serial_number="serial_number"
      :home_id="home_id" :temp_val="temp_val" :temp_home="temp_home" :subdevice_serial="subdevice_serial">
    </CmpThermostatTemp>
    <CmpThermostat v-if="cmpShow" :status="statusThermostat" @clicked="getThermostatCmpCallback" :temp_home="temp_home">
    </CmpThermostat>
    <div class="row rounded-16 px-5 fixed-bottom">
      <div class="col-12 col-lg-12  py-20">
        <v-btn class="btn btn-info btn-block" color="bg-primary" :loading="loading" :disabled="loading"
          @click="sendDataThermostat()">
          <span class="text-white"> {{ $t('message.public.send') }}</span>
        </v-btn>
      </div>
    </div>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <ModalResetFactoryThermostat :infoItem="dataReset"></ModalResetFactoryThermostat>
    <SubModalDel v-bind:data_info_sub="data_info_thermostat_pro"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_thermostat_pro"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_thermostat_pro"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import { ErrorToastMsg, SucessToastMsg } from "@/../public/assets/js/utils/util";
import { getTopics } from "@/../public/assets/js/utils/topics";
import { postAjaxCall } from "@/../public/assets/js/postAjax";
import { responseAjax } from "@/../public/assets/js/utils/response_ajax_handler";
import { Color, hexToRgb } from "@/../public/assets/js/utils/colorRGB";
import { Solver } from "@/../public/assets/js/utils/colorRGB";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import { Internets } from "@/../public/assets/js/utils/util.js";
import CmpThermostatTemp from "@/components/Cmp/sub/CmpThermostatTemp";
import CmpThermostat from "@/components/Cmp/sub/CmpThermostat";
import ModalResetFactoryThermostat from "../../subdevice/thermostatpro/ModalResetFactoryThermostat";
import { passHomeValidationInput } from "@/../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";
import { BetweenStr } from "../../../../public/assets/js/utils/util.js";


var showToast;
var connection = false;
var timeout;

export default {
  // name: "KeyTouchpro",
  components: {
    CmpThermostatTemp,
    CmpThermostat,
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
    ModalResetFactoryThermostat
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      cmpShow: false,
      statusThermostat: {},

      type_Selected_item: "action",
      data_info_thermostat_pro: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      isModalVisible: false,
      subdevice_serial: "",
      thermostat_status: "",
      pass: 0,
      data: [],
      info: [],
      rgbInfo: [],
      dataReset: [],
      set_password: 0,

      // data_info: [this.serial_number, this.count],
      // passthermostat: "",
      temp_val: 25,
      loading: false,
      msgFinalSend: '',
      // data: {},
      temp_home: 16,


      data_info: [this.serial_number, this.count],
      passthermostat: "",
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] === this.serial_number) {
        connection = true;
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
        clearTimeout(timeout);
        this.showGetMsgBroadAction(String(data));
        if (String(data).startsWith("TOFF")) {
          this.parseMsgTOFF(String(data));
        }
        if (String(data).startsWith("TON")) {
          this.parseMsgTON(String(data));
        }
        this.closeModalLoading();
      }
    },
    // "+/+/receive/+/setting"(data, topic) {
    //   if (topic.split("/")[1] === this.serial_number) {
    //     connection = true;
    //     clearTimeout(timeout);
    //     this.closeModalLoading();
    //     this.showGetMsg(String(data))
    //   }
    // },
    // "+/+/receive/+/seting"(data, topic) {
    //   if (topic.split("/")[1] === this.serial_number) {
    //     connection = true;
    //     clearTimeout(timeout);
    //     this.closeModalLoading();
    //     this.showGetMsg(String(data))
    //   }
    // },
  },
  created() {
    let self = this;
    let data = localStorage.getItem("infoSubDevice");
    data = JSON.parse(data);



    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = data.set_password;
  },
  mounted: function mounted() {
    Internets.Internet;
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.main_admin = this.$store.getters.getadmin;
    this.title = this.$store.getters.getNameSub;


    this.info = localStorage.getItem("infoSubDevice") ? JSON.parse(localStorage.getItem("infoSubDevice")) : [];
    this.subdevice_serial = this.info.serial_number;
    this.dataReset.push({ serial_number: this.serial_number, subdevice_serial: this.subdevice_serial })
    // init run
    // try {

    this.lastStatus();
    // subscribe
    this.onSubscribe();
    //  this.ThermostatTempRefresh()


    // }
    //  catch
    //     (err) {
    //   let self = this;
    //   swal(
    //       {
    //         title: "",
    //         text: this.$t('message.ListDevice.err_process'),
    //         type: "error",
    //         confirmButtonColor: "#DD6B55",
    //         confirmButtonText: this.$t('message.public.OK'),
    //         closeOnConfirm: false,
    //       },
    //       function (isConfirm) {
    //         if (isConfirm) {
    //           self.$router.push({name: "mainpage"});
    //           swal.close();
    //         }
    //       }
    //   );
    // }
    let details = {
      title: this.title,
      sub_setting:true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubThermostatPro');
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  methods: {
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
        this.serial_number,
        this.subdevice_serial
      );
      // var topic2 = getTopics.getTopicSetingReceive(
      //   this.serial_number,
      //   this.subdevice_serial
      // );
      // var topic3 = getTopics.getTopicSettingReceive(
      //   this.serial_number,
      //   this.subdevice_serial
      // );
      this.$mqtt.subscribe(topic1);
      // this.$mqtt.subscribe(topic2);
      // this.$mqtt.subscribe(topic3);
    },

    lastStatus() {

      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      let data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Request------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Response----------------//
      p.then(
        function (response) {
          self.closeModalLoading();
          let jsonArray = JSON.parse(response.responseText);
          self.cmpShow = true;
          if (responseAjax(response)) {
            let message = jsonArray.content.message;
            self.statusThermostat = message
            self.msgFinalSend = message;
            // ****** code save data in subdevice
            self.sub_device_info.push({
              subDevices: jsonArray.content.device_info
            })
            localStorage.setItem("infoSubDevice", JSON.stringify(self.sub_device_info[0].subDevices))
            // ****** code save data in subdevice

            if (message == "NOTSET") {
              self.msgFinalSend = message;
            } else {
              self.temp_home = BetweenStr.getBetweenStr(String(jsonArray.content.message), "TP", "-")[1];
            }
            if (message.startsWith("TOFF")) {
              self.parseMsgTOFF(message);
            }
            if (message.startsWith("TON")) {
              self.parseMsgTON(message);
            }
          } else {
            if (response.status == 401) {
              if (jsonArray.code == "4001") {
                self.$router.push({ name: "home" });
              }
            }
          }
        },
        //--------------------------------------Err --------------------------------------//
        function () {
          // console.log("err", errr);
        }
      );
    },
    parseMsgTON(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    parseMsgTOFF(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    getThermostatCmpCallback(msg) {
      this.msgFinalSend = msg;
    },
    sendDataThermostat() {
      this.type_Selected_item = "action"
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.msgFinalSend, '')
      }
    },
    showGetMsgBroadCast(msg) {
      this.temp_val = msg.slice(5, 7);
      localStorage.setItem("primeryTemp", this.temp_val);

    },
    showGetMsgBroadAction(msg) {
      this.temp_home = BetweenStr.getBetweenStr(String(msg), "TP", "-")[1];
      if (this.temp_home >= 16) {
        localStorage.setItem("tempHome", this.temp_home)
      } else {
        localStorage.setItem("tempHome", 329)
      }

    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: { home_id: this.home_id, serial_number: this.serial_number },
        });
      }
      if(value== "sub_setting"){
        this.$router.push({
          name: "ThermostatProSetting",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      // if (value == "update") {
      //   this.showUpdateDialog();
      // }
      if (value == "refresh") {
        this.lastStatus();
        // location.reload();
      }
    },
    ThermostatTempRefresh() {
      this.sendMessageTemp()
    },
    sendMessageTemp() {
      var topic_finalTemp;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var topic_finalTemp = getTopics.getTopicBroadcastSend(
        this.serial_number,
        this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_finalTemp,
        message: "TEMP?",
        client_id: "TEM" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      // console.log(topic_finalTemp);
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            let message = jsonArray.content.message;
          } else {
            //error
          }
        },
        function (errr) {
          let jsonArray = JSON.parse(errr);
        }
      );

    },
    sendMessage(status) {

      this.thermostat_status = status;
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(this.thermostat_status, '');
      }
    },
    sendReq(password) {
      this.sendRequest(this.msgFinalSend, password);
    },
    sendRequest(message, password) {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let topic_final = "";


      this.type_Selected_item == 'update' ?
        topic_final = getTopics.getTopicBroadcastSend(this.serial_number, this.subdevice_serial) :
        topic_final = getTopics.getTopicActionSend(this.serial_number, this.subdevice_serial);
      // ----------------------Data------------------//
      let data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: message,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        let jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          timeout = setTimeout(function () {
            self.closeModalLoading();
            if (!connection) {
              setTimeout(function () {
                self.lastStatus();
              }, 1000);
              if (showToast === 1) {
                ErrorToastMsg.Msg();
                self.lastStatus();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.closeModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({ name: "home" });
            }
          }
        }
      });
    },
    // showUpdateDialog() {
    //   this.$swal({
    //     title: this.$t("message.public.refresh"),
    //     text: "آیا میخواهید به روز رسانی  کنید؟",
    //     type: "info",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: this.$t("message.public.refresh"),
    //     cancelButtonText: this.$t("message.public.cancel"),
    //   }).then(
    //     (result) => {
    //       if (result) {
    //         this.onSubscribe();
    //         if (this.set_password == 1) {
    //           $("#modal-pass-code").modal("show");
    //           this.msgFinalSend = "UPDATE#"
    //           this.type_Selected_item = "update";
    //         } else {
    //           this.type_Selected_item = "update"
    //           this.sendRequest('UPDATE#', '');
    //         }
    //       }
    //     },
    //     (dismiss) => {
    //       if (dismiss === "cancel") {
    //       } else {
    //         throw dismiss;
    //       }
    //     }
    //   );
    // },
    showGetMsg(msg) {
      let self = this;
      // this.loading=false
      if (msg == "UPDATEOK") {
        swal({
          // title: self.$t('message.timer.timer'),
          title: this.$t("message.public.refresh"),
          text: this.$t("message.public.update_done"),
          type: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      }
      if (msg == "RESETOK") {
        //   swal({
        //   // title: self.$t('message.timer.timer'),
        //   title:this.$t("message.public.refresh"),
        //   text: this.$t("message.public.update_done"),
        //   type: "success",
        //   timer: 3000,
        //   showConfirmButton: false,
        // });
      }
      else {
        this.temp_val = msg.slice(5, 7);
        localStorage.setItem("primeryTemp", this.temp_val);
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
  },
}
</script>

<style scoped></style>