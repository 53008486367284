
var baseUrl=process.env.VUE_APP_BASE_URL

// console.log('process.env.VUE_APP_BASE_URL',process.env.VUE_APP_BASE_URL)

export const getUrl = {
    // BASEURL:'https://server.pfas.ir/api/',
    SCENARIOKEYADD:baseUrl+'user/home/scenario/key/edit',
    mqttRequestIR:baseUrl+'user/rsay/app/ir/request/send',
    mqttRequest:baseUrl+'user/rsay/app/request/send',
    subDeviceStatus:baseUrl+'user/sub/device/status',
    timerCount:baseUrl+'user/device/timer/count',
    timers:baseUrl+'user/device/timers',
    types:baseUrl+'user/device/types',
    noneTypes:baseUrl+'user/none/home/device/types',
    roomInfo:baseUrl+'user/home/room/info',


    homeInfo:baseUrl+'user/home/info',
    homeAdd:baseUrl+'user/home/add',

    scenarios:baseUrl+'user/home/scenarios',
    scenarioDevices:baseUrl+'user/home/scenario/devices',
    scenarioDel:baseUrl+'user/home/scenario/delete',
    scenarioEdit:baseUrl+'user/home/scenario/edit',
    scenarioLogo:baseUrl+'user/home/scenario/logos',
    scenarioAdd:baseUrl+'user/home/scenario/add',
    

    login:baseUrl+'user/login',
    loginEmail:baseUrl+'user/login/email',
    registerEmail:baseUrl+'user/register/email',
    registerEmailVerify:baseUrl+'user/register/email/verify',
    forgotPassEmail:baseUrl+'user/forgot/password',
    forgotPassEmailVerify:baseUrl+'user/forgot/password/verify',
    
    loginVerify:baseUrl+'user/verify',
    info:baseUrl+'user/info',
    subDelete:baseUrl+'user/home/sub/device/delete',
    subAdd:baseUrl+'user/home/sub/device/add',
    subEdit:baseUrl+'user/home/sub/device/edit',
    subStatus:baseUrl+'user/sub/device/status',
    passChange:baseUrl+'user/home/sub/device/password/change',
    passStatus:baseUrl+'user/home/password/change/status',
    passSet:baseUrl+'user/home/password/set',
    modelChange:baseUrl+'user/home/sub/device/model/change',
    subAlarmCount:baseUrl+'home/sub/device/alarm/counts',
    relayItems:baseUrl+'user/home/relay/items',
    relays:baseUrl+'user/home/relays',
    relayEdit:baseUrl+'user/home/relay/items/edit',
    relayType:baseUrl+'user/relay/types',
    volt:baseUrl+'user/device/volt',
    temp:baseUrl+'user/device/temp',
    amp:baseUrl+'user/device/amp',
    deviceReport:baseUrl+'user/device/report',
    homeEdit:baseUrl+'user/home/edit',
    homeDel:baseUrl+'user/home/delete',
    homePassChange:baseUrl+'user/home/password/change',
    clinetDel:baseUrl+'user/home/client/delete/client',
    clinetDel1:baseUrl+'user/home/client/delete',
    clientDelVerify:baseUrl+'user/home/client/delete/client/verify',
    clientDelVerify1:baseUrl+'user/home/client/delete/verify',
    clientVerify:baseUrl+'user/home/client/client/verify',
    clientSet:baseUrl+'user/home/client/client',
    adminVerify:baseUrl+'user/home/client/admin/verify',
    adminSet:baseUrl+'user/home/client/admin',
    clients:baseUrl+'user/gadget/clients',
    clientGadgetVerify:baseUrl+'user/gadget/client/unregister/verify',
    clientGadgetDel:baseUrl+'user/gadget/client/unregister',
    clientGadgetRegisterVerify:baseUrl+'user/gadget/client/register/verify',
    clientGadgetRegister:baseUrl+'user/gadget/client/register',
    clientIndex:baseUrl+'user/home/client/index',
    clientAddVerify:baseUrl+'user/home/client/add/verify',
    clientAdd:baseUrl+'user/home/client/add',

    rooms:baseUrl+'user/home/rooms',
    roomsDel:baseUrl+'user/home/room/delete',
    roomsDevices:baseUrl+'user/home/room/devices',
    roomsUpdate:baseUrl+'user/home/room/update',
    roomsAdd:baseUrl+'user/home/room/add',

    profile:baseUrl+'user/profile',
    profileEdit:baseUrl+'user/profile/edit',
    profilePasswordChange:baseUrl+'user/profile/password/change',
    profileEmailChange:baseUrl+'user/profile/email/change',
    profileEmailChangeVerify:baseUrl+'user/profile/email/change/verify',

    deviceEdit:baseUrl+'user/device/edit',
    deviceStatus:baseUrl+'user/device/status',

    noneDeviceAdd:baseUrl+'user/none/home/device/add',
    noneDeviceEdit:baseUrl+'user/none/home/device/edit',
    nonemqttRequest:baseUrl+'user/rsay/none/app/request/send',

    nonevolt:baseUrl+'user/none/home/device/volt',
    noneTemp:baseUrl+'user/none/home/device/temp',
    noneAmp:baseUrl+'user/none/home/device/amp',
    noneStatus:baseUrl+'user/none/home/device/status',
    noneTimer:baseUrl+'user/none/home/device/timer',
    noneTimerCount:baseUrl+'user/none/home/device/timer/count',
   
    noneDeviceDel:baseUrl+'user/none/home/device/delete',
    noneRelayItems:baseUrl+'user/none/home/relay/items',
    noneRelayItemsEdit:baseUrl+'user/none/home/relay/items/edit',

    noneSetPin:baseUrl+'user/none/home/password/set',
    noneStatusPin:baseUrl+'user/none/home/password/change/status',
    noneChangePin:baseUrl+'user/none/home/password/change',

    
   
    
   controlAdd:baseUrl+'user/device/control/add',
   controlTypes:baseUrl+'user/device/control/types',
   controlInfo:baseUrl+'user/device/control/info',
   controlsInfo:baseUrl+'user/device/controls/info',
   controlBrand:baseUrl+'user/device/control/brand',
   controlEdit:baseUrl+'user/device/control/edit',
   controlBrandTest:baseUrl+'user/device/control/brand/test',
   controlCount:baseUrl+'user/device/control/count',

   reportHistory:baseUrl+'user/device/ir/report/history'
  };
  
  

  
  
  
  