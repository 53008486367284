import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//to handle state
const state = {
    // posts: []
    InfoListSubDevice: '',
    InfoListIrControls: '',
    InfoHome: '',
    InfoUser: '',
    infoListDevice: '',
    statusTimervalue: '',
    InfoNoneHome: '',
    InfoIrSenario: '',
    language: '',
    InfoIrStatus: '',
    infoRelay8oLocal: '',
    infoScenarioKeyLocal:'',
    infoScenarioLocal: '',
    //----info ir subdevice senario save
    InfoIrSubScenario: '',
    //---info ir all scenario save
    infoIrScenario: '',
    //---flag for edit or and in scenario
    flagEditScenario: '',
}
//to handle state
const getters = {
    //------------SubDevice
    getInfoSub: state => {
        return state.InfoListSubDevice
    },
    getSetPasswordSub: state => {
        return state.InfoListSubDevice.set_password
    },
    getNameSub: state => {
        return state.InfoListSubDevice.name
    },
    getPolSub: state => {
        return state.InfoListSubDevice.pol
    },
    getSerialSub: state => {
        return state.InfoListSubDevice.serial
    },
    getPassword: state => {
        return state.InfoListSubDevice.password
    },
    getModel: state => {
        return state.InfoListSubDevice.model
    },
    //-------------IR
    getNameIrControl: state => {
        return state.InfoListIrControls.name
    },
    getUIDIrControl: state => {
        return state.InfoListIrControls.uid
    },
    getKeysControl: state => {
        return state.InfoListIrControls.keys
    },
    getIrControl: state => {
        return state.InfoListIrControls
    },
    //-------------Info Home
    getadmin: state => {
        return state.InfoHome.admin
    },
    getNameHome: state => {
        return state.InfoHome.name
    },
    getPassDeviceFromHome() {
        return state.InfoHome.password;
    },
    getTypeGatway() {
        return state.InfoHome.type_code;
    },
    //-------------Info User
    getUserPhone: state => {
        return state.InfoUser.mobile
    },
    getFnameUser: state => {
        return state.InfoUser.fname
    },
    getLnameUser: state => {
        return state.InfoUser.lname
    },
    getAddressUser: state => {
        return state.InfoUser.address
    },
    //-------------Info Device
    getPassDevice() {
        return state.infoListDevice.password;
    },
    getSetPassDevice() {
        return state.infoListDevice.set_password;
    },
    getNameDevice() {
        return state.infoListDevice.name;
    },
    getTypeCodeDevice() {
        return state.infoListDevice.type_code;
    },
//-------------Info Timer
    getTimerStatus() {
        return state.statusTimervalue;
    },
//-------------Info None Home
    getNameNoneHome() {
        return state.InfoNoneHome.name;
    },
//-------------Info IR Status
    getKeyValStatus() {
        return state.InfoIrStatus.key_value;
    },
//-------------Info IR Scenario
    getScenario() {
        return state.infoIrScenario;
    },
//-------------Info Flag IR Scenario
    getFlag() {
        return state.flagEditScenario;
    },
//-------------Info IR Scenario actions & name & icon in local list
    getRelay8Local() {
        return state.infoRelay8oLocal;
    },
 //-------------Info IR ScenarioKey actions & name & icon number & in local list
    getScenarioKeyLocal() {
        console.log("getScenarioKeyLocal",state.infoScenarioKeyLocal)
        return state.infoScenarioKeyLocal;
    },
//-------------Info IR Scenario actions & name & icon in local list
    getScenarioLocal() {
        return state.infoScenarioLocal;
    },
//-------------Info IR Senario subdecive
    getIrSubScenario() {
        return state.InfoIrSubScenario;
    },
    getLanguage() {
        return state.language;
    }
}
//to handle actions
const actions = {
    InfoSubDevice: (context) => {
        context.commit('putSubDeviceInfo', JSON.parse(localStorage.getItem("infoSubDevice")))
    },
    infoIrControls: (context) => {
        context.commit('putIrControlsInfo', JSON.parse(localStorage.getItem("infoIrControls")))
    },
    infoHome: (context) => {
        context.commit('putHomeInfo', JSON.parse(localStorage.getItem("infoHome")))
    },
    infoUser: (context) => {
        context.commit('putUserInfo', JSON.parse(localStorage.getItem("infoUser")))
    },
    infoNoneHome: (context) => {
        context.commit('putNoneHomeInfo', JSON.parse(localStorage.getItem("infoNoneHome")))
    },
    infoDevice: (context) => {
        context.commit('putDeviceInfo', JSON.parse(localStorage.getItem("infoDevice")))
    },
    statusTimer: (context) => {
        context.commit('putTimerStatus', JSON.parse(localStorage.getItem("statusTimer")))
    },
    infoIrStatus: (context) => {
        context.commit('putIrStatusInfo', JSON.parse(localStorage.getItem("infoIrStatus")))
    },
    infoIrScenario: (context) => {
        context.commit('putIrScenarioInfo', JSON.parse(localStorage.getItem("infoIrScenario")))
    },
    flagEditScenario: (context) => {
        context.commit('putflagEditScenario', localStorage.getItem("flagEditScenario"))
    },
    infoRelay8oLocal: (context) => {
        context.commit('putIrRelay8LocalInfo', JSON.parse(localStorage.getItem("infoRelay8oLocal")))
    },
    infoScenarioKeyLocal: (context) => {
        console.log('infoScenarioKeyLocal', JSON.parse(localStorage.getItem("infoScenarioKeyLocal")))
        context.commit('putScenarioKeyLocalInfo', JSON.parse(localStorage.getItem("infoScenarioKeyLocal")))
    },
    infoScenarioLocal: (context) => {
        context.commit('putIrScenarioLocalInfo', JSON.parse(localStorage.getItem("infoScenarioLocal")))
    },
    InfoIrSubScenario: (context) => {
        context.commit('putIrSubScenarioInfo', JSON.parse(localStorage.getItem("InfoIrSubScenario")))
    },
}

const mutations = {
    putSubDeviceInfo(state, data) {
        state.InfoListSubDevice = data;
    },
    putIrControlsInfo(state, data) {
        state.InfoListIrControls = data;
    },
    putHomeInfo(state, data) {
        state.InfoHome = data;
    },
    putUserInfo(state, data) {
        state.InfoUser = data;
    },
    putDeviceInfo(state, data) {
        state.infoListDevice = data;
    },
    putTimerStatus(state, data) {
        state.statusTimervalue = data;
    },
    putNoneHomeInfo(state, data) {
        state.InfoNoneHome = data;
    },
    putIrStatusInfo(state, data) {
        state.InfoIrStatus = data;
    },
    putIrScenarioInfo(state, data) {
        state.infoIrScenario = data;
    },
    putflagEditScenario(state, data) {
        state.flagEditScenario = data;
    },
    putIrRelay8LocalInfo(state, data) {
        state.infoRelay8oLocal = data;
    },
    putScenarioKeyLocalInfo(state, data) {
        state.infoScenarioKeyLocal = data;
    },
    putIrScenarioLocalInfo(state, data) {
        state.infoScenarioLocal = data;
    },
    putIrSubScenarioInfo(state, data) {
        state.InfoIrSubScenario = data;
    },
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
