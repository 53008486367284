import { render, staticRenderFns } from "./ElevatorProStep2Timer.vue?vue&type=template&id=73daaf97&scoped=true"
import script from "./ElevatorProStep2Timer.vue?vue&type=script&lang=js"
export * from "./ElevatorProStep2Timer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73daaf97",
  null
  
)

export default component.exports