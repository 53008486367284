<template>
  <v-app class="bg">
      <CmpThermostatTemp v-if="cmpShow" @callThermostatTempRefresh="ThermostatTempRefresh"
                    :serial_number="serial_number" :home_id="home_id" :temp_val="temp_val" :temp_home="temp_home"  :subdevice_serial="subdevice_serial"
                   ></CmpThermostatTemp>
    <CmpThermostat  :status="statusThermostat"  @clicked="getThermostatCmpCallback" :temp_home="temp_home"></CmpThermostat>
    <div class="row rounded-16 px-15 fixed-bottom">
      <div class="col-12 col-lg-12 px-15 py-20">
        <v-btn
            class="btn btn-info btn-block"
            color="bg-primary"
            :loading="loading"
            :disabled="loading"
            @click="sendDataThermostat()">
          <span class="text-white"> {{ $t('message.public.send') }}</span>
        </v-btn>
      </div>
    </div>
    <!-- ./Modal Pass-->
    <div
        class="modal center-modal fade"
        id="modal-pass-thermostat"
        tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"> {{ $t('message.public.security') }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6> {{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
              <input
                  type="number"
                  class="form-control"
                  required=""
                  pattern="[0-9]"
                  aria-invalid="false"
                  maxlength="4"
                  autocomplete="off"
                  style="-webkit-text-security: disc; margin-top: 8px"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  id="idpassther"
                  :placeholder="$t('message.ModalAddHome.password_hint')"
                  v-model="passthermostat"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassThermostat()"
                >
                  {{ $t('message.public.send') }}
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <SubModalDel v-bind:data_info_sub="data_info_thermostat"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_thermostat"></SubModalEdit>
    <SubModalSetPass
        v-bind:data_info_sub="data_info_thermostat"
    ></SubModalSetPass>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {Internets} from "../../../../public/assets/js/utils/util.js";
import {
  SucessToastMsg,
  ErrorToastMsg,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpThermostat from "@/components/Cmp/sub/CmpThermostat";
import CmpThermostatTemp from "@/components/Cmp/sub/CmpThermostatTemp";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var timer;
var thermostat_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpThermostat,
    CmpThermostatTemp,
    CmpPasscode,
  },
  props: {
    serial_number: {},
    count: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      data_info: [this.serial_number, this.count],
      passthermostat: "",
      data_info_thermostat: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      set_password: "",
       temp_val:25,
      pass: true,
      title: "",
      loading: false,
      cmpShow: false,
      subdevice_serial:"",
      statusThermostat: {},
      msgFinalSend: '',
      data: {},
      isModalVisible: false,
      temp_home:16 
      };
  },
  mqtt: {

    "+/+/receive/+/action"(data, topic) {
      this.showGetMsgBroadAction(data + "");
      if (
        topic.split("/")[1] == this.serial_number &&
        topic.split("/")[3] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      ) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
          if (String(data).startsWith("TOFF")) {
            this.parseMsgTOFF(String(data));
          }
          if (String(data).startsWith("TON")) {
            this.parseMsgTON(String(data));
          }
        }
      }
    },
    "+/+/receive/+/setting"(data, topic) {
      if (topic.split("/")[1] == this.serial_number) {
        if (topic.split("/")[4] === "setting") {
          connection = true;
          this.showGetMsgBroadCast(data + "");
        }
      }
    },
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  mounted() {
    try {
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      setTimeout(function () {
        self.lastStaus();
      }, 50);

      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
      this.subdevice_serial= subdevice_serial
      //------subscribe

      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          subdevice_serial
      );

      this.$mqtt.subscribe(topic1);
      this.ThermostatTempRefresh()
      this.onSubscribe();
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true,
      refresh: true,
      timer: true,
      about: true,
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubThetmostatFara');
  },
  methods: {
    sendMessage(msg, pass) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final;     
      this.passthermostat = pass;
      var subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: msg,
        password: this.passthermostat,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.openModalLoading();
          $("#modal-pass-thermostat").modal("hide");
          timeout = setTimeout(function () {
            self.exitModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          self.exitModalLoading();
          if (response.status == 401) {
            if (jsonArray.code == "4001") {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
   ThermostatTempRefresh() {
    this.sendMessageTemp()
  },
    sendMessageTemp(){
  var topic_finalTemp;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var topic_finalTemp = getTopics.getTopicBroadcastSend(
        this.serial_number,
        this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_finalTemp,
        message: "TEMP?",
        client_id: "TEM" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
        function (response) {
          if (responseAjax(response)) {
            let jsonArray = JSON.parse(response.responseText);
            let message = jsonArray.content.message;
          } else {
            //error
          }
        },
        function (errr) {
          let jsonArray = JSON.parse(errr);
        }
      );

    },
      onSubscribe() {
      var topic2 = getTopics.getTopicBroadcastReceive(
        this.serial_number,
       this.subdevice_serial
      );
      this.$mqtt.subscribe(topic2);
    },
   showGetMsgBroadCast(msg) {
      this.temp_val = msg.slice(5, 7);
     localStorage.setItem("primeryTemp",this.temp_val);
 
    },
     showGetMsgBroadAction(msg) {
  this.temp_home = BetweenStr.getBetweenStr(String(msg), "TP", "-")[1];
  if(this.temp_home >= 16){
    localStorage.setItem("tempHome", this.temp_home)
  }else{
    localStorage.setItem("tempHome", 329)
  }
 
    },  
    lastStaus() {
     
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subDeviceStatus;
      const p = postAjaxCall(URL, data);

      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            self.exitModalLoading();
            self.cmpShow = true;
            if (responseAjax(response)) {
              self.temp_home =BetweenStr.getBetweenStr(String(jsonArray.content.message), "TP", "-")[1];
              let message = jsonArray.content.message;
             
              self.statusThermostat = message
              self.msgFinalSend = message;
              if (message == "NOTSET") {
                self.msgFinalSend = message;
              }
              if (message.startsWith("TOFF")) {
                self.parseMsgTOFF(message);
              }
              if (message.startsWith("TON")) {
                self.parseMsgTON(message);
              }
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
      if (value == "refresh") {
        // location.reload();
        this.lastStaus();
      }
      if (value == "timer") {
        this.$router.push({
          name: "ThermostatTimerShow",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "about") {
        this.$router.push({
          name: "aboutthermostat",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
    },
    sendPassThermostat() {
      if (!passHomeValidationInput(this.passthermostat)) return;
      this.sendMessage(thermostat_status, this.passthermostat);
      this.passthermostat = "";
    },
    parseMsgTON(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    parseMsgTOFF(message) {
      this.statusThermostat = message
      this.msgFinalSend = message;
    },
    getThermostatCmpCallback(msg) {
      this.msgFinalSend = msg;
    },
    sendDataThermostat() {
      if (this.set_password == 1) {
        $("#modal-pass-code").modal("show");
      } else {
        this.sendMessage(this.msgFinalSend, '')
      }
    },
    sendReq(password) {
      this.sendMessage(this.msgFinalSend, password);
    },
    subTimerShow() {
      this.$router.push({
        name: "ThermostatTimerShow",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          count: this.count,
        },
      });
    },
  },
};
</script>
<style scoped>
</style>
