import i18n from "../../../../src/i18n";

export const getMessageScenario = {
    getRelayStatus: function (message, version) {
        //   var str='S0146848484848484GhjE01';

        var str = message;
        var status = '';

        if (message.match("S01")) {
            if (str.substr(str.indexOf(message.match("val")) + 3, 1) == 'F') {
                status = i18n.t('message.public.off')
            }
            if (str.substr(str.indexOf(message.match("val")) + 3, 1) == 'D') {
                status = str.match(new RegExp('D' + "(.*)" + 'delay'))[1]
            }
            if (str.substr(str.indexOf(message.match("val")) + 3, 1) == '0') {
                status = i18n.t('message.subDevice.unlock')
            }
            if (str.substr(str.indexOf(message.match("val")) + 3, 1) == '1') {
                status = i18n.t('message.public.close')
            }
            if (str.substr(str.indexOf(message.match("val")) + 3, 1) == 'T') {
                status = i18n.t('message.public.on')
            }
            if (str.substr(str.indexOf(message.match("val")) + 3, 2) == 'FF') {
                status = i18n.t('message.public.off')
            }
        }
        var str = str.substring(str.indexOf('S01') + 3, str.indexOf("E01")) + ':' + status;
        return str
    },
    getCurtainStatus: function (message, version) {
        //   var str='S0146848484848484GhjE01';
        var status = '';
        if (version == 'V1') {
            if (message.substr(message.indexOf("K") + 2, 1) == 'T') {
                if (message.substr(message.indexOf("K") + 1, 1) == 1) {
                    status = i18n.t('message.public.open')
                }
                if (message.substr(message.indexOf("K") + 1, 1) == 2) {
                    status = i18n.t('message.public.stop')
                }
                if (message.substr(message.indexOf("K") + 1, 1) == 3) {
                    status = i18n.t('message.public.close')
                }
            }

        }
        return i18n.t('message.relay.type.curtain') + Array(1).fill('\xa0').join('') + ':' + status;
    },
    getCoolerStatus: function (message, version) {
        let status = '';
        let name = '';
        if (message.substr(message.indexOf("K") + 2, 1) == 'T') {
            status = i18n.t('message.public.on');
        } else {
            status = i18n.t('message.public.off');
        }
        switch (message.substr(message.indexOf("K") + 1, 1)) {
            case '1':
                name = i18n.t('message.subDevice.cooler_pomp');
                break;
            case '2':
                name = i18n.t('message.subDevice.cooler_low');
                break;
            case '3':
                name = i18n.t('message.subDevice.cooler_fast');
                break;
            case '5':
                name = i18n.t('message.public.all');
                break;
            default:
            // code block
        }
        return name + Array(1).fill('\xa0').join('') + ':' + status;
    },
    getWcStatus: function (message, version) {
        let status = '';
        let name = '';
        if (message.substr(message.indexOf("K") + 2, 1) == 'T') {
            status = i18n.t('message.public.on');
        } else {
            status = i18n.t('message.public.off');
        }
        switch (message.substr(message.indexOf("K") + 1, 1)) {
            case '1':
                name = i18n.t('message.touch_key.key');
                break;
            case '2':
                name = i18n.t('message.touch_key.fan');
                break;
            case '3':
                name = i18n.t('message.touch_key.spray');
                break;
            case '5':
                name = i18n.t('message.touch_key.music');
                break;
            default:
            // code block
        }
        return name + Array(1).fill('\xa0').join('') + ':' + status;
    },
}
  
  
  
