<template>
  <v-app class="bg">
    <div class="px-4 mb-5">
      <div :key="index + 'A'" v-for="(control, index) in controls">
        <CmpRowList
            :infoItem="control"
            :menuShow="false"
            :subTitleShow="true"
            @clickedRow="getIndexRow"
        ></CmpRowList> 
      </div>
      <div :key="index" v-for="(subDevice, index) in subDevices">
        <CmpRowList
            :infoItem="subDevice"
            :menuShow="false"
            :subTitleShow="true"
            @clickedRow="getIndexRowSub"
        ></CmpRowList>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>
<script>
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import {postAjaxCall} from "../../../public/assets/js/postAjax.js";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";

export default {
  components: {CmpRowList},
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      ServerInfoSub: [],
      ServerInfoControls: [],
      subdevice_serial: "",
      isModalVisible: false,
      subDevices: [],
      controls: [],
      relays: [],
      subtitleControl: "",
      subtitleSub: "",
    };
  },
  created() {
    let self = this;
    setTimeout(function () {
      self.getListSenarioDevices();
    }, 50);
    let details = {
      title: this.$t('message.ListDevice.list_device'),
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SenarioDevices');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(val) {
      if (val === 'back'){
      this.$router.push({
        name: "ScenarioAddList",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
      }
    },
    getIndexRow(index) {
      const dataircontrols = {
        name: this.ServerInfoControls[index].name,
        uid: this.ServerInfoControls[index].uid,
        keys: this.ServerInfoControls[index].keys,
        type_code: this.ServerInfoControls[index].new_control.category.type_code,
        path_logo: this.ServerInfoControls[index].new_control.category.logo_path,
      };
      localStorage.setItem("infoIrControls", JSON.stringify(dataircontrols));
      console.log(dataircontrols);
      this.$router.push({
        name: "GetControlStr",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          typecode: this.ServerInfoControls[index].new_control.category.type_code,
          count: this.ServerInfoControls[index].count,
          uid: this.ServerInfoControls[index].uid,
        },
      });
    },
    getIndexRowSub(index) {
      if (this.ServerInfoSub[index].type.type_code == 15) {
        this.relays = this.ServerInfoSub[index].relays

      }
       console.log("label",this.ServerInfoSub)
      var infosub = {
        labels:this.ServerInfoSub[index].labels? JSON.parse(this.ServerInfoSub[index].labels) : "",
        name: this.ServerInfoSub[index].name,
        type_code: this.ServerInfoSub[index].type.type_code,
        pol: this.ServerInfoSub[index].pol,
        path_logo: this.ServerInfoSub[index].type.logo_path,
        relays: this.relays
      };

      localStorage.setItem("InfoIrSubScenario", JSON.stringify(infosub));

      this.$router.push({
        name: "GetSubDeviceStr",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          subdevice_serial: this.ServerInfoSub[index].serial_number,
        },
      });
    },
    getListSenarioDevices() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.scenarioDevices;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.closeModalLoading();
              self.ServerInfoSub = jsonArray.content.subDevices;
              self.ServerInfoControls = jsonArray.content.gateway.controls;
              self.ServerInfoControls.forEach(function (item, i) {
                if (item.new_control.category.type_code == "1001")
                  self.subtitleControl = self.$t("message.irTv.tv_control");
                if (item.new_control.category.type_code == "1002")
                  self.subtitleControl = self.$t("message.irAmplifier.amplifier_control");
                if (item.new_control.category.type_code == "1003")
                  self.subtitleControl = self.$t("message.irAir.air_control");
                self.controls.push({
                  index: i,
                  title: item.name,
                  img: item.new_control.category.logo_path,
                  subTitle: self.subtitleControl,
                  icon: false,
                });
              });

              self.ServerInfoSub.forEach(function (item, i) {
                if (item.type.type_code == "1")
                  self.subtitleSub = self.$t("message.subDevice.keytouch.keytouch");
                if (item.type.type_code == "10")
                  self.subtitleSub = self.$t("message.subDevice.thermostat.thermostat");
                  if (item.type.type_code == "33")
                  self.subtitleSub = self.$t("message.subDevice.thermostat.thermostat") + " " + self.$t("message.public.pro");
                if (item.type.type_code == "15")
                  self.subtitleSub = self.$t("message.relay.relay_device");
                if (item.type.type_code == "17")
                  self.subtitleSub = self.$t("message.relay.type.air_conditioner") + " " + self.$t("message.public.TwoWay");
                if (item.type.type_code == "18")
                  self.subtitleSub = self.$t("message.relay.type.curtain") + " " + self.$t("message.public.TwoWay");
                  if (item.type.type_code == "32")
                  self.subtitleSub = self.$t("message.relay.type.curtain") + " " + self.$t("message.public.pro");
                  if (item.type.type_code == "31")
                  self.subtitleSub = self.$t("message.relay.type.air_conditioner")  + " " + self.$t("message.public.pro");
                  if (item.type.type_code == "30")
                  self.subtitleSub = self.$t("message.subDevice.keytouch.keytouch")  + " " + self.$t("message.public.pro");
                  if (item.type.type_code == "30")
                  self.subtitleSub = self.$t("message.subDevice.keytouch.keytouch")  + " " + self.$t("message.public.pro");
                  if (item.type.type_code == "34")
                  self.subtitleSub = self.$t("message.relay.type.elevator")  + " " + self.$t("message.public.pro");
                  if (item.type.type_code == "20")
                  self.subtitleSub = self.$t("message.relay.type.elevator");
                  if (item.type.type_code == "21")
                  self.subtitleSub = self.$t("message.subDevice.service");
                  if (item.type.type_code == "36")
                  self.subtitleSub = self.$t("message.subDevice.service")+" " + self.$t("message.public.pro");
                  if (item.type.type_code == "38")
                  self.subtitleSub = self.$t("message.subDevice.thermostatic.thermostatic") +" " + self.$t("message.public.pro");
                self.subDevices.push({
                  index: i,
                  title: item.name,
                  img: item.type.logo_path,
                  subTitle: self.subtitleSub,
                  icon: false,
                });
              });
            } else {

              //error
              self.closeModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
  },
};
</script>