<template>
  <!-- .row -->
  <div class="row px-2 mt-1">
    <div class="col-12 col-lg-12 ">
      <div class="box" :style="[mode==='Learn' ? {'background-color': '#e2e3e5'} : {'background-color': '#FFF'}]">
        <div class="box-body  py-40 px-0" :style="[mode==='Learn' ? {'background-color': '#e2e3e5'} : {'background-color': '#FFF'}]">
          <div class="text-center">
            <!-- ----row1 -->
            <ul class="flexbox flex-justified text-center">
              <!-- <li class="px-2"></li> -->
              <li class="px-2">
                 <button
                    v-if="listactive[1].active == true"
                    @click="sendData(listactive[1].code)"
                    type="button"
                    class="btn btn-circle btn-outline-primary btn-lg mt-1 mb-1"
                  >
                    <i class="mdi mdi-volume-off mdi-24px"></i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-circle btn-default btn-lg mt-1 mb-1"
                      @click="sendData(listactive[1].code)"
                  >
                    <i class="mdi mdi-volume-off mdi-24px text-gray"></i>
                  </button>
              </li>
              <li class="px-2">
                 <button
                    v-if="listactive[2].active == true"
                    @click="sendData(listactive[2].code)"
                    type="button"
                    class="btn btn-circle btn-outline-primary btn-lg mb-1"
                  >
                    menu
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-circle btn-default btn-lg mb-1"
                     @click="sendData(listactive[2].code)"
                  >
                    menu
                  </button></li>
              <li class="px-2">
                <button
                  v-if="listactive[0].active == true"
                  type="button"
                  class="btn btn-circle btn-outline-primary btn-lg mb-1"
                  @click="sendData(listactive[0].code)"
                >
                  <i class="mdi mdi-power mdi-24px"></i>
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-circle btn-default btn-lg mb-1"
                   @click="sendData(listactive[0].code)"
                >
                  <i class="mdi mdi-power mdi-24px text-gray"></i>
                </button>
              </li>
            </ul>
            <!-- ----./row1 -->
          </div>
          <!-- ----row2 -->
          <div class="text-center">
            <ul class="flexbox flex-justified text-center my-10">
              <li class="px-5 py-20 mt-10">
                <div
                  style="
                    border-radius: 60px;
                    border: 1px #adadad solid;
                    width: 65px;
                    margin: auto;
                  "
                >
                  <button
                    v-if="listactive[3].active == true"
                    @click="sendData(listactive[3].code)"
                    type="button"
                    class="btn btn-circle btn-outline-primary btn-lg mt-1 mb-1"
                  >
                    <i class="mdi mdi-plus mdi-24px"></i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-circle btn-default btn-lg mb-1"
                    @click="sendData(listactive[3].code)"
                  >
                    <i class="mdi mdi-plus mdi-24px"></i>
                  </button>
                  <div class="clearfix">VOL</div>
                  <button
                    v-if="listactive[4].active == true"
                    @click="sendData(listactive[4].code)"
                    type="button"
                    class="btn btn-circle btn-outline-primary btn-lg"
                  >
                    <i class="mdi mdi-minus mdi-24px"></i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-circle btn-default btn-lg"
                    @click="sendData(listactive[4].code)"
                  >
                    <i class="mdi mdi-minus mdi-24px"></i>
                  </button>
                </div>
              </li>
              <li class="">
                <div class="row" style="margin: auto">
                  <span class="dot mt-10">
                    <div>
                      <div>
                        <div class="text-center">
                          <ul class="flexbox flex-justified text-center mt-1">
                            <li>
                              <button
                                v-if="listactive[6].active == true"
                                @click="sendData(listactive[6].code)"
                                type="button"
                                class="
                                  btn btn-circle btn-outline-primary btn-lg
                                  mb-1
                                "
                                style="border: 0"
                              >
                                <i
                                  class="ti-angle-up"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-circle btn-default btn-lg mb-1"
                                style="border: 0"
                                @click="sendData(listactive[6].code)"
                              >
                                <i
                                  class="ti-angle-up"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                            </li>
                          </ul>
                          <ul class="flexbox flex-justified text-center">
                            <li>
                              <button
                                v-if="listactive[9].active == true"
                                @click="sendData(listactive[9].code)"
                                type="button"
                                class="
                                  btn btn-circle btn-outline-primary btn-lg
                                  mb-1
                                "
                                style="border: 0"
                              >
                                <i
                                  class="ti-angle-right"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-circle btn-default btn-lg mb-1"
                                style="border: 0"
                                @click="sendData(listactive[9].code)"
                              >
                                <i
                                  class="ti-angle-right"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                            </li>
                            <li>
                              <button
                                v-if="listactive[5].active == true"
                                @click="sendData(listactive[5].code)"
                                type="button"
                                class="
                                  btn btn-circle btn-outline-primary btn-lg
                                  mb-1
                                "
                              >
                                OK
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-circle btn-default btn-lg mb-1"
                                 @click="sendData(listactive[5].code)"
                              >
                                OK
                              </button>
                            </li>
                            <li>
                              <button
                                v-if="listactive[8].active == true"
                                @click="sendData(listactive[8].code)"
                                type="button"
                                class="
                                  btn btn-circle btn-outline-primary btn-lg
                                  mb-1
                                "
                                style="border: 0"
                              >
                                <i
                                  class="ti-angle-left"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-circle btn-default btn-lg mb-1"
                                style="border: 0"
                               @click="sendData(listactive[8].code)"
                              >
                                <i
                                  class="ti-angle-left"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                            </li>
                          </ul>
                          <ul class="flexbox flex-justified text-center">
                            <li>
                              <button
                                v-if="listactive[7].active == true"
                                @click="sendData(listactive[7].code)"
                                type="button"
                                class="
                                  btn btn-circle btn-outline-primary btn-lg
                                  mb-1
                                "
                                style="border: 0"
                              >
                                <i
                                  class="ti-angle-down"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-circle btn-default btn-lg mb-1"
                                style="border: 0"
                                @click="sendData(listactive[7].code)"
                              >
                                <i
                                  class="ti-angle-down"
                                  style="font-size: 24px"
                                ></i>
                              </button>
                            </li>
                          </ul>
                          <ul class="flexbox flex-justified text-center"></ul>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </li> 
            </ul>
          </div>
          <ul class="flexbox flex-justified text-center my-10">
            <li>
              <button
                v-if="listactive[12].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[12].code)"
              >
                <i class="mdi mdi-pause mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
                @click="sendData(listactive[12].code)"
              >
                <i class="mdi mdi-pause mdi-24px text-gray"></i>
              </button>
            </li>
            <li>
              <button
                v-if="listactive[11].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[11].code)"
              >
                <i class="mdi mdi-play mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
                @click="sendData(listactive[11].code)"
              >
                <i class="mdi mdi-play mdi-24px text-gray"></i>
              </button>
            </li>
            <li>
              <button
                v-if="listactive[10].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[10].code)"
              >
                <i class="mdi mdi-keyboard-return mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
               @click="sendData(listactive[10].code)"
              >
                <i class="mdi mdi-keyboard-return mdi-24px text-gray"></i>
              </button>
            </li>
          </ul>
          <!-- ----./row3 -->
          <!-- ----row4 -->
          <ul class="flexbox flex-justified text-center my-10">
            <li>
              <button
                v-if="listactive[15].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[15].code)"
              >
                <i class="mdi mdi-step-forward mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
                @click="sendData(listactive[15].code)"
              >
                <i class="mdi mdi-step-forward mdi-24px text-gray"></i>
              </button>
            </li>
            <li>
              <button
                v-if="listactive[14].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[14].code)"
              >
                <i class="mdi mdi-stop mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
                @click="sendData(listactive[14].code)"
              >
                <i class="mdi mdi-stop mdi-24px text-gray"></i>
              </button>
            </li>
            <li>
              <button
                v-if="listactive[13].active == true"
                type="button"
                class="btn btn-circle btn-outline-primary btn-lg mb-1"
                @click="sendData(listactive[13].code)"
              >
                <i class="mdi mdi-step-backward mdi-24px"></i>
              </button>
              <button
                v-else
                type="button"
                class="btn btn-circle btn-default btn-lg mb-1"
                 @click="sendData(listactive[13].code)"
              >
                <i class="mdi mdi-step-backward mdi-24px text-gray"></i>
              </button>
            </li>
          </ul>
          <!-- ----./row4 -->
        </div>
      </div>
    </div>
    <Modal v-show="isModalVisible" @close="closeModal"> </Modal>
  </div>
  <!-- /.row -->
</template>

 <script>

import { ToastCustom } from "../../../public/assets/js/utils/util.js";
import Modal from "../Modal/Modal.vue";
export default {
  components: {
    Modal,
  },
  props: {

    data_list: { type: Array },
    mode: String,
  },
  data() {
    return {
      listactive: [],
      isModalVisible:false,
    };
  },

  created() {
    this.listactive = this.data_list;
  },
  mounted() {},
  methods: {
   unActive() {
      ToastCustom.custom(this.$t('message.irAmplifier.config'), "#fd7e14");
    },
   sendData(value) {
       this.$emit("clicked",value);
    },
   showModal() {
      this.isModalVisible = true;
    },
   closeModal() {
      this.isModalVisible = false;
    },
  },
};
</script>
<style scoped>
.dot {
  height: 198px;
  width: 198px;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: inline-block;
  border-color: #007bff;
  border: 1px solid #adadad;
  margin: auto;
}
</style>
