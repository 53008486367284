<template>
  <v-app class="bg">
    <section class="content">
      <div class="col-12 py-0">
        <div class="mt-20 text-center">
          <img
              src="/assets/images/smart/vector-login.svg"
              style="width: 200px; height: 200px"
              alt="login"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="box">
          <div class="box-body">
            <form>
              <h3 class="mb-20 font-weight-bold" id="txtlogin">
                {{ $t('message.public.title') }}
              </h3>
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-email-outline"></i
                          ></span>
                  </div>
                  <input
                      class="form-control pl-15"
                      :placeholder="$t('message.loginEmail.inputEmail')"
                      autocomplete="off"
                      v-model="email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                          <span class="input-group-text bg-info border-info"
                          ><i class="mdi mdi-lock-outline"></i
                          ></span>
                  </div>
                  <input
                      type="number"
                      class="form-control pl-15"
                      :placeholder="$t('message.loginEmail.inputPassword')"
                      autocomplete="off"
                      onKeyPress="if(this.value.length==11) return false;"
                      v-model="password"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-center">
                  <button
                      type="button"
                      class="btn btn-info btn-block"
                      @click="loginEmailFunction"
                      id="btnsubmit"
                  >
                    {{ $t('message.public.log_in') }}
                  </button>
                  <label class="text-blue mt-5" @click="forgotPassEmailFunction">{{ $t('message.loginEmail.forgot.forgot_password') }}</label>
                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5 py-0">
        <div class="alert alert-secondary p-0 m-0">
          <div class="media">
            <i class="mdi mdi-alert-circle-outline mdi-18px"></i>
            <div class="media-body">
              <label>
                {{ $t('message.loginEmail.creatAccount') }}<label class="text-blue" @click="creatAccountFunction()"> {{ $t('message.loginEmail.account') }} </label> {{ $t('message.loginEmail.createtxt2') }} </label>
            
            </div>
          </div>
        </div>
      </div>
      <div class="px-20 mt-10 text-center">
        <label>{{ $t('message.privacy.login') }}
          <u class="text-primary" @click="privacyPlicy">{{ $t('message.privacy.privacy') }} </u>
          {{ $t('message.privacy.accept') }}</label>
      </div>
      <!-- /.col -->
    </section>
    <ModalPrivacy></ModalPrivacy>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import ModalPrivacy from "@/components/Modal/ModalPrivacy";
import {EmailValidationInput,passEmailValidationInput} from "../../public/assets/js/utils/error_handler";
import {postAjaxCall} from "../../public/assets/js/postAjax";
import {responseAjax} from "../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../layouts/LayoutDefault";

export default {
  components: {
    ModalPrivacy
  },
  props: {
    click: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    showModal: false,
    isModalVisible: false,
    email:"",
    password:"",
  }),
  created() {
    let data = {
      title: this.$t('message.welcome.header'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'Login');
  },
  mounted() {
    if (localStorage.getItem("log") === 0) {
      console.log = function () {
      };
    }
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    loginEmailFunction() {
      let self = this;
      if (!EmailValidationInput(this.email) || 
         !passEmailValidationInput(this.password)) return;
      // localStorage.setItem("mobile_number", mobile);

      var data = {
        email: this.email,
        password:this.password
      };

      // console.log('data',data)
      //--------------------------------------Request --------------------------------------//
      let URL = getUrl.loginEmail;
      this.openModal();
      // eslint-disable-next-line no-undef
      const p = postAjaxCall(URL, data, "");
      //--------------------------------------Response --------------------------------------//
      p.then(
          function (response) {
            // eslint-disable-next-line no-undef
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.closeModal();
              let dataparse = jsonArray.content.user;
              dataparse.forEach(function (item) {
                localStorage.setItem("user_id", item.user_id);
                localStorage.setItem("token", item.token);
              });
              localStorage.setItem("login_id", 1);
              self.$router.replace({name: "mainpage"});
            } else {
              self.closeModal();
              //error
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    forgotPassEmailFunction(){
      this.$router.replace({name: "forgotPasswordEmail"});
    },
    creatAccountFunction(){
      this.$router.push({
        name: "registerEmail",
      });
    },
    privacyPlicy() {
      $("#modal-privacy").modal("show");
    },
    openEventCourse: function () {
      this.$router.replace({name: "loginverify", params: {id: id}});
    },
    openModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onClickChild(val) {
      if (val === 'back') {
        this.$router.push({name: "welcome"})
      }
    },
  },
};
</script>


<style scoped>
</style>
