<template>
  <v-app class="bg">
    <div class="mr-0">
      <div class="m-10">

        <v-col md="6" cols="12" class="py-0">
          <div class="box mt-20">
            <div class="box-header with-border">
              <h5 class="box-title">
                <i class="mdi mdi-thermometer mdi-24px"></i>
                {{ $t('message.report.chart') }} {{ $t('message.RelayKey.T') }}
              </h5>

              <ul class="box-controls pull-right">
                <li><a class="box-btn-slide" href="#"></a></li>
              </ul>
            </div>
            <div class="box-body">
              <div class="chart">
                <iframe
                    class="chartjs-hidden-iframe"
                    style="
                      width: 100%;
                      display: block;
                      border: 0px;
                      height: 0px;
                      margin: 0px;
                      position: absolute;
                      inset: 0px;
                    "
                ></iframe>
                <line-chart v-if="loaded" :chartdata="datacollectionT"></line-chart>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </v-col>
        <v-col md="6" cols="12" class="py-0">
          <div class="box mt-20">
            <div class="box-header with-border">
              <h5 class="box-title">
                <i class="mdi mdi-water-percent mdi-24px"></i>
                {{ $t('message.report.chart') }} {{ $t('message.ir.humidity') }}
              </h5>

              <ul class="box-controls pull-right">
                <li><a class="box-btn-slide" href="#"></a></li>
              </ul>
            </div>
            <div class="box-body">
              <div class="chart">
                <iframe
                    class="chartjs-hidden-iframe"
                    style="
                      width: 100%;
                      display: block;
                      border: 0px;
                      height: 0px;
                      margin: 0px;
                      position: absolute;
                      inset: 0px;
                    "
                ></iframe>
                <bar-chart
                    v-if="loaded"
                    :chartdata="datacollectionH"
                ></bar-chart>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </v-col>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>
<script>
import BarChart from "../../../../public/assets/js/utils/BarChart.js";
import LineChart from "../../../../public/assets/js/utils/LineChart.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

export default {
  components: {
    LineChart,
    BarChart,
  },
  data() {
    return {
      datacollectionH: null,
      datacollectionT: null,
      loaded: false,
      isModalVisible: false,
    };
  },
  props: {
    home_id: {},
    serial_number: {},
    click: {}
  },
  mounted() {
    let data = {
      title: this.$t('message.ir.report'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'report');
    var self = this;
    setTimeout(function () {
      self.ChartFunction();
    }, 50);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    ChartFunction() {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var home_id = this.home_id;
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        serial_number: this.serial_number,
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.reportHistory;
      const p = postAjaxCall(URL, data, "");
      this.openModal();
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.exitModal();
              let content = jsonArray.content;


              content.forEach(function (item) {
                var humidity = item.device_humidity;
                var temp = item.device_temperature;
                var date = item.date.reverse();


                self.datacollectionT = {
                  labels: date,
                  datasets: [
                    {
                      label: self.$t('message.RelayKey.T'),
                      backgroundColor: "rgba(241, 90, 34, 0.8)",
                      borderColor: "rgba(211, 84, 0, 0.6)",
                      data: temp,
                    },
                  ],
                };
                self.datacollectionH = {
                  labels: date,
                  datasets: [
                    {
                      label: self.$t('message.ir.humidity') + '%',
                      backgroundColor: "rgba(50, 120, 255, 0.5)",
                      borderColor: "#007bff",
                      data: humidity,
                    },
                  ],
                };
                self.loaded = true;
              });
            } else {
              self.exitModal();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    openModal() {
      this.isModalVisible = true;
    },
    exitModal() {
      this.isModalVisible = false;
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "settingsir",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
    },
  },
};
</script>

