
var baseMtt=process.env.VUE_APP_BASE_MTT




console.log('process.env.VUE_APP_BASE_URL',process.env.VUE_APP_BASE_URL)

export const getMtt = {
    action:baseMtt+'/+/receive/+/action',
    scenario:baseMtt+'/+/receive/+/scenario'
  };
  
  

  
  
  
  