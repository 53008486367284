<template>
  <!-- .wrapper  -->
  <div class="wrapper mr-0">
    <!-- .header -->
    <header class="main-header">
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top">
        <!-- Sidebar toggle button-->
        <div class="navbar-custom-menu toolbar">
          <div class="media p-0">
            <i class="ti-angle-right" style="font-size:24px" @click="goBackSubRelay()"></i>
            <div class="media-body p-0">
              <strong>{{ title }}</strong></div>
          </div>
        </div>
        <div class="navbar-custom-menu" id="btn_setting">
          <ul class="nav navbar-nav" v-if="this.$store.getters.getadmin == 1">
            <!-- Control Sidebar Toggle Button -->
            <li class="dropdown">
              <a data-toggle="dropdown" href="#" aria-expanded="false"
              ><i class="ti-more-alt rotate-90"></i
              ></a>
              <div
                  class="dropdown-menu dropdown-menu-right"
                  x-placement="bottom-end"
                  style="
                  position: absolute;
                  transform: translate3d(-43px, 21px, 0px);
                  top: 0px;
                  left: 50px;
                  will-change: transform;
                "
              >
                <a class="dropdown-item" v-on:click.prevent="subEditShow()">
                  <i class="mdi mdi-border-color"></i>
                  ویرایش نام</a
                >
                <a class="dropdown-item" v-on:click.prevent="subDelShow()">
                  <i class="mdi mdi-delete"></i> حذف</a
                >
                <a class="dropdown-item" v-on:click.prevent="subTimerShow()">
                  <i class="mdi mdi-delete"></i
                  >تنظیمات زمانبندی</a
                >
                <a class="dropdown-item" @click="outletMomentary()">
                  <i class="mdi mdi-clock"></i> تنظیمات لحظه ای </a
                >
                <a class="dropdown-item" v-on:click.prevent="subPassShow()">
                  <i class="mdi mdi-lock"></i>گذرواژه
                  <span v-if="this.$store.getters.getSetPasswordSub==1"
                        class="badge badge-success badge-sm p-2 ml-2 mr-2">فعال</span>
                  <span v-if="this.$store.getters.getSetPasswordSub==0"
                        class="badge badge-danger ml-2 mr-2 p-2">غیرفعال</span>
                </a
                >
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- ./header -->
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper mr-0">
      <!-- Main content -->
      <section class="content">
        <div class="row mt-20 px-20" id="app">
          <div class="col-12">
            <!-- .row device-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16">
                <div
                    class="media align-items-center bg-white mb-20 py-20 rounded"
                >
                  <img
                      class="avatar"
                      src="/assets/images/smart/device.svg"
                      width="40px"
                      height="40px"
                      alt="..."
                  />
                  <div class="media-body">
                    <p>
                      <strong>{{ this.$store.getters.getNameSub }}</strong>
                    </p>
                    <p></p>
                  </div>
                  <!-- <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="false"
                         autocomplete="off"  id="checkdevice">
                         <div class="handle">
                           <input name="checkoutlet" id="checkoutlet" type="checkbox">
                         </div>
                       </button> -->

                  <label class="switch">
                    <input
                        name="checkoutlet"
                        id="checkoutlet"
                        type="checkbox"
                    />
                    <span class="switch-indicator"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- .row lahzeie-->
            <div class="row rounded-16">
              <div class="col-xl-12 col-md-12 col-12 rounded-16">
                <div
                    class="media align-items-center bg-white mb-20 py-20 rounded"
                >
                  <i class="mdi mdi-alarm mdi-36px" style="color: #007bff"></i>
                  <div class="media-body">
                    <p><strong>تنظیمات لحظه ای</strong></p>
                    <p></p>
                  </div>
                  <button
                      type="button"
                      class="btn btn-circle btn-primary mb-5"
                      v-on:click.prevent="MyFunctionPower()"
                  >
                    <i class="mdi mdi-power mdi-24px"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- .row report-->
            <div class="row mt-10">
              <div class="col-lg-12 col-12">
                <div class="box">
                  <div class="box-header with-border">
                    <h4 class="box-title">گزارش ها</h4>
                  </div>
                  <div class="box-body">
                    <div class="row">
                      <div class="col-xl-12 col-md-12 col-12">
                        <div class="text-center py-10 bb-1">
                          <h4>آخرین زمان بروزرسانی</h4>
                          <label id="clock" style="font-size: 16px"
                          >00.00.00</label>
                          <ul class="flexbox flex-justified text-center my-20">
                            <li class="px-10">
                              <h6 class="mb-0 text-bold">جریان</h6>
                              <label
                                  id="amp"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>
                            <li class="br-1 bl-1 px-10">
                              <h6 class="mb-0 text-bold">دما</h6>
                              <label
                                  id="temp"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>
                            <li class="px-10">
                              <h6 class="mb-0 text-bold">ولتاژ</h6>
                              <label
                                  id="volt"
                                  class="p-4"
                                  style="font-size: 16px"
                              >0.0</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <br/>
                    </div>
                    <div class="d-flex justify-content-center mt-20">
                      <button
                          id="refresh"
                          type="button"
                          class="btn btn-primary mb-5"
                          v-on:click.prevent="ATVC()"
                      >
                        <i class="mdi mdi-refresh"></i> بروزرسانی
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- /.content -->
    </div>

    <!-- /.content-wrapper -->
    <!-- Modal Laoding-->
    <ModalSubMomentray v-show="isModalVisible" v-bind:serials="serial"></ModalSubMomentray>
    <SubModalDel v-bind:data_info_sub="data_info_subrelay"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_subrelay"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_subrelay"></SubModalSetPass>
    <!-- ./Modal Pass-->
    <div class="modal center-modal fade" id="modal-pass-subrelay" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">امنیت</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                @click="closePass()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>گذرواژه<span class="text-danger">*</span></h6>
              <input
                  type="text"
                  class="form-control"
                  required=""
                  aria-invalid="false"
                  maxlength="4"
                  pattern="[0-9]"
                  autocomplete="off"
                  style="-webkit-text-security: disc; margin-top: 8px"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  id="idpassoutlet"
                  placeholder="گذرواژه را وارد نمایید"
                  v-model="passoutlet"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassOutlet()"
                >
                  ارسال
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /. Wrapper -->
</template>
<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {BetweenStr} from "../../../../public/assets/js/utils/util.js";
import {SucessToastMsg} from "../../../../public/assets/js/utils/util.js";
import {ErrorToastMsg} from "../../../../public/assets/js/utils/util.js";
import ModalSubMomentray from "./ModalSubMomentray";
import {SucessToast} from "../../../../public/assets/js/utils/util.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {msgOutlet} from "../../../../public/assets/js/utils/msg_handler_outlet";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {getTopics} from "@/../public/assets/js/utils/topics.js";

var topic1;
var topic2;
var connection = false;
var switch_status = "P1OFF#";
var set_password;
var showToast;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    ModalSubMomentray,

  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
  },
  data() {
    return {
      isModalVisiblePass: false,
      isShowOutlet: false,
      isModalVisible: false,
      serial: null,
      homeid: this.home_id,
      data_info_subrelay: [this.home_id, this.serial_number, this.count],
      passoutlet: "",
      title: '',
    };
  },
  mqtt: {
    "+/+/receive/256/action"(data, topic) {

      if (topic.split("/")[1] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number) {
        if (topic.split("/")[4] === "action") {
         
          connection = true;
          this.showGetMsg(data + "");
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
    "+/+/receive/256/atvc"(data, topic) {

      if (topic.split("/")[1] == JSON.parse(localStorage.getItem("infoSubDevice")).serial_number) {
        if (topic.split("/")[4] === "atvc") {
         
          connection = true;
          this.showGetMsgATVC(data + "");

        }
      }
    },
  },
  mounted() {

    var tapp = localStorage.getItem("t_app");
    var user_id = localStorage.getItem("user_id");
    var token = localStorage.getItem("token");
    let self = this;
    setTimeout(function () {
      self.lastStatusMqtt(token, user_id);
      self.ATVC();
    }, 50);
    //------------- GET DATA VUEX
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    set_password = this.$store.getters.getSetPasswordSub;
    this.title = this.$store.getters.getNameSub;
    var checkbox = document.getElementById("checkoutlet");

    //----------------------------------- Subscribe-----------------------------------
    let subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number;
    this.serial = subdevice_serial;
 
    var topic1 = getTopics.getTopicActionReceive(subdevice_serial, "256");
    var topic2 = getTopics.getTopicAtvcReceive(subdevice_serial, "256");

    this.$mqtt.subscribe(topic1);
    this.$mqtt.subscribe(topic2);

    checkbox.addEventListener("change", function () {

      if (checkbox.checked) {
        if (set_password == 1) {
          switch_status = "P1ON#";
          $("#modal-pass-subrelay").modal("show");
        } else {
          self.switchAction("P1ON#", "");
        }
      } else {
        if (set_password == 1) {
          switch_status = "P1OFF#";
          $("#modal-pass-subrelay").modal("show");
        } else {
          self.switchAction("P1OFF#", "");
        }
      }
    });
  },
  methods: {
    switchAction(saction, password) {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final;
      let subdevice_serial = this.$store.getters.getSerialSub
      var topic_final = getTopics.getTopicActionSend(subdevice_serial, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {

          var jsonArray = JSON.parse(response.responseText);

          $("#modal-pass-subrelay").modal("hide");
          setTimeout(function () {
            // self.exitModalLoading();
            if (!connection) {
              if (showToast == 1) {
                ErrorToastMsg.Msg();
              }
              setTimeout(function () {
                var checkbox = document.getElementById("checkoutlet");
                checkbox.checked = !checkbox.checked;
              }, 3000);
            }
            showToast = 0;
            connection = false;
          }, 3000);
        } else {
          if (response.status == 401) self.$router.push({name: "home"});
          //error
        }
      });
    },
    MyFunctionPower() {
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var subdevice_serial = this.$store.getters.getSerialSub
      var topic_final;
      let relay_delay = localStorage.getItem(
          "relay_delay" + subdevice_serial
      );

      if (relay_delay == null || relay_delay == "") {
        relay_delay = "01";
      }
      let ssaction = "P1:" + relay_delay + "S#";

      var topic_final = getTopics.getTopicDelaySend(subdevice_serial, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: ssaction,

        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(function (response) {
        if (responseAjax(response)) {
          let jsonArray = JSON.parse(response.responseText);
          SucessToast.delay();
        } else {
          //error
        }
      });
    },
    lastStatusMqtt(token, user_id) {
      let self = this;
      //------DATA
      var data = {
        user_id: user_id,
        token: token,
        home_id: this.home_id,
        serial_number: this.$store.getters.getSerialSub,
        count: this.count
      };

      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data, "");
      p.then(function (response) {
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          var checkbox = document.getElementById("checkoutlet");
          var msg_relay = jsonArray.content.message;
          msgOutlet(msg_relay, checkbox);
        } else {
          if (response.status == 401) {
            if (jsonArray.code == '4001') {
              self.$router.push({name: "home"});
            }
          }
        }
      });
    },
    showGetMsg(msg) {
      var checkbox = document.getElementById("checkoutlet");
      msgOutlet(msg, checkbox);
    },
    ATVC() {
      let subdevice_serial = this.$store.getters.getSerialSub
      var tapp = localStorage.getItem("t_app");
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var topic_final;
      var topic_final = getTopics.getTopicAtvcSend(subdevice_serial, "256");
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: "ATVC?",
        client_id: "atv" + Date.now(),
      };
      // --------------------Requsest----------------------//
     let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              let message = jsonArray.content.message;
            } else {
              //error
            }
          },
          function (errr) {
            // loading.style.visibility = "hidden";
            let jsonArray = JSON.parse(errr);
            // err.innerHTML = jsonArray.message;
            // EnableItem();
          }
      );
    },
    showGetMsgATVC(msg) {
      var amp = document.getElementById("amp");
      var temp = document.getElementById("temp");
      var volt = document.getElementById("volt");
      var clock = document.getElementById("clock");

      var A = BetweenStr.getBetweenStr(msg, "A=", "~T");
      var T = BetweenStr.getBetweenStr(msg, "~T=", "~V");
      var V = BetweenStr.getBetweenStr(msg, "~V=", "~C");
      var C = BetweenStr.getBetweenStr(msg, "~C", "#");
     
      amp.textContent = A[1];
      temp.textContent = T[1];
      volt.textContent = V[1];
      clock.textContent = C[1];
    },
    editNameOutlet() {
      $("#modal-edit-outlet").modal("show");
    },
    outletMomentary() {
      this.isModalVisible = true;
      $("#modal-sub-momentray").modal("show");
    },
    outletPassShow() {
      this.isShowOutlet = true;
    },
    openModalPass() {
      this.isModalVisiblePass = true;
      //  $("#modal-pass").modal("show");
    },
    closeModalPass() {
      this.isModalVisiblePass = false;
      // $("#modal-pass").modal("hide");
    },
    sendPassOutlet() {
      var msg;
      $("#modal-pass-subrelay").modal("hide");
      this.switchAction(switch_status, this.passoutlet);
      this.passoutlet = "";
    },
    subPassShow() {
      $("#modal-set-pass-sub").modal("show");
    },
    goBackSubRelay() {
      this.$router.push({
        name: "homeDevices",
        params: {
          serial_number: this.serial_number,
          home_id: this.home_id,
        },
      });
    },
    subTimerShow() {
      this.$router.push({
        name: "SubRelayTimerShow",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
        },
      });
    },
    closePass() {
      var checkbox = document.getElementById("checkoutlet");
      checkbox.checked = !checkbox.checked;
    },

    subEditShow() {
      $("#modal-edit-sub").modal("show");
    },
    subDelShow() {
      $("#modal-del-sub").modal("show");
    },

  },
};
</script>
<style scoped>
</style>
