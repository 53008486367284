<template>
<v-app class="bg">
  <div>
    <div class="row px-20 mt-20">
      <div class="col-lg-12 col-12 py-0">
        <div class="box rounded-16">
          <div class="box-body pb-0">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-12 py-0">
                 <div class="box-footer with-border p-0" v-bind:key="index" v-for="(info, index) in infos">
                  <CmpRow2RF :info="info">
                    <label class="switch">
                      <input 
                        name="checkL4"
                        type="checkbox"
                        v-model="info.value"
                        v-bind="$attrs"
                        :disabled="!info.active"
                      />
                      <span class="switch-indicator"></span>
                    </label>
                    <template v-slot:select>
                       <v-checkbox v-model="info.active" class="mt-0" ></v-checkbox>
                    </template>
                  </CmpRow2RF>

                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</v-app>
</template>
<script>
import CmpRow2RF from "@/components/Cmp/CmpRow2RF.vue";

export default {
  components: {
    CmpRow2RF,
  },
   props: ["infos"],

   watch: {
    data: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    deep: true,
    // handler (val, oldVal) {

    //   for (let i = 0; i <=3; i++) {
    //    if(val[4].active){
    //       val[i].active=true
    //     if(val[4].value){
    //       val[i].value=true
    //     }
    //     if(!val[4].value){
    //       val[i].value=false
    //     }
    //    }
    //    else{
    //     val[i].active=val[i].active
        
    //   }
    //   } 
    // }
  }
},
  data() {
    return {
      cmpShow: false,
      data:this.infos
    };
  },

  created(){

  },
  mounted() {
   
  },

  methods: {

  },
};
</script>
