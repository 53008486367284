<template>
  <v-app class="bg">
    <div>
      <v-container>
        <div v-if="emptyShow" class="mt-50">
          <CmpEmptyData
              :txtCaption="txtCaptionRoomEmpty"
              :btnShow="true"
              :txtBtn="$t('message.Rooms.add_room')"
              @clicked="clickAddRoom(false, 0)"
          ></CmpEmptyData>
        </div>
        <div :key="index" v-for="(room, index) in rooms">
          <v-row class="d-flex align-items-center px-3 py-2">
            <v-col cols="12" class="align-items-center p-0">
              <CmpRowList
                  :infoItem="room"
                  @clicked="clicked"
                  :items="items"
                  :ripple="false"
              ></CmpRowList>
            </v-col>
          </v-row>
        </div>
        <my-modal v-show="isModalVisible"></my-modal>
        <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import {postAjaxCall} from "../../../public/assets/js/postAjax";
import {responseAjax} from "../../../public/assets/js/utils/response_ajax_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../layouts/LayoutDefault";
import CmpEmptyData from "../Cmp/public/CmpEmptyData";
import CmpPasscode from "../Cmp/general/CmpPasscode";
import CmpRowList from "../Cmp/CmpRowList";

export default {
  name: "SettingRoom",
  components: {CmpRowList, CmpPasscode, CmpEmptyData},
  props: {
    serial_number: {},
    home_id: {},
    click: {}
  },
  data() {
    return {
      index: '',
      rooms: [],
      items: [],
      emptyShow: false,
      txtCaptionRoomEmpty: '',
      isModalVisible: false
    }
  },
  created() {
    this.lastStatus()
    this.txtCaptionRoomEmpty = this.$t(
        "message.Rooms.txtCaptionRoomEmpty"
    );
    this.items = [{
      title: this.$t('message.public.edit'),
      icon: 'mdi-border-color'
    }, {title: this.$t('message.public.delete'), icon: 'mdi-delete'}]
  },
  watch: {
    click(val) {
      this.onClickChild(val)
    }
  },
  mounted() {
    let details = {
      title: this.$t("message.ListDevice.Rooms"),
      add: true,
      refresh: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'scenarioList');
  },
  methods: {
    onClickChild(val) {
      if (val === "back") {
        this.$router.replace({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (val === "refresh") {
        this.lastStatus()
      }
      if (val === "add") {
        this.clickAddRoom(false, 0)
      }
    },
    lastStatus() {
      this.rooms = []
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      var data = {
        user_id: user_id,
        token: token,
        home_id: this.home_id,
        serial_number: this.serial_number,
      };
      let URL = getUrl.rooms;

      const p = postAjaxCall(URL, data);
      self.openModalLoading()
      p.then(function (response) {
        self.closeModalLoading()
        var jsonArray = JSON.parse(response.responseText);
        if (responseAjax(response)) {
          self.index = jsonArray.content
          self.index.rooms.forEach((item, index) => {
            console.log({
              index: index,
              img: item.logo_path,
              title: item.name,
              actions: 'menu',
              uid: item.uid,
              ...JSON.parse(item.info),
            })
            self.rooms.push({
              index: index,
              img: item.logo_path,
              title: item.name,
              actions: 'menu',
              uid: item.uid,
              ...JSON.parse(item.info),
            })
          })
          if (self.index.rooms.length === 0) {
            console.log("emptyShow")
            self.emptyShow = true
          }
          console.log(self.rooms)
        }
      })
    },
    onClick(index) {
      localStorage.setItem("RoomIndex", JSON.stringify({
        index: index,
        room: this.rooms[index]
      }));
      this.$router.push({
        name: "RoomInfo",
        params: {
          home_id: this.home_id,
          serial_number: this.serial_number,
          uid: this.rooms[index].uid,
        },
      });
    },
    clicked(itemId) {
      console.log(this.rooms[itemId.index])
      switch (itemId.itemId) {
        case 0:
          localStorage.setItem("infoRoomLocal", JSON.stringify({
            name: "",
            logo: this.rooms[itemId.index].img,
            devices: ""
          }))
          localStorage.setItem("nameRoom", JSON.stringify(this.rooms[itemId.index].title))
          localStorage.setItem("roomDevices", JSON.stringify({
            controls: this.rooms[itemId.index].controls,
            relays: this.rooms[itemId.index].relays,
            subDevices: this.rooms[itemId.index].subDevices,
          }))
          this.clickAddRoom(true, itemId.index)
          break
        case 1:
          this.deleteItem(itemId.index)
          break
      }
    },
    deleteItem(index) {
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      let self = this;
      var data = {
        user_id: user_id,
        token: token,
        room_uid: self.rooms[index].uid
      };
      let URL = getUrl.roomsDel;

      const p = postAjaxCall(URL, data);
      self.openModalLoading()
      p.then(function (response) {
        self.closeModalLoading()
        console.log(response)
      }).then(() => {
        self.lastStatus()
      })
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    closeModalLoading() {
      this.isModalVisible = false;
    },
    sendReq() {

    },
    clickAddRoom(update, index) {
      if (update)
        localStorage.setItem("update", JSON.stringify({
          update: true,
          uid: this.rooms[index].uid
        }))
      else
        localStorage.setItem("update", JSON.stringify({
          update: false,
          uid: ''
        }))
      this.$router.push({
        name: "AddRoom",
        params: {
          home_id: this.home_id, serial_number: this.serial_number
        }
      })
    }
  }
}
</script>

<style scoped>

</style>