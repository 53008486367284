<template>
  <!-- .wrapper  -->
  <v-app class="bg">
    <div class="mt-2">
      <!-- Main content -->
      <div class="px-3 mt-5">
        <v-card max-width="1750" class="mx-auto">
          <v-row class="justify-center px-3 py-0">
            <v-col cols="1" class="align-self-center">
              <v-icon color="primary">mdi-format-color-text</v-icon>
            </v-col
            >
            <v-col cols="4" class="align-self-center">
              <label class="mt-1">
                {{ $t("message.relay.relay_name") }}
              </label>
            </v-col>
            <v-col cols="7" class="align-self-center">
              <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.relay.relay_name_input')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="nameboxrelay"
                  maxlength="32"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="border-gray-light"></v-divider>
          <v-row class="justify-center px-3 py-0">
            <v-col cols="1" class="align-self-center">
              <v-icon color="primary">mdi-barcode</v-icon>
            </v-col
            >
            <v-col cols="4" class="align-self-center">
              <label class="mt-1">
                {{ $t("message.public.serial") }}
              </label></v-col
            >
            <v-col cols="7" class="align-self-center">
              <v-text-field
                  background-color="#f5f5f5"
                  :placeholder="$t('message.relay.relay_serial_input')"
                  clearable
                  solo
                  flat
                  hide-details="true"
                  v-model="serialboxrelay"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <v-container class="mt-2">
        <v-btn
            class="btn btn-block"
            color="bg-blue-light"
            elevation="0"
            @click="AddRelayAction()"
        >
          <v-icon class="text-dark mdi-24px" left> mdi-plus</v-icon>
          <label class="text-dark text-bold">
            {{ $t("message.relay.add.add_relay") }}
          </label>
        </v-btn>
        <CmpEmptyData
            class="mt-10"
            v-if="localRelaydata.length == 0"
            :btnShow="false"
            :txtCaptionShow="false"
        >
        </CmpEmptyData>
        <div class="mb-50">
          <div :key="index" v-for="(relayItem, index) in localRelaydata">
            <CmpRowList
                :infoItem="relayItem"
                :subTitleShow="true"
                :index="index"
                @clicked="CmpCallDel">
            </CmpRowList>
          </div>
        </div>
        <div class="fixed-bottom mb-1 px-4">
          <cmpbutton
              class="mb-2"
              :txtBtn="$t('message.public.submit')"
              :loading="loading"
              @clickedbtn="getCallBackBtn"
          ></cmpbutton>
        </div>
      </v-container>
      <DialogImg
          v-model="showDialogImg"
          :data="logos"
          @clicked="CmpCallImg"
      />
      <DialogInputImg
          v-model="showDialog"
          :data="type_relay"
          :datarelay="localRelaydata"
          @clicked="CmpCallTypeRelay"
      />
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
  <!-- /. Wrapper -->
</template>
<script>
import DialogImg from "@/components/Cmp/DialogImg.vue";
import CmpEmptyData from "../../Cmp/public/CmpEmptyData.vue";
import DialogInputImg from "@/components/Cmp/DialogInputImg.vue";
import CmpRowList from "@/components/Cmp/CmpRowList.vue";
import cmpbutton from "@/components/Cmp/CmpButton.vue";
import {postAjaxCall} from "../../../../public/assets/js/postAjax.js";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler.js";
import {
  actionRelaydata,
  nameValidationInput,
  serialDeviceValidationInput,
} from "../../../../public/assets/js/utils/error_handler.js";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import {errOutletDelay} from "../../../../public/assets/js/utils/error_handler";
import LayoutDefault from "../../../layouts/LayoutDefault";

// camelCase in JavaScript
export default {
  components: {
    cmpbutton,
    DialogInputImg,
    DialogImg,
    CmpRowList,
    CmpEmptyData,
  },
  data() {
    return {
      loading: false,
      isModalVisible: false,
      showDialog: false,
      showDialogImg: false,
      logos: [],
      type_relay: [],
      localRelaydata: [],
      serialboxrelay: "",
      nameboxrelay: "",
      relay_data: [],
      arr: [],
      // delayShow: false,
      errDelay: false,
      listnum: [],
    };
  },
  props: {
    click: {},
  },
  mounted() {
    this.$store.dispatch("infoRelay8oLocal");
    this.localRelaydata = this.$store.getters.getRelay8Local;
  },
  created() {
    let self = this;
    for (let i = 1; i < 100; i++) {
      this.listnum.push(i)
    }
    let data = {
      title: this.$t('message.relay.add.add_relay'),
    }
    localStorage.setItem("AppBar", JSON.stringify(data));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'noneBoxRelay');
    setTimeout(function () {
      self.getImageRelay();
    }, 500);
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    SaveRelay() {
      // this.isModalVisible = true;
      this.relay_data = [];
      this.errDelay = false;

      if (this.nameboxrelay == null) {
        this.nameboxrelay = "";
      }
      if (this.serialboxrelay == null) {
        this.serialboxrelay = "";
      }

      let self = this;
      if (
          !nameValidationInput(this.nameboxrelay) ||
          !serialDeviceValidationInput(this.serialboxrelay) ||
          !actionRelaydata(this.localRelaydata)
      )
        return;

      this.localRelaydata.forEach(function (item, i) {
        console.log('item.delay', item.delay);
        console.log('item.delayShow', item.delayShow);
        if (item.delayShow == true) {
          if (!errOutletDelay(item.delay)) {
            this.errDelay = true
          }
        }
        self.relay_data.push({
          name: item.title,
          numbers: item.numbers,
          type_code: item.type_code,
          delay: item.delay,
        });
      });
      if (!this.errDelay) {
        this.sendRequest();
      }
    },
    sendRequest() {
      let self = this;
      var name = this.nameboxrelay;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var serial_number = this.serialboxrelay;

      var object = {
        relay_data: this.relay_data,
      };

      var data = {
        user_id: user_id,
        token: token,
        name: name,
        serial_number: serial_number,
        relay_data: JSON.stringify(object),
      };
  console.log('data relay ',data);
      //-----------------------Request ------------------------------//
      let URL = getUrl.noneDeviceAdd;
      const p = postAjaxCall(URL, data, "");
      this.loading = true;
      // -----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);

              self.loading = false;
              self.$router.push({
                name: "mainpage"
              });
            } else {
              //error
              self.loading = false;
            }
          },
          //----------------------Err ---------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false
    },
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "mainpage",
        });
      }
    },
    getCallBackBtn(msg) {
      this.SaveRelay();
    },
    AddRelayAction() {
      this.showDialogImg = true;
    },
    CmpCallImg(info) {
      this.type_relay = info;
      this.showDialog = true;
    },
    CmpCallTypeRelay(msg) {
      let self = this;

      var text = "";
      for (let i = 0; i < msg.numbers.length; i++) {
        text += this.$t("message.relay.relay") + msg.numbers[i];
      }
    
      this.localRelaydata.push({
        title: msg.name,
        img: msg.logo_path,
        subTitle: text,
        icon: true,
        actions: "icon",
        numbers: msg.numbers,
        type_code: msg.type,
        delay: parseInt(msg.delay),
        // delayShow: this.delayShow,
      });
      localStorage.setItem(
          "infoRelay8oLocal",
          JSON.stringify(this.localRelaydata)
      );
    },
    CmpCallDel(index) {
      // this.arr = [];
      // this.localRelaydata.splice(index, 1);
      // localStorage.setItem(
      //   "infoScenarioLocal",
      //   JSON.stringify(this.localRelaydata)
      // );
      this.showDelDialog(index);
    },
    getImageRelay() {
      let self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");

      var data = {
        user_id: user_id,
        token: token,
      };

      //-----------------------Request ------------------------------//
      let URL = getUrl.relayType;
      const p = postAjaxCall(URL, data, "");
      //-----------------------Response ------------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              console.log(jsonArray);
              let data = [];
              for (let i = 0; i < jsonArray.length; i++) {
                // if (jsonArray[i].number_relay > 1) {
                //   continue;
                // }
                data.push(jsonArray[i]);
              }
              self.logos = data;
              console.log(self.logos);
            } else {
              //error
            }
          }
          ,

          //----------------------Err ---------------------//
          function (errr) {
          }
      )
      ;
    },
    showErrEqaulDialog(uid) {
      this.$swal({
        title: this.$t("message.public.error"),
        text: this.$t("message.relay.add.err_add_equal"),
        type: "error",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.OK"),
        // cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              // this.delScenario(uid);
            }
          }
          // (dismiss) => {
          //   if (dismiss === "cancel") {
          //   } else {
          //     throw dismiss;
          //   }
          // }
      );
    }
    ,
    showDelDialog(index) {
      // console.log('showDelDialog');
      this.$swal({
        title: this.$t("message.public.delete"),
        text: this.$t("message.relay.del.del_dialog_caption"),
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t("message.public.delete"),
        cancelButtonText: this.$t("message.public.cancel"),
      }).then(
          (result) => {
            if (result) {
              this.arr = [];
              this.localRelaydata.splice(index, 1);
              localStorage.setItem(
                  "infoScenarioLocal",
                  JSON.stringify(this.localRelaydata)
              );
            }
          },
          (dismiss) => {
            if (dismiss === "cancel") {
            } else {
              throw dismiss;
            }
          }
      );
    },
    select_value(e) {
      console.log(e)
      let self = this
      // console.log('localRelaydata',this.localRelaydata);
      //  this.localRelaydata.forEach(function (item, i) {
      //       console.log('select_value',i);
      //        item.delay=e
      //        console.log(' this.localRelaydata', self.localRelaydata[i].delay);
      //        self.localRelaydata[i].delay=e
      // });
    }
  },
};
</script>
<style>
.v-input {
  font-family: "IRANSans";
  font-size: 12px !important;
}

.v-sheet.v-card {
  border-radius: 12px;
  box-shadow: none !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #f5f5f5;
  height: 10;
  min-height: 20;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  border: 0;
}
</style>
