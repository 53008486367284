import i18n from "../../../../src/i18n";
export function getTypeRelay8(message) {
    var type='';
    switch(message) {
        case '2001':
          type=i18n.t('message.relay.type.lamp')
          break;
        case '2002':
          type=i18n.t('message.relay.type.tea_Maker')
          break;
        case '2003':
          type=i18n.t('message.relay.type.light')
          break;
        case '2004':
          type=i18n.t('message.relay.type.television')
          break;
        case '2005':
          type=i18n.t('message.relay.type.heater')
          break;
        case '2006':
          type=i18n.t('message.relay.type.fan')
          break;
        case '2007':
          type=i18n.t('message.relay.type.fireplace')
          break;
        case '2008':
          type=i18n.t('message.relay.type.speaker')
          break;
        case '2009':
          type=i18n.t('message.relay.type.electric_water_pump')
          break;
        case '2010':
          type=i18n.t('message.relay.type.door_lock')
          break;
        case '2011':
          type=i18n.t('message.relay.type.microwave')
          break;
        case '2012':
          type=i18n.t('message.relay.type.refrigerator')
          break;
        case '2013':
          type=i18n.t('message.relay.type.hairdryer')
          break;
        case '2014':
          type=i18n.t('message.relay.type.rice_cooker')
          break;
        case '2015':
          type=i18n.t('message.relay.type.gas_electric_pulley')
          break;
        case '2016':
          type=i18n.t('message.relay.type.laundry')
          break;
        case '2017':
          type=i18n.t('message.relay.type.fan1')
          break;
        case '2018':
          type=i18n.t('message.relay.type.playStation')
          break;
        case '2019':
          type=i18n.t('message.relay.type.plug')
          break;
        case '2020':
          type=i18n.t('message.relay.type.audio_and_video_device')
          break;
        case '2021':
          type=i18n.t('message.relay.type.night_lamp')
          break;
        case '2022':
          type=i18n.t('message.relay.type.reading_light')
          break;
        case '2023':
          type=i18n.t('message.relay.type.sewing_machine')
          break;
        case '2024':
          type=i18n.t('message.relay.type.gas_oven')
          break;
        case '2025':
          type=i18n.t('message.relay.type.coffee_maker')
          break;
        case '2026':
          type=i18n.t('message.relay.type.juicer')
          break;
        case '2027':
          type=i18n.t('message.relay.type.modem')
          break;
        case '2028':
          type=i18n.t('message.relay.type.window')
          break;
        case '2029':
          type=i18n.t('message.relay.type.mobile')
          break;
        case '2030':
          type=i18n.t('message.relay.type.laptop')
          break;
        case '2031':
          type=i18n.t('message.relay.type.air_conditioner')
          break;
        case '2032':
          type=i18n.t('message.relay.type.radio')
          break;
        case '2033':
          type=i18n.t('message.relay.type.home')
          break;
        case '2034':
          type=i18n.t('message.relay.type.leaves')
          break;

        case '2035':
          type=i18n.t('message.relay.type.star')
          break;
        case '2036':
          type=i18n.t('message.relay.type.cloud')
          break;
        case '2037':
          type=i18n.t('message.relay.type.curtain')
          break;
        case '2038':
          type=i18n.t('message.relay.type.shutter_door')
          break;
        case '2039':
          type=i18n.t('message.relay.type.water_cooler')
          break;
        case '2040':
          type=i18n.t('message.relay.type.printer')
          break;
        case '2041':
          type=i18n.t('message.relay.type.shaver')
          break;
        case '2042':
          type=i18n.t('message.relay.type.vacuum_cleaner')
          break;
        case '2043':
          type=i18n.t('message.relay.type.Iron')
          break;
        case '2044':
          type=i18n.t('message.relay.type.yard_pasture')
          break;
        default:
      }
      return type

}