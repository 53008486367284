<template>
  <v-app class="bg">
    <div>
      <section class="content">
        <!-- row -->
        <div class="row px-5 mt-3">
          <!-- col -->
          <div class="col-12 col-lg-12">
            <div class="box">
              <div class="box-body">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="bg-white">
                    <div style="text-align: center">
                      <div class="fontawesome-icon-list m-0">
                        <img
                            src="/assets/images/smart/vector-lock.svg"
                            style="width: 250px; height: auto;display: inline !important;" 
                        />
                      </div>
                    </div>
                    <div class="row rounded-16 px-10 mt-10 px-20">
                      <div
                          class="col-xl-12 col-md-12 col-12 rounded-16 borderR"
                      >
                        <div
                            class="
                            media
                            align-items-center
                            bg-white
                            py-20
                            rounded
                          "
                        >
                          <i class="mdi mdi-verified mdi-24px text-primary"></i>
                          <div class="media-body">
                            <h6>{{ $t('message.subDevice.last') }}</h6>
                          </div>
                          <img
                              id="imglock"
                              src="/assets/images/smart/loading.svg"
                              style="width: 36px; height: auto"
                          />
                        </div>
                      </div>
                    </div>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <div class="row rounded-16 px-5 mt-3">
          <div class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 rounded">
              <div v-if="activeClose == true">
                <i class="mdi mdi-lock-outline mdi-24px text-primary"></i>
              </div>
              <div v-else>
                <i class="mdi mdi-lock-outline mdi-24px text-gray"></i>
              </div>
              <div class="media-body">
                <h6>{{ $t('message.subDevice.lock') }}</h6>
              </div>
              <button
                  type="button"
                  class="btn btn-circle btn-success"
                  @click="CloseFunction()"
              >
                <i class="mdi mdi-lock-outline mdi-24px"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row rounded-16 px-5 mt-3">
          <div class="col-xl-12 col-md-12 col-12 rounded-16">
            <div class="media align-items-center bg-white py-20 rounded">
              <div v-if="activeOpen == true">
                <i class="mdi mdi-lock-open-outline mdi-24px text-primary"></i>
              </div>
              <div v-else>
                <i class="mdi mdi-lock-open-outline mdi-24px text-gray"></i>
              </div>
              <div class="media-body">
                <h6>{{ $t('message.subDevice.unlock') }}</h6>
              </div>
              <button
                  type="button"
                  class="btn btn-circle btn-danger"
                  @click="OpenFunction()"
              >
                <i class="mdi mdi-lock-open-outline mdi-24px"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- /. Main content -->
    </div>
    <!-- ./Modal Pass-->
    <div class="modal center-modal fade" id="modal-pass-lock" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('message.public.security') }}</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span aria-hidden="true" style="font-size: 28px">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <h6>{{ $t('message.ModalAddHome.pin_code') }}<span class="text-danger">*</span></h6>
              <input
                  type="number"
                  class="form-control"
                  required=""
                  aria-invalid="false"
                  maxlength="4"
                  pattern="[0-9]"
                  autocomplete="off"
                  id="idpasslock"
                  style="-webkit-text-security: disc; margin-top: 8px"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  :placeholder=" $t('message.ModalAddHome.password_hint') "
                  v-model="passlock"
              />
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-12 text-center">
                <button
                    type="button"
                    class="btn btn-info btn-block margin-top-10"
                    @click="sendPassLock()"
                >
                  <span class="text-white">{{ $t('message.public.send') }}</span>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-modal v-show="isModalVisible"></my-modal>
    <SubModalDel v-bind:data_info_sub="data_info_lock"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_lock"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_lock"></SubModalSetPass>
  </v-app>
  <!-- /. Wrapper -->
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
  Internets,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import LayoutDefault from "../../../layouts/LayoutDefault";

var lock_status;
var set_password;
var connection = false;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    click: {},
  },
  data() {
    return {
      passlock: "",
      data_info_lock: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      activeClose: false,
      activeOpen: false,
      pass: "",
      set_password: "",
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.count) {
        if (topic.split("/")[4] === "action") {
          connection = true;
          clearTimeout(timeout);
          this.showGetMsg(String(data));
          this.exitModalLoading();
          if (showToast == 1) {
            SucessToastMsg.Msg();
          }
        }
      }
    },
  },
  mounted() {
    try {
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      // var tapp = localStorage.getItem("t_app");
      set_password = this.$store.getters.getSetPasswordSub;

      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      setTimeout(function () {
        self.lastStausLock();
      }, 100);
      // subscribe
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.count
      );
      this.$mqtt.subscribe(topic1);
    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#DD6B55",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    this.$store.dispatch("InfoSubDevice");
    this.$store.dispatch("infoHome");
    this.data = JSON.parse(localStorage.getItem("infoHome"))
    this.pass = this.data.password === 1;
    this.set_password = this.$store.getters.getSetPasswordSub;
    let details = {
      title: this.title,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCurtain');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    OpenFunction() {
      if (set_password == 1) {
        lock_status = "OPEN#";
        $("#modal-pass-lock").modal("show");
      } else {
        this.sendMessage("OPEN#", "");
      }
    },
    CloseFunction() {
      if (set_password == 1) {
        lock_status = "CLOSE#";
        $("#modal-pass-lock").modal("show");
      } else {
        this.sendMessage("CLOSE#", "");
      }
    },
    sendMessage(saction, password) {
      var self = this;

      var password = this.passlock;
      var topic_final;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.count
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data, "");
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            if (responseAjax(response)) {
              let jsonArray = JSON.parse(response.responseText);
              self.openModalLoading();
              connection = false;
              $("#modal-pass-lock").modal("hide");
              timeout = setTimeout(function () {
                self.exitModalLoading();
                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    showGetMsg(msg) {
      var imglock = document.getElementById("imglock");
      if (msg == "OPEN") {
        imglock.src = "/assets/images/smart/fill_lock_open.svg";
        this.activeOpen = true;
        this.activeClose = false;
        setTimeout(function () {
          imglock.src = "/assets/images/smart/fill_lock_open0.svg";
        }, 1000);
      }
      if (msg == "CLOSE") {
        imglock.src = "/assets/images/smart/fill_lock_closed.svg";
        this.activeClose = true;
        this.activeOpen = false;
        setTimeout(function () {
          imglock.src = "/assets/images/smart/fill_lock_closed0.svg";
        }, 1000);
      }
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassLock() {
      if (!passHomeValidationInput(this.passlock)) return;
      this.sendMessage(lock_status, this.passlock);
      this.passlock = "";
    },
    goBackRemote() {
      this.$router.push({
        name: "homeDevices",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    goBackLock() {
      this.$router.push({
        name: "homeDevices",
        params: {home_id: this.home_id, serial_number: this.serial_number},
      });
    },
    lastStausLock() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let serial_number = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data, "");
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              self.showStatusLock(message);
            } else {
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
            // console.log("err", errr);
          }
      );
    },
    showStatusLock(message) {
      var imglock = document.getElementById("imglock");
      if (message == "OPEN") {
        imglock.src = "/assets/images/smart/fill_lock_open.svg";
        setTimeout(function () {
          imglock.src = "/assets/images/smart/fill_lock_open0.svg";
        }, 1000);
      }
      if (message == "CLOSE") {
        imglock.src = "/assets/images/smart/fill_lock_closed.svg";
        setTimeout(function () {
          imglock.src = "/assets/images/smart/fill_lock_closed0.svg";
        }, 1000);
      }
    },
  },
};
</script>


<style scoped>
.borderR {
  border-radius: 20px;
  border: 1px solid #d5d6d6;
  padding: 5px;
}
</style>
