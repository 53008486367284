<template>
  <v-app class="bg">
    <v-container>
      <div class="mt-5">
        <v-row class="justify-center px-3 py-0 text-center">
          <v-col cols="12" md="4" class="py-0">
            <img :src="'/assets/images/smart/img_curtain.png'"/>
          </v-col>
          <v-card class="mx-2 mt-5 py-5">
            <v-col cols="12" class="py-0 text-center  px-10">
              <v-row>
                <v-col cols="12"><label class="text-center"> {{ $t('message.subDevice.curtain.status_curtain') }} :
                  {{ txtStatus }}</label></v-col>
              </v-row>
              <v-row class="py-0 text-center">
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn
                      fab
                      large
                      outlined
                      color="primary"
                      elevation="3"
                      @click="openFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5">
                  <v-btn
                      fab
                      x-large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="stopFunction()"
                  >
                    <v-icon class="text-primary">mdi-pause</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="px-5 mt-1">
                  <v-btn
                      fab
                      large
                      color="primary"
                      outlined
                      elevation="3"
                      @click="closeFunction()"
                  >
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-left</v-icon>
                    <v-icon class="text-primary" style="font-size:22px">mdi-arrow-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-row>

      </div>
    </v-container>
    <CmpPasscode @sendPassCode="sendReq"></CmpPasscode>
    <SubModalDel v-bind:data_info_sub="data_info_curtain"></SubModalDel>
    <SubModalEdit v-bind:data_info_sub="data_info_curtain"></SubModalEdit>
    <SubModalSetPass v-bind:data_info_sub="data_info_curtain"></SubModalSetPass>
    <my-modal v-show="isModalVisible"></my-modal>
  </v-app>
</template>

<script>
import SubModalDel from "../../subdevice/SubModalDel";
import SubModalEdit from "../../subdevice/SubModalEdit";
import SubModalSetPass from "../../subdevice/SubModalSetPass";
import {
  SucessToastMsg,
  ErrorToastMsg,
  BetweenStr,
} from "../../../../public/assets/js/utils/util.js";
import {getTopics} from "../../../../public/assets/js/utils/topics.js";
import {postAjaxCall} from "../../../../public/assets/js/postAjax";
import {responseAjax} from "../../../../public/assets/js/utils/response_ajax_handler";
import {passHomeValidationInput} from "../../../../public/assets/js/utils/error_handler";
import { getUrl } from "@/../public/assets/js/utils/helper_url.js";
import CmpPasscode from "../../Cmp/general/CmpPasscode";
import LayoutDefault from "../../../layouts/LayoutDefault";

var connection = false;
var status_admin = localStorage.getItem("status_admin");
var status;
var curtain_status;
var showToast;
var timeout;

export default {
  components: {
    SubModalDel,
    SubModalEdit,
    SubModalSetPass,
    CmpPasscode,
  },
  props: {
    home_id: {},
    serial_number: {},
    count: {},
    name: {},
    click: {}
  },
  data() {
    return {
      passcurtain: "",
      set_password: "",
      pass: true,
      data_info_curtain: [this.home_id, this.serial_number, this.count],
      main_admin: "",
      title: "",
      subdevice_serial: '',
      colorOpen: "gray",
      colorClose: "gray",
      colorStop: "gray",
      txtStatus: '',
      isModalVisible: false
    };
  },
  mqtt: {
    "+/+/receive/+/action"(data, topic) {
      if (topic.split("/")[1] == this.serial_number && topic.split("/")[3] == this.subdevice_serial) {
        connection = true;
        clearTimeout(timeout);
        this.showGetMsg(String(data));
        this.exitModalLoading();
        if (showToast == 1) {
          SucessToastMsg.Msg();
        }
      }
    },
  },
  mounted() {
    try {
      let self = this;
      this.$store.dispatch("InfoSubDevice");
      this.$store.dispatch("infoHome");
      this.data = JSON.parse(localStorage.getItem("infoHome"))
      this.pass = this.data.password === 1;
      this.set_password = this.$store.getters.getSetPasswordSub;
      this.main_admin = this.$store.getters.getadmin;
      this.title = this.$store.getters.getNameSub;
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number
      this.onSubscribe();
      setTimeout(function () {
        self.lastStausCurtain();
      }, 100);


    } catch (err) {
      let self = this;
      swal(
          {
            title: "",
            text: self.$t('message.public.operation_failure'),
            type: "error",
            confirmButtonColor: "#dc3545",
            confirmButtonText: self.$t('message.public.OK'),
            closeOnConfirm: false,
          },
          function (isConfirm) {
            if (isConfirm) {
              self.$router.push({name: "mainpage"});
              swal.close();
            }
          }
      );
    }
    let details = {
      title: this.title,
      timer: true,
      pin: this.pass,
      passcode: this.set_password,
      menu: true,
      editName: true,
      del: true,
      about:true
    }
    localStorage.setItem("AppBar", JSON.stringify(details));
    this.$emit('update:layout', LayoutDefault);
    this.$emit('update:update', 'SubCurtainFara');
  },
  watch: {
    click(val) {
      this.onClickChild(val);
    }
  },
  methods: {
    onClickChild(value) {
      if (value == "back") {
        this.$router.push({
          name: "homeDevices",
          params: {home_id: this.home_id, serial_number: this.serial_number},
        });
      }
      if (value == "timer") {
        this.$router.push({
          name: "CurtainTimerShow",
          params: {home_id: this.home_id, serial_number: this.serial_number, count: this.count},
        });
      }
            if (value == "about") {
        this.$router.push({
          name: "aboutcurtain",
          params: {
            home_id: this.home_id,
            serial_number: this.serial_number,
            count: this.count,
          },
        });
      }
      if (value == "del") {
        $("#modal-del-sub").modal("show");
      }
      if (value == "edit") {
        $("#modal-edit-sub").modal("show");
      }
      if (value == "pass") {
        $("#modal-set-pass-sub").modal("show");
      }
    },
    openFunction() {
      this.sendMessage("K1T#");
    },
    stopFunction() {
      this.sendMessage("K2T#");
    },
    closeFunction() {
      this.sendMessage("K3T#");
    },
    sendMessage(status) {
      if (this.set_password == 1) {
        curtain_status = status;
        $("#modal-pass-code").modal("show");
      } else {
        this.sendRequest(status, '');
      }
    },
    sendReq(password) {
      this.sendRequest(curtain_status, password);
    },
    sendRequest(saction, password) {
      var self = this;
      var user_id = localStorage.getItem("user_id");
      var token = localStorage.getItem("token");
      this.subdevice_serial = JSON.parse(localStorage.getItem("infoSubDevice")).serial_number

      var topic_final = getTopics.getTopicActionSend(
          this.serial_number,
          this.subdevice_serial
      );
      // ----------------------Data------------------//
      var data = {
        user_id: user_id,
        token: token,
        topic: topic_final,
        message: saction,
        password: password,
        client_id: "fara" + Date.now(),
      };
      // --------------------Requsest----------------------//
      let URL = getUrl.mqttRequest;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      showToast = 1;
      // ---------------------Rseponse--------------------//
      p.then(
          function (response) {
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              self.openModalLoading();
              $("#modal-pass-curtain").modal("hide");
              connection = false;
              timeout = setTimeout(function () {
                self.exitModalLoading();

                if (!connection) {
                  if (showToast == 1) {
                    ErrorToastMsg.Msg();
                  }
                }
                showToast = 0;
                connection = false;
              }, 3000);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
    openModalLoading() {
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
      }, 10000)
    },
    exitModalLoading() {
      this.isModalVisible = false;
    },
    sendPassCurtain() {
      if (!passHomeValidationInput(this.passcurtain)) return;
      this.sendRequest(curtain_status, this.passcurtain);
      this.passcurtain = "";
    },
    showGetMsg(msg) {
      this.parseStatusCurtain(msg)

    },
    parseStatusCurtain(msg) {
      if (msg == '1000') {
        this.txtStatus = this.$t('message.public.open')
      }
      if (msg == '0000') {
        this.txtStatus = this.$t('message.public.stop')
      }
      if (msg == '0010') {
        this.txtStatus = this.$t('message.public.close')
      }
    },
    onSubscribe() {
      var topic1 = getTopics.getTopicActionReceive(
          this.serial_number,
          this.subdevice_serial
      );

      this.$mqtt.subscribe(topic1);
    },
    lastStausCurtain() {
      let self = this;
      let user_id = localStorage.getItem("user_id");
      let token = localStorage.getItem("token");
      let home_id = this.home_id;
      let count = this.count;
      // ------------------Data--------------//
      var data = {
        user_id: user_id,
        token: token,
        home_id: home_id,
        serial_number: JSON.parse(localStorage.getItem("infoSubDevice")).serial_number,
        count: count,
      };
      // ------------------Requsest------------------//
      let URL = getUrl.subStatus;
      const p = postAjaxCall(URL, data);
      this.openModalLoading();
      // ------------------Rseponse----------------//
      p.then(
          function (response) {
            self.exitModalLoading();
            var jsonArray = JSON.parse(response.responseText);
            if (responseAjax(response)) {
              let message = jsonArray.content.message;
              console.log('message', message);
              self.parseStatusCurtain(message);
            } else {
              self.exitModalLoading();
              if (response.status == 401) {
                if (jsonArray.code == "4001") {
                  self.$router.push({name: "home"});
                }
              }
            }
          },
          //--------------------------------------Err --------------------------------------//
          function (errr) {
          }
      );
    },
  },
};
</script>
<style scoped>
.col-3 {
  text-align: center !important;
}
.v-btn--fab.v-size--default {
  width: 45px !important;
  height: 45px !important;
}
.v-sheet.v-card {
  border-radius: 14px;
  box-shadow: none !important;;
}
.icon-size {
  font-size: 25px;
}
</style>
